import { Component, OnInit } from '@angular/core';
import { SitesService } from 'src/app/services/sites.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-programming-tools-page',
  templateUrl: './programming-tools.page.html',
  styleUrls: ['./programming-tools.page.scss'],
})
export class ProgrammingToolsPage implements OnInit {
  public controller;
  private node_id;
  private gettingData;
  public narrow;

  constructor(
    private route: ActivatedRoute,
    public sitesApiProvider: SitesService
  ) {
    this.route.params.subscribe(params => {
      this.narrow = !this.node_id ? params["narrow"] : false;
      this.node_id = !this.node_id ? params["node_id"] : this.node_id;
      this.start();
    });    
  }

  start() {
    let page = this;
    let timer = function(x) {
      if(x === 0) {
        if(!page.controller) {
          page.controller = page.sitesApiProvider.getLocalNodeResponseData();
        }

        return;
      }

      return setTimeout(() => {timer(--x)}, 1000)
    }

    this.route.params.subscribe(params => {
      this.sitesApiProvider.activeNodeId = !this.node_id ? params["node_id"] : this.node_id;
      this.node_id = !this.node_id ? params["node_id"] : this.node_id;
      this.gettingData = this.node_id !== null;
      timer(1);
      if(!this.gettingData) {
        this.sitesApiProvider.getNodeData()
                             .then((data) => {
                               this.controller = data.node ? data.node : data;
                               this.gettingData = false;
                             })
      }

      this.sitesApiProvider.nodeDataSubscription.subscribe((data) => { this.controller = data; });
    }, error => {
      console.log(`error getting programming tools page params`)
      console.log(error)
    });
  }

  ngOnInit() {
    this.start()
  }  
}
