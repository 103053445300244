<div *ngIf="light_value || daily_light_integral || light_set_point" class="light_sensor"
  [class.no_light_val]="!light_value"
>
  <div *ngIf="light_value" class="uneditable">
    <label>Light Value</label>
    <span>{{light_value}}</span>
  </div>
  <div class="uneditable">
    <label>Daily Light Integral</label>
    <span>{{daily_light_integral / 100}}</span>
  </div>
  <div *ngIf="light_set_point !== undefined" (click)="editLightSetPoint()" [class.pending]="pending">
    <label>Light Set Point</label>
    <span>{{format_set_point ? formatted_set_point : light_set_point}}</span>
  </div>
</div>
<div *ngIf="!light_value && !daily_light_integral && !light_set_point" class="no-light_sensor">
  <p>Your light sensor is not enabled. Please configure it on your controller.</p>
</div>
