<div class="programming-title" [class.narrow]="(narrow !== undefined) || smallScreen" *ngIf="online">
  <app-title-navigation [finalReview]="finalReview" [startFromScratch]="!startFromScratchSelected" [online]="online" 
    [smallScreen]="smallScreen" [current_page]="current_page" [viewablePages]="viewablePages" [current_step]="current_step"
    (change)="setPage($event)" (import)="importToController()"></app-title-navigation>  
  <div *ngIf="finalReview" class="final-review">Please review the proposed settings changes. Press OK to import all changes to the controller.</div>    
  <ion-item class="program-review-title" lines="none" [class.narrow]="narrow && !smallScreen" *ngIf="!smallScreen && startFromScratchSelected">
    <ion-label class="review-label">Review</ion-label>
    <ion-button slot="end" class="secondary" *ngIf="!reviewing" alt="Review" 
        [disabled]="!current_step || (steps && current_step_index >= stepsLength())" (click)="review()"><ion-icon name="eye-outline"></ion-icon></ion-button>
    <ion-button slot="end" class="secondary" *ngIf="reviewing" alt="Back to Guide" (click)="backToGuide()"><ion-icon name="eye-off-outline"></ion-icon></ion-button>
    <ion-button class="load-controller" *ngIf="steps && !finalReview && startFromScratchSelected && (current_page && current_page.steps[0] !== 'dehumidification_form')" (click)="importToController()"><ion-icon name="cloud-upload-outline"></ion-icon></ion-button>
  </ion-item>
</div>
<div class="programming-and-review" [class.narrow]="(narrow !== undefined) || smallScreen">
  <div *ngIf="!online" id="offline">
    Your controller is currently offline. You can use the guided programming feature when the controller comes back online.
  </div>
  <ion-item *ngIf="!startFromScratchSelected && online" id="starting-selection">
    <ion-label>Start From</ion-label>      
    <ion-radio-group [(ngModel)]="startFromScratch" [value]="startFromScratch">
      <ion-item *ngFor="let opt of optionsForStart">        
        <ion-radio [value]="opt.value" mode="ios"></ion-radio>
        <ion-label>{{opt.name}}</ion-label>
      </ion-item>
    </ion-radio-group>        
  </ion-item>
  <form id="programmingForm" [formGroup]="programmingForm" [class.narrow]="narrow"
    [class.reviewing]="reviewing" *ngIf="online && current_page && !finalReview" [class.display-none]="startFromScratch === undefined || !startFromScratchSelected">
    <div *ngIf="current_page && current_page.steps && current_page.steps.includes('zones')">
      <ion-label class="ion-text-wrap">
        How many zones do you want to operate with this controller? 
        <span>Choose 1 if you have only one growing zone. Choose 2 if you will be operating two growing zones or two greenhouses with this controller.</span>
      </ion-label>
      <ion-radio-group>
        <ion-label><ion-radio formControlName="zones" [value]="2" mode="ios" (change)="changeZones($event)" type="radio"></ion-radio>2</ion-label>
        <ion-label><ion-radio formControlName="zones" [value]="1" mode="ios" (change)="changeZones($event)" type="radio"></ion-radio>1</ion-label>
      </ion-radio-group>
    </div>

    <ion-item *ngIf="(current_page && current_page.steps.includes('day_start'))">
      <ion-label class="ion-text-wrap">When would you like your DAY stage to start (HH:mm)?</ion-label>
      <ion-input formControlName="day_start" type="text" 
        inputmode="tel"
        pattern="[0-9]{1,2}:[0-9]{2}"
        (keyup)="reformatTime($event, 'day_start')"
        ></ion-input>
    </ion-item>

    <ion-item *ngIf="(current_page && current_page.steps.includes('day_temp'))">
      <ion-label class="ion-text-wrap">During the day what is your target temperature ({{tUnits}}) to maintain? </ion-label>
      <ion-input formControlName="day_temp" type="number" [min]="tempMin" [max]="tempMax" ></ion-input>
    </ion-item>

    <div *ngIf="(current_page && current_page.steps.includes('day_temp_zone2')) && programmingForm.value.zones === 2">
      <ion-label class="ion-text-wrap">During the day what is your zone 2 target temperature ({{tUnits}}) to maintain? </ion-label>
      <ion-input formControlName="day_temp_zone2" type="number" [min]="tempMin" [max]="tempMax"></ion-input>
    </div>

    <ion-item *ngIf="(current_page && current_page.steps.includes('nite_start'))">
      <ion-label class="ion-text-wrap">Use DAY settings for Night?</ion-label>
      <ion-button (click)="useDayForNite()">Copy DAY Settings</ion-button>
    </ion-item>
    <ion-item *ngIf="(current_page && current_page.steps.includes('nite_start'))">
      <ion-label class="ion-text-wrap">When would you like your Night stage to start (HH:mm)?</ion-label>
        <ion-input formControlName="nite_start" type="text" 
          inputmode="tel"
          pattern="[0-9]{1,2}:[0-9]{2}"
          (keyup)="reformatTime($event, 'nite_start')"
      ></ion-input>
    </ion-item>

    <ion-item *ngIf="current_page && current_page.steps.includes('nite_degrees')">
      <ion-label class="ion-text-wrap">Please enter your Night stage target temperature. Your day target is: ({{programmingForm.value.day_temp}})</ion-label>
      <ion-input formControlName="nite_degrees" type="number" [min]="tempMin" [max]="tempMax"></ion-input>
    </ion-item>

    <ion-item *ngIf="(current_page && current_page.steps.includes('nite_degrees_zone2')) && programmingForm.value.zones === 2">
      <ion-label class="ion-text-wrap">Zone 2: Please enter your Night stage target temperature. Your day target is: ({{programmingForm.value.day_temp}})</ion-label>
      <ion-input formControlName="nite_degrees_zone2" type="number" [min]="tempMin" [max]="tempMax"></ion-input>
    </ion-item>
    
    <ion-item *ngIf="current_page && current_page.steps.includes('dif_start')">
      <ion-label class="ion-text-wrap">Use DAY settings for DIF?</ion-label>
      <ion-button (click)="useDayForDif()">Copy DAY Settings</ion-button>
    </ion-item>
    <ion-item *ngIf="current_page && current_page.steps.includes('dif_start')">
      <ion-label class="ion-text-wrap">When would you like your DIF stage to start (HH:mm)?</ion-label>
      <ion-input formControlName="dif_start" type="text"
            inputmode="tel"
            pattern="[0-9]{1,2}:[0-9]{2}"
            (keyup)="reformatTime($event, 'dif_start')"
        ></ion-input>
    </ion-item>
    <ion-item *ngIf="current_page && current_page.steps.includes('dif_degrees')">
      <ion-label class="ion-text-wrap">Please enter your DIF stage target temperature.</ion-label>
      <ion-input formControlName="dif_degrees" type="number" [min]="tempMin" [max]="tempMax" ></ion-input>
    </ion-item>

    <ion-item *ngIf="(current_page && current_page.steps.includes('dif_degrees_zone2')) && programmingForm.value.zones === 2">
      <ion-label class="ion-text-wrap">Zone 2: Please enter your DIF stage target temperature.</ion-label>
      <ion-input formControlName="dif_degrees" type="number" [min]="tempMin" [max]="tempMax" ></ion-input>
    </ion-item>
    <span *ngIf="current_page.steps.includes('dif_start')">DIF is a period for transition from night to day to allow the house or growing zone to stay a little cooler before sunrise and optimize plant growth.</span>    

    <ion-item *ngIf="(current_page && (current_page.steps.includes('day_h1') || current_page.steps.includes('day_h2')))">
      <h6 class="output-activation">Activation Temperatures</h6>
      <ion-note>Night & DIF activation temperatures will be displayed as you adjust the day activation temperatures for each output.</ion-note>
    </ion-item> 
    <ion-grid *ngIf="(current_page && (current_page.steps.includes('day_h1') || current_page.steps.includes('day_h2')))" class="labels">
      <ion-row>
        <ion-col>
          Day
        </ion-col>
        <ion-col>Night</ion-col>
        <ion-col>DIF</ion-col>
        <ion-col>Enable/Disable</ion-col>
      </ion-row>    
      <ion-row *ngIf="(current_page && current_page.steps.includes('day_c4') && model && model.includes('GHK'))" class="output-activation">
        <ion-col class="vertical">
          <ion-label>Cool 4:</ion-label>
          <input [class.invalid]="programmingForm.controls.day_c4.errors?.heatCoolInvalid" [class.disabled]="programmingForm.value && programmingForm.value.c4m === 'OFF'"
            formControlName="day_c4" fill="solid" type="number" [min]="tempMin" [max]="tempMax"/>
        </ion-col>
        <ion-col>{{programmingForm.value.nite_degrees + getOffset('day_c4')}}</ion-col>
        <ion-col>{{programmingForm.value.dif_degrees + getOffset('day_c4')}}</ion-col>
        <ion-col><ion-button (click)="setValue('c4m', programmingForm.value.c4m === 'AUTO' ? 'OFF' : 'AUTO')">{{programmingForm.value.c4m === 'AUTO' ? 'OFF' : 'ENABLE'}}</ion-button></ion-col>
      </ion-row>
    
      <ion-row *ngIf="(current_page && current_page.steps.includes('day_c3') && model && model.includes('GHK'))" class="output-activation">
        <ion-col>
          <ion-label>
            Cool 3:
          </ion-label>
          <input [class.invalid]="programmingForm.controls.day_c3.errors?.heatCoolInvalid" [class.disabled]="programmingForm.value && programmingForm.value.c3m === 'OFF'"
            formControlName="day_c3" type="number" [min]="tempMin" [max]="tempMax"/>        
        </ion-col>
        <ion-col>{{programmingForm.value.nite_degrees + getOffset('day_c3')}}</ion-col>
        <ion-col>{{programmingForm.value.dif_degrees + getOffset('day_c3')}}</ion-col>
        <ion-col><ion-button (click)="setValue('c3m', programmingForm.value.c3m === 'AUTO' ? 'OFF' : 'AUTO')">{{programmingForm.value.c3m === 'AUTO' ? 'OFF' : 'ENABLE'}}</ion-button></ion-col>
      </ion-row>

      <ion-row *ngIf="(current_page && current_page.steps.includes('day_c2') && model && model.includes('GHK'))" class="output-activation">
        <ion-col>
        <ion-label>
          Cool 2:
        </ion-label>
          <input [class.invalid]="programmingForm.controls.day_c2.errors?.heatCoolInvalid" [class.disabled]="programmingForm.value && programmingForm.value.c2m === 'OFF'"
            formControlName="day_c2" type="number" [min]="tempMin" [max]="tempMax"/>        
        </ion-col>
        <ion-col>{{programmingForm.value.nite_degrees + getOffset('day_c2')}}</ion-col>
        <ion-col>{{programmingForm.value.dif_degrees + getOffset('day_c2')}}</ion-col>
        <ion-col><ion-button (click)="setValue('c2m', programmingForm.value.c2m === 'AUTO' ? 'OFF' : 'AUTO')">{{programmingForm.value.c2m === 'AUTO' ? 'OFF' : 'ENABLE'}}</ion-button></ion-col>
      </ion-row>        

      <ion-row *ngIf="(current_page && current_page.steps.includes('day_c1') && model && model.includes('GHK'))" class="output-activation">
        <ion-col>
          <ion-label>Cool 1:</ion-label>
          <input [class.invalid]="programmingForm.controls.day_c1.errors?.heatCoolInvalid" [class.disabled]="programmingForm.value && programmingForm.value.c1m === 'OFF'"
            formControlName="day_c1" type="number" [min]="tempMin" [max]="tempMax"/>        
        </ion-col>
        <ion-col>{{programmingForm.value.nite_degrees + getOffset('day_c1')}}</ion-col>
        <ion-col>{{programmingForm.value.dif_degrees + getOffset('day_c1')}}</ion-col>
        <ion-col><ion-button (click)="setValue('c1m', programmingForm.value.c1m === 'AUTO' ? 'OFF' : 'AUTO')">{{programmingForm.value.c1m === 'AUTO' ? 'OFF' : 'ENABLE'}}</ion-button></ion-col>
      </ion-row>

      <ion-row *ngIf="(current_page && (current_page.steps.includes('day_h1') || current_page.steps.includes('day_h2')))" class="output-activation">      
        <ion-col size="auto">Day Set Point (target temperature) is {{programmingForm && programmingForm.value && programmingForm.value.day_temp ? programmingForm.value.day_temp : 'N/A' }}</ion-col>
      </ion-row>

      <ion-row *ngIf="(current_page && current_page.steps.includes('day_h1'))" class="output-activation">
        <ion-col>
          <ion-label>Heat 1:</ion-label>
          <input [class.invalid]="programmingForm.controls.day_h1.errors?.heatCoolInvalid" [class.disabled]="programmingForm.value && programmingForm.value.h1m === 'OFF'"
            formControlName="day_h1" type="number" [min]="tempMin" [max]="tempMax"/>        
        </ion-col>
        <ion-col>{{programmingForm.value.nite_degrees - getOffset('day_h1')}}</ion-col>
        <ion-col>{{programmingForm.value.dif_degrees - getOffset('day_h1')}}</ion-col>
        <ion-col><ion-button (click)="setValue('h1m', programmingForm.value.h1m === 'AUTO' ? 'OFF' : 'AUTO')">{{programmingForm.value.h1m === 'AUTO' ? 'OFF' : 'ENABLE'}}</ion-button></ion-col>
      </ion-row>

      <ion-row *ngIf="(current_page && current_page.steps.includes('day_h2'))" class="output-activation">
        <ion-col>
          <ion-label>Heat 2<sup>*</sup>:</ion-label>
          <input [class.invalid]="programmingForm.controls.day_h2.errors?.heatCoolInvalid" [class.disabled]="programmingForm.value && programmingForm.value.h2m === 'OFF'"
            formControlName="day_h2" type="number" [min]="tempMin" [max]="tempMax"/>
        </ion-col>
        <ion-col>{{programmingForm.value.nite_degrees - getOffset('day_h2')}}</ion-col>
        <ion-col>{{programmingForm.value.dif_degrees - getOffset('day_h2')}}</ion-col>
        <ion-col><ion-button (click)="setValue('h2m', programmingForm.value.h2m === 'AUTO' ? 'OFF' : 'AUTO')">{{programmingForm.value.h2m === 'AUTO' ? 'OFF' : 'ENABLE'}}</ion-button></ion-col>
      </ion-row>
    </ion-grid>   
    <div *ngIf="(current_page && current_page.steps.includes('day_h2')) && zones === 1" class="output-activation">
      <ion-label class="ion-text-wrap"><span><sup>*</sup>Heat 2 is often used as a backup or staged heat</span></ion-label></div>

    <div class="vents-title vent-config" [class.display]="current_page.steps.includes('vents') || current_page.steps.includes('vents_zone2')
          && !editingVent">
      <ion-label class="timer-labels">        
        <a *ngFor="let vent of ventNumbers let vent_index = index"
          (click)="editVent(vent)" [class.selected]="vent_editing_index === vent">
          Vent {{getVentLabel(vent, (current_page.steps.includes('vents') ? 1 : 2))}}
        </a>
      </ion-label>
      <!-- <div *ngIf="current_page.steps.includes('vents_zone2')  && numberVents && numberVents > 0" class="vent-buttons">
        <ion-button (click)="editVent(vents_available_by_zone === 0 ? 0 : 1)">Edit Vent {{model.includes('RWL') ? 'B' : 'D'}}</ion-button>
        <ion-button *ngIf="model.includes('RWL')" (click)="editVent(3)">Edit Vent D</ion-button>
      </div>
      <ion-button class="add" *ngIf="ventsAvailable()" (click)="addVent()">Edit Add Vent</ion-button> -->
    </div>

    <div class="vent-form" [class.display]="(current_page.steps.includes('vents') || current_page.steps.includes('vents_zone2'))" formArrayName="vents">      
      <div *ngFor="let v of vent_config let vent_index = index" [class.display-none]="!active_vent || (active_vent && !active_vent.vent_name) || (active_vent && active_vent.vent_name && active_vent.vent_name !== v.vent_name)">
        <app-vent-form #vents [vent]="v" [index]="vent_index" [model]="model"
          [dayTemp]="current_step === 'vents' ? programmingForm.value.day_temp : programmingForm.value.day_temp_zone2"
          (nextVal)="addVentToProgram($event)"></app-vent-form>
      </div>
    </div>

    <div class="timer-config" [class.display]="current_page && current_page.steps.includes('timers')">
      <ion-label class="timer-labels">
        <a *ngFor="let timer of timerTypes let timer_index = index" [class.selected]="current_timer === timer_index"
          (click)="setTimer(timer_index)">
          Timer {{timer_index + 1}} ({{timer.isShade ? 'Shade' : (timer.isMister ? 'Mister': 'Timer')}})
        </a>
      </ion-label>
      
      <div class="timer" *ngFor="let timer of timerTypes let timer_index = index" [class.display-none]="current_timer !== timer_index">
        <app-timer-form #timers [index]="timer_index" [timer]="timer" [defaults]="timer" [misterInfo]="getMisterInfo(timer_index)" [viewing]="current_timer === timer_index"
          [shadeInfo]="getShadeInfo()" (nextVal)="updateTimer($event)" [current_timer]="current_timer"></app-timer-form>
      </div>
    </div>

    <div class="dehumidification-container" [class.display]="current_page && current_page.steps.includes('dehumidification_form')">
      <app-dehumidification-form #dehum [model]="node.model" [c2_temp]="model.includes('GHK') ? programmingForm.value.day_c2 : (programmingForm.value.vents && programmingForm.value.vents.length > 0 ? programmingForm.value.vents[0].p1 : '0')"
        [h1_temp]="programmingForm.value.day_h1" [defaults]="getDehumDefaults()"
        (nextVal)="updateDehum($event)">
      </app-dehumidification-form>
    </div>

    <p class="invalid-entry">
      <span *ngFor="let step of current_page.steps">
        {{ (programmingForm.controls[step] && programmingForm.controls[step].dirty && !programmingForm.controls[step].valid && programmingForm.controls[step].touched) ?
          'This value is invalid, please correct before moving to the next step.' : ' '
        }}
      </span>
    </p>
  </form>  
  <app-review [class.reviewing]="reviewing || finalReview"
    [programmingForm]='programmingForm' [showGraph]="showGraph"
    [narrow]="narrow" [model]="model" [vent_config]="vent_config" [dehumValue]="dehumValue"
    [timer_values]="timer_values" [reviewing]="reviewing" [finalReview]="finalReview" [timerTypes]="timerTypes">
  </app-review>
</div>
<app-navigation
  [startFromScratchSelected]="startFromScratchSelected" [current_step]="current_step" [startFromScratch]="startFromScratch"
  [steps]="steps" [stepsLength]="stepsLength" [current_step_index]="current_step_index" [current_page]="current_page"
  [finalReview]="finalReview" [editingVent]="editingVent" [reviewing]="reviewing"
  [vent_editing_index]="vent_editing_index" [programmingForm]="programmingForm" [showGraph]="showGraph"
  (changeStepEmitter)="changeStep($event.i, $event.back)" [smallScreen]="smallScreen"
  (import)="importToController()" (start)="startForm()" (back)="backToGuide()"
  (final)="finalImport($event)" (nextV)="nextVent($event)" (rev)="review()"
  (updateD)="updateDehum()" (nextD)="nextDehum($event)"  
  (nextT)="nextTimer($event)" (invalid)="subFormInvalid()" (showHideG)="showHideGraph()"
></app-navigation>