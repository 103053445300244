import {AbstractControl, ValidationErrors, ValidatorFn, UntypedFormGroup, FormControl} from '@angular/forms';

let validateControl = (control, key, day_temp, h1, h2, c1, c2, c3, c4, model): ValidationErrors | null => {
  const heat_one_activation = h1;
  const heat_two_activation = h2;
  let heatUnderCool = (heat, cool) => {
    if(heat && cool) {
      return heat < cool;
    } else { return true; }
  }

  let valid = true;
  let cools = [];
  if(model && model.includes('RWL')) {
    let target_temp = day_temp;
    const vents = control && control.value ? control.value.vents : undefined;
    let ctrls = vents && vents.controls ? vents.controls : [];
    cools = ctrls.map((v: UntypedFormGroup, index) => {
      let vent_controls = v.controls;
      return target_temp + (vent_controls.step ? (vent_controls.step.value * index) : 0)
    }).filter((v) => v)
  } else {
    const cool_one_activation = c1;
    const cool_two_activation = c2;
    const cool_three_activation = c3;
    const cool_four_activation = c4;
    cools = [cool_one_activation, cool_two_activation, cool_three_activation, cool_four_activation];
  }

  if(heat_one_activation !== null && key.includes('h1')) {
    valid = cools.every((c, i) => {
      return heatUnderCool(heat_one_activation, c)
    })
  }

  if(heat_two_activation !== null && key.includes('h2')) {
    valid = cools.every((c) => heatUnderCool(heat_two_activation, c))
  }

  if(!valid) {
    control.setErrors({'heatCoolInvalid': true})
  }

  return valid ? null : {'heatCoolInvalid': true}
}

export function heatCoolActivationValidation(model): ValidatorFn {
  return (control): ValidationErrors | null => {
    if(control.constructor.name === "FormGroup") {
      let target_temp = control.get('day_temp').value;
      let day_h1 = control.get('day_h1').value;
      let day_h2 = control.get('day_h2').value;
      let day_c1 = control.get('day_c1').value;
      let day_c2 = control.get('day_c2').value;
      let day_c3 = control.get('day_c3').value;
      let day_c4 = control.get('day_c4').value;

      return Object.keys((control as UntypedFormGroup).controls).map((key) => {
        return validateControl((control as UntypedFormGroup).controls[key], key, target_temp, day_h1, day_h2, day_c1, day_c2, day_c3, day_c4, model);
      }).filter((err) => err !== null)
    } else {
      console.log(`validation for: `)
      console.log(control)
      // return validateControl(control.controls[key], target_temp, day_h1, day_h2, day_c1, day_c2, day_c3, day_c4, model);
    }
  }
}
