<table *ngIf="settings">
  <tr>
    <td>&nbsp;</td>
    <td class="header">On</td>
    <td class="header">Cycle</td>
  </tr>

  <tr>
    <td>Stage A</td>
    <td (click)="editMisterSetting('mistAOnSec')" class="clickable"
      [class.pending]="settings.pendingSettings && settings.pendingSettings.mistAOnSec">
      {{settings.mistAOnSec}}
    </td>
    <td (click)="editMisterSetting('mistACycleMin')" class="clickable"
      [class.pending]="settings.pendingSettings && settings.pendingSettings.mistACycleMin">
      {{settings.mistACycleMin}}</td>
  </tr>

  <tr>
    <td>Stage B</td>
    <td (click)="editMisterSetting('mistBOnSec')" class="clickable"
      [class.pending]="settings.pendingSettings && settings.pendingSettings.mistBOnSec">{{settings.mistBOnSec}}</td>
    <td (click)="editMisterSetting('mistBCycleMin')" class="clickable"
      [class.pending]="settings.pendingSettings && settings.pendingSettings.mistBCycleMin">{{settings.mistBCycleMin}}</td>
  </tr>

  <tr>
    <td>Idle</td>
    <td (click)="editMisterSetting('mistIdleOnSec')" class="clickable"
      [class.pending]="settings.pendingSettings && settings.pendingSettings.mistIdleOnSec">{{settings.mistIdleOnSec}}</td>
    <td (click)="editMisterSetting('mistIdleCycleMin')" class="clickable"
      [class.pending]="settings.pendingSettings && settings.pendingSettings.mistIdleCycleMin">{{settings.mistIdleCycleMin}}</td>
  </tr>
</table>

<app-light-sensor
  *ngIf="lightSource !== 'NONE'"
  [daily_light_integral]="daily_light_integral"
  [light_set_point]="light_set_point"
  [format_set_point]="true"
  [light_value]="light_value"
  [nodeData]="nodeData"
  [misterSettings]="settings"
  [misterIndex]="mister_index"
  [pending]="settings ? settings.pending : false"
></app-light-sensor>
