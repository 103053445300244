<div id="site-usage-report" class="section" *ngIf="showSection" [class.active]="showSection && adminPage" [class.report]="!adminPage">
  <div>
    <div class='subdiv' *ngIf="sites">
      <div *ngIf="siteId && (siteId && siteId !== 'ALL') && !adminPage">
        <h5>Selected Site: {{siteName}}</h5>
      </div>
      <!-- <input type="button" (click)="updateChanges()" value="Update"/> -->
      <div *ngIf="charts_drawn && selectedSite && !adminPage" class="print"><button (click)="print()">Print</button></div>
      <div *ngIf="selectedSite && site_usage && site_usage.length > 0 && !adminPage && !charts_drawn"><button (click)="drawChartForAveragesForAll()">See averages chart(s) for all</button></div>
      <!-- <input type="button" (click)="updateChanges()" value="Update"/> -->
    </div>
  </div>
  <div *ngIf="getNodes().length === 0 && selectedSite">
    There are no nodes for that site.
  </div>
  <div>
    <ul *ngIf="site_usage.length > 0" class="controllers">
      <li #allSiteUsage *ngFor='let node of site_usage; index as i; last as last'>
        <div class="name"><span>{{node.name}}</span> <button *ngIf="!charts_drawn" (click)="drawChartForAverages(node, i)">See Averages</button></div>
        <div class='data'>
          <app-run-times [runTimes]="node.run_times"></app-run-times>

          <div id="{{ 'zoneOneChart' + i }}" class="chart"
            [class.display-none]="hasGraphData[i] && hasGraphData[i].zone_one === false"
            [class.two-charts]="hasGraphData[i] && hasGraphData[i].zone_one && hasGraphData[i].zone_two">
          </div>
          <!-- <div class="no-chart" *ngIf="hasGraphData[i] && !hasGraphData[i].zone_one">
            There is no data for Zone 1 at this time.
          </div> -->

          <div id="{{ 'zoneTwoChart' + i }}" class="chart"
            [class.display-none]="hasGraphData[i] && hasGraphData[i].zone_two === false"
            [class.two-charts]="hasGraphData[i] && hasGraphData[i].zone_one && hasGraphData[i].zone_two">
          </div>
          <!-- <div class="no-chart" *ngIf="hasGraphData[i] && !hasGraphData[i].zone_two">
            There is no data for Zone 2 at this time.
          </div> -->
        </div>
      </li>
    </ul>
  </div>
</div>
