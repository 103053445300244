import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { NodeListItem } from '../model-defs/node-def'
import { NavController } from '@ionic/angular';
import { SitesService } from 'src/app/services/sites.service';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-environmental-node-list',
  templateUrl: './environmental-node-list.component.html',
  styleUrls: ['./environmental-node-list.component.scss'],
})
export class EnvironmentalNodeListComponent implements OnInit, OnChanges {
  @Input() node: NodeListItem;
  @Input() isOwner: Boolean;
  @Input() asDashboard: Boolean = false;
  @Output()
  public choose: EventEmitter<any> = new EventEmitter<any>();

  public zone1HasAlarm = false;
  public zone2HasAlarm = false;
  public h3HasAlarm = false;
  public averages;
  public has_averages = false;

  constructor(public sitesApiProvider: SitesService,
    public navCtrl: NavController,
    public platform: Platform,
    public router: Router
  ) {}

  ngOnInit() {
    if(this.node && this.node.status) {
      this.zone1HasAlarm = ((this.node.status.alarm && this.node.status.alarm!=="NONE" && this.node.status.alarm.endsWith("1")) || (this.node.status.alarms && (this.node.status.alarms.includes("HIGH1") || this.node.status.alarms.includes("LOW1"))));
      this.zone2HasAlarm = this.node.status && this.node.status.zones > 1 && ((this.node.status.alarm && this.node.status.alarm!=="NONE" && this.node.status.alarm.endsWith("2")) || (this.node.status.alarms && (this.node.status.alarms.includes("HIGH2") || this.node.status.alarms.includes("LOW2"))));
      this.h3HasAlarm = (this.node.status.alarm && ['THERM3', 'HIGH3', 'LOW3'].some((h3AlarmType) => h3AlarmType === this.node.status.alarm)) || (this.node.status.alarms && this.node.status.alarms.some((a) => a === 'THERM3' || a === 'HIGH3' || a === 'LOW3'));
    } else {
      this.zone1HasAlarm = false;
      this.zone2HasAlarm = false;
    }
  }

  ngOnChanges() {
    if(this.node && this.node.status) {
      this.zone1HasAlarm = ((this.node.status.alarm && this.node.status.alarm!=="NONE" && this.node.status.alarm.endsWith("1")) || (this.node.status.alarms && (this.node.status.alarms.includes("HIGH1") || this.node.status.alarms.includes("LOW1"))));
      this.zone2HasAlarm = this.node.status && this.node.status.zones > 1 && ((this.node.status.alarm && this.node.status.alarm!=="NONE" && this.node.status.alarm.endsWith("2")) || (this.node.status.alarms && (this.node.status.alarms.includes("HIGH2") || this.node.status.alarms.includes("LOW2"))));
      this.h3HasAlarm = (this.node.status.alarm && this.node.status.alarm === 'THERM3') || (this.node.status.alarms && this.node.status.alarms.some((a) => a === 'THERM3'));
    } else {
      this.zone1HasAlarm = false;
      this.zone2HasAlarm = false;
    }
  }

  ngOnDestroy() {
    this.has_averages = false;
  }

  public openUsers(){
    this.navCtrl.navigateForward('users');
  }

  public selectNodeNoStatus(id) {
    this.select(id, false);
  }

  public selectNode(id) {
    this.select(id, true);
  }

  private select(id, hasStatus) {
    this.asDashboard = false;
    this.sitesApiProvider.activeNodeId = id;

    let width = this.platform.width();
    if(width >= 820) {
      this.choose.emit({node: this.node, has_status: hasStatus});
    } else {
      this.router.navigate(['status', this.node.node_id], {skipLocationChange: false, replaceUrl: false})
    }
  }
}
