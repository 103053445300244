<ion-header>
  <ion-toolbar class="settings-header">
    <div>
      <span class="settings-name">{{settingInfo && settingInfo.desc}}</span>
      <span class="settings-site-house">{{siteName}} - {{houseName}}</span>
    </div>
  </ion-toolbar>
</ion-header>

<div class="content" #editHeatCool>
  <div *ngIf="inputInvalid"
    class="invalid-input">Invalid Input. Acceptable values are: {{invalidMessage}}</div>

  <div class="pending" *ngIf="isPending">
    Currently syncing with the controller
  </div>  

  <form [formGroup]="heatCoolForm">
    <div *ngIf="settingInfo && (settingInfo.type=='heat'||settingInfo.type=='cool'||settingInfo.type=='vent')">
      <div class="onAutoOff">
        <ion-label>Auto</ion-label>
        <input type="radio" name="onOffAuto" formControlName="onOffAuto" value="AUTO"/>
      </div>
      <div class="onAutoOff">
        <ion-label>On</ion-label>
        <input type="radio" name="onOffAuto" formControlName="onOffAuto" value="ON" />
      </div>
      <div class="onAutoOff">
        <ion-label>Off</ion-label>
        <input type="radio" name="onOffAuto" formControlName="onOffAuto" value="OFF"/>
      </div>
    </div>

    <ion-list *ngIf="settingInfo && ['temp', 'percent', 'step', 'time'].includes(settingInfo.type)">
      <div class="setting-item">
        <ion-item lines="none">
          <ion-label class="setting-header">Setting</ion-label>
          <ion-label class="ion-float-right setting-value">
            {{value}}<span *ngIf="settingInfo && settingInfo.type=='temp'">{{tUnits}}</span><span *ngIf="settingInfo.type=='percent'">%</span>
          </ion-label>
        </ion-item>
        <div class="setting-ion-item" *ngIf="settingInfo && settingInfo.type=='temp'">
          <!-- <ion-range class="setting-range" min="32" max="131" [(ngModel)]="value" color="secondary-blue">
            <ion-label range-left>32</ion-label>
            <ion-label range-right>131</ion-label>
          </ion-range> -->
          <ion-icon
            (click)="change('value', -1, validators.temperatureValid, 'Min: 32, Max 131')"
            name="remove-circle-outline" color="secondary-blue"></ion-icon>
          <input
            min="32" max="131"
            formControlName="value"
            type="text" inputmode="tel"
            pattern="[0-9]*"
            select-all
            [disabled]='!canEdit'
            (change)='validateValue($event, validators.temperatureValid, "Min: 32, Max 131")'
            (focus)="scroll()"
          />
          <ion-icon (click)="change('value', 1, validators.temperatureValid, 'Min: 32, Max 131')" name="add-circle-outline" color="secondary-blue"></ion-icon>
        </div>
        <div class="setting-ion-item" *ngIf="settingInfo && settingInfo.type=='percent'">
          <ion-icon (click)="change('value', -1, validators.percentValid, 'Min: 0, Max 99')" name="remove-circle-outline" color="secondary-blue"></ion-icon>
          <input
            min="0" max="99"
            formControlName="value"
            type="text" inputmode="tel"
            pattern="[0-9]*"
            select-all
            [disabled]='!canEdit'
            (change)='validateValue($event, validators.percentValid, "Min: 0, Max 99")'
            (focus)="scroll()"
          />
          <ion-icon (click)="change('value', 1, validators.percentValid, 'Min: 0, Max 99')" name="add-circle-outline" color="secondary-blue"></ion-icon>
        </div>
        <div class="setting-ion-item" *ngIf="settingInfo && settingInfo.type=='step'">
          <ion-icon (click)='change("value", -1, validators.percentValid, "Min: 0, Max 32")'
            name="remove-circle-outline" color="secondary-blue"></ion-icon>
          <input
            min="0" max="32"
            formControlName="value"
            type="text" inputmode="tel"
            pattern="[0-9]*"
            select-all
            [disabled]='!canEdit'
            (change)='validateValue($event, validators.percentValid, "Min: 0, Max 32")'
            (focus)="scroll()"
          />
          <ion-icon (click)="change('value', 1, validators.percentValid, 'Min: 0, Max 32')"
            name="add-circle-outline" color="secondary-blue"></ion-icon>
        </div>
        <div class="setting-ion-item" *ngIf="settingInfo && settingInfo.type=='time'">
          <input
            min="0:00" max="60:60"
            formControlName="value"
            type="text" required inputmode="tel"
            select-all
            pattern="[0-9]{1,2}:[0-9]{2}"
            (change)='validateValue($event, validators.percentValid, "Min: 00:00, Max 60:60")'
            (keyup)="value = timeStrToMinutes($event.target.value); reformatTime($event, 'difStart');"
            (focus)="scroll()"
          />
        </div>
        <div class="setting-ion-item disable" *ngIf="settingInfo.disable_allowed">
          <ion-button color="medium" class="settings-buttons" (click)="disableSetting()">
            Disable
          </ion-button>
        </div>
      </div>
    </ion-list>

    <!-- Heat/Cool settings -->
    <ion-list *ngIf="heatCoolForm && heatCoolForm.value && heatCoolForm.value.onOffAuto=='AUTO'">
      <table class="setting-item">
        <tr>
          <th></th>
          <th class="tb-col-hdr">DIF</th>
          <th class="tb-col-hdr">Day</th>
          <th class="tb-col-hdr">Night</th>
        </tr>
        <tr>
          <th class="tb-row-hdr">Program Set Point</th>
          <td>{{difTemp}}</td>
          <td>{{dayTemp}}</td>
          <td>{{niteTemp}}</td>
        </tr>
        <tr *ngIf="settingInfo && settingInfo.type=='heat'">
          <th class="tb-row-hdr">{{settingInfo.desc}} Active Below -heat</th>
          <td class="tb-row-set">{{difTemp-(this.heatCoolForm.value.value*1)}}</td>
          <td class="tb-row-set">{{dayTemp-(this.heatCoolForm.value.value*1)}}</td>
          <td class="tb-row-set">{{niteTemp-(this.heatCoolForm.value.value*1)}}</td>
        </tr>
        <tr *ngIf="settingInfo && settingInfo.type=='cool'">
          <th class="tb-row-hdr">{{settingInfo.desc}} Active Above -cool</th>
          <td class="tb-row-set">{{difTemp+(this.heatCoolForm.value.value*1)}}</td>
          <td class="tb-row-set">{{dayTemp+(this.heatCoolForm.value.value*1)}}</td>
          <td class="tb-row-set">{{niteTemp+(this.heatCoolForm.value.value*1)}}</td>
        </tr>
      </table>

      <div class="setting-item current">
        <ion-item lines="full">
          <ion-label class="setting-header">Offset</ion-label>          
          <ion-label class="ion-float-right setting-value">            
            <ngx-gauge *ngIf="settingInfo && settingInfo.proportional !== undefined" type="semi" [value]="settingInfo.proportional" label="Percent Open" size="100" 
              thick="5" min="0" max="100" [markers]="markerConfig" margin="20">
          </ngx-gauge>
            {{value}}{{tUnits}}
          </ion-label>
        </ion-item>
        <div class="setting-ion-item">
          <ion-icon (click)="change('value', -1, validators.stepSize, 'Min: 0, Max 31')" name="remove-circle-outline" color="secondary-blue"></ion-icon>
          <input
            min="0" max="31"
            formControlName="value"
            type="text" inputmode="tel"
            pattern="[0-9]*"
            select-all
            [disabled]='!canEdit'
            (change)='validateValue($event, validators.stepSize, "Min: 0, Max 31")'
            (focus)="scroll()"
          />
          <ion-icon (click)="change('value', 1, validators.stepSize, 'Min: 0, Max 31')" name="add-circle-outline" color="secondary-blue"></ion-icon>
        </div>
      </div>        
    </ion-list>

    <div class="setting-ion-item comment-box" *ngIf="canEdit">
        <label>Comment<sup>*</sup></label>
        <input
          formControlName="comment"
          [disabled]='!canEdit'
          (focus)="scroll()"
        />
    </div>
    <p *ngIf="canEdit"><sup>*</sup>Add a message about this change</p>

    <div class="buttons">
      <ion-button color="secondary" class="settings-buttons" (click)="cancel()">
        Cancel
      </ion-button>
      <ion-button
        color="primary"
        [disabled]="!canEdit || inputInvalid"
        class="settings-buttons" (click)="save()">
        Save
      </ion-button>
    </div>
    <div *ngIf="!canEdit" class="save-disabled"><i>Save disabled; You don't have edit permissions.</i></div>
    <div *ngIf="inputInvalid" class="save-disabled"><i>Save disabled; Please fix errors before saving.</i></div>
  </form>
</div>
