import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { SitesService } from 'src/app/services/sites.service';
import { TimerService } from 'src/app/services/timer.service';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-time-boss-list',
  templateUrl: './time-boss-list.component.html',
  styleUrls: ['./time-boss-list.component.scss'],
})
export class TimeBossListComponent implements OnInit, OnChanges {
  public node;
  @Input() nodeData;
  public nodeLoaded = false;
  public timers = [];
  public canEdit: Boolean = false;
  public current_user;
  public pending = {};

  constructor(
    private route: ActivatedRoute,
    public sitesApiProvider: SitesService,
    public timerService: TimerService,
    public modalCtrl: PopoverController,
    public userService: UserService
  ) {
    if (this.userService.LoggedIn()) this.sitesApiProvider.doUpdates = 2;
    this.timerService.setTimerSecond(1);

    this.route.queryParams.subscribe(params => {
      this.node = params['node'];
      this.nodeData = this.node;
    });
  }

  ngOnChanges() {
    this.sitesApiProvider.nodeInList = this.nodeData.node_id;
    if (this.userService.LoggedIn()) this.sitesApiProvider.doUpdates = 2;
    this.timerService.setTimerSecond(1);
  }

  ngOnInit() {
    this.sitesApiProvider.nodeInList = this.nodeData.node_id;
    if (this.userService.LoggedIn()) this.sitesApiProvider.doUpdates = 2;
    this.timerService.setTimerSecond(1);
    this.sitesApiProvider.nodeDataSubscription.subscribe((data) => {this.onGetNodeSuccess(data)}, (error) => this.onGetNodeError(error));
  }

  private setCanEdit() {
    const users = this.nodeData.users || [];
    this.current_user = this.userService.userLoginData.email;

    if(users && this.current_user) {
      var current_user_role = users.find((u) => { return u.email === this.current_user; })
      this.canEdit = current_user_role ? (current_user_role.role > 2) : false;
    } else {
      this.canEdit = false;
    }
  }

  public getShadeInfo(index) {
    if(index === 0 && this.nodeData && this.nodeData.settings && this.nodeData.status) {
      return {
        shadeEnable: this.nodeData.settings && this.nodeData.settings.timers && this.nodeData.settings.timers.length > 0 ? this.nodeData.settings.timers[0].mode : this.nodeData.settings.shadeEnable,
        shadeOpenTime: this.nodeData.settings.shadeOpenTime,
        shadePause: this.nodeData.settings.shadePause,
        shadeSlowRetract: this.nodeData.settings.shadeSlowRetract,
        shadeStep: this.nodeData.settings.shadeStep,
        shadeExtending: this.nodeData.status.timer,
        shadeRetracting: this.nodeData.status.alarm,
        shadeTempSP: this.nodeData.settings.shadeTempSP
      }
    } else {
      return undefined
    }
  }

  private onGetNodeSuccess(data) {
    this.nodeData = data;
    var updatedTimerInfo = Array.from(Array(10).keys());

    const addTimerInfo = (timer, index, is_group_two) => {
      if(is_group_two) {
        index = index + 8;
      }

      if(timer) {
        timer.on = this.nodeData.status[`t${index}`] === 'ON';
      }

      updatedTimerInfo[index - 1] = timer;
    }

    if(data.settings) {
      updatedTimerInfo.forEach((timer_info, i) => {
        if(i < 8) {
          if(data.settings.group1Timer) {
            let timer_settings = data.settings.group1Timer[i];
            let mister_settings = data.settings.group1Mister[i];
            let is_pending = timer_settings.isMister ? this.pending[`tbMister${i + 1}`] : this.pending[`tbTimer${i + 1}`]
            let mister_pending = timer_settings.isMister ? undefined : this.pending[`tbMister${i + 1}`]

            let t = {...timer_settings, ...mister_settings, ...{pending: is_pending, mister_pending: mister_pending}};

            addTimerInfo(t, (i + 1), false)
          }
        } else {
          if(data.settings.group2Timer) {
            let timer_settings = data.settings.group2Timer[i - 8];
            let mister_settings = data.settings.group2Mister ? data.settings.group2Mister[i - 8] : {};
            let is_pending = timer_settings.isMister ? this.pending[`tbMister${i - 7}`] : this.pending[`tbTimer${i - 7}`]
            let mister_pending = timer_settings.isMister ? undefined : this.pending[`tbMister${i - 7}`]

            let t = {...timer_settings, ...mister_settings, ...{pending: is_pending, mister_pending: mister_pending}};
            addTimerInfo(t, ((i - 8) + 1), true)
          }
        }
      })

      if(data.reqs) {
        data.reqs.forEach((req) => {
          this.pending[req.key] = true;
        })
      }
    }

    this.timers = updatedTimerInfo;
    this.setCanEdit();
    this.nodeLoaded = true;
  }

  private onGetNodeError(error){
    console.log("error getting data: " + JSON.stringify(error));
  }

  setPending(index) {
    if(typeof index === 'object') {
      this.timers[index.index].pending = true;
      this.timers[index.index].pendingSettings = this.setTimeBossPending(index, this.timers[index.index]);
    } else {
      if(index) {
        console.log(`set pending - ${JSON.stringify(index)}`)
        this.timers[index].pending = true;
      } else { console.log('no index'); }
    }
  }

  public setTimeBossPending(event, objectToUpdate) {
    let newVals, keys;
    let updating = event.pending_data && event.pending_data.key && event.pending_data.key.toLowerCase().includes("timer") ? 'timer' : 'mister';
    if(event.pending_data && event.pending_data.key && event.pending_data.key.toLowerCase().includes("timer") && !event.pending_data.key.includes('manual') && !['started', 'stopped', 'disabled', 'resume', 'end', 'abort'].some((k) => event.pending_data.comment.includes(k))) {
      keys = [ 'startTimeA', 'holdTimeA', 'startTimeB', 'holdTimeB', 'enable', 'abort'];
      newVals = {startTimeA: '', holdTimeA: '', startTimeB:'', holdTimeB: '', enable:'', abort: ''};
      [ newVals.startTimeA, newVals.holdTimeA, newVals.startTimeB, newVals.holdTimeB, newVals.enable, newVals.abort ] = event.pending_data && event.pending_data.value.split(",");
    } else if(event.pending_data && event.pending_data.key && event.pending_data.key.toLowerCase().includes("mister")) {
      keys = ['light_set_point', 'mistAOnSec', 'mistACycleMin', 'mistBOnSec', 'mistBCycleMin', 'mistIdleOnSec', 'mistIdleCycleMin']
      newVals = {light_set_point: '', mistAOnSec: '', mistACycleMin: '', mistBOnSec:'', mistBCycleMin: '', mistIdleOnSec:'', mistIdleCycleMin: ''};
      [ newVals.light_set_point, newVals.mistAOnSec, newVals.mistACycleMin, newVals.mistBOnSec, newVals.mistBCycleMin, newVals.mistIdleOnSec, newVals.mistIdleCycleMin ] = event.pending_data && event.pending_data.value.split(",");
    }

    let getPendingKey = (comment) => {
      let key = 'enable';
      if(comment && comment.includes('started')) { key = 'manualStart'; }
      if(comment && comment.includes('stopped')) { key = 'stopped'; }
      if(comment && comment.includes('aborted')) { key = 'abort'; }
      if(comment && comment.includes('resume')) { key = 'resume'; }
      if(comment && comment.includes('end')) { key = 'end'; }
      return key;
    }

    let pendingSettings = objectToUpdate.pendingSettings || {};
    if(keys && objectToUpdate && newVals) {
      keys.forEach((k) => {
        if(updating === 'mister' ? (parseInt(objectToUpdate[k]) !== parseInt(newVals[k])) : (objectToUpdate[k] !== newVals[k])) {
          if(event.pending_data.comment && ['started', 'stopped', 'disabled', 'resume', 'end', 'abort'].some((k) => event.pending_data.comment.includes(k))) {
            k = getPendingKey(event.pending_data.comment)
          }

          pendingSettings[k] = true;
        }

        if(['enable', 'abort'].includes(k)) {
          if(event.pending_data.comment && ['started', 'stopped', 'disabled', 'resume', 'end', 'abort'].some((k) => event.pending_data.comment.includes(k))) {
            k = getPendingKey(event.pending_data.comment)
          }
          objectToUpdate[k] = newVals[k] === '1' ? true : false;
        } else {
          objectToUpdate[k] = newVals[k];
        }
      })
    }

    if (event && event.pending_data && event.pending_data.key && event.pending_data.key.includes('manual')) {
      objectToUpdate = this.nodeData.settings.group1Timer[event.index];
      pendingSettings[getPendingKey(event.pending_data.comment)] = true;
    }

    return pendingSettings;
  }

  getMisterSettings(timer) {
    return {
      lightSP: timer.lightSP,
      mistACycleMin: timer.mistACycleMin,
      mistAOnSec: timer.mistAOnSec,
      mistBCycleMin: timer.mistBCycleMin,
      mistBOnSec: timer.mistBOnSec,
      mistIdleCycleMin: timer.mistIdleCycleMin,
      mistIdleOnSec: timer.mistIdleOnSec
    }
  }

  public getLightSetPoint() {
    if(this.nodeData && this.nodeData.settings) {
      let misterInfo = this.nodeData.settings.group1Mister ? this.nodeData.settings.group1Mister[1] : undefined;
      return misterInfo ? misterInfo.lightSP : "N/A"
    } else {
      return "N/A"
    }
  }
}
