import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { SitesService } from 'src/app/services/sites.service';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-node-table',
  templateUrl: './node-table.component.html',
  styleUrls: ['./node-table.component.scss'],
})
export class NodeTableComponent implements OnInit, OnChanges {
  @Input() site:any;
  @Input() nodes:[any];
  @Output()
  public choose: EventEmitter<any> = new EventEmitter<any>();
  private node;

  public weatherStations = [];
  public timers = [];
  public environmentalControls = [];

  public smallScreen = false;
  public allSingleZone = false;

  constructor(
    public sitesApiProvider: SitesService,
    public platform: Platform,
    public router: Router
  ) { }

  ngOnInit() {
    let controllers = this.nodes;
    if(controllers) {
      this.weatherStations = controllers.filter((c) => c.model && (c.model.includes('WR') || c.model.includes('WB')))
      this.environmentalControls = controllers.filter((c) => c.model && (c.model.includes('GHK') || c.model.includes('RWL')))
      this.timers = controllers.filter((c) => c.model && c.model.includes('TB'))
    }
    
    this.smallScreen = 700 >= this.platform.width();
    this.allSingleZone = this.environmentalControls.every((c) => c && c.status && c.status.zones === 1);
  }

  ngOnChanges() {
    this.smallScreen = 700 >= this.platform.width();
    let controllers = this.nodes;
    this.weatherStations = controllers.filter((c) => c.model && (c.model.includes('WR') || c.model.includes('WB')))
    this.environmentalControls = controllers.filter((c) => c.model && (c.model.includes('GHK') || c.model.includes('RWL')))
    this.timers = controllers.filter((c) => c.model && c.model.includes('TB'))
    this.smallScreen = 700 >= this.platform.width();
  }

  public select(id, hasStatus = true) {
    this.sitesApiProvider.activeNodeId = id;
    let controllers = this.site.controllers || this.nodes;
    this.node = controllers.find((n) => n.node_id === id);

    let width = this.platform.width();
    if(width >= 820) {
      this.choose.emit({node: this.node, has_status: hasStatus});
    } else {
      this.router.navigate(['status', this.node.node_id], {skipLocationChange: false, replaceUrl: false})
    }
  }

  inAlarm(controller) {
    return controller.status.alarms && controller.status.alarms.length > 0 && !controller.status.alarms.includes('NONE');
  }

  isOn(controller, key) {
    return controller && controller.status && controller.status[key] ? controller.status[key] === 'ON' : false;
  }

  getSetPoint(controller, zone) {
    return controller.settings[`sp${zone}`]
  }

  hasEnv() {
    return this.environmentalControls && this.environmentalControls.length > 0;
  }

  hasWb() {
    return this.weatherStations && this.weatherStations.length > 0
  }

  hasTb() {
    return this.timers && this.timers.length > 0
  }
}
