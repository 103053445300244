let style = `
  #site-usage-report {
    font-family: "Montserrat", sans-serif;
    --ion-font-family: "Montserrat", sans-serif;
    /** primary **/
    --ion-color-primary: #5CBF91;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #def2e9;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #408666;
    --ion-color-primary-tint: #7dcca7;
    --ion-color-secondary-blue: #5DA2BF;
    --ion-color-secondary-blue-rgb: 93, 162, 191;
    --ion-color-secondary-blue-tint: #a0c8d9;
    --ion-color-secondary-blue-dark: #4085a2;
    --ion-color-secondary-blue-light: #8dbdd2;
    --ion-color-gray: #F2F2F2;
    --ion-color-background-gray: #F2F2F2;
    --ion-color-background-gray-rgb: 30, 45, 91;
    --ion-color-background-gray-dark: #858585;
    --ion-color-outline-gray-tint: #c8c8c8;
    --ion-color-status-orange: #e65722;
    --ion-color-wind-rain-on: #f09941;
    --ion-color-wind-rain-on-rgb: 240, 153, 65;
    --ion-color-status-blue: #4183D7;
    /** secondary **/
    --ion-color-secondary: #cc9000;
    --ion-color-secondary-rgb: 209, 155, 26;
    --ion-color-secondary-contrast: #f0deb3;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #8f6500;
    --ion-color-secondary-tint: #e0bc66;
    --ion-color-secondary-light-7: #e19f00;
    --ion-color-secondary-light-5: #db9a00;
    /** tertiary **/
    --ion-color-tertiary: #a968bb;
    --ion-color-tertiary-rgb: 169,104,187;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0,0,0;
    --ion-color-tertiary-shade: #955ca5;
    --ion-color-tertiary-tint: #b277c2;
    /** success **/
    --ion-color-success: #10dc60;
    --ion-color-success-rgb: 16, 220, 96;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #0ec254;
    --ion-color-success-tint: #28e070;
    /** warning **/
    --ion-color-warning: #ffce00;
    --ion-color-warning-rgb: 255, 206, 0;
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 255, 255, 255;
    --ion-color-warning-shade: #e0b500;
    --ion-color-warning-tint: #ffd31a;
    /** danger **/
    --ion-color-danger: #f04141;
    --ion-color-danger-rgb: 245, 61, 61;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #d33939;
    --ion-color-danger-tint: #f25454;
    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 34, 34;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;
    /** medium **/
    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;
    /** light **/
    --ion-color-light: #f9f9f9;
    --ion-color-light-rgb: 244, 244, 244;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
    --ion-color-light-darkened: #cccccc;
    --ion-color-white: #ffffff;
    --ion-padding-start: 1em;
    --ion-padding-end: 1em;

    display: flex;
    flex-direction: row;
    padding: 0.5em;
    justify-content: space-between;
    border-bottom: 1px solid var(--ion-color-medium);
    width: 100%;
    flex: 1 0 auto;
    padding: 0.5em;
    flex-direction: column;
  }

  #site-usage-report .print { display: none; }
  #site-usage-report button { display: none; }

  #site-usage-report:last-of-type, #site-usage-report.subdiv {
    border-bottom: none;
  }

  #site-usage-report.report button {
    --background-color: var(--ion-color-secondary-blue);
    background-color: var(--ion-color-secondary-blue);
    color: var(--ion-color-light);
    padding: 0.5em;
  }

  #site-usage-report.subdiv {
    padding: 1em 0.5em;
    justify-content: space-between;
    display: flex;
    flex: 1 0 100%;
  }

  #site-usage-report.subdiv div {
    display: flex; flex: 1 0 auto;
    &:last-of-type { justify-content: flex-end; }
  }

  #site-usage-report label {
    margin-right: 0.5em;
  }

  #site-usage-report.section {
    flex-direction: column;
  }

  #site-usage-report ul.controllers {
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    width: 100%;
  }

  #site-usage-report ul.controllers > li {
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
    flex-wrap: wrap;
    border-top: 1px solid var(--ion-color-medium);
    padding: 0.5em 0;
  }

  #site-usage-report ul li .name {
    font-size: 1.25em;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  #site-usage-report ul li .data {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex: 1 0 auto;
  }

   #site-usage-report ul li app-run-times {
      display: flex;
    }

   #site-usage-report ul li .no-chart {
      width: 5%;
      display: flex;
      flex-wrap: wrap;
      word-wrap: break-word;
    }

    #site-usage-report ul li .chart {
      height: auto;
      max-height: 25em;
      width: 40%;
      display: flex;
      flex: 1 0 60%;
    }

    #site-usage-report ul li .chart.display-none {
      display: none;
      flex: 0;
    }

    #site-usage-report ul li .chart svg {
      display: flex;
      flex: 1 0 40%;
      width: 40%
    }

    #run-times-container {
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 1.5em 0 0.5em 0;
    }

    #run-times-container div {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }

    #run-times-container h6 {
      margin: 0 0 0 1em;
      font-size: 1.05em;
    }

    #run-times-container ul {
      list-style: none;
    }

    #run-times-container ul li {
      display: flex;
      flex-direction: row;
      flex: 1 0 auto;
      padding: 0.25em 0;
      justify-content: space-between;
    }

    #run-times-container ul li label {
      font-weight: 450;
    }

    #run-times-container ul li p {
      margin: 0 0.5em;
      padding: 0;
    }
`

export const ReportStyle = {
  get: () => { return style; }
}
