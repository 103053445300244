import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl, FormGroup, UntypedFormArray } from '@angular/forms';
import { SitesService } from '../services/sites.service';
import { SiteService } from '../services/site.service';
import { TimerGraphChartGenerator } from './timer-graph-generator'
import moment from 'moment';

@Component({
  selector: 'app-timer-graph',
  templateUrl: './timer-graph.component.html',
  styleUrls: ['./timer-graph.component.scss'],
})
export class TimerGraphComponent  implements OnInit {
  public timerGraphForm; 
  public run_times_by_node = {}
  public run_times = [];
  public currentNodeList;
  public view = 'table';
  public totals = {};
  private houses;
  public retrievingAverageInformation = false;

  @Input() site;
  
  constructor(
    private nodeService: SitesService,
    private siteService: SiteService
  ) { }

  ngOnInit() {
    this.currentNodeList = this.nodeService.getLatestList()
    this.houses = new UntypedFormArray(this.currentNodeList ? this.currentNodeList.map((node) => {
      return new UntypedFormControl({name: (node.api_name || node.node_name), node_id: node.node_id, selected: false})
    }): []);
    this.timerGraphForm = new UntypedFormGroup({
      start_date: new UntypedFormControl('', [Validators.required]),
      end_date: new UntypedFormControl('', [Validators.required]),
      houses: this.houses
    }, [this.dateLessThan('start_date', 'end_date')]);    
  }

  triggerEvent(e) {
    e.detail.value.forEach((controller) => {
      let houseIndex = this.houses.value.findIndex((h) => { return h.node_id === controller.node_id });      
      if(houseIndex != -1) {
        this.houses.at(houseIndex).setValue({node_id: controller.node_id, name: controller.name, selected: true})
      }
    })
  }

  dateLessThan(from: string, to: string) {
    return (group: FormGroup): {[key: string]: any} => {
      let f = group.controls[from];
      let t = group.controls[to];
      if (f && t && f.value && t.value && f.value.length > 0 && t.value.length > 0 && f.value > t.value) {
        return {
          dates: "Start date must be before end date"
        };
      }
      return {};
    }
  }

  getTimerLabel(timerKey) {
    return `Timer ${timerKey.split('')[1]}`
  }

  getName(id) {
    let found = this.currentNodeList.find((node) => node.node_id === id)
    return found ? (found.api_name || found.node_name) : this.nodeService.getNodeName(id);
  }
  
  changeDates() {
    let selectedControllers = this.timerGraphForm.value.houses.filter((h) => h.selected).map((h) => h.node_id)
    console.log(`selectedControllers: ${selectedControllers.length}`)
    this.siteService.listSites(this.site.cis_id).then((response) => {      
      let site = response.sites[0];      
      let controllerAveragePromises = site.controllers
        .filter((controller) => controller.model && (controller.model.includes("GHK") || controller.model.includes('RWL') && selectedControllers.includes(controller.node_id)))
        .map((controller) => {
          return new Promise((resolve) => {
              this.nodeService.getAverageInformation(controller.node_id, this.timerGraphForm.value.start_date, this.timerGraphForm.value.end_date)
                .then((run_times) => {
                  resolve({node_id: controller.node_id, run_times: run_times})
                })
                .catch((e) => {
                  resolve({});
                })
          });
      })      
      
      console.log(`get logs for ${controllerAveragePromises && controllerAveragePromises.length} controllers`)
      this.retrievingAverageInformation = true;
      Promise.all(controllerAveragePromises).then((averageInformation) => {
        try {
          averageInformation.forEach((info) => {   
            if(info.node_id && !this.totals[info.node_id]) {
              this.totals[info.node_id] = {t1: 0, t2: 0}
            }

            if(info && info.run_times && info.run_times.length) {
              let mapped_run_times = []
              info.run_times.forEach((run_times) => {
                this.totals[info.node_id].t1 = this.totals[info.node_id].t1 + run_times.run_times.t1;
                this.totals[info.node_id].t2 = this.totals[info.node_id].t2 + run_times.run_times.t2;
                mapped_run_times.push({...{archive_date: info.archive_date || info.date}, ...run_times})                
              })  
              
              this.run_times_by_node[info.node_id] = mapped_run_times.sort((a, b) => {
                let ad = a.archive_date ? a.archive_date : a.date
                let bd = b.archive_date ? b.archive_date : b.date
                if(moment(ad).isSame(moment(bd))) { return 0; }
                else if(moment(ad).isSameOrBefore(moment(bd))) { return -1; }
                else { return 1; }
              })
            } else if(info.archive_date || info.date) {
              this.run_times_by_node[info.node_id] = {archive_date: info.archive_date || info.date, run_times: {}}              
            }                   
          })        

          this.run_times = Object.keys(this.run_times_by_node)
          this.retrievingAverageInformation = false;
        } catch(e) {
          console.log(e)
        }
      })
      .catch((e) => {
        console.log(e)
      })
    }) 
  }

  seeTable() { 
    this.view = 'table'; 
  }

  getTotal(milliseconds) {
    return moment.duration(milliseconds).asMinutes().toFixed(2);
  }

  seeChart() { 
    this.view = 'chart'; 
    try {
      let info = this.run_times.reduce((all_data, node_id) => {
        this.run_times_by_node[node_id].forEach((entry) => {
          if(!all_data.subgroups.includes(entry.archive_date)) {
            all_data.subgroups.push(entry.archive_date)
          }
          
          all_data.data.push({
            group: node_id,
            date: entry.archive_date,
            t1: entry.run_times.t1 ? entry.run_times.t1 : 0,
            t2: entry.run_times.t2 ? entry.run_times.t2 : 0
          });          
        })

        return all_data;
      }, {subgroups: [], data: [], totals: {}})  
      
      let chartGenerator = TimerGraphChartGenerator.chartData("#timer-graph-chart", 800, 600)
      chartGenerator.draw(this.run_times, info.subgroups, info.data)
    } catch(e) {
      console.log(e);
    }
  }

  getDownloadLinkInfo() {  
    let csv_format = this.run_times.reduce((agg, key) => {
      agg.push(`${this.getName(key)},,`)
      this.run_times_by_node[key].forEach((data) => {
        let timer1OnAndOffTimes = data.run_times.t1_onOffTimes && data.run_times.t1_onOffTimes.length > 0 ? data.run_times.t1_onOffTimes.map((times) => {
          return `On at ${moment(times.on).format("HH:mm")}, off at ${moment(times.off).format("HH:mm")}`
        }) : "No on and off times recorded";
        
        // console.log(`${key} - T1 On/Off: ${JSON.stringify(data.run_times.t1_onOffTimes)}, T2 On/Off: ${JSON.stringify(data.run_times.t2_onOffTimes)}`)
        let timer2OnAndOffTimes = data.run_times.t2_onOffTimes && data.run_times.t2_onOffTimes.length > 0 ? data.run_times.t2_onOffTimes.map((times) => {
          return `On at ${moment(times.on).format("HH:mm")}, off at ${moment(times.off).format("HH:mm")}`
        }) : ["No on and off times recorded"];
        data.onOffTimes = {t1: timer1OnAndOffTimes, t2: timer2OnAndOffTimes}
        agg.push(`${moment(data.archive_date || data.date).format('L')},${moment.duration(data.run_times.t1).asMinutes()},${timer1OnAndOffTimes},${moment.duration(data.run_times.t2).asMinutes()},${timer2OnAndOffTimes}`)
      })
      agg.push(`Total,${moment.duration(this.totals[key].t1).asMinutes()},${moment.duration(this.totals[key].t2).asMinutes()},`)
      agg.push(`,,,,,`)
      return agg;
    }, ["Controller ID,Timer 1,Timer 1 On & Off Times,Timer 2,Timer 2 On & Off Times"])
    return `data:text/csv;charset=utf-8,${csv_format.join('\n')}`;    
  }
}
