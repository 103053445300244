<div [formGroup]="ventForm" class="vent-form" *ngIf="ventForm">
  <ion-item *ngIf="current_page && current_page === 'name_mode'">
    <ion-label class="ion-text-wrap">What would you like to name this vent?</ion-label>
    <ion-input type="text" formControlName="vent_name" [value]="ventForm && ventForm.value ? ventForm.value.vent_name : ''"></ion-input>
  </ion-item>
  <ion-item *ngIf="current_page && current_page === 'name_mode'">
    <ion-label class="ion-text-wrap">What mode would you like this vent to run as (PID or 5-Step are the only options in guided programming)? </ion-label>
    <ion-select formControlName="mode">
      <ion-select-option value="PID">PID</ion-select-option>
      <ion-select-option value="5-STEP">5-Step</ion-select-option>
      <ion-select-option value="OFF">OFF</ion-select-option>
    </ion-select>
  </ion-item>
  <ion-item *ngIf="current_page ">
    <ion-label class="ion-text-wrap">What temperature (˚F) do you want the vent to start opening? (Day set point is {{dayTemp}})</ion-label>
    <ion-input type="number" min="32" max="130" formControlName="start_opening"></ion-input>
  </ion-item>
  <ion-item *ngIf="current_page ">
    <ion-label class="ion-text-wrap">How many seconds does it take to completely open the vent?</ion-label>
    <ion-input type="number" min="0" max="999" formControlName="openTime"></ion-input>
  </ion-item>
  <ion-item class="vertical" *ngIf="ventForm.value && current_page ">
    <ion-label class="ion-text-wrap">
      Enter the percent you want the vent open for the following percentages
    </ion-label>
    <ion-list>
      <ion-item>
        <ion-label>Percent 1 ({{dayTemp && vent_step ? dayTemp + (vent_step * 1) : dayTemp}} ˚F)</ion-label>
        <!-- <ion-label *ngIf="!ventForm.value.start_opening || !ventForm.value.step">&nbsp;</ion-label> -->
        <ion-input type="number" min="0" max="99" formControlName="p1"></ion-input>
      </ion-item>
      <ion-item *ngIf="ventForm.value && ventForm.value.mode !== 'PID'">
        <ion-label>Percent 2 ({{dayTemp && vent_step ? dayTemp + (vent_step * 2) : dayTemp}} ˚F)</ion-label>
        <!-- <ion-label *ngIf="!ventForm.value.start_opening || !ventForm.value.step">&nbsp;</ion-label> -->
        <ion-input type="number" min="0" max="99" formControlName="p2"></ion-input>
      </ion-item>
      <ion-item *ngIf="ventForm.value && ventForm.value.mode !== 'PID'">
        <ion-label>Percent 3 ({{dayTemp && vent_step ? dayTemp + (vent_step * 3) : dayTemp}} ˚F)</ion-label>
        <!-- <ion-label *ngIf="!ventForm.value.start_opening || !ventForm.value.step">&nbsp;</ion-label> -->
        <ion-input type="number" min="0" max="99" formControlName="p3"></ion-input>
      </ion-item>
      <ion-item *ngIf="ventForm.value && ventForm.value.mode !== 'PID'">
        <ion-label>Percent 4 ({{dayTemp && vent_step ? dayTemp + (vent_step * 4) : dayTemp}} ˚F)</ion-label><!-- <ion-label *ngIf="!ventForm.value.start_opening || !ventForm.value.step">&nbsp;</ion-label> -->
        <ion-input type="number" min="0" max="99" formControlName="p4"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label>Percent 5 ({{dayTemp && vent_step ? dayTemp + (vent_step * 5) : dayTemp}} ˚F)</ion-label><!-- <ion-label *ngIf="!ventForm.value.start_opening || !ventForm.value.step">&nbsp;</ion-label> -->
        <ion-input type="number" min="0" max="99" formControlName="p5"></ion-input>
      </ion-item>
    </ion-list>
  </ion-item>
  <ion-item *ngIf="current_page">
    <ion-label class="ion-text-wrap">Would you like to override the following vents when the respective alarm is active?</ion-label>
    <ion-list>
      <ion-item>
        <ion-label>Rain Alarm</ion-label>
        <ion-select formControlName="rainOR">
          <ion-select-option value="0">No</ion-select-option>
          <ion-select-option value="1">Yes</ion-select-option>
        </ion-select>
      </ion-item>  
      <ion-item>
        <ion-label>Wind 1 Alarm</ion-label>
        <ion-select formControlName="wind1OR">
          <ion-select-option value="0">No</ion-select-option>
          <ion-select-option value="1">Yes</ion-select-option>
        </ion-select>
      </ion-item>  
      <ion-item>
        <ion-label>Wind 2 Alarm</ion-label>
        <ion-select formControlName="wind2OR">
          <ion-select-option value="0">No</ion-select-option>
          <ion-select-option value="1">Yes</ion-select-option>
        </ion-select>
      </ion-item>  
    </ion-list>
  </ion-item>

  <div *ngIf="!valid()">
    This field is invalid. Please correct before you can go to the next step.
  </div>
</div>
