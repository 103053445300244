import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-logo',
  templateUrl: './main-logo.component.html',
  styleUrls: ['./main-logo.component.scss'],
})
export class MainLogoComponent implements OnInit {
  public logoUrl = "assets/imgs/headgrower_logo-02.png"
  constructor() { }

  ngOnInit() {}

}
