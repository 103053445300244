<div>
  <ion-item>
    <ion-label>Sites <span *ngIf="sites">({{sites && sites.length}})</span></ion-label>
    <ionic-selectable
        *ngIf="sites"
        item-content
        [(ngModel)]="selectedSite"
        [items]="sites"
        itemValueField="name"
        itemTextField="name"
        [canSearch]="true"
        (onChange)="siteChange($event)"
        placeholder="Select Site"
      >
      </ionic-selectable>
  </ion-item>
  <ion-card *ngIf="selectedSite">
    <ion-card-header>
      <ion-card-title>{{selectedSite.name}}</ion-card-title>
      <ion-card-subtitle>{{selectedSite.cis_id}}</ion-card-subtitle>
    </ion-card-header>
  
    <ion-card-content>
      <ion-list>
        <ion-item><ion-label>Owner:</ion-label><ion-text>{{selectedSite.owner_email}}</ion-text></ion-item>
        <ion-item><ion-label>Number Controllers</ion-label><ion-text>{{selectedSite.controllers && selectedSite.controllers.length}}</ion-text></ion-item>
      </ion-list>
    </ion-card-content>

    <ion-button fill="clear" (click)="view = 'owner';">Change Owner</ion-button>
    <ion-button fill="clear" (click)="view = 'highLow';">Resend High/Low Report</ion-button>
    <ion-button fill="clear" (click)="view = 'usage';">Usage Report</ion-button>
  </ion-card>

  <div>
    <app-high-low-report-admin [showSection]="selectedSite && view === 'highLow'" [site]="selectedSite"
      *ngIf="selectedSite && view === 'highLow'"></app-high-low-report-admin>
    
    <app-site-usage-report [showSection]="selectedSite && view === 'usage'" [siteId]="selectedSite.cis_id"
      *ngIf="selectedSite && view === 'usage'" [adminPage]="true"></app-site-usage-report>
    
    <app-change-site-owner *ngIf="selectedSite && view === 'owner'" 
      [selectedCis]="selectedSite" [cisList]="sites"></app-change-site-owner>
  </div>
</div>