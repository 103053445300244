import { Component, OnInit, Input } from '@angular/core';
import { SitesService } from 'src/app/services/sites.service';
import { ToastController, AlertController } from '@ionic/angular';

@Component({
  selector: 'app-controller-users',
  templateUrl: './controller-users.component.html',
  styleUrls: ['./controller-users.component.scss'],
})
export class ControllerUsersComponent implements OnInit {
  @Input() node = { node_id: undefined, node_name: '', api_name: '' };
  @Input() current;
  public userList = [];
  public data;
  public auth;
  public userListLoaded: boolean;
  public updated_role;
  public editing;
  public adding_user;
  public new_user;
  public userIsAdmin = false;

  constructor(public sitesService: SitesService,
              public toastCtrl: ToastController,
              public alertController: AlertController) { }

  ngOnInit() {
    if(this.node) {
      this.sitesService.getSingleNodeUserList(this.node.node_id)
        .then((data) => this.onGetListSuccess(data));
    }
  }

  private onGetListSuccess(data){
    this.auth = data.auth ? data.auth : this.auth;
    this.data = data;
    this.userList = data.node ? data.node.users : [];
    if (this.userList) {
      this.userListLoaded = true;
    }
    let currentUser = this.userList.find((u) => {
      return u.email === this.current
    })
    if(currentUser && currentUser.role >= 3) { this.userIsAdmin = true; }
  }

  addUser() {
    this.adding_user = true;
    this.new_user = {email: "", role: 1};
  }

  async removeUser(user) {
    const alert = await this.alertController.create({
      header: 'Remove User',
      message: 'This user will no longer be able to view the controller. <strong>Are you sure?</strong>',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Remove',
          handler: () => {
            console.log('Confirm remove user');
            this.sitesService.removeUser(this.node.node_id, user.email)
                              .then((data) => {
                                this.onGetListSuccess(data);
                                this.showMessage(`User removed from ${this.node.api_name || this.node.node_name}`, true);
                              })
                              .catch((error) => {
                                if(error) {
                                  this.showMessage(error.message, false)
                                } else {
                                  this.showMessage("User could not be removed.", false)
                                }
                              })
          }
        }
      ]
    });

    await alert.present();
  }

  saveNewUser() {
    this.sitesService.shareWithNewUser(this.node.node_id, this.new_user)
        .then((data) => {
          this.onGetListSuccess(data);
          this.adding_user = false;
          this.new_user = undefined;
          this.showMessage(`User added to ${this.node.api_name || this.node.node_name}`, true);
        })
        .catch((error) => {
          if(error) {
            this.showMessage(error.message, false)
          } else {
            this.showMessage("User could not be added.", false);
          }
        });
  }

  allRoles() {
    return [
      {val: 3, role_name: "Controller Admin"},
      {val: 2, role_name: "Edit Access"},
      {val: 1, role_name: "View Only"}
    ]
  }

  getRole(roleVal) {
    switch(roleVal) {
      case 4:
        return "Owner";
        break;
      case 2:
        return "Edit Access";
        break;
      case 3:
        return "Admin Access";
        break;
      default:
        return "Viewer Access";
    }
  }

  saveRoleChange(user, new_role) {
    const userToUpdate = this.data.node.users.find((u) => u.email === user.email);
    userToUpdate.role = parseInt(new_role);
    this.editing = undefined;
    this.sitesService.saveRoleUpdate(this.data.node.node_id, user.email, new_role)
        .then((data) => {
          this.onGetListSuccess(data)
          this.editing = false;
          this.showMessage(`The user role has been updated.`, true);
        });
  }

  async showMessage(msg, success) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'top',
      color: success ? 'medium' : 'danger'
    });

    toast.present();
  }

  getAlerts(alertObject) {
    let alert_type_to_name = {
      push: "Push Notification",
      status: "Controller Status (on/off) Changes",
      alarms: "Controller Temperature Alarms",
      settings: "Settings Changes",
      wind: "Wind Alarms",
      rain: "Rain Alarms",
      windAndRain: "Wind and Rain Alarms",
      settingsFailed: "Settings Change Failed"
    }
    return `User has the following alerts configured: ${Object.keys(alertObject).map((k) => alert_type_to_name[k]).join(', ')}`;
  }
}
