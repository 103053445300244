<table *ngIf="hasWb()"
    [style.height]="hasTb() && hasEnv() ? '15%' : ((hasTb() || hasEnv()) ? '20%' : '100%')">
    <thead>
        <tr>
            <th>Name</th>
            <th>{{smallScreen ? 'Stat' : 'Status'}}</th>
            <th>{{smallScreen ? 'Temp' : 'Outside Temp'}}</th>
            <th>{{smallScreen ? 'Hum' : 'Humidity'}}</th>
            <th>Light</th>
            <th>{{smallScreen ? 'Rain' : 'Rain Alarm'}}</th>
            <th>{{smallScreen ? 'W1' : 'Wind 1 Alarm'}}</th>
            <th>{{smallScreen ? 'W2' : 'Wind 2 Alarm'}}</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let controller of weatherStations" [class.offline]="!controller.online && controller.in_use">
            <td class="name" (click)="select(controller.node_id)">{{controller.api_name || controller.node_name}}</td>
            <td class="status alarm" *ngIf="!controller.online && controller.in_use">{{smallScreen ? 'OFF': 'OFFLINE'}}</td>
            <td class="status" *ngIf="controller.online && controller.in_use">{{controller.status && controller.status.time}}</td>
            <td>{{controller.status && controller.status.outsideTemp}}</td>
            <td>{{controller.status && controller.status.humidity || '0'}}%</td>
            <td>{{controller.status && controller.status.dli}}</td>
            <td [class.alarm]="controller.status && controller.status.rain && controller.status.rain.alarm">
                {{controller.status && controller.status.rain && controller.status.rain.alarm ? 'ACTIVE' : 'OFF'}}</td>
            <td [class.alarm]="controller.status && controller.status.wind && controller.status.wind.alarm">
                {{controller.status && controller.status.wind && controller.status.wind.alarm ? 'ACTIVE' : 'OFF'}}</td>
            <td [class.alarm]="controller.status && controller.status.wind2 && controller.status.wind2.alarm">
                {{controller.status && controller.status.wind2 && controller.status.wind2.alarm ? 'ACTIVE' : 'OFF'}}</td>
        </tr>
    </tbody>
  </table>
<table *ngIf="hasEnv()"
    [style.height]="hasWb() && hasTb() ? '41%' : ((hasWb() || hasTb()) ? '50%' : '100%')">
  <thead>
      <tr>
          <th>Name</th>
          <th>{{smallScreen ? 'Stat' : 'Status'}}</th>
          <th>{{smallScreen ? 'Z1' : 'Zone 1'}}</th>
          <th>{{smallScreen ? 'SP1' : (allSingleZone ? 'Set Point' : 'Set Point 1')}}</th>
          <th *ngIf="!allSingleZone">{{smallScreen ? 'Z2' : 'Zone 2'}}</th>
          <th *ngIf="!allSingleZone">{{smallScreen ? 'SP2' : 'Set Point 2'}}</th>
          <th>{{smallScreen ? 'Hum' : 'Humidity'}}</th>
          <th>{{smallScreen ? 'H1' : 'Heat 1'}}</th>
          <th>{{smallScreen ? 'C1' : 'Cool 1'}}</th>
          <th>{{smallScreen ? 'C2' : 'Cool 2'}}</th>
          <th>{{smallScreen ? 'V-A' : 'Vent A'}}</th>
          <th>{{smallScreen ? 'V-C' : 'Vent C'}}</th>
          <th>{{smallScreen ? 'H2' : 'Heat 2'}}</th>
          <th>{{smallScreen ? 'C3' : 'Cool 3'}}</th>  
          <th>{{smallScreen ? 'C4' : 'Cool 4'}}</th>
          <th>{{smallScreen ? 'V-B' : 'Vent B'}}</th>         
          <th>{{smallScreen ? 'V-D' : 'Vent D'}}</th>
          <th>{{smallScreen ? 'T1' : 'Timer 1'}}</th>
          <th>{{smallScreen ? 'T2' : 'Timer 2'}}</th>
          <th>{{smallScreen ? 'H3' : 'Heat 3'}}</th>          
      </tr>
  </thead>
  <tbody>
      <tr *ngFor="let controller of environmentalControls" [class.offline]="!controller.online && controller.in_use">
          <td class="name env" (click)="select(controller.node_id)">{{controller.api_name || controller.node_name}}</td>
          <td class="status alarm" *ngIf="!controller.online && controller.in_use">{{smallScreen ? 'OFF': 'OFFLINE'}}</td>
          <td class="status" *ngIf="controller.online && controller.in_use" [class.alarm]="inAlarm(controller)">{{inAlarm(controller) ? controller.status.alarms.join(", ") : controller.status.stage}}</td>
          <td>{{controller.status.ct1}}</td>
          <td>{{getSetPoint(controller, '1')}}</td>
          <td *ngIf="controller.status.zones > 1 && !allSingleZone">{{controller.status.ct2}}</td>
          <td *ngIf="controller.status.zones === 1 && !allSingleZone">---</td>
          <td *ngIf="controller.status.zones > 1 && !allSingleZone">{{getSetPoint(controller, '2')}}</td>
          <td *ngIf="controller.status.zones === 1 && !allSingleZone">---</td>
          <td>{{controller.status.hum}}</td>
          <td class="output" [class.on]="isOn(controller, 'h1')">{{controller.status.h1}}</td>
          <td class="output" [class.on]="isOn(controller, 'c1')" *ngIf="controller.model && controller.model.includes('GHK')">{{controller.status.c1}}</td>
          <td *ngIf="controller.model && !controller.model.includes('GHK')">---</td>
          <td class="output" [class.on]="isOn(controller, 'c2')" *ngIf="controller.model && controller.model.includes('GHK')">{{controller.status.c2}}</td>
          <td *ngIf="controller.model && !controller.model.includes('GHK')">---</td>
          <td *ngIf="controller.model && controller.model.includes('RWL')">{{(controller.settings.vent && controller.settings.vent[0]) ? controller.settings.vent[0].man : controller.settings.v1man}}%</td>
          <td *ngIf="controller.model && !controller.model.includes('RWL')">{{(controller.settings.vent && controller.settings.vent[2]) ? controller.settings.vent[2].man : '0'}}%</td>
          <td *ngIf="controller.model && controller.model.includes('CB-RWL')">{{(controller.settings.vent && controller.settings.vent[3] ? controller.settings.vent[3].man : '0')}}%</td>
          <td *ngIf="controller.model && !controller.model.includes('CB-RWL')">---</td>
          <td class="output" [class.on]="isOn(controller, 'h2')">{{controller.status.h2}}</td>
          <td class="output" [class.on]="isOn(controller, 'c3')" *ngIf="controller.model && controller.model.includes('GHK')">{{controller.status.c3}}</td>
          <td *ngIf="controller.model && !controller.model.includes('GHK')">---</td>
          <td class="output" [class.on]="isOn(controller, 'c4')" *ngIf="controller.model && controller.model.includes('GHK')">{{controller.status.c4}}</td>
          <td *ngIf="controller.model && !controller.model.includes('GHK')">---</td>
          <td *ngIf="controller.model && controller.model.includes('RWL')">{{(controller.settings.vent && controller.settings.vent[1]) ? controller.settings.vent[1].man : controller.settings.v2man}}%</td>
          <td *ngIf="controller.model && !controller.model.includes('RWL')">{{(controller.settings.vent && controller.settings.vent[3]) ? controller.settings.vent[3].man : '0'}}%</td>
          <td *ngIf="controller.model && controller.model.includes('CB-RWL')">{{(controller.settings.vent && controller.settings.vent[3] ? controller.settings.vent[2].man : '0')}}%</td>
          <td *ngIf="controller.model && !controller.model.includes('CB-RWL')">---</td>
          <td>{{controller.status.timer}}</td>
          <td *ngIf="controller.model && controller.model.includes('CB')">{{controller.status.timer2}}</td>
          <td *ngIf="controller.model && !controller.model.includes('CB')">---</td>
          <td>{{controller.status.h3 ? controller.status.h3 : '---'}}</td>          
      </tr>
  </tbody>
</table>
<table *ngIf="hasTb()"
    [style.height]="hasWb() && hasEnv() ? '41%' : ((hasWb() || hasEnv()) ? '50%' : '100%')">
    <thead>
        <tr>
            <th>Name</th>
            <th>{{smallScreen ? 'Stat' : 'Status'}}</th>
            <th>{{smallScreen ? 'T1' : 'Timer 1'}}</th>
            <th>{{smallScreen ? 'T2' : 'Timer 2'}}</th>
            <th>{{smallScreen ? 'T3' : 'Timer 3'}}</th>
            <th>{{smallScreen ? 'T4' : 'Timer 4'}}</th>
            <th>{{smallScreen ? 'T5' : 'Timer 5'}}</th>
            <th>{{smallScreen ? 'T6' : 'Timer 6'}}</th>
            <th>{{smallScreen ? 'T7' : 'Timer 7'}}</th>
            <th>{{smallScreen ? 'T8' : 'Timer 8'}}</th>
            <th>{{smallScreen ? 'T9' : 'Timer 9'}}</th>
            <th>{{smallScreen ? 'T10' : 'Timer 10'}}</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let controller of timers" [class.offline]="!controller.online && controller.in_use">
            <td class="name" (click)="select(controller.node_id)">{{controller.api_name || controller.node_name}}</td>
            <td class="status alarm" *ngIf="!controller.online && controller.in_use">{{smallScreen ? 'OFF': 'OFFLINE'}}</td>    
            <td class="status" *ngIf="controller.online && controller.in_use">{{controller.status && controller.status.time}}</td>        
            <td>{{controller.status.t1}}</td>
            <td>{{controller.status.t2}}</td>
            <td>{{controller.status.t3}}</td>
            <td>{{controller.status.t4}}</td>
            <td>{{controller.status.t5}}</td>
            <td>{{controller.status.t6}}</td>
            <td>{{controller.status.t7}}</td>
            <td>{{controller.status.t8}}</td>
            <td>{{controller.status.t9}}</td>
            <td>{{controller.status.t10}}</td>       
        </tr>
    </tbody>
  </table>