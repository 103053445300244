<ion-header>
  <ion-toolbar class="settings-header">
    <ion-back-button></ion-back-button>
    <div>
      <span class="settings-name">{{settingInfo && settingInfo.desc}}</span>
      <span class="settings-site-house">{{siteName}} - {{houseName}}</span>
    </div>
  </ion-toolbar>
</ion-header>

<div class="content">
  <div class="pending" *ngIf="isPending">
    Currently syncing with the controller
  </div>

  <!--Radio buttons for option types (heat and cool mode, prop outs)-->
  <div *ngIf="inputInvalid"
    class="invalid-input">
    <p>Invalid Input</p>
    <p *ngFor="let invalidMessage of invalidMessages">{{invalidMessage}}</p>
  </div>

  <form [formGroup]="programForm">
    <!-- Program settings -->
      <!-- program time/temperature graph -->
      <div class="setting-item settings-graph" #graph>
          <div>
            <span float-left style="width:25%">0:00</span>
            <span float-left style="width:50%; text-align:center">Time of Day</span>
            <span float-right>24:00</span>
          </div>
          <div>
            <div float-left class="settings-graph-box settings-graph-box-bdr background-night" [style.width.%]="difStartMin/1440*90">
              <hr class="h-line" [style.margin-top.px]="(133-niteTemp)">
            </div>
            <div *ngIf="(dayStartMin && difStartMin)" float-left class="settings-graph-box settings-graph-box-bdr background-dif" [style.width.%]="(dayStartMin-difStartMin)/1440*90">
              <hr class="h-line" [style.margin-top.px]="(133-difTemp)">
            </div>
            <div *ngIf="niteStartMin && dayStartMin" float-left class="settings-graph-box settings-graph-box-bdr background-day" [style.width.%]="(niteStartMin-dayStartMin)/1440*90">
              <hr class="h-line" [style.margin-top.px]="(133-dayTemp)">
            </div>
            <div *ngIf="niteStartMin" float-left class="settings-graph-box settings-graph-box-bdr background-night" [style.width.%]="(1440-niteStartMin)/1440*90">
              <hr class="h-line" [style.margin-top.px]="(133-niteTemp)">
            </div>
          </div>
        </div>

    <!-- program temperature settings list -->
    <div class="setting-note">Note: Time settings apply to Zone 1 and Zone 2. Times should be entered in 24 hour format.</div>
    <div class="setting-table">
      <!-- dif temperature -->
      <div class="dif tr setting-header">
        <div class="td">
          <span>DIF</span>
          <span>Current</span>
        </div>
        <div class="td">
          <span>Set Point ({{tUnits}})</span>
          <span [class.strikethrough]="edited && edited.difTemp && current">{{current ? current.difTemp : ''}}{{tUnits}}</span>
          <span class="new" *ngIf="edited && edited.difTemp">New: {{difTemp}}{{tUnits}}</span>
        </div>
        <div class="td">
          <span>Start Time</span>
          <span *ngIf="difStartMin" [class.strikethrough]="edited && edited.difStart">{{timeToStr(current.difStartMin)}}</span>
          <span class="new" *ngIf="edited && edited.difStart">New: {{timeToStr(difStartMin)}}</span>
        </div>
      </div>
      <div class="dif tr" *ngIf="canEdit" #dif>
        <!-- <ion-range class="setting-range" min="32" max="131" [(ngModel)]="difTemp" color="secondary-blue">
          <ion-label range-left>32</ion-label>
          <ion-label range-right>131</ion-label>
        </ion-range> -->
        <div class="td">&nbsp;</div>
        <div class="td">
          <ion-icon (click)="change('difTemp', -1, 'dif')" name="remove-circle-outline" color="secondary"></ion-icon>
          <input
            min="32" max="131"
            formControlName="difTemp"
            type="text" inputmode="tel"
            pattern="[0-9]*"
            select-all
            (click)="scroll('dif')"
            (mouseleave)="saveSetting('dif')"
          />
          <ion-icon (click)="change('difTemp', 1, 'dif')" name="add-circle-outline" color="secondary"></ion-icon>
        </div>
        <div class="td">
          <input
            min="0:00" max="dayStart"
            formControlName="difStart"
            type="text" required inputmode="tel"
            pattern="[0-9]{1,2}:[0-9]{2}"
            (keyup)="difStartMin = timeStrToMinutes($event.target.value); reformatTime($event, 'difStart');"
            (mouseleave)="saveSetting('dif');"
            (click)="scroll('dif')"
          />
        </div>
      </div>

      <div class="day tr setting-header" #day>
        <div class="td">
          <span>DAY</span>
          <span>Current</span>
        </div>
        <div class="td">
          <span>Set Point ({{tUnits}})</span>
          <span [class.strikethrough]="edited && edited.dayTemp">{{current ? current.dayTemp : ''}}{{tUnits}}</span>
          <span class="new" *ngIf="edited && edited.dayTemp">New: {{dayTemp}}{{tUnits}}</span>
        </div>
        <div class="td">
          <span>Start Time</span>
          <span [class.strikethrough]="edited && edited.dayStart">{{current ? timeToStr(current.dayStartMin) : '0:00'}}</span>
          <span class="new" *ngIf="edited && edited.dayStart">New: {{dayStartMin ? timeToStr(dayStartMin) : '0:00'}}</span>
        </div>
      </div>
      <div class="day tr" *ngIf="canEdit">
        <!-- <ion-range class="setting-range" min="32" max="131" [(ngModel)]="difTemp" color="secondary-blue">
          <ion-label range-left>32</ion-label>
          <ion-label range-right>131</ion-label>
        </ion-range> -->
        <div class="td">&nbsp;</div>
        <div class="td">
          <ion-icon (click)="change('dayTemp', -1, 'day')" name="remove-circle-outline" color="secondary"></ion-icon>
          <input
            min="32" max="131"
            formControlName="dayTemp"
            type="tel" inputmode="tel"
            pattern="[0-9]*"
            select-all
            (click)="scroll('day')"
            (mouseleave)="saveSetting('day')"
          />
          <ion-icon (click)="change('dayTemp', 1, 'day')" name="add-circle-outline" color="secondary"></ion-icon>
        </div>
        <div class="td">
          <input
            min="difStart" max="niteStart"
            formControlName="dayStart"
            type="text" required inputmode="tel"
            pattern="[0-9]{1,2}:[0-9]{2}"
            (keyup)="dayStartMin = timeStrToMinutes($event.target.value); reformatTime($event, 'dayStart');"
            (mouseleave)="saveSetting('day');"
            (click)="scroll('day')"
          />
        </div>
      </div>

      <div class="nite setting-header tr" #nite>
        <div class="td">
          <span>NITE</span>
          <span class="current">Current</span>
        </div>
        <div class="td">
          <span>Set Point ({{tUnits}})</span>
          <span class="current" [class.strikethrough]="edited && edited.niteTemp">{{current ? current.niteTemp : ''}}{{tUnits}}</span>
          <span class="new" *ngIf="edited && edited.niteTemp">New: {{niteTemp}}{{tUnits}}</span>
        </div>
        <div class="td">
          <span>Start Time</span>
          <span class="current" [class.strikethrough]="edited && edited.niteStart">{{current ? timeToStr(current.niteStartMin) : '0:00'}}</span>
          <span class="new" *ngIf="edited && edited.niteStart">New: {{niteStartMin ? timeToStr(niteStartMin): '0:00'}}</span>
        </div>
      </div>
      <div class="nite tr" *ngIf='canEdit'>
        <!-- <ion-range class="setting-range" min="32" max="131" [(ngModel)]="difTemp" color="secondary-blue">
          <ion-label range-left>32</ion-label>
          <ion-label range-right>131</ion-label>
        </ion-range> -->
        <div class="td">&nbsp;</div>
        <div class="td">
          <ion-icon (click)="change('niteTemp', -1, 'nite')" name="remove-circle-outline" color="secondary"></ion-icon>
          <input
            min="32" max="131"
            formControlName="niteTemp"
            type="tel" inputmode="tel"
            pattern="[0-9]*"
            select-all
            (click)="scroll('nite')"
            (mouseleave)="saveSetting('nite')"
          />
          <ion-icon (click)="change('niteTemp', 1, 'nite')" name="add-circle-outline" color="secondary"></ion-icon>
        </div>
        <div class="td">
          <input
            min="dayStart" max="24:00"
            formControlName="niteStart"
            type="text" required inputmode="tel"
            pattern="[0-9]{1,2}:[0-9]{2}"
            (keyup)="niteStartMin = timeStrToMinutes($event.target.value); reformatTime($event, 'niteStart');"
            (mouseleave)="saveSetting('nite')"
            (click)="scroll('nite')"
          />
        </div>
      </div>
    </div>

    <div class="setting-ion-item comment-box" *ngIf="canEdit">
        <label>Comment<sup>*</sup></label>
        <input
          formControlName="comment"
          [disabled]='!canEdit'
          (focus)="scroll()"
        />
    </div>
    <p *ngIf="canEdit"><sup>*</sup>Add a message about this change</p>

    <!-- <div *ngIf="canEdit && (difEdit || dayEdit || niteEdit)" class="save-disabled">
      <span>Please finish editing and then press the checkmark to confirm or exit out of the change (X).</span>
    </div> -->
    <div *ngIf="!canEdit" class="save-disabled"><span>Save disabled; You don't have edit permissions.</span></div>
    <div *ngIf="inputInvalid" class="save-disabled"><i>Save disabled; Please fix errors before saving.</i></div>
    <div class="buttons saveOrCancel">
      <ion-button class="settings-buttons"
        [class.primary]="canEdit"
        [class.secondary]="!canEdit" (click)="cancel()">
        Cancel
      </ion-button>
      <ion-icon *ngIf="help" name="help-circle-outline" (click)="pressHelp()"></ion-icon>
      <ion-button [disabled]="!canEdit || inputInvalid"
        [class.secondary]="!canEdit"
        [class.primary]="canEdit" class="settings-buttons" (click)="save()">
        Save
      </ion-button>
    </div>
  </form>
</div>
