import {TimeFormat} from '../status-wind-rain/reformat-time';

const settings = {
  "settings.wind.activation.setPoint": {
    desc: "Activation Set Point", type: "text",
    pattern: "[0-9]*",
    inputmode:"tel",
    error: "Sorry only numbers are allowed",
    requestKey: "windActSp",
    climateBoss: "windActSpH1",
    pending: false},
  "settings.wind.activation.loSetPoint": {
    desc: "Low Activation Set Point", type: "text",
    pattern: "[0-9]*",
    inputmode:"tel",
    error: "Sorry only numbers are allowed",
    requestKey: "windActSpL1",
    pending: false},
  "settings.wind.activation.delay": {
    desc: "Activation Delay (mm:ss)", type: "text",
    pattern: "[0-9]{2}:[0-9]{2}",
    inputmode:"tel",
    units: "mm:ss",
    reformat: TimeFormat.reformatString,
    error: "Please enter only numbers in mm:ss format",
    requestKey: "windActTime",
    pending: false},
  "settings.wind.dropOff.setPoint": {
    desc: "Drop Off Set Point", type: "text",
    pattern: "[0-9]*",
    inputmode:"tel",
    error: "Sorry only numbers are allowed",
    requestKey: "windDropSp", pending: false},
  "settings.wind.dropOff.delay": {
    desc: "Drop Off Delay (mm:ss)", type: "text",
    pattern: "[0-9]{2}:[0-9]{2}",
    inputmode:"tel",
    units: "mm:ss",
    error: "Please enter only numbers in mm:ss format",
    reformat: TimeFormat.reformatString,
    requestKey: "windDropTime", pending: false },
  "settings.wind2.activation.setPoint": {
    desc: "Activation Set Point", type: "text",
    pattern: "[0-9]*",
    inputmode:"tel",
    error: "Sorry only numbers are allowed",
    requestKey: "windActSpH2",
    pending: false},
  "settings.wind2.activation.loSetPoint": {
    desc: "Low Activation Set Point", type: "text",
    pattern: "[0-9]*",
    inputmode:"tel",
    error: "Sorry only numbers are allowed",
    requestKey: "windActSpL2",
    pending: false},
  "settings.wind2.activation.delay": {
    desc: "Activation Delay (mm:ss)", type: "text",
    pattern: "[0-9]{2}:[0-9]{2}",
    inputmode:"tel",
    units: "mm:ss",
    reformat: TimeFormat.reformatString,
    error: "Please enter only numbers in mm:ss format",
    requestKey: "windActTime",
    pending: false},
  "status.override.activation": { pending: false },
  "status.override.cancel": {pending: false},
  "settings.windDir1": {pending: false, requestKey: "windDir1"},
  "settings.windDir2": {pending: false, requestKey: "windDir2"}
};

export const WindRainSettings = {
  get: () => {
    return Object.assign({}, settings);
  }
}
