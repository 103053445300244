import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WindRainEditPage } from '../wind-rain-edit/wind-rain-edit.page'
import { SitesService } from 'src/app/services/sites.service';
import { TimerService } from 'src/app/services/timer.service';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-mister-settings',
  templateUrl: './mister-settings.component.html',
  styleUrls: ['./mister-settings.component.scss'],
})
export class MisterSettingsComponent implements OnInit {
  @Input() nodeData;
  @Input() settings;
  @Input() light_set_point;
  @Input() daily_light_integral;
  @Input() mister_index;
  @Input() light_value;
  @Input() pending;
  @Input() lightSource;
  @Output() edit = new EventEmitter<any>();
  @Output() setPending = new EventEmitter<any>();

  constructor(public sitesApiProvider: SitesService,
              public timerService: TimerService,
              public modalCtrl: PopoverController) { }

  ngOnInit() {}

  valueForTimerRequest(key, value) {
    let settings = this.settings;
    settings[key] = value;

    return `${this.light_set_point},${settings.mistAOnSec},${settings.mistACycleMin},${settings.mistBOnSec},${settings.mistBCycleMin},${settings.mistIdleOnSec},${settings.mistIdleCycleMin}`
  }

  getDescription(key) {
    let key_to_description = {
      'mistAOnSec': 'Stage A On Time',
      'mistACycleMin': 'Stage A Cycle Time',
      'mistBOnSec': 'Stage B On Time',
      'mistBCycleMin': 'Stage B Cycle Time',
      'mistIdleOnSec': 'Idle On Time',
      'mistIdleCycleMin': 'Idle Cycle Time'
    }
  }

  editMisterSetting(key) {
    // light set point, Stage A on time (sec), Stage A cycle time (min), Stage B on time, Stage B cycle time, idle on time, idle cycle time
    var settingInfo = {
      node_id: this.nodeData.node_id,
      key: `tbMister${this.mister_index}`,
      value: this.settings[key],
      current: this.settings[key],
      type: 'number',
      pending: false,
      desc: this.getDescription(key),
      pattern: "[0-9]*",
      error: "Please enter only numbers, 0 - 99.",
      inputmode:"tel",
      valid: (val) => { return 0 <= parseInt(val) && parseInt(val) <= 99},
    }

    this.showWindRainEdit(settingInfo, key);
  }

  async showWindRainEdit(settings, key) {
      var settingsForKey = settings;
      if(!settingsForKey) return;
      var reqData = {
        node_id: this.nodeData.node_id,
        key: settings.key,
        value: '',
        comment: ''
      }

      var settingInfo = settings;
      if(settingInfo) {
        const options = {
          component: WindRainEditPage,
          componentProps: {
            settingInfo: settingInfo,
            siteName: this.nodeData.site_name,
            houseName: (this.nodeData.api_name || this.nodeData.node_name),
            canEdit: this.nodeData.canEdit,
            isPending: false
          }
        };
        const settingsModal = await this.modalCtrl.create(options);

        settingsModal.onDidDismiss().then(data => {
          data = data.hasOwnProperty('data') ? data.data : data;
          if (typeof data !== 'undefined'){
            var value = data['value']
            if (data['button'] == "Save" && value != null) {
              reqData.value = this.valueForTimerRequest(key, value['value']);
              reqData.comment = value['comment'];
              this.sitesApiProvider.postRequest(reqData);
              this.timerService.setFastUpdate(60); //set to fast update for the next 60 seconds
              this.setPending.emit({index: this.mister_index, value: reqData});
            }
          }
        });
        settingsModal.present();
      }
  }
}
