import { Component, OnInit, Input, ViewChild, ElementRef, ViewEncapsulation, Output, EventEmitter, OnChanges, ChangeDetectorRef } from '@angular/core';
import { NavController, NavParams, ToastController, Platform } from '@ionic/angular';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SitesService } from 'src/app/services/sites.service';
import moment from 'moment';

@Component({
  selector: 'app-controller-search-and-add',
  templateUrl: './controller-search-and-add.component.html',
  styleUrls: ['./controller-search-and-add.component.scss'],
})
export class ControllerSearchAndAddComponent implements OnInit {
  @Input() showSection;
  @Output() loginAs = new EventEmitter<any>();
  public results = [];
  public error = undefined;
  public filteredResults;
  public searchForm: UntypedFormGroup;
  public addForm: UntypedFormGroup;
  public addController = false;
  private savingNewController = false;
  @ViewChild('searchInputId') searchInputId: ElementRef;

  constructor(
    private sitesApiProvider: SitesService,
    private ref: ChangeDetectorRef,
    private toastCtrl: ToastController
  ) {}

  ngOnInit() {
    this.searchForm = new UntypedFormGroup({
      searchTerm: new UntypedFormControl('')
    });

    this.blankAddForm();
    this.ref.detectChanges();
    if(this.searchInputId) { this.searchInputId.nativeElement.focus(); }
  }

  private blankAddForm() {
    this.addForm = new UntypedFormGroup({
      serial: new UntypedFormControl(''),
      mac: new UntypedFormControl(''),
      type: new UntypedFormControl('')
    });
  }

  public filter(values) {
    this.results = [];
    this.error = undefined;
    this.sitesApiProvider.adminNodeId = values.searchTerm;
    this.sitesApiProvider.getNodeList(true)
        .then((response) => {
          if(this.searchForm && this.sitesApiProvider.adminNodeId && this.searchForm.value && this.sitesApiProvider.adminNodeId === this.searchForm.value.searchTerm) {
            this.results = response.nodes;
          }
        })
        .catch((error) => {
          this.error = "Sorry there was an error trying to get the matching controller(s)."
        })
  }

  public cancelSearch() {
    this.searchForm = new UntypedFormGroup({
      searchTerm: new UntypedFormControl('')
    });
    this.results = [];
  }

  public getNodeOwner(node) {
    if(node && node.users) {
      let owner = node.users.find((u) => u.role === 4)
      return owner ? owner.email : "Unclaimed";
    } else {
      return "Unclaimed";
    }
  }

  public create(value) {
    if(!this.savingNewController) {
      this.sitesApiProvider.adminSaveController(value)
          .then((response) => {
            this.showMessage("The controller was successfully added");
            this.savingNewController = false;
            this.blankAddForm();
            this.addController = false;
          })
          .catch((error) => {
            this.error = error && error.message ? error.message : error;
            this.showMessage(this.error);
            this.savingNewController = false;
          })
    }
  }

  public viewController(node_id, owner, cis_id) {
    this.sitesApiProvider.activeNodeId = node_id
    this.loginAs.emit({email: owner, cis_id: node_id});
  }

  private resetComponent() {
    this.sitesApiProvider.adminNodeId = undefined;
  }

  ionViewWillUnload(){ this.resetComponent(); }
  ionViewDidLeave(){ this.resetComponent(); }

  async showMessage(message) {
    let toast = await this.toastCtrl.create({
      message: message,
      duration: 1000,
      position: 'top'
    });

    toast.present();
  }
}
