import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class SiteService {
  private baseUrl = environment.apiUrl;
  private gettingAllSites = false;

  constructor(
    private http: HttpClient,
    public userService: UserService
  ) {}

  public validateNodeId(node_id): Promise<any> {
    var siteUrl = `${this.baseUrl}/api/sites/validate`;
    return new Promise((resolve, reject) => {
      this.http.post(siteUrl, {node_id: node_id})
              .toPromise()
              .then(response => {
                resolve(response);
              })
              .catch(error => {
                reject(error.error.message);
              });
     })
  }

  public listSites(cis_id?: string): Promise<any> {
    var siteUrl = cis_id ? `${this.baseUrl}/api/sites/${cis_id}` : (this.userService.isLoggedInAs() ? `${this.baseUrl}/api/sites?loginAs=${this.userService.getLoggedInEmail()}` : `${this.baseUrl}/api/sites`);
    if(cis_id || !this.gettingAllSites) {
      return new Promise((resolve, reject) => {
        this.http.get(siteUrl)
                .toPromise()
                .then(response => {
                  if(cis_id) { resolve(response) } else {
                    this.gettingAllSites = false;
                    resolve(response)
                  }
                })
                .catch(error => {
                  if(cis_id) {
                    reject(error.error.message);
                  } else {
                    this.gettingAllSites = false;
                    reject(error.error.message);
                  }
                });
        })
    } else { console.log(`already fetching sites`); }
  }

  public addControllerToSite(siteId, controllerSerialNumber, api_name, replacement_id): Promise<any> {
    var siteUrl = this.baseUrl + "/api/sites/add";

    return new Promise((resolve, reject) => {
      this.http.post(
              siteUrl,
              {
                site_id: siteId,
                serial_number: controllerSerialNumber,
                api_name: api_name,
                replacement_id: replacement_id
              })
              .toPromise()
              .then(response => { resolve(response); })
              .catch(error => {
                reject((error && error.error) ? error.error.message : 'Could not add controller')
              });
      })
  }

  public markNotInUse(controllerId, in_use): Promise<any> {
    var siteUrl = this.baseUrl + `/api/sites/${controllerId}/dormant`;
    return new Promise((resolve, reject) => {
      this.http.post(siteUrl, {in_use: in_use})
              .toPromise()
              .then(response => {
                resolve({response: response, controller_id: controllerId})
              })
              .catch(error => {
                reject((error && error.error) ? error.error.message : 'Could not add controller')
              });
    })
  }

  public removeControllerFromSite(siteId, controllerId): Promise<any> {
    var siteUrl = this.baseUrl + "/api/sites/remove";
    return new Promise((resolve, reject) => {
      this.http.post(
              siteUrl,
              {site_id: siteId, controller_id: controllerId}
            )
              .toPromise()
              .then(response => {
                resolve({response: response, controller_id: controllerId})
              })
              .catch(error => {
                reject((error && error.error) ? error.error.message : 'Could not add controller')
              });
    })
  }

  public createSite(siteName): Promise<any> {
    var siteUrl = this.baseUrl + "/api/sites";
    return new Promise((resolve, reject) => {
    return this.http.post(siteUrl,{name: siteName})
              .toPromise()
              .then(response => {
                resolve(response)
              })
              .catch(error => {
                reject(error.error.message)
              });
    })
  }

  public getSiteChanges(siteId): Promise<any> {
    var siteUrl = this.baseUrl + "/api/site-changes";
    return new Promise((resolve, reject) => {
      this.http.post(siteUrl, {site_id: siteId})
              .toPromise()
              .then(response => { resolve(response); })
              .catch(error => { reject(error.error.message); });
    })
  }

  public editSite(values): Promise<any> {
    var siteUrl = this.userService.isLoggedInAs() ? `${this.baseUrl}/api/sites?loginAs=${this.userService.getLoggedInEmail()}` : `${this.baseUrl}/api/sites`;
    if(values && values.site_id) {
      let updateVal = {
        site_id: values.site_id,
        name: values.name,
        reports: values.reports,
        max_timers: values.max_timers,
        alerts: values.alerts
      };

      return new Promise((resolve, reject) => {
        this.http.put(siteUrl, updateVal)
            .toPromise()
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              reject((error && error.error) ? error.error.message : 'The site name could not be updated');
            });
      })
    }
  }

  public addUserToSite(site_id, user_email, user_role): Promise<any> {
    var siteUrl = `${this.baseUrl}/api/sites/users`;
    return this.http.post(siteUrl,
      {site_id: site_id, user: { email: user_email, role: user_role || 1}})
      .toPromise()
  }

  public updateRole(site_id:string, user_email:string, user_role:number, new_email): Promise<any> {
    var siteUrl = `${this.baseUrl}/api/sites/users`;
    return this.http.put(siteUrl,
      {site_id: site_id, user: { email: user_email, role: user_role || 1, updated_email: new_email}})
          .toPromise()
  }

  public removeUserFromSite(site_id, user_email): Promise<any> {
    var siteUrl = `${this.baseUrl}/api/sites/users/remove`;
    return this.http.post(siteUrl,
      {site_id: site_id, user: user_email})
          .toPromise();
  }

  public saveControllerOrder(site_id, controllers): Promise<any> {
    var siteUrl = `${this.baseUrl}/api/sites/${site_id}/controllers/order${this.userService.isLoggedInAs() ? `?loginAs=${this.userService.getLoggedInEmail()}` : ''}`;
    return this.http.post(siteUrl,
      {site_id: site_id, controllers: controllers.map((c) => c._id)})
          .toPromise()
  }

  public getUsageReport(site_id): Promise<any> {
    var siteUrl = `${this.baseUrl}/api/sites/avgs`;
    return this.http.post(siteUrl, {site_id: site_id})
          .toPromise()
  }

  public testHighLowReport(site, email, date): Promise<any> {
    let url = `${this.baseUrl}/api/sites/reports/highLowSend`;
    return this.http.post(url, {site: site, email: email, date: date}).toPromise()
  }
}
