import * as d3 from 'd3';
import * as d3Array from 'd3-array';
import * as d3Axis from 'd3-axis';
import * as d3Scale from 'd3-scale';
import { select, selectAll } from "d3-selection";
import * as d3Shape from 'd3-shape';
import * as d3TimeFormat from 'd3-time-format';
import moment from 'moment';
import * as momentTz from 'moment-timezone';

var svg;
const chartLabel = "Log Data";
var margin = {top: 20, right: 50, bottom: 20, left: 100};
var width;
var height;
var data;
var color;
var yDomain;
var xAxis;
var yAxis;
var gX;
var gY;
let xScale, yScaleGlobal, yLinear, lightYScale;
let rectangle_width = 1;
let rectangleScale;
let difDayNiteScale;
let line_g;
let font_size_small = '0.85em';
let font_size = '1em';
let num_ticks = 14;
let x_offset_headings = '-10';
let x_offset_headings_large = '-25';

function * range ( start, end, step = 1 ) {
  let state = start;
  while ( state < end ) {
    yield state;
    state += step;
  }
  return;
};

const init = (chartId, data_width) => {
  svg = select(chartId)
    .append('svg')
    .attr('height', height)
    .attr('width', width)
    .attr('id', 'logs-chart')
    .attr("viewBox", `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
    // .attr('width', width + margin.left + margin.right)
    // .attr('height', height + margin.top + margin.bottom);

  const g = svg.append('g')
    .attr('width', data_width)
    .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
    .attr("pointer-events","all")
    .on('mouseenter', function (d, i) {
      g.select('.tooltip')
        .transition()
        .duration(500)
        .style('opacity', 1);
    })
    .on('mouseleave', (d) => {
      let location = d3.pointer(d);
      if(location[0] > width || location[1] > height || location[0] < 0 || location[1] < 0) {
        g.select('.tooltip')
          .transition()
          .duration(1000)
          .style('opacity', 0);
      }
    })

  line_g = g
    .append("svg:clipPath")
      .attr("id", "lines")
      .append("svg:rect")
        .attr("id", "line-rect")
        .attr("x", 0)
        .attr("y", '0')
        .attr("width", width)
        .attr("height", height)
        .attr('fill', `transparent`)

  return {svg: svg, g: g}
}

const keys_to_data_key = {
  "Heat 1": "h1",
  "Heat 2": "h2",
  "Cool 1": "c1",
  "Cool 2": "c2",
  "Cool 3": "c3",
  "Cool 4": "c4",
  "Heat 3": "h3",
  "% 1": "v1p",
  "% 2": "v2p",
  "% 3": "v3p",
  "% 4": "v4p",
  "Alarm": "alarm"
}

const getMinAndMaxForKeys = (data, keys) => {
  const minMaxData = data.reduce((minMaxAcc, timeLogData) => {
    keys.forEach((key) => {
      timeLogData.logTime = moment(timeLogData.createdAt).format("MMM Do YY, HH:mm");
      if (['h1','h2','h3','c1','c2','c3','c4','t1','t2','t3','t4','t5','t6','t7','t8','t9','t10'].includes(key)){
        timeLogData[key] = (parseInt(timeLogData[key]) == 1) ? "ON" : "OFF";
      }
      if (['aa','w1a','w2a','ra','orf','orb'].includes(key)){
        timeLogData[key] = (timeLogData[key] == "true") ? "ON" : "OFF";
      }
      const data_for_key = timeLogData[keys_to_data_key[key]]
      if(data_for_key === "ON" || (key === 'alarm' && data_for_key !== "NONE" && data_for_key !== "OFF") ||
          (['v1p', 'v2p', 'v3p', 'v4p'].includes(key) && data_for_key != "99" && data_for_key !== "OFF")) {
            if(!minMaxAcc[key]) {
              minMaxAcc[key] = {min: timeLogData.raw, max: timeLogData.raw, raw: timeLogData.raw}
            } else {
              const day_time = moment(timeLogData.raw);
              const currentMax = minMaxAcc[key] ? moment(minMaxAcc[key].max) : undefined;
              const currentMin = minMaxAcc[key] ? moment(minMaxAcc[key].min) : undefined;

              if((!currentMax || !day_time) || (currentMax.isBefore(day_time))) {
                minMaxAcc[key].max = timeLogData.raw;
              }

              if((!currentMin || !day_time) || (day_time.isBefore(currentMin))) {
                minMaxAcc[key].min = timeLogData.raw;
              }
            }
      }
    });

    return minMaxAcc;
  }, {});

  return minMaxData;
}

const minMaxLight = (timeLog, minMaxAcc) => {
  const currentMinLight = parseInt(minMaxAcc.min_light)
  const currentMaxLight = parseInt(minMaxAcc.max_light)
  if(timeLog.ll) {
    const currentMaxLight = parseInt(minMaxAcc.max_light)
    const currentMinLight = parseInt(minMaxAcc.min_light)
    const light_level = parseInt(timeLog.ll)
    if((!currentMaxLight && currentMaxLight != 0) || (light_level > currentMaxLight)) {
      minMaxAcc.max_light = light_level;
    }

    if((!currentMinLight && currentMinLight != 0) || (light_level < currentMinLight)) {
      minMaxAcc.min_light = light_level;
    }
  }

  return minMaxAcc;
}

const createLightScale = (chartData, y) => {
  var minAndMax = chartData.reduce((minMaxAcc, timeLog) => {
    return minMaxLight(timeLog, minMaxAcc);
  }, {min_light: undefined, max_light: undefined})

  return {scale: d3Scale.scaleLinear([(minAndMax.max_light), (minAndMax.min_light)], [y, (y + 100)]), updated_y: (y + 110)};
}

const initAxes = (chartData, svg, g, keys, modelType, show_light_info) => {
  var minAndMax = chartData.reduce((minMaxAcc, timeLog) => {
    const currentMax = parseInt(minMaxAcc.max)
    const currentMin = parseInt(minMaxAcc.min)

    if(modelType === 'W') {
      const weatherLogOutTemp = parseInt(timeLog.ot1)
      const windSpeed = parseInt(timeLog.ws)
      const hum = parseInt(timeLog.hum)
      if((!currentMax && currentMax != 0) || (weatherLogOutTemp > currentMax) || (windSpeed > currentMax) || (hum > currentMax)) {
        minMaxAcc.max = Math.max(weatherLogOutTemp, windSpeed, hum)
      }

      if((!currentMin && currentMin != 0) || (weatherLogOutTemp < currentMin) || (windSpeed < currentMin) || (hum < currentMin)) {
        minMaxAcc.min = Math.min(weatherLogOutTemp, windSpeed, hum)
      }
    } else {
      const timeLogT1 = parseInt(timeLog.ct1)
      const timeLogT2 = parseInt(timeLog.ct2)
      const timeLogS1 = parseInt(timeLog.sp1)
      const timeLogS2 = parseInt(timeLog.sp2)

      if((!currentMax && currentMax != 0) || (timeLogS1 > currentMax) || (timeLogS2 > currentMax)
          || (timeLogT1 > currentMax) || (timeLogT2 > currentMax)) {
        var max_of_two = Math.max(timeLogS1, timeLogS2, timeLogT1, timeLogT2)
        minMaxAcc.max = max_of_two;
      }

      if((!currentMin && currentMin != 0) || timeLogS1 < currentMin || timeLogS2 < currentMin
          || timeLogT1 < currentMin || timeLogT2 < currentMin) {
        var min_of_two = Math.min(timeLogS1, timeLogS2, timeLogT1, timeLogT2)
        minMaxAcc.min = min_of_two;
      }
    }

    minMaxAcc = minMaxLight(timeLog, minMaxAcc);
    return minMaxAcc;
  }, {min: undefined, max: undefined, min_light: undefined, max_light: undefined});

  const getMinMaxArray = (minMax) => {
    var top = (minMax.max + 5)
    var bottom = (minMax.min - 5)
    return Array.from(range(bottom, top, Math.round((top - bottom) / 5)))
  }

  var half_height = ((height - margin.top - margin.bottom) / 3)
  const yRange = Array.from(range((half_height * 2), (height - margin.top), ((height - margin.top) - (half_height * 2)) / keys.length))
  const y = d3Scale.scaleBand()
                   .domain(keys)
                   .rangeRound([(half_height * 2), (height - margin.top)])
                   .paddingInner(0.1);
  const yLinear = d3Scale.scaleLinear([(minAndMax.max + 5), (minAndMax.min - 5)], [margin.top, 180]);
  let lightY = d3Scale.scaleLinear([(minAndMax.max_light), (minAndMax.min_light)], [200, 300]);
  return {yScale: y, yLinear: yLinear, lightY: lightY};
}

const drawTimeAxes = (g, x, y, zoom = false) => {
  if(zoom) {
    g.select('.axis--x')
      .transition()
      .duration(300)
      .attr('transform', 'translate(0,' + y + ')')
      .call(d3Axis.axisBottom(x)
          .ticks(num_ticks)
          .tickFormat(d3TimeFormat.timeFormat("%m/%d %H:%M")))
      .attr('font-size', font_size_small)
        .selectAll("text")
          .attr("transform", "rotate(90)")
          .attr("dx", "1em")
          .attr("dy", "-.5em")
          .style("text-anchor", "start");
  } else {
    xAxis = g.append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', 'translate(0,' + y + ')')
      .call(d3Axis.axisBottom(x)
        .ticks(num_ticks)
        .tickFormat(d3TimeFormat.timeFormat("%m/%d %H:%M")))
      .attr('font-size', font_size_small)
      .selectAll("text")
        .attr("transform", "rotate(90)")
        .attr("dx", "1em")
        .attr("dy", "-.5em")
        .style("text-anchor", "start");
  }
}

const drawTemperatureAxes = (g, x, y, yLinear, chartLabel) => {
   yAxis = g.append('g')
    .attr('class', 'axis axis--y')
    .call(d3Axis.axisLeft(yLinear)
     .ticks(6)
     .tickSize(-(width - margin.right)))
    .attr('font-size', font_size_small)
    .append('text')
      .attr('class', 'axis-title')
      .attr('x', (width + margin.right + margin.left))
      .attr('dx', '5em')
      .attr('fill', 'rgb(92, 191, 145)')
      .text(chartLabel);

    g.append('text')
      .attr('x', x_offset_headings)
      .attr('y', y + 30)
      .attr('stroke', 'rgb(92, 191, 145)')
      .style("font-size", font_size)
      .attr("text-anchor", "end")
      .attr('class', `label`)
      .text('Log Data')
}

const createD3Lines = (x, yLinear) => {
  var set_point_1_line = d3Shape.line()
    .x(function(d, i) { return x(d.createdAt); })
    .y(function(d) {return yLinear(d.sp1);});

    var set_point_2_line = d3Shape.line()
      .x(function(d, i) { return x(d.createdAt); })
      .y(function(d) { return yLinear(d.sp2); });

    var temp_1_line = d3Shape.line()
      .x(function(d, i) { return x(d.createdAt); })
      .y(function(d) { return yLinear(d.ct1); });

    var temp_2_line = d3Shape.line()
      .x(function(d, i) { return x(d.createdAt); })
      .y(function(d) { return yLinear(d.ct2); });

    return {
      sp1: set_point_1_line,
      sp2: set_point_2_line,
      temp1: temp_1_line,
      temp2: temp_2_line
    }
}

function prepareWbTooltip(g) {
  const tooltip = g.append("g")
    .attr("class", "tooltip")
    .attr('background-color', '#ffffff')
    .attr("height", height - (margin.top + margin.bottom + 30))
    .attr('y', margin.top)
    .attr('border-left', '1px solid black')
    .style("display", "none");

  tooltip.append("rect")
    .attr("height", 90)
    .attr('width', 200)
    .attr("fill", 'white')
    .attr('break-word', 'break-all')
    .style("opacity", 0.9);

  let text_zone = tooltip.append("text")
    .attr("x", 10)
    .attr("dy", "0")
    .style('font-family', 'sans-serif')
    .attr("font-size", "11px")
    .style('text-anchor', 'start')

  text_zone.append("tspan")
    .attr('id', 'tooltip_title')
    .attr('x', 10)
    .attr('dy', 15)
    .attr('font-size', '14px')
    .attr('font-weight', 800)

  text_zone.append("tspan")
    .attr('id', 'tooltip_subtitle')
    .attr('x', 10)
    .attr('dy', 15)
    .attr('font-size', '12px')
    .attr('font-weight', 600)

  text_zone.append('tspan')
    .attr('id', 'tooltip_info')
    .attr('x', 10)
    .attr('dy', 14)
    .append('tspan')
}

const drawLines = (chartData, g, x, y, yLinear) => {
  let lines = createD3Lines(x, yLinear);
  if(chartData) {
    g.append("svg:path")
      .datum(chartData)
      .attr("stroke", "#5CBF91")
      .attr("stroke-width", 3)
      .attr("fill", "none")
      .attr('class', 'sp1 line')
      .attr("pointer-events","all")
      .attr("d", lines.sp1)
      .attr('clip-path', 'url("#lines")')

    g.append("svg:path")
      .datum(chartData)
      .attr("stroke", "#5DA2BF")
      .attr("stroke-width", 3)
      .attr("fill", "none")
      .attr('class', 'sp2 line')
      .attr("d", lines.sp2)
      .attr('clip-path', 'url("#lines")')

    g.append("svg:path")
      .datum(chartData)
      .attr("stroke", "#e0b500")
      .attr("stroke-width", 3)
      .attr("fill", "none")
      .attr('class', 'temp1 line')
      .attr("d", lines.temp1)
      .attr('clip-path', 'url("#lines")')

    g.append("svg:path")
      .datum(chartData)
      .attr("stroke", "#a94633")
      .attr("stroke-width", 3)
      .attr("fill", "none")
      .attr('class', 'temp2 line')
      .attr("d", lines.temp2)
      .attr('clip-path', 'url("#lines")')
  } else {
    g.select('.sp1')
       .transition()
       .duration(1000)
       .attr("d", lines.sp1)

    g.select('.sp2')
      .transition()
      .duration(1000)
      .attr("d", lines.sp2)

    g.select('.temp1')
       .transition()
       .duration(1000)
       .attr("d", lines.temp1)

    g.select('.temp2')
      .transition()
      .duration(1000)
      .attr("d", lines.temp2)
  }
}

const drawLightLines = (chartData, g, x, yScale, y) => {
  if(yScale) {
    var light_level_line = d3Shape.line()
      .x(function(d, i) { return x(d.createdAt); })
      .y(function(d) { return yScale(d.ll); });

    if(chartData) {
        yAxis = g.append('g')
         .attr('class', 'axis axis--y')
         .call(d3Axis.axisLeft(yScale)
          .ticks(10)
          .tickSize(-(width - margin.right)))
         .attr('font-size', font_size_small);

       g.append('text')
         .attr('x', '-40')
         .attr('y', y - 60)
         .attr('stroke', 'rgb(92, 191, 145)')
         .style("font-size", font_size)
         .attr("text-anchor", "end")
         .attr('class', `label`)
         .text('Light')

       g.append("path")
        .datum(chartData)
        .attr("stroke", "#e65722")
        .attr("stroke-width", 3)
        .attr("fill", "none")
        .attr('class', 'light_level_line line')
        .attr("d", light_level_line)
        .attr('clip-path', 'url("#lines")');
      } else {
        g.select('.light_level_line')
          .transition()
          .duration(1000)
          .attr("d", light_level_line)
      }
  }

  return y + 10;
}

const drawWbLines = (chartData, g, x, y, yLinear) => {
  //time,stage,alarm,ot1,hum,ws,wd,w1a,w2a,ra,orf,orb,aa
  var out_temp_line = d3Shape.line()
    .x(function(d, i) { return x(d.createdAt); })
    .y(function(d) {return yLinear(d.ot1);});

  var hum_line = d3Shape.line()
    .x(function(d, i) { return x(d.createdAt); })
    .y(function(d) { return yLinear(d.hum); });

  var wind_speed_line = d3Shape.line()
    .x(function(d, i) { return x(d.createdAt); })
    .y(function(d) { return yLinear(d.ws); });

  if(chartData) {
    g.append("path")
      .datum(chartData)
      .attr("stroke", "#e0b500")
      .attr("stroke-width", 3)
      .attr("fill", "none")
      .attr('class', 'out_temp_line line')
      .attr("d", out_temp_line)
      .attr('clip-path', 'url("#lines")');

    g.append("path")
      .datum(chartData)
      .attr("stroke", "#a94633")
      .attr("stroke-width", 3)
      .attr("fill", "none")
      .attr('class', 'hum_line line')
      .attr("d", hum_line)
      .attr('clip-path', 'url("#lines")');

    g.append("path")
      .datum(chartData)
      .attr("stroke", "#5CBF91")
      .attr("stroke-width", 3)
      .attr("fill", "none")
      .attr('class', 'wind_speed_line line')
      .attr("d", wind_speed_line)
      .attr('clip-path', 'url("#lines")');
  } else {
    g.select('.out_temp_line')
      .transition()
      .duration(1000)
      .attr("d", out_temp_line)

    g.select('.hum_line')
       .transition()
       .duration(1000)
       .attr("d", hum_line)

    g.select('.wind_speed_line')
      .transition()
      .duration(1000)
      .attr("d", wind_speed_line)
  }
}

//draw a vent open percent graph
const drawPercent = (data, g, x, y, keyInfo) => {
  var pad = 8;
  var top = y + pad;
  var bottom = y + pad + 20;
  const yLinear = d3Scale.scaleLinear([100, 0], [top, bottom]);
  const hLinear = d3Scale.scaleLinear([0, 100], [0, 20]);
  const yLinearAxis = d3Axis.axisLeft(yLinear)
                            .ticks(1)
                            .tickSize(-(width - margin.right));

  if(data) {
    g.selectAll("bar")
      .data(data)
      .enter()
      .append("rect")
      .style("fill", keyInfo.color)
      .attr('class', `bar ${keyInfo.key}PercentBar percentBar`)
      .attr("x", function(d) {
        return rectangleScale(d.createdAt);
      })
      .attr("width", (d) => { return rectangleScale.bandwidth() })
      .attr("y", function(d) { return yLinear(d[keyInfo.key]); })
      .attr("height", function(d) { return hLinear(d[keyInfo.key]); })
      .attr('clip-path', 'url("#lines")');

    g.append('g')
      .attr('class', `axis axis--y percent`)
      .call(yLinearAxis)
      .attr('font-size', font_size_small)
      .append('text')
      .attr('class', 'axis-title')
      .attr('x', (width + margin.right + margin.left))
      .attr('dx', '5em')
      .attr('fill', 'rgb(92, 191, 145)')
      // .text(chartLabel);

    g.append('text')
      .attr('x', x_offset_headings_large)
      .attr('y', top + 15)
      .attr('stroke', 'rgb(92, 191, 145)')
      .style("font-size", font_size)
      .attr("text-anchor", "end")
      .attr('class', `label`)
      .text(keyInfo.name)
  } else {
    g.selectAll(`.${keyInfo.key}PercentBar`)
      .transition()
      .duration(500)
      .attr("width", (d) => { return rectangleScale.bandwidth() })
      .attr("x", function(d) { return rectangleScale(d.createdAt); })
      .attr("height", function(d) { return hLinear(d[keyInfo.key]); })
  }

  return y + pad + 20 + pad;
}

//draw a wind direction plot
const drawDirection = (data, g, x, y, keyInfo) => {
  var pad = 8;
  var top = y + pad;
  var bottom = y + pad + 40;
  var yScale = d3Scale.scaleBand()
    .domain(["N","NE","E","SE","S","SW","W","NW"])
    .range([y, y+100])                       // This is where the axis is placed: from 100 px to 800px

  if(data) {
    data = data.filter((d) => d.wd)
    g.selectAll("bar")
      .data(data)
      .enter()
      .append("circle")
      .style("fill", keyInfo.color)
      .attr("class", function(d) { return `${keyInfo.key}_circle ${d.wd}` })
      .attr("cx", function(d) { return rectangleScale(d.createdAt); })
      // .attr("width", rectangle_width)
      .attr("cy", function(d) { return yScale(d[keyInfo.key]) + (yScale.bandwidth()/2); })
      .attr("r", yScale.bandwidth()/3)
      .attr('clip-path', 'url("#lines")');

    g.append('g')
      .attr('class', 'axis axis--y')
      .call(d3Axis.axisLeft(yScale)
        .ticks(3)
        .tickSize(-(width - margin.right)))
      .attr('font-size', font_size)
      .append('text')
        .attr('class', 'axis-title')
        .attr('x', (width - margin.right))
        .attr('dx', '10em');

    g.append('text')
      .attr('x', x_offset_headings_large)
      .attr('y', top + 10)
      .attr('stroke', 'rgb(92, 191, 145)')
      .style("font-size", font_size)
      .attr("text-anchor", "end")
      .text(keyInfo.name);
  }

  return y + pad + 100 + pad;
}

//draw a on/off graph for heats and cools
const drawOnOff = (data, g, x, y, keyInfo) => {
  const yLinear = d3Scale.scaleLinear([1, 0], [y, y+10]);
  if(data) {
    g.append('line')
      .attr("stroke-width", 1)
      .attr("stroke", "black")
      .attr("x1", 0)
      .attr("y1", y+7)
      .attr("x2", width - margin.right)
      .attr("y2", y+7);

    g.selectAll("bar")
      .data(data)
      .enter().append("rect")
        .style("fill", keyInfo.color)
        .attr('class', `bar onOffBar ${keyInfo.key}OnOff`)
        .attr("x", function(d) { return rectangleScale(d.createdAt); })
        .attr("width", (d) => { return rectangleScale.bandwidth() })
        .attr("y", function(d) { return (d[keyInfo.key] == 'ON' || d[keyInfo.key] === 1) ? y+1:y+6; })
        .attr("height", function(d) { return (d[keyInfo.key] === 'ON' || d[keyInfo.key] === 1) ? 11:2; })
        .attr('clip-path', 'url("#lines")');

    g.append('text')
      .attr('x', x_offset_headings)
      .attr('y', y+12)
      .attr('stroke', 'rgb(92, 191, 145)')
      .style("font-size", font_size)
      .attr("text-anchor", "end")
      .text(keyInfo.name)
  } else {
    g.selectAll(`.${keyInfo.key}OnOff`)
      .transition()
      .duration(500)
      .attr("x", function(d) { return rectangleScale(d.createdAt); })
      .attr("width", (d) => { return rectangleScale.bandwidth() })
      .attr("height", function(d) { return (d[keyInfo.key] == 'ON' || d[keyInfo.key] === 1) ? 11:2; })
  }

  return y + 15;
}

const drawRectangles = (data, g, x, y, modelType) => {
  const coolColor = '#4183D7';
  const heatColor = '#e65722';
  const timerColor = '#989aa2';
  var keyInfo = [
    {key: 'wd', name: 'Wind Dir', color: "#5DA2BF"},
    {key: 'aa', name: 'Alarm', color: heatColor},
    {key: 'w1a', name: 'Wind Alm 1', color: heatColor},
    {key: 'w2a', name: 'Wind Alm 2', color: heatColor},
    {key: 'ra', name: 'Rain Alarm', color: heatColor},
    {key: 'orf', name: 'Force', color: timerColor},
    {key: 'orb', name: 'Block', color: timerColor},
  ]

  //draw the graphs from the info array
  keyInfo.forEach(function(info){
    if (info.key.startsWith('v') || info.key === 'hum') {
      y = drawPercent(data, g, x, y, info);
    } else if (info.key.startsWith('wd')){
      y = drawDirection(data, g, x, y, info);
    } else {
      y = drawOnOff(data, g, x, y, info);
    }
  });

  return y;
}

export const WeatherBossLogsChartGenerator = {
  chartData:(chartId, chart_data, chart_width, chart_height, modelType, columns, show_light_info) => {
    data = chart_data;
    width = chart_width;
    if(chart_width < 500) {
      margin = {top: 10, right: 5, bottom: 10, left: 40};
      num_ticks = 6;
      font_size = '10px';
      x_offset_headings = '-3';
      x_offset_headings_large = '-5'
    }
    height = chart_height;
    var keys = columns.slice(1);
    var y = 10;

    selectAll("svg").remove();
    color = d3Scale.scaleOrdinal(keys,
      ["#5CBF91", "#7044ff",
        "#f04141", "#f04141",
        "#989aa2", "#989aa2",
        "#989aa2", "#989aa2",
        "#f04141", "#d7d8da",
        "#d7d8da", "#d33939"]);

    const groupKey = "label";
    const chartLabel = "Time Log"
    let min_date, max_date;
    let reset_chart = () => {}
    let width_for_data = chart_width < 500 ? width : (width - (margin.left - margin.right));
    const drawZoneChart = (chartId, data) => {
      data = data.map((d) => {
        d.createdAt = momentTz.utc(d.createdAt, 'MM/DD/YYYY HH:mm').toDate()
        return d;
      })

      var {svg, g} = init(chartId, width_for_data);
      const min_and_max_by_key = getMinAndMaxForKeys(data, keys);
      min_date = d3Array.min(data, d => d.createdAt);
      max_date = d3Array.max(data, d => d.createdAt);

      rectangle_width = width/data.length;
      let get_rectangle_domain = (min, max) => {
        let domain = data
          .filter((d) => {
            let same_or_before_max = moment(d.createdAt).isSameOrBefore(max)
            let same_or_after_min = moment(d.createdAt).isSameOrAfter(min);
            return same_or_before_max && same_or_after_min;
          })
          .map((d) => d.createdAt)
          .sort((a, b) => {
            if (a && b && a < b) { return -1; } else if (a && b && a === b) { return 0; } else { return 1; }
          })
        return domain;
      }

      rectangleScale = d3Scale.scaleBand()
        .domain(get_rectangle_domain(min_date, max_date))
        .range([0, width_for_data])
        .padding(0);
      xScale = d3Scale.scaleTime()
        .domain([min_date, max_date])
        .range([0, width_for_data]);

      function zoomed(event) {
        let y = 10;
        if (event && event === "brush") return; // ignore zoom-by-brush
        drawWbLines(null, g, xScale, yScaleGlobal, yLinear);
        let zoom_y = show_light_info ? 300 : 200;

        if(show_light_info) {
          zoom_y = drawLightLines(null, g, xScale, lightYScale, zoom_y);
        }
        zoom_y = drawRectangles(null, g, xScale, zoom_y, modelType) + 10;
        drawTimeAxes(g, xScale, zoom_y, true);
      }

      var zoom = d3.zoom()
        .scaleExtent([1, Infinity])
        .translateExtent([[margin.left, margin.top], [width, height]])
        .extent([[margin.left, margin.top], [width, height]])
        .on("zoom", zoomed);

      function brushed(event, d) {
        if (event && event === "zoom") return; // ignore brush-by-zoom
        let selection = event.selection
        if(selection) {
          xScale

          let inverted = [xScale.invert(selection[0]), xScale.invert(selection[1])]
          xScale
            .domain([inverted[0], inverted[1]])

          rectangleScale
            .domain(get_rectangle_domain(inverted[0], inverted[1]))
          g.call(zoom.transform, d3.zoomIdentity)
          g.select(".brush").call(brush.move, null)
        }
      }

      const brush = d3.brushX()
                      .extent([[0, 0], [(width + margin.left + margin.right), (height || chart_height)]])
                      .on('end', brushed);

      g.append('g')
        .attr('class', 'brush')
        .call(brush);

      if (modelType == 'R' || modelType == 'G' || modelType == 'W') {
        //only draw temperature graph for RWL or
        let {yScale, yLinear: yLinearInit, lightY} = initAxes(data, svg, g, keys, modelType, show_light_info);
        yScaleGlobal = yScale;
        yLinear = yLinearInit;
        lightYScale = lightY;
        drawTemperatureAxes(g, xScale, yScale, yLinear, chartLabel);
        drawWbLines(data, g, xScale, yScale, yLinear);
        // y = height/2 + 10;
        y = show_light_info ? 300 : 200;
      } else {
        let {scale, updated_y} = createLightScale(data, y)
        y = updated_y;
        lightYScale = scale;
      }

      if(show_light_info) {
        y = drawLightLines(data, g, xScale, lightYScale, y);
      }

      y = drawRectangles(data, g, xScale, y, modelType) + 10;
      drawTimeAxes(g, xScale, y);
      y += 60; //add space for time axis
      prepareWbTooltip(g);

      reset_chart = function() {
        xScale
          .domain([min_date, max_date])
          .range([0, width_for_data])
        let rectangle_domain = get_rectangle_domain(min_date, max_date);
        rectangleScale
          .domain(rectangle_domain)
          .range([0, width_for_data]);
        g.call(zoom.transform, d3.zoomIdentity)
      }
      g.on("dblclick", reset_chart);
    }

    drawZoneChart(chartId, data);

    return {
      draw: () => {
        drawZoneChart(chartId, data);
      },
      reset: reset_chart,
      height: y
    }
  }
}
