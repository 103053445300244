import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent  implements OnInit {
  @Input() startFromScratchSelected;
  @Input() steps;
  @Input() current_step;
  @Input() current_step_index;
  @Input() current_page;
  @Input() finalReview;
  @Input() editingVent;
  @Input() vent_editing_index;
  @Input() programmingForm;
  @Input() stepsLength;  
  @Input() reviewing;
  @Input() showGraph;
  @Input() startFromScratch;
  @Input() smallScreen;

  @Output() changeStepEmitter = new EventEmitter();
  @Output() import = new EventEmitter();
  @Output() final = new EventEmitter();
  @Output() nextT = new EventEmitter();
  @Output() nextD = new EventEmitter();
  @Output() nextV = new EventEmitter();
  @Output() updateD = new EventEmitter();
  @Output() invalid = new EventEmitter();
  @Output() showHideG = new EventEmitter();
  @Output() start = new EventEmitter();
  @Output() back = new EventEmitter();
  @Output() rev = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  changeStep(index, back?) {
    this.changeStepEmitter.emit({i:index, back: back})
  }

  importToController() {
    this.nextDehum(); 
    this.updateDehum(); 
    this.import.emit()
  }

  finalImport() {
    this.final.emit()
  }

  nextTimer(event?) {
    this.nextT.emit(event)
  }

  nextVent(event?) { this.nextV.emit(event); }

  showNormalStepControl() {
    try {
      return !['vents_zone2', 'vents', 'timers', 'dehumidification_form'].includes(this.current_page && this.current_page.steps[0])
    } catch(e) { console.log(e); }
  }

  nextDehum(event?) { this.nextD.emit(event); }
  updateDehum() { this.updateD.emit(); }
  subFormInvalid() { this.invalid.emit(); }
  showHideGraph() { this.showHideG.emit(); }
  startForm() { this.start.emit(); }
  backToGuide() { this.back.emit(); }
  review() { this.rev.emit(); }

  goBack() {
    if(!this.finalReview && !this.current_page && this.startFromScratchSelected) {
      this.changeStep(this.steps.length - 1, true);
    } else if(!this.finalReview && this.current_page && ['vents', 'vents_zone2'].includes(this.current_page.steps[0])) {
      this.nextVent(true);
    } else if(!this.finalReview && this.current_page && this.current_page.steps[0] === 'timers') {
      this.nextTimer(true);
    } else if(!this.finalReview && this.current_page && this.current_page.steps[0] === 'dehumidification_form') {
      this.nextDehum(true)
    } else if(this.current_page && this.showNormalStepControl() && this.startFromScratchSelected) {
      this.changeStep(this.current_step_index - 1, true);
    }
    
  }

  forward() {
    this.changeStep(this.current_step_index + 1);
  }
}
