import { Component } from '@angular/core';

import { NavController, Platform } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { FirebaseMessaging } from '@awesome-cordova-plugins/firebase-messaging/ngx';
import { NativeAudio } from '@ionic-native/native-audio/ngx';

import { SitesService } from 'src/app/services/sites.service';
import { NodeListItem } from './model-defs/node-def';
import { UserService } from 'src/app/services/user.service';
import { Storage } from '@ionic/storage';
import { NotificationOptions } from './notifications/options';
import { NavigationExtras, Router } from '@angular/router';
declare var cordova: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    public platform: Platform,
    public statusBar: StatusBar,
    public sitesApiProvider: SitesService,
    public userService: UserService,
    private fcm: FirebaseMessaging,
    public storage: Storage,
    private nativeAudio: NativeAudio,
    public navCtrl: NavController,
    public router: Router    
  ) {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    prefersDark.addListener((mediaQuery) => this.toggleDarkTheme(mediaQuery.matches));
    this.initializeApp();
  }

  toggleDarkTheme(shouldAdd) {
    document.body.classList.toggle('dark', shouldAdd);
  }

  goToNode(data, inForeground) {
    if(inForeground) alert(data.message);
    if (this.userService && this.userService.LoggedIn() && data.node_id) {
      //get the current view to decide what action to take
      this.sitesApiProvider.activeNodeId = data.node_id; //set the node id to be retrieved
      // if it is on a status page just change the node it is seeing, otherwise
      // change to the status page for the alert node
      if (this.router.url.startsWith('status')) {
        var alertNode = new NodeListItem;
        alertNode.node_id = data ? data.node_id : "";
        alertNode.node_name = data ? (data.api_name || data.node_name) : "";
        alertNode.site_name = data ? data.site_name: "";

        let navigationExtras: NavigationExtras = {
          queryParams: { inNodeData: alertNode }
        };

        if(alertNode.node_id.endsWith("-00")) {
          this.navCtrl.navigateForward('status-wind-rain', navigationExtras);
        } else {
          this.navCtrl.navigateForward('status-wind-rain', navigationExtras);
        }
      }
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.statusBar.overlaysWebView(false);
      
      if (this.platform.is('cordova')) {
        this.fcm.requestPermission({forceShow: true}).then(function(hasPermission){
          console.log("Critical notifications permission was " + (hasPermission ? "granted" : "denied"));
      });

      let createChannelPromises = []
        if(this.platform.is('android')) {
          let allNotificationOptions = NotificationOptions.getAll()
          createChannelPromises = allNotificationOptions.map((notification) => {
            return new Promise((resolve) => {
              console.log(`Create channel: ${notification.filename}`)              
              try {                                
                cordova.plugins.firebase.messaging.createChannel({
                  id: notification.filename === 'Firebase' ? notification.filename : `${notification.filename}_channel`,
                  name: notification.filename === 'Firebase' ? notification.filename : `${notification.filename}_channel`,
                  description: `Headgrower Alarm Type - ${notification.filename}`,
                  importance: 4,
                  sound: notification.filename,
                  vibration: true,
                  light: true
                })
                resolve(`Created ${notification.filename}`);  
              } catch(e) {
                console.log(e)
              }
            })     
          });          
        } else {
          console.log(`not android so not setting up channels`)
        }

        Promise.all(createChannelPromises).then(() => {
          if(this.platform.is('android')) {
            cordova.plugins.firebase.messaging.listChannels().then(function(channels) {
              console.log(JSON.stringify(channels));
            })
          }

          this.fcm.subscribe('all');
          this.fcm.onTokenRefresh()
            .subscribe(() => {
              this.fcm.getToken().then((token) => {
                console.log(`BinstLog: Refreshed token: ${token}`)
                this.userService.pushToken = token;
                if (this.userService.LoggedIn()) {
                  this.userService.storeUserPushToken(token);
                }
              });
            }, (err) => {
              console.log('Error on getting token');
              console.log(err);
            });
          this.fcm.onMessage().subscribe((data) => {
            // console.log(`on message => ${JSON.stringify(data)}`)
            this.goToNode(data, true);
          });        

          this.fcm.onBackgroundMessage().subscribe((data) => {
            // console.log(`on message => ${JSON.stringify(data)}`)
            this.goToNode(data, true);
          });        
        }).catch((e) => { console.log(`error after creating channels`); console.log(e)})        
      } else {
        console.info("Push not available.  Cordova not loaded.");
      }
      
      this.navCtrl.navigateRoot('home');
    });
  }
}
