import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { SitesService } from 'src/app/services/sites.service';
import { Node, NodeListItem } from '../model-defs/node-def';
import { NavController } from '@ionic/angular';
import { NavigationExtras } from '@angular/router';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-time-boss-list-item',
  templateUrl: './time-boss-list-item.component.html',
  styleUrls: ['./time-boss-list-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TimeBossListItemComponent implements OnInit {
  @Input() node;
  @Input() isOwner;
  @Input() asDashboard: Boolean = false;

  @Output()
  public choose: EventEmitter<number> = new EventEmitter<any>();

  public timerInfo = [];

  constructor(
    public sitesApiProvider: SitesService,
    public navCtrl: NavController,
    public platform: Platform
  ) { }

  ngOnInit() {
    this.timerInfo = this.getTimerInfo();
  }

  ngOnChanges() {
    this.timerInfo = this.getTimerInfo();
  }

  public selectNode(id) {
    this.sitesApiProvider.activeNodeId = id;

    let navigationExtras: NavigationExtras = {
      queryParams: { node: this.node }
    };

    let width = this.platform.width();
    if(width >= 820) {
      this.node.has_status = this.node.status ? true : false;
      this.choose.emit(this.node);
    } else {
      this.navCtrl.navigateForward(`time-boss-status-page/${this.node.node_id}`, navigationExtras);
    }
  }

  public getTimerInfo() {
    // console.log(`getTimerInfo`)
    // return [{on: true, isMister: false}];
    let keys = ["t1", "t2", "t3", "t4", "t5", "t6", "t7", "t8", "t9", "t10"]
    let info = keys.map((k, index) => {
      if(this.node) {
        let group = (index > 7) ? this.node.settings.group2Timer : this.node.settings.group1Timer;
        let group_index = (index > 7) ? (index - 8) : index;
        return {
          on: (this.node.status[k] === 'ON'),
          icon_name: (group && group_index && group[group_index].isMister ? 'rainy' : 'timer')
        }
      } else {
        return {on: false, icon_name: 'timer'};
      }      
    });
    return info;
  }

  public length() { return this.getTimerInfo().length; }
  public getWidthPercent() {
    return `${100 / (this.length() / 2)}%`;
  }
}
