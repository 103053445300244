import { Component, Input, OnChanges, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastController, AlertController, AlertInput, Platform } from '@ionic/angular';

import { SitesService } from 'src/app/services/sites.service';
import { UserService } from '../services/user.service';
import { DefaultSettings } from './defaultSettings';
import { DehumidificationFormComponent } from './dehumidification-form/dehumidification-form.component';
import { heatCoolActivationValidation } from './heatCoolValidator';
import { SettingsFileGenerator } from './settings-file-generator';
import { TimelineChartGenerator } from './timeline-chart-generator';
import { TimerFormComponent } from './timer-form/timer-form.component';
import { VentFormComponent } from './vent-form/vent-form.component';
import { TimeHelper } from '../components/time_helper';

@Component({
  selector: 'app-programming-tools',
  templateUrl: './programming-tools.component.html',
  styleUrls: ['./programming-tools.component.scss'],
})
export class ProgrammingToolsComponent implements OnInit, OnChanges {
  @Input() node;
  @Input() reviewDefault;
  @Input() narrow;
  public model;
  public zones;
  public numberVents = 2;
  public programmingForm;
  public current_step;
  public current_step_index;
  public current_page;
  public reviewing = false;

  public current_timer = 0;
  public showGraph = false;

  public steps = [];
  private two_zone_field_steps;
  private two_zone_fields;
  private one_zone_form_fields;

  public editingVent = false;
  public vents_available_by_zone;
  public vent_editing_index = 0;
  public vent_config = [];

  public dehumValue;

  public timerTypes = [];
  public timer_editing_index;
  public timer_values = [];
  public ventNumbers = [1, 2];
  public finalReview = false;
  private allUserControllers = [];
  public optionsForStart;
  public startFromScratch = 'current';
  public online = true;

  public isCelsius = false;
  public tUnits = "˚F";
  public tempMin = '32';
  public tempMax = '130';

  public pages = [     
    {name: 'Day', steps: ["day_start", "day_temp", "day_temp_zone2"]},
    {name: 'Night', steps: ["nite_start", "nite_degrees", "nite_degrees_zone2"]},
    {name: 'DIF', steps:["dif_start", "dif_degrees", "dif_degrees_zone2"]},
    {name: "Outputs", key: 'offsets', steps: ["day_h1", "day_h2", "day_c1", "day_c2", "day_c3", "day_c4"]},
    {name: "Zone 1 Outputs", key: 'offsets', steps: ["day_h1", "day_c1", "day_c2"]},
    {name: "Vents", steps: ["vents"]},
    {name: "Zone 2 Outputs", key: "offsets_zone2", steps: ["day_h2", "day_c3", "day_c4"]},
    {name: "Zone 2 Vents", steps: ["vents_zone2"]},
    {name: "Timers", steps: ["timers"]},
    {name: "Dehumidification", steps:["dehumidification_form"]},
  ]  

  public viewablePages = this.pages;
  public smallScreen = false;
  private initialFormValue;
  public active_vent = undefined;
  public startFromScratchSelected = false;

  @ViewChild(DehumidificationFormComponent) dehumFormComponent;
  @ViewChildren('vents') ventFormComponents!:QueryList<VentFormComponent>;
  @ViewChildren("timers") timerFormComponents!:QueryList<TimerFormComponent>;

  private ventNumberOptions = {
    1: {
      ghk: {
        zone1: [2, 3]
      },
      rwl: {
        zone1: [0, 1, 2, 3]
      }
    },
    2: {
      ghk: {
        zone1: [2],
        zone2: [3]
      },
      rwl: {
        zone1: [0, 2],
        zone2: [1, 3]
      }
    },
  }

  constructor(private nodeService: SitesService,
              private userService: UserService,
              private toastCtrl: ToastController, 
              private alertController: AlertController, 
              private platform: Platform) {
                this.initForm();
              }

  initForm() {
    this.reviewing = this.reviewDefault !== undefined ? this.reviewDefault : false;
    this.optionsForStart = [{'name': 'Default Settings', 'value': 'default'},{'name': 'Current Controller Settings', 'value': 'current'}];        
    this.smallScreen = 720 >= this.platform.width();   
    // console.log(`init form`)
    // this.startForm('init form'); 
    this.online = this.node && this.node.online;
    this.setTemperatureUnits(this.userService.getTemperatureScale());
    if(this.narrow) { 
      this.startFromScratch = 'current'; 
      this.startFromScratchSelected = true; 
      this.startForm();
    }
  }

  ngOnChanges() {
    this.smallScreen = 720 >= this.platform.width();
    this.online = this.node && this.node.online;
  }

  ngOnInit() {
    this.initForm();
  }  

  setTemperatureUnits(scale) {
    this.isCelsius = scale ? (scale != 'fahrenheit') : false;
    this.tUnits = this.isCelsius ? '˚C' : this.nodeService.tempUnits;
    if(this.isCelsius) {
      this.tempMax = '55';
      this.tempMin = '0';
    }
  }

  public reformatTime(event, field, recursive?) {
    TimeHelper.reformat(event, field, this.programmingForm, recursive)
  }

  startForm() {  
    this.model = this.node ? this.node.model : 'CB-GHK';
    this.online = this.node && this.node.online;
    this.startFromScratchSelected = true;
    if(this.startFromScratch) {
      this.node = DefaultSettings.get(this.node);
    }

    let output_overrides = this.node ? this.node.output_overrides : null;
    if(this.model.includes('RWL')) {
      this.numberVents = 4;
      this.vent_config = this.node.settings.vent.map((v, index) => {
        return {...v, ...{step: v ? v.st: 0, vent_name: (output_overrides ? output_overrides[`v${index + 1}`] : this.getVentName(index))}}
      })
    } else if(this.model.includes('GHKC')) {
      this.numberVents = 2;
      this.vent_config = this.node.settings.vent.map((v, index) => {
        if(index > 1) {
          return {...v, ...{step: v ? v.st: 0, vent_name: (output_overrides ? output_overrides[`v${index + 2}`] : this.getVentName(index))}}
        } else { return {} }       
      })
    } else {
      this.numberVents = 1;
      if(this.node && this.node.settings && this.node && this.node.settings.vent && this.node && this.node.settings.vent.length > 0) {
        this.vent_config = this.node.settings.vent.map((v, index) => {
          if(index === 3) {
            let addOns = index > 0 ? {step: v ? v.st: 0, vent_name: (output_overrides ? output_overrides[`v4`] : 'D')} : {}
            return {...v, ...addOns}
          } else { return {} }
        })
      }
      // this.pages = Object.assign([], this.pages.filter((s) => !['Vents'].includes(s.name)));
    }
    
    if(this.node) {
      this.dehumValue = this.getDehumDefaults();
      this.timer_values = (this.node.settings && this.node.settings.group1Timer) ? this.node.settings.group1Timer.filter((t) => Object.keys(t).length > 0).map((t, index) => {
        let val = t;
        if(output_overrides && output_overrides[`t${index + 1}`]) val.name = output_overrides[`t${index + 1}`]
        if(t.isShade && index === 0) { val = {...val, ...this.getShadeInfo()}}
        if(t.isMister) { val = {...val, ...this.getMisterInfo(index)}}
        return val;
      }) : [];
    }

    if(this.node && this.node.status) {
      let base_form_fields = {
        zones: new UntypedFormControl(this.node.status.zones, [Validators.required]),
        day_start: new UntypedFormControl(this.node.settings.dayst, [Validators.required]),      
        day_temp: new UntypedFormControl(this.node.settings.daysp1, [Validators.required]),
        day_temp_zone2: new UntypedFormControl(this.node.settings.daysp1, [Validators.required]),
        nite_start: new UntypedFormControl(this.node.settings.nitst, [Validators.required, Validators.pattern(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)]),
        nite_degrees: new UntypedFormControl(this.node.settings.nitsp1, [Validators.required]),
        nite_degrees_zone2: new UntypedFormControl(this.node.settings.nitsp2, [Validators.required]),
        dif_start: new UntypedFormControl(this.node.settings.difst, [Validators.required, Validators.pattern(/^[0-9]{1,2}:[0-9]{2}$/)]),
        dif_degrees: new UntypedFormControl(this.node.settings.difsp1, [Validators.required]),
        dif_degrees_zone2: new UntypedFormControl(this.node.settings.difsp2, [Validators.required]),
      }

      let ghk_form_fields = {
        day_h1: new UntypedFormControl(this.node.settings.daysp1 - this.node.settings.h1t, [Validators.required]),
        day_c1: new UntypedFormControl(this.node.settings.daysp1 + this.node.settings.c1t, [Validators.required]),
        day_c2: new UntypedFormControl(this.node.settings.daysp1 + this.node.settings.c2t, [Validators.required]),
        day_h2: new UntypedFormControl(this.node.settings.daysp1 - this.node.settings.h2t, [Validators.required]),
        day_c3: new UntypedFormControl(this.node.settings.daysp1 + this.node.settings.c3t, [Validators.required]),
        day_c4: new UntypedFormControl(this.node.settings.daysp1 + this.node.settings.c4t, [Validators.required]),
        h1m: new UntypedFormControl(this.node.settings.h1m, [Validators.required]),
        c1m: new UntypedFormControl(this.node.settings.c1m, [Validators.required]),
        c2m: new UntypedFormControl(this.node.settings.c2m, [Validators.required]),
        h2m: new UntypedFormControl(this.node.settings.h2m, [Validators.required]),
        c3m: new UntypedFormControl(this.node.settings.c3m, [Validators.required]),
        c4m: new UntypedFormControl(this.node.settings.c4m, [Validators.required]),
        vents: new UntypedFormArray([])      
      };

      let rwl_form_fields = {
        day_h1: new UntypedFormControl(this.node.settings.daysp1 - this.node.settings.h1t, [Validators.required]),
        day_h2: new UntypedFormControl(this.node.settings.daysp1 - this.node.settings.h2t, [Validators.required]),
        h1m: new UntypedFormControl(this.node.settings.h1m, [Validators.required]),
        h2m: new UntypedFormControl(this.node.settings.h2m, [Validators.required]),
        vents: new UntypedFormArray([])      
      }

      let ghk_form_fields_zone2 = {
        day_h1: new UntypedFormControl(this.node.settings.daysp1 - this.node.settings.h1t, [Validators.required]),
        h1m: new UntypedFormControl(this.node.settings.h1m, [Validators.required]),
        day_c1: new UntypedFormControl(this.node.settings.daysp1 + this.node.settings.c1t, [Validators.required]),
        day_c2: new UntypedFormControl(this.node.settings.daysp1 + this.node.settings.c2t, [Validators.required]),
        c1m: new UntypedFormControl(this.node.settings.c1m, [Validators.required]),
        c2m: new UntypedFormControl(this.node.settings.c2m, [Validators.required]),
        vents: new UntypedFormArray([]),
        day_h2: new UntypedFormControl(this.node.settings.daysp1 - this.node.settings.h2t, [Validators.required]),
        h2m: new UntypedFormControl(this.node.settings.h2m, [Validators.required]),
        day_c3: new UntypedFormControl(this.node.settings.daysp1 + this.node.settings.c3t, [Validators.required]),
        day_c4: new UntypedFormControl(this.node.settings.daysp1 + this.node.settings.c4t, [Validators.required]),
        c3m: new UntypedFormControl(this.node.settings.c3m, [Validators.required]),
        c4m: new UntypedFormControl(this.node.settings.c4m, [Validators.required]),   
        vent_zone2: new UntypedFormArray([]),        
      };

      let rwl_form_fields_zone2 = {
        day_h1: new UntypedFormControl(this.node.settings.daysp1 - this.node.settings.h1t, [Validators.required]),
        vents: new UntypedFormArray([]),      
        day_h2: new UntypedFormControl(this.node.settings.daysp1 - this.node.settings.h2t, [Validators.required]),
        vent_zone2: new UntypedFormArray([]),
        h1m: new UntypedFormControl(this.node.settings.h1m, [Validators.required]),
        h2m: new UntypedFormControl(this.node.settings.h2m, [Validators.required])
      };

      let other_form_fields = {      
        timers: new UntypedFormArray([]),
        dehumidification: new UntypedFormControl(false),
        dehumidification_form: new UntypedFormControl()
      }

      let timer1_info = this.node.settings.timer ? this.node.settings.timer[0] : this.node.settings.group1Timer[0]
      let timer2_info = this.node.settings.timer ? this.node.settings.timer[1] : this.node.settings.group1Timer[1]
      this.timerTypes = [timer1_info, timer2_info]
      this.one_zone_form_fields = this.model.includes('GHK') ? {...base_form_fields, ...ghk_form_fields, ...other_form_fields} : {...base_form_fields, ...rwl_form_fields, ...other_form_fields};
      this.two_zone_fields = this.model.includes('GHK') ? {...base_form_fields, ...ghk_form_fields_zone2, ...other_form_fields} : {...base_form_fields, ...rwl_form_fields_zone2, ...other_form_fields};
      this.two_zone_field_steps = this.model.includes('GHK') ? {...base_form_fields, ...ghk_form_fields_zone2, ...other_form_fields} : {...base_form_fields, ...rwl_form_fields_zone2, ...other_form_fields};    
      this.programmingForm = new UntypedFormGroup(this.one_zone_form_fields, (this.model.includes('GHK') ? [heatCoolActivationValidation(this.model)] : []) );
      this.initialFormValue = SettingsFileGenerator.generate({...this.programmingForm.value, ...{
        day_start: this.programmingForm.value.day_start,
        dif_start: this.programmingForm.value.dif_start,
        vents: this.vent_config,
        model: this.node.model,
        timers: this.timer_values      
      }}, this.timerTypes, (this.dehumValue ? this.dehumValue : (this.dehumFormComponent ? this.dehumFormComponent.getValue() : {})), this.isCelsius);

      let number_zones = this.programmingForm && this.programmingForm.value && this.programmingForm.value.zones
      let by_zone = this.numberVents && number_zones ? (this.numberVents / number_zones) : 0
      this.vents_available_by_zone = number_zones === 1 ? Math.ceil(by_zone) : Math.floor(by_zone)

      this.ventNumbers = this.ventNumberOptions[this.programmingForm && this.programmingForm.value && this.programmingForm.value.zones || 1][this.model.includes('RWL') ? 'rwl' : 'ghk'][`zone1`]    
      if(this.model && (!this.model.includes('GHKC') && !this.model.includes('RWL'))) { this.ventNumbers = [3]; }    
      this.active_vent = this.vent_config[this.ventNumbers[0]]
      this.changeZones({})
      this.changeStep(0);      

      let allControls = this.nodeService.nodeListSubscription.getValue()
      this.allUserControllers = allControls && allControls.nodes && allControls.nodes.filter((c) => this.node && this.node.site_id === c.site_id && this.node.node_id !== c.node_id );
      this.nodeService.nodeListSubscription.subscribe((listData) => {
        if(listData && listData.nodes) { 
          this.allUserControllers = listData.nodes
            .filter((c) => {
              let same_site = this.node && this.node.site_id === c.site_id;
              let not_current_node = this.node.node_id !== c.node_id;
              let same_model = this.node.model === c.model;

              return same_site && not_current_node && same_model;
            })
            .map((n) => { return {name: (n.api_name || n.node_name), node_id: n.node_id, zones: n.status.number_zones}}); 
        }
      })
    }
  }

  stepsLength() {
    let has_zones = this.programmingForm && this.programmingForm.value && this.programmingForm.value.zones;
    return has_zones && this.programmingForm && this.programmingForm.value.zones > 1 ? (this.steps ? this.steps.length : 0) : (this.steps ? this.steps.filter((s) => s && !s.includes('zone2')).length : 0);
  }

  changeCurrentStepIndex(event) {
    this.current_step_index = this.steps.findIndex((s) => this.current_step === s);
  }

  updateFormWithCurrentPageValues(previous_index) {    
    let pageToUpdate = this.viewablePages[previous_index]
    if(pageToUpdate && pageToUpdate.steps && (pageToUpdate.steps[0].includes('vents') || pageToUpdate.steps[0].includes('dehum') || pageToUpdate.steps[0].includes('timers'))) { //update form before leaving
      if(pageToUpdate.steps[0].includes('vents')) {this.addVent()}
      if(pageToUpdate.steps[0].includes('dehum')) {this.updateDehum()}
      if(pageToUpdate.steps[0].includes('timers')) {this.updateTimer()}        
    } 
  }

  changeStep(index, back?) {  
    let previous_index = parseInt(this.current_step_index);
    let isVent2 = false
    if(this.current_step && this.current_step.includes('vents_zone2')) {isVent2 = true;}
    this.current_step_index = index;    
    if(index !== 0) {
      this.current_page = back ? this.viewablePages[previous_index - 1] : this.viewablePages[previous_index + 1] 
    } else {
      this.current_page = this.viewablePages[index]
    }
    this.current_step = this.current_page && this.current_page.steps[0]
    this.updateFormWithCurrentPageValues(previous_index)
    
    let next_index = this.current_step_index && (previous_index < this.current_step_index) ? 1 : -1;
    if(this.current_page && this.current_page.steps.includes("vents_zone2")
      && this.programmingForm && this.programmingForm.value && this.programmingForm.value.zones === 2
    ) {        
        this.ventNumbers = this.ventNumberOptions[this.programmingForm && this.programmingForm.value && this.programmingForm.value.zones || 1][this.model.includes('RWL') ? 'rwl' : 'ghk'][`zone2`]
        this.setActiveVent(this.ventNumbers[0])
      } 

    if(!this.current_step) {
      this.review()
    }
    this.generateFileAndGraph();
  }

  review() {
    this.reviewing = true;
    this.generateFileAndGraph();
  }

  generateFileAndGraph() {
    try {
      if(this.programmingForm.value) {
        let form = this.programmingForm.value;
        let settingsFile = SettingsFileGenerator.generate({...this.programmingForm.value, ...{
          day_start: this.programmingForm.value.day_start,
          dif_start: this.programmingForm.value.dif_start,
          vents: this.vent_config,
          model: this.node.model,
          timers: this.timer_values
        }}, this.timerTypes, (this.dehumValue ? this.dehumValue : (this.dehumFormComponent ? this.dehumFormComponent.getValue() : {})));

        if(this.programmingForm.value.zones && this.programmingForm.value.zones === 2) {
          let ghkKeys = {zone1: ['c1t', 'c3t','h1t'], zone2: ['c2t','c4t','h2t']}
          let rwlKeys = {zone1: ['v1', 'v3', 'h1t'], zone2: ['v2', 'v4', 'h2t']}
          TimelineChartGenerator.draw('#timelinechart', this.programmingForm.value.dif_start, this.programmingForm.value.day_start, form.nite_start,
            {dif: settingsFile.difsp1, day: settingsFile.daysp1, nite: settingsFile.nitsp1},
            this.model.includes('GHK') ?
              ghkKeys.zone1.map((k) => { return {key: k, val: settingsFile[k]} }) :
              rwlKeys.zone1.map((k) => {
                let val = k.includes('v') ? settingsFile.vent[parseInt(k.split('v')[1]) - 1].st : settingsFile[k]
                return {key: k, val: val}
              })
          )

          TimelineChartGenerator.draw('#timelinechart2', this.programmingForm.value.dif_start, this.programmingForm.value.day_start, form.nite_start,
            {dif: settingsFile.difsp2, day: settingsFile.daysp2, nite: settingsFile.nitsp2},
            this.model.includes('GHK') ?
              ghkKeys.zone2.map((k) => { return {key: k, val: settingsFile[k]} }) :
              rwlKeys.zone2.map((k) => { return {key: k, val: settingsFile[k]} })
          )
        } else {
          let ghkKeys = ['c1t', 'c2t', 'c3t', 'c4t', 'h1t', 'h2t']
          let rwlKeys = ['v1', 'v2', 'v3', 'v4', 'h1t', 'h2t']
          TimelineChartGenerator.draw('#timelinechart', this.programmingForm.value.dif_start, this.programmingForm.value.day_start, form.nite_start,
            {dif: settingsFile.difsp1, day: settingsFile.daysp1, nite: settingsFile.nitsp1},
            this.model.includes('GHK') ?
              ghkKeys.map((k) => { return {key: k, val: settingsFile[k]} }) :
              rwlKeys.map((k) => {
                let val = k.includes('v') ? settingsFile.vent[parseInt(k.split('v')[1]) - 1].st : settingsFile[k]
                return {key: k, val: val}
              })
          )

          TimelineChartGenerator.remove('#timelinechart2')
        }
      }
    } catch(e) { console.log(e)}
  }

  backToGuide() {
    this.reviewing = false;
  }

  private deepEqual(x, y, equalityFunction) { 
    return (x && y && typeof x === 'object' && typeof y === 'object') ?
      (Object.keys(x).length === Object.keys(y).length) &&
        Object.keys(x).reduce(function(isEqual, key) {
          return isEqual && equalityFunction(x[key], y[key], equalityFunction);
        }, true) : (x === y);
  }

  finalImport(node_id = null, callback = null) {
    try {
      let settingsFile = SettingsFileGenerator.generate({...this.programmingForm.value, ...{
        day_start: this.programmingForm.value.day_start,
        dif_start: this.programmingForm.value.dif_start,
        vents: this.vent_config,
        model: this.node.model,
        timers: this.timer_values
      }}, this.timerTypes, (this.dehumValue ? this.dehumValue : this.dehumFormComponent.getValue()), this.isCelsius)

      let id = node_id || this.node.node_id;
      this.updateOutputNames();
      let changedSettings = Object.keys(settingsFile).filter((key) => { 
        return !this.deepEqual(this.initialFormValue[key], settingsFile[key], this.deepEqual)
      }).reduce((agg, key) => { agg[key] = settingsFile[key]; return agg; }, {})

      this.nodeService.importSettings(this.node.node_id, changedSettings)
                      .then(() => {
                        if(callback) { callback(`Successfully imported settings for ${id}`); } else {
                          this.showAlert(`Your settings were imported for ${id}. Please wait a few minutes for the controller to have time to apply them. `);
                        }
                      })
                      .catch((error) => {
                        this.showMessage(`Sorry there was an error applying setting updates for ${id} at this time, please try again soon.`)
                      })   
    } catch(e) { console.log(e); } 
  }

  async importToController() {
    this.finalReview = true;    
    if(!this.reviewing) this.reviewing = true;
  }

  vents() {
    return this.programmingForm.get('vents') as UntypedFormArray;
  }

  timers() {
    return this.programmingForm.get('timers') as UntypedFormArray;
  }

  ventsAvailable() {
    let no_vents_configured = (!this.vent_config && this.numberVents > 0)
    let number_zones = this.programmingForm && this.programmingForm.value && this.programmingForm.value.zones
    let by_zone = this.numberVents && number_zones ? (this.numberVents / number_zones) : 0
    this.vents_available_by_zone = number_zones === 1 ? Math.ceil(by_zone) : Math.floor(by_zone)

    let vent_config_copy = Array.from(this.vent_config)
    let vents_configured = !this.current_step.includes('zone2') ? (Array.from(vent_config_copy).splice(0, this.vents_available_by_zone).length) : Array.from(vent_config_copy).splice(0+this.vents_available_by_zone, this.vents_available_by_zone).length
    return no_vents_configured || (vents_configured < this.vents_available_by_zone)
  }

  ventForZone() {
    let vent_config_copy = Array.from(this.vent_config)
    return this.vent_config.map((v, index) => v.vent_name || `Vent ${index}`);
  }

  subFormInvalid() {
    try {
      if(this.current_page && this.current_page.steps.includes('dehumidification')) {
        return this.dehumFormComponent && !this.dehumFormComponent.valid();
      } else if(this.current_page && (this.current_page.steps.includes('vents_zone2') || this.current_page.steps.includes('vents')) && this.vent_editing_index) {
        return this.vent_editing_index && this.ventFormComponents.length >= (this.vent_editing_index + 1) ? this.ventFormComponents && !this.ventFormComponents.find((_, i) => i == this.vent_editing_index).valid() : true;
      } else if(this.current_page && (this.current_page.steps.includes('timers')) && this.current_timer) {
        return this.current_timer ? this.timerFormComponents && this.timerFormComponents.find((_, i) => i == this.current_timer) && !this.timerFormComponents.find((_, i) => i == this.current_timer).valid() : true;
      } else { return false; }
    } catch(e) {
      console.log(`sub form invalid`)
      console.log(e)
    }
  }

  addVent() {
    if(this.vent_editing_index !== undefined) {
      this.addVentToProgram({vent: this.ventFormComponents.find((_, i) => i == this.vent_editing_index).getValue(), index: this.vent_editing_index, no_change_step: true});
    }
  }

  notEditingVent(vent_config, vent_index) {
    return (vent_config.length - 1) !== vent_index;
  }

  nextVent(is_back = false) {
    if(this.vent_editing_index !== undefined && this.ventNumbers.includes(this.vent_editing_index)) {
      this.setActiveVent(this.ventNumbers[this.ventNumbers.length -1] === this.vent_editing_index ? 0 : (this.vent_editing_index + 1));
    } else {
      this.changeStep(this.current_step_index + 1)
      this.vent_editing_index = undefined;
      this.active_vent = undefined;
    }
  }

  addVentToProgram(event) {    
    this.vent_config[event.index] = event.vent;  
    this.setActiveVent(this.ventNumbers[this.ventNumbers.findIndex((v) => v === this.vent_editing_index) + 1]);
    
    if(!this.ventNumbers.includes(this.vent_editing_index)) {
      this.active_vent = undefined;
      if(!event.no_change_step) this.changeStep(this.current_step_index + 1)      
    } 
  }

  editVent(index) {
    this.setActiveVent(index)
  }

  nextDehum(is_back = false) {
    this.dehumFormComponent.next(is_back);
  }

  updateDehum(event = null) {
    if(!event) {
      event = {value: this.dehumFormComponent.getValue(), change_step: false}
    }
    
    if(event && event.change_step) {
      let next_step = event.is_back ? this.current_step_index - 1 : this.current_step_index + 1
      this.changeStep(next_step, (event.is_back ? true: false));
    }

    if(event.val) {
      this.dehumValue = event.val
    }
  }

  nextTimer(is_back = false) {    
    if(this.current_timer !== undefined) { // && this.timer_values.length < 2) {
      try {
        this.timerFormComponents && this.timerFormComponents.find((_, i) => i == this.current_timer).next(is_back);        
      } catch(e) { console.log(e); }
    } else {
      this.changeStep(this.current_step_index + 1)
      this.current_timer = 0;
    }
  }

  updateTimer(event = null) {
    if(!event) {
      event = {val: this.timerFormComponents && this.timerFormComponents.find((_, i) => i == this.current_timer).getValue(), change_step: false }
    }
    
    if(event.is_back) {
      this.changeStep(this.current_step_index - 1, true)
    } else if(event.change_step) {
      if(this.current_timer === 1) {
        this.changeStep(this.current_step_index + 1)
      } else {
        this.current_timer = this.current_timer + 1;
      }
    }

    if(event.val) {
      if(this.timer_values.length >= event.index) {
        this.timer_values[event.index] = event.val;
        event.val = Object.keys(event.val).reduce((formGroupValue, key) => { formGroupValue[key] = new UntypedFormControl(event.val[key]); return formGroupValue;}, {})
        this.timers()[event.index] = new UntypedFormGroup(event.val)
      } else {
        this.timer_values.push(event.val);
        event.val = Object.keys(event.val).reduce((formGroupValue, key) => { formGroupValue[key] = new UntypedFormControl(event.val[key]); return formGroupValue;}, {})
        this.timers().push(new UntypedFormGroup(event.val))
      }
    }
  }

  async showAlert(message) {    
    if(this.allUserControllers && this.allUserControllers.length > 0) {
      let inputOptions:AlertInput[] = this.allUserControllers.map(c => {
        return {
          type: 'checkbox',
          value: c.node_id,
          label: c.name
        }
      })

      let alert = await this.alertController.create({
        header: "Apply To Other Houses",
        message: "Would you like to import these settings to other greenhouses?",
        inputs: inputOptions,
        buttons: [
          {
            text: 'Yes, Select Other Controllers',
            cssClass: 'primary',
            handler: (data) => {
              let importsForOthers = data.map((id) => {
                return new Promise((resolve) => {
                  this.finalImport(id, resolve)
                })
              })
              Promise.all(importsForOthers).then((importMessages) => { 
                importMessages.forEach((msg) => { this.showMessage(msg); }); 
                this.finalReview = false; 
              });
            }
          }, { text: 'Not Now' }
        ]
      })

      alert.present();
    } else {
      let toast = await this.toastCtrl.create({message: "Your settings were successfully saved.", duration: 1000, position: 'top'});
      toast.onDidDismiss().then((data) => {this.finalReview = false;})
      toast.present();
    }
  }

  async showMessage(message) {
    let toast = await this.toastCtrl.create({
      message: message,
      duration: 1000,
      position: 'top'
    });

    toast.present();
  }

  changeZones(event) {
    if(this.programmingForm && this.programmingForm.value && parseInt(this.programmingForm.value.zones) === 2) {
      this.steps = Object.keys(this.two_zone_field_steps)
      this.two_zone_fields.zones = new UntypedFormControl(this.programmingForm.value.zones, [Validators.required])
      this.programmingForm = new UntypedFormGroup(this.two_zone_fields, this.model.includes('GHK') ? [heatCoolActivationValidation(this.model)] : []);
      this.viewablePages = Object.assign([], this.pages.filter((s) => !['Outputs'].includes(s.name)));
    } else {
      this.steps = Object.keys(this.one_zone_form_fields).filter((s) => !['nite_degrees_zone2', 'dif_degrees_zone2', 'day_temp_zone2'].includes(s));
      this.programmingForm = new UntypedFormGroup(this.one_zone_form_fields, this.model.includes('GHK') ? [heatCoolActivationValidation(this.model)] : [] );
      this.viewablePages = Object.assign([], this.pages.filter((s) => !['Zone 2 Outputs', 'Zone 2 Vents', 'Zone 1 Outputs'].includes(s.name)));
    }
    
    if(this.narrow) {
      this.viewablePages = this.viewablePages.filter((page) => this.narrow.includes(page.name))
    }
    console.log(this.viewablePages)
  }

  getDehumDefaults() {
    if(this.node && this.node.settings) {
      let stages = []
      if(this.node.settings.dhDif) { stages.push('dif')}
      if(this.node.settings.dhDay) { stages.push('day')}
      if(this.node.settings.dhNight) { stages.push('nite')}

      let outputs = [];
      if(this.node.settings) {
        var inBits = (parseInt(this.node.settings.dhSetup)).toString(2)
        var outputSettings = inBits.padStart(8, "0");
        var splitSettings = outputSettings.split("").map((settingOn) => {return settingOn === "0" ? false : true });
        if(splitSettings[7]) { this.node.model.includes('GHK') ? outputs.push("Cool 1") : outputs.push("HAF")};
        if(splitSettings[6]) { this.node.model.includes('GHK') ? outputs.push("Cool 2") : outputs.push("Vent 1")};
        if(splitSettings[5]) { this.node.model.includes('GHK') ? outputs.push("Cool 3") : outputs.push("Vent 2")};
        if(splitSettings[4]) { this.node.model.includes('GHK') ? outputs.push("Cool 4") : outputs.push("Vent 3")};
        if(splitSettings[3]) outputs.push("Heat 2");
        if(splitSettings[2]) outputs.push("Heat 1");
        if(splitSettings[1]) { this.node.model.includes('GHK') ? outputs.push("Percent 1") : outputs.push("Vent 4")};
        if(splitSettings[0]) outputs.push("Enable Heat");
      }

      return {
        start: this.node.settings.dhStart,
        vent_mode: this.node.settings.dhVentMode,
        humidity_set_point: this.node.settings.dhSp,
        stages: stages,
        humid_sensor: true,
        exhaust_1: this.node.settings.dhFan1 || "0:00",
        exhaust_2: this.node.settings.dhFan2 || "0:00",
        preheat: this.node.settings.dhHeat || "0:00",
        low_limit: (this.node.settings.dhLoTemp === 0) ? 0 : this.node.settings.dhLoTemp,
        high_limit: (this.node.settings.dhHiTemp === 0) ? 0 : this.node.settings.dhHiTemp,
        high_limit_val: ((this.node.settings.dhLoTemp === 0) ? "Heat 1 Activation Temperature" : this.node.settings.dhLoTemp),
        low_limit_val: ((this.node.settings.dhHiTemp === 0) ? `${(this.node.model.includes('GHK') ? "Cool 2" : "Vent 2")} Activation Temperature` : this.node.settings.dhHiTemp),
        repeat: this.node.settings.dhRepeat || false,
        start_time: this.node.settings.dhStartTime,
        dhSetup: this.node.settings.dhSetup,
        outputs: outputs
      }
    }
  }

  getMisterInfo(index) {
    if(this.node && this.node.settings && (this.node.settings.group1Mister || this.node.settings.group2Mister) && index !== undefined) {
      let misterSettingsKey = (8 > index) ? 'group1Mister' : 'group2Mister'
      if(index >= 8) index = index - 8;
      return this.node.settings[misterSettingsKey][index];
    } else {
      return {}
    }
  }

  getShadeInfo() {
    if(this.node && this.node.settings) {
      return {
        shadeEnable: this.node.settings.shadeEnable,
        shadeOpenTime: this.node.settings.shadeOpenTime,
        shadePause: this.node.settings.shadePause,
        shadeSlowRetract: this.node.settings.shadeSlowRetract,
        shadeStep: this.node.settings.shadeStep,
        shadeTempSP: this.node.settings.shadeTempSP
      }
    }
  }  

  showHideGraph() {
    this.showGraph = !this.showGraph;
  }

  getVentName(index) {    
    let defaultName = this.model.includes('RWL') ? ['A', 'B', 'C', 'D'][index] : ['', '', 'C', 'D'][index];
    if(this.vent_config && this.vent_config[index] && this.vent_config[index].vent_name) {
      return this.vent_config[index].vent_name;
    } else {
      return defaultName;
    }
  }

  updateOutputNames() {
    let modelToNames = {
      "CB-GHK": [
        {output: 'c1', default_name:"Cool 1"}, {output: 'c2', default_name:"Cool 2"}, {output: 'c3', default_name:"Cool 3"}, {output: 'c4', default_name:"Cool 4"}, {output: 'v4', default_name:"Vent D"}, {output: 'h1', default_name:"Heat 1"}, {output: 'h2', default_name:"Heat 2"}, {output: 't1', default_name:"Timer 1"}, {output: 't2', default_name:"Timer 2"}],
      "CB-GHKC": [
        {output: 'c1', default_name:"Cool 1"}, {output: 'c2', default_name:"Cool 2"}, {output: 'c3', default_name:"Cool 3"}, {output: 'c4', default_name:"Cool 4"}, {output: 'v3', default_name:"Vent C"}, {output: 'v4', default_name:"Vent D"}, {output: 'h1', default_name:"Heat 1"}, {output: 'h2', default_name:"Heat 2"}, {output: 't1', default_name:"Timer 1"}, {output: 't2', default_name:"Timer 2"}],
      "CB-GHKHC": [
        {output: 'c1', default_name:"Cool 1"}, {output: 'c2', default_name:"Cool 2"}, {output: 'c3', default_name:"Cool 3"}, {output: 'c4', default_name:"Cool 4"}, {output: 'v3', default_name:"Vent C"}, {output: 'v4', default_name:"Vent D"}, {output: 'h1', default_name:"Heat 1"}, {output: 'h2', default_name:"Heat 2"}, {output: 'h3', default_name:"Heat 3"}, {output: 't1', default_name:"Timer 1"}, {output: 't2', default_name:"Timer 2"}],
      "CB-GHKH": [
        {output: 'c1', default_name:"Cool 1"}, {output: 'c2', default_name:"Cool 2"}, {output: 'c3', default_name:"Cool 3"}, {output: 'c4', default_name:"Cool 4"}, {output: 'v4', default_name:"Vent D"}, {output: 'h1', default_name:"Heat 1"}, {output: 'h2', default_name:"Heat 2"}, {output: 'h3', default_name:"Heat 3"}, {output: 't1', default_name:"Timer 1"}, {output: 't2', default_name:"Timer 2"}],
      "CB-RWL": [
        {output: 'v1', default_name:"Vent A"}, {output: 'v2', default_name:"Vent B"}, {output: 'v3', default_name:"Vent C"}, {output: 'v4', default_name:"Vent D"}, {output: 'h1', default_name:"Heat 1"}, {output: 'h2', default_name:"Heat 2"}, {output: 't1', default_name:"Timer 1"}, {output: 't2', default_name:"Timer 2"}],
      "CB-TB": [0,1,2,3,4,5,6,7,8,9,10].map((t) => { return {default_name: `Timer ${t}`, output: `t${t}`}})
    }

    if(modelToNames[this.model]) {
      let values = modelToNames[this.node.model].reduce((all, output) => {
        let default_val = (this.node && this.node.output_overrides) ? this.node.output_overrides[output.output] : output.default_name;
        let ventOverride = output.output.includes('v') && output.output.length === 2 && this.getVentName(parseInt(output.output.split('v')[1]));
        let timerOverride = output.output.includes('t') && output.output.length === 2 && this.timer_values[parseInt(output.output.split('t')[1])] && this.timer_values[parseInt(output.output.split('t')[1])].name
        all[output.output] = (ventOverride || timerOverride) ? (ventOverride || timerOverride) : default_val;
        return all;
      }, {})

      this.nodeService.updateOutputNames(this.node.node_id, values)
          .then(() => {
            this.showMessage("Your output names were updated");
          })
          .catch(() => {
            this.showMessage("Your output names could not be updated at this time. Pleast try again.");
          })
    }
  }

  setTimer(i) {
    this.current_timer = i;
  }

  useDayForNite() {        
    var niteStartInput = this.programmingForm.get("nite_start");
    niteStartInput.setValue('17:00');

    var niteDegreesInput = this.programmingForm.get("nite_degrees");
    niteDegreesInput.setValue(this.programmingForm.value ? this.programmingForm.value.day_temp : 65);    

    var niteDegreesZone2Input = this.programmingForm.get("nite_degrees_zone2");
    if(this.programmingForm.value.zones > 1) {      
      niteDegreesZone2Input.setValue(this.programmingForm.value ? this.programmingForm.value.day_temp_zone2 : 65);
    } else {
      niteDegreesZone2Input.setValue(this.programmingForm.value ? this.programmingForm.value.day_temp : 65);
    }
  }

  useDayForDif() {
    var difStartInput = this.programmingForm.get("dif_start");
    if(this.programmingForm.value && this.programmingForm.value.day_start) {
      let splitDayStart = this.programmingForm.value.day_start.split(":");
      let difStartTime = this.programmingForm.value && splitDayStart[1];
      if(parseInt(difStartTime) === 0) {
        difStartTime = `${parseInt(this.programmingForm.value.day_start.split(":")[0]) - 1}:59`
      } else {
        difStartTime = `${this.programmingForm.value.day_start.split(":")[0]}:${parseInt(difStartTime) - 1}`
      }

      difStartInput.setValue(difStartTime);
    }    

    var difDegreesInput = this.programmingForm.get("dif_degrees");
    difDegreesInput.setValue(this.programmingForm.value ? this.programmingForm.value.day_temp : 65);
    
    var difDegreesZone2Input = this.programmingForm.get("dif_degrees_zone2");
    if(this.programmingForm.value.zones > 1) {      
      difDegreesZone2Input.setValue(this.programmingForm.value ? this.programmingForm.value.day_temp_zone2 : 65);
    } else {
      difDegreesZone2Input.setValue(this.programmingForm.value ? this.programmingForm.value.day_temp : 65);
    }
  }

  setValue(field, val) {
    var inputField = this.programmingForm.get(field);
    inputField.setValue(val ? val : 'AUTO');
  }

  getVentLabel(index, zone) {
    if(index === 0) {
      return (this.node.model.includes('RWL')) ? 'A' : 'C'
    } else if(index === 1) {
      return (this.node.model.includes('RWL')) ? 'B' : 'D'
    } else if(index === 2) {
      return "C"
    } else {
      return "D"
    }
  }
  
  setPage(page, i = null) {
    i = !i ? this.viewablePages.findIndex((p) => this.current_page.name === p.name) : i;
    this.updateFormWithCurrentPageValues(i)
    console.log(`set page - ${JSON.stringify(page)}`)
    this.current_page = page; 
    this.current_step = page && page.steps[0]; 
    this.current_step_index = i;    
    if(this.current_page && this.current_page.name === 'Vents') {
      this.setActiveVent(this.ventNumbers[0])
    }

    if(this.current_page && this.current_page.name === 'Zone 2 Vents') {
      this.ventNumbers = this.ventNumberOptions[this.programmingForm && this.programmingForm.value && this.programmingForm.value.zones || 1][this.model.includes('RWL') ? 'rwl' : 'ghk'][`zone2`]
      if(this.model && this.model.includes('GHK') && !this.model.includes('GHKC')) { this.ventNumbers = [3]; }
      this.setActiveVent(this.ventNumbers[0])
    }

    if(this.current_page && this.current_page.name === 'Timers') {
      this.timer_editing_index = 0;
    }
        
    if(i && this.viewablePages && i > this.viewablePages.length) {
      this.reviewing = true;
    }
  }

  setActiveVent(index) {
    this.vent_editing_index = index
    this.active_vent = this.vent_config ? this.vent_config[this.vent_editing_index] : {vent_name: 'Vent C'}
  }

  getOffset(key) {
    if(this.programmingForm) {
      if(key.includes('c')) {
        return (parseInt(this.programmingForm.value[key]) - parseInt(this.programmingForm.value.day_temp))
      } else {
        return (parseInt(this.programmingForm.value.day_temp) - parseInt(this.programmingForm.value[key]))
      }
    } else {
      return 0;
    }    
  }
}
