import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl, UntypedFormArray } from '@angular/forms';

@Component({
  selector: 'app-dehumidification-form',
  templateUrl: './dehumidification-form.component.html',
  styleUrls: ['./dehumidification-form.component.scss'],
})
export class DehumidificationFormComponent implements OnInit {
  public dehumidification: UntypedFormGroup;
  @Input() model = "GHK";
  @Input() c2_temp;
  @Input() h1_temp;
  @Input() defaults;
  @Output() nextVal = new EventEmitter<any>();
  public current_step = "humid_sensor";
  public current_step_index = 0;

  private humidify_steps = [];
  private timed_steps = [];
  public pages = [
    {steps: ['humid_sensor', 'humidity_set_point', 'start', 'repeat']},
    {steps: ['high_limit', 'low_limit']},
    {steps: ['stages']},
    {steps: ['outputs']},
    {steps: ['exhaust_1', 'preheat', 'exhaust_2']}
  ]
  public current_page;

  constructor() {}

  ngOnInit() {
    if(this.defaults) {
      var inBits = (parseInt(this.defaults.dhSetup)).toString(2)
      var outputSettings = inBits.padStart(8, "0");
      var splitSettings = outputSettings.split("").map((settingOn) => {return settingOn === "0" ? false : true });

      let outputs = []
      if(splitSettings[7]) { this.model.includes('GHK') ? outputs.push(new UntypedFormControl('c1')) : outputs.push(new UntypedFormControl('haf'))};
      if(splitSettings[6]) { this.model.includes('GHK') ? outputs.push(new UntypedFormControl('c2')) : outputs.push(new UntypedFormControl('v1'))};
      if(splitSettings[5]) { this.model.includes('GHK') ? outputs.push(new UntypedFormControl('c3')) : outputs.push(new UntypedFormControl('v2'))};
      if(splitSettings[4]) { this.model.includes('GHK') ? outputs.push(new UntypedFormControl('c4')) : outputs.push(new UntypedFormControl('v3'))};
      if(splitSettings[3]) outputs.push(new UntypedFormControl('h2'));
      if(splitSettings[2]) outputs.push(new UntypedFormControl('h1'));
      if(splitSettings[1]) { this.model.includes('GHK') ? outputs.push(new UntypedFormControl('p1')) : outputs.push(new UntypedFormControl('v4'))};
      if(splitSettings[0]) outputs.push(new UntypedFormControl('enableHeat'));

      this.dehumidification = new UntypedFormGroup({
        mode: new UntypedFormControl(false),
        humid_sensor: new UntypedFormControl(this.defaults.humid_sensor.toString()),
        humidity_set_point: new UntypedFormControl(this.defaults.humidity_set_point),
        stages: new UntypedFormArray(this.defaults.stages ? this.defaults.stages.map((o) => new UntypedFormControl(o)) : []),
        start: new UntypedFormControl(this.defaults.start_time, [Validators.pattern(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)]),
        repeat: new UntypedFormControl(this.defaults.repeat || false),
        high_limit: new UntypedFormControl(this.defaults.high_limit === 0 ? '0' : 'high'),
        high_limit_val: new UntypedFormControl(this.defaults.high_limit),
        low_limit: new UntypedFormControl(this.defaults.low_limit === 0 ? '0' : 'low'),
        low_limit_val: new UntypedFormControl(this.defaults.low_limit),
        outputs: new UntypedFormArray(outputs),
        exhaust_1: new UntypedFormControl(this.defaults.exhaust_1),
        preheat: new UntypedFormControl(this.defaults.preheat),
        exhaust_2: new UntypedFormControl(this.defaults.exhaust_2)
      })
    }

    let common_steps = ["high_limit", "low_limit", "outputs", "exhaust_1", "preheat", "exhaust_2"]
    this.humidify_steps = ["humid_sensor", "humidity_set_point", "stages"].concat(common_steps)
    this.timed_steps = ["humid_sensor", "start", "repeat"].concat(common_steps)
    this.current_page = this.pages[0];
  }  

  public next(is_back = false) {
    if(is_back) {
      this.current_step_index = this.current_step_index - 1;
    } else {
      this.current_step_index = this.current_step_index + 1;
    }

    if(this.dehumidification.value.humid_sensor) {
      this.current_step = this.humidify_steps[this.current_step_index]
    } else {
      this.current_step = this.timed_steps[this.current_step_index]
    }
    this.current_page = this.pages[this.current_step_index];

    this.nextVal && this.nextVal.emit({is_back: is_back, val: this.getValue(), change_step: this.current_step === undefined})
  }

  private stages(): UntypedFormArray {
    return this.dehumidification.get('stages') as UntypedFormArray;
  };

  addStage(val) {
    if (!this.dehumidification.value.stages || (this.dehumidification.value.stages && !this.dehumidification.value.stages.includes(val))) {
      this.stages().push(new UntypedFormControl(val));
    }
  }

  private outputs(): UntypedFormArray {
    return this.dehumidification.get('outputs') as UntypedFormArray;
  };

  addOutput(val) {
    if (!this.dehumidification.value.outputs || (this.dehumidification.value.outputs && !this.dehumidification.value.outputs.includes(val))) {
      this.outputs().push(new UntypedFormControl(val));
    }
  }

  public getValue() {
    let formCopy = Object.assign({}, this.dehumidification.value)
    var isOnToBit = (setting) => { return this.dehumidification && this.dehumidification.value && this.dehumidification.value.outputs && this.dehumidification.value.outputs.includes(setting) ? "1" : "0" };
    var keys = this.model.includes('GHK') ? ["enableHeat", "p1", "h1", "h2", "c4", "c3", "c2", "c1"] : ["enableHeat", "v4", "h1", "h2", "v3", "v2", "v1", "haf"]
    const dehumidificationSettingBitMask = keys.map((key) => {
      return isOnToBit(key)
    }).join("")

    formCopy.low_limit = this.dehumidification.value.low_limit === 'low' ? this.dehumidification.value.high_limit_val : 0;
    formCopy.high_limit = this.dehumidification.value.high_limit === 'high' ? this.dehumidification.value.low_limit_val : 0;
    formCopy.dhSetup = parseInt(dehumidificationSettingBitMask, 2)
    return formCopy;
  }

  public valid() {
    let is_invalid
    if(this.current_step && this.dehumidification) {
      is_invalid = this.dehumidification[this.current_step] && this.dehumidification[this.current_step].dirty && !this.dehumidification.controls[this.current_step].valid;
    }
    
    return !is_invalid
  }
}
