<div class="wind-rain-node-name"
  [class.warning-on]='alarmOn()'
  [class.offline]='node && !node.online'
  [class.dashboard]="asDashboard"
  (click)="selectNode(node.node_id)">
  <div [class.warning-on]='alarmOn()' [class.dashboard-name]="asDashboard">
    <div *ngIf="node"><h1>{{node.node_name || (node.model.startsWith('CB') ? 'Weather Boss' : 'Wind / Rain Alarm')}}</h1></div>
    <div class="model">
      <div class="controller_name" *ngIf="!asDashboard">{{node ? node.model : ''}}</div>
      <span *ngIf="node && !node.in_use">&nbsp;|&nbsp;NOT IN USE</span>
    </div>
  </div>
  <div class="icons" *ngIf="!asDashboard">
    <ion-icon
      *ngIf='alarmOn()'
      name='warning'
      class="note-alert warning">
    </ion-icon>
    <div *ngIf="node && !node.online" class="offline">
      <ion-icon class="warning" color="danger" name="eye-off-outline"></ion-icon>
      <p>OFF</p>
    </div>
    <ion-icon class="go-to-settings" name='arrow-forward-outline'></ion-icon>
  </div>
</div>
<div class="wr-dashboard-timer-info" *ngIf="node && node.status">
  <div class="headers">
    <div class="th" *ngIf="node.status && node.status.light">
      <ion-icon name="sunny"></ion-icon>
    </div>
    <div class="th">
      <ion-icon name="thermometer"></ion-icon>
    </div>
    <div class="th">
      <ion-icon name="leaf"></ion-icon>
    </div>
    <div class="th" *ngIf="node.status && node.status.wind && node.status.wind.direction">
      <ion-icon *ngIf="node.status.wind && node.status.wind.direction === 'N'" name="arrow-up-outline" color="background-gray"></ion-icon>
      <ion-icon *ngIf="node.status.wind && node.status.wind.direction === 'E'" name="arrow-forward-outline" color="background-gray"></ion-icon>
      <ion-icon *ngIf="node.status.wind && node.status.wind.direction === 'S'" name="arrow-down-outline" color="background-gray"></ion-icon>
      <ion-icon *ngIf="node.status.wind && node.status.wind.direction === 'W'" name="arrow-back-outline" color="background-gray"></ion-icon>
    </div>
  </div>
  <div>
    <div *ngIf="node.status && node.status.light"
      [class.weather_boss]="node.status && node.status.light && node.status.wind && node.status.wind.direction">
      {{node.status.light || 'N/A'}}
    </div>
    <div [class.wr_alarm]="node.status && !node.status.light && (!(node.status.wind && node.status.wind.direction) || !node.status.wind)"
      [class.weather_boss]="node.status && node.status.light && node.status.wind && node.status.wind.direction">
      {{node.status.outsideTemp}}˚
    </div>
    <div [class.wr_alarm]="node.status && !node.status.light && (!(node.status.wind && node.status.wind.direction) || !node.status.wind)"
      [class.weather_boss]="node.status && node.status.light && node.status.wind && node.status.wind.direction">
      {{node.status.wind ? node.status.wind.current : 'Wind N/A'}} <span class="mph">{{node.settings && node.settings.english ? 'MPH' : 'KPH'}}</span>
    </div>
    <div *ngIf="node.status && node.status.wind && node.status.wind.direction"
      [class.weather_boss]="node.status && node.status.light && node.status.wind && node.status.wind.direction">
      {{node.status.wind && node.status.wind.direction ? node.status.wind.direction : 'Dir. N/A'}}
    </div>
  </div>
</div>
