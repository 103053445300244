<div id="controller-info-container">
  <div class="column general-menu" *ngIf="!selected">    
    <div class="menu">
      <h4>Menu</h4>
      <ul>        
        <li *ngIf="(controller && controller.model && (controller.model.includes('GHK') || controller.model.includes('RWL')))" (click)="section = 'setup'" [class.selected]="section === 'setup'">
          Configuration
        </li>
        <li (click)="section = 'settings'" [class.selected]="section === 'settings'">
          Settings
        </li>
        <li *ngIf="(controller && controller.model && (!controller.model.includes('WB') && !controller.model.includes('WR')))" (click)="section = 'outputs'" [class.selected]="section === 'outputs'">
          Output Names
        </li>
      </ul>
    </div>    
  </div>
  <div class="column">    
    <div *ngIf="section === 'setup' && (controller.model.includes('GHK') || controller.model.includes('RWL'))">
      <ul>
        <h6>Export</h6>
        <li *ngIf="controller && controller.model">
          <label>Download Current Settings</label>
          <a href="{{downloadUrl()}}" class="link"><ion-icon class="save-icon" name="cloud-download-outline"></ion-icon></a>
        </li>
        <li *ngIf="controller && canView('growlibrary')" class="grow-library-container">
          <label>Save to Library</label>       
          <div class="growing-plan">
            <div>            
              <label>Name for Current Settings:</label> 
              <input type='text' [(ngModel)]="scheduleName" name="library-settings-name" accept="application/json" placeholder="Week 1" />
            </div>          
            <div>
              <label>Growing Plan</label>
            </div>
            <div>
              <label class="small">
                <p *ngIf="libraryOptions && libraryOptions.length > 0">
                  <span>Select an existing plan to add settings </span>
                  <b>or</b>
                  <span> type a new plan name to create.</span>
                </p>
                <span *ngIf="libraryOptions && libraryOptions.length === 0">Add name for growing plan.</span>
              </label>
              <ul>
                <li *ngIf="libraryOptions && libraryOptions.length > 0">
                  <select [(ngModel)]="optionId">
                    <option value="NEWPLAN">New Plan</option>
                    <option *ngFor="let libraryOption of libraryOptions;" [value]="libraryOption.id">
                      {{libraryOption && libraryOption.name}}              
                    </option>
                  </select>              
                </li>
                <li *ngIf="libraryOptions && libraryOptions.length >= 0">
                  <input type='text' [(ngModel)]="settingsName" name="library-settings-name" accept="application/json" placeholder="Poinsettas" />
                </li>                
              </ul>                          
            </div>
            <div class="link">
              <span class="link" (click)="saveSettingsToLibrary()"><ion-icon class="save-icon" name="library-outline"></ion-icon></span>
            </div>
          </div>   
        </li>
        <br/>
        <h6>Import</h6>
        <li *ngIf="(controller)">
          <label>Import Settings File</label>
          <input type='file' name="settings-file-upload" accept="application/json" (change)="loadSettingsFromDevice($event)"/>
          <button [disabled]="!this.settings_to_import"
            (click)="saveImportSettings()">Import</button>
        </li>
        <li *ngIf="canView('growlibrary')">
          <label>Import From Library</label>
          <ul class="options" *ngIf="libraryOptions && libraryOptions.length > 0">
            <li *ngFor="let libraryOption of libraryOptions;">
              {{libraryOption && libraryOption.name}}
              <ul class="options">
                <li *ngFor="let scheduleItem of libraryOption.schedule; let i = index;">
                  {{scheduleItem}}
                  <div>
                    <span class="link"><ion-icon (click)="viewLibrarySetting(libraryOption, i)" name="eye-outline"></ion-icon></span>
                    <span class="link"><ion-icon (click)="importLibrarySettings(libraryOption, i)" name="cloud-upload-outline"></ion-icon></span>
                  </div>
                </li>                
              </ul>
            </li>
          </ul>
          <p *ngIf="!libraryOptions || (libraryOptions && libraryOptions.length === 0)">You do not have any saved settings in your library.</p>
        </li>
      </ul>
    </div>
    <div class="info" *ngIf="section === 'settings'">
      <ul>
        <li>
          <label>Model:</label>
          {{controller.model}}
        </li>
        <li>
          <label>Firmware:</label>
          {{controller.firmware}}
        </li>
        <li>
          <label>ID:</label>
          {{controller.id}}
        </li>
        <li *ngIf="controller.hub_id && controller.id !== controller.hub_id">
          <label>Hub ID:</label>
          {{controller.hub_id}}
        </li>
        <li *ngIf="controller.updated">
          <label>Last Updated</label>
          {{controller.updated | date:'short'}}
        </li>
        <li *ngIf="!controller.updated" class="no-status-yet">
          <label>Last Updated</label>
          <b>This controller has not reported in yet</b>
        </li>
      </ul>
    </div>
    <div *ngIf="section === 'settings' && (controller && controller.model && (controller.model.includes('GHK') || controller.model.includes('RWL')))">
      <ul>
        <li [class.disabled]="!controller.direct_reporting">
          <label>In Use<sup *ngIf="!controller.direct_reporting">*</sup></label>
          <ion-toggle name="in_use" [(ngModel)]="in_use" [disabled]="!controller.direct_reporting"
            [checked]="in_use"></ion-toggle>
          <button *ngIf='controller.direct_reporting' [disabled]="in_use === controller.in_use"
            (click)="saveNotInUse()">Save</button>
        </li>
      </ul>
      <ul [formGroup]="controllerGeneralInfoForm">
        <li>
          <label>Name:</label>
          <div>
            <input name="name" formControlName="name" />
            <button [disabled]="!controllerGeneralInfoForm || (controllerGeneralInfoForm && !controllerGeneralInfoForm.controls.name.dirty)"
              (click)="updateSettings('api_name', controllerGeneralInfoForm.value.name)">Save</button>
          </div>
        </li>
        <li *ngIf="!this.controller.model || (this.controller.model && !this.controller.model.startsWith('TB'))">
          <label>Thermistor Fail Mode</label>
          <div>
            <ion-toggle name="thermistor_fail" formControlName="thermistor_fail"
              [checked]="controller.thermistor_fail"></ion-toggle>
            <button [disabled]="!controllerGeneralInfoForm || (controllerGeneralInfoForm && !controllerGeneralInfoForm.controls.thermistor_fail.dirty)"
              (click)="updateSettings('thermistor_fail', controllerGeneralInfoForm.value.thermistor_fail)">Save</button>
          </div>
        </li>
        <li>
          <label>Time Log Interval (minutes):</label>
          <div>
            <input name="time_log_interval" type="number"
              formControlName="time_log_interval"
              placeholder="10" />
            <button [disabled]="!controllerGeneralInfoForm || (controllerGeneralInfoForm && !controllerGeneralInfoForm.controls.time_log_interval.dirty)"
              (click)="updateSettings('time_log_interval', controllerGeneralInfoForm.value.time_log_interval)">Save</button>
          </div>
        </li>
        <li *ngIf="!this.controller.model || (this.controller.model && !this.controller.model.startsWith('TB'))">
          <label>Controller Time:</label>
          <div>
            <input
              name="controller_time"
              type="text" inputmode="tel"
              pattern="[0-9]{1,2}:[0-9]{2}"
              formControlName="controller_time"
              (keyup)="reformatTime($event, 'controller_time')"
            />
            <button
              [disabled]="!controllerGeneralInfoForm || (controllerGeneralInfoForm && !controllerGeneralInfoForm.controls.controller_time.dirty)"
              (click)="updateSettings('controller_time', controllerGeneralInfoForm.value.controller_time)">Save</button>
          </div>
        </li>
        <li *ngIf="!this.controller.model || (this.controller.model && !this.controller.model.startsWith('TB') && !this.controller.model.startsWith('WB') && !this.controller.model.startsWith('WR'))">
          <label>Heat / Cool Delay Time:</label>
          <div>
            <div class="vertical">
              <input name="hc_delay" type="number"
                formControlName="hc_delay"
                min="0" max="30"
                placeholder="15" />
              <p class="error"
                *ngIf="controllerGeneralInfoForm && controllerGeneralInfoForm.controls.hc_delay.dirty && controllerGeneralInfoForm.controls.hc_delay.invalid">
                Please enter a number between 0 and 30 minutes.
              </p>
            </div>
            <button
              [disabled]="controllerGeneralInfoForm && (!controllerGeneralInfoForm.controls.hc_delay.dirty || controllerGeneralInfoForm.controls.hc_delay.invalid)"
              (click)="updateSettings('hc_delay', controllerGeneralInfoForm.value.hc_delay)">Save</button>
          </div>
        </li>
        <li *ngIf="!this.controller.model || (this.controller.model && !this.controller.model.startsWith('TB'))">
          <label>On to Auto Time:</label>
          <div>
            <input
              name="on_to_auto"
              type="text" inputmode="tel"
              pattern="[0-9]{1,2}:[0-9]{2}"
              formControlName="on_to_auto"
              (keyup)="reformatTime($event, 'on_to_auto')"
            />
            <button [disabled]="controllerGeneralInfoForm && !controllerGeneralInfoForm.controls.on_to_auto.dirty" (click)="updateSettings('on_to_auto', controllerGeneralInfoForm.value.on_to_auto)">Save</button>
          </div>
        </li>
      </ul>
    </div>
    <form [formGroup]="outputNameForm" *ngIf="outputNames && outputNames.length > 0 && section === 'outputs'">
      <div>
        <p>You can override output names to customize the view from Headgrower. You will still see the output name as subtext; this is for troubleshooting purposes.</p>
      </div>
      <ul class="outputNames">
        <li *ngFor="let n of outputNames">
          <label>{{n.default_name}}</label>
          <div>
            <input
              [name]="n.output"
              type="text"
              [formControlName]="n.output"
            />
          </div>
        </li>
      </ul>
      <div>
        <button [disabled]="!outputNameForm.valid" class="output-save"
          (click)="updateOutputNames(outputNameForm.value)">Save Output names</button>
      </div>
    </form>

    <div *ngIf="!controller.direct_reporting && section === 'settings'"><sup>*</sup>This is a C.I.S. only setting and is read only. If you want to update it, please go to your local C.I.S. installation and change the setting.</div>
  </div>
</div>
<!-- <div id="program-container">
  <ul>
    <li>
      <label>Save Current Settings</label>
      <input [(ngModel)]="settings_name" placeholder="Name" />
      <button>Save</button>
    </li>
    <li>
      <label>Load Saved Settings To Controller</label>
      <select>
        <option val="''">Please choose a saved setting to load</option>
      </select>
    </li>
  </ul>
</div> -->
