<div id="controller-logs-container">
  <!-- <div>
    <h6>Log Options</h6>
    <select (change)="changeLogType($event)">
      <option value="both" default='true'>Please select the log type you wish to view.</option>
      <option value="time">Time Log</option>
      <option value="event">Event Log</option>
    </select>
  </div> -->
  <div class="spinner" *ngIf="fetchingData">
    <ion-progress-bar type="indeterminate"></ion-progress-bar>
  </div>

  <p *ngIf="!fetchingData && (!canShowLogInfo() || error)">
    We didn't find any log information. Please try to <a (click)="getLogData()">refresh the event log</a>. Or contact Bartlett Instrument Company for help.
  </p>

  <div class="refresh">
    <div class="start-and-end-dates" *ngIf="descLogs && !fetchingData && !error">
      <div><label>Start Date: <input type="datetime-local" [(ngModel)]='start_log_data'/></label></div>
      <div><label>End Date: <input type="datetime-local" [(ngModel)]='end_log_data'/></label></div>
    </div>
    <div class="actions" *ngIf="!fetchingData">
      <ion-item lines="none">
        <ion-label>Actions</ion-label>
        <select [(ngModel)]="chosenAction">
          <option value="refresh">Refresh Data&nbsp;<ion-icon alt="Refresh Data" name="sync-outline"></ion-icon></option>
          <option value="dates">Change Dates&nbsp;<ion-icon alt="Change Dates" *ngIf="width < 500" name="calendar-outline"></ion-icon></option>
          <option value="zoom">Reset Zoom&nbsp;<ion-icon alt="Reset Zoom" name="refresh-outline"></ion-icon></option>
          <option *ngIf="width > 550" value="graph">See As Graph</option>
          <option *ngIf="width > 550" value="table">See As Table</option>
        </select>
        <ion-button (click)="dataAction()">Go</ion-button>
      </ion-item>      
      <div class="download"><a type="button" class="download-data" href="{{downloadUrl()}}" color="light">Download Data <ion-icon class="save-icon" color='medium' name="save-outline"></ion-icon></a></div>
    </div>        
  </div>
  <div id="logsChart" #logsChart [class.display-none]="!canShowLogInfo() || viewTable" [style.height.px]="[height]"></div>

  <div *ngIf="modelType === 'T' && !fetchingData && !error && canShowLogInfo()" class="show-hide-timers">
    <div>
      <h6> Show/Hide timers</h6>
    </div>
    <ul>
      <li *ngFor="let timer of timer_options">
        {{timer + 1}} <ion-toggle [(ngModel)]="shown_timers[timer]" [checked]="shown_timers[timer]"></ion-toggle>
      </li>
    </ul>
  </div>
  <div *ngIf="(modelType === 'R' || modelType === 'G') && !fetchingData && !error && canShowLogInfo()" class="show-hide-keys">
    <div>
      <h6>Show/Hide Keys</h6>
    </div>
    <ul>
      <li *ngFor="let option of togglesShown" class="vertical">
        <span>{{option.name}}</span><ion-toggle (ionChange)="toggleOption($event, option.key)" [checked]="option.shown"></ion-toggle>
      </li>
    </ul>
  </div>

<!-- GHK or RWL -->
  <div class="header"  *ngIf="canShowLogInfo() && (modelType == 'G' || modelType == 'R') && viewTable">
    <!-- <div class="th two">Desc.</div> -->
    <div class="th two">Log Date</div>
    <div class="th">Time</div>
    <div class="th">Stage</div>
    <div class="th">T1</div>
    <div class="th">T2</div>
    <div class="th">SP1</div>
    <div class="th">SP2</div>
    <div class="th">Tmr1</div>
    <div class="th">Tmr2</div>
    <div class="th">H1</div>
    <div class="th" *ngIf="modelType == 'G'">C1</div>
    <div class="th" *ngIf="modelType == 'G'">C2</div>
    <div class="th">H2</div>
    <div class="th" *ngIf="modelType == 'G'">C3</div>
    <div class="th" *ngIf="modelType == 'G'">C4</div>
    <div class="th" *ngIf="modelType == 'G'">H3</div>
    <div class="th" *ngIf="modelType == 'R'">%A</div>
    <div class="th" *ngIf="modelType == 'R'">%B</div>
    <div class="th">%C</div>
    <div class="th">%D</div>
    <div class="th hum">Hum %</div>
    <div class="th">Alarm</div>
  </div>

  <div class="table"  *ngIf="canShowLogInfo() && (modelType == 'G' || modelType == 'R') && viewTable">
    <div class="tr" *ngFor="let log of descLogs">
      <!-- <div class="td two">{{log.description}}</div> -->
      <div class="td two">{{log.createdAt | date:'MMM d, y, HH:mm'}}</div>
      <div class="td">{{log.time}}</div>
      <div class="td stage">{{log.stage}}</div>
      <div class="td temperature-one">{{log.ct1}}</div>
      <div class="td temperature-two">{{log.ct2}}</div>
      <div class="td set-point-one">{{log.sp1}}</div>
      <div class="td set-point-two">{{log.sp2}}</div>
      <div class="td cool">{{log.t1}}</div>
      <div class="td cool">{{log.t2}}</div>
      <div class="td heat">{{log.h1}}</div>
      <div class="td cool" *ngIf="modelType == 'G'">{{log.c1}}</div>
      <div class="td cool" *ngIf="modelType == 'G'">{{log.c2}}</div>
      <div class="td heat">{{log.h2}}</div>
      <div class="td cool" *ngIf="modelType == 'G'">{{log.c3}}</div>
      <div class="td cool" *ngIf="modelType == 'G'">{{log.c4}}</div>
      <div class="td heat" *ngIf="modelType == 'G'">{{log.h3}}</div>
      <div class="td" *ngIf="modelType == 'R'">{{log.v1p}}</div>
      <div class="td" *ngIf="modelType == 'R'">{{log.v2p}}</div>
      <div class="td">{{log.v3p}}</div>
      <div class="td">{{log.v4p}}</div>
      <div class="td hum">{{log.hum}}</div>
      <div class="td">{{log.alarm}}</div>
    </div>
  </div>

  <!-- Time Boss -->
  <div class="header"  *ngIf="canShowLogInfo() && modelType == 'T' && viewTable">
    <!-- <div class="th two">Desc.</div> -->
    <div class="th two">Log Date</div>
    <div class="th">Time</div>
    <div class="th">T1</div>
    <div class="th">T2</div>
    <div class="th">T3</div>
    <div class="th">T4</div>
    <div class="th">T5</div>
    <div class="th">T6</div>
    <div class="th">T7</div>
    <div class="th">T8</div>
    <div class="th">T9</div>
    <div class="th">T10</div>
  </div>

  <div class="table"  *ngIf="canShowLogInfo() && modelType == 'T' && viewTable">
    <div class="tr" *ngFor="let log of descLogs">
      <!-- <div class="td two">{{log.description}}</div> -->
      <div class="td two">{{log.logTime}}</div>
      <div class="td">{{log.time}}</div>
      <div class="td cool">{{log.t1}}</div>
      <div class="td cool">{{log.t2}}</div>
      <div class="td cool">{{log.t3}}</div>
      <div class="td cool">{{log.t4}}</div>
      <div class="td cool">{{log.t5}}</div>
      <div class="td cool">{{log.t6}}</div>
      <div class="td cool">{{log.t7}}</div>
      <div class="td cool">{{log.t8}}</div>
      <div class="td cool">{{log.t9}}</div>
      <div class="td cool">{{log.t10}}</div>
    </div>
  </div>

  <!-- Weather Boss -->
  <div class="header"  *ngIf="canShowLogInfo() && modelType == 'W' && viewTable">
    <!-- <div class="th two">Desc.</div> -->
    <div class="th two">Log Date</div>
    <div class="th">Time</div>
    <div class="th">Temp</div>
    <div class="th">Hum</div>
    <div class="th">W Spd</div>
    <div class="th">W Dir</div>
    <div class="th">W Alm 1</div>
    <div class="th">W Alm 2</div>
    <div class="th">R Alm</div>
    <div class="th">Force</div>
    <div class="th">Block</div>
    <div class="th">Alarm</div>
  </div>

  <div class="table"  *ngIf="canShowLogInfo() && modelType == 'W' && viewTable">
    <div class="tr" *ngFor="let log of descLogs">
      <!-- <div class="td two">{{log.description}}</div> -->
      <div class="td two">{{log.logTime}}</div>
      <div class="td">{{log.time}}</div>
      <div class="td temperature-one">{{log.ot1}}</div>
      <div class="td temperature-two">{{log.hum}}</div>
      <div class="td set-point-one">{{log.ws}}</div>
      <div class="td set-point-two">{{log.wd}}</div>
      <div class="td heat">{{log.w1a}}</div>
      <div class="td heat">{{log.w2a}}</div>
      <div class="td heat">{{log.ra}}</div>
      <div class="td cool">{{log.orf}}</div>
      <div class="td cool">{{log.orb}}</div>
      <div class="td heat">{{log.alarm}}</div>
    </div>
  </div>
</div>
