  import { Component, OnInit, Input, ViewChild, ElementRef, ViewEncapsulation, Output, EventEmitter, OnChanges, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, UntypedFormGroup, AbstractControl,
         ValidatorFn, Validators, ValidationErrors,
         UntypedFormControl, FormArray } from '@angular/forms';

@Component({
  selector: 'app-vent-form',
  templateUrl: './vent-form.component.html',
  styleUrls: ['./vent-form.component.scss'],
})
export class VentFormComponent implements OnInit {
  public ventForm: UntypedFormGroup;
  @Input() model
  @Input() vent;
  @Input() index;
  @Input() dayTemp;
  @Output()
  nextVal: EventEmitter<any> = new EventEmitter<any>();

  public current_step = "vent_name";
  public current_step_index = 0;
  private steps = [];
  public vent_step;
  private pages = ["name_mode", "open", "percents", "overrides"];
  public current_page = "name_mode";

  constructor() {}
  ngOnInit() {
    this.current_step_index = 0;
    this.current_step = 'vent_name';
    
    let v = this.vent || {};
    let names = (this.model && this.model.includes('RWL')) ? ['A', 'B', 'C', 'D'] : ['C', 'D']
    let form_fields = {
      vent_name: new UntypedFormControl(v.vent_name || `Vent ${names[this.index]}`),
      mode: new UntypedFormControl(v.mode || '5-STEP'),
      start_opening: new UntypedFormControl(v.start_opening || (v.st ? this.dayTemp + v.st : this.dayTemp)),
      openTime: new UntypedFormControl(v.openTime || 100),
      p1: new UntypedFormControl(v.p1 || 7),
      p2: new UntypedFormControl(v.p2 || 15),
      p3: new UntypedFormControl(v.p3 || 25),
      p4: new UntypedFormControl(v.p4 || 50),
      p5: new UntypedFormControl(v.p5 || 99),
      rainOR: new UntypedFormControl(v.rainOR || '1'),
      wind1OR: new UntypedFormControl(v.wind1OR || '1'),
      wind2OR: new UntypedFormControl(v.wind2OR || '0')
    }
    this.ventForm = new UntypedFormGroup(form_fields)
    this.steps = Object.keys(form_fields)
    this.vent_step = this.ventForm.value.start_opening - this.dayTemp
  }

  public next(is_back = false) { 
    if(is_back) {
      this.current_step_index = this.current_step_index - 1;
    } else {
      this.current_step_index = this.current_step_index + 1;
    }
    
    if(this.ventForm.value && this.ventForm.value.mode === 'OFF') {
      this.current_page = undefined;
      this.nextVal && this.nextVal.emit({vent: this.ventForm.value, index: this.index})
    } else {
      if(this.ventForm.value && this.ventForm.value.start_opening) {
        this.vent_step = this.ventForm.value.start_opening - this.dayTemp
      }
      this.current_step = this.steps[this.current_step_index]
      this.current_page = this.pages[this.current_step_index];
      
      if(['p1', 'p2', 'p3', 'p4', 'p5'].includes(this.current_step)) {
        this.current_step = is_back ? 'openTime' : 'rainOR';
        this.current_step_index = this.steps.findIndex((s) => s === this.current_step);
      }
  
      if(this.current_step === undefined || this.current_page === undefined) {
        this.nextVal && this.nextVal.emit({vent: this.ventForm.value, index: this.index})
      } else {
        console.log(`still has step ${JSON.stringify(this.current_step)} or page ${JSON.stringify(this.current_page)}`)
      }
    }    
  }

  public getValue() {
    return this.ventForm.value;
  }

  public valid() {
    let is_invalid = false;
    if(this.current_step && this.ventForm) {
      is_invalid = this.ventForm[this.current_step] && this.ventForm[this.current_step].dirty && !this.ventForm.controls[this.current_step].valid;
    }

    return !is_invalid;
  }
}
