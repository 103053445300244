import * as d3 from 'd3';
import * as d3Array from 'd3-array';
import * as d3Axis from 'd3-axis';
import * as d3Scale from 'd3-scale';
import { select, selectAll } from "d3-selection";
import * as d3Shape from 'd3-shape';
import * as d3TimeFormat from 'd3-time-format';
import moment from 'moment';
import * as momentTz from 'moment-timezone';

const chartLabel = "Log Data";
var margin = {top: 20, right: 50, bottom: 20, left: 100};
var width;
var height;
var data;
var color;
var yDomain;
var xAxis;
var yAxis;
var gX;
var gY;
let xScale, yScaleGlobal, yLinear, lightYScale;
let rectangle_width = 1;
let rectangleScale;
let difDayNiteScale;
let font_size_small = '1.05em';
let font_size = '1.2em';
let num_ticks = 4;
let x_offset_headings = '-10';
let x_offset_headings_large = '-25';
let has_light_logging = false;

function * range ( start, end, step = 1 ) {
  let state = start;
  while ( state < end ) {
    yield state;
    state += step;
  }
  return;
};

const init = (chartId, data_width, index, height) => {
  let svg = select(chartId)
    .append('svg')
    .attr('height', height + 50)
    .attr('width', data_width)
    .attr('class', 'logs-chart')
    .attr("viewBox", `0 0 ${data_width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
    .style('border', '1px solid #989aa2')
    .style('margin', '0.5em 0')

  const g = svg.append('g')
    .attr('width', data_width)
    .attr('transform', 'translate(' + margin.left + ',' + 0 + ')')
    .attr("pointer-events","all")

  svg.append('text')
      .attr('class', 'axis-title')
      .attr("x", ((data_width+margin.right+margin.left) / 2))
      .attr("y", margin.top)
      .attr("text-anchor", "middle")
      .style("font-size", "24px")
      .style("font-weight", "bold")
      .text(`Timer ${index}`);

  let line_g = g
    .append("svg:clipPath")
      .attr("id", "lines")
      .append("svg:rect")
        .attr("id", "line-rect")
        .attr("x", (data_width - margin.right - margin.left))
        .attr("y", 0) //margin.top)
        .attr("width", data_width)
        .attr("height", height)
        .attr('fill', `transparent`)

  return {svg: svg, g: g, line_g: line_g}
}

const keys_to_data_key = {
  "Heat 1": "h1",
  "Heat 2": "h2",
  "Cool 1": "c1",
  "Cool 2": "c2",
  "Cool 3": "c3",
  "Cool 4": "c4",
  "Heat 3": "h3",
  "% 1": "v1p",
  "% 2": "v2p",
  "% 3": "v3p",
  "% 4": "v4p",
  "Alarm": "alarm"
}

const getMinAndMaxForKeys = (data, keys) => {
  const minMaxData = data.reduce((minMaxAcc, timeLogData) => {
    keys.forEach((key) => {
      timeLogData.logTime = moment(timeLogData.createdAt).format("MMM Do YY, HH:mm");
      const data_for_key = timeLogData[keys_to_data_key[key]]
      if(data_for_key === "ON" || (key === 'alarm' && data_for_key !== "NONE" && data_for_key !== "OFF") ||
          (['v1p', 'v2p', 'v3p', 'v4p'].includes(key) && data_for_key != "99" && data_for_key !== "OFF")) {
            if(!minMaxAcc[key]) {
              minMaxAcc[key] = {min: timeLogData.raw, max: timeLogData.raw, raw: timeLogData.raw}
            } else {
              const day_time = moment(timeLogData.raw);
              const currentMax = minMaxAcc[key] ? moment(minMaxAcc[key].max) : undefined;
              const currentMin = minMaxAcc[key] ? moment(minMaxAcc[key].min) : undefined;

              if((!currentMax || !day_time) || (currentMax.isBefore(day_time))) {
                minMaxAcc[key].max = timeLogData.raw;
              }

              if((!currentMin || !day_time) || (day_time.isBefore(currentMin))) {
                minMaxAcc[key].min = timeLogData.raw;
              }
            }
      }
    });

    return minMaxAcc;
  }, {});

  return minMaxData;
}

const minMaxLight = (timeLog, minMaxAcc) => {
  const currentMinLight = parseInt(minMaxAcc.min_light)
  const currentMaxLight = parseInt(minMaxAcc.max_light)
  if(timeLog.ll) {
    const currentMaxLight = parseInt(minMaxAcc.max_light)
    const currentMinLight = parseInt(minMaxAcc.min_light)
    const light_level = parseInt(timeLog.ll)
    if((!currentMaxLight && currentMaxLight != 0) || (light_level > currentMaxLight)) {
      minMaxAcc.max_light = light_level;
    }

    if((!currentMinLight && currentMinLight != 0) || (light_level < currentMinLight)) {
      minMaxAcc.min_light = light_level;
    }
  }

  return minMaxAcc;
}

const createLightScale = (chartData, y) => {
  var minAndMax = chartData.reduce((minMaxAcc, timeLog) => {
    return minMaxLight(timeLog, minMaxAcc);
  }, {min_light: undefined, max_light: undefined})

  let y_top = minAndMax.max_light === minAndMax.min_light ? y : (y + 100)
  return {scale: d3Scale.scaleLinear([(minAndMax.max_light), (minAndMax.min_light)], [y, y_top]), updated_y: (y_top + 50)};
}

const drawTimeAxes = (g, x, y, zoom = false) => {
  if(zoom) {
    g.select('.axis--x')
      .transition()
      .duration(300)
      .attr('transform', 'translate(0,' + y + ')')
      .call(d3Axis.axisBottom(x)
          .ticks(num_ticks)
          .tickFormat(d3TimeFormat.timeFormat("%m/%d %H:%M")))
      .attr('font-size', font_size_small)
        .selectAll("text")
          .attr("transform", "rotate(65)")
          .attr("dx", "1em")
          .attr("dy", "-.5em")
          .style("text-anchor", "start");
  } else {
    xAxis = g.append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', 'translate(0,' + y + ')')
      .call(d3Axis.axisBottom(x)
        .ticks(num_ticks)
        .tickFormat(d3TimeFormat.timeFormat("%m/%d %H:%M")))
      .attr('font-size', font_size_small)
      .selectAll("text")
        .attr("transform", "rotate(65)")
        .attr("dx", "1em")
        .attr("dy", "-.5em")
        .style("text-anchor", "start")
  }
}

const drawLightLines = (chartData, g, x, yScale, y, index, data_width) => {
  if(yScale) {
    var light_level_line = d3Shape.line()
      .x(function(d, i) { return x(d.createdAt); })
      .y(function(d) { return yScale(d.ll); });

    if(chartData) {
        yAxis = g.append('g')
         .attr('class', 'axis axis--y')
         .call(d3Axis.axisLeft(yScale)
          .ticks(5)
          .tickSize(-(data_width)))
         .attr('font-size', font_size_small);

       g.append('text')
         .attr('x', '-20')
         .attr('y', (y + (yScale.range()[1] - y) / 2))
         .attr('stroke', 'rgb(92, 191, 145)')
         .style("font-size", font_size)
         .attr("text-anchor", "end")
         .attr('class', `label`)
         .text('Light')

       g.append("path")
        .datum(chartData)
        .attr("stroke", "#e65722")
        .attr("stroke-width", 3)
        .attr("fill", "none")
        .attr('class', 'light_level_line line')
        .attr("d", light_level_line)
        .attr('clip-path', 'url("#lines")');
      } else {
        g.select('.light_level_line')
          .transition()
          .duration(1000)
          .attr("d", light_level_line)
      }
  }

  return y + 50;
}

//draw a on/off graph for heats and cools
const drawOnOff = (data, g, x, y, keyInfo, data_width) => {
  const yLinear = d3Scale.scaleLinear([1, 0], [y, y+10]);
  if(data) {
    g.append('line')
      .attr("stroke-width", 1)
      .attr("stroke", "black")
      .attr("x1", 0)
      .attr("y1", y)
      .attr("x2", data_width)
      .attr("y2", y);

    g.selectAll("bar")
      .data(data)
      .enter().append("rect")
        .style("fill", (keyInfo ? keyInfo.color : '#989aa2'))
        .attr('class', `bar onOffBar ${keyInfo.key}OnOff`)
        .attr("x", function(d) { return rectangleScale(d.createdAt); })
        .attr("width", (d) => { return rectangleScale.bandwidth() })
        .attr("y", function(d) {
          return (d[keyInfo.key] == 'ON' || d[keyInfo.key] === 1 || d[keyInfo.key] === '1') ? (y-12) : y;
        })
        .attr("height", function(d) {
          return (d[keyInfo.key] === 'ON' || d[keyInfo.key] === 1 || d[keyInfo.key] === '1') ? 24:0;
        })
        .attr('clip-path', 'url("#lines")');

    g.append('text')
      .attr('x', '-20')
      .attr('y', y+6)
      .attr('stroke', 'rgb(92, 191, 145)')
      .style("font-size", font_size)
      .attr("text-anchor", "end")
      .text('On/Off')
  } else {
    g.selectAll(`.${keyInfo.key}OnOff`)
      .transition()
      .duration(500)
      .attr("x", function(d) { return rectangleScale(d.createdAt); })
      .attr("width", (d) => { return rectangleScale.bandwidth() })
      .attr("height", function(d) { return (d[keyInfo.key] == 'ON' || d[keyInfo.key] === 1|| d[keyInfo.key] === '1') ? 24: 0; })
  }

  return y + 30;
}

const drawRectangles = (data, g, x, y, modelType, index, data_width) => {
  const coolColor = '#4183D7';
  const heatColor = '#e65722';
  const timerColor = '#989aa2';
  const keyInfo = [
    {key: 't1', name: 'Timer 1', color: timerColor},
    {key: 't2', name: 'Timer 2', color: timerColor},
    {key: 't3', name: 'Timer 3', color: timerColor},
    {key: 't4', name: 'Timer 4', color: timerColor},
    {key: 't5', name: 'Timer 5', color: timerColor},
    {key: 't6', name: 'Timer 6', color: timerColor},
    {key: 't7', name: 'Timer 7', color: timerColor},
    {key: 't8', name: 'Timer 8', color: timerColor},
    {key: 't9', name: 'Timer 9', color: timerColor},
    {key: 't10', name: 'Timer 10', color: timerColor},
  ]

  y = drawOnOff(data, g, x, y, keyInfo[index], data_width);
  return y;
}

export const TimeBossControllerLogsChartGenerator = {
  chartData:(chartId, chart_data, chart_width, chart_height, modelType, columns, shown_timers, show_light) => {
    data = chart_data;
    width = chart_width;
    if(chart_width < 500) {
      margin = {top: 10, right: 5, bottom: 10, left: 40};
      num_ticks = 6;
      font_size = '16px';
      x_offset_headings = '-3';
      x_offset_headings_large = '-5'
    }
    height = 300;
    var keys = columns.slice(1);
    var y = 10;

    selectAll("svg").remove();
    color = d3Scale.scaleOrdinal(keys,
      ["#5CBF91", "#7044ff",
        "#f04141", "#f04141",
        "#989aa2", "#989aa2",
        "#989aa2", "#989aa2",
        "#f04141", "#d7d8da",
        "#d7d8da", "#d33939"]);

    const groupKey = "label";
    const chartLabel = "Time Log"
    let min_date, max_date;
    let reset_chart = () => {}

    let get_amount_per_row = shown_timers && shown_timers.length > 8 ? 3 : shown_timers.length
    if(width < 600) {
      get_amount_per_row = 2;
    } else if(width < 400) {
      get_amount_per_row = 1;
    }
    let width_for_data = (chart_width < 500 ? (width/get_amount_per_row) : ((width - (margin.left - margin.right)) / (get_amount_per_row)));

    const drawZoneChart = (chartId, data) => {
      has_light_logging = show_light && data.map((d) => parseInt(d.ll)).some((d) => d && d > 0)
      data = data.map((d) => {
        d.createdAt = momentTz.utc(d.createdAt, 'MM/DD/YYYY HH:mm').toDate()
        return d;
      })

      if(shown_timers.length < 5) margin.top = 40;
      shown_timers.forEach((timer) => {
        let index = parseInt(timer);
        y = 15;
        const min_and_max_by_key = getMinAndMaxForKeys(data, keys);
        min_date = d3Array.min(data, d => d.createdAt);
        max_date = d3Array.max(data, d => d.createdAt);

        rectangle_width = width_for_data/data.length;
        let get_rectangle_domain = (min, max) => {
          let domain = data
            .filter((d) => {
              let same_or_before_max = moment(d.createdAt).isSameOrBefore(max)
              let same_or_after_min = moment(d.createdAt).isSameOrAfter(min);
              return same_or_before_max && same_or_after_min;
            })
            .map((d) => d.createdAt)
            .sort((a, b) => {
              if (a && b && a < b) { return -1; } else if (a && b && a === b) { return 0; } else { return 1; }
            })
          return domain;
        }

        rectangleScale = d3Scale.scaleBand()
          .domain(get_rectangle_domain(min_date, max_date))
          .range([0, width_for_data])
          .padding(0);
        xScale = d3Scale.scaleTime()
          .domain([min_date, max_date])
          .range([0, width_for_data]);

        let {scale, updated_y} = createLightScale(data, y)
        y = has_light_logging ? updated_y : y;
        lightYScale = scale;

        let additional_height = shown_timers.length > 5 ? 150 : 300
        var {svg, g, line_g} = init(chartId, width_for_data, (parseInt(timer)+1), additional_height);

        function zoomed(event) {
          let y = 15;
          if (event && event === "brush") return; // ignore zoom-by-brush
          let zoom_y = y;

          zoom_y = has_light_logging ? drawLightLines(null, g, xScale, lightYScale, lightYScale.range()[0], index, width_for_data) : zoom_y;
          zoom_y = drawRectangles(null, g, xScale, zoom_y, modelType, index, width_for_data) + 50;
          drawTimeAxes(g, xScale, zoom_y, true);
        }

        var zoom = d3.zoom()
          .scaleExtent([1, Infinity])
          .translateExtent([[margin.left, margin.top], [width_for_data, height]])
          .extent([[margin.left, margin.top], [width_for_data, height]])
          .on("zoom", zoomed);

        function brushed(event, d) {
          if (event && event === "zoom") return; // ignore brush-by-zoom
          let selection = event.selection
          if(selection) {
            let inverted = [xScale.invert(selection[0]), xScale.invert(selection[1])]
            xScale
              .domain([inverted[0], inverted[1]])

            rectangleScale
              .domain(get_rectangle_domain(inverted[0], inverted[1]))
            g.call(zoom.transform, d3.zoomIdentity)
            g.select(".brush").call(brush.move, null)
          }
        }

        const brush = d3.brushX()
                        .extent([[0, 0], [(width_for_data + margin.left + margin.right), (height || chart_height)]])
                        .on('end', brushed);

        g.append('g')
          .attr('class', 'brush')
          .call(brush);

        y = has_light_logging ? drawLightLines(data, g, xScale, lightYScale, lightYScale.range()[0], index, width_for_data) : updated_y;
        y = drawRectangles(data, g, xScale, y, modelType, index, width_for_data) + 10;
        drawTimeAxes(g, xScale, y);
        y += 60; //add space for time axis

        reset_chart = function() {
          xScale
            .domain([min_date, max_date])
            .range([0, width_for_data])
          let rectangle_domain = get_rectangle_domain(min_date, max_date);
          rectangleScale
            .domain(rectangle_domain)
            .range([0, width_for_data]);
          g.call(zoom.transform, d3.zoomIdentity)
        }
        g.on("dblclick", reset_chart);
      })
    }

    drawZoneChart(chartId, data);

    return {
      draw: () => {
        drawZoneChart(chartId, data);
      },
      reset: reset_chart,
      height: y
    }
  }
}
