let keyTranslations = {
  'rem': "Reset Min/Max",
  'alarm1h': "Zone 1: Alarm High",
  'alarm1l': "Zone 1: Alarm Low",
  'alarm2h': "Zone 2: Alarm High",
  'alarm2l': "Zone 2: Alarm Low",
  'sp1': "Zone 1: Set Point",
  'sp2': "Zone 2: Set Point",
  'sp3': "Heat 3: Set Point",
  'c1m': "Cool 1",
  'c2m': "Cool 2",
  'c3m': "Cool 3",
  'c4m': "Cool 4",
  'vent1': "Vent 1",
  'vent2': "Vent 2",
  'vent4': "Vent D",
  'vent3': "Vent C",
  'h1m': "Heat 1",
  'h2m': "Heat 2",
  'h3m': "Heat 3",
  'prog1': "Zone 1: Adjusted Program",
  'prog2': "Zone 2: Adjusted Program",
  'humsp': "Humidity Set Point",
  "dhSp": "Dehumidification Set Point",
  "dhNight": "Dehumidification, NITE",
  "dhDay": "Dehumidification, DAY",
  "dhDif": "Dehumidification, DIF",
  "dhLoTemp": "Dehumidification Low Temp",
  "dhHiTemp": "Dehumidification Low Temp",
  "dhRepeat": "Dehumidification Repeat",
  "dhFan2": "Dehumid. Fan 2 Cycle",
  "dhFan1": "Dehumid. Fan 2 Cycle",
  "dhHeat": "Dehumid. Heat Time",
  "dhStartTime": "Dehumid. Start Time",
  "dhVentMode": "Dehumidification Vent Mode",
  "dhStart": {
    "0": "Dehumidification Stop",
    "1": "Dehumidification Start"
  },
  "dhSetup": "Dehumidification Setup",
  "timerStart": {
    "0": "Timer Stop",
    "1": "Timer Start"
  },
  "timerStartTime": "Timer: Start Time",
  "timerHoldTime": "Timer: Hold Time",
  "timerRepeat": "Timer: Repeat",
  "mistDifCycleMin": "Mister: DIF Cycle",
  "mistDifOnSec": "Mister: DIF On",
  "mistDayCycleMin": "Mister: DAY Cycle",
  "mistDayOnSec": "Mister: DAY On",
  "mistNightCycleMin": "Mister: NITE Cycle",
  "mistNightOnSec": "Mister: NITE On",
  "cancel": "Wind/Rain Alarm: Cancel",
  "activate": "Wind/Rain Alarm: Activate",
  "windActSp": "Wind/Rain Alarm: Activation Set Point",
  "windActTime": "Wind/Rain Alarm: Activation Time",
  "windDropSp": "Wind/Rain Alarm: Drop Off Set Point",
  "windDropTime": "Wind/Rain Alarm: Activation Time",
  "windActSpH1": "WeatherBoss Alarm 1: High Activation Speed",
  "windActSpL1": "WeatherBoss Alarm 1: Low Activation Speed",
  "windActSpH2": "WeatherBoss Alarm 2: High Activation Speed",
  "windActSpL2": "WeatherBoss Alarm 2: Low Activation Speed",
  "windDir1": "Weather Boss Alarm 1: Cardinal & Intermediate Directions",
  "windDir2": "Weather Boss Alarm 2: Cardinal & Intermediate Directions",
  "thermistor_fail": "Thermistor Fail On/Off",
  "controller_time": "Controller Time Change",
  "shadePause": "Shade Pause Time",
  "shadeEnable": "Shade Enabled",
  "shadeSlowRetract": "Shade Slow Retract",
  "shadeOpenTime": "Shade Open Time",
  "shadeStep": "Shade Step",
  "shadeTempSP": "Shade Temperature Set Point",
  'alarm1l_float':"Heat 1 Low Floating Alarm",
  'alarm2l_float' : "Heat 2 Low Floating Alarm",
  'alarm3h': "Heat 3 High Alarm",
  'alarm3l': "Heat 3 Low Alarm"
};

let timeBossKeys = {
  startTimeA: "Start Time (A)",
  startTimeB: "Start Time (B)",
  holdTimeA: "Hold Time (A)",
  holdTimeB: "Hold Time (B)",
  abort: "Aborted",
  enable: "Enabled",
  startTime: "Start Time",
  durationB: "Duration B",
  durationA: "Duration A",
  DurationB: "Duration B",
  DurationA: "Duration A"
}

export const KeyTranslations = {
  getTranlations: () => {
    return keyTranslations;
  },
  timeBossKeys: () => { return timeBossKeys; }
}
