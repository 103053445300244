<div id="controller-search" class="section" *ngIf="showSection" [class.active]="showSection">
  <!-- <div class="error" *ngIf="error">{{error}}</div> -->
  <form id="offline-controller-search" [formGroup]="searchForm" (ngSubmit)="filter(searchForm.value)">
    <label>Search Controllers <input type='search' value="Node ID" formControlName="searchTerm" autofocus="true" #searchInputId/></label>
    <div>
      <button type="button" (click)="cancelSearch()"><ion-icon name="close-outline"></ion-icon></button>
      <button type="submit" (click)="filter(searchForm.value)"><ion-icon name="search-outline"></ion-icon></button>
    </div>
  </form>
  <div class='sort_items'>
    <div *ngIf="results && results.length === 0">No results.</div>
    <table *ngIf="results && results.length > 0">
      <thead>
        <tr>
          <th>Name</th>
          <th>ID</th>
          <th>Owner</th>
          <th>Last Updated</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let result of results;">
          <td>{{result.api_name || result.node_name || "No Name"}}</td>
          <td>{{result.node_id}}</td>
          <td>{{getNodeOwner(result) || 'No Owner'}}</td>
          <td>{{result.updated | date:'short'}}</td>
          <td><button (click)="viewController(result.node_id, getNodeOwner(result), result.site_id)">View</button></td>
        </tr>
      </tbody>
    </table>
  </div>
  <div id="add-controller">
    <div><button *ngIf="!addController" (click)="addController = true;">Add Controller</button></div>
    <div *ngIf="addController">
      <form id="add-controller-form" [formGroup]="addForm">
        <label>Serial Number <input type='text' value="XXXXXXXXX" formControlName="serial" autofocus="true"/></label>
        <label>Mac Address <input type='text' value="XXXXXXXXX" formControlName="mac" autofocus="true"/></label>
        <label>Type <input placeholder="SWP" type='text' value="SWP" formControlName="type" autofocus="true"/></label>
        <div>
          <button type="submit" (click)="create(addForm.value)">Submit</button>
          <button *ngIf="addController" type="button" (click)="addController = false">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</div>
