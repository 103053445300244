import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, timer, from, of, throwError } from "rxjs";
import { UserService } from './user.service';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class TimerService {
  private normalUpdateTime = 30; //normal update time in seconds
  private fastUpdateTime = 10; //fast update time in seconds
  private subscriptionTimerSecond: any;
  public updateTime = 1;
  public fastUpdateTimer = 0;

  private baseUrl = environment.apiUrl;
  public timerSubscription = new BehaviorSubject<any>({});

  constructor(
    public http: HttpClient,
    private userService: UserService
  ) { }

  public startTimerSecond() {
    // This timer is used to flash messages and as the time base for the update timer
    let TimerSecond = timer(1000, 2000);
    this.subscriptionTimerSecond = TimerSecond.subscribe(t => {
      this.onTickTimerSecond(t);
    });
  }

  public killTimerSecond () {
    // console.log('killing timer')
    this.subscriptionTimerSecond && this.subscriptionTimerSecond.unsubscribe();
  }

  public onTickTimerSecond(tick) {
    //process fast update timer
    if (this.fastUpdateTimer > 0) this.fastUpdateTimer--;

    //process update timer
    this.updateTime--;
    // console.log(`update time: ${this.updateTime}`)
    // console.log(`timer tick: updateTime: ${this.updateTime}, fastUpdateTimer: ${this.fastUpdateTimer}, normalUpdateTime: ${this.normalUpdateTime}`)
    if (this.updateTime <= 0){
      //time for update
      if (this.fastUpdateTimer > 0){
        //do fast update rate
        this.updateTime = this.fastUpdateTime; //reset the timer to fast update time
      } else {
        //do normal update rate
        this.updateTime = this.normalUpdateTime; //reset the timer to normal update time
      }

      this.timerSubscription.next({});
    }
  }

  public setTimerSecond(time: number){
    this.updateTime = time;
  }

  public setFastUpdate(seconds){
    this.fastUpdateTimer = seconds; //set the fast update timer count down to user specified time
    this.updateTime = this.fastUpdateTime; //set the current update time to the fast update time
  }

  public addScheduled(site_id, timerInfo): Promise<any> {
    if(timerInfo) {
      var url = (this.userService.isLoggedInAs() ? `${this.baseUrl}/api/irrigation/${site_id}/${timerInfo.timer_id}?loginAs=${this.userService.getLoggedInEmail()}` : `${this.baseUrl}/api/irrigation/${site_id}/${timerInfo.timer_id}`);
      return new Promise((resolve, reject) => {
        this.http.post(url, timerInfo)
                        .toPromise()
                        .then(response => { resolve(response); })
                        .catch(error => { reject(); })
      })
      } else {
        return new Promise((resolve, reject) => { reject(); })
      }
  }

  public editScheduled(site_id, timerInfo): Promise<any> {
    return new Promise((resolve, reject) => {
      if(timerInfo.id && timerInfo.timer_index !== undefined) {
        var url = (this.userService.isLoggedInAs() ? `${this.baseUrl}/api/irrigation/${site_id}/${timerInfo.timer_id}?loginAs=${this.userService.getLoggedInEmail()}` : `${this.baseUrl}/api/irrigation/${site_id}/${timerInfo.timer_id}`);
        this.http.put(url, timerInfo)
                        .toPromise()
                        .then(response => {
                          resolve({id:  timerInfo.id, index: timerInfo.index, info: timerInfo});
                        })
                        .catch(error => {
                          reject();
                        })
        } else { reject(); }
    })
  }

  reorder(site_id, list): Promise<any> {
    let url = (this.userService.isLoggedInAs() ? `${this.baseUrl}/api/irrigation/${site_id}?loginAs=${this.userService.getLoggedInEmail()}` : `${this.baseUrl}/api/irrigation/${site_id}`);
    return this.http.put(url, list)
            .toPromise();
  }

  public removeScheduled(site_id, node_id, index): Promise<any> {
    return new Promise((resolve, reject) => {
      if(node_id && index !== undefined) {
        var url = (this.userService.isLoggedInAs() ? `${this.baseUrl}/api/irrigation/${site_id}/${node_id}-${index}?loginAs=${this.userService.getLoggedInEmail()}` : `${this.baseUrl}/api/irrigation/${site_id}/${node_id}-${index}`);
        return this.http.delete(url)
                    .toPromise()
                    .then(response => { resolve({id: node_id}); })
                    .catch(error => { reject(); })
      } else {
        reject();
      }
    })
  }
}
