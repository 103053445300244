import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, AfterViewInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { TimerGraphGenerator } from './timer-graph-generator';
import { TimeHelper } from 'src/app/components/time_helper';
import { ViewDidLeave, ViewWillLeave } from '@ionic/angular';

@Component({
  selector: 'app-timer-form',
  templateUrl: './timer-form.component.html',
  styleUrls: ['./timer-form.component.scss'],
})
export class TimerFormComponent implements OnInit, OnChanges {
  @Input() index;
  @Input() timer;
  @Input() current_timer;
  @Input() defaults;
  @Input() misterInfo;
  @Input() shadeInfo;
  @Input() viewing = false;
  @Output() nextVal = new EventEmitter<any>();
  public current_step = "name";
  public current_page;
  public current_step_index = 0;
  public steps = [];
  public timerForm;
  private previousStep;

  public pages = [
    {key: "general", fields: ["name", "open_time"]},
    {key: "stage_a", fields: ["stage_a_start_time", "stage_a_run_time", "stage_a_cycle_time", "stage_a_on_time", "stage_b_enable"]},
    {key: "stage_b", fields: ["stage_b_start_time", "stage_b_run_time", "stage_b_run_time", "stage_b_on_time", "stage_idle_enable"]},
    {key: "stage_idle", fields: ["stage_idle_cycle_time", "stage_idle_on_time"]},
    {key: "shade_settings", fields: ["slow_retract", "pulse_yes_no", "step_percent", "pause"]},
    {key: "overrides", fields: ["temp_sp", "light_sp"]}
  ]

  private shade_fields = (timer, misterInfo, shadeInfo) => {
    return {
      name: new UntypedFormControl(timer.name ? timer.name : `Timer ${this.index + 1}`),
      open_time: new UntypedFormControl(shadeInfo.shadeOpenTime),
      slow_retract: new UntypedFormControl(shadeInfo.shadeSlowRetract),
      temp_sp_set: new UntypedFormControl(shadeInfo.shadeTempSP ? 'true' : 'false'),
      temp_sp: new UntypedFormControl(shadeInfo.shadeTempSP),
      light_sp_set: new UntypedFormControl(misterInfo.lightSP ? 'true' : 'false'),
      light_sp: new UntypedFormControl(misterInfo.lightSP),
      stage_a_start_time: new UntypedFormControl(TimeHelper.reformatString(timer.startTimeA), [Validators.pattern(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)]),
      stage_a_run_time: new UntypedFormControl(TimeHelper.reformatString(timer.holdTimeA)),
      stage_b_start_time: new UntypedFormControl(TimeHelper.reformatString(timer.startTimeB), [Validators.pattern(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)]),
      stage_b_run_time: new UntypedFormControl(TimeHelper.reformatString(timer.holdTimeB)),
      pulse_yes_no: new UntypedFormControl(shadeInfo.shadeStep ? 'true' : 'false'),
      step_percent: new UntypedFormControl(shadeInfo.shadeStep),
      pause: new UntypedFormControl(shadeInfo.shadePause),
    }
  }

  private mister_fields = (timer, misterInfo) => {
    return {
      name: new UntypedFormControl(timer.name ? timer.name : `Timer ${this.index + 1}`),
      stage_a_start_time: new UntypedFormControl(TimeHelper.reformatString(timer.startTimeA), [Validators.pattern(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)]),
      stage_a_run_time: new UntypedFormControl(TimeHelper.reformatString(timer.holdTimeA)),
      stage_a_cycle_time: new UntypedFormControl(misterInfo.mistACycleMin),
      stage_a_on_time: new UntypedFormControl(misterInfo.mistAOnSec),
      stage_b_enable: new UntypedFormControl('true'),
      stage_b_start_time: new UntypedFormControl(TimeHelper.reformatString(timer.startTimeB), [Validators.pattern(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)]),
      stage_b_run_time: new UntypedFormControl(TimeHelper.reformatString(timer.holdTimeB)),
      stage_b_cycle_time: new UntypedFormControl(misterInfo.mistBCycleMin),
      stage_b_on_time: new UntypedFormControl(misterInfo.mistBOnSec),
      stage_idle_enable: new UntypedFormControl('true'),
      stage_idle_cycle_time: new UntypedFormControl(misterInfo.mistIdleCycleMin),
      stage_idle_on_time: new UntypedFormControl(misterInfo.mistIdleOnSec),
      light_sp_set: new UntypedFormControl(misterInfo.lightSP ? 'true' : 'false'),
      light_sp: new UntypedFormControl(misterInfo.lightSP)
    }
  }

  private timer_mode_fields = (timer, misterInfo) => {
    if(!timer || timer && Object.keys(timer) && Object.keys(timer).length === 0) {
      timer = {startTimeA: '0:00', holdTimeA: '0:00', startTimeB: '0:00', holdTimeB: '0:00'}
    }

    if(!misterInfo || misterInfo && Object.keys(misterInfo) && Object.keys(misterInfo).length === 0) {
      misterInfo = {lightSp: 0}
    }
    
    return {
      name: new UntypedFormControl(timer.name ? timer.name : `Timer ${this.index + 1}`),
      stage_a_start_time: new UntypedFormControl(TimeHelper.reformatString(timer.startTimeA), [Validators.pattern(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)]),
      stage_a_run_time: new UntypedFormControl(TimeHelper.reformatString(timer.holdTimeA)),
      stage_b_enable: new UntypedFormControl('true'),
      stage_b_start_time: new UntypedFormControl(TimeHelper.reformatString(timer.startTimeB), [Validators.pattern(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)]),
      stage_b_run_time: new UntypedFormControl(TimeHelper.reformatString(timer.holdTimeB)),
      light_sp_set: new UntypedFormControl(misterInfo.lightSP ? 'true' : 'false'),
      light_sp: new UntypedFormControl(misterInfo.lightSP)
    }
  }

  constructor() { }
  
  ngOnInit() {
    try {
      let fields;
      if(this.timer && this.timer.isShade && !this.timer.isMister && this.defaults) {
        fields = this.shade_fields(this.defaults, this.misterInfo, this.shadeInfo);
        this.pages = this.pages.filter((page) => { return page && page.key !== 'stage_idle'})
      } else if(this.timer && this.timer.isMister && !this.timer.isShade && this.defaults) {
        fields = this.mister_fields(this.defaults, this.misterInfo);
        this.pages = this.pages.filter((page) => { return page && page.key !== 'shade_settings'})
      } else {
        fields = this.defaults && this.misterInfo ? this.timer_mode_fields(this.defaults, this.misterInfo) : this.timer_mode_fields({}, {});
        this.pages = this.pages.filter((page) => { return page && !['shade_settings', 'stage_idle'].includes(page.key) })
      }
      
      this.steps = Object.keys({name: new UntypedFormControl(''), ...fields});
      this.timerForm = new UntypedFormGroup(fields)
      this.current_page = this.pages[this.current_step_index];      
    } catch(e) {
      console.log(e)
    }
  }

  ngAfterViewInit(): void {
    if(this.timerForm && this.timerForm.value) {
      TimerGraphGenerator.graph(`.timer-graph-${this.index}`, 600, 85, this.timerForm.value);
    } 
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes && changes.current_timer && this.timerForm && this.timerForm.value && this.viewing) {      
      TimerGraphGenerator.graph(`.timer-graph-${this.index}`, 600, 85, this.timerForm.value);
    }
  }

  public next(is_back = false) {
    this.previousStep = this.current_page;
    if(is_back) {
      this.current_step_index = this.current_step_index - 1;
    } else {
      this.current_step_index = this.current_step_index + 1;
    }

    this.current_page = this.pages[this.current_step_index];
    this.current_step = this.steps[this.current_step_index]
    let skip_pulse_info = ['step_percent', 'pause'].includes(this.current_step) && this.timerForm && this.timerForm.value && (!this.timerForm.value.pulse_yes_no || this.timerForm.value.pulse_yes_no === 'false')
    let skip_stage_b_info = ['stage_b_start_time', 'stage_b_run_time', 'stage_b_cycle_time', 'stage_b_on_time'].includes(this.current_step) && this.timerForm && this.timerForm.value && (!this.timerForm.value.stage_b_enable || this.timerForm.value.stage_b_enable === 'false')
    let skip_idle_info = ['stage_idle_cycle_time', 'stage_idle_on_time'].includes(this.current_step) && this.timerForm && this.timerForm.value && (!this.timerForm.value.stage_idle_enable || this.timerForm.value.stage_idle_enable === 'false')

    if(skip_stage_b_info || skip_idle_info) {
      this.next()
    } else {
      if(!this.current_page) {
        this.current_step_index = 0;
      }
      this.nextVal && this.nextVal.emit({is_back: (is_back && !this.current_page), val: this.timerForm.value, index: this.index, change_step: !(this.timer && this.timer.enable) || !this.current_page})
    }
  }

  public goToStageB() {
    this.previousStep = this.current_page;
    this.current_step_index = this.pages.findIndex((page) => page.key === "stage_b");
    this.current_page = this.pages[this.current_step_index];
    this.current_step = this.steps[this.current_step_index];
  }

  public goToIdle() {
    this.previousStep = this.current_page;
    this.current_step_index = this.pages.findIndex((page) => page.key === "stage_idle");
    this.current_page = this.pages[this.current_step_index];
    this.current_step = this.steps[this.current_step_index];
  }

  public goToModeDetails() {
    this.previousStep = this.current_page;
    let detailsPage = this.timer && this.timer.isShade && !this.timer.isMister ? "shade_settings" : "overrides";    
    this.current_step_index = this.pages.findIndex((page) => page.key === detailsPage);
    this.current_page = this.pages[this.current_step_index];
    this.current_step = this.steps[this.current_step_index];    
  }

  public goToPrevious() {
    this.current_page = this.previousStep;
  }

  public completeForm() {
    this.nextVal && this.nextVal.emit({is_back: false, val: this.timerForm.value, index: this.index, change_step: true})
  }

  public getValue() {
    return this.timerForm.value;
  }

  public valid() {
    let is_invalid
    if(this.current_step && this.timerForm) {
      is_invalid = this.timerForm[this.current_step] && this.timerForm[this.current_step].dirty && !this.timerForm.controls[this.current_step].valid;
    }

    return !is_invalid
  }

  public reformatTime(event, field, recursive?) {
    TimeHelper.reformat(event, field, this.timerForm, recursive)
  }
}
