import { ErrorHandler, Injectable } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { ErrorProvider } from 'src/app/services/error.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(
    private alertController: AlertController,
    private errorService: ErrorProvider
  ) { }

  handleError(error) {
    let noStorageAvailable = "No available storage"
    let cordovaNotAvailable = 'cordova_not_available'
    if(error && error.message && [noStorageAvailable, cordovaNotAvailable].every((message) => !error.message.includes(message))) {
      // console.log(error);
      // console.log(error.stack);
      this.errorService.logFrontendError('unknown', error);
      // this.presentAlert(error);
    }
  }

  async presentAlert(error) {
    const alert = await this.alertController.create({
      header: 'An Error Has Occurred',
      message: 'Unfortunately, the app needs to be restarted',
      buttons: [
        {
          text: 'Restart',
          handler: () => {
            window.location.reload();
          }
        }
      ]
    });

    await alert.present();
  }
}
