import { Injectable } from '@angular/core';
import owasp from 'owasp-password-strength-test';

@Injectable()
export class PasswordStrengthProvider {

  constructor() {
    owasp.config({
      minOptionalTestsToPass: 3,
      minLength: 8
    })
  }

  public check(password) {
    return owasp.test(password);
  }
}
