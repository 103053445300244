import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { NavController, NavParams,
         ToastController, AlertController, PopoverController } from '@ionic/angular';
import { Node, NodeListItem, TimerSetting } from '../model-defs/node-def';
import { SitesService, quickNodeType } from 'src/app/services/sites.service';
import { UserService } from 'src/app/services/user.service';
import { TimerService } from 'src/app/services/timer.service';

import { EnglishMetricFormat } from '../status-wind-rain/englishMetricConversion'
import { EditSettingPage } from '../edit-setting/edit-setting.page'
import { EditVentSettingPage } from '../edit-vent-setting/edit-vent-setting.page'
import { EditHeatCoolSettingPage } from '../edit-heat-cool-setting/edit-heat-cool-setting.page'
import { DehumidSettingsPage } from '../dehumid-settings/dehumid-settings.page';
import { AuditTrailPage } from '../audit-trail/audit-trail.page'
import { WindRainEditPage } from '../wind-rain-edit/wind-rain-edit.page'
import { SettingInfo } from '../status-wind-rain/setting-info';
import { TimerMisterSettings, ClimateBossRequestToKeys } from '../status/getTimerMisterSettings'
import { NavigationExtras, ActivatedRoute } from '@angular/router';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'timer-mister',
  templateUrl: './timer-mister.component.html',
  styleUrls: ['./timer-mister.component.scss'],
})
export class TimerMisterComponent implements OnInit {
  @Input() nodeData = new Node;
  @Input() timerInfo = new TimerSetting;
  @Input() isClimateBoss = false;
  @Input() tUnits;
  @Input() timerIndex;
  @Input() outputStatus;
  @Input() shadeInfo;
  @Input() offline;
  private timerMisterSettings;
  private pendingSettings = [];

  constructor(
    public navCtrl: NavController,
    private route: ActivatedRoute,
    public sitesApiProvider: SitesService,
    public alertCtrl: AlertController,
    private timers: TimerService,
    public userService: UserService,
    public modalCtrl: PopoverController,
    private toastCtrl: ToastController
  ) {
  }

  isAShadeSetting(key) {
    let shadeKeys = ["settings.shadeEnable", "settings.shadeSlowRetract", "settings.shadeOpenTime", "settings.shadeStep", "settings.shadePause"]
    return (shadeKeys.includes(key) || key.startsWith("shade"));
  }

  ngOnInit() {
    this.timerMisterSettings = TimerMisterSettings.getTimerMisterSettings();
    Object.keys(this.timerMisterSettings).forEach(setting => {
      if(this.timerMisterSettings[setting]) {
        this.timerMisterSettings[setting].pending = false;
      }
    });

    this.nodeData && this.nodeData.reqs && this.nodeData.reqs.forEach(req => {
      if (req.node_id === this.nodeData.node_id && req.key && (this.isClimateBoss && (req.key.startsWith("timer") || this.isAShadeSetting(req.key)))){
        var key = req.key
        var isCorrectTimerMister = this.isAShadeSetting(key) ? true : false;

        if(this.isClimateBoss && key.includes('timer')) {
          isCorrectTimerMister = key.includes(this.timerIndex)
          key = key.replace(`timer${this.timerIndex}.`, "");
          key = ClimateBossRequestToKeys[key]
        } else {
          key = this.isAShadeSetting(req.key) ? req.key : `settings.${req.key}`
        }

        if(this.timerMisterSettings[key] && (this.isClimateBoss && isCorrectTimerMister)) {
          this.timerMisterSettings[key].pending = true;
        }
      }
    });
  }

  public getTimerName() {
    return `Timer ${this.timerIndex ? `${this.timerIndex} ` : ''}Options`
  }

  public activateTimer(value = 1) {
    this.manuallyActivate('timerStart', 'Manually starting timer', value.toString())
  }

  private manuallyActivate(key, comment, value = '1') {
    this.sitesApiProvider.postRequest({
      node_id: this.nodeData.node_id,
      key: key,
      value: value,
      comment: comment
    });
    this.timers.setFastUpdate(60); //set to fast update for the next 60 seconds
    if(this.timerMisterSettings[`settings.${key}`]) {
      this.timerMisterSettings[`settings.${key}`].pending = true;
    }
  }

  public addIndexIfClimateBoss(key) {
    return (this.timerIndex && this.nodeData.model.startsWith("CB") && this.nodeData.direct_reporting) ? `timer${this.timerIndex}.${key}` : key;
  }

  async showWindRainEdit(key) {
    var settingsForKey = this.timerMisterSettings[key]
    if(!settingsForKey) return;
    let reqDataKey = this.isClimateBoss ? (settingsForKey.climate_boss || settingsForKey.requestKey) : settingsForKey.requestKey
    let request_key = this.isAShadeSetting(settingsForKey.requestKey) ? settingsForKey.requestKey : this.addIndexIfClimateBoss(reqDataKey);
    var reqData = {
      node_id: this.nodeData.node_id,
      key: request_key,
      value: '',
      comment: ''
    }

    var settingInfo = SettingInfo.get(this.nodeData, this.timerMisterSettings, key);
    if(this.isClimateBoss && this.isAShadeSetting(key)) {
      if(key === 'shadeEnable') { settingInfo.key = 'tbTimer1.mode'; }
      settingInfo.value = this.timerInfo[settingsForKey.climate_boss || settingsForKey.requestKey]
      settingInfo.pattern = settingInfo.pattern ? settingInfo.pattern : "\\.*";
    }

    if(settingInfo) {
      const options = {
        component: WindRainEditPage,
        componentProps: {
          settingInfo: settingInfo,
          siteName: this.nodeData.site_name,
          houseName: (this.nodeData.api_name || this.nodeData.node_name),
          canEdit: this.nodeData.canEdit,
          isPending: this.isPending(key)
        }
      };
      const settingsModal = await this.modalCtrl.create(options);

      settingsModal.onDidDismiss().then(data => {
        data = data.hasOwnProperty('data') ? data.data : data;
        if (typeof data !== 'undefined'){
          var value = data['value']
          if (data['button'] == "Save" && value != null){
            var eng = this.nodeData.settings.hasOwnProperty('english') ? this.nodeData.settings.english : true;
            reqData.value = settingsForKey.hasOwnProperty("convertValue") ? settingsForKey.convertValue(value.value, eng) : value['value'];
            reqData.comment = value['comment'];
            this.sitesApiProvider.postRequest(reqData);
            this.timers.setFastUpdate(60); //set to fast update for the next 60 seconds
            if(this.timerMisterSettings[key]) {
              this.timerMisterSettings[key].pending = true;
            }
          }
        }
      });
      settingsModal.present();
    }
  }

  public isPending(testKey, climateBossKey?){
    if(this.timerMisterSettings[testKey]) {
      return this.timerMisterSettings[testKey].pending;
    } else {
      return false;
    }
  }

  private isEnglishMetrics() {
    return (this.nodeData && this.nodeData.settings) && (this.nodeData.settings.hasOwnProperty('english') ? this.nodeData.settings.english : true);
  }

  private isCelsius() {
    if(this.userService.userLoginData && this.userService.userLoginData.settings) {
      return this.userService.getTemperatureScale() != 'fahrenheit';
    } else {
      return false;
    }
  }
}

export interface SettingInfoType {
    key: string;
    value: string;
    current?: string;
    type: string;
    pending: boolean;
    desc: string;
    pattern?: string;
    reformat?;
    error?: string;
    additionalOption?: {
      checked?: boolean;
      value: string;
      label: string;
    },
    valid?;
    units?;
    options?;
    inputmode?;
    disable_allowed?: boolean;
}
