const reformat = function(event, field, form, recursive?) {
  try {
    var timeString = recursive ? event : event.target.value;
    if(recursive || Number.isInteger(parseInt(event.key))) {
      timeString = timeString.replace(":", "")
      let updatedVal;
      if(timeString.length == 1) {
        updatedVal = `${timeString}`;
      } else if(timeString.length == 2) {
        updatedVal = `:${timeString}`
      } else if(timeString.length >= 3 && timeString.length <= 4) {
        var startInt = (timeString.length === 3) ? 1 : 2
        updatedVal = `${timeString.substring(0, startInt)}:${timeString.substring(startInt, timeString.length)}`        
      } else {
        if((timeString.length % 2) !== 0) {
          timeString = timeString.padStart((timeString.length + 1), '0')
        }

        var time_array = timeString.match(/.{1,2}/g);
        var formatted_string = time_array.map((segment) => {
          return reformat(segment, field, form, true)
        }).join("")
        updatedVal = formatted_string.substring(1, formatted_string.length)
      }

      if(recursive) {
        return updatedVal;
      } else {
        var valFormInput = form.get(field);
        valFormInput.setValue(updatedVal);
      }
    }
  } catch(e) {
    console.log(e)
  }
}

let reformatString = function(timeString) {
  timeString = timeString.replace(":", "")
    let updatedVal;
    if(timeString.length == 1) {
      updatedVal = `${timeString}`;
    } else if(timeString.length == 2) {
      updatedVal = `:${timeString}`
    } else if(timeString.length >= 3 && timeString.length <= 4) {
      var startInt = (timeString.length === 3) ? 1 : 2
      updatedVal = `${timeString.substring(0, startInt).padStart(2, '0')}:${timeString.substring(startInt, timeString.length)}`
    } else {
      if((timeString.length % 2) !== 0) {
        timeString = timeString.padStart((timeString.length + 1), '0')
      }

      var time_array = timeString.match(/.{1,2}/g);
      var formatted_string = time_array.map((segment) => {
        return this.reformatString(segment)
      }).join("")
      updatedVal = formatted_string.substring(1, formatted_string.length)
    }
    return updatedVal
}

export const TimeHelper = {
  reformat,
  reformatString
}
