import { Component, Input, OnInit } from '@angular/core';
import { CisProvider } from '../services/cis.service';
import { ToastController } from '@ionic/angular';
import { User } from '../model-defs/user-item';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-change-site-owner',
  templateUrl: './change-site-owner.component.html',
  styleUrls: ['./change-site-owner.component.scss'],
})
export class ChangeSiteOwnerComponent  implements OnInit {
  @Input() public cisList;  
  @Input() selectedCis;
  public newCisOwner;  
  public userIsNotRegistered;
  public newCisForm;

  public users: User[];

  constructor(private cisService: CisProvider, 
    private toastCtrl: ToastController,
    private userService: UserService
  ) {
    let user = new UntypedFormControl('', [Validators.required]);
    let cis = new UntypedFormControl('', [Validators.required]);
    this.newCisForm = new UntypedFormGroup({
      owner: new UntypedFormControl('', [Validators.required]),
      site_name: new UntypedFormControl('', [Validators.required])
    });
  }

  ngOnInit() {
    this.userService.getAllUsers().then((users) => {
      this.users = users;
    })
  }

  public changeOwner() {
    this.cisService.changeOwner({owner: this.newCisOwner.email, cis_id: this.selectedCis.cis_id}).then((data) => {
      this.updateCisInList(data);
    });
  }

  async updateCisInList(updatedCisEntry) {
    this.newCisOwner = undefined;
    var cisWithoutOldItem = this.cisList.filter(cis => cis.cis_id !== updatedCisEntry.cis_id)
    cisWithoutOldItem.push(updatedCisEntry)
    this.cisList = cisWithoutOldItem;
    this.selectedCis = null;
    this.showMessage(`Site, ${updatedCisEntry.cis_id}, updated`);
  }

  public selectNewOwner(event) {
    let owner_email = (event && event.value) ? event.value.email : "N/A"
    const user = this.users.find(user => {return user.email === owner_email});
    this.newCisOwner = user;
  } 
  
  public selectCis(selectedCis) {
    this.newCisOwner = undefined;
    this.selectedCis = selectedCis;
    const selectedOwner = this.getCisOwner();
    const user = this.users.find(user => {return user.email === selectedOwner});
    this.userIsNotRegistered = user === undefined;
  }  

  public getCisOwner() {
    var owner = ""
    if(this.cisList && this.selectedCis) {
      const selected = this.cisList.find((cis) => {return cis.cis_id === this.selectedCis.cis_id});
      if(selected) {
        var ownerFromCis = selected.users.find((u) => {return u.role === 4})
        owner = ownerFromCis ? ownerFromCis.email : ""
      }
    }

    return owner;
  }

  async showMessage(message) {
    let toast = await this.toastCtrl.create({
      message: message,
      duration: 1000,
      position: 'top'
    });

    toast.present();
  }
}
