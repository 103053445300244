<form *ngIf="showSection" [formGroup]="reportForm">
  <ion-item>
    <ion-label>Date</ion-label>
    <ion-select formControlName="date" >
      <ion-select-option *ngFor="let date of dates" [value]="date">{{date | date:'MMM dd'}}</ion-select-option>
    </ion-select>
  </ion-item>

  <ion-item>
    <ion-label>Email(s)</ion-label>
    <ion-input formControlName="email" placeholder="molly@bartinst.com"></ion-input>
  </ion-item>

  <ion-item>
    <ion-button slot="end" (click)="submit()">Submit</ion-button>
  </ion-item>
</form>