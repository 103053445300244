import {TimeFormat} from '../status-wind-rain/reformat-time';
import { EnglishMetricFormat } from '../status-wind-rain/englishMetricConversion'

var minutesOfDay = function(timeString){
  var splitTime = timeString.split(":")
  return parseInt(splitTime[1]) + (parseInt(splitTime[0]) * 60);
}

const settings = {
  "settings.timerStart": {pending: false},
  "settings.dhStart": {pending: false},
  "settings.timerStartTime": {
    desc: "Timer Start Time (hh:mm)", type: "text",
    pattern: "[0-9]{1,2}:[0-9]{2}",
    reformat: TimeFormat.reformatString,
    error: "Please enter only numbers in hh:mm format; 0:00-24:00 are valid values",
    requestKey: "timerStartTime",
    units: "hh:mm",
    valid: (val) => { return 0 <= minutesOfDay(val) && minutesOfDay(val) <= 1440; },
    pending: false,
    inputmode:"tel",
    climate_boss: "startTime"
  },
  "settings.timerHoldTime": {
    desc: "Timer Hold Time (hh:mm)", type: "text",
    pattern: "[0-9]{1,2}:[0-9]{2}",
    inputmode:"tel",
    error: "Please enter only numbers in mm:ss format; 0:00-24:00 are valid values",
    reformat: TimeFormat.reformatString,
    requestKey: "timerHoldTime",
    units: "hh:mm",
    valid: (val) => { return 0 <= minutesOfDay(val) && minutesOfDay(val) <= 1440; },
    pending: false,
    climate_boss: "holdTime" },
  "settings.timerRepeat": {
    desc: "Timer Repeat", type: "select",
    requestKey: "timerRepeat",
    pending: false,
    options: [{value:"ON" , text:"On"}, {value: "OFF", text: "Off"}],
    climate_boss: "repeat"
  },
  "settings.mistDifOnSec": {
    desc: "Mister: DIF On (seconds)", type: "text",
    pattern: "[0-9]*",
    inputmode:"tel",
    error: "Sorry only numbers are allowed; 0-99 are valid values",
    units: "sec",
    valid: (val) => { return 0 <= parseInt(val) && parseInt(val) <= 99},
    requestKey: "mistDifOnSec", pending: false},
  "settings.mistDifCycleMin": {
    desc: "Mister: DIF Cycle Time (minutes)", type: "text",
    pattern: "[0-9]*",
    inputmode:"tel",
    units: "min",
    error: "Sorry only numbers are allowed",
    requestKey: "mistDifCycleMin",
    valid: (val) => { return 0 <= parseInt(val) && parseInt(val) <= 99},
    pending: false},
  "settings.mistDayOnSec": {
    desc: "Mister: DAY On (seconds)", type: "text",
    pattern: "[0-9]*",
    inputmode:"tel",
    units: "sec",
    error: "Sorry only numbers are allowed",
    requestKey: "mistDayOnSec",
    valid: (val) => { return 0 <= parseInt(val) && parseInt(val) <= 99},
    pending: false},
  "settings.mistDayCycleMin": {
    desc: "Mister: DAY Cycle Time (minutes)", type: "text",
    pattern: "[0-9]*",
    inputmode:"tel",
    units: "min",
    error: "Sorry only numbers are allowed; 0-99 are valid values",
    requestKey: "mistDayCycleMin",
    valid: (val) => { return 0 <= parseInt(val) && parseInt(val) <= 99},
    pending: false},
  "settings.mistNightOnSec": {
    desc: "Mister: NITE On (seconds)", type: "text",
    pattern: "[0-9]*",
    inputmode:"tel",
    units: "sec",
    error: "Sorry only numbers are allowed; 0-99 are valid values",
    requestKey: "mistNightOnSec",
    valid: (val) => { return 0 <= parseInt(val) && parseInt(val) <= 99},
    pending: false},
  "settings.mistNightCycleMin": {
    desc: "Mister: NITE Cycle Time (minutes)", type: "text",
    pattern: "[0-9]*",
    inputmode:"tel",
    error: "Sorry only numbers are allowed; 0-99 are valid values",
    requestKey: "mistNightCycleMin",
    units: "min",
    valid: (val) => { return 0 <= parseInt(val) && parseInt(val) <= 99},
    pending: false},
  "settings.dhVentMode":{
    desc: "Dehumidification: Vent Mode", type: "select",
    requestKey: "dhVentMode",
    pending: false,
    options: [{value:"Timed" , text:"Timed"}, {value: "Off", text: "Off"}, {value: "Humid", text: "Humidity"}]
  },
  "settings.dhStartTime":{
    desc: "Dehumidification: Start Time (hh:mm)", type: "text",
    pattern: "[0-9]{1,2}:[0-9]{2}",
    inputmode:"tel",
    units: "hh:mm",
    error: "Please enter only numbers in hh:mm format; 0:00-24:00 are valid values",
    reformat: TimeFormat.reformatString,
    requestKey: "dhStartTime",
    valid: (val) => { return 0 <= minutesOfDay(val) && minutesOfDay(val) <= 1440},
    pending: false },
  "settings.dhRepeat":{
    desc: "Dehumidification: Repeat", type: "select",
    requestKey: "dhRepeat",
    pending: false,
    options: [{value:"ON" , text:"On"}, {value: "OFF", text: "Off"}]
  },
  "settings.dhFan1":{
    desc: "Dehumidification: Fan 1 (min)", type: "text",
    pattern: "[0-9]*",
    inputmode:"tel",
    units: "min",
    error: "Sorry only numbers are allowed; 0-30 are valid values",
    requestKey: "dhFan1",
    valid: (val) => { return 0 <= parseInt(val) && parseInt(val) <= 30},
    pending: false},
  "settings.dhFan2":{
    desc: "Dehumidification: Fan 2 (min)", type: "text",
    pattern: "[0-9]*",
    inputmode:"tel",
    units: "min",
    error: "Sorry only numbers are allowed; 0-30 are valid values",
    requestKey: "dhFan2",
    valid: (val) => { return 0 <= parseInt(val) && parseInt(val) <= 30},
    pending: false},
  "settings.dhHeat":{
    desc: "Dehumidification: Heat (min)", type: "text",
    pattern: "[0-9]*",
    inputmode:"tel",
    error: "Sorry only numbers are allowed; 0-30 are valid values",
    requestKey: "dhHeat",
    units: "min",
    valid: (val) => { return 0 <= parseInt(val) && parseInt(val) <= 30},
    pending: false},
  "settings.dhSp":{
    desc: "Dehumidification: Set Point (%)", type: "text",
    pattern: "[0-9]*",
    inputmode:"tel",
    error: "Sorry only numbers are allowed; 0-100 are valid values",
    requestKey: "dhSp",
    units: "%",
    valid: (val) => { return 0 <= parseInt(val) && parseInt(val) <= 100},
    pending: false},
  "settings.dhDif":{
    desc: "Dehumidification: Enabled in DIF", type: "select",
    requestKey: "dhDif",
    pending: false,
    options: [{value:"ON" , text:"On"}, {value: "OFF", text: "Off"}]
  },
  "settings.dhDay":{
    desc: "Dehumidification: Enabled in DAY", type: "select",
    requestKey: "dhDay",
    pending: false,
    options: [{value:"ON" , text:"On"}, {value: "OFF", text: "Off"}]
  },
  "settings.dhNight":{
    desc: "Dehumidification: Enabled in NITE", type: "select",
    requestKey: "dhNight",
    pending: false,
    options: [{value:"ON" , text:"On"}, {value: "OFF", text: "Off"}]
  },
  "settings.dhSetup":{
    desc: "Dehumidification: Setup", type: "text",
    pattern: "[0-9]*",
    inputmode:"tel",
    error: "Sorry only numbers are allowed",
    requestKey: "dhSetup", pending: false},
  "settings.dhLoTemp":{
    desc: "Dehumidification: Low Temp", type: "text",
    pattern: "[0-9]*",
    inputmode:"tel",
    error: "Sorry only numbers are allowed",
    requestKey: "dhLoTemp", pending: false,
    convertValue: (val, english) => { return english ? val : EnglishMetricFormat.convertToFahrenheit(val) },
    additionalOption: {
      label: "Use Heat 1",
      value: 0,
      checked: false
    }
  },
  "settings.dhHiTemp":{
    desc: "Dehumidification: High Temp", type: "text",
    pattern: "[0-9]*",
    inputmode:"tel",
    error: "Sorry only numbers are allowed",
    requestKey: "dhHiTemp", pending: false,
    convertValue: (val, english) => { return english ? val : EnglishMetricFormat.convertToFahrenheit(val) },
    additionalOption: {
      label: "Use Cool 2",
      value: 0,
      checked: false
    }
  },
  "settings.shadeEnable": {
    desc: "Enable Shade", type: "select",
    options: [{value:"1" , text:"On"}, {value: "0", text: "Off"}],
    pending: false,
    requestKey: "shadeEnable"
  },
  "settings.shadeSlowRetract": {
    desc: "Enable Slow Retract", type: "select",
    options: [{value:"1" , text:"On"}, {value: "0", text: "Off"}],
    pending: false,
    requestKey: "shadeSlowRetract"
  },
  "settings.shadeOpenTime": {
    desc: "Shade Open Time",
    requestKey: "shadeOpenTime",
    pending: false, type: "text",
    pattern: "[0-9]*",
    inputmode:"tel",
    error: "Sorry only numbers are allowed",
    valid: (val) => { return 0 <= parseInt(val) && parseInt(val) <= 9999},
  },
  "settings.shadeStep": {
    desc: "Shade Step",
    pending: false, type: "text",
    pattern: "[0-9]*",
    inputmode:"tel",
    error: "Sorry only numbers are allowed",
    requestKey: "shadeStep",
    valid: (val) => { return 0 <= parseInt(val) && parseInt(val) <= 9999},
  },
  "settings.shadePause": {
    desc: "Shade Pause Time",
    requestKey: "shadePause",
    pending: false, type: "text",
    pattern: "[0-9]*",
    inputmode:"tel",
    error: "Sorry only numbers are allowed",
    valid: (val) => { return 0 <= parseInt(val) && parseInt(val) <= 9999},
  },
  "settings.shadeTempSP": {
    desc: "Shade Temperature Set Point",
    requestKey: "shadeTempSP",
    pending: false, type: "text",
    pattern: "[0-9]*",
    inputmode:"tel",
    error: "Sorry only numbers are allowed",
    valid: (val) => { return 0 <= parseInt(val) && parseInt(val) <= 9999},
  }
};

export const TimerMisterSettings = {
  getTimerMisterSettings: () => {
    return Object.assign({}, settings);
  }
}

export const ClimateBossRequestToKeys = {
  startTime: "settings.timerStartTime",
  holdTime: "settings.timerHoldTime",
  repeat: "settings.timerRepeat",
  mistDifOnSec: "settings.mistDifOnSec",
  mistDifCycleMin: "settings.mistDifCycleMin",
  mistDayOnSec: "settings.mistDayOnSec",
  mistDayCycleMin: "settings.mistDayCycleMin",
  mistNightOnSec: "settings.mistNightOnSec",
  mistNightCycleMin: "settings.mistNightCycleMin"
}
