import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ErrorProvider {
  private baseUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    public userService: UserService
  ) {}

  public logFrontendError(component, error) {
    var url = this.baseUrl + "/api/error/frontend";
  }
}
