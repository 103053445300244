import { AfterViewChecked, AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
})
export class ViewComponent  implements OnInit {
  @Input("option") option;
  @Input("name") name;
  @Input("settings") settings;

  public showGraph = false;
  public reviewing = true;

  public timer_values = [];
  public model = "CB-GHK";
  public populatedVents = []

  constructor() { }

  ngOnInit() {
    if(this.settings) {
      this.settings.difSt = parseInt(this.settings.difSt)
      this.settings.daySt = parseInt(this.settings.daySt)
      this.settings.nitSt = parseInt(this.settings.nitSt)
      this.settings.c1t = parseInt(this.settings.c1t)
      this.settings.c2t = parseInt(this.settings.c2t)
      this.settings.c3t = parseInt(this.settings.c3t)
      this.settings.c4t = parseInt(this.settings.c4t)
      this.settings.h1t = parseInt(this.settings.h1t)
      this.settings.h2t = parseInt(this.settings.h2t)
      this.populatedVents = this.settings.vent.map((v, i) => {
        v.index = i
        return v;
      }).filter((v) => v.st !== undefined)

      this.timer_values = [{...this.settings.group1Timer[0], ...this.settings.group1Timer[0]}, {...this.settings.group1Timer[1], ...this.settings.group1Timer[1]}]
    }
  }

  showHideGraph() {
    this.showGraph = !this.showGraph;
  }

  getVentName(index) {    
    let defaultName = this.model.includes('RWL') ? ['A', 'B', 'C', 'D'][index] : ['', '', 'C', 'D'][index];
    if(this.settings.vent && this.settings.vent[index] && this.settings.vent[index].vent_name) {
      return this.settings.vent[index].vent_name;
    } else {
      return defaultName;
    }
  }

  dehumidificationSetupValue(setting) {
    var inBits = (parseInt(setting)).toString(2)
    var updatedSettings = inBits.padStart(8, "0");
    var splitSettings = updatedSettings.split("")

    return `${splitSettings[7] === '0' ? 'Cool 1, ' : ''}${splitSettings[6] === '0' ? 'Cool 2, ' : ''}${splitSettings[5] === '0' ? 'Cool 3, ' : ''}${splitSettings[4] === '0' ? 'Cool 4, ' : ''}` +
           `${splitSettings[3] === '0' ? 'Heat 2, ' : ''}${splitSettings[2] === '0' ? 'Heat 1, ' : ''}${splitSettings[1] === '0' ? 'Percent 1, ' : ''}${splitSettings[0] === '0' ? 'Enable Heat' : ''}`;
  }
}
