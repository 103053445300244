<h5>The following users have access to one or more controllers that you administer.
  You may add a user or select any user to change their permissions.</h5>
<div class="users-list">
  <div class="editable-users" *ngFor="let user of userList">
    <label>{{user.email}}</label>
    <span>
      <ion-icon name="create" (click)="selectUser(user.email)">
    </ion-icon>    
    </span>    
  </div>
  <form id="addUserForm" [formGroup]="addUserForm" (ngSubmit)="addUser(addUserForm)">
    <div class="error">
      <p *ngIf="addUserForm.controls.username.hasError('required') && formSubmitted">Sorry, an email address is required!</p>
      <p *ngIf="addUserForm.controls.username.hasError('email') && formSubmitted">Sorry, must be a valid email address!</p>
    </div>

    <div class="add-user-form">
      <input formControlName="username" type="email" placeholder="Email" autofocus="true" />
      <button class="add-user" type="submit">
        <ion-icon name="person-add" slot="end"></ion-icon>
        <span>Add User</span>
      </button>
    </div>
  </form>
</div>
