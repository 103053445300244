import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Site } from '../model-defs/site-def';
import { Node, NodeListItem } from '../model-defs/node-def';
import { UserService } from './user.service';
import { TimerService } from './timer.service';
import { environment } from '../../environments/environment';
import { EnglishMetricFormat } from '../status-wind-rain/englishMetricConversion'
import { Platform } from '@ionic/angular';
import { Observable, BehaviorSubject, timer, from, of, throwError } from "rxjs";
import moment from 'moment';
declare var cordova: any;

@Injectable({ providedIn: 'root' })
export class SitesService {
  private baseUrl = environment.apiUrl;
  private sitesUrl = this.baseUrl + "/api/sites";
  private nodesUrl = this.baseUrl + "/api/nodes";
  private nodeLogUrl = this.baseUrl + "/api/nodelog24";
  private nodesAdminUrl = this.baseUrl + "/api/admin/nodes";
  // private nodesAdminUrl = this.baseUrl + "/api/nodes/admin/";
  private reqUrl = this.baseUrl + "/api/requests";
  private shareUrl = this.baseUrl + "/api/nodes/share";
  public adminNodeId = "";
  public site: Site;
  public site_loaded = false;
  public doSiteUpdates = false; //whether or not to do timed site data updates
  public doUpdates = 0; //determines which updates to do on a timed basis, 0=none, 1=node list, 2=node data, 3=admin node list
  public activeNodeId = ""; //the node id that is currenly actively updating
  public pushToken;
  public nodeInList; //node id to find in list for next or previous function
  public tempUnits = "°F"; //fixed value for now, need to add ability to change units
  public nodeData: Node; //copy of the last node data received
  public platform_width;
  public fileUrl;
  public loginAs;
  private nodeList;
  private previousTwentyFourHourAverages = {};
  private previousTwentyFourHourAveragesUpdated;
  private fetchingNodes = false;

  public nodeListSubscription = new BehaviorSubject<any>({});
  public nodeDataSubscription = new BehaviorSubject<any>({});
  public nextPreviousSubscription = new BehaviorSubject<any>({});

  constructor(
    private http: HttpClient,
    private users: UserService,
    private timerService: TimerService,
    public platform: Platform
  ) {
    this.timerService.timerSubscription.subscribe(() => {
      this.onTimerUpdate();
    })
    this.platform_width = this.platform.width();
  }

  public onTimerUpdate() {
    // console.log(`onTimerUpdate, this.doUpdates: ${this.doUpdates}`)
    //check which updates are enabled
    if (this.doUpdates == 1) {
      //update the node list
      var loginData = this.users.getLoginData();
      this.getNodeList(loginData.is_bart_admin, (loginData.is_bart_admin ? this.loginAs : undefined));
    } else if (this.doUpdates == 2) {
      //update node data for the selected node
      this.getNodeData();
      if(this.platform_width && this.platform_width >= 820) {
        var loginData = this.users.getLoginData();
        this.getNodeList(loginData.is_bart_admin, (loginData.is_bart_admin ? this.loginAs : undefined));
      }
    } else if (this.doUpdates == 3){
      //update the node list admin version
      this.getNodeList(true, this.loginAs);
    }
  }

  getLatestList() { return this.nodeList; }

  registerNextPrevious(nodeId, page?) {
    // console.log(`registerNextPrevious for ${nodeId}`)
    this.nodeInList = nodeId;
    this.getNextPrevious(this.nodeList)
  }

  // ---- get node list ----
  //get the list of nodes for the current user (by token)
  getNodeList(bartAdmin: boolean, loginAs?: boolean): Promise<any> {
    var listUrl;
    if (bartAdmin) {
      if(loginAs) { this.loginAs = loginAs; }
      // console.log(`admin node id: ${this.adminNodeId}, loginAs: ${loginAs}`)
      listUrl = loginAs ? `${this.nodesAdminUrl}/${this.adminNodeId}?loginAs=${loginAs}` : ((this.adminNodeId && this.adminNodeId !== '') ? `${this.nodesAdminUrl}/${this.adminNodeId}` : this.nodesUrl);
    } else {
      listUrl = this.nodesUrl;
      this.loginAs = undefined;
    }

    return new Promise((resolve, reject) => {
      if(!this.fetchingNodes) {
        this.fetchingNodes = true;
        return this.http.get(listUrl)
                    .toPromise()
                    .then(response => this.nodeListResponse(response, resolve))
                    .catch(error => this.nodeListError(error, reject));
      } else { 
        resolve({success: true}); 
      }
    })
  }

  getNodeName(node_id) {
    let found_node = this.nodeList.find((n) => {n.node_id === node_id})
    return found_node ? found_node.node_name : node_id;
  }

  private nodeListResponse(response, callback){
    this.fetchingNodes = false;
    //got the node list
    let logged_in_user = this.users.getLoginData()
    var updateListWithData = this.checkOptionsList;
    let temp_scale = this.users.getTemperatureScale()
    var updatedNodeList = response.nodes.map(function(node) {
      if(node && node.model && node.model.includes('TB')) {
        node.node_name = node.api_name ? node.api_name : node.node_name;
        return node;
      } else {
        return updateListWithData(node, logged_in_user, temp_scale);
      }
    });

    // response.nodes = updatedNodeList;
    this.nodeList = updatedNodeList;
    if(updatedNodeList) {      
      this.nodeListSubscription.next({nodes: updatedNodeList})      
    }
    
    var isDesktop = (this.platform_width && this.platform_width >= 820)
    if (this.nodeInList && !isDesktop) {
      this.getNextPrevious(updatedNodeList, callback);
    }

    callback && callback(response);
  }

  private checkOptionsList(data, logged_in_user, temp_scale){
    //check model options
    if(!data.model) {
      data.isRWL = false;
      data.hasH3 = false;
      data.hasPo1 = false;
      data.hasPo2 = false;
    }

    data.node_name = data.api_name ? data.api_name : data.node_name;
    if (data.model && data.model.includes('RWL')) {
      data.isRWL = true;
      data.hasPo1 = true; //RWL does have proportional output 1
      data.hasPo2 = true; //RWL does have proportional output 2      

      if((data.settings && data.settings.sp3 > 250) || (data.status && (!data.status.ct3 || data.status.ct3 > 250))) {        
        data.hasH3 = false; //RWL does not have heat 3
      } else {
        //is an RWL controller
        data.hasH3 = true; //RWL does not have heat 3        
      }
    } else { //is not an RWL controller (is a GHK)
      data.isRWL = false;
      data.hasH3 = data.model ? data.model.replace("CB-", "").replace("GHK", "").includes('H') : false; //has heat 3 option
      data.hasPo1 = data.model ? data.model.replace("CB-", "").replace("GHK", "").includes('C') : false; //has curtain option
      data.hasPo2 = data.model ? (data.model.includes("CB") && data.direct_reporting) : false; //GHK never has proportional output 2
    }

    data.canEdit = false;
    if(logged_in_user) {
      data.users && data.users.forEach(user => {
        if (user.email == logged_in_user.email) {
          //check for admin role
          if(user.role >= 3) {
            data.admin = true;
          }
          //check for edit role
          if(user.role >= 2) {
            data.canEdit = true;
          }
        }
      });
    } else {
      data.canEdit = false;
      data.admin = false;
    }

    let isCelsius = temp_scale ? (temp_scale != 'fahrenheit') : false;
    if(isCelsius && data.settings) {
      var statusKeysToConvert = [
        'ct1', 'ct2', 'ct3', 'h3',
        'min1', 'max1', 'min2', 'max2', 'outsideTemp'
      ];

      statusKeysToConvert.forEach((key) => {
        if(data.status && data.status[key]) {
          data.status[key] = EnglishMetricFormat.convertToCelsius(data.status[key]);
        }
      });

      var settingsKeysToConvert = [
        'alarm1l','alarm1h','alarm2l','alarm2h',
        'sp1', 'sp2', 'sp3',
        'difsp1','daysp1','nitsp1',
        'difsp2','daysp2','nitsp2',
        'v1st'
      ];
      
      settingsKeysToConvert.forEach((key) => {
        if(key.includes('alarm') && data.settings[key] === 0) { } else {
          data.settings[key] = EnglishMetricFormat.convertToCelsius(data.settings[key]);
        }
      });

      var settingRatesToConvert = ['h1t', 'h2t', 'c1t', 'c2t', 'c3t', 'c4t', 'alarm1l_float', 'alarm2l_float'];
      settingRatesToConvert.forEach((key) => {
        if(!data.settings) console.log(data.node_id)        
        if(data.settings[key] !== undefined) {
          data.settings[key] = EnglishMetricFormat.offsetConvertToCelsius(data.settings[key]);        
        }
      });
    }

    if(data && data.settings) {
      data.setPt1 = data.settings.sp1;
      data.setPt2 = data.settings.sp2;
      data.setPt3 = data.settings.sp3;
    }

    return data;
  }

  private nodeListError(error, callback){
    this.fetchingNodes = false;
    //error getting list
    console.log(`error getting list`);
    console.log(error);
    callback(error.error);
  }

  //get next and previous nodes from list when requested
  private getNextPrevious(nodeList, callback = null) {
    // console.log("node in list: " + JSON.stringify(this.nodeInList));
    var i = nodeList.findIndex((node) => node.node_id === this.nodeInList);
    // console.log(`${this.nodeInList} is ${i} of ${nodeList.length} in ${nodeList.map((n) => n.node_id).join(", ")} ids`)
    // console.log("node index=" + i + " of " + nodeList.length);
    var next = i + 1;
    if (next >= nodeList.length) {
      next = 0;
    }

    var previous = i - 1;
    if (previous <  0) {
      previous = nodeList.length - 1;
    }

    // console.log(`this.nodeInList: ${i}, next: ${next}, prev: ${previous}`)
    var nextNode = (next !== i) ? Object.assign({}, nodeList[next]) : undefined
    var prevNode = (previous !== i) ? Object.assign({}, nodeList[previous]) : undefined;
    // console.log("nextPrevious for " + this.nodeInList + "next node: " + nextNode.node_id + " " + nextNode.node_name + " " + " prev node: " + prevNode.node_id + " " + prevNode.node_name + " ");
    callback && callback({nextNode: nextNode, prevNode: prevNode})
    this.nextPreviousSubscription.next({next: nextNode, prev: prevNode})
  }
  //--------------------

  // ---- get node data ----
  //get the node data for the selected node using current user (by token)
  getNodeData(): Promise<any> {
    var getNodeUrl = this.nodesUrl + "/" + this.activeNodeId;
    
    if(this.activeNodeId) {
      return new Promise((resolve, reject) => {
        this.http.get(getNodeUrl)
                .toPromise()
                .then(response => {
                  if(!response) {
                    reject();
                  } else {
                    this.nodeData = response as Node;
                    if(this.nodeData.model && this.nodeData.model.includes('TB')) {
                      this.nodeDataSubscription.next(response);
                      resolve(response);
                    } else {
                      var extData: Node;
                      extData = this.checkOptions(this.nodeData);
                      this.nodeDataSubscription.next(extData);
                      resolve(extData);
                    }
                  }
                })
                .catch(error => {
                  console.log("error getting node data");
                  console.log(error)
                  reject();
                });
      })
    } else {
      return new Promise((resolve, reject) => { resolve(0)})
    }
  }

  public getLocalNodeResponseData() {
    return this.nodeData;
  }
  //--------------------

  // ---- check options ----
  // set extended node data for model types and user roles
  private checkOptions(data: Node){
    //check model options
    if (data.model && data.model.includes('RWL')) {
      //is an RWL controller
      data.isRWL = true;
      data.hasPo1 = true; //RWL does have proportional output 1
      data.hasPo2 = true; //RWL does have proportional output 2
      if((data.settings && data.settings.sp3 > 250) || (data.status && (!data.status.ct3 || data.status.ct3 > 250))) {        
        data.hasH3 = false; //RWL does not have heat 3
      } else {
        //is an RWL controller
        console.log(`${data.node_id} has heat 3 & is RWL | ct3: ${data.status.ct3} | SP3: ${data.settings.sp3}, therm_fail: ${data.settings.therm_fail}, ${data.status.alarm}`)
        data.hasH3 = true; //RWL does not have heat 3        
      }      
    } else {
      //is not an RWL controller (is a GHK)
      data.isRWL = false;
      data.hasPo2 = data.model ? (data.model.includes("CB") && data.direct_reporting) : false; //GHK never has proportional output 2
      //does not have heat 3 options
      data.hasH3 = data.model ? data.model.replace("CB-", "").replace("GHK", "").includes('H') : false;
      //has curtain option
      data.hasPo1 = data.model ? data.model.replace("CB-", "").replace("GHK", "").includes('C') : false;

      //check stage for valid value
      if (data && data.status && (data.status.stage != "DAY"
        && data.status.stage != "NITE"
        && data.status.stage != "DIF")){
          data.status.stage = "OFF";
      }
    }

    //set display text for heats and cools

    let temp_scale = this.users.getTemperatureScale()
    let isCelsius = temp_scale ? (temp_scale != 'fahrenheit') : false;
    var units = isCelsius ? '˚C' : '˚F';
    if(!data.settings) {
      data.settings = {};
    }

      if(isCelsius) {
      var statusKeysToConvert = [
        'ct1', 'ct2', 'ct3', 'h3',
        'min1', 'max1', 'min2', 'max2', 'outsideTemp'
      ];

      statusKeysToConvert.forEach((key) => {
        if(data.status && data.status[key]) {
          data.status[key] = EnglishMetricFormat.convertToCelsius(data.status[key]);
        }
      });

      var settingsKeysToConvert = [
        'alarm1l','alarm1h','alarm2l','alarm2h',
        'sp1', 'sp2', 'sp3',
        'difsp1','daysp1','nitsp1',
        'difsp2','daysp2','nitsp2', 'v1st'
      ];
      
      settingsKeysToConvert.forEach((key) => {
        if(key.includes('alarm') && data.settings[key] === 0) { } else {
          data.settings[key] = EnglishMetricFormat.convertToCelsius(data.settings[key]);
        }
      });

      var settingRatesToConvert = ['h1t', 'h2t', 'c1t', 'c2t', 'c3t', 'c4t', 'alarm1l_float', 'alarm2l_float'];
      settingRatesToConvert.forEach((key) => {
        if(data.settings[key]) {
          data.settings[key] = EnglishMetricFormat.offsetConvertToCelsius(data.settings[key]);
        }
      });
    }

    if (data.settings && data.settings.h1m == "AUTO"){
      var h1temp = data.settings.sp1 - data.settings.h1t;
      // if(isCelsius) { h1temp = EnglishMetricFormat.offsetConvertToCelsius(h1temp); }
      data.settings.h1text = (h1temp).toString() + units;
    } else {
      data.settings.h1text = data.settings ? data.settings.h1m : "N/A";
    }

    if (data.settings && data.settings.h2m == "AUTO"){
      let set_point = data.status.zones === 1 ? data.settings.sp1 : data.settings.sp2
      var h2temp = set_point - data.settings.h2t
      // if(isCelsius) { h2temp = EnglishMetricFormat.offsetConvertToCelsius(h2temp); }
      data.settings.h2text = (h2temp).toString() + units;
    } else {
      data.settings.h2text = data.settings ? data.settings.h2m: "N/A";
    }

    if (data.settings && data.settings.c1m == "AUTO"){
      var c1temp = data.settings.sp1 + data.settings.c1t
      // if(isCelsius) { c1temp = EnglishMetricFormat.offsetConvertToCelsius(c1temp); }
      data.settings.c1text = (c1temp).toString() + units;
    } else {
      data.settings.c1text = data.settings ? data.settings.c1m : "N/A";
    }

    if (data.settings.c2m == "AUTO"){
      var c2temp = data.settings.sp1 + data.settings.c2t
      // if(isCelsius) { c2temp = EnglishMetricFormat.offsetConvertToCelsius(c2temp); }
      data.settings.c2text = (c2temp).toString() + units;
    } else {
      data.settings.c2text = data.settings.c2m;
    }
    if (data.settings && data.settings.c3m == "AUTO"){
      var c3temp = data.settings.sp2 + data.settings.c3t
      // if(isCelsius) { c3temp = EnglishMetricFormat.offsetConvertToCelsius(c3temp); }
      data.settings.c3text = (c3temp).toString() + units;
    } else {
      data.settings.c3text = data.settings ? data.settings.c3m : "N/A";
    }
    if (data.settings && data.settings.c4m == "AUTO"){
      var c4temp = data.settings.sp2 + data.settings.c4t
      // if(isCelsius) { c4temp = EnglishMetricFormat.offsetConvertToCelsius(c4temp); }
      data.settings.c4text = (c4temp).toString() + units;
    } else {
      data.settings.c4text = data.settings ? data.settings.c4m : "N/A";
    }

    //check user role
    //default to not admin and no edit
    data.isAdmin = false;
    data.canEdit = false;
    data.users && data.users.forEach(user => {
      if (user.email == this.users.getLoginData().email){
        //check for admin role
        if(user.role >= 3){
          data.isAdmin = true;
        }
        //check for edit role
        if(user.role >= 2){
          data.canEdit = true;
          // console.log(`user, ${user.email} has permissions to edit ${data.node_name}`)
        }
      }
    });

    return data;
  }
  //--------------------


  claimSite(siteCode: string): Promise<any>{
    var claim = {
      site_id: siteCode
    };

    return this.http.post(this.sitesUrl + "/claim", claim).toPromise();
  }

  // get("/api/sites/:site_id")
  getSite(site_id): Promise<Site> {
    return this.http.get(this.sitesUrl + '/' + site_id)
                .toPromise()
                .then(response => response)
                .catch(this.handleError);
  }

  private handleError (error: any): Promise<any> {
    let errMsg = (error.message) ? error.message :
    error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console
    return Promise.reject(errMsg);
  }

  //post a request for settings change, etc.
  postRequest(reqData): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.reqUrl, reqData)
            .toPromise()
            .then(response => { resolve(response); })
            .catch(error => { reject(error.error) });
    })
  }

  getNodeAuditTrail(node_id): Promise<any> {
    var auditTrailUrl = this.baseUrl + `/api/audit/${node_id}`
    return this.http.get(auditTrailUrl)
                .toPromise()
  }

  getAdminAuditSnapshot(): Promise<any> {
    var auditTrailUrl = `${this.baseUrl}/api/admin/snapshot`
    return this.http.get(auditTrailUrl)
                .toPromise()
  }

  getAdminAuditTrailReport(date?): Promise<any> {
    var auditTrailUrl = this.baseUrl + `/api/audit`
    return this.http.post(auditTrailUrl, {date: date || moment().format('YYYY-MM-DD')})
                .toPromise()
  }

  //post a share node request
  public requestShare(reqData): Promise<any> {
    return this.http.post(this.shareUrl, reqData)
                .toPromise()
  }

  public getSingleNodeUserList(node_id): Promise<any> {
    var getNodeUrl = `${this.nodesUrl}/users?node_id=${node_id}`
    return new Promise((resolve, reject) => {
      this.http.get(getNodeUrl)
                .toPromise()
                .then(response => resolve(response))
                .catch(error => reject(error ? error.error : "There was an error getting the node's users"));
    })
  }

  public updateGeneralSettings(updateDoc): Promise<any> {
    var getNodeUrl = `${this.nodesUrl}`
    return this.http.put(getNodeUrl, updateDoc)
                .toPromise()
  }

  public adminSaveController(newController): Promise<any> {
    return this.http.post(`${this.nodesUrl}/checkback`, {
      serial_number: newController.serial,
      mac_address: newController.mac_address,
      model: newController.type
    })
      .toPromise()
  }

  public saveRoleUpdate(node_id, user_email, new_role): Promise<any> {
    var getNodeUrl = `${this.nodesUrl}/${node_id}/users`
    return this.http.post(getNodeUrl,
      {
        node_id: node_id,
        user_email: user_email,
        role: new_role
      })
      .toPromise()
  }

  public shareWithNewUser(node_id, new_user): Promise<any> {
    var getNodeUrl = `${this.nodesUrl}/${node_id}/users/share`
    return this.http.post(getNodeUrl,
      {
        node_id: node_id,
        user_email: new_user.email,
        role: new_user.role
      })
      .toPromise()
  }

  public removeUser(node_id, email): Promise<any> {
    var getNodeUrl = `${this.nodesUrl}/${node_id}/users`
    return this.http.post(getNodeUrl,
      {
        node_id: node_id,
        user_email: email,
        delete: true
      })
      .toPromise()
  }

  // ---- get nodes users list ----
  //get a list of users for all nodes in which the current user is an admin
  public getNodeUserList(): Promise<any> {
    var getNodeUrl = this.users.isLoggedInAs() ? `${this.nodesUrl}/users/v2?loginAs=${this.users.getLoggedInEmail()}` : `${this.nodesUrl}/users/v2`
    return this.http.get(getNodeUrl)
                .toPromise()
  }

  // ---- get nodes users list ----
  //get a list of users for all nodes in which the current user is an admin
  public getNodeListUserRoles(user_email: string): Promise<any> {
    var getNodeUrl = this.users.isLoggedInAs() ? `${this.nodesUrl + "/user/" + user_email}/?loginAs=${this.users.getLoggedInEmail()}` : this.nodesUrl + "/user/" + user_email
    return this.http.get(getNodeUrl)
                .toPromise()
  }

  public getSiteListForUser(user_email: string): Promise<any> {
    var getSitesUrl = this.users.isLoggedInAs() ? `${this.sitesUrl}/users?email=${user_email}}/?loginAs=${this.users.getLoggedInEmail()}` : `${this.sitesUrl}/users?email=${user_email}`
    return this.http.get(getSitesUrl).toPromise()    
  }

  getAlerts(node_id = undefined): Promise<any> {
    var alertsUrl = this.users.isLoggedInAs() ? `${this.baseUrl}/api/user/alerts?loginAs=${this.users.getLoggedInEmail()}` : `${this.baseUrl}/api/user/alerts`;
    if(node_id) {
      alertsUrl = this.users.isLoggedInAs() ? `${this.baseUrl}/api/user/alerts?node_id=${node_id}&loginAs=${this.users.getLoggedInEmail()}` : `${this.baseUrl}/api/user/alerts?node_id=${node_id}`;
    }

    return this.http.get(alertsUrl)
                .toPromise()
  }

  //post the alert request status
  setAlerts(nodeId: string, reqAlerts: boolean | string, details: NotificationDetails): Promise<any> {    
    var alertsUrl = this.users.isLoggedInAs() ? `${this.baseUrl}/api/nodes/alerts?loginAs=${this.users.getLoggedInEmail()}` : `${this.baseUrl}/api/nodes/alerts`;
    var postDoc = {
      node_id: nodeId,
      alerts:{
        push: reqAlerts,
        status: details.status,
        alarms: details.alarms,
        settings: details.settings,
        settingsFailed: details.settingsFailed,
        wind: details.wind,
        rain: details.rain,
        windAndRain: details.windAndRain
      }
    }

    return new Promise((resolve, reject) => {
      this.http.post(alertsUrl, postDoc)
                .toPromise()
                .then((response) => { resolve({...response, ...{node_id: nodeId}}) })
                .catch(error => { reject(error.error) });
    })
  }

  getPushNotifications(site_id = ''): Promise<any> {
    if(this.users.isLoggedInAs()) {
      site_id = this.users.getLoggedInSite();
    }

    var urlSecondHalf = (site_id !== '') ? `/api/pushnotifications?site_id=${site_id}`: "/api/pushnotifications";
    var notificationUrl = this.baseUrl + urlSecondHalf;
    return this.http.get(notificationUrl)
                .toPromise()
  }

  getAverageInformation(node_id, start=null, end=null): Promise<any> {
    var getNodeAveragesUrl = `${this.nodesUrl}/${node_id}/avgs`
    if(start && end) {
      getNodeAveragesUrl = `${this.nodesUrl}/${node_id}/avgs?start=${start}&end=${end}`
    }

    return this.http.get(getNodeAveragesUrl)
                .toPromise()
  }

  getUnclaimedNodes(): Promise<any> {
    var url = `${this.nodesUrl}/unclaimed`;
    return this.http.get(url)
                .toPromise()
  }

  getOfflineNodes(): Promise<any> {
    var url = `${this.nodesUrl}/offline`;
    return this.http.get(url)
                .toPromise()
  }

  // ---- get node log ----
  //get the log data for the selected node using current user (by token)
  public getNodeLog(nodeId?): Promise<any> {
    var offset = (new Date().getTimezoneOffset() / 60);
    var node_id = nodeId || this.activeNodeId;
    var getNodeUrl = `${this.nodeLogUrl}/${node_id}`;
    return this.http.get(getNodeUrl)
                .toPromise()
  }

  public getNodeLogByDate(nodeId, start, end) {
    var offset = (new Date().getTimezoneOffset() / 60);
    var node_id = nodeId || this.activeNodeId;
    var getNodeUrl = offset ? `${this.nodeLogUrl}/${node_id}?timezone=${offset}` : `${this.nodeLogUrl}/${node_id}`;
    return this.http.post(getNodeUrl, {start: start, end: end})
                .toPromise()
  }

  public getDownloadSettingsUrl(nodeId) {
    var node_id = nodeId || this.activeNodeId;
    return `${this.baseUrl}/api/controllers/${node_id}/settings?token=${this.users.getLoginData().authentication_token}`;
  }

  public importSettings(nodeId, import_settings): Promise<any> {
    var node_id = nodeId || this.activeNodeId;
    let import_url = `${this.baseUrl}/api/controllers/${node_id}/settings?token=${this.users.getLoginData().authentication_token}`;

    return this.http.put(import_url, import_settings)
      .toPromise();
  }

  public importSettingsFromLibrary(nodeId, libraryOptionId, index): Promise<any>{
    var node_id = nodeId || this.activeNodeId;
    let import_url = `${this.baseUrl}/api/controllers/${node_id}/settings?token=${this.users.getLoginData().authentication_token}`;
    return this.http.put(import_url, {libraryId: libraryOptionId, id: index}).toPromise();
  }

  public updateOutputNames(nodeId, output_names): Promise<any> {
    var node_id = nodeId || this.activeNodeId;
    let url = `${this.baseUrl}/api/nodes/${node_id}/outputs?token=${this.users.getLoginData().authentication_token}`;

    return new Promise((resolve, reject) => {
      this.http.put(url, output_names)
        .toPromise()
        .then(response => { resolve(response) })
        .catch(error => { reject(error) });
    })
  }

  public getDownloadStatusUrl(nodeId, endDate) {
    var node_id = nodeId || this.activeNodeId;
    if(endDate) {
      return `${this.baseUrl}/api/controllers/${node_id}/logs?token=${this.users.getLoginData().authentication_token}&end=${moment(endDate)}`;
    } else {
      return `${this.baseUrl}/api/controllers/${node_id}/logs?token=${this.users.getLoginData().authentication_token}`;
    }
  }
}

export interface NotificationDetails {
  push: boolean | string,
  status: boolean | string,
  alarms: boolean | string,
  settings: boolean | string,
  wind: boolean | string,
  rain: boolean | string,
  windAndRain: boolean | string,
  settingsFailed: boolean | string
  vfdLinkLost: boolean | string
}

export interface shareReqType {
  node_id: string,
  email: string,
  role: number
}

export interface quickNodeType {
  node_id: string,
  node_name: string,
  site_name: string,
  type: string
}
