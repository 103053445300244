<div class="button-container">
  <div *ngIf="!startFromScratchSelected">
    <ion-button class="load" (click)="startForm()" [disabled]="startFromScratch === undefined">Start Guide</ion-button>
  </div>
  <div class="review-small" *ngIf="startFromScratchSelected && smallScreen">
    <ion-button class="secondary" *ngIf="!reviewing" (click)="review()"><ion-icon name="eye-outline"></ion-icon></ion-button>
    <ion-button class="secondary" *ngIf="reviewing" (click)="backToGuide()"><ion-icon name="eye-off-outline"></ion-icon></ion-button>
    <ion-button *ngIf="!showGraph" [disabled]="!reviewing" (click)="showHideGraph()"><ion-icon name="stats-chart-outline"></ion-icon></ion-button>
    <ion-button *ngIf="showGraph" [disabled]="!reviewing" (click)="showHideGraph()"><ion-icon name="list-outline"></ion-icon></ion-button>
  </div>
  <div class="steps" >
    <ion-button alt="Back" *ngIf="startFromScratchSelected">
      <ion-icon *ngIf="smallScreen" (click)="goBack()" name="chevron-back-circle-outline"></ion-icon>
      <span *ngIf="!smallScreen">Previous</span>
    </ion-button>
    <ion-button alt="Forward" *ngIf="startFromScratchSelected && !finalReview && !reviewing" (click)="forward()"
      [disabled]="(programmingForm && programmingForm.controls[current_step] && programmingForm.controls[current_step].dirty && !programmingForm.controls[current_step].valid && programmingForm.controls[current_step].touched)">
      <ion-icon *ngIf="smallScreen && current_page && !(current_page.steps[0] === 'timers' || ['vents', 'vents_zone2'].includes(current_page.steps[0]))" name="chevron-forward-circle-outline"></ion-icon>
      <ion-icon *ngIf="smallScreen && current_page && (current_page.steps[0] === 'timers' || ['vents', 'vents_zone2'].includes(current_page.steps[0]))" name="play-skip-forward-outline"></ion-icon>
      <span *ngIf="!smallScreen">Forward</span>
    </ion-button>    
    <ion-button class="next vent" *ngIf="!finalReview && current_page && ['vents', 'vents_zone2'].includes(current_page.steps[0])" (click)="nextVent()" [disabled]="subFormInvalid()">
      <ion-icon *ngIf="smallScreen" name="chevron-forward-outline"></ion-icon>
      <span *ngIf="!smallScreen">Next Vent</span>
    </ion-button>
    <ion-button class="add timer" *ngIf="!finalReview && current_page && current_page.steps[0] === 'timers'" (click)="nextTimer()" [disabled]="subFormInvalid()">
      <ion-icon *ngIf="smallScreen" name="chevron-forward-outline"></ion-icon>
      <span *ngIf="!smallScreen">Next Timer</span>
    </ion-button>
    <ion-button *ngIf="reviewing && !finalReview" class="load" (click)="importToController()">
      <ion-icon name="cloud-upload-outline"></ion-icon>
      <span *ngIf="!smallScreen">&nbsp;Load to Controller</span>
    </ion-button>
    <ion-button *ngIf="finalReview" class="secondary" (click)="finalReview = false; reviewing = false;">
      <ion-icon *ngIf="smallScreen" name="close-circle-outline"></ion-icon>
      <span *ngIf="!smallScreen">Cancel</span>
    </ion-button>
    <ion-button *ngIf="finalReview" class="load" (click)="finalImport()">
      <ion-icon *ngIf="smallScreen" name="checkmark-done-outline"></ion-icon>
      <span *ngIf="!smallScreen">Confirm Import</span>
    </ion-button>        
  </div>    
</div>
