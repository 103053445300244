<div class="section" *ngIf="showSection" [class.active]="showSection">
  <form id="unclaimed-controller-search" [formGroup]="searchForm" (ngSubmit)="filter(searchForm.value)">
    <label>Search Controllers <input type='search' value="User" formControlName="searchTerm" autofocus="true" #searchInputId/></label>
    <div>
      <button type="button" (click)="cancelSearch()"><ion-icon name="close-outline"></ion-icon></button>
      <button type="submit" (click)="filter(searchForm.value)"><ion-icon name="search-outline"></ion-icon></button>
    </div>
  </form>
  <ul id="unclaimed-controllers">
    <li *ngFor="let controller of getControllers()">
      <h6>{{controller.node_id}}</h6>
      <p>Model: {{controller.model }}</p>
      <p>Checked Back: {{controller.createdAt | date:'short' }}</p>
      <p *ngIf="controller.registeredAt">Registered: {{controller.createdAt | date:'short' }}</p>
      <p>Online: {{controller.online}}</p>
    </li>
  </ul>
</div>
