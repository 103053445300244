import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
export class ReviewComponent  implements OnInit {
  @Input() programmingForm;
  @Input() showGraph
  @Input() narrow;
  @Input() model;
  @Input() vent_config;
  @Input() dehumValue;
  @Input() timer_values;
  @Input() reviewing;
  @Input() finalReview;
  @Input() timerTypes;

  constructor() { }

  ngOnInit() {}

  getVentName(index) {    
    let defaultName = this.model.includes('RWL') ? ['A', 'B', 'C', 'D'][index] : ['', '', 'C', 'D'][index];
    if(this.vent_config && this.vent_config[index] && this.vent_config[index].vent_name) {
      return this.vent_config[index].vent_name;
    } else {
      return defaultName;
    }
  }
}
