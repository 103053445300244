import { Component, OnInit, Input, ViewChild, ElementRef, ViewEncapsulation, Output, EventEmitter, OnChanges, ChangeDetectorRef } from '@angular/core';
import { AlertController, NavController, ToastController } from '@ionic/angular'; 
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SitesService } from 'src/app/services/sites.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import moment from 'moment';

@Component({
  selector: 'app-admin-subscriptions',
  templateUrl: './admin-subscriptions.component.html',
  styleUrls: ['./admin-subscriptions.component.scss'],
})
export class AdminSubscriptionsComponent implements OnInit {
  @Input() showSection;
  @Input() showMessage;
  @ViewChild('searchInputId') searchInputId: ElementRef;
  public subscriptions = [];
  public filteredSubscriptions = [];
  public searchForm: UntypedFormGroup;
  public filterValue;
  public subscription_summary = {
    total: 0,
    active: 0,
    never_expires: 0,
    past_due_or_expired: 0,
    cancelled: 0,
    recently_expired_or_past_due: [],
    upcoming_renewal: [],
    all: [],
    owners: {}
  };
  public selected;

  constructor(
    public navCtrl: NavController,
    public alertCtrl: AlertController,
    public controllersService: SitesService,
    private ref: ChangeDetectorRef,
    private toastCtrl: ToastController,
    private subscriptionService: SubscriptionService
  ) {
    this.searchForm = new UntypedFormGroup({
      searchTerm: new UntypedFormControl('')
    });
  }

  ngOnInit() {
    if(this.showSection) {      
      this.searchForm = new UntypedFormGroup({
        searchTerm: new UntypedFormControl('')
      });
      this.ref.detectChanges();
      if(this.searchInputId) { this.searchInputId.nativeElement.focus(); }
    }
  }

  public filter(values) {
    this.selected = null;
    this.subscriptionService.search(this.searchForm.value.searchTerm)
      .then((subscriptions) => {
        this.filteredSubscriptions = subscriptions;
      })
      .catch((e) => {
        this.filteredSubscriptions = [];
      })
  }

  public cancelSearch() {
    this.searchForm = new UntypedFormGroup({
      searchTerm: new UntypedFormControl('')
    });
    this.filteredSubscriptions = [];
  }

  public getSubscriptions() {
    return this.filteredSubscriptions && this.filteredSubscriptions.length > 0 ? this.filteredSubscriptions.map((s) => s.latest ? s.latest : s) : this.subscriptions.map((s) => s.latest ? s.latest : s);
  }

  public getFilterValues() {
    return this.subscriptions.reduce((status_array, sub) => {
      let s = sub.latest;
      if(!status_array.find((array_stat) => array_stat.value === s.status)) {
        status_array.push({show: s.status, value: s.status})
      }

      return status_array;
    }, []).concat([
      {show: 'Controller Limit > 50', value: 'greater_than_50'},
      {show: 'Controller Limit > 30', value: 'greater_than_30'},
      {show: 'Controller Limit > 19', value: 'greater_than_19'},
      {show: 'Controller Limit > 10', value: 'greater_than_10'},
      {show: 'Controller Limit > 4', value: 'greater_than_4'},
      {show: 'Upcoming Expiry', value: 'expiration_upcoming_renewal'},
      {show: 'Past Due or Expired', value: 'expiration_past_due_or_expired'},
      {show: 'Never Expires', value: 'expiration_never'}
    ])
  }

  public setFilteredSubscriptionValue(event) {
    this.filteredSubscriptions = null;
    let filter_value = event.target.value;
    if(filter_value.startsWith('greater_than')) {
      let controller_limit = filter_value.split('_')[2]
      this.filteredSubscriptions = this.subscriptions.filter((s) => { return s.latest && s.latest.controllerLimit > controller_limit })
    } else if(filter_value.startsWith('expiration')) {
      let sub_list = filter_value.split('_')[1];
      if(sub_list === "upcoming") { this.filteredSubscriptions = this.subscription_summary.upcoming_renewal; }
      else if(sub_list === "past") { this.filteredSubscriptions = this.subscription_summary.recently_expired_or_past_due; }
      else { this.filteredSubscriptions = this.subscriptions.filter((s) => s.latest && s.latest.neverExpires); }
    } else {
      this.filter({searchTerm: filter_value});
      this.filterValue = filter_value;
    }
  }

  public getHumanFriendlyExpirationString(subscription) {
    return subscription.expirationDate ? moment.duration(moment().diff(moment(subscription.expirationDate))).humanize() : 'N/A'
  }

  selectSubscription(subscription) {
    if(this.selected === subscription) {
      this.selected = null;
    } else {
      this.selected = subscription;
      this.selected.excluded = this.excludedControllers();
      this.selected.included = this.includedControllers();
      console.log(this.selected)
    }
  }

  public excludedControllers() {
    return this.selected && this.selected.controllers && this.selected.controllers.filter((c) => !c.inSubscription).map((c) => c.name)
  }

  public includedControllers() {
    return this.selected && this.selected.controllers && this.selected.controllers.filter((c) => c.inSubscription).map((c) => c.name)
  }
}
