import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Platform } from '@ionic/angular';
import * as d3 from 'd3-dsv';
import moment from 'moment';
import * as momentTz from 'moment-timezone';

import { SitesService } from 'src/app/services/sites.service';
import { ControllerLogsChartGenerator } from './controller-logs-chart-generator';
import { TimeBossControllerLogsChartGenerator } from './time-boss-controller-logs-chart-generator';
import { WeatherBossLogsChartGenerator } from './weather-boss-chart-generator';
import { UserService } from '../services/user.service';
import { EnglishMetricFormat } from '../status-wind-rain/englishMetricConversion';

@Component({
  selector: 'app-controller-logs',
  templateUrl: './controller-logs.component.html',
  styleUrls: ['./controller-logs.component.scss'],
})
export class ControllerLogsComponent implements OnInit, OnChanges {
  @Input() nodeData: any;
  @Input() leftColumnVisible: boolean;
  @Input() fullScreen: boolean;
  showingEventLog: boolean = false;
  showingTimeLog: boolean = false;
  selectedLog: string = "time";
  timeLogs = [];
  eventLogs = [];
  logs = [];
  descLogs = [];
  width;
  height = 'auto';
  modelType;
  fetchingData;
  error;
  start_log_data;
  end_log_data;
  changing_log_dates = false;
  csvData;
  chart_instance;
  timer_options = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  shown_timers = {0: true, 1: true, 2: true, 3: true, 4: true, 5: true, 6: true, 7: true, 8: true, 9: true};
  shown_rwl_keys = {
    'v1p': {name: 'Vent A', shown: true, onOff: true},
    'v2p': {name: 'Vent B', shown: true, onOff: true},
    'v3p': {name: 'Vent C', shown: true, onOff: true},
    'v4p': {name: 'Vent D', shown: true, onOff: true},
    'h1': {name: 'Heat 1', shown: true, onOff: true},
    'h2': {name: 'Heat 2', shown: true, onOff: true},
    't1': {name: 'Timer 1', shown: true, onOff: true},
    't2': {name: 'Timer 2', shown: true, onOff: true},
    'hum': {name: 'Humidity', shown: true, onOff: true},
    'temps': {name: 'Temp', shown: true},
    'light': {name: 'Light', shown: true},
    'dayDifNite': {name: "Colors", shown: true}
    // 'water': {name: 'Water Vol.', shown: true}
  }

  shown_ghk_keys = {
    'v3p': {name: 'Vent C', shown: true, onOff: true},
    'v4p': {name: 'Vent D', shown: true, onOff: true},
    'h1': {name: 'Heat 1', shown: true, onOff: true},
    'c1': {name: 'Cool 1', shown: true, onOff: true},
    'c2': {name: 'Cool 2', shown: true, onOff: true},
    'h2': {name: 'Heat 2', shown: true, onOff: true},
    'c3': {name: 'Cool 3', shown: true, onOff: true},
    'c4': {name: 'Cool 4', shown: true, onOff: true},
    't1': {name: 'Timer 1', shown: true, onOff: true},
    't2': {name: 'Timer 2', shown: true, onOff: true},
    'hum': {name: 'Hum.', shown: true, onOff: true},
    'temps': {name: 'Temp', shown: true},
    'light': {name: 'Light', shown: true},
    'dayDifNite': {name: "Colors", shown: true}
    // 'water': {name: 'Water Vol.', shown: true}
  }

  public togglesShown = [];
  public viewTable = false;
  public chosenAction;

  @ViewChild('logsChart')
  logsChart: ElementRef;

  constructor(
    public sitesApiProvider: SitesService,
    public platform: Platform,
    private userService: UserService
  ) {
    this.chart_instance = null;
    let width = this.platform.width();
    this.width = width ? ((!this.leftColumnVisible) ? (width * .94) : (width*.77)) : 600;
  }

  ngOnInit() {
    let width = this.platform.width();
    this.width = width ? ((!this.leftColumnVisible) ? (width *.94) : (width*.77)) : 600;
    this.chart_instance = null;
    if (this.nodeData && this.nodeData.model){
      if (this.nodeData.model.includes('RWL')){
          this.modelType = 'R';
      } else if (this.nodeData.model.includes('GHK')){
        this.modelType = 'G';
      } else if (this.nodeData.model.includes('TB')){
        this.modelType = 'T';
      } else if (this.nodeData.isWindRain){
        this.modelType = 'W';
      }
    }

    this.showingTimeLog = true;
    this.showingEventLog = false;
    this.logs = this.timeLogs;
    this.descLogs = this.timeLogs.sort((a,b) => {
      var createdA = momentTz.utc(a.createdAt, 'MM/DD/YYYY HH:mm')
      var createdB = momentTz.utc(b.createdAt, 'MM/DD/YYYY HH:mm')
      return createdA.isSameOrBefore(createdB) ? 1 : -1;
    });

    this.setStartAndEndData()
    const range = (start, end) => {
      return (new Array(end - start + 1)).fill(undefined).map((_, i) => i + start);
    }

    const numberOfTimeLogs = range(1, 50)
    let startLogTime = moment().startOf('day').toDate();
    let heats = 10;
    let cools = 5;

    this.togglesShown = this.getEnvironmentalOptions(this.modelType);
    this.getLogData();    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes && changes.leftColumnVisible && changes.leftColumnVisible.currentValue !== changes.leftColumnVisible.previousValue) {
      let width = this.platform.width();
      this.width = width ? ((!this.leftColumnVisible) ? (width * 0.94) : (width*.77)) : 600;
      this.drawChart(this.modelType)
    }
  }

  dataAction() {
    console.log(`dataAction`)
    switch (this.chosenAction) {
      case 'refresh':
        this.getLogData()
        break;
      case 'dates':
        this.changeLogDates();
        break;
      case 'zoom':
        this.resetZoom();
        break;
      case 'table':
        this.viewTable = true;
        break;
      case 'graph':
        this.viewTable = false;
        break;
      default:
        break;
    }    
  }

  getLogData() {
    this.fetchingData = this.nodeData && this.nodeData.node_id;
    if(this.nodeData) {
      this.sitesApiProvider.getNodeLog(this.nodeData.node_id).then((data) => { 
        this.onGetNodeLogSuccess(data) 
      }).catch((error) => { this.onGetNodeLogError(error) })
    }
    // this.onGetNodeLogSuccess('');
  }

  onGetNodeLogSuccess(dataCsv) {
    this.fetchingData = false;
    // dataCsv = `createdAt,time,stage,alarm,ct1,ct2,ct3,hum,sp1,sp2,sp3,v1p,v2p,v3p,v4p,c1,c2,c3,c4,h1,h2,t1,t2\n03/15/2021 16:13,10:09,DAY,NONE,77,77,254,0,85,65,68,,,,99,1,1,0,1,1,0,0,0\n03/16/2021 16:15,10:11,DAY,NONE,79,86,254,0,55,75,68,,,,99,1,1,1,0,1,0,0,0\n03/17/2021 16:17,10:13,DAY,NONE,81,74,254,0,75,75,68,,,,99,1,0,1,1,0,0,0,0\n03/18/2021 16:19,10:15,DAY,NONE,76,76,254,0,65,73,68,,,,99,1,1,1,1,0,0,0,0\n03/19/2021 16:21,10:17,DAY,NONE,71,72,254,0,67,85,68,,,,99,1,1,0,1,0,0,0,0\n03/20/2021 16:23,10:19,DAY,NONE,79,76,254,0,75,65,68,,,,99,1,1,0,1,0,1,0,0\n03/21/2021 16:25,10:21,DAY,NONE,76,73,254,0,45,45,68,,,,99,1,0,1,1,0,1,0,0\n`;
    this.csvData = dataCsv
    var data = d3.csvParse(dataCsv);
    let temp_scale = this.userService.getTemperatureScale()
    if(temp_scale && temp_scale !== 'fahrenheit') {
      //convert temps to C
      let keysToConvertToCelcius = ["ct1", "ct2", "ct3", "sp1", 'sp2', 'sp3'];
      data.forEach((d) => {
        keysToConvertToCelcius.forEach((key) => {
          d[key] = EnglishMetricFormat.convertToCelsius(d[key]);
        })        
      })      
    }

    this.timeLogs = data;
    this.logs = data;
    this.descLogs = this.timeLogs.sort((a,b) => {
      var createdA = moment(a.createdAt, 'MM/DD/YYYY HH:mm')
      var createdB = moment(b.createdAt, 'MM/DD/YYYY HH:mm')
      return createdA.isSameOrBefore(createdB) ? 1 : -1;
    });

    this.setStartAndEndData()
    this.drawChart(this.modelType);
    this.showingTimeLog = true;
  }

  onGetNodeLogError(error){
    this.fetchingData = false;
    this.error = true;
    console.log("Error getting log data: " + JSON.stringify(error));
  }

  setStartAndEndData() {
    if(this.timeLogs && this.timeLogs.length > 0) {
      let earliest = momentTz.utc(this.timeLogs[0].createdAt, 'MM/DD/YYYY HH:mm').toDate();
      let latest = momentTz.utc(this.timeLogs[this.timeLogs.length - 1].createdAt, 'MM/DD/YYYY HH:mm').toDate();
      this.end_log_data = moment(earliest).format("YYYY-MM-DDTHH:mm")
      this.start_log_data = moment(latest).format("YYYY-MM-DDTHH:mm");
    }
  }

  resetZoom() {
    if(this.chart_instance) this.chart_instance.reset()
  }

  changeLogType(event) {
    if(event.target) {
      this.selectedLog = event.target.value
    }

    if(this.selectedLog == "time") {
      this.showingTimeLog = true;
      this.showingEventLog = false;
      this.logs = this.timeLogs;
      this.descLogs = this.timeLogs.sort((a,b) => {
        var createdA = moment(a.createdAt, 'MM/DD/YYYY HH:mm')
        var createdB = moment(b.createdAt, 'MM/DD/YYYY HH:mm')
        return createdA.isSameOrBefore(createdB) ? 1 : -1;
      });

      // console.log(`change log type`)
      this.setStartAndEndData()
    } else {
      this.showingTimeLog = false;
      this.showingEventLog = true;
      this.logs = this.eventLogs;
    }
  }

  changeLogDates() {
    var data = d3.csvParse(this.csvData);
    this.logs = data;
    var start = moment(this.start_log_data).utc()
    var end = moment(this.end_log_data).utc()
    this.fetchingData = true;

    this.sitesApiProvider.getNodeLogByDate(this.nodeData.node_id, start, end).then((data) => { this.onGetNodeLogSuccess(data) }).catch((error) => { this.onGetNodeLogError(error) })
  }

  canShowLogInfo() {
    return (this.showingEventLog && this.eventLogs.length > 0) || (this.showingTimeLog && this.timeLogs.length > 0);
  }

  drawChart(modelType) {
    let show_water_vol_info = false;
    let show_light_info = this.nodeData && this.nodeData.settings && this.nodeData.settings.lightSource !== 'NONE'
    let multiplier = this.viewTable ? 0.5 : 0.75    
    let height = this.width < 550 ? this.platform.height() * (multiplier - 0.1) : window.screen.height * multiplier;

    if(this.modelType === 'T') {
      this.chart_instance = TimeBossControllerLogsChartGenerator.chartData("#logsChart", this.timeLogs, this.width, height, modelType, this.timeLogs['columns'] || this.logs['columns'], Object.keys(this.shown_timers).filter((t) => this.shown_timers[t]), show_light_info);
    } else if(this.modelType == 'W')
      this.chart_instance = WeatherBossLogsChartGenerator.chartData("#logsChart", this.timeLogs, this.width, height, modelType, this.timeLogs['columns'] || this.logs['columns'], show_light_info);
    else {
      let options = modelType === "R" ? this.shown_rwl_keys : this.shown_ghk_keys
      if(!show_light_info) {
        options.light.shown = false;
      }
      let keys_to_show = Object.keys(options).filter((k) => options[k].shown)
      this.chart_instance = ControllerLogsChartGenerator.chartData("#logsChart", this.timeLogs, this.width, height, modelType, this.timeLogs['columns'] || this.logs['columns'], keys_to_show, this.platform.width());
    }
  }

  public downloadUrl() {
    if(this.nodeData) {
      return this.sitesApiProvider.getDownloadStatusUrl(this.nodeData.node_id, moment(this.start_log_data).utc());
    }
  }

  private getEnvironmentalOptions(modelType) {
    let options = modelType === "R" ? this.shown_rwl_keys : this.shown_ghk_keys
    return Object.keys(options).map((key) => {
      let opt = options[key]
      return {name: opt.name, key: key, shown: opt.shown}
    })
  }

  toggleOption(event, key) {
    var toggleTo = (event && event.detail) ? event.detail.checked : false;
    let options = this.modelType === "R" ? this.shown_rwl_keys : this.shown_ghk_keys
    options[key].shown = toggleTo;
    this.drawChart(this.modelType)
  }
}
