export const TimeFormat = {
  reformatString: (val, pattern) => {
    if(val.match(pattern)) return val;
    if(val.length >= 1) {
      val = val.replace(":", "");
      var sz = val.length;
      var last_chars = val.substring((sz - 2), sz).padStart(2, "0")
      var first_chars = `${val.substring((sz - 4), (sz - 2))}`
      first_chars = first_chars.substring((first_chars.length - 2), (first_chars.length))
      return `${first_chars.padStart(2, "0")}:${last_chars}`
    } else {
      return `00:${val}`
    }
  }
}
