import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class CisProvider {
  private baseUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    public userService: UserService
  ) {}

  public getCisList(): Promise<any>{
    var cisListUrl = this.baseUrl + "/api/cis";
    return new Promise((resolve, reject) => {
      this.http.get(cisListUrl)
                .toPromise()
                .then(response => {
                  resolve(response);
                }).catch(error => {
                  reject(error.error.message);
                });
    })
  }

  public changeOwner(data): Promise<any>{
    var cisUrl = this.baseUrl + "/api/cis/owner";
    return new Promise((resolve, reject) => {
      this.http.post(cisUrl, data)
                  .toPromise()
                  .then(response => {
                    resolve(response)
                  }).catch(error => {
                    reject(error);
                  });
    })
  }

  public newCis(data): Promise<any>{
    var cisUrl = this.baseUrl + "/api/cis/new";
    return new Promise((resolve, reject) => {
      this.http.post(cisUrl, data)
                  .toPromise()
                  .then(response => {
                    resolve(response)
                  }).catch(error => {
                    reject(error)
                  });
    })
  }
}
