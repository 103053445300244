import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IonContent, NavController, NavParams, PopoverController } from '@ionic/angular';
import { SitesService } from 'src/app/services/sites.service';
import { EnglishMetricFormat } from '../status-wind-rain/englishMetricConversion';
import { SettingInfoType } from '../status/status.page';

@Component({
  selector: 'app-edit-heat-cool-setting',
  templateUrl: './edit-heat-cool-setting.page.html',
  styleUrls: ['./edit-heat-cool-setting.page.scss'],
})
export class EditHeatCoolSettingPage implements OnInit {
  @ViewChild('editHeatCool', { read: IonContent }) content: IonContent;
  public settingInfo: SettingInfoType;
  public siteName: string;
  public houseName: string;
  public value;
  public option: string;
  public difTemp: number;
  public dayTemp: number;
  public niteTemp: number;
  public comment: string;
  public canEdit: false;
  public isCelsius: false;
  public inputInvalid: boolean = false;
  public invalidMessage: string = '';
  public validators = {
    temperatureValid: (temp) => { return !this.isCelsius ? (parseInt(temp) >= 32 && parseInt(temp) <= 131) : (parseInt(temp) >= 0 && parseInt(temp) <= 55); },
    percentValid: (temp) => { return parseInt(temp) >= 0 && parseInt(temp) <= 99; },
    stepSize: (size) => { return parseInt(size) >= 0 && parseInt(size) <= 31; },
    minutesSecondsValid: (time) => { return true; }
  }
  public heatCoolForm: UntypedFormGroup;
  public isPending;
  public tUnits;

  constructor(public navCtrl: NavController,
    public popoverController: PopoverController,
    public sitesApiProvider: SitesService,
    public formBuilder: UntypedFormBuilder,
    public navParams: NavParams
  )
  {
    this.settingInfo = navParams.get('settingInfo');
    console.log(this.settingInfo)
    this.siteName = navParams.get('siteName');
    this.houseName = navParams.get('houseName');
    this.canEdit = navParams.get('canEdit');
    this.isPending = navParams.get('isPending');
    this.isCelsius = navParams.get('isCelsius');
    this.tUnits = this.isCelsius ? '˚C' : '˚F';
    var str;
    if (this.settingInfo && (this.settingInfo.type == "heat" || this.settingInfo.type == "cool")) {
      str = this.settingInfo.value.split(',',11);
      this.option = str[0].trim();
      this.value = parseInt(str[1]);
      this.difTemp = parseInt(str[2]);
      this.dayTemp = parseInt(str[3]);
      this.niteTemp = parseInt(str[4]);
    } else {
      this.value = parseInt(this.settingInfo ? this.settingInfo.value : "0");
      this.option = "OFF"
    }

    // console.log("option: " + this.option + "value: " + this.value + ' NaN? ' + isNaN(this.value));
    if(isNaN(this.value)) {
      this.value = this.settingInfo.type === 'time' ? '00:00' : 0;
    }
    let onOffAuto = new UntypedFormControl(this.option, []);
    let value = new UntypedFormControl(this.value, []);
    let comment = new UntypedFormControl('', []);

    this.heatCoolForm = new UntypedFormGroup({
      onOffAuto: onOffAuto,
      value: value,
      comment: comment
    });

    window.addEventListener('keyboardWillShow', (event) => {
      this.content.scrollToBottom();
    });
  }

  public scroll() {
    this.content && this.content.scrollToBottom();
  }

  public change(field, val, validator = null, message = null) {
    var valueToSet = {}
    var form = this.heatCoolForm.value
    valueToSet[field] = parseInt(form[field]) + val;
    this.heatCoolForm.patchValue(valueToSet);
    if(validator) {
      this.validateValue({target: {value: valueToSet[field]}}, validator, message)
    }
  }

  public cancel(){
    var returnData = {
      button: "Cancel",
      value: null
    };
    this.popoverController.dismiss(returnData);
  }

  public save() {
    const form = this.heatCoolForm.value
    var returnData = {
      button: "Save",
      value: {comment: form.comment, value: null}
    };

    if(this.settingInfo && (this.settingInfo.type == "heat" || this.settingInfo.type == "cool" || this.settingInfo.type=='temp' || this.settingInfo.type=='step')) {            
      var val = (this.isCelsius && this.settingInfo.type !== 'temp' ? EnglishMetricFormat.offsetConvertToFarenheit(form.value) : form.value).toString()
      console.log(`${this.isCelsius && this.settingInfo.type !== 'temp'}, ${form.value}, ${val}`)
      val = (this.isCelsius && this.settingInfo.type === 'temp' ? EnglishMetricFormat.convertToFahrenheit(form.value) : val).toString()      
      console.log(`${this.isCelsius && this.settingInfo.type !== 'temp'}, ${form.value}, ${val}`)
      
      // console.log(`is celsius? ${this.isCelsius}, C val: ${form.value} => F ${val}`)
      if(form.onOffAuto && this.settingInfo && (this.settingInfo.type == "heat" || this.settingInfo.type == "cool")) {
        returnData.value.value = form.onOffAuto;
        returnData.value.value += "," + val;
      } else {
        returnData.value.value = val;
      }
    } else {
      returnData.value.value = form.value.toString();
    }

    this.popoverController.dismiss(returnData);
  }

  public validateValue(event, validator, message) {
    this.inputInvalid = !validator(event.target.value)
    if(this.inputInvalid) {
      this.invalidMessage = message
    }
  }

  public timeStrToMinutes(timeStr: string){
    var timeAry = {
      hr: "00", min: "00"
    };

    if(timeStr.includes(":")) {
      var splitTime = timeStr.split(":");
      timeAry.hr = splitTime[0];
      timeAry.min = splitTime[1];
    } else {
      timeAry.min = timeStr.substr(0, 2);
      if(timeStr.length > 2) {
        timeAry.hr = timeStr.substr(0, (timeStr.length - 2));
        timeAry.min = timeStr.substr((timeStr.length - 2), timeStr.length);
      }
    }

    return (parseInt(timeAry.hr) * 60) + parseInt(timeAry.min);
  }

  reformatTime(event, field, recursive?) {
    var timeString = recursive ? event : event.target.value;
    if(recursive || Number.isInteger(parseInt(event.key))) {
      timeString = timeString.replace(":", "");
      let updatedVal;
      if(timeString.length == 1) {
        updatedVal = `${timeString}`;
      } else if(timeString.length == 2) {
        updatedVal = `:${timeString}`
      } else if(timeString.length >= 3 && timeString.length <= 4) {
        var startInt = (timeString.length === 3) ? 1 : 2
        updatedVal = `${timeString.substring(0, startInt)}:${timeString.substring(startInt, timeString.length)}`
      } else {
        if((timeString.length % 2) !== 0) {
          timeString = timeString.padStart((timeString.length + 1), '0')
        }

        var time_array = timeString.match(/.{1,2}/g);
        var formatted_string = time_array.map((segment) => {
          return this.reformatTime(segment, field, true)
        }).join("")
        updatedVal = formatted_string.substring(1, formatted_string.length)
      }

      if(recursive) {
        return updatedVal;
      } else {
        var valFormInput = this.heatCoolForm.get(field);
        valFormInput.setValue(updatedVal);
      }
    }
  }

  ngOnInit() {
  }

  disableSetting() {
    var returnData = {
      button: "Save",
      value: {comment: `Disable ${this.settingInfo.desc}`, value: 0}
    };
    this.popoverController.dismiss(returnData);
  }

  markerConfig = {
    "0": { color: '#555', size: 3, label: '0', type: 'line'},
    "25": { color: '#555', size: 2, label: '25', type: 'line'},
    "50": { color: '#555', size: 3, label: '50', type: 'line'},
    "75": { color: '#555', size: 2, label: '75', type: 'line'},
    "100": { color: '#555', size: 3, label: '100', type: 'line'},
  }
}
