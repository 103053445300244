import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class UserService {
  private baseUrl = environment.apiUrl;
  public userLoginData: userInfoType;
  private isLoggedIn = false;
  public pushToken = "";
  public temperature_scale;
  private loggedInAs;

  constructor(
    private http: HttpClient,
    public platform: Platform,
    public storage: Storage
  ) {
  }

  public setTemperatureScale(newValue) {
    this.temperature_scale = newValue;
  }

  public login(email: string, password: string): Promise<any> {
    var loginUrl = this.baseUrl + "/api/login";
    var data: userLoginType = {
      "user": {
        "email": email && email.trim(),
        "password": password && password.trim()
      }
    }

    return new Promise((resolve, reject) => {
      this.http.post(loginUrl, data).toPromise().then((response: any) => {
        if (response.status == "ok") {
          this.userLoginData = response;
          this.isLoggedIn = true;

          //store the push token for this user if there is one
          if (this.pushToken) {
            this.storeUserPushToken(this.pushToken);
          } 
          resolve(this.userLoginData)
        } else {
          this.isLoggedIn = false;
          reject();
        }
      }).catch((error) => {
        console.log(`BinstLog: Error logging in ${email}: ${error}`)
        reject((error && error.error) ? (error.error.message ? error.error.message : "Sorry there was a login error") : error.message)
      })
    })
  }

  public logOut(): Promise<any> {
    // for logout with JWT auth just delete the token and change the logged in status
    this.userLoginData.authentication_token = "";
    this.isLoggedIn = false;

    var logoutUrl = this.baseUrl + "/api/users/sign_out";

    return new Promise((resolve, reject) => {
      this.http.delete(logoutUrl).toPromise().then((response)=> {
        this.isLoggedIn = false;
        resolve(response)
      }).catch((error)=> {
        console.log(`BinstLog: Error logging out ${this.userLoginData.email}: ${error}`)
        reject()
      })
    })
  }

  public acceptTerms(email: string, token: string): Promise<any> {
    var acceptUrl = this.baseUrl + "/users/terms/accept?user_email=" + email + "&user_token=" + token;
    return this.http.delete(acceptUrl).toPromise();
  }

  public LoggedIn(){
    return this.isLoggedIn;
  }

  public getLoginData(){
    return this.userLoginData;
  }

  public HasAcceptedTerms(){
    if (this.userLoginData.needs_to_accept_new_terms_and_conditions !== undefined){
      return !this.userLoginData.needs_to_accept_new_terms_and_conditions;
    } else {
      return false;
    }
  }

  public createForgotPassword(email): Promise<any> {
    var newUserUrl = this.baseUrl + `/api/users/password/forgot`;
    return new Promise((resolve, reject) => {
      this.http.post(newUserUrl, {email: email})
          .toPromise()
          .then(response => {
            resolve(email)
          })
          .catch(error => {
            reject();
          });
    })
  }

  public resetPassword(resetPasswordForm): Promise<any> {
    var resetPasswordUrl = this.baseUrl + `/api/users/password/reset`;
    const request = {
      user: resetPasswordForm
    }

    return new Promise((resolve, reject) => {
      this.http.post(resetPasswordUrl, request)
          .toPromise()
          .then(response => {
            resolve(resetPasswordForm)
          })
          .catch(error => {
            reject();
          });
    })
  }

  public getAllUsers(): Promise<any> {
    // var allUsersUrl = this.baseUrl + "/api/users/v2";
    var allUsersUrl = this.baseUrl + "/api/users";
    return new Promise((resolve, reject) => {
      this.http.get(allUsersUrl)
          .toPromise()
          .then(response => {
            resolve(response);
          }).catch(error => {
            reject(error && error.error && error.error.message);
          });
    });
  }

  public getLoggedInUser(getNotificationCount = false): Promise<any> {
    var allUsersUrl = this.isLoggedInAs() ? `${this.baseUrl}/api/users/me?loginAs=${this.getLoggedInEmail()}${getNotificationCount ? '&getNotificationCount=true' : ''}` : `${this.baseUrl}/api/users/me${getNotificationCount ? '?getNotificationCount=true' : ''}`;
    return new Promise((resolve, reject) => {
      this.http.get(allUsersUrl)
                .toPromise()
                .then(response => {
                  if(response['settings']) this.temperature_scale = response['settings']['temperature_scale'];
                  resolve(response)
                }).catch(error => {
                  reject(error.error ? error.error.message : 'Sorry something went wrong')
                });
      });
  }

  public createNewUser(newUser: userLoginType): Promise<any> {
    var newUserUrl = this.baseUrl + "/api/users";
    return this.http.post(newUserUrl, newUser).toPromise();
  }

  public confirmUser(confirmUser: { email: any; confirmationCode: any; }): Promise<any> {
    var newUserUrl = this.baseUrl + `/api/users/confirm`;
    return new Promise((resolve, reject) => {
      this.http.post(newUserUrl, confirmUser)
                .toPromise()
                .then(response => {
                  resolve(response);
                })
                .catch(error => {
                  reject(error.error)
                });
     })
  }

  public storeUserPushTokenAndSentTestMessage(pushToken) {
    var pushUrl = this.baseUrl + "/api/users/prt?sendTest=true";
    var putData = {
      pushtoken: pushToken
    }

    return new Promise((resolve,reject) => {
      this.http.put(pushUrl, putData)
                .toPromise()
                .then(response => resolve("Token was saved and test message sent"))
                .catch(error => reject(error));
    })
  }

  //store a push registration token for a user (PUT /api/users/prt)
  public storeUserPushToken(pushToken): Promise<any> {
    var pushUrl = this.baseUrl + "/api/users/prt";
    var putData = {
      pushtoken: pushToken
    }

    return new Promise((resolve, reject) => {
      this.http.put(pushUrl, putData)
          .toPromise()
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error.error);
          });
    });
  }

  public saveRingtonePreference(ringtone): Promise<any> {
    var pushUrl = this.baseUrl + "/api/users/ringtone";
    var putData = {
      ringtone: ringtone
    }

    return new Promise((resolve, reject) => {
      this.http.put(pushUrl, putData)
                .toPromise()
                .then(response => resolve("Your ringtone was updated"))
                .catch(error => reject("Sorry there was an error updating your ringtone."));
    })
  }

  public verifyNumber(type, code): Promise<any> {
    var pushUrl = this.baseUrl + "/api/users/preferences/notifications";
    let verification_object = {}
    verification_object[type] = {code: code}
    return new Promise((resolve, reject) => {
      this.http.put(pushUrl, verification_object)
                    .toPromise()
                    .then(response => resolve("Your number was successfully verified."))
                    .catch(error => reject("Sorry there was an error verifying that number."));
    })
  }

  public resendVerification(type): Promise<any> {
    var pushUrl = this.baseUrl + "/api/users/preferences/resend";
    return new Promise((resolve, reject) => {
      this.http.put(pushUrl, {type: type})
                    .toPromise()
                    .then(response => resolve("Verification code was resent."))
                    .catch(error => reject("Something went wrong trying to resend your verification code."));
    })
  }

  public removeNumber(type): Promise<any> {
    var pushUrl = this.baseUrl + "/api/users/numbers";
    return new Promise((resolve, reject) => {
      this.http.post(pushUrl, {type: type})
                    .toPromise()
                    .then(response => resolve("Your number was successfully removed."))
                    .catch(error => reject("Sorry there was an error removing that number."));
    })
  }

  public deleteAccount(email): Promise<any> {
    var deleteAccountUrl = `${this.baseUrl}/api/users?email=${email}`;
    return new Promise((resolve, reject) => {
      this.http.delete(deleteAccountUrl)
              .toPromise()
              .then(response => resolve("Your account was successfully removed."))
              .catch(error => reject("Sorry there was an error removing your account."));
    })
  }

  public saveNotificationPreferences(values): Promise<any> {
    var pushUrl = this.isLoggedInAs() ? `${this.baseUrl}/api/users/preferences/notifications?loginAs=${this.getLoggedInEmail()}` : `${this.baseUrl}/api/users/preferences/notifications`;
    var putData = {
      ringtone: values.ringtone,
      alert_type: values.alert_type
    };

    putData['call'] = values.call;
    putData['text'] = values.text;

    return new Promise((resolve, reject) => {
        this.http.put(pushUrl, putData)
                  .toPromise()
                  .then(response => resolve("Your notification settings have been updated"))
                  .catch(error => reject("Sorry there was an error updating your notifications settings."));
    })
  }

  public getTemperatureScale() {
    return this.temperature_scale || ((this.userLoginData && this.userLoginData.settings) ? this.userLoginData.settings.temperature_scale : 'fahrenheit');
  }

  public setLoginAs(email, site_id) {
    this.loggedInAs = {
      email: email,
      site: site_id
    };
    this.getLoggedInUser();
  }

  public unsetLoginAs() {
    this.loggedInAs = undefined;
  }

  public isLoggedInAs() {
    return this.loggedInAs !== undefined;
  }

  public getLoggedInEmail() {
    return this.loggedInAs && this.loggedInAs.email;
  }

  public getLoggedInSite() {
    return this.loggedInAs && this.loggedInAs.site;
  }

  public getAuthToken() {
    return this.getLoginData() ? this.getLoginData().authentication_token : null;
  }

  // public isBetaTester() {
  //   let email = this.userLoginData ? this.userLoginData.email : "Email N/A";
  //   return email.includes("@bartinst.com") || email === 'steve43276@gmail.com' || email === 'matt@mattsgreenhouse.com' || email === 'ted@mattsgreenhouse.com';
  // }

  public updateEmail(email): Promise<any> {
    let updateUserUri = this.isLoggedInAs() ? `${this.baseUrl}/api/users?loginAs=${this.getLoggedInEmail()}` : `${this.baseUrl}/api/users`
    return this.http.put(updateUserUri, {new_email: email})
                .toPromise()
  }

  public saveSettingsPreference(settings): Promise<any> {
    var pushUrl = this.baseUrl + "/api/users/settings";

    var putData = {
      temperature_scale: settings.temperature_scale,
      dark_mode: settings.dark_mode,
      dashboard_default: settings.dashboard_default,
      default_view: settings.default_view
    }

    return new Promise((resolve, reject) => {
      this.http.put(pushUrl, putData)
                .toPromise()
                .then(response => {
                  if(this.userLoginData.settings) {
                    this.userLoginData.settings.temperature_scale = settings.temperature_scale;
                    this.userLoginData.settings.dark_mode = settings.dark_mode;
                  } else {
                    this.userLoginData.settings = {
                      temperature_scale: 'fahrenheit',
                      dark_mode: false,
                      dashboard_default: false,
                      default_view: 'list'
                    }
                    this.temperature_scale = settings.temperature_scale;
                  }
                  resolve("Your settings were updated.")
                })
                .catch(error => {
                  reject("Sorry there was an error updating your settings.");
                });
    })
  }

  public getPaymentInformation(): Promise<any> {
    let paymentUri = this.isLoggedInAs() ? `${this.baseUrl}/api/payment/info?loginAs=${this.getLoggedInEmail()}` : `${this.baseUrl}/api/payment/info`
    return this.http.get(paymentUri)
                .toPromise()
  }

  public updatePaymentInformation(nonce): Promise<any> {
    return this.http.put(`${this.baseUrl}/api/payment/info`, {nonce: nonce})
                .toPromise()
  }

  public search(term): Promise<any> {
    return this.http.post(`${this.baseUrl}/api/users/search`, {search: term}).toPromise()
  }
}

//define the type for user login info object
export interface userInfoType {
  status: string,
  email: string,
  has_valid_subscription: boolean,
  withinTrialPeriod: boolean,
  not_all_nodes_shown: boolean,
  is_owner: boolean,
  authentication_token: string,
  needs_to_accept_new_terms_and_conditions: boolean,
  new_terms_and_conditions: string,
  is_bart_admin: boolean,
  ringtone: {
    Name: string,
    Url: string,
    default: boolean,
    filename: string
  },
  cisUpgradeAvailable?: boolean,
  settings: {
    temperature_scale: string,
    dark_mode: boolean,
    dashboard_default: boolean,
    default_view: string
  }
};

//define the user structure for login and new user
export interface userLoginType {
  user: {
    email: string,
    password: string
  }
}
