<ion-header>
  <ion-toolbar class="settings-header">
    <div>
      <span class="settings-name">{{settingInfo && settingInfo.desc}}</span>
      <span class="settings-site-house">{{siteName}} - {{houseName}}</span>
    </div>
  </ion-toolbar>
</ion-header>
<div class="content">
  <!--Radio buttons for option types (heat and cool mode, prop outs)-->
  <div *ngIf="inputInvalid"
    class="invalid-input">Invalid Input. Acceptable values are: {{invalidMessage}}</div>

  <div class="pending" *ngIf="isPending">
    Currently syncing with the controller
  </div>

  <form [formGroup]="ventForm">
    <div>
      <div class="onAutoOff" *ngIf="!isClimateBoss && !manualOverride">
        <ion-label>Auto</ion-label>
        <input type="radio" name="option" formControlName="option" value="AUTO"/>
      </div>
      <div class="onAutoOff" *ngIf="!isClimateBoss && !manualOverride">
        <ion-label>Manual</ion-label>
        <input type="radio" name="option" formControlName="option" value="MAN" />
      </div>
      <div class="onAutoOff" *ngIf="!manualOverride">
        <ion-label>Off</ion-label>
        <input type="radio" name="option" formControlName="option" value="OFF"/>
      </div>
      <div class="onAutoOff" *ngIf="isClimateBoss && !manualOverride">
        <ion-label>5 Step</ion-label>
        <input type="radio" name="option" formControlName="option" value="5-STEP"/>
      </div>
      <div class="onAutoOff" *ngIf="isClimateBoss && !manualOverride">
        <ion-label>PID (Vent Saver)</ion-label>
        <input type="radio" name="option" formControlName="option" value="PID"/>
      </div>
      <div class="onAutoOff" *ngIf="isClimateBoss">
        <ion-label>Manual</ion-label>
        <input type="radio" name="option" formControlName="option" value="MAN"/>
      </div>
      <div *ngIf="manualOverride">
        <p>WeatherBoss Extended Override</p>
      </div>
    </div>

    <!-- Standard value setting -->
    <ion-list *ngIf="settingInfo && (settingInfo.type=='temp'||settingInfo.type=='percent') && !manualOverride">
      <div class="setting-item">
        <ion-item lines="none">
          <ion-label class="setting-header">Setting</ion-label>
          <ion-label class="ion-float-right setting-value">
            {{value}}<span *ngIf="settingInfo.type=='temp'">{{tUnits}}</span><span *ngIf="settingInfo.type=='percent'">%</span>
          </ion-label>
        </ion-item>        
        <div class="setting-ion-item" *ngIf="settingInfo && (settingInfo.type=='temp' || ventForm.value.option === 'MAN')">
          <!-- <ion-range class="setting-range" min="32" max="131" [(ngModel)]="value" color="secondary-blue">
            <ion-label range-left>32</ion-label>
            <ion-label range-right>131</ion-label>
          </ion-range> -->
          <ion-icon (click)="change('value', -1)" name="remove-circle-outline" color="secondary-blue"></ion-icon>
          <input
            min="32" max="131"
            formControlName="value"
            type="text" inputmode="tel"
            pattern="[0-9]*"
            select-all
            [disabled]='!canEdit'
            (change)='validateValue($event, validators.temperatureValid, "Min: 32, Max 131")'
          />
          <ion-icon (click)="change('value', 1)" name="add-circle-outline" color="secondary-blue"></ion-icon>
        </div>
        <div class="setting-ion-item" *ngIf="settingInfo && settingInfo.type=='percent'">
          <ion-icon (click)="change('value', -1)" name="remove-circle-outline" color="secondary-blue"></ion-icon>
          <input
            min="0" max="99"
            formControlName="value"
            type="text" inputmode="tel"
            pattern="[0-9]*"
            select-all
            [disabled]='!canEdit'
            (change)='validateValue($event, validators.percentValid, "Min: 0, Max 99")'
          />
          <ion-icon (click)="change('value', 1)" name="add-circle-outline" color="secondary-blue"></ion-icon>
        </div>
      </div>
    </ion-list>

    <div *ngIf="ventForm && manualOverride" class="setting-item">
      <ion-item lines="full">
        <ion-label class="setting-header">Override Duration</ion-label>          
      </ion-item>
      <div class="setting-ion-item">
        <input
          formControlName="overrideDuration"
          type="text" inputmode="tel"
          pattern="[0-9]{1,2}:[0-9]{2}"
          select-all
          [disabled]='!canEdit'
        />
      </div>
    </div>

    <!-- <div *ngIf="ventForm && (ventForm.value.option=='MAN' || ventForm.value.option=='ON') && manualOverride" class="setting-item">
      <ion-item lines="full">
        <ion-label class="setting-header">Return to previous Percent 1 value? (Current value is: {{vent && vent.p1}})</ion-label>          
      </ion-item>
      <div class="setting-ion-item">
        <input
          formControlName="resetAfterOverride"
          type="checkbox"
          [disabled]='!canEdit'
        />
      </div>
    </div> -->

    <!-- vent activation temperature table -->
    <table *ngIf="settingInfo && settingInfo.type=='vent' && showPercents(ventForm.value.option)" class="setting-item">
      <tr>
        <th></th>
        <th class="tb-col-hdr">DIF</th>
        <th class="tb-col-hdr">Day</th>
        <th class="tb-col-hdr">Night</th>
      </tr>
      <tr>
        <th class="tb-row-hdr">Program Set Point</th>
        <td>{{difTemp}}</td>
        <td>{{dayTemp}}</td>
        <td>{{niteTemp}}</td>
      </tr>
      <tr>
        <th class="tb-row-hdr">{{!isPid() ? 'Step 1 Active Above' : 'Initial Opening'}}</th>
        <td class="tb-row-set">{{difTemp+((this.ventForm ? this.ventForm.value.step : vent.step) * 1)}}</td>
        <td class="tb-row-set">{{dayTemp+((this.ventForm ? this.ventForm.value.step : vent.step)*1)}}</td>
        <td class="tb-row-set">{{niteTemp+((this.ventForm ? this.ventForm.value.step : vent.step)*1)}}</td>
      </tr>
      <tr *ngIf="showPercents(ventForm.value.option, 2)">
        <th class="tb-row-hdr">Step 2 Active Above</th>
        <td class="tb-row-set">{{difTemp+((this.ventForm ? this.ventForm.value.step : vent.step)*2)}}</td>
        <td class="tb-row-set">{{dayTemp+((this.ventForm ? this.ventForm.value.step : vent.step)*2)}}</td>
        <td class="tb-row-set">{{niteTemp+((this.ventForm ? this.ventForm.value.step : vent.step)*2)}}</td>
      </tr>
      <tr *ngIf="showPercents(ventForm.value.option, 3)">
        <th class="tb-row-hdr">Step 3 Active Above</th>
        <td class="tb-row-set">{{difTemp+((this.ventForm ? this.ventForm.value.step : vent.step)*3)}}</td>
        <td class="tb-row-set">{{dayTemp+((this.ventForm ? this.ventForm.value.step : vent.step)*3)}}</td>
        <td class="tb-row-set">{{niteTemp+((this.ventForm ? this.ventForm.value.step : vent.step)*3)}}</td>
      </tr>
      <tr *ngIf="showPercents(ventForm.value.option, 4)">
        <th class="tb-row-hdr">Step 4 Active Above</th>
        <td class="tb-row-set">{{difTemp+((this.ventForm ? this.ventForm.value.step : vent.step)*4)}}</td>
        <td class="tb-row-set">{{dayTemp+((this.ventForm ? this.ventForm.value.step : vent.step)*4)}}</td>
        <td class="tb-row-set">{{niteTemp+((this.ventForm ? this.ventForm.value.step : vent.step)*4)}}</td>
      </tr>
      <tr *ngIf="!isPid()">
        <th class="tb-row-hdr">{{!isPid() ? 'Step 5 Active Above' : 'Maximum Opening'}}</th>
        <td class="tb-row-set">{{difTemp+((this.ventForm ? this.ventForm.value.step : vent.step)*5)}}</td>
        <td class="tb-row-set">{{dayTemp+((this.ventForm ? this.ventForm.value.step : vent.step)*5)}}</td>
        <td class="tb-row-set">{{niteTemp+((this.ventForm ? this.ventForm.value.step : vent.step)*5)}}</td>
      </tr>
    </table>

    <!-- Vent settings -->
    <ion-list *ngIf="settingInfo && settingInfo.type=='vent'">
      <!-- manual vent override -->
      <div *ngIf="ventForm.value.option=='MAN' || ventForm.value.option=='ON'" class="setting-item">
        <ion-item lines="full">
          <ion-label class="setting-header">Manual Override</ion-label>
          <ion-label class="ion-float-right setting-value">{{vent.man}}%</ion-label>
        </ion-item>
        <div class="setting-ion-item">
          <ion-icon (click)="change('manual', -1)" name="remove-circle-outline" color="secondary-blue"></ion-icon>
          <input
            min="0" max="99"
            formControlName="manual"
            type="text" inputmode="tel"
            pattern="[0-9]*"
            select-all
            [disabled]='!canEdit'
            (change)='validateValue($event, validators.percentValid, "Min: 0, Max 99")'
          />
          <ion-icon (click)="change('manual', 1)" name="add-circle-outline" color="secondary-blue"></ion-icon>
        </div>
      </div>      

      <!-- step size -->
      <div *ngIf="showPercents(ventForm.value.option)" class="setting-item" (click)="selected=0">
        <ion-item class="setting-ion-item" lines="none">
          <ion-label class="setting-header">Step Size</ion-label>
          <ion-label class="ion-float-right setting-value">{{vent.step}}{{tUnits}}</ion-label>
        </ion-item>
        <div class="setting-ion-item" *ngIf="selected==0">
          <!-- <ion-range class="setting-range" min="0" max="31" [(ngModel)]="vent.step" color="secondary-blue">
            <ion-label range-left>0</ion-label>
            <ion-label range-right>31</ion-label>
          </ion-range> -->
          <ion-icon (click)="change('step', -1)" name="remove-circle-outline" color="secondary-blue"></ion-icon>
          <input
            min="0" max="31"
            formControlName="step"
            type="text" inputmode="tel"
            pattern="[0-9]*"
            select-all
            [disabled]='!canEdit'
            (change)='validateValue($event, validators.stepSize, "Min: 0, Max 31")'
          />
          <ion-icon (click)="change('step', 1)" name="add-circle-outline" color="secondary-blue"></ion-icon>
        </div>
      </div>

      <!-- percent 1 -->
      <div *ngIf="showPercents(ventForm.value.option)" class="setting-item" (click)="selected=1">
          <ion-item class="setting-ion-item" lines="none">
            <ion-label class="setting-header">{{!isPid() ? 'Step 1 Open Percent' : 'Initial Opening'}}</ion-label>
            <ion-label class="ion-float-right setting-value">{{vent.p1}}%</ion-label>
          </ion-item>
          <div class="setting-ion-item" *ngIf="selected==1">
            <!-- <ion-range class="setting-range" min="0" max="99" [(ngModel)]="vent.p1" color="secondary-blue">
              <ion-label range-left>0</ion-label>
              <ion-label range-right>99</ion-label>
            </ion-range> -->
            <ion-icon (click)="change('p1', -1)" name="remove-circle-outline" color="secondary-blue"></ion-icon>
            <input
              min="0" max="99"
              formControlName="p1"
              type="text" inputmode="tel"
              pattern="[0-9]*"
              select-all
              [disabled]='!canEdit'
              (change)='validateValue($event, validators.percentValid, "Min: 0, Max 99")'
            />
            <ion-icon (click)="change('p1', 1)" name="add-circle-outline" color="secondary-blue"></ion-icon>
          </div>
        <div class="setting-bar" [style.width.%]="vent.p1"></div>
      </div>

      <!-- percent 2 -->
      <div *ngIf="showPercents(ventForm.value.option, 2)" class="setting-item" (click)="selected=2">
        <ion-item class="setting-ion-item" lines="none">
          <ion-label class="setting-header">Step 2 Open Percent</ion-label>
          <ion-label class="ion-float-right setting-value">{{vent.p2}}%</ion-label>
        </ion-item>
        <div class="setting-ion-item" *ngIf="selected==2">
          <!-- <ion-range class="setting-range" min="0" max="99" [(ngModel)]="vent.p2" color="secondary-blue">
            <ion-label range-left>0</ion-label>
            <ion-label range-right>99</ion-label>
          </ion-range> -->
          <ion-icon (click)="change('p2', -1)" name="remove-circle-outline" color="secondary-blue"></ion-icon>
          <input
            min="0" max="99"
            formControlName="p2"
            type="text" inputmode="tel"
            pattern="[0-9]*"
            select-all
            [disabled]='!canEdit'
            (change)='validateValue($event, validators.percentValid, "Min: 0, Max 99")'
          />
          <ion-icon (click)="change('p2', 1)" name="add-circle-outline" color="secondary-blue"></ion-icon>
        </div>
        <div class="setting-bar" [style.width.%]="vent.p2"></div>
      </div>

      <!-- percent 3 -->
      <div *ngIf="showPercents(ventForm.value.option, 3)" class="setting-item" (click)="selected=3">
        <ion-item class="setting-ion-item" lines="none">
          <ion-label class="setting-header">Step 3 Open Percent</ion-label>
          <ion-label class="ion-float-right setting-value">{{vent.p3}}%</ion-label>
        </ion-item>
        <div class="setting-ion-item" *ngIf="selected==3">
          <!-- <ion-range class="setting-range" min="0" max="99" [(ngModel)]="vent.p3" color="secondary-blue">
            <ion-label range-left>0</ion-label>
            <ion-label range-right>99</ion-label>
          </ion-range> -->
          <ion-icon (click)="change('p3', -1)" name="remove-circle-outline" color="secondary-blue"></ion-icon>
          <input
            min="0" max="99"
            formControlName="p3"
            type="text" inputmode="tel"
            pattern="[0-9]*"
            select-all
            [disabled]='!canEdit'
            (change)='validateValue($event, validators.percentValid, "Min: 0, Max 99")'
            (focus)="scroll()"
          />
          <ion-icon (click)="change('p3', 1)" name="add-circle-outline" color="secondary-blue"></ion-icon>
        </div>
        <div class="setting-bar" [style.width.%]="vent.p3"></div>
      </div>

      <!-- percent 4 -->
      <div *ngIf="showPercents(ventForm.value.option, 4)" class="setting-item" (click)="selected=4">
        <ion-item class="setting-ion-item" lines="none">
          <ion-label class="setting-header">Step 4 Open Percent</ion-label>
          <ion-label class="ion-float-right setting-value">{{vent.p4}}%</ion-label>
        </ion-item>
        <div class="setting-ion-item" *ngIf="selected==4">
          <!-- <ion-range class="setting-range" min="0" max="99" [(ngModel)]="vent.p4" color="secondary-blue">
            <ion-label range-left>0</ion-label>
            <ion-label range-right>99</ion-label>
          </ion-range> -->
          <ion-icon (click)="change('p4', -1)" name="remove-circle-outline" color="secondary-blue"></ion-icon>
          <input
            min="0" max="99"
            formControlName="p4"
            type="text" inputmode="tel"
            pattern="[0-9]*"
            select-all
            [disabled]='!canEdit'
            (change)='validateValue($event, validators.percentValid, "Min: 0, Max 99")'
            (focus)="scroll()"
          />
          <ion-icon (click)="change('p4', 1)" name="add-circle-outline" color="secondary-blue"></ion-icon>
        </div>
        <div class="setting-bar" [style.width.%]="vent.p4"></div>
      </div>

      <!-- percent 5 -->
      <div *ngIf="showPercents(ventForm.value.option)" class="setting-item" (click)="selected=5">
        <ion-item class="setting-ion-item" lines="none">
          <ion-label class="setting-header">{{!isPid() ? 'Step 5 Open Percent' : 'Maximum Opening'}}</ion-label>
          <ion-label class="ion-float-right setting-value">{{vent.p5}}%</ion-label>
        </ion-item>
        <div class="setting-ion-item" *ngIf="selected==5">
          <!-- <ion-range class="setting-range" min="0" max="99" [(ngModel)]="vent.p5" color="secondary-blue">
            <ion-label range-left>0</ion-label>
            <ion-label range-right>99</ion-label>
          </ion-range> -->
          <ion-icon (click)="change('p5', -1)" name="remove-circle-outline" color="secondary-blue"></ion-icon>
          <input
            min="0" max="99"
            formControlName="p5"
            type="text" inputmode="tel"
            pattern="[0-9]*"
            select-all
            [disabled]='!canEdit'
            (change)='validateValue($event, validators.percentValid, "Min: 0, Max 99")'
            (focus)="scroll()"
          />
          <ion-icon (click)="change('p5', 1)" name="add-circle-outline" color="secondary-blue"></ion-icon>
        </div>
        <div class="setting-bar" [style.width.%]="vent.p5"></div>
      </div>

      <div *ngIf="ventForm.value.option !== 'OFF' && isClimateBoss" class="setting-item">
        <ion-item class="setting-ion-item" lines="none">
          <ion-label class="setting-header">Threshold (minutes)</ion-label>
          <ion-label class="ion-float-right setting-value">{{vent.threshold}}</ion-label>
        </ion-item>
        <div class="setting-ion-item">
          <input
            min="0" max="9"
            formControlName="threshold"
            type="text" inputmode="tel"
            pattern="[0-9]*"
            select-all
            [disabled]='!canEdit'
            (change)='validateValue($event, validators.minutesValid, "Min: 0, Max 9")'
            (focus)="scroll()"
          />
        </div>
      </div>

      <div *ngIf="ventForm.value.option !== 'OFF' && isClimateBoss" class="setting-item">
        <ion-item class="setting-ion-item" lines="none">
          <ion-label class="setting-header">Open Time (seconds)</ion-label>
          <ion-label class="ion-float-right setting-value">{{vent.openTime}}</ion-label>
        </ion-item>
        <div class="setting-ion-item">
          <input
            min="0" max="9999"
            formControlName="openTime"
            type="text" inputmode="tel"
            pattern="[0-9]*"
            select-all
            [disabled]='!canEdit'
            (change)='validateValue($event, validators.secondsValid, "Min: 0, Max 9999")'
            (focus)="scroll()"
          />
        </div>
      </div>

      <div *ngIf="ventForm.value.option !== 'OFF' && isClimateBoss" class="setting-item">
        <ion-item class="setting-ion-item" lines="none">
          <ion-label class="setting-header">Rain Override</ion-label>
          <ion-label class="ion-float-right setting-value">{{vent.rainOR === 0 ? 'OFF' : 'ON'}}</ion-label>
        </ion-item>
        <div class="setting-ion-item">
          <ion-toggle formControlName="rainOR" [disabled]='!canEdit'></ion-toggle>
        </div>
      </div>

      <div *ngIf="ventForm.value.option !== 'OFF' && isClimateBoss" class="setting-item">
        <ion-item class="setting-ion-item"  lines="none">
          <ion-label class="setting-header">Wind 1 Override</ion-label>
          <ion-label class="ion-float-right setting-value">{{vent.wind1OR === 0 ? 'OFF' : 'ON'}}</ion-label>
        </ion-item>
        <div class="setting-ion-item">
          <ion-toggle formControlName="wind1OR" [disabled]='!canEdit'></ion-toggle>
        </div>
      </div>

      <div *ngIf="ventForm.value.option !== 'OFF' && isClimateBoss" class="setting-item">
        <ion-item class="setting-ion-item" lines="none">
          <ion-label class="setting-header">Wind 2 Override</ion-label>
          <ion-label class="ion-float-right setting-value">{{vent.wind2OR === 0 ? 'OFF' : 'ON'}}</ion-label>
        </ion-item>
        <div class="setting-ion-item">
          <ion-toggle formControlName="wind2OR" [disabled]='!canEdit'></ion-toggle>
        </div>
      </div>

    </ion-list>

    <div class="setting-ion-item comment-box" *ngIf="canEdit">
        <label>Comment<sup>*</sup></label>
        <input
          formControlName="comment"
          [disabled]='!canEdit'
          (focus)="scroll()"
        />
    </div>
    <p *ngIf="canEdit"><sup>*</sup>Add a message about this change</p>

    <div class="buttons saveOrCancel">
      <ion-button color="secondary" class="settings-buttons" (click)="cancel()">
        Cancel
      </ion-button>
      <ion-button color="primary" [disabled]="!canEdit || inputInvalid" class="settings-buttons" (click)="save()">
        Save
      </ion-button>
    </div>

    <p class="note" *ngIf="note">{{note}}</p>
    <div *ngIf="!canEdit" class="save-disabled"><i>Save disabled; You don't have edit permissions.</i></div>
    <div *ngIf="inputInvalid" class="save-disabled"><i>Save disabled; Please fix errors before saving.</i></div>
  </form>
</div>
