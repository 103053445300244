import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IonContent, NavController, NavParams, PopoverController } from '@ionic/angular';
import { SitesService } from 'src/app/services/sites.service';
import { TimeHelper } from '../components/time_helper';
import { EnglishMetricFormat } from '../status-wind-rain/englishMetricConversion';
import { SettingInfoType } from '../status/status.page';

@Component({
  selector: 'app-edit-setting',
  templateUrl: './edit-setting.page.html',
  styleUrls: ['./edit-setting.page.scss'],
})
export class EditSettingPage implements OnInit {
  @ViewChild('editSetting', { read: IonContent }) content: IonContent;
  @ViewChild('dif', { read: ElementRef }) dif : ElementRef;
  @ViewChild('day', { read: ElementRef }) day : ElementRef;
  @ViewChild('nite', { read: ElementRef }) nite : ElementRef;
  @ViewChild('graph', { read: ElementRef }) graph: ElementRef;
  public settingInfo: SettingInfoType;
  public siteName: string;
  public houseName: string;
  public value: number;
  public option: string;
  public difTemp: number;
  public dayTemp: number;
  public niteTemp: number;
  public ovrTemp: number;
  public difStart: string; //start time converted to minutes
  public dayStart: string; //start time converted to minutes
  public niteStart: string; //start time converted to minutes
  public difStartMin: number;
  public dayStartMin: number;
  public niteStartMin: number;
  public selected = 0;
  public comment: string;
  public canEdit: false;
  public isCelsius: false;
  public inputInvalid: boolean = false;
  public invalidMessages = [];
  public difEdit = false;
  public dayEdit = false;
  public niteEdit = false;
  public current;
  private minutesOfDay = function(timeString){
    var splitTime = timeString.split(":")
    return parseInt(splitTime[1]) + (parseInt(splitTime[0]) * 60);
  }
  public validators = {
    temperatureValid: (temp) => {
      if(this.isCelsius) { return parseInt(temp) >= 0 && parseInt(temp) <= 55;  }
      else {
        return parseInt(temp) >= 32 && parseInt(temp) <= 131;
      }
    },
    dayStartValidation: (dayStart) => {
      var day = this.minutesOfDay(dayStart)
      return (day > this.minutesOfDay(this.programForm.get('difStart').value)) && (day <= this.minutesOfDay(this.programForm.get('niteStart').value))
    },
    difStartValidation: (difStart) => {
      var dif = this.minutesOfDay(difStart)
      return dif > 0 && dif < this.minutesOfDay(this.programForm.get('dayStart').value);
    },
    niteStartValidation: (niteStart) => {
      var nite = this.minutesOfDay(niteStart)
      return nite > this.minutesOfDay(this.programForm.get('dayStart').value) && nite <= 1440;
    }
  }
  public programForm: UntypedFormGroup;
  public edited = {
    difStart: false,
    difTemp: false,
    dayStart: false,
    dayTemp: false,
    niteStart: false,
    niteTemp: false
  }
  public messages = {
    dif: "Start time for DIF is invalid. Must be between 0:00 and DAY start time. ",
    day: "Start time for DIF is invalid. Must be between DIF and NITE start times. ",
    nite: "Start time for DIF is invalid. Must be between DAY start time and 24:00. "
  }
  public tUnits;
  public isPending = false;
  public help;

  constructor(public navCtrl: NavController,
    private navParams: NavParams,
    public popoverController: PopoverController,
    public sitesApiProvider: SitesService)
  {
    this.settingInfo = navParams.get('settingInfo');
    this.siteName = navParams.get('siteName');
    this.houseName = navParams.get('houseName');
    this.canEdit = navParams.get('canEdit');
    this.isCelsius = navParams.get('isCelsius');
    this.isPending = navParams.get('isPending');
    this.help = navParams.get('help');
    this.tUnits = this.isCelsius ? '˚C' : '˚F';
    var str;
    if (this.settingInfo && this.settingInfo.type == "prog"){
      str = this.settingInfo.value.split(',',7);
      this.difStartMin = parseInt(str[0])
      this.difStart = this.timeToStr(this.difStartMin);
      this.difTemp = parseInt(str[1]);
      this.dayStartMin = parseInt(str[2]);
      this.dayStart = this.timeToStr(this.dayStartMin);
      this.dayTemp = parseInt(str[3]);
      this.niteStartMin = parseInt(str[4]);
      this.niteStart = this.timeToStr(this.niteStartMin);
      this.niteTemp = parseInt(str[5]);
      this.ovrTemp = parseInt(str[6]);

      this.current = {
        difStartMin: this.difStartMin,
        difStart: this.difStart,
        difTemp: this.difTemp,
        dayStart: this.dayStart,
        dayStartMin: this.dayStartMin,
        dayTemp: this.dayTemp,
        niteStart: this.niteStart,
        niteStartMin: this.niteStartMin,
        niteTemp: this.niteTemp
      }
    } else {
      this.value = this.settingInfo ? parseInt(this.settingInfo.value) : 1;
      this.option = ""
    }

    let difStart = new UntypedFormControl(this.difStart, []);
    let difTemp = new UntypedFormControl(this.difTemp, []);
    let dayStart = new UntypedFormControl(this.dayStart, []);
    let dayTemp = new UntypedFormControl(this.dayTemp, []);
    let niteStart = new UntypedFormControl(this.niteStart, []);
    let niteTemp = new UntypedFormControl(this.niteTemp, []);
    let ovrTemp = new UntypedFormControl(this.ovrTemp, []);
    let comment = new UntypedFormControl('', []);

    this.programForm = new UntypedFormGroup({
      difStart: difStart,
      difTemp:difTemp,
      dayStart:dayStart,
      dayTemp:dayTemp,
      niteStart:niteStart,
      niteTemp:niteTemp,
      ovrTemp:ovrTemp,
      comment: comment
    });
  }

  public scroll(stage?) {
    if(stage) {
      var graphBottom = this[stage].nativeElement.getBoundingClientRect().bottom;
      var height = this[stage].nativeElement.getBoundingClientRect().top;
    }

    if(height) {
      this.content && this.content.scrollToPoint(0, height - 65);
    } else {
      this.content && this.content.scrollToBottom();
    }
  }

  public saveSetting(stage, tempOnly?) {
    let stageStartKey;
    let stageStart;
    let stageIsInvalid = false;

    if(!tempOnly) {
      stageStartKey = `${stage}Start`;
      stageStart = this.programForm.get(stageStartKey);
      stageIsInvalid = this.validateValue(stageStart.value, this.validators[`${stage}StartValidation`], this.messages[stage])
      if(stageIsInvalid) {
        stageStart.setErrors({overlaps: this.messages[stage]})
      } else {
        this[`${stage}Start`] = stageStart.value;
        this[`${stage}StartMin`] = this.timeStrToMinutes(stageStart.value);
        this.edited[stageStartKey] = stageStart.value && this.timeStrToMinutes(stageStart.value) !== this.current[`${stage}StartMin`]
      }
    }

    let stageTempKey = `${stage}Temp`;
    let stageTemp = this.programForm.get(stageTempKey)
    let tempIsInvalid = this.validateValue(stageTemp.value, this.validators.temperatureValid, `The set point for ${stage} is invalid`)
    this.inputInvalid = stageIsInvalid || tempIsInvalid;
    if(tempIsInvalid) {
      stageTemp.setErrors({invalidTemp: "The set point is invalid"});
    } else {
      this.inputInvalid = false;
      this.invalidMessages = [];
      this[`${stage}Temp`] = stageTemp.value;
      this.edited[stageTempKey] = stageTemp.value && stageTemp.value !== this.current[`${stage}Temp`]
    }
  }

  public editSetting(stage) {
    this.programForm.get(`${stage}Start`).enable();
    this.programForm.get(`${stage}Temp`).enable();
    this[`${stage}Edit`] = true;
  }

  public cancelEdit(stage) {
    let stageStart = this.programForm.get(`${stage}Start`);
    let stageTemp = this.programForm.get(`${stage}Temp`);
    stageStart.disable({ emitEvent: false });
    stageTemp.disable({ emitEvent: false });

    stageStart.setValue(this.current[`${stage}Start`]);
    stageTemp.setValue(this.current[`${stage}Temp`]);
    this[`${stage}Edit`] = false;
    this[`${stage}Start`] = this.current[`${stage}Start`];
    this[`${stage}Temp`] = this.current[`${stage}Temp`];
    this[`${stage}StartMin`] = this.timeStrToMinutes(this[`${stage}Start`]);
    this.inputInvalid = false;
    this.invalidMessages = [];
  }

  public cancel(){
    var returnData = {
      button: "Cancel",
      value: null
    };
    this.popoverController.dismiss(returnData);
  }

  public change(field, val, stage) {
    var valueToSet = {}
    var form = this.programForm.value
    valueToSet[field] = parseInt(form[field]) + val;
    this.programForm.patchValue(valueToSet);
    this.saveSetting(stage, true)
  }

  public save(){
    const form = this.programForm.getRawValue()
    var returnData = {
      button: "Save",
      value: {comment: form.comment, value: null}
    };

    //convert back to F for saving, if necessary
    var difTemp = (this.isCelsius ? EnglishMetricFormat.convertToFahrenheit(form.difTemp) : form.difTemp).toString();
    var dayTemp = (this.isCelsius ? EnglishMetricFormat.convertToFahrenheit(form.dayTemp) : form.dayTemp).toString();
    var niteTemp = (this.isCelsius ? EnglishMetricFormat.convertToFahrenheit(form.niteTemp) : form.niteTemp).toString();

    returnData.value.value = form.difStart;
    returnData.value.value += "," + difTemp;
    returnData.value.value += "," + form.dayStart;
    returnData.value.value += "," + dayTemp;
    returnData.value.value += "," + form.niteStart;
    returnData.value.value += "," + niteTemp;
    this.popoverController.dismiss(returnData);
  }

  public pressHelp() {
    this.help && this.help();
  }

  public timeToStr(minutes: number) {
    var hourStr = Math.floor(minutes/60).toString();
    hourStr = hourStr.padStart(2, "0");

    var minStr = "0" + (minutes%60).toString();
    minStr = minStr.substr(minStr.length-2);
    return hourStr + ":" + minStr;
  }

  public timeStrToMinutes(timeStr: string){
    var timeAry = {
      hr: "00", min: "00"
    };

    if(timeStr.includes(":")) {
      var splitTime = timeStr.split(":");
      timeAry.hr = splitTime[0];
      timeAry.min = splitTime[1];
    } else {
      timeAry.min = timeStr.substr(0, 2);
      if(timeStr.length > 2) {
        timeAry.hr = timeStr.substr(0, (timeStr.length - 2));
        timeAry.min = timeStr.substr((timeStr.length - 2), timeStr.length);
      }
    }

    return (parseInt(timeAry.hr) * 60) + parseInt(timeAry.min);
  }

  public validateValue(value, validator, message) {
    let fieldIsInvalid = !validator(value);
    if(fieldIsInvalid && !this.invalidMessages.includes(message)) {
      this.invalidMessages.push(message);
    }
    return fieldIsInvalid;
  }

  public reformatTime(event, field, recursive?) {
    TimeHelper.reformat(event, field, this.programForm, recursive)
  }

  ngOnInit() {
  }
}
