import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { NavController, NavParams, ToastController, PopoverController } from '@ionic/angular';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from '../../environments/environment';

import dropin from 'braintree-web-drop-in';
import moment from 'moment';

@Component({
  selector: 'app-credit-card-information',
  templateUrl: './credit-card-information.component.html',
  styleUrls: ['./credit-card-information.component.scss'],
})
export class CreditCardInformationComponent implements OnInit {
  public paymentToken: string;
  public dropInFormSuccess = false;
  private merchantId;
  public paymentInfo;
  public updating = false;
  public viewing;
  public hasExpired = false
  public updatingPayment = false;

  @Input() user: {
    is_owner: false,
    type: "user",
    braintree_customer_id: string,
    subscription:
      { planId: string, planName: string, nodeLimit: number,
        cancelled: boolean, status: string, expirationDate: string, nextBillingDate: string,
        firstBillingDate: string, numberOfBillingCycles: number, nextBillingPeriodAmount: string,
        neverExpires: false, allowTextsAndPhoneCallNotifications: boolean, notification_balance: number
    },
    endOfTrialPeriod: '',
    notificationCount : 0,
    payment: {
      expiration: '',
      masked: ''
    }
  };

  constructor(
    private elementRef: ElementRef,
    public subscriptionService: SubscriptionService,
    public userService: UserService,
    private toastCtrl: ToastController
  ) {
    this.merchantId = environment.merchantId && `https://www.braintreegateway.com/merchants/${environment.merchantId}/verified`;
  }

  ngOnInit() {
    if(this.user && this.user.subscription) {
      this.userService.getPaymentInformation().then((data) => { 
        let information = (Object.keys(data).length === 0) ? this.user : data;
        this.updatePaymentInfo(information) 
      })
      this.dropInFormSuccess = false;
    }
  }

  updatePaymentInfo(data) {
    if(data && data.payment) {
      this.paymentInfo = data.payment
      if(moment(this.paymentInfo.expiration, "MM/YYYY").isSameOrBefore(moment())) {
        this.hasExpired = true;
      }
    }
  }

  private setPaymentToken(response) {
    this.dropInFormSuccess = true;
    this.paymentToken = response.payment_token;
  }

  public updatePayment() {
    this.dropInFormSuccess = false;
    this.updating = !this.updating;
    this.subscriptionService.getClientToken()
        .then((data) => {
          let formNeedsRendered = this.paymentToken ? false : true;
          this.setPaymentToken(data);
          if(this.updating) {
            this.renderContainer();
          } else {
            this.dropInFormSuccess = true;
          }
        })
  }

  private renderContainer() {
    var dropinContainer = this.elementRef.nativeElement.querySelector('#dropin-container');
    var submitButton = this.elementRef.nativeElement.querySelector('#submit-button');
    const component = this;

    dropin.create({
      authorization: this.paymentToken,
      container: dropinContainer,
      vaultManager: true
    }).then(function (dropinInstance) {
        component.dropInFormSuccess = true;
        submitButton.addEventListener('click', () => {
          dropinInstance.requestPaymentMethod().then((payload) => {
            component.updatingPayment = true;
            component.userService.updatePaymentInformation(payload.nonce)
                     .then((data) => {
                       component.updatingPayment = false;
                       component.updating = false;
                       component.showMessage('Your payment method was updated successfully.');
                       component.userService.getPaymentInformation().then((data) => { 
                        let information = (Object.keys(data).length === 0) ? this.user : data;
                        this.updatePaymentInfo(information) 
                      })
                     })
                     .catch((data) => {
                       component.updatingPayment = false;
                       component.updating = true;
                       component.showMessage('Your payment method could not be updated, please try again or contact us for support.');
                     })
          }).catch((err) => {
            component.showError("There was an error with your form of payment. Please double check your method of payment.");
          });
        });
      }).catch(function (err) {
        component.dropInFormSuccess = false;
        component.showError("There was an error rendering the payment form. Please contact Bartlett Instrument to set up your subscription.");
      });
  }

  async showError(error) {
    let toast = await this.toastCtrl.create({
      message: error.message ? error.message : error,
      duration: 3000,
      position: 'top',
      cssClass: 'error'
    });

    toast.present();
  }

  async showMessage(message) {
    let toast = await this.toastCtrl.create({
      message: message,
      duration: 3000,
      position: 'top'
    });

    toast.present();
  }
}
