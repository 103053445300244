<div [formGroup]="dehumidification" class="dehum-guide-form">
  <ion-item *ngIf="current_page">
    <ion-label class="ion-text-wrap">Do you have a humidity sensor?</ion-label>
    <ion-radio-group formControlName="humid_sensor" name="humid_sensor">
      <ion-item><ion-radio mode="ios"value=true></ion-radio><ion-label>Yes</ion-label></ion-item>
      <ion-item lines="none"><ion-radio mode="ios"value=false></ion-radio><ion-label>No</ion-label></ion-item>
    </ion-radio-group>
  </ion-item>

  <ion-item *ngIf="current_page && dehumidification && dehumidification.value.humid_sensor === 'true'">
    <ion-label class="ion-text-wrap">What is your target humidity percentage?</ion-label>
    <ion-input type="number" name="humidity_set_point" [disabled]="!dehumidification.value.humid_sensor" formControlName="humidity_set_point"></ion-input>
  </ion-item>

  <ion-item *ngIf="current_page && dehumidification && dehumidification.value.humid_sensor === 'false'">
    <ion-label class="ion-text-wrap">What time (hh:mm) would you like to start the cycle?</ion-label>
    <ion-input type="time" formControlName="start" [disabled]="!dehumidification.value.humid_sensor"></ion-input>
  </ion-item>

  <ion-item *ngIf="current_page && dehumidification && dehumidification.value.humid_sensor === 'false'">
    <ion-label class="ion-text-wrap">Would you like to repeat the cycle on a daily basis?</ion-label>
    <ion-radio-group formControlName="repeat">
      <ion-item><ion-radio mode="ios"value='true'></ion-radio><ion-label>Yes</ion-label></ion-item>
      <ion-item lines="none"><ion-radio mode="ios"value='false'></ion-radio><ion-label>No</ion-label></ion-item>
    </ion-radio-group>
  </ion-item>

  <ion-item *ngIf="current_page && dehumidification" formArrayName="stages" class="stages">
    <ion-label class="ion-text-wrap">What stage(s) would you like this dehumidification cycle to run during?</ion-label>
    <ion-list class="output-options">
      <ion-item lines="none"><ion-checkbox [checked]="dehumidification.value.stages.includes('dif')" (click)="addStage('dif')" value="dif"></ion-checkbox><span>DIF</span></ion-item>
      <ion-item lines="none"><ion-checkbox [checked]="dehumidification.value.stages.includes('day')" (click)="addStage('day')" value="day"></ion-checkbox><span>DAY</span></ion-item>
      <ion-item lines="none"><ion-checkbox [checked]="dehumidification.value.stages.includes('nite')" (click)="addStage('nite')" value="nite"></ion-checkbox><span>NITE</span></ion-item>
    </ion-list>
  </ion-item>  

  <ion-item *ngIf="current_page" lines="none">
    <ion-label class="ion-text-wrap">Between what temperature limits would you like the dehumidification cycle to run between - first, what is the high limit?</ion-label>
    <ion-radio-group formControlName="high_limit" name="high_limit" >
      <ion-item *ngIf="model.includes('GHK')"><ion-radio mode="ios"value='0'></ion-radio><ion-label>Use Cool 2 ({{c2_temp}})</ion-label></ion-item>
      <ion-item *ngIf="model && !model.includes('GHK')"><ion-radio mode="ios"value='0'></ion-radio><ion-label>Use Percent 1</ion-label></ion-item>
      <ion-item lines="none"><ion-radio mode="ios"value="high"></ion-radio><ion-label>Set Temperature</ion-label></ion-item>
    </ion-radio-group>    
  </ion-item>
  <ion-item *ngIf="current_page">
    <ion-label>&nbsp;</ion-label>
    <ion-input slot="end" *ngIf="dehumidification && dehumidification.value.high_limit ==='high'" formControlName="high_limit_val" type="number"></ion-input>
  </ion-item>

  <ion-item *ngIf="current_page" lines="none">
    <ion-label class="ion-text-wrap">Between what temperature limits would you like the dehumidification cycle to run between - what is the low limit?</ion-label>
    <ion-radio-group formControlName="low_limit">
      <ion-item><ion-radio mode="ios"name="low_limit" value='0'></ion-radio><ion-label>Use Heat 1 ({{h1_temp}})</ion-label></ion-item>
      <ion-item><ion-radio mode="ios"name="low_limit" value="low"></ion-radio><ion-label>Set Temperature</ion-label></ion-item>
    </ion-radio-group>    
  </ion-item>
  <ion-item *ngIf="current_page">
    <ion-label>&nbsp;</ion-label>
    <ion-input *ngIf="dehumidification && dehumidification.value.low_limit === 'low'" formControlName="low_limit_val" type="number" ></ion-input>
  </ion-item>

  <ion-item *ngIf="current_page && dehumidification" formArrayName="outputs" class="outputs">
    <ion-label class="ion-text-wrap">What equipment would you like to run during the dehumidification cycle?</ion-label>
    <ion-list class="output-options">
      <ion-item *ngIf="model.includes('GHK')" lines="none"><ion-checkbox labelPlacement="end" value="c1" [checked]="dehumidification.value.outputs.includes('c1')" (click)="addOutput('cool1')"></ion-checkbox>Cool 1</ion-item>
      <ion-item *ngIf="model.includes('GHK')" lines="none"><ion-checkbox labelPlacement="end" [checked]="dehumidification.value.outputs.includes('c2')" (click)="addOutput('cool2')" value="c2"></ion-checkbox>Cool 2</ion-item>
      <ion-item *ngIf="model.includes('GHK')" lines="none"><ion-checkbox labelPlacement="end" [checked]="dehumidification.value.outputs.includes('c3')" (click)="addOutput('cool3')" value="c3"></ion-checkbox>Cool 3</ion-item>
      <ion-item *ngIf="model.includes('GHK')" lines="none"><ion-checkbox labelPlacement="end" [checked]="dehumidification.value.outputs.includes('c4')" (click)="addOutput('cool4')" value="c4"></ion-checkbox>Cool 4</ion-item>
      <ion-item *ngIf="model.includes('GHK')" lines="none"><ion-checkbox labelPlacement="end" [checked]="dehumidification.value.outputs.includes('p1')" (click)="addOutput('percent1')" value="p1"></ion-checkbox>Percent 1</ion-item>
      
      <ion-item *ngIf="!model.includes('GHK')" lines="none"><ion-checkbox labelPlacement="end" [checked]="dehumidification.value.outputs.includes('haf')" (click)="addOutput('haf')" value="haf"></ion-checkbox>HAF</ion-item>
      <ion-item *ngIf="!model.includes('GHK')" lines="none"><ion-checkbox labelPlacement="end" [checked]="dehumidification.value.outputs.includes('v1')" (click)="addOutput('v1')" value="v1"></ion-checkbox>Vent A</ion-item>
      <ion-item *ngIf="!model.includes('GHK')" lines="none"><ion-checkbox labelPlacement="end" [checked]="dehumidification.value.outputs.includes('v2')" (click)="addOutput('v2')" value="v2"></ion-checkbox>Vent B</ion-item>
      <ion-item *ngIf="!model.includes('GHK')" lines="none"><ion-checkbox labelPlacement="end" [checked]="dehumidification.value.outputs.includes('v3')" (click)="addOutput('v3')" value="v3"></ion-checkbox>Vent C</ion-item>
      <ion-item *ngIf="!model.includes('GHK')" lines="none"><ion-checkbox labelPlacement="end" [checked]="dehumidification.value.outputs.includes('v4')" (click)="addOutput('v4')" value="v4"></ion-checkbox>Vent D</ion-item>
      
      <ion-item lines="none"><ion-checkbox [checked]="dehumidification.value.outputs.includes('h1')" labelPlacement="end" (click)="addOutput('heat1')" value="h1"></ion-checkbox>Heat 1</ion-item>
      <ion-item lines="none"><ion-checkbox [checked]="dehumidification.value.outputs.includes('h2')" labelPlacement="end" (click)="addOutput('heat2')" value="h2"></ion-checkbox>Heat 2</ion-item>
      <ion-item lines="none"><ion-checkbox [checked]="dehumidification.value.outputs.includes('enableHeat')" labelPlacement="end" (click)="addOutput('enableHeat')" value="enableHeat"></ion-checkbox>Heat Enable</ion-item>
    </ion-list>
  </ion-item>

  <ion-item *ngIf="current_page">
    <ion-label class="ion-text-wrap">You can have 3 stages for the cycle - exhaust, preheat, exhaust. Please enter the length of time for each in the boxes to the right.</ion-label>
    <ion-list>
      <ion-item lines="none">
        First Exhaust: <ion-input type="text" fill="solid" formControlName="exhaust_1"></ion-input>
      </ion-item>
      <ion-item lines="none">
        Preheat: <ion-input type="text" fill="solid" formControlName="preheat"></ion-input>
      </ion-item>
      <ion-item lines="none">
        Second Exhaust: <ion-input type="text" fill="solid" formControlName="exhaust_2"></ion-input>
      </ion-item>
    </ion-list>
  </ion-item>

  <!-- <div *ngIf="current_page && current_page.steps.includes('manual_start'">
    <ion-label>Would you like to start the dehumidification cycle now?</ion-label>
    <div>
      <ion-label><input type="radio" name="manual_start" formControlName="manual_start" value="true" />Yes</ion-label>
      <ion-label><input type="radio" name="manual_start" formControlName="manual_start" value="false" />No</ion-label>
    </div>
  </div> -->

  <div *ngIf="!valid()">
    This field is invalid. Please correct before you can go to the next step.
  </div>
</div>
