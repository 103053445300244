<ion-list [formGroup]="timerGraphForm">
  <ion-item>
    <ion-label>
      <h3>Start Day/Time</h3>
    </ion-label>
    <ion-input formControlName="start_date" class="ion-text-right" type="date" helperText="Enter a valid start date" errorText="Invalid start date"></ion-input>
  </ion-item>

  <ion-item>
    <ion-label>
      <h3>End Day/Time</h3>
      <p *ngIf="timerGraphForm && timerGraphForm.touched && timerGraphForm.errors && timerGraphForm.errors.dates !== null" color="medium">Invalid end date.</p>
    </ion-label>    
    <ion-input formControlName="end_date" class="ion-text-right" type="date" helperText="Enter a valid end date" errorText="Invalid end date"></ion-input>
  </ion-item>

  <ion-item>
    <ion-label>Houses</ion-label>
    <ion-select formArrayName="houses" [multiple]="true" (ionChange)="triggerEvent($event)">
      <ion-select-option *ngFor="let node of timerGraphForm.value.houses" [value]="node" [selected]="node.selected">{{node.name}}</ion-select-option>
    </ion-select>
  </ion-item>
  
  <ion-item>
    <ion-label>See Last Week's Timer Usage</ion-label>
    <ion-button slot="end" [disabled]="timerGraphForm && timerGraphForm.touched && !timerGraphForm.valid" (click)="changeDates()">Submit</ion-button>
  </ion-item>
</ion-list>

<ion-spinner *ngIf="retrievingAverageInformation"></ion-spinner>
<section *ngIf="run_times && run_times.length !== 0">
  <ion-item>
    <ion-label>Run Times</ion-label>
    <ion-label>
      <ion-button (click)="seeTable()">Table View</ion-button>
      <ion-button (click)="seeChart()">Chart View</ion-button>
    </ion-label>
    <ion-label *ngIf="view === 'table'">
      <a [href]="getDownloadLinkInfo()" download="run_time_data.csv">Download</a>
    </ion-label>
  </ion-item>
  <div *ngIf="view === 'table'">
    <ion-grid *ngFor="let node_id of run_times">
      <ion-row class="header">                    
        <ion-col>{{getName(node_id)}} - {{node_id}}</ion-col>
        <ion-col>Timer 1</ion-col>
        <ion-col>Timer 2</ion-col>
      </ion-row>
      
      <ion-row *ngFor="let day of run_times_by_node[node_id]">
        <ion-col>{{(day.archive_date || day.date) | date:'L'}}</ion-col>
        <ion-col>
          <h6>Total: {{day.run_times.t1_readable || 'N/A' }}</h6>
          <hr/>
          <ion-row *ngFor="let onOff of day.onOffTimes.t1">{{onOff}}</ion-row>
        </ion-col>        
        <ion-col>
          <h6>{{day.run_times.t2_readable || 'N/A' }}</h6>
          <hr/>
          <ion-row *ngFor="let onOff of day.onOffTimes.t2">{{onOff}}</ion-row>
        </ion-col>                
      </ion-row>
      <ion-row>
        <ion-col>Total</ion-col>
        <ion-col>{{totals && totals[node_id] && getTotal(totals[node_id].t1)}} minutes</ion-col>
        <ion-col>{{totals && totals[node_id] && getTotal(totals[node_id].t2)}} minutes</ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <div [class.display-none]="view !== 'chart'">
    <div id="timer-graph-chart"></div>
  </div>
</section>