import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  {
    path: 'accept-terms',
    loadChildren: () => import('./accept-terms/accept-terms.module').then( m => m.AcceptTermsPageModule)
  },
  {
    path: 'add-site',
    canActivate: [AuthGuard],
    loadChildren: () => import('./add-site/add-site.module').then( m => m.AddSitePageModule)
  },
  {
    path: 'add-user-to-site',
    canActivate: [AuthGuard],
    loadChildren: () => import('./add-user-to-site/add-user-to-site.module').then( m => m.AddUserToSitePageModule)
  },
  {
    path: 'audit-trail',
    canActivate: [AuthGuard],
    loadChildren: () => import('./audit-trail/audit-trail.module').then( m => m.AuditTrailPageModule)
  },
  {
    path: 'bartinst-admin',
    canActivate: [AuthGuard],
    loadChildren: () => import('./bartinst-admin/bartinst-admin.module').then( m => m.BartinstAdminPageModule)
  },
  {
    path: 'connect-controller-to-site',
    canActivate: [AuthGuard],
    loadChildren: () => import('./connect-controller-to-site/connect-controller-to-site.module').then( m => m.ConnectControllerToSitePageModule)
  },
  {
    path: 'edit-site',
    canActivate: [AuthGuard],
    loadChildren: () => import('./edit-site/edit-site.module').then( m => m.EditSitePageModule)
  },
  {
    path: 'site/:site_id/edit',
    canActivate: [AuthGuard],
    loadChildren: () => import('./edit-site/edit-site.module').then( m => m.EditSitePageModule)
  },
  {
    path: 'view-site',
    canActivate: [AuthGuard],
    loadChildren: () => import('./view-site/view-site.module').then( m => m.ViewSitePageModule)
  },
  {
    path: 'dehumid-settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./dehumid-settings/dehumid-settings.module').then( m => m.DehumidSettingsPageModule)
  },
  {
    path: 'edit-heat-cool-setting',
    canActivate: [AuthGuard],
    loadChildren: () => import('./edit-heat-cool-setting/edit-heat-cool-setting.module').then( m => m.EditHeatCoolSettingPageModule)
  },
  {
    path: 'edit-setting',
    canActivate: [AuthGuard],
    loadChildren: () => import('./edit-setting/edit-setting.module').then( m => m.EditSettingPageModule)
  },
  {
    path: 'edit-vent-setting',
    canActivate: [AuthGuard],
    loadChildren: () => import('./edit-vent-setting/edit-vent-setting.module').then( m => m.EditVentSettingPageModule)
  },
  {
    path: 'wind-rain-edit',
    canActivate: [AuthGuard],
    loadChildren: () => import('./wind-rain-edit/wind-rain-edit.module').then( m => m.WindRainEditPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'new-user',
    loadChildren: () => import('./new-user/new-user.module').then( m => m.NewUserPageModule)
  },
  {
    path: 'confirm-user',
    loadChildren: () => import('./confirm-user/confirm-user.module').then( m => m.ConfirmUserPageModule)
  },
  {
    path: 'node-list',
    canActivate: [AuthGuard],
    loadChildren: () => import('./node-list/node-list.module').then( m => m.NodeListPageModule)
  },
  {
    path: 'controller-dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./node-list/node-list.module').then( m => m.NodeListPageModule)
  },
  {
    path: 'notification-device',
    canActivate: [AuthGuard],
    loadChildren: () => import('./notification-device/notification-device.module').then( m => m.NotificationDevicePageModule)
  },
  {
    path: 'notifications',
    canActivate: [AuthGuard],
    loadChildren: () => import('./notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'push-notifications',
    canActivate: [AuthGuard],
    loadChildren: () => import('./push-notifications/push-notifications.module').then( m => m.PushNotificationsPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'status',
    canActivate: [AuthGuard],
    loadChildren: () => import('./status/status.module').then( m => m.StatusPageModule)
  },
  {
    path: 'status/:node_id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./status/status.module').then( m => m.StatusPageModule)
  },
  {
    path: 'status-wind-rain',
    canActivate: [AuthGuard],
    loadChildren: () => import('./status-wind-rain/status-wind-rain.module').then( m => m.StatusWindRainPageModule)
  },
  {
    path: 'status-wind-rain/:node_id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./status-wind-rain/status-wind-rain.module').then( m => m.StatusWindRainPageModule)
  },
  {
    path: 'all-subscriptions',
    loadChildren: () => import('./all-subscriptions/all-subscriptions.module').then( m => m.AllSubscriptionsPageModule)
  },
  {
    path: 'edit-subscription',
    canActivate: [AuthGuard],
    loadChildren: () => import('./edit-subscription/edit-subscription.module').then( m => m.EditSubscriptionPageModule)
  },
  {
    path: 'manage-controllers',
    canActivate: [AuthGuard],
    loadChildren: () => import('./manage-controllers/manage-controllers.module').then( m => m.ManageControllersPageModule)
  },
  {
    path: 'subscription',
    loadChildren: () => import('./subscription/subscription.module').then( m => m.SubscriptionPageModule)
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () => import('./terms-and-conditions/terms-and-conditions.module').then( m => m.TermsAndConditionsPageModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./contact-us/contact-us.module').then( m => m.ContactUsPageModule)
  },
  {
    path: 'user-edit',
    canActivate: [AuthGuard],
    loadChildren: () => import('./user-edit/user-edit.module').then( m => m.UserEditPageModule)
  },
  {
    path: 'users',
    canActivate: [AuthGuard],
    loadChildren: () => import('./users/users.module').then( m => m.UsersPageModule)
  },
  {
    path: 'slides',
    loadChildren: () => import('./slides/slides.module').then( m => m.SlidesPageModule)
  },
  {
    path: 'time-boss-status-page',
    canActivate: [AuthGuard],
    loadChildren: () => import('./time-boss-status/time-boss-status-page.module').then( m => m.TimeBossStatusPagePageModule)
  },
  {
    path: 'time-boss-status-page/:node_id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./time-boss-status/time-boss-status-page.module').then( m => m.TimeBossStatusPagePageModule)
  },
  {
    path: 'controller-graph/:node_id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./controller-graph/controller-graph.module').then( m => m.ControllerGraphPageModule)
  },
  {
    path: 'site/:id/usage',
    canActivate: [AuthGuard],
    loadChildren: () => import('./site-usage-report/site-usage-report.module').then( m => m.SiteUsageReportPageModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then( m => m.HelpPageModule)
  },
  {
    path: 'controller/:node_id/general',
    canActivate: [AuthGuard],
    loadChildren: () => import('./controller-general-info/controller-general-info.module').then( m => m.ControllerGeneralInfoPageModule)
  },
  {
    path: 'site/:site_id/reports',
    canActivate: [AuthGuard],
    loadChildren: () => import('./site-reports/site-reports.module').then( m => m.SiteReportsPageModule)
  },
  {
    path: 'timers',
    // canActivate: [AuthGuard],
    loadChildren: () => import('./site/timers/timers.module').then( m => m.TimersPageModule)
  },
  {
    path: 'programming-tools/:node_id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./programming-tools/programming-tools.module').then( m => m.ProgrammingToolsPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
