<ul id="reports-list">
  <p *ngIf="error" class="error">{{error}}</p>
  <li>
    <label>Timezone</label>
    <select [(ngModel)]='selectedTimezone' (change)="setTz($event)" [class.error]="error">
      <option *ngFor="let zone of timezones" [value]="zone" [selected]="zone === selectedTimezone">{{zone}}</option>
    </select>
  </li>
  <li>
    <label class="bold">Site Usage Report</label>
    <button class='button view-site-report' (click)="viewSiteReport()">View Site Usage Report</button>
  </li>

  <li *ngIf="isSiteAdmin">
    <div [class.border-bottom]="highLowView">
      <label class="bold">High / Low Report</label>
      <button (click)="highLowView = !highLowView">{{highLowView ? 'Hide Configuration' : 'Configure'}}</button>
    </div>
    <div *ngIf="highLowView && site && site.users && site.users.length > 0 && site.controllers && site.controllers.length > 0"
        id="high-low-report-config">
      <label>Configure High/Low Report</label>
      <form [formGroup]="highLowReportForm">
        <div>
          <label>
            <span>Run At:</span>
            <p>The report will be run once a day for the past 24 hours. Select your preferred time to have the report run.</p>
          </label>
          <input formControlName="runAt" type="time" value="8:00"/>
        </div>
        <div class="border-top">
          <label>Send To:</label>
          <ul formArrayName="sendTo">
            <li *ngFor="let u of site.users; let i = index">
              <input type="checkbox" [id]="i" [formControlName]="i"/>
              <label [for]="i"> {{ u.email }} </label>
            </li>
          </ul>
        </div>
        <div>
          <label>&nbsp;</label>
          <button (click)="updateReportSettings(highLowReportForm.value)">Update Report Configuration</button>
        </div>
      </form>
    </div>
    <div *ngIf="highLowView && site && ((!site.users || (site.users && site.users.length === 0)) || (!site.controllers || (site.controllers && site.controllers.length === 0)))">
      <div *ngIf="(!site.users || (site.users && site.users.length === 0))">You do not have any users configured for this site.</div>
      <div *ngIf="!site.controllers || (site.controllers && site.controllers.length === 0)">You do not have any controllers configured for this site.</div>
    </div>
  </li>

  <li *ngIf="isSiteAdmin" class="auto-downloads">
    <div [class.border-bottom]="autoDownloadView">
      <label class="bold">Automatic Logs Download</label>
      <button (click)="autoDownloadView = !autoDownloadView">{{autoDownloadView ? 'Hide Configuration' : 'Configure'}}</button>
    </div>
    <div *ngIf="autoDownloadView && site && site.users && site.users.length > 0 && site.controllers && site.controllers.length > 0"
        id="automatic-download-config">
      <label>Configure Automatic Logs Download</label>
      <form [formGroup]="automaticLogDownloadForm">
        <div>
          <label>
            <span>Run At:</span>
            <p>The report will be run once a day for the past 24 hours. Select your preferred time to have the report run.</p>
          </label>
          <input formControlName="runAt" type="time" value="8:00"/>
        </div>
        <div class="border-top">
          <label>Select Controllers:</label>
          <ul formArrayName="controllers" *ngIf="site.controllers">
            <li *ngFor="let ctrl of site.controllers; let i = index">
              <input *ngIf="ctrl.api_name || ctrl.node_name" type="checkbox" [id]="i" [formControlName]="i"/>
              <label *ngIf="ctrl.api_name || ctrl.node_name"> {{ ctrl.api_name || ctrl.node_name }} </label>
            </li>
          </ul>
        </div>
        <div class="border-top">
          <label>Send To:</label>
          <ul formArrayName="sendTo" *ngIf="site.users">
            <li *ngFor="let u of site.users; let i = index">
              <input *ngIf="u.email" type="checkbox" [id]="i" [formControlName]="i"/>
              <label *ngIf="u.email"> {{ u.email }} </label>
            </li>
          </ul>
        </div>
        <div>
          <label>&nbsp;</label>
          <button (click)="updateAutomaticDownloadSettings(automaticLogDownloadForm.value)">Update Report Configuration</button>
        </div>
      </form>
    </div>
    <div *ngIf="autoDownloadView && site && ((!site.users || (site.users && site.users.length === 0)) || (!site.controllers || (site.controllers && site.controllers.length === 0)))">
      <div *ngIf="(!site.users || (site.users && site.users.length === 0))">You do not have any users configured for this site.</div>
      <div *ngIf="!site.controllers || (site.controllers && site.controllers.length === 0)">You do not have any controllers configured for this site.</div>
    </div>
  </li>
</ul>
