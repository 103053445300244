<ion-grid id="zone" class="loading-controller-status" *ngIf="!nodeLoaded && onError">
  <ion-row class="main-heading">
    <ion-col class="zone" size="7">
      There was an error loading the controller status. Please check that the controller is reporting and properly set up.
    </ion-col>
  </ion-row>
</ion-grid>
<ion-grid id="zone" *ngIf="nodeLoaded"
  [ngClass]="(nodeData.status && nodeData.status.stage) ? nodeData.status.stage.toLowerCase() : 'day'"
  [class.view-only]="!nodeData.canEdit">
  <!-- Zone 1 display -->
  <ion-row class="main-heading">
    <ion-col class="zone" size="5">
      <b>Zone 1</b>
    </ion-col>
    <ion-col class="stage" size="4">
      <span>Stage</span>
      <ion-icon src="assets/custom/{{getStage()}}"></ion-icon>
      <b>{{nodeData.status ? nodeData.status.stage : 'N/A'}}</b>
    </ion-col>
    <ion-col size="3" class='last-update desktop'>Last Update: &nbsp;{{nodeData.updatedAt | date:'short'}}</ion-col>
  </ion-row>
  <hr class="hr-line">

  <div class="current">
    <ion-row class="main">
      <div class="alarm" *ngIf="zone1HasAlarm && getAlarmMessageForZone(1)">
        <div>
          <p>Alert</p>
          <p>The alarm for {{getAlarmMessageForZone(1)}} has sounded</p>
          <button *ngIf="(getAlarmMessageForZone(1).includes('HIGH') || getAlarmMessageForZone(1).includes('LOW')) && !(isPending('alarm1l') || isPending('alarm1h'))"
            (click)="resetAlarm(1)">Reset Alarm</button>
          <p class="syncing" *ngIf="(getAlarmMessageForZone(1).includes('HIGH') || getAlarmMessageForZone(1).includes('LOW')) && (isPending('alarm1l') || isPending('alarm1h'))">Alarm Settings Syncing...</p>
        </div>
        <ion-icon name='alert-circle-outline' class="note-alert warning"></ion-icon>
      </div>
      <div class="alarm offline" *ngIf="!nodeData.online">
        <div>
          <p>OFFLINE</p>
          <p>The node is offline and was last updated: {{nodeData.updatedAt | date:'short'}}</p>
        </div>
        <ion-icon name='eye-off-outline' class="note-alert warning"></ion-icon>
      </div>
      <div class="temp-and-set">        
        <div class="t">
          <div class="status-indicator">
            <ion-icon
              class="changing"
              color="secondary"
              style="fill: secondary;"
              *ngIf="nodeData.online && nodeData.settings && nodeData.settings.heatCool && nodeData.settings.heatCool.zone1.on" color="secondary"
              name="{{nodeData.settings.heatCool.zone1.heating ? 'arrow-up-outline' : 'arrow-down-outline'}}">
            </ion-icon>
            <ion-icon
              *ngIf="nodeData.online && (nodeData.settings && nodeData.settings.heatCool && !nodeData.settings.heatCool.zone1.on)"
              color="secondary-blue"
              src="/assets/custom/icon_star.svg">
            </ion-icon>
            <ion-icon
              *ngIf="!nodeData.online"
              color="danger"
              name="eye-off-outline">
            </ion-icon>
          </div>
          <div class="temp">
            <h5>Current Temp</h5>
            <p [class.pending]="nodeData.settings && nodeData.settings.heatCool && nodeData.settings.heatCool.zone1.on" *ngIf="nodeData.online">
              {{nodeData.status.ct1}}{{tUnits}}
            </p>
            <p *ngIf="!nodeData.online" class="offline">OFF</p>
            <div class="set" [class.isOverridden]='isSetPointOverridden().sp1'>
              <p>
                <label>Current Set Point</label>
                {{nodeData.settings.sp1}}{{tUnits}}
              </p>
            </div>
          </div>  
        </div>
        <app-status-chip
          class="set-point green status-chip"
          green="true"
          *ngIf="!isPending('sp1')"
          param='sp1'
          [pending]="isPending('sp1')"
          (edit)="editSetting($event)"
          name="Temporary Override Set Point"
        >
        </app-status-chip>
        <ion-button *ngIf="isPending('sp1')" class="set-point syncing" color="secondary"><ion-icon src="assets/custom/icon_sync.svg"></ion-icon> Syncing</ion-button>         
      </div>
      <div *ngIf="nodeData && nodeData.status">
        <div>
          <p>
            <label>Max</label>
            {{nodeData.status.max1}}{{tUnits}}
          </p>
        </div>

        <div>
          <p>
            <label>Min</label>
            {{nodeData.status.min1}}{{tUnits}}
          </p>
        </div>

        <div>
          <p>
            <label>Clock</label>
            {{nodeData.status.time}}
          </p>
        </div>

        <div>
          <p><label>Humidity Percentage</label>
          {{nodeData.status.hum}}%
          </p>
        </div>

        <p *ngIf="!nodeData.canEdit">VIEW ONLY</p>

        <app-status-chip class="green status-chip"
          green="true"
          *ngIf='nodeData.canEdit && !isPending("rem")'
          param=''
          [pending]="isPending('rem')"
          [editable]='nodeData.canEdit'
          (edit)="resetMinMax()"
          name="Reset Min/Max"
        >
        </app-status-chip>
        <ion-button class="syncing" *ngIf="isPending('rem')" color="secondary">
          <ion-icon src="assets/custom/icon_sync.svg"></ion-icon> Syncing
        </ion-button>
      </div>
    </ion-row>
    <ion-row  class="main border">
      <app-status-chip class="green status-chip times-two"
          green="true"
          *ngIf='!isPending("prog1")'
          param='prog1'
          [pending]="isPending('prog1')"
          (edit)="editSetting($event)"
          name="Adjust Stage Start Times & Set Points"
        >
        </app-status-chip>
        
        <ion-button *ngIf="isPending('prog1')" class="times-two syncing" color="secondary">
          <ion-icon src="assets/custom/icon_sync.svg"></ion-icon> <span>Syncing</span>
        </ion-button>           
    </ion-row>
  </div>

  <div class="options" [class.offline]="!nodeData.online">
    <section class="alarm"> <!-- settings -->
      <div class="title">
        <div>
          <ion-icon src="assets/custom/icon_bell.svg"></ion-icon>
          <span>Alarm Options</span>
        </div>
      </div>

      <div class="cools">
        <app-status-chip
          class="status-chip"
          *ngIf='true'
          [pending]="isPending('alarm1h')"
          (edit)="editSetting($event)"
          param='alarm1h'
          name="High"
          data="{{nodeData.settings.alarm1h}}"
          units="{{tUnits}}"
          [zeroDisabled]="!isCelsius"
        >
        </app-status-chip>

        <app-status-chip
          class="status-chip"
          *ngIf="true"
          param='alarm1l'
          [pending]="isPending('alarm1l')"
          (edit)="editSetting($event)"
          name="Low"
          data="{{nodeData.settings && nodeData.settings.alarm1l}}"
          units="{{tUnits}}"
          [zeroDisabled]="!isCelsius"
        >
        </app-status-chip>
      </div>
    </section>

    <section class="heat-cool">
      <div class="title">
        <div>
          <ion-icon src="assets/custom/icon_flame.svg"></ion-icon>
          <span>Temperature Options</span>
          <ion-icon name="help-circle-outline" (click)="pressHelp('1');"></ion-icon>
        </div>        
      </div>

      <div class="cools">
        <app-status-chip
          class="status-chip"
          *ngIf="nodeData && nodeData.model && !nodeData.model.includes('RWL')"
          param='c1m'
          [pending]="isPending('c1m')"
          [showstatus]="true"
          (edit)="editSetting($event)"
          [name]="getCoolHeatName('c1m')"
          data="{{nodeData.settings.c1text}}"
          [indicator]="nodeData.status ? nodeData.status.c1==='ON' : false"
          [status]="nodeData.status && nodeData.status.c1"
          [setting]="nodeData.settings && nodeData.settings.c1m"
          off="nodeData.status.c1==='OFF'"
          [vfdStatus]="nodeData.status && nodeData.status.spartan1"
        >
        </app-status-chip>

        <app-status-chip
          *ngIf="nodeData && nodeData.model && !nodeData.model.includes('RWL')"
          param='c2m'
          [pending]="isPending('c2m')"
          [showstatus]="true"
          (edit)="editSetting($event)"
          [name]="getCoolHeatName('c2m')"
          data="{{nodeData.settings.c2text}}"
          [indicator]="nodeData.status.c2==='ON'"
          [status]="nodeData.status.c2"
          off="nodeData.status.c2==='OFF'"
          [setting]="nodeData.settings.c2m"
          class="status-chip"
          [vfdStatus]="nodeData.status && nodeData.status.spartan2"
        >
        </app-status-chip>
        
        <app-status-chip
          *ngIf="nodeData && nodeData.model && !nodeData.model.includes('RWL') && (nodeData.status && nodeData.status.zones === 1)"
          param='c3m'
          [pending]="isPending('c3m')"
          [showstatus]="true"
          (edit)="editSetting($event)"
          [name]="getCoolHeatName('c3m')"
          data="{{nodeData.settings.c3text}}"
          [indicator]="nodeData.status.c3==='ON'"
          off="nodeData.status.c3==='OFF'"
          [status]="nodeData.status.c3"
          [setting]="nodeData.settings.c3m"
          class="status-chip"
          [vfdStatus]="nodeData.status && nodeData.status.spartan3"
        >
        </app-status-chip>

        <app-status-chip
          *ngIf="nodeData && nodeData.model && !nodeData.model.includes('RWL') && (nodeData.status && nodeData.status.zones == 1)"
          param='c4m'
          [pending]="isPending('c4m')"
          [showstatus]="true"
          (edit)="editSetting($event)"
          [name]="getCoolHeatName('c4m')"
          off="nodeData.status.c4==='OFF'"
          data="{{nodeData.settings.c4text}}"
          [indicator]="nodeData.status.c4=='ON'"
          [status]="nodeData.status.c4"
          [setting]="nodeData.settings.c4m"
          class="status-chip"
          [vfdStatus]="nodeData.status && nodeData.status.spartan4"
        >
        </app-status-chip>

        <app-status-chip
          *ngIf='nodeData.hasPo1 && (!nodeData.model.includes("CB") || (nodeData.model.includes("CB") && nodeData.cis_reporting))'
          param='vent1'
          [pending]="isPending('vent1')"
          [showstatus]="true"
          (edit)="editSetting($event)"
          name="{{getVentOneName()}}"
          status="{{nodeData.settings.v1mode}}"
          off="nodeData.settings.v1mode === 'OFF'"
          [indicator]="nodeData.settings.v1man > 0"
          data="{{nodeData.settings.v1man || (nodeData.settings && nodeData.settings.heatCool && nodeData.settings.heatCool.vent1ActivationTemperature) }}"
          units="{{(nodeData.settings.v1man !== 0) ? '%' : tUnits}}"
          [setting]="nodeData.settings.v1mode"
          class="status-chip"
        >
        </app-status-chip>

        <app-status-chip
          *ngIf="nodeData.model && nodeData.model.includes('RWL') && (nodeData.status && nodeData.status.zones == 1) && (!nodeData.model.includes('CB') || (nodeData.model.includes('CB') && nodeData.cis_reporting))"
          param='vent2'
          [pending]="isPending('vent2')"
          [showstatus]="true"
          (edit)="editSetting($event)"
          name="{{nodeData.node_id.startsWith('SWP') ? 'Vent B' : 'Vent 2'}}"
          status="{{nodeData.settings.v2mode}}"
          [indicator]="nodeData.settings.v2man > 0"
          off="nodeData.settings.v2mode === 'OFF'"
          data="{{nodeData.settings.v2man || nodeData.settings && nodeData.settings.heatCool && nodeData.settings.heatCool.vent2ActivationTemperature}}"
          units="{{(nodeData.settings.v2man !== 0) ? '%' : tUnits}}"
          [setting]="nodeData.settings.v2mode"
          class="status-chip"
        >
        </app-status-chip>

        <ng-container *ngIf="nodeData.model && nodeData.model.includes('CB') && !nodeData.cis_reporting">
          <app-status-chip
            *ngFor="let ventSetting of getVentInformation(false) | slice : (nodeData.hasPo2 ? 0 : 2) ; index as i; first as first; last as last"
            param='vent'
            [pending]="isPendingFromArrayVal('vent', ventSetting.original_index)"
            [showstatus]="true"
            (edit)="editSettingArrayValue('vent', ventSetting.original_index)"
            name="{{getCbVentName(ventSetting.original_index, (nodeData.status && nodeData.status.zones || 1), false, (first && last))}}"
            status="{{!ventSetting.mode ? 'OFF' : ventSetting.mode}}"
            off="!ventSetting.mode || ventSetting.mode === 'OFF'"
            [indicator]="ventSetting.man > 0"
            data="{{ventSetting.man || getActivationForCbVent(ventSetting, i) }}"
            units="{{((ventSetting.man && ventSetting.man !== 0) ? '%' : tUnits)}}"
            [setting]="ventSetting.mode"
            class="status-chip"
          >
          </app-status-chip>
        </ng-container>

        <div class="emptySpace"
          *ngIf="(nodeData && nodeData.model && !nodeData.model.includes('RWL') || (nodeData.status && nodeData.status.zones != 1)) || !nodeData.hasPo1"
        ></div>
      </div>

      <div class="set-point-line"><span>Set Point: {{nodeData.settings.sp1}}{{tUnits}}</span></div>
      <div class="heats">
        <app-status-chip
          *ngIf="nodeData && nodeData.status"
          param='h1m'
          [pending]="isPending('h1m')"
          [showstatus]="true"
          (edit)="editSetting($event)"
          [name]="getCoolHeatName('h1m')"
          off="nodeData.status.h1==='OFF'"
          data="{{nodeData.settings.h1text}}"
          [indicator]="nodeData.status.h1==='ON'"
          [status]="nodeData.status.h1"
          [setting]="nodeData.settings.h1m"
          class="status-chip"
        >
        </app-status-chip>

        <app-status-chip
          *ngIf='(nodeData.status && nodeData.status.zones == 1)'
          param='h2m'
          [pending]="isPending('h2m')"
          [showstatus]="true"
          (edit)="editSetting($event)"
          [name]="getCoolHeatName('h2m')"
          data="{{nodeData.settings.h2text}}"
          off="nodeData.status.h2==='OFF'"
          [indicator]="nodeData.status.h2==='ON'"
          [status]="nodeData.status.h2"
          [setting]="nodeData.settings.h2m"
          class="status-chip"
        >
        </app-status-chip>

        <div class="emptySpace"
          *ngIf="(nodeData.status && nodeData.status.zones != 1)"
        ></div>
        <app-status-chip
          class="status-chip"
          param='alarm1l_float'
          [pending]="isPending('alarm1l_float')"
          (edit)="editSetting($event)"
          name="Heat 1 Floating Low Alarm"
          data="{{nodeData.settings && nodeData.settings.alarm1l_float}}"
          activation="{{getFloatingAlarmActivation(nodeData.settings.sp1, nodeData.settings.h1t, nodeData.settings.alarm1l_float)}}"
          units="{{tUnits}}"
          indicator="{{alarmOn('alarm1l_float','FLOAT1LOW')}}"
          [setting]="alarmOn('alarm1l_float','FLOAT1LOW') ? 'ALARM' : 'OFF'"
          [showstatus]="true"
        >
        </app-status-chip>

        <app-status-chip
          class="status-chip"
          param='alarm1l_float_delay'
          [pending]="isPending('alarm1l_float_delay')"
          (edit)="editSetting($event)"
          name="Heat 1 Floating Low Alarm Delay (mm:ss)"
          data="{{nodeData.settings && nodeData.settings.alarm1l_float_delay}}"
        >
        </app-status-chip>

        <app-status-chip
          class="status-chip"
          *ngIf="(nodeData.status && nodeData.status.zones !== 2)"
          param='alarm2l_float'
          [pending]="isPending('alarm2l_float')"
          (edit)="editSetting($event)"
          name="Heat 2 Floating Low Alarm"
          data="{{nodeData.settings.alarm2l_float}}"
          activation="{{getFloatingAlarmActivation(nodeData.settings.sp1, nodeData.settings.h2t, nodeData.settings.alarm2l_float)}}"
          units="{{tUnits}}"
          indicator="{{alarmOn('alarm2l_float', 'FLOAT2LOW')}}"
          [setting]="alarmOn('alarm2l_float','FLOAT2LOW') ? 'ALARM' : 'OFF'"
          [showstatus]="true"
        >
        </app-status-chip>

        <app-status-chip
          class="status-chip"
          param='alarm2l_float_delay'
          *ngIf="(nodeData.status && nodeData.status.zones !== 2)"
          [pending]="isPending('alarm2l_float_delay')"
          (edit)="editSetting($event)"
          name="Heat 2 Floating Low Alarm Delay (mm:ss)"
          data="{{nodeData.settings.alarm2l_float_delay}}"
        >
        </app-status-chip>
      </div>
    </section>
  </div>

  <!-- Zone 2 display -->
  <ion-row class="main-heading" [class.offline]="!nodeData.online"
    *ngIf="(nodeData.status && nodeData.status.zones == 2)">
    <ion-col class="zone" size="7">
      <b>Zone 2</b>
    </ion-col>
    <ion-col class="stage" size="5">
      <span>Stage</span>
      <ion-icon src="assets/custom/{{getStage()}}"></ion-icon>
      <b>{{nodeData.status.stage}}</b>
    </ion-col>
  </ion-row>
  <hr class="hr-line"/>

  <div class="current" [class.offline]="!nodeData.online"
    *ngIf="(nodeData.status && nodeData.status.zones == 2)">
    <ion-row class="main">
      <div class="alarm" *ngIf="zone2HasAlarm && getAlarmMessageForZone(2)">
        <div>
          <p>Alert</p>
          <p>The alarm for {{getAlarmMessageForZone(2)}} has sounded</p>
          <button *ngIf="(getAlarmMessageForZone(2).includes('HIGH') || getAlarmMessageForZone(2).includes('LOW')) && !(isPending('alarm1l') || isPending('alarm1h'))"
            (click)="resetAlarm(2)">Reset Alarm</button>
        </div>
        <ion-icon name='alert-circle-outline' class="note-alert warning"></ion-icon>
      </div>
      <ion-col class="temp-and-set">
        <div class="status-indicator">
          <ion-icon
            *ngIf="nodeData.settings && nodeData.settings.heatCool &&nodeData.settings.heatCool.zone2.on"
            color="secondary"
            name="{{(nodeData.settings && nodeData.settings.heatCool && nodeData.settings.heatCool.zone2.heating) ? 'arrow-up-outline' : 'arrow-down-outline'}}">
          </ion-icon>
          <ion-icon
            *ngIf="nodeData.settings && nodeData.settings.heatCool && !nodeData.settings.heatCool.zone2.on"
            color="secondary-blue" name="star-outline">
          </ion-icon>
        </div>
        <div class="temp">
          <h5>Current Temp</h5>
          <p [class.pending]="nodeData.settings && nodeData.settings.heatCool && nodeData.settings.heatCool.zone2.on">{{nodeData.status.ct2}}{{tUnits}}</p>
          <div class="set" [class.isOverridden]='isSetPointOverridden().sp1'>
            <p *ngIf="nodeData && nodeData.settings">
              <label>Set</label>
              {{nodeData.settings.sp2}}{{tUnits}}
            </p>
          </div>
        </div>
      </ion-col>
      <ion-col>
        <div>
          <p><label>Max</label>&nbsp;
            {{nodeData.status.max2}}{{tUnits}}</p>
        </div>

        <div>
          <p><label>Min</label>&nbsp;
            {{nodeData.status.min2}}{{tUnits}}</p>
        </div>

        <p *ngIf="!nodeData.canEdit">VIEW ONLY</p>

        <app-status-chip class="green status-chip"
          green="true"
          *ngIf='nodeData.canEdit && !isPending("rem")'
          param=''
          [pending]="isPending('rem')"
          (edit)="resetMinMax()"
          name="Reset Min/Max"
        >
        </app-status-chip>
        <ion-button class="syncing" *ngIf="isPending('rem')" color="secondary">
          <ion-icon src="assets/custom/icon_sync.svg"></ion-icon> Syncing
        </ion-button>
      </ion-col>
    </ion-row>
  </div>

  <div class="options" [class.offline]="!nodeData.online"
    *ngIf="(nodeData.status && nodeData.status.zones == 2)">
    <section class="alarm"> <!-- settings -->
      <div class="title">
        <div>
          <ion-icon src="assets/custom/icon_bell.svg" ></ion-icon>
          <span>Alarm Options</span>
        </div>
      </div>

      <div class="cools">
        <app-status-chip
          *ngIf='true'
          [pending]="isPending('alarm2h')"
          (edit)="editSetting($event)"
          param='alarm2h'
          name="High"
          data="{{nodeData.settings.alarm2h}}"
          units="{{tUnits}}"
          class="status-chip"
          [zeroDisabled]="!isCelsius"
        >
        </app-status-chip>

        <app-status-chip
          *ngIf="true"
          param='alarm2l'
          [pending]="isPending('alarm2l')"
          (edit)="editSetting($event)"
          name="Low"
          data="{{nodeData.settings.alarm2l}}"
          units="{{tUnits}}"
          class="status-chip"
          [zeroDisabled]="!isCelsius"
        >
        </app-status-chip>
      </div>
    </section>

    <section class="heat-cool">
      <div class="title">
        <div>
          <ion-icon src="assets/custom/icon_flame.svg"></ion-icon>
          <span>Temperature Options</span>
          <ion-icon name="help-circle-outline" (click)="pressHelp('2');"></ion-icon>
        </div>
        <app-status-chip
          class="set-point green status-chip"
          green="true"
          *ngIf="!isPending('sp2')"
          param='sp2'
          [pending]="isPending('sp2')"
          (edit)="editSetting($event)"
          name="Override Set Point"
        >
        </app-status-chip>
        <ion-button *ngIf="isPending('sp2')" class="set-point syncing"
          color="secondary"><ion-icon src="assets/custom/icon_sync.svg"></ion-icon> Syncing</ion-button>
      </div>

      <div class="cools">
        <app-status-chip
          *ngIf="nodeData && nodeData.model && !nodeData.model.includes('RWL')"
          param='c3m'
          [pending]="isPending('c3m')"
          [showstatus]="true"
          (edit)="editSetting($event)"
          [name]="getCoolHeatName('c3m')"
          data="{{nodeData.settings.c3text}}"
          [indicator]="nodeData.status.c3==='ON'"
          off="nodeData.status.c3==='OFF'"
          [status]="nodeData.status.c3"
          [setting]="nodeData.settings.c3m"
          class="status-chip"
        >
        </app-status-chip>

        <app-status-chip
          *ngIf="nodeData && nodeData.model && !nodeData.model.includes('RWL')"
          param='c4m'
          [pending]="isPending('c4m')"
          [showstatus]="true"
          off="nodeData.status.c3==='OFF'"
          (edit)="editSetting($event)"
          [name]="getCoolHeatName('c4m')"
          data="{{nodeData.settings.c4text}}"
          [indicator]="nodeData.status.c4==='ON'"
          [status]="nodeData.status.c4"
          [setting]="nodeData.settings.c4m"
          class="status-chip"
        >
        </app-status-chip>

        <app-status-chip
          *ngIf='nodeData.hasPo2 && (!nodeData.model.includes("CB") || (nodeData.model && nodeData.model.includes("CB") && nodeData.cis_reporting))'
          param='vent2'
          [pending]="isPending('vent2')"
          [showstatus]="true"
          (edit)="editSetting($event)"
          name="Vent 2"
          [indicator]="nodeData.settings.v2man > 0"
          off="nodeData.settings.v2mode === 'OFF'"
          data="{{nodeData.settings.v2man || (nodeData.settings && nodeData.settings.heatCool && nodeData.settings.heatCool.vent2ActivationTemperature)}}"
          units="{{nodeData.settings.v2man !== 0 ? '%' : tUnits}}"
          status="{{nodeData.settings.v2mode}}"
          [setting]="nodeData.settings.v2mode"
          class="status-chip"
        >
        </app-status-chip>

        <ng-container *ngIf="nodeData.hasPo2 && nodeData.model && nodeData.model.includes('CB') && !nodeData.cis_reporting">
          <app-status-chip
            *ngFor="let ventSetting of getVentInformation(true) | slice : (nodeData.hasPo2 ? 0 : 2) ; index as i; first as first; last as last;"
            param='vent'
            [pending]="isPendingFromArrayVal('vent', ventSetting.original_index)"
            [showstatus]="true"
            (edit)="editSettingArrayValue('vent', ventSetting.original_index)"
            name="{{getCbVentName(ventSetting.original_index, (nodeData.status && nodeData.status.zones || 1), true, (first && last))}}"
            status="{{!ventSetting.mode ? 'OFF' : ventSetting.mode}}"
            off="!ventSetting.mode || ventSetting.mode === 'OFF'"
            [indicator]="ventSetting.man > 0"
            data="{{ventSetting.man || getActivationForCbVent(ventSetting, i) }}"
            units="{{(ventSetting.man ? ((ventSetting.man !== 0) ? '%' : tUnits) : '')}}"
            [setting]="ventSetting.mode"
            class="status-chip"
          >
          </app-status-chip>
        </ng-container>
        <div class="emptySpace"></div>
      </div>

      <div class="set-point-line" *ngIf="nodeData && nodeData.settings"><span>Set Point: {{nodeData.settings.sp2}}{{tUnits}}</span></div>
      <div class="heats" *ngIf='nodeData.status && nodeData.status.zones !== 1'>
        <app-status-chip
          param='h2m'
          [pending]="isPending('h2m')"
          [showstatus]="true"
          (edit)="editSetting($event)"
          [name]="getCoolHeatName('h2m')"
          data="{{nodeData.settings.h2text}}"
          off="nodeData.status.h2==='OFF'"
          [indicator]="nodeData.status.h2==='ON'"
          [status]="nodeData.status.h2"
          [setting]="nodeData.settings.h2m"
          class="status-chip"
        >
        </app-status-chip>
        <div class="emptySpace"
          *ngIf="(nodeData.status && nodeData.status.zones != 1)"
        ></div>

        <app-status-chip
          class="status-chip"
          param='alarm2l_float'
          [pending]="isPending('alarm2l_float')"
          (edit)="editSetting($event)"
          name="Heat 2 Floating Low Alarm"
          data="{{nodeData.settings.alarm2l_float}}"
          activation="{{getFloatingAlarmActivation(nodeData.settings.sp2, nodeData.settings.h2t, nodeData.settings.alarm2l_float)}}"
          indicator="{{alarmOn('alarm2l_float', 'FLOAT2LOW')}}"
          [setting]="alarmOn('alarm2l_float','FLOAT2LOW') ? 'ALARM' : 'OFF'"
          units="{{tUnits}}"
          [showstatus]="true"
        >
        </app-status-chip>

        <app-status-chip
          class="status-chip"
          param='alarm2l_float_delay'
          [pending]="isPending('alarm2l_float_delay')"
          (edit)="editSetting($event)"
          name="Heat 2 Floating Low Alarm Delay"
          data="{{nodeData.settings.alarm2l_float_delay}}"
          units="mm:ss"
        >
        </app-status-chip>
      </div>
    </section>

    <section class="misc">
      <app-status-chip class="green times-two status-chip"
        green="true"
        *ngIf='!isPending("prog2")'
        param='prog2'
        [pending]="isPending('prog2')"
        (edit)="editSetting($event)"
        name="Adjust Program"
      >
      </app-status-chip>      
      <ion-button *ngIf="isPending('prog2')" class="times-two syncing" color="secondary">
        <ion-icon src="assets/custom/icon_sync.svg"></ion-icon> <span>Syncing</span>
      </ion-button>
    </section>
  </div>

  <timer-mister
    *ngIf="(nodeData && nodeData.model && !nodeData.model.startsWith('CB')) || nodeData.cis_reporting"
    [nodeData]="nodeData && nodeData.settings && nodeData.status"
    [isClimateBoss]='nodeData.model.startsWith("CB")'
    [tUnits]="tUnits"
    [timerInfo]="getTimerInfo()"
    [offline]="!nodeData.online"
    [outputStatus]="nodeData.status.timer"
    [isClimateBoss]="nodeData.model.startsWith('CB')"
    [timerIndex]="1"
  ></timer-mister>

  <timer-mister
    [offline]="!nodeData.online"
    *ngIf="nodeData.model && nodeData.model.startsWith('CB') && !nodeData.cis_reporting && nodeData.settings.timer && !isEmpty(nodeData.settings.timer[1])"
    [isClimateBoss]='nodeData.model.startsWith("CB")'
    [nodeData]="nodeData"
    [tUnits]="tUnits"
    [timerInfo]="nodeData.settings.timer ? nodeData.settings.timer[1] : {}"
    [timerIndex]="2"
    [outputStatus]="nodeData.status.timer2"
  ></timer-mister>

  <div class="timeboss-timer" *ngIf="nodeData.model && nodeData.model.startsWith('CB') && !nodeData.cis_reporting"
    [class.timer-on]="getTwoStageTimerInformation(0).on"
    [class.offline]="!nodeData.online">
    <ion-item lines="no-lines" class="ion-no-padding">
      <ion-label>
        <ion-icon *ngIf="!getTwoStageTimerInformation(0).isMister && !getTwoStageTimerInformation(0).isShade" name='alert-circle-outline'></ion-icon>
        <ion-icon *ngIf="getTwoStageTimerInformation(0).isMister" name="rainy-outline"></ion-icon>
        <ion-icon *ngIf="getTwoStageTimerInformation(0).isShade" name="glasses-outline"></ion-icon>
        <span class="timer-name">
          {{getTimerName('t1')}} Options
          <ion-icon name="help-circle-outline" (click)="this.guideMe(['Timers']);"></ion-icon>
          <ion-spinner *ngIf="getTwoStageTimerInformation(0).pending || getTwoStageTimerInformation(0).mister_pending" color="secondary" name="bubbles"></ion-spinner>          
        </span>
      </ion-label>
      <ion-label slot="end" class="mode">Mode: {{getTwoStageTimerMode(0)}}</ion-label>
    </ion-item>

    <app-time-boss-status [t]="getTwoStageTimerInformation(0)" [i]="1"
      [canEdit]="nodeData.canEdit" (pending)="setTimeBossPending($event)"
      [nodeData]="nodeData" [email]="getCurrentUserEmail()" notitle="true"
      [light_value]="nodeData.status && nodeData.status.light"
      [daily_light_integral]="nodeData.status && nodeData.status.dli"
      [light_set_point]="getLightSetPoint(0)"
      [shadeInfo]="getShadeInfo()" [pendingSettings]="getTwoStageTimerInformation(0) ? getTwoStageTimerInformation(0)['pendingSettings'] : {}"
      [isClimateBoss]="nodeData.model.startsWith('CB')"
      [clock]="nodeData.status && nodeData.status.time"
      ></app-time-boss-status>
  </div>
<!--
  <timer-mister
    [class.offline]="!nodeData.online"
    *ngIf="nodeData.model && nodeData.model.startsWith('CB') && !nodeData.cis_reporting"
    [nodeData]="nodeData"
    [isClimateBoss]='nodeData.model.startsWith("CB")'
    [tUnits]="tUnits"
    [timerInfo]="nodeData.settings.timer ? nodeData.settings.timer[0] : {}"
    [timerIndex]="1"
    [outputStatus]="nodeData.status.timer"
    [shadeInfo]="getShadeInfo()"
  ></timer-mister> -->

  <div class="timeboss-timer" *ngIf="nodeData.model && nodeData.model.startsWith('CB') && !nodeData.cis_reporting && !isEmpty(getTwoStageTimerInformation(1))"
    [class.timer-on]="getTwoStageTimerInformation(1).on"
    [class.offline]="!nodeData.online">
    <ion-item lines="no-lines" class="ion-no-padding">
        <ion-label>
          <ion-icon *ngIf="!getTwoStageTimerInformation(1).isMister && !getTwoStageTimerInformation(1).isShade" name="alarm-outline"></ion-icon>
          <ion-icon *ngIf="getTwoStageTimerInformation(1).isMister" name="rainy-outline"></ion-icon>
          <ion-icon *ngIf="getTwoStageTimerInformation(1).isShade" name="glasses-outline"></ion-icon>
          <span class="timer-name">{{getTimerName('t2')}} Options
            <ion-icon name="help-circle-outline" (click)="this.guideMe(['Timers']);"></ion-icon>
            <ion-spinner *ngIf="getTwoStageTimerInformation(1).pending || getTwoStageTimerInformation(1).mister_pending" color="secondary" name="bubbles"></ion-spinner>
          </span>          
        </ion-label>
        <ion-label slot="end" class="mode">Mode: {{getTwoStageTimerMode(1)}}</ion-label>
    </ion-item>
    <app-time-boss-status [t]="getTwoStageTimerInformation(1)" [i]="2"
      [canEdit]="nodeData.canEdit" (pending)="setTimeBossPending($event)"
      [nodeData]="nodeData" [email]="getCurrentUserEmail()" notitle="true"
      [light_value]="nodeData.status && nodeData.status.light"
      [daily_light_integral]="nodeData.status && nodeData.status.dli"
      [light_set_point]="getLightSetPoint(1)"  [pendingSettings]="getTwoStageTimerInformation(1) ? getTwoStageTimerInformation(1)['pendingSettings'] : {}"
      [clock]="nodeData.status && nodeData.status.time"
      ></app-time-boss-status>
  </div>

  <div class="dehumidification" [class.offline]="!nodeData.online"
    *ngIf="nodeData.model && (nodeData.model.startsWith('CB') || !nodeData.model.includes('RWL'))">
    <section> <!-- settings -->
      <div class="title">
        <div>
          <ion-icon name="sunny-outline"></ion-icon>
          <span>Dehumidification Options</span>
          <ion-icon name="help-circle-outline" (click)="this.guideMe(['Dehumidification']);"></ion-icon>
        </div>
        <app-status-chip
          class="set-point green status-chip"
          green="true"
          *ngIf="!isPending('settings.dhStart') && nodeData.canEdit && !nodeData.status.dhPreheat && !nodeData.status.dhExhaust"
          param='dhStart'
          [pending]="isPending('settings.dhStart')"
          [editable]='false'
          name="Start"
          (edit)="activateDehumidification(1)"
        >
        </app-status-chip>
        <!-- <app-status-chip
          class="set-point green"
          *ngIf="!isPending('settings.dhStart') && nodeData.canEdit && (nodeData.status.dhPreheat || nodeData.status.dhExhaust)"
          param='dhStart'
          [pending]="!isPending('settings.dhStart')"
          [editable]='false'
          name="Stop"
          (edit)="activateDehumidification(0)"
        >
        </app-status-chip>
         -->
        <ion-button *ngIf="isPending('settings.dhStart') && !(nodeData.status.dhPreheat || nodeData.status.dhExhaust)"
          class="set-point syncing" color="secondary">
          <ion-icon src="assets/custom/icon_sync.svg"></ion-icon> Syncing
        </ion-button>
      </div>

      <app-status-chip
        param='settings.dhVentMode'
        [pending]="isPending('settings.dhVentMode')"
        name="Vent Mode"
        data="{{nodeData.settings.dhVentMode}}"
        (edit)="showWindRainEdit($event)"
        class="status-chip"
      >
      </app-status-chip>

      <app-status-chip
        param='settings.dhStartTime'
        [pending]="isPending('settings.dhStartTime')"
        name="Start Time (hh:mm)"
        data="{{nodeData.settings.dhStartTime}}"
        (edit)="showWindRainEdit($event)"
        class="status-chip"
      >
      </app-status-chip>

      <app-status-chip
        param='settings.dhFan1'
        [pending]="isPending('settings.dhFan1')"
        name="Fan 1 Cycle Time"
        data="{{nodeData.settings.dhFan1}}"
        units="m"
        (edit)="showWindRainEdit($event)"
        class="status-chip"
      >
      </app-status-chip>

      <app-status-chip
        param='settings.dhHeat'
        [pending]="isPending('settings.dhHeat')"
        name="Heat Time"
        data="{{nodeData.settings.dhHeat}}"
        units="m"
        (edit)="showWindRainEdit($event)"
        class="status-chip"
      >
      </app-status-chip>

      <app-status-chip
        param='settings.dhFan2'
        [pending]="isPending('settings.dhFan2')"
        name="Fan 2 Cycle Time"
        data="{{nodeData.settings.dhFan2}}"
        units="m"
        (edit)="showWindRainEdit($event)"
        class="status-chip"
      >
      </app-status-chip>

      <app-status-chip
        param='settings.dhRepeat'
        [pending]="isPending('settings.dhRepeat')"
        name="Repeat"
        data="{{nodeData.settings.dhRepeat}}"
        (edit)="showWindRainEdit($event)"
        class="status-chip"
      >
      </app-status-chip>

      <app-status-chip
        param='settings.dhDif'
        [pending]="isPending('settings.dhDif')"
        name="Enabled in DIF"
        data="{{nodeData.settings.dhDif}}"
        (edit)="showWindRainEdit($event)"
        class="status-chip"
      >
      </app-status-chip>

      <app-status-chip
        param='settings.dhHiTemp'
        [pending]="isPending('settings.dhHiTemp')"
        name="High Temp"
        data="{{(nodeData.settings.dhHiTemp === 0) ? 'Use Cool 2' : nodeData.settings.dhHiTemp}}"
        (edit)="showWindRainEdit($event)"
        class="status-chip"
      >
      </app-status-chip>

      <app-status-chip
        param='settings.dhDay'
        [pending]="isPending('settings.dhDay')"
        name="Enabled in DAY"
        data="{{nodeData.settings.dhDay}}"
        (edit)="showWindRainEdit($event)"
        class="status-chip"
      >
      </app-status-chip>

      <app-status-chip
        param='settings.dhSp'
        [pending]="isPending('settings.dhSp')"
        name="Set Point"
        data="{{nodeData.settings.dhSp}}"
        units="%"
        (edit)="showWindRainEdit($event)"
        class="status-chip"
      >
      </app-status-chip>

      <app-status-chip
        param='settings.dhNight'
        [pending]="isPending('settings.dhNight')"
        name="Enabled in NITE"
        data="{{nodeData.settings.dhNight}}"
        (edit)="showWindRainEdit($event)"
        class="status-chip"
      >
      </app-status-chip>

      <app-status-chip
        param='settings.dhLoTemp'
        [pending]="isPending('settings.dhLoTemp')"
        name="Low Temp"
        data="{{(nodeData.settings.dhLoTemp === 0) ? 'Use Heat 1' : nodeData.settings.dhLoTemp}}"
        (edit)="showWindRainEdit($event)"
        class="status-chip"
      >
      </app-status-chip>
    </section>

    <section class="misc">
      <app-status-chip
        class="green times-two status-chip"
        green="true"
        *ngIf="!isPending('settings.dhSetup') && nodeData.canEdit"
        param='dhSetup'
        [pending]="isPending('settings.dhSetup')"
        [editable]='false'
        name="Change Dehumidification Setup"
        (edit)="editDehumidificationSetup()"
      >
      </app-status-chip>
      <ion-button *ngIf="isPending('settings.dhSetup')"
        class="syncing times-two" color="secondary">
        <ion-icon src="assets/custom/icon_sync.svg"></ion-icon> Syncing
      </ion-button>
    </section>
  </div>

  <div class="dehumidification" *ngIf="nodeData.model && nodeData.model.includes('RWL')">
    <section class="humidity">
      <div class="title">
        <div>
          <ion-icon src="assets/custom/icon_humidity.svg"></ion-icon>
          <span>Humidity Options</span>
        </div>
        <app-status-chip
          class="set-point green status-chip"
          green="true"
          *ngIf="!isPending('humsp')"
          param='humsp'
          [pending]="isPending('humsp')"
          (edit)="editSetting($event)"
          name="Set Point"
        >
        </app-status-chip>
        <ion-button *ngIf="isPending('humsp')"  class="set-point syncing" color="secondary"><ion-icon src="assets/custom/icon_sync.svg"></ion-icon> Syncing</ion-button>
      </div>
      <div class="details">
        Current Humidity
      </div>
      <div class="details">
        <p class="zone-humidity">{{nodeData.status.hum}}%</p>
        <p>
          <label>Set</label>
          {{nodeData.settings.humsp}}%
        </p>
      </div>
    </section>
  </div>

  <!-- Heat 3 display -->
  <ion-row class="main-heading" [class.offline]="!nodeData.online"
    *ngIf="nodeData.hasH3">
    <ion-col class="zone" size="5">
      <b>{{getCoolHeatName('h3m')}}</b>
    </ion-col>
    <ion-col class="stage" size="5">
      <span>Stage</span>
      <ion-icon src="assets/custom/{{getStage()}}"></ion-icon>
      <b>{{nodeData.status.stage}}</b>
    </ion-col>
  </ion-row>
  <hr class="hr-line"/>

  <div class="current" [class.offline]="!nodeData.online"
    *ngIf="nodeData.hasH3">
    <ion-row class="main">
      <div class="alarm" *ngIf="nodeData.status.alarms && (nodeData.status.alarms.includes('HIGH3') || nodeData.status.alarms.includes('LOW3'))">
        <div>
          <p>Alert</p>
          <p>The alarm for {{nodeData.status.alarms.includes("HIGH3") ? "Heat 3 High" : "Heat 3 Low"}} has sounded</p>
          <p class="syncing" *ngIf="(isPending('alarm3l') || isPending('alarm3h'))">Alarm Settings Syncing...</p>
        </div>
        <ion-icon name='alert-circle-outline' class="note-alert warning"></ion-icon>
      </div>      
      <ion-col class="temp-and-set">
        <div class="status-indicator">
          <ion-icon
            *ngIf="nodeData.settings && nodeData.settings.heatCool && nodeData.settings.heatCool.heat3.on" color="secondary"
            name="arrow-up-outline">
          </ion-icon>
          <ion-icon
            *ngIf="!nodeData.settings.heatCool.heat3.on"
            color="secondary-blue" name="star-outline">
          </ion-icon>
        </div>
        <div class="temp">
          <h5>Current Heat</h5>
          <p
            [class.pending]="nodeData.settings && nodeData.settings.heatCool && nodeData.settings.heatCool.heat3.on">{{nodeData.status.ct3}}{{tUnits}}
          </p>
          <div class="set">
            <p>
              <label>Set</label>
              {{nodeData.settings.sp3}}{{tUnits}}
            </p>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </div>

  <div class="options" [class.offline]="!nodeData.online"
    *ngIf="nodeData.hasH3">
    <section class="alarm" *ngIf="nodeData.hasH3"> <!-- settings -->
      <div class="title">
        <div>
          <ion-icon src="assets/custom/icon_bell.svg" ></ion-icon>
          <span>Heat 3 Alarm Options</span>
        </div>
      </div>

      <div class="cools">
        <app-status-chip
          [pending]="isPending('alarm3h')"
          (edit)="editSetting($event)"
          param='alarm3h'
          name="High"
          data="{{nodeData.settings.alarm3h}}"
          units="{{tUnits}}"
          class="status-chip"
        >
        </app-status-chip>

        <app-status-chip
          param='alarm3l'
          [pending]="isPending('alarm3l')"
          (edit)="editSetting($event)"
          name="Low"
          data="{{nodeData.settings.alarm3l}}"
          units="{{tUnits}}"
          class="status-chip"
        >
        </app-status-chip>
      </div>
    </section>

    <div class="title">
      <div>
        <ion-icon src="assets/custom/icon_flame.svg"></ion-icon>
        <span>Temperature Options</span>
      </div>
      <app-status-chip
        class="set-point green status-chip"
        green="true"
        *ngIf="!isPending('sp3')"
        param='sp3'
        [pending]="isPending('sp3')"
        (edit)="editSetting($event)"
        name="Override Set Point"
      >
      </app-status-chip>
      <ion-button *ngIf="isPending('sp3')"
        class="set-point syncing" color="secondary">
        <ion-icon src="assets/custom/custom-sync.svg" ></ion-icon> Syncing
      </ion-button>
    </div>

    <div class="heats">
      <app-status-chip
        param='sp3'
        [pending]="isPending('sp3')"
        [showstatus]="true"
        name="Heat 3"
        units="{{tUnits}}"
        data="{{nodeData.settings.sp3}}"
        off="(nodeData.status.ct3 > nodeData.settings.sp3)"
        [indicator]="(nodeData.settings.sp3 >= nodeData.status.ct3)"
        [status]="(nodeData.settings.sp3 >= nodeData.status.ct3) ? 'ON' : 'OFF'"
        [setting]="(nodeData.settings.sp3 >= nodeData.status.ct3) ? 'ON' : 'OFF'"
        class="status-chip"
      >
      </app-status-chip>
      <div class="emptySpace"></div>
    </div>
  </div>
  <!-- misc house info -->

  <hr class="hr-line">
  <div class="last-update phone">
    <span>Last Update</span>
    <span>{{nodeData.updatedAt | date:'short'}}</span>
  </div>

  <div class="last-update" (click)="goToAuditTrailPage()">
    <a>View Audit Trail</a>
  </div>

  <div class="last-update">
    <a href="{{downloadUrl()}}">Download Current Settings</a>
  </div>
</ion-grid>
