import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { environment } from '../../environments/environment';
import { SitesService } from './sites.service';
import { Node } from '../model-defs/node-def';

@Injectable({ providedIn: 'root' })
export class SettingsService {
  private baseUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private users: UserService,
    private nodeService: SitesService
  ) {}

  getLibraryOptions(site_id): Promise<any>{
    let import_url = `${this.baseUrl}/api/site/${site_id}/settings?token=${this.users.getLoginData().authentication_token}`;
    return this.http.get(import_url).toPromise();
  }

  getLibraryOptionDetails(site_id, external_id, index): Promise<any>{
    let import_url = `${this.baseUrl}/api/site/${site_id}/settings/${external_id}/${index}?token=${this.users.getLoginData().authentication_token}`;
    return this.http.get(import_url).toPromise();
  }

  updateLibraryOptions(site_id, option, action): Promise<any>{    
    if(action === 'ADD') {      
      return new Promise((resolve, reject) => {        
        this.nodeService.getNodeData()
          .then((response) => {
            let nodeData = response as Node;
            if(nodeData.model && nodeData.settings && nodeData.status) {
              if(option.optionId) {
                let editSettingUrl = `${this.baseUrl}/api/site/${site_id}/settings/${option.optionId}?token=${this.users.getLoginData().authentication_token}`;
                this.http.put(editSettingUrl, {
                  name: option.name,               
                  scheduleName: option.scheduleName,
                  settings: nodeData.settings, status: {zones: nodeData.status.zones, model: nodeData.model}
                }).toPromise()
                    .then((response) => { resolve(response); })
                    .catch((e) => reject(e))
              } else {
                let addSettingUrl = `${this.baseUrl}/api/site/${site_id}/settings?token=${this.users.getLoginData().authentication_token}`;
                this.http.post(addSettingUrl, {
                  name: option.name,               
                  scheduleName: option.scheduleName,
                  settings: nodeData.settings, status: {zones: nodeData.status.zones, model: nodeData.model}
                }).toPromise()
                    .then((response) => { resolve(response); })
                    .catch((e) => reject(e))
              }              
            } else {
              reject();
            }
          })
          .catch((e) => {
            reject();
          })      
      })      
    } else if(action === 'DELETE') {
      let deleteSettingUrl = `${this.baseUrl}/api/site/${site_id}/settings/${option.id}?token=${this.users.getLoginData().authentication_token}`;
      return this.http.delete(deleteSettingUrl).toPromise();
    } else {
      return new Promise((resolve) => resolve(0))
    }    
  }  
}