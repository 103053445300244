import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IonContent, NavController, NavParams, PopoverController } from '@ionic/angular';
import { SitesService } from 'src/app/services/sites.service';
import { SettingInfoType } from '../status/status.page';

@Component({
  selector: 'app-edit-vent-setting',
  templateUrl: './edit-vent-setting.page.html',
  styleUrls: ['./edit-vent-setting.page.scss'],
})
export class EditVentSettingPage implements OnInit {
  @ViewChild('editVentSetting', { read: IonContent }) content: IonContent;
  public settingInfo: SettingInfoType;
  public note: string;
  public siteName: string;
  public houseName: string;
  public value: number;
  public option: string;
  public difTemp: number;
  public dayTemp: number;
  public niteTemp: number;
  public selected = 0;
  public comment: string;
  public canEdit: false;
  private isCelsius: false;
  public name;
  public vent = {
    mode: "",
    man: 0,
    step: 0,
    p1: 0,
    p2: 0,
    p3: 0,
    p4: 0,
    p5: 0,
    threshold: 0,
    openTime: 0,
    rainOR: 0,
    wind1OR: 0,
    wind2OR: 0
  }
  public inputInvalid: boolean = false;
  public invalidMessage: string = '';
  public validators = {
    temperatureValid: (temp) => { return parseInt(temp) >= 32 && parseInt(temp) <= 131; },
    percentValid: (percent) => { return parseInt(percent) >= 0 && parseInt(percent) <= 99; },
    secondsValid: (num) => { return parseInt(num) >= 0 && parseInt(num) <= 9999; },
    minutesValid: (num) => { return parseInt(num) >= 0 && parseInt(num) <= 9; },
    stepSize: (size) => { return parseInt(size) >= 0 && parseInt(size) <= 31; },
  }
  public ventForm: UntypedFormGroup;
  public isPending;
  public tUnits;
  public isClimateBoss;
  public manualOverride;

  constructor(public navCtrl: NavController,
    private navParams: NavParams,
    public popoverController: PopoverController,
    public sitesApiProvider: SitesService)
  {
    this.translateNavParams(navParams);
    this.tUnits = this.isCelsius ? '˚C' : '˚F';

    var str = (this.settingInfo && this.settingInfo.value) ? this.settingInfo.value.split(',',18) : [];
    this.manualOverride = str && str.length < 5;
    this.option = str[0];
    this.vent.mode = str[0];
    this.value = parseInt(str[1]);
    this.vent.man = parseInt(str[1]);
    this.vent.step = parseInt(str[2]);
    this.vent.p1 = parseInt(str[3]);
    this.vent.p2 = parseInt(str[4]);
    this.vent.p3 = parseInt(str[5]);
    this.vent.p4 = parseInt(str[6]);
    this.vent.p5 = parseInt(str[7]);
    this.difTemp = parseInt(str[8]);
    this.dayTemp = parseInt(str[9]);
    this.niteTemp = parseInt(str[10]);
    this.vent.threshold = parseInt(str[11]);
    this.vent.openTime = parseInt(str[12]);
    this.vent.rainOR = parseInt(str[13]);
    this.vent.wind1OR = parseInt(str[14]);
    this.vent.wind2OR = parseInt(str[15]);
    // console.log('option=' + this.option + ' value=' + this.value);

    let option = new UntypedFormControl(this.option, []);
    let mode = new UntypedFormControl(this.vent.mode, []);
    let value = new UntypedFormControl(this.value, []);
    let manual = new UntypedFormControl(this.vent.man, []);
    let step = new UntypedFormControl(this.vent.step, []);
    let p1 = new UntypedFormControl(this.vent.p1, []);
    let p2 = new UntypedFormControl(this.vent.p2, []);
    let p3 = new UntypedFormControl(this.vent.p3, []);
    let p4 = new UntypedFormControl(this.vent.p4, []);
    let p5 = new UntypedFormControl(this.vent.p5, []);
    let threshold = new UntypedFormControl(this.vent.threshold, []);
    let openTime = new UntypedFormControl(this.vent.openTime, []);
    let rainOR = new UntypedFormControl(this.vent.rainOR, []);
    let wind1OR = new UntypedFormControl(this.vent.wind1OR, []);
    let wind2OR = new UntypedFormControl(this.vent.wind2OR, []);
    let comment = new UntypedFormControl((this.manualOverride ? 'Manual override of vents' : ''), []);

    this.ventForm = new UntypedFormGroup({
      mode: mode,
      manual: manual,
      option: option,
      value: value,
      step: step,
      p1: p1,
      p2: p2,
      p3: p3,
      p4: p4,
      p5: p5,
      threshold: threshold,
      openTime: openTime,
      rainOR: rainOR,
      wind1OR: wind1OR,
      wind2OR: wind2OR,
      overrideDuration: new UntypedFormControl('0:00'),
      resetAfterOverride: new UntypedFormControl(false),
      comment: comment
    });
  }

  translateNavParams(navParams) {
    if(navParams && navParams.data) {
      this.settingInfo = navParams.data['settingInfo'];
      this.siteName = navParams.data['siteName'];
      this.houseName = navParams.data['houseName'];
      this.canEdit = navParams.data['canEdit'];
      this.isPending = navParams.data['isPending'];
      this.isCelsius = navParams.data['isCelsius'];
      this.isClimateBoss = navParams.data["isClimateBoss"];
      this.note = navParams.data["note"];
    } else if(navParams) {
      this.settingInfo = navParams.get('settingInfo');
      this.siteName = navParams.get('siteName');
      this.houseName = navParams.get('houseName');
      this.canEdit = navParams.get('canEdit');
      this.isPending = navParams.get('isPending');
      this.isCelsius = navParams.get('isCelsius');
      this.isClimateBoss = navParams.get("isClimateBoss");
      this.note = navParams.get('note');
    }
  }

  public scroll() {
    if(this.content) { this.content.scrollToBottom(); }
  }

  public cancel(){
    var returnData = {
      button: "Cancel",
      value: null,
      overrideDuration: null
    };
    this.popoverController.dismiss(returnData);
  }

  public save(){
    const form = this.ventForm.value;
    var returnData = {
      button: "Save",
      value: {comment: form.comment, value: null, overrideDuration: null}
    };

    //determine which data to return based on type
    if (this.settingInfo && this.settingInfo.type == "vent"){
      this.vent.mode = form.option;
      returnData.value.value = form.option;
      returnData.value.value += "," + form.manual.toString();
      returnData.value.value += "," + form.step.toString();
      returnData.value.value += "," + form.p1.toString();
      returnData.value.value += "," + form.p2.toString();
      returnData.value.value += "," + form.p3.toString();
      returnData.value.value += "," + form.p4.toString();
      returnData.value.value += "," + form.p5.toString();
      if(this.isClimateBoss) {
        returnData.value.value += "," + form.threshold.toString();
        returnData.value.value += "," + form.openTime.toString();

        var overrideVal = (orVal) => { return (orVal === 1 || orVal) ? "1" : "0"; }
        returnData.value.value += "," + overrideVal(form.rainOR);
        returnData.value.value += "," + overrideVal(form.wind1OR);
        returnData.value.value += "," + overrideVal(form.wind2OR);
      }
    } else if (this.settingInfo && this.settingInfo.type == "vo"){
      //proportional output setting
      if (form.option == "MAN"){
        returnData.value.value = form.value;
      } else {
        returnData.value.value = form.option;
      }
    } else {
      returnData.value.value =  form.value;
      if(form.overrideDuration && this.manualOverride) returnData.value.overrideDuration = form.overrideDuration;
    }

    this.popoverController.dismiss(returnData);
  }

  public change(field, val) {
    var valueToSet = {}
    var form = this.ventForm.value
    valueToSet[field] = parseInt(form[field]) + val;
    this.ventForm.patchValue(valueToSet);
  }

  public validateValue(event, validator, message) {
    this.inputInvalid = !validator(event.target.value)
    if(this.inputInvalid) {
      this.invalidMessage = message
    }
  }

  ngOnInit() {}

  public showPercents(option, vent_number?) {
    return ["AUTO", "5-STEP", "PID", "ENRG"].includes(option) && (option === "PID" ? !vent_number : true)
  }

  public isPid() {
    return this.ventForm.value.option === "PID";
  }
}
