<div class="pending" *ngIf="isPending">
  Currently syncing with the controller
</div>

<h6>Editing Timer {{timer_index}}</h6>
<form [formGroup]="timerEditForm">
  <div>
    <div>
      <label>{{key}}</label>
      <p>{{current_value}}</p>
    </div>
    <input
      formControlName="value"
      inputmode="tel"
      select-all
      type="text" required
      pattern="[0-9]{1,2}:[0-9]{2}"
      (mouseEnter)="clearValue()"
      (keyup)="reformatTime($event)"
    />
  </div>

  <div *ngIf="canEdit">
    <div>
      <label>Comment<sup>*</sup></label>
      <input
        class="comment"
        formControlName="comment"
        [disabled]='!canEdit'
      />
    </div>
  </div>
  <p *ngIf="canEdit"><sup>*</sup>Add a message about this change</p>

  <div class="buttons saveOrCancel">
    <ion-button color="secondary" (click)="cancel()">
      Cancel
    </ion-button>
    <ion-button [disabled]="!canEdit || !timerEditForm.valid" color="primary" (click)="save()">
      Save
    </ion-button>
  </div>
  <div *ngIf="!canEdit" class="save-disabled"><i>Save disabled; You don't have edit permissions.</i></div>
  <div *ngIf="inputInvalid" class="save-disabled"><i>Save disabled; Please fix errors before saving.</i></div>
</form>
