import * as d3 from 'd3';
import * as d3Array from 'd3-array';
import * as d3Axis from 'd3-axis';
import * as d3Scale from 'd3-scale';
import { select, selectAll } from "d3-selection";
import * as d3Shape from 'd3-shape';
import * as d3TimeFormat from 'd3-time-format';

let remove = function(chartId = "#timer-graph") {
    d3.select(chartId).selectAll("svg").remove();
}

let hasValues = function(start, run) {
    return (start !== '0:00' || run !== '0:00');
}

let graph = function(chartId, width = 600, height = 85, values) {
    remove(chartId);
    let chart = select(chartId)
        .append('svg')
        .attr('id', `${chartId}-svg`)
        .attr("viewBox", `0 -10 ${width + 40} ${height + 40}`);

    var today = new Date().setHours(0,0,0,0);

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0,0,0,0);
    
    // Set the ranges
    var x = d3Scale.scaleTime()
        .domain([today, tomorrow])
        .range([5, width]);
    var y = d3Scale.scaleBand()
        .range([0, height])
        .domain(["A", "B"])

    chart.append('g')
        .attr('class', 'axis axis--x')
        .attr('transform', 'translate(' + 5 + ',' + (height - 25) + ')')
        // tickValues to include dif/day/nite start/stop
        .call(d3Axis.axisBottom(x)
            .ticks(24)
            .tickFormat(d3TimeFormat.timeFormat("%H:%M")))
            .attr('font-size', '10px')
        .selectAll("text")
            .attr("transform", "rotate(65)")
            .attr("dx", "1em")
            .attr("dy", "-.5em")
            .style("text-anchor", "start");

    if(hasValues(values.stage_a_start_time, values.stage_a_run_time)) {
        chart.append("g")    
            .attr("fill", '#955ca5')
            .selectAll("rect")
            .data([{stage: "A", start: values.stage_a_start_time, run: values.stage_a_run_time}])    
            .join("rect")
            .attr("x", i => x(new Date().setHours(parseInt(i.start.split(":")[0]))))
            .attr("y", i => y(i.stage))
            .attr("width", i => {
                let start = parseInt(i.start.split(":")[0])
                let end = start + parseInt(i.run.split(":")[0])
                let endDate = new Date().setHours(end, 0, 0, 0)
                return x(endDate) - x(new Date().setHours(start, 0, 0, 0))
            })
            .attr("height", "1em");
    }

    if(hasValues(values.stage_b_start_time, values.stage_b_run_time)) {
        chart.append("g")    
            .attr("fill", '#800000')
            .selectAll("rect")
            .data([Object.assign({}, {stage: "B", start: values.stage_b_start_time, run: values.stage_b_run_time})])    
            .join("rect")
            .attr("x", i => x(new Date().setHours(parseInt(i.start.split(":")[0]))))
            .attr("y", i => y(i.stage))
            .attr("width", i => {
                let start = parseInt(i.start.split(":")[0])
                let end = start + parseInt(i.run.split(":")[0])
                let endDate = new Date().setHours(end, 0, 0, 0)
                return x(endDate) - x(new Date().setHours(start, 0, 0, 0))
            })
            .attr("height", "1em");
    }
}

export const TimerGraphGenerator = {
    graph,
    remove
}