import { Component, OnInit, Input, ViewChild, ElementRef, ViewEncapsulation, Output, EventEmitter, OnChanges, ChangeDetectorRef } from '@angular/core';
import { AlertController, NavController, NavParams, ToastController, PopoverController, Platform } from '@ionic/angular';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EnglishMetricFormat } from '../status-wind-rain/englishMetricConversion'
import { SitesService } from 'src/app/services/sites.service';
import { KeyTranslations } from '../audit-trail-inner/key-translations';
import moment from 'moment';

import { LineChartGenerator } from './line_chart_generator';

@Component({
  selector: 'app-audit-trail-report',
  templateUrl: './audit-trail-report.component.html',
  styleUrls: ['./audit-trail-report.component.scss'],
})
export class AuditTrailReportComponent implements OnInit {
  @Input() showSection;
  public changes = [];
  private keyTranslations;
  private timeBossKeys;
  private keysToConvertToMph = ["windActSp", "windDropSp"];
  private keysToConvertToCelcius = ["dhLoTemp", "dhHiTemp"];
  private english = true;
  public selectedDate;
  private filteredChanges;
  public selectedType = 'site-change';
  public selectedUser = undefined;
  public summaries = {
    'created-user': 0,
    'request-log': 0,
    'site-change': 0,
    'push-notification': 0
  }

  public top_change_requests = {}

  constructor(
    public navCtrl: NavController,
    public alertCtrl: AlertController,
    public controllersService: SitesService,
    private ref: ChangeDetectorRef,
    private toastCtrl: ToastController
  ) {
    this.selectedDate = moment().format('YYYY-MM-DD');
    this.keyTranslations = KeyTranslations.getTranlations();
    this.timeBossKeys = KeyTranslations.timeBossKeys();
  }

  ngOnInit() {
    if(this.showSection) {
      if(this.selectedDate) this.controllersService.getAdminAuditTrailReport(this.selectedDate).then((data) => {this.adminAuditTrailReport(data)});
      this.controllersService.getAdminAuditSnapshot().then((data) => { this.showSnapshotCharts(data) });
    }
  }

  ngOnChanges() {
    if(this.showSection) {
      if(this.selectedDate) this.controllersService.getAdminAuditTrailReport(this.selectedDate).then((data) => {this.adminAuditTrailReport(data)});
      this.controllersService.getAdminAuditSnapshot().then((data) => { this.showSnapshotCharts(data) });
    }
  }

  adjustProgramValue(setting) {
    var splitProgramValue = setting.split(",")
    var adjustedVal = `DIF start ${splitProgramValue[0]} with temp ${splitProgramValue[1]}. ` +
           `DAY start ${splitProgramValue[2]} with temp ${splitProgramValue[3]}. ` +
           `NITE start ${splitProgramValue[4]} with temp ${splitProgramValue[5]}.`
   return adjustedVal;
  }

  dehumidificationSetupValue(setting) {
    var inBits = (parseInt(setting)).toString(2)
    var updatedSettings = inBits.padStart(8, "0");
    var splitSettings = updatedSettings.split("").map((settingOn) => {return settingOn === "0" ? "OFF" : "ON" });

    return `Cool1 ${splitSettings[7]}, Cool2 ${splitSettings[6]}, Cool3 ${splitSettings[5]}, Cool4 ${splitSettings[4]}, ` +
           `Heat2 ${splitSettings[3]}, Heat1 ${splitSettings[2]}, Percent1 ${splitSettings[1]}, EnableHeat ${splitSettings[0]}`;
  }

  ventModeValue(setting) {
    var splitVal = setting.split(",")
    return `Set to ${splitVal[0]} with a step size of ${splitVal[1]}. ` +
           `Open Percentage Settings - Step 1: ${splitVal[2]}%, Step 2: ${splitVal[3]}%, ` +
           `Step 3: ${splitVal[4]}%, Step 4: ${splitVal[5]}%, Step 5: ${splitVal[6]}%.`
  }

  updateChanges() {
    this.summaries = {
      'created-user': 0,
      'request-log': 0,
      'site-change': 0,
      'push-notification': 0
    };
    this.top_change_requests = {};

    if(this.selectedDate) {
      this.controllersService.getAdminAuditTrailReport(this.selectedDate).then((data) => { this.showSnapshotCharts(data) })
    }
  }

  filter() {
    if(this.selectedType === 'NONE' && !this.selectedUser) {
      this.selectedType = undefined;
      this.filteredChanges = undefined;
    } else if (this.selectedUser && this.selectedType === 'request-log') {
      this.filteredChanges = this.changes.filter((change) => change.type === this.selectedType && (this.selectedUser && change.email === this.selectedUser))
    } else {
      this.filteredChanges = this.changes.filter((change) => (this.selectedType && change.type === this.selectedType) || (this.selectedUser && change.email === this.selectedUser))
    }
  }

  getChanges() {
    return this.filteredChanges ? this.filteredChanges : this.changes;
  }

  getChangeRequestsGrouped() {
    return Object.keys(this.top_change_requests);
  }

  selectUser(user) {
    this.selectedUser = user;
    this.filter();
  }

  showSnapshotCharts(data) {
    let keyToLabel = {
      users: "Users Added",
      userChanges: "Setting Changes",
      pushNotifications: "Push Notifications",
      siteChanges: "Site Changes"
    }

    Object.keys(data).forEach((key) => {
      var {svg, g} = LineChartGenerator.init(`#snapshot-${key}`, '125%', '100%', keyToLabel[key]);
      var {x, y} = LineChartGenerator.initAxes(data[key], svg, g);
      LineChartGenerator.drawAxes(g, x, y);
      LineChartGenerator.drawChart(data[key], g, x, y);
    })
  }

  private adminAuditTrailReport(data) {
    this.changes = data;
    this.filter();
    data.forEach((d) => {
      this.summaries[d.type] = this.summaries[d.type] + 1;
      if(d.type === 'request-log') {
        if(!this.top_change_requests[d.email]) {
          this.top_change_requests[d.email] = { count: 1, key: d.change }
        } else {
          this.top_change_requests[d.email].count = this.top_change_requests[d.email].count + 1;
        }
      }
    })
  }
}
