<div class="tb-node-name" (click)="selectNode(node.node_id)" [class.dashboard]="asDashboard">
  <div [class.dashboard-name]="asDashboard">
    <div>
      <h1 *ngIf="node">{{node.api_name || node.node_name}}
        <ion-spinner *ngIf="node.reqs && node.reqs.length > 0" color="secondary" name="bubbles"></ion-spinner>
      </h1>
    </div>
    <div class="model">
      <div class="controller_name" *ngIf="!asDashboard && node">{{node.model}}</div>
      <span *ngIf="node && !node.in_use">&nbsp;|&nbsp;NOT IN USE</span>
    </div>
  </div>
  <div class="icons" *ngIf="!asDashboard">
    <div *ngIf="node && !node.online" class="offline">
      <ion-icon class="warning" color="danger" name="eye-off-outline"></ion-icon>
      <p>OFF</p>
    </div>
    <ion-icon class="go-to-settings" name='arrow-forward-outline'></ion-icon>
  </div>
</div>
<div *ngIf="asDashboard" class="dashboard-timer-info">
  <div *ngFor="let tmr of timerInfo; index as i"
    [class.on]="tmr.on" [style.width]="getWidthPercent()">
    <span>{{i + 1}}</span>
    <span>
      <ion-icon *ngIf="tmr.on" name="{{tmr.icon_name}}"></ion-icon>
      <ion-icon *ngIf="!tmr.on" name="{{tmr.icon_name}}"></ion-icon>
    </span>
  </div>
</div>
