import moment from 'moment';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SiteService } from '../services/site.service';
import { CisProvider } from '../services/cis.service';

@Component({
  selector: 'app-high-low-report-admin',
  templateUrl: './high-low-report-admin.component.html',
  styleUrls: ['./high-low-report-admin.component.scss'],
})
export class HighLowReportAdminComponent  implements OnInit {
  @Input() showSection;
  @Input() site;
  public dates = [];
  public sites = [];
  public emails = [];

  public reportForm: UntypedFormGroup;

  constructor(private siteService: SiteService,
    private cisService: CisProvider) { }

  ngOnInit() {
    this.reportForm = new UntypedFormGroup({
      date: new UntypedFormControl('', []),
      site: new UntypedFormControl(this.site && this.site.cis_id, []),
      email: new UntypedFormControl('', [])
    });

    this.cisService.getCisList().then((data) => {
      this.sites = data.sort((da, db) => {
        if(da.name > db.name) { return 1; } else { return -1; }
      });
    });
    this.dates = this.getDates(moment().subtract(2, 'weeks').toDate(), moment().toDate())
  }

  // Returns an array of dates between the two dates
  getDates (startDate, endDate) {
    const dates = []
    let currentDate = startDate
    const addDays = function (days) {
      const date = new Date(this.valueOf())
      date.setDate(date.getDate() + days)
      return date
    }
    while (currentDate <= endDate) {
      dates.push(currentDate)
      currentDate = addDays.call(currentDate, 1)
    }
    return dates
  }

  submit() {
    let value = this.reportForm.value
    this.siteService.testHighLowReport(value.site, value.email, value.date)
      .then((response) => {
        console.log(`high low response`)
        console.log(response);
      })
      .catch((e) => {
        console.log(`high low report error`)
        console.log(e)
      })
  }
}
