import { Component, OnInit, Input, ViewChild, ElementRef, ViewEncapsulation, Output, EventEmitter, OnChanges, ChangeDetectorRef } from '@angular/core';
import { AlertController, NavController,
  NavParams, ToastController, PopoverController, Platform } from '@ionic/angular';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SitesService } from 'src/app/services/sites.service';

@Component({
  selector: 'app-unclaimed-controllers',
  templateUrl: './unclaimed-controllers.component.html',
  styleUrls: ['./unclaimed-controllers.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UnclaimedControllersComponent implements OnInit {
  @Input() showSection;
  public controllers = [];
  public filteredControllers = [];
  public searchForm: UntypedFormGroup;
  @ViewChild('searchInputId') searchInputId: ElementRef;

  constructor(
    public navCtrl: NavController,
    public alertCtrl: AlertController,
    public controllersService: SitesService,
    private ref: ChangeDetectorRef,
    private toastCtrl: ToastController
  ) { }

  ngOnInit() {
    this.searchForm = new UntypedFormGroup({
      searchTerm: new UntypedFormControl('')
    });
    this.ref.detectChanges();
    if(this.searchInputId) { this.searchInputId.nativeElement.focus(); }
  }

  async alert(msg) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 4000,
      position: 'top'
    });

    toast.present();
  }

  public filter(values) {
    let filterCallback = () => {
      let filterRegex = new RegExp(values.searchTerm && values.searchTerm.toLowerCase());
      this.filteredControllers = this.controllers.filter((u) => {
        return u && (filterRegex.test(u.node_id && u.node_id.toLowerCase()) || filterRegex.test(u.model && u.model.toLowerCase()) || (values.searchTerm === 'online' && u.online));
      })
    }
    
    if(!this.controllers || (this.controllers && this.controllers.length === 0)) {
       this.getFromApi(filterCallback);
    } else {
      filterCallback();
    }    
  }

  public async getFromApi(callback = null) {
    try {
      let response = await this.controllersService.getUnclaimedNodes()
      this.controllers = response.nodes;
      callback && callback()
    } catch(e) {
      this.alert("There was an error getting the unclaimed nodes.")
    }
  }

  public getControllers() {
    return this.filteredControllers && this.filteredControllers.length > 0 ? this.filteredControllers : this.controllers;
  }

  public cancelSearch() {
    this.searchForm = new UntypedFormGroup({
      searchTerm: new UntypedFormControl('')
    });
    this.filteredControllers = [];
  }
}
