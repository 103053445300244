import { Component, OnInit, Input } from '@angular/core';
import { NavController, NavParams } from '@ionic/angular';
import { SitesService } from 'src/app/services/sites.service';
import { EnglishMetricFormat } from '../status-wind-rain/englishMetricConversion'
import moment from 'moment';
import { NavigationExtras, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-audit-trail-inner',
  templateUrl: './audit-trail-inner.component.html',
  styleUrls: ['./audit-trail-inner.component.scss'],
})
export class AuditTrailInnerComponent implements OnInit {
  public nodeChanges;
  public filteredChanges;
  private keyTranslations = {
    'rem': "Reset Min/Max",
    'alarm1h': "Zone 1: Alarm High",
    'alarm1l': "Zone 1: Alarm Low",
    'alarm2h': "Zone 2: Alarm High",
    'alarm2l': "Zone 2: Alarm Low",
    'sp1': "Zone 1: Set Point",
    'sp2': "Zone 2: Set Point",
    'sp3': "Heat 3: Set Point",
    'c1m': "Cool 1",
    'c2m': "Cool 2",
    'c3m': "Cool 3",
    'c4m': "Cool 4",
    'vent1': "Vent 1",
    'vent2': "Vent 2",
    'vent4': "Vent D",
    'vent3': "Vent C",
    'h1m': "Heat 1",
    'h2m': "Heat 2",
    'h3m': "Heat 3",
    'prog1': "Zone 1: Adjusted Program",
    'prog2': "Zone 2: Adjusted Program",
    'humsp': "Humidity Set Point",
    "dhSp": "Dehumidification Set Point",
    "dhNight": "Dehumidification, NITE",
    "dhDay": "Dehumidification, DAY",
    "dhDif": "Dehumidification, DIF",
    "dhLoTemp": "Dehumidification Low Temp",
    "dhHiTemp": "Dehumidification Low Temp",
    "dhRepeat": "Dehumidification Repeat",
    "dhFan2": "Dehumid. Fan 2 Cycle",
    "dhFan1": "Dehumid. Fan 2 Cycle",
    "dhHeat": "Dehumid. Heat Time",
    "dhStartTime": "Dehumid. Start Time",
    "dhVentMode": "Dehumidification Vent Mode",
    "dhStart": {
      "0": "Dehumidification Stop",
      "1": "Dehumidification Start"
    },
    "dhSetup": "Dehumidification Setup",
    "timerStart": {
      "0": "Timer Stop",
      "1": "Timer Start"
    },
    "timerStartTime": "Timer: Start Time",
    "timerHoldTime": "Timer: Hold Time",
    "timerRepeat": "Timer: Repeat",
    "mistDifCycleMin": "Mister: DIF Cycle",
    "mistDifOnSec": "Mister: DIF On",
    "mistDayCycleMin": "Mister: DAY Cycle",
    "mistDayOnSec": "Mister: DAY On",
    "mistNightCycleMin": "Mister: NITE Cycle",
    "mistNightOnSec": "Mister: NITE On",
    "cancel": "Wind/Rain Alarm: Cancel",
    "activate": "Wind/Rain Alarm: Activate",
    "windActSp": "Wind/Rain Alarm: Activation Set Point",
    "windActTime": "Wind/Rain Alarm: Activation Time",
    "windDropSp": "Wind/Rain Alarm: Drop Off Set Point",
    "windDropTime": "Wind/Rain Alarm: Activation Time",
    "windActSpH1": "WeatherBoss Alarm 1: High Activation Speed",
    "windActSpL1": "WeatherBoss Alarm 1: Low Activation Speed",
    "windActSpH2": "WeatherBoss Alarm 2: High Activation Speed",
    "windActSpL2": "WeatherBoss Alarm 2: Low Activation Speed",
    "windDir1": "Weather Boss Alarm 1: Cardinal & Intermediate Directions",
    "windDir2": "Weather Boss Alarm 2: Cardinal & Intermediate Directions",
    "thermistor_fail": "Thermistor Fail On/Off",
    "controller_time": "Controller Time Change",
    "shadePause": "Shade Pause Time",
    "shadeEnable": "Shade Enabled",
    "shadeSlowRetract": "Shade Slow Retract",
    "shadeOpenTime": "Shade Open Time",
    "shadeStep": "Shade Step",
    "shadeTempSP": "Shade Temperature Set Point",
    'alarm1l_float':"Heat 1 Low Floating Alarm",
    'alarm2l_float' : "Heat 2 Low Floating Alarm",
    'alarm3h': "Heat 3 High Alarm",
    'alarm3l': "Heat 3 Low Alarm"
  };
  public keysToConvertToMph = ["windActSp", "windDropSp"];
  public keysToConvertToCelcius = ["dhLoTemp", "dhHiTemp"];

  public timeBossKeys = {
    startTimeA: "Start Time (A)",
    startTimeB: "Start Time (B)",
    holdTimeA: "Hold Time (A)",
    holdTimeB: "Hold Time (B)",
    abort: "Aborted",
    enable: "Enabled",
    startTime: "Start Time",
    durationB: "Duration B",
    durationA: "Duration A",
    DurationB: "Duration B",
    DurationA: "Duration A"
  }

  public climateBossVentKeys = {

  }
  public dateOptions = ["View All"];
  public settingKeys = ["View All"];
  public searchSettingKey;
  public searchDate;
  @Input() node_id: any;
  @Input() english: boolean = true;

  constructor(
    public navCtrl: NavController,
    public sitesApiProvider: SitesService,
    public route: ActivatedRoute
  ) {
    var days = Array.from(new Array(14), (x,i) => i);
    this.dateOptions = this.dateOptions.concat(days.map((day) => {
      return moment().subtract(day, 'days').format("MM-DD-YYYY")}
    ));
  }

  ngOnInit() {
    if(this.node_id) {
      this.sitesApiProvider.getNodeAuditTrail(this.node_id)
                           .then((data) => {
                             this.nodeChanges = data.map((data) => {
                               data.showing = false;
                               if(this.keysToConvertToMph.includes(data.key) && this.english) {
                                 data.value = EnglishMetricFormat.convertToMph(data.value);
                               }

                               if(data.key.startsWith('tbTimer') || data.key.startsWith("tbMister")) {
                                 // console.log(`timer key: ${data.key}`)
                                 var timer_keys = data.key.split(".")

                                 let tmr_temp;
                                 if(data.key.startsWith('tbTimer')) {
                                   tmr_temp = timer_keys[0].replace('tbTimer', "Timer ")
                                 } else {
                                   tmr_temp = `${timer_keys[0].replace('tbMister', "Timer ")} (Mister Mode)`
                                 }

                                 var timer = tmr_temp.replace("[", '')
                                 timer = tmr_temp.replace("]", "")
                                 var setting = this.timeBossKeys.hasOwnProperty(timer_keys[1]) ? this.timeBossKeys[timer_keys[1]] : (timer_keys[1] || '')
                                 data.key = `${timer} ${setting}`
                                 // console.log(data.key)
                               } else {
                                 if(this.keysToConvertToCelcius.includes(data.key) && !this.english) {
                                   console.log('audit trail convert')
                                   data.value = EnglishMetricFormat.convertToCelsius(data.value);
                                 }

                                 if(["prog1", "prog2"].includes(data.key)) { data.value = this.adjustProgramValue(data.value);}
                                 if(data.key === 'dhSetup') { data.value = this.dehumidificationSetupValue(data.value); }
                                 if(["vent1", "vent2"].includes(data.key)) { data.value = this.ventModeValue(data.value);}

                                 if(!["timerStart", "dhStart"].includes(data.key)) {
                                   data.key = this.keyTranslations.hasOwnProperty(data.key) ? this.keyTranslations[data.key] : `${data.key}`;
                                 } else {
                                   data.key = this.keyTranslations[data.key] ? this.keyTranslations[data.key][data.value] : `${data.key}`;
                                 }
                               }

                               return data;
                             });
                             this.settingKeys = this.settingKeys.concat(Array.from(new Set(this.nodeChanges.map((change) => {return change.key}))));
                           })
    }
  }

  filterItems(updatedSearchDate) {
    this.searchSettingKey = '';
    let filterBy = this.searchDate;
    var searchDateStart = moment(filterBy, "MM-DD-YYYY").startOf('day');

    if(filterBy === 'View All') {
      this.filteredChanges = this.nodeChanges;
    } else {
      this.filteredChanges = this.nodeChanges.filter(item => {
        return moment(item.date, "MM-DD-YYYY HH:mm").startOf('day').isSame(searchDateStart);
      });
    }
  }

  filterItemsByKey(searchKey) {
    this.searchDate = '';
    let filterBy = this.searchSettingKey;
    if(filterBy === 'View All') {
      this.filteredChanges = this.nodeChanges;
    } else {
      this.filteredChanges = this.nodeChanges.filter(item => {
        return item.key === filterBy;
      });
    }
  }

  changes() {
    return this.filteredChanges ? this.filteredChanges : this.nodeChanges;
  }

  hasChanges() {
    return this.changes() && this.changes().length > 0
  }

  header() {
    var searchDateString = ` for ${this.searchDate}`
    return `Changes${this.searchDate ? searchDateString : ''}`
  }

  adjustProgramValue(setting) {
    var splitProgramValue = setting.split(",")
    var adjustedVal = `DIF start ${splitProgramValue[0]} with temp ${splitProgramValue[1]}. ` +
           `DAY start ${splitProgramValue[2]} with temp ${splitProgramValue[3]}. ` +
           `NITE start ${splitProgramValue[4]} with temp ${splitProgramValue[5]}.`
   return adjustedVal;
  }

  dehumidificationSetupValue(setting) {
    var inBits = (parseInt(setting)).toString(2)
    var updatedSettings = inBits.padStart(8, "0");
    var splitSettings = updatedSettings.split("").map((settingOn) => {return settingOn === "0" ? "OFF" : "ON" });

    return `Cool1 ${splitSettings[7]}, Cool2 ${splitSettings[6]}, Cool3 ${splitSettings[5]}, Cool4 ${splitSettings[4]}, ` +
           `Heat2 ${splitSettings[3]}, Heat1 ${splitSettings[2]}, Percent1 ${splitSettings[1]}, EnableHeat ${splitSettings[0]}`;
  }

  ventModeValue(setting) {
    var splitVal = setting.split(",")
    return `Set to ${splitVal[0]} with a step size of ${splitVal[1]}. ` +
           `Open Percentage Settings - Step 1: ${splitVal[2]}%, Step 2: ${splitVal[3]}%, ` +
           `Step 3: ${splitVal[4]}%, Step 4: ${splitVal[5]}%, Step 5: ${splitVal[6]}%.`
  }
}
