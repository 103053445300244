<div id="controller-users-title">
  <h6>Users With Access to {{node.api_name || node.node_name}}</h6>
</div>
<ul id="controller-users-list">
  <li *ngFor ="let user of userList">
    <span class="email">
      <p>{{user.email}}
        <span class="current" *ngIf="current === user.email">&nbsp;(you)</span>
      </p>
    </span>
    <span class="role" *ngIf="editing !== user.email">{{getRole(user.role)}}</span>
    <select *ngIf="editing === user.email" [(ngModel)]="updated_role">
      <option *ngFor="let roleOption of allRoles()" [value]="roleOption.val">{{roleOption.role_name}}
      </option>
    </select>
    <div *ngIf="editing !== user.email">
      <button (click)="editing = user.email"
        [disabled]="!auth || (user.role === 4) || editing || adding_user">Edit</button>
      <button (click)="removeUser(user)" class="remove"
        [disabled]="!auth || (user.role === 4) || editing || adding_user || user.site_user">Remove</button>
    </div>
    <div *ngIf="editing === user.email">
      <button (click)="saveRoleChange(user, updated_role)" type="submit">Save</button>
      <button (click)="editing = undefined">Cancel</button>
    </div>
    <div *ngIf="userIsAdmin" class="user-alerts-configuration">
      <div *ngIf="user.alerts">
        {{getAlerts(user.alerts)}}
      </div>
      <div *ngIf="!user.alerts">User has no alerts configured for {{node.api_name || node.node_name}}.</div>
    </div>
  </li>
  <li *ngIf="adding_user && new_user">
    <span class="email">
      <input placeholder="Enter new user email"
        type="text" [(ngModel)]="new_user.email"/>
    </span>
    <select [(ngModel)]="new_user.role">
      <option *ngFor="let roleOption of allRoles()" [value]="roleOption.val">{{roleOption.role_name}}
      </option>
    </select>
    <div>
      <button class="users-button" (click)="saveNewUser()" *ngIf="auth && adding_user">
        <div class ="users-value">
          <ion-icon name="save-outline" class="save-icon" slot="end"></ion-icon>
          Save
        </div>
      </button>
      <button (click)="adding_user = false; new_user = undefined;">Cancel</button>
    </div>
  </li>

  <button class="users-button" (click)="addUser()" *ngIf="auth && !adding_user">
    <div class ="users-value">
      <ion-icon name="person-add-outline" slot="end"></ion-icon>
      Add A User
    </div>
  </button>
</ul>
