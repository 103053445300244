<div class="show-hide-timer" *ngIf="smallScreen">
  <label>Clock: {{clock}}</label>
</div>
<ion-grid *ngIf="t" class="timer-grid" [class.pendingSettings]="hasPendingChanges()">
  <ion-row class="title-row">
    <ion-col class="title">
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-row *ngIf="!notitle">
              {{name(i)}}
              <ion-icon *ngIf="!t.isMister && !t.isShade" name="alarm" alt="Timer Mode"></ion-icon>
              <ion-icon *ngIf="t.isMister && !t.isShade" name="rainy" alt="Mister Mode"></ion-icon>
              <ion-icon *ngIf="!t.isMister && t.isShade" name="cloud" alt="Shade Mode"></ion-icon>
              <ion-spinner *ngIf="t.pending || t.mister_pending" color="secondary" name="bubbles"></ion-spinner>
            </ion-row>
            <ion-row *ngIf="t.enable && !notitle">Mode: {{t.isMister ? 'Mister' : (t.isShade ? 'Shade' : 'Timer')}}</ion-row>
            <ion-row *ngIf="t && !smallScreen" class="enable-toggle" 
              [class.notitle]="notitle" [class.pending]="(pendingSettings && pendingSettings.enable)"
              [class.ion-justify-content-start]="!smallScreen"
              [class.ion-justify-content-end]="smallScreen"
            >
              <p>Enable</p>
              <ion-toggle [disabled]="!canEdit || (pendingSettings && pendingSettings.enable)" [checked]="t.enable" class="clickable" (click)="enableOrAbort(i, 'enable')"></ion-toggle>
              <!-- <ion-toggle name="in_use" [(ngModel)]="t.enable" [checked]="t.enable"></ion-toggle> -->
            </ion-row>
            <ion-row class="buttons" *ngIf="t && !smallScreen"
              [class.ion-justify-content-start]="!smallScreen"
              [class.ion-justify-content-end]="smallScreen"
            >
              <button [disabled]="!canEdit" class="primary" [class.pending]="(pendingSettings && pendingSettings.manualStart)"
                *ngIf="(t.isShade) ? false : (t.enable && !t.manualActive && !t.isMister)"
                (click)="editTimerSetting(i, 'start')">
                Manual Start
              </button>
              <button [disabled]="!canEdit" [class.pending]="(pendingSettings && pendingSettings.stopped)"
                *ngIf="(t.isShade) ? false : (t.enable && t.manualActive && !t.isMister)"
                (click)="stop(i)"
                class="clickable">
                End
              </button>
              <button *ngIf="t && t.on && t.enable && !t.abort && !t.isMister"
                class="danger clickable" [class.pending]="(pendingSettings && pendingSettings.abort)"
                (click)="enableOrAbort(i, 'abort')" [disabled]="!canEdit">
                Abort
              </button>
              <button *ngIf="t && t.enable && t.abort && !t.isMister"
                class="clickable resume" [class.pending]="(pendingSettings && pendingSettings.resume)"
                (click)="enableOrAbort(i, 'abort')" [disabled]="!canEdit">
                Resume
              </button>
            </ion-row>
          </ion-col>
          <ion-col class="time-remaining ion-justify-content-end">
            <ion-row class="ion-justify-content-end">
              <ion-icon *ngIf="t && t.on" name="alarm" color="secondary"></ion-icon>
              <span *ngIf="t && t.on" class="timer-remaining-container">
                <span class="time-remaining-title">Time Remaining</span>
                <p>{{t.timeRemaining}}</p>
                <span class="small-text current-stage" *ngIf="currentStage && smallScreen"
                  [class.stageA]="currentStage === 'stageA'"
                  [class.stageB]="currentStage === 'stageB'">
                  {{currentStage === 'stageA' ? 'Stage A' : 'Stage B'}}</span>
              </span>
              <span *ngIf="t && !t.on && !t.isMister">0:00</span>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-col>  
    <ion-col *ngIf="(t && smallScreen)">
      <ion-grid>
        <ion-row class="enable-toggle" 
          [class.ion-justify-content-start]="!smallScreen"
          [class.ion-justify-content-end]="smallScreen"
          [class.pending]="(pendingSettings && pendingSettings.enable)">
          <p>Enable</p>
          <div><ion-toggle [disabled]="!canEdit" [checked]="t.enable" class="clickable" (click)="enableOrAbort(i, 'enable')"></ion-toggle></div>
          <!-- <ion-toggle name="in_use" [(ngModel)]="t.enable" [checked]="t.enable"></ion-toggle> -->
        </ion-row>
        <ion-row class="buttons" *ngIf="t.enable && (t.on || t.enable || t.abort) && (!t.isMister && !t.isShade)"
          [class.ion-justify-content-start]="!smallScreen"
          [class.ion-justify-content-end]="smallScreen">
          <button [disabled]="!canEdit" class="primary" [class.pending]="(pendingSettings && pendingSettings.manualStart)"
            *ngIf="(t.isShade) ? false : (t.enable && !t.manualActive && !t.isMister)"
            (click)="editTimerSetting(i, 'start')">
            Manual Start
          </button>
          <button [disabled]="!canEdit" [class.pending]="(pendingSettings && pendingSettings.stop)"
            *ngIf="(t.isShade) ? false : (t.enable && t.manualActive && !t.isMister)"
            (click)="stop(i)"
            class="clickable">
            End
          </button>
          <button *ngIf="t && t.on && t.enable && !t.abort && !t.isMister"
            class="danger clickable" [class.pending]="(pendingSettings && pendingSettings.abort)"
            (click)="enableOrAbort(i, 'abort')" [disabled]="!canEdit">
            Abort
          </button>
          <button *ngIf="t && t.enable && t.abort && !t.isMister"
            class="clickable resume" [class.pending]="(pendingSettings && pendingSettings.resume)"
            (click)="enableOrAbort(i, 'abort')" [disabled]="!canEdit">
            Resume
          </button>
        </ion-row>
      </ion-grid>
    </ion-col>
    <ion-col *ngIf="smallScreen ? showTimer : true" >
    <ion-grid class="stageA">
      <ion-row class="th two"><p>Stage A</p></ion-row>
      <ion-row class="start-run">
        <ion-col (click)="editTimerSetting(i, 'startTimeA', t.startTimeA)"
          [class.pending]="t.pendingSettings && t.pendingSettings.startTimeA">
          Start
        </ion-col>
        <ion-col (click)="editTimerSetting(i, 'holdTimeA', t.holdTimeA)"
          [class.pending]="t.pendingSettings && t.pendingSettings.holdTimeA">
          Run
        </ion-col>
      </ion-row>
      <ion-row>
          <ion-col (click)="editTimerSetting(i, 'startTimeA', t.startTimeA)"
          [class.pending]="t.pendingSettings && t.pendingSettings.startTimeA">
            <p class="clickable">{{t.startTimeA}}</p>
          </ion-col>      
          <ion-col (click)="editTimerSetting(i, 'holdTimeA', t.holdTimeA)"
          [class.pending]="t.pendingSettings && t.pendingSettings.holdTimeA">
            <p class="clickable">{{t.holdTimeA}}</p>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-col>
    <ion-col *ngIf="smallScreen ? showTimer : true" >
      <ion-grid class="stageB">
        <ion-row><p>Stage B</p></ion-row>
        <ion-row class="start-run">
          <ion-col (click)="editTimerSetting(i, 'startTimeB', t.startTimeB)"
            [class.pending]="t.pendingSettings && t.pendingSettings.startTimeB">
            Start
          </ion-col>
          <ion-col (click)="editTimerSetting(i, 'holdTimeB', t.holdTimeB)"
            [class.pending]="t.pendingSettings && t.pendingSettings.holdTimeB">
            Run
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col (click)="editTimerSetting(i, 'startTimeB', t.startTimeB)" [class.pending]="t.pendingSettings && t.pendingSettings.startTimeB" class="clickable">
            {{t.startTimeB}}
          </ion-col>      
          <ion-col (click)="editTimerSetting(i, 'holdTimeB', t.holdTimeB)" [class.pending]="t.pendingSettings && t.pendingSettings.startTimeB" class="clickable">
            {{t.holdTimeB}}
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-col>
  </ion-row>
</ion-grid>

<app-light-sensor *ngIf="t && !t.isMister && lightSource !== 'NONE' && (smallScreen ? showTimer : true)"
  [light_value]="light_value"
  [daily_light_integral]="daily_light_integral"
  [light_set_point]="light_set_point"
  [nodeData]="getSubsetNodeData()"
  [misterSettings]="t"
  [misterIndex]="i"
  [pending]="t.mister_pending"
></app-light-sensor>
<div *ngIf="t && !t.isMister && lightSource === 'NONE' && (smallScreen ? showTimer : true)">
  <p>Your light sensor is not enabled. Please configure it on your controller.</p>
</div>

<app-mister-settings *ngIf="t && t.isMister && (smallScreen ? showTimer : true)"
  [settings]="t"
  [mister_index]="i"
  [light_value]="light_value"
  [daily_light_integral]="daily_light_integral"
  [light_set_point]="light_set_point"
  [nodeData]="getSubsetNodeData()"
  [pending]="t.pending"
  [lightSource]="lightSource"
  (setPending)="setMisterPending($event)"
></app-mister-settings>

<div *ngIf="t && t.isShade && (smallScreen ? showTimer : true)" class="shade-settings">
  <div class="title">
    <div>
      <ion-icon name="cloud"></ion-icon>
      <span>Shade Settings</span>
    </div>
  </div>
  <app-status-chip
    param='settings.shadeSlowRetract'
    [pending]="isPending('settings.shadeSlowRetract', 'shadeSlowRetract')"
    name="Slow Retract"
    data="{{shadeInfo && shadeInfo.shadeSlowRetract ? 'ON' : 'OFF'}}"
    [setting]="shadeInfo && shadeInfo.shadeSlowRetract ? 'ON' : 'OFF'"
    (edit)="showWindRainEdit($event)"
    class="status-chip"
  >
  </app-status-chip>
  <app-status-chip
    param='settings.shadeOpenTime'
    [pending]="isPending('settings.shadeOpenTime', 'shadeOpenTime')"
    name="Open Time"
    data="{{shadeInfo.shadeOpenTime}}"
    [setting]="shadeInfo.shadeOpenTime"
    (edit)="showWindRainEdit($event)"
    *ngIf="shadeInfo"
    class="status-chip"
  >
  </app-status-chip>
  <app-status-chip
    param='settings.shadeTempSP'
    [pending]="isPending('settings.shadeTempSP', 'shadeTempSP')"
    name="Temp Set Point"
    data="{{shadeInfo && shadeInfo.shadeTempSP}}"
    [setting]="shadeInfo && shadeInfo.shadeTempSP"
    (edit)="showWindRainEdit($event)"
    class="status-chip"
  >
  </app-status-chip>
  <div class="setting uneditable">
    <label>Extend</label>
    <p>{{shadeInfo && shadeInfo.shadeExtending !== 'OFF' ? "On" : "Off"}}</p>
  </div>

  <app-status-chip
    param='settings.shadeStep'
    [pending]="isPending('settings.shadeStep', 'shadeStep')"
    name="Step Percentage"
    data="{{shadeInfo.shadeStep}}"
    [setting]="shadeInfo.shadeStep"
    (edit)="showWindRainEdit($event)"
    class="status-chip"
    *ngIf="shadeInfo && shadeInfo.shadeSlowRetract"
  >
  </app-status-chip>
  <div class="setting uneditable">
    <label>Retract</label>
    <p>{{shadeInfo && shadeInfo.shadeRetracting !== 'OFF' && shadeInfo.shadeExtending !== 'OFF' ? "Off" : "On"}}</p>
  </div>
  <app-status-chip
    param='settings.shadePause'
    [pending]="isPending('settings.shadePause', 'shadePause')"
    name="Pause"
    data="{{shadeInfo.shadePause}}"
    [setting]="shadeInfo.shadePause"
    (edit)="showWindRainEdit($event)"
    class="status-chip"
    *ngIf="shadeInfo && shadeInfo.shadeSlowRetract"
  >
  </app-status-chip>
</div>

<div class="show-hide-timer" *ngIf="smallScreen" (click)="showTimer = !showTimer">
  <ion-icon *ngIf="showTimer" name="chevron-up-outline"></ion-icon>
  <ion-icon *ngIf="!showTimer" name="chevron-down-outline"></ion-icon>
</div>
