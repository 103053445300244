<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button class="icon back"></ion-back-button>
    </ion-buttons>
    <ion-title>Programming Tools</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div *ngIf="!controller" class="content">
    <ion-progress-bar type="indeterminate"></ion-progress-bar>
  </div>  
  <div *ngIf="controller" class="programming-tools-container content">
    <app-programming-tools [node]="controller" [reviewDefault]="false" [narrow]="narrow"></app-programming-tools>
  </div>
</ion-content>
