import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Platform, PopoverController } from '@ionic/angular';
import { SitesService } from 'src/app/services/sites.service';
import { TimerService } from 'src/app/services/timer.service';
import { UserService } from 'src/app/services/user.service';
import { EditTimeBossComponent } from '../edit-time-boss/edit-time-boss.component';
import { SettingInfo } from '../status-wind-rain/setting-info';
import { ClimateBossRequestToKeys, TimerMisterSettings } from '../status/getTimerMisterSettings';
import { WindRainEditPage } from '../wind-rain-edit/wind-rain-edit.page';
import moment from 'moment';

@Component({
  selector: 'app-time-boss-status',
  templateUrl: './time-boss-status.component.html',
  styleUrls: ['./time-boss-status.component.scss'],
})
export class TimeBossStatusComponent implements OnInit {
  @Input() nodeData;
  @Input() email;
  @Input() t;
  @Input() i;
  @Input() canEdit;
  @Input() notitle: Boolean = false;
  @Output() pending = new EventEmitter<Object>();
  @Input() light_set_point;
  @Input() daily_light_integral;
  @Input() light_value;
  @Input() lightSource;
  @Input() shadeInfo;
  @Input() isClimateBoss;
  @Input() clock;
  @Input() pendingSettings;

  public isShadeMode = false;
  public timerMisterSettings;
  public showTimer = false;
  public smallScreen = false;
  public currentStage;

  constructor(
    public sitesApiProvider: SitesService,
    public timerService: TimerService,
    public modalCtrl: PopoverController,
    public userService: UserService,
    private platform: Platform
  ) {
  }

  ngOnInit() {
    this.smallScreen = 820 >= this.platform.width();
    if(!this.lightSource && this.nodeData && this.nodeData.settings) {
      this.lightSource = this.nodeData.settings.lightSource;
    }
    
    this.timerMisterSettings = TimerMisterSettings.getTimerMisterSettings();
    Object.keys(this.timerMisterSettings).forEach(setting => {
      if(this.timerMisterSettings[setting]) {
        this.timerMisterSettings[setting].pending = false;
      }
    });

    this.nodeData && this.nodeData.reqs && this.nodeData.reqs.forEach(req => {
      if (req.node_id === this.nodeData.node_id && req.key && (this.isClimateBoss && (req.key.startsWith("timer") || this.isAShadeSetting(req.key)))){
        var key = req.key
        var isCorrectTimerMister = this.isAShadeSetting(key) ? true : false;

        if(this.isClimateBoss && key.includes('timer')) {
          isCorrectTimerMister = key.includes(this.i)
          key = key.replace(`timer${this.i}.`, "");
          key = ClimateBossRequestToKeys[key]
        } else {
          key = this.isAShadeSetting(req.key) ? req.key : `settings.${req.key}`
        }

        if(this.timerMisterSettings[key] && (this.isClimateBoss && isCorrectTimerMister)) {
          this.timerMisterSettings[key].pending = true;
        }
      }
    });

    this.isShadeMode = this.shadeInfo && this.shadeInfo.shadeEnable;
    this.setCurrentStage();
  }

  hasPendingChanges() {
    return this.pendingSettings && Object.keys(this.pendingSettings) && Object.keys(this.pendingSettings).length > 0;
  }

  name(index) {    
    return this.nodeData.output_overrides && this.nodeData.output_overrides[`t${index}`] ? `${this.nodeData.output_overrides[`t${index}`]} - Timer ${index}` : `Timer ${index}`;
  }

  timerKeys() {
    return ["startTimeA", "holdTimeA", "startTimeB", "holdTimeB", "enable", "abort"];
  }

  stop(index) {
    var reqData = {
      node_id: this.nodeData.node_id,
      key: `tbTimer${index}.manual`,
      value: "0",
      comment: `User, ${this.email} stopped timer ${index}`
    }

    this.setPending(index, reqData);
    this.sitesApiProvider.postRequest(reqData);
    this.timerService.setFastUpdate(2);
  }

  enableOrAbort(index, key) {
    var timer_value_currently_true = this.t[key];
    var enable_or_abort = ['enable', 'abort']
    var full_timer_value = this.timerKeys().map((timer_key) => {
      if(key === timer_key) {
        return timer_value_currently_true ? 0 : 1;
      } else {
        if(enable_or_abort.includes(timer_key)) {
          return this.t[timer_key] ? 1 : 0;
        } else {
          return this.t[timer_key];
        }
      }
    }).join(",")

    var comment = "N/A"
    if(key !== 'abort') {
      comment = timer_value_currently_true ? `Timer ${index} disabled` : `Timer ${index} enabled`
    } else {
      comment = timer_value_currently_true ? `Timer ${index} resumed` : `Timer ${index} aborted`
    }

    var reqData = {
      node_id: this.nodeData.node_id,
      key: `tbTimer${index}`,
      value: full_timer_value,
      comment: comment
    }

    this.setPending(index, reqData);
    this.sitesApiProvider.postRequest(reqData);
    this.timerService.setFastUpdate(10);
  }

  isAShadeSetting(key) {
    let shadeKeys = ["settings.shadeEnable", "settings.shadeSlowRetract", "settings.shadeOpenTime", "settings.shadeStep", "settings.shadePause", "settings.shadeTempSP"]
    return (shadeKeys.includes(key) || key.startsWith("shade"));
  }

  public isPending(testKey, climateBoss?) {
    if(this.timerMisterSettings[testKey]) {
      return this.timerMisterSettings[testKey].pending;
    } else {
      return false;
    }
  }

  async showWindRainEdit(key) {
    var settingsForKey = this.timerMisterSettings[key]
    if(!settingsForKey) return;
    var reqData = {
      node_id: this.nodeData.node_id,
      key: (this.isAShadeSetting(key) ? key.replace('settings.', '') : key),
      value: '',
      comment: ''
    }

    var settingInfo = SettingInfo.get(this.nodeData, this.timerMisterSettings, key);
    if(this.isClimateBoss && this.isAShadeSetting(key)) {
      let val = this.shadeInfo[key.replace("settings.", '')];
      let current;
      if(["settings.shadeSlowRetract"].includes(key) && (val === true || val === false)) {
        val = val ? "1" : "0";
        current = val ? "On" : "Off";
      }

      if(key.includes('shadeEnable') && (val === true || val === false)) {
        val = val ? "1" : "0";
        current = (val === "SHADE") ? "On" : "Off";
        settingInfo.key = 'tbTimer1.mode';
        settingInfo.requestKey = 'tbTimer1.mode';
        reqData.key = 'tbTimer1.mode';
      }
      settingInfo.value = val;
      settingInfo.current = current || val;
      settingInfo.pattern = settingInfo.pattern ? settingInfo.pattern : "\\.*";
    }

    if(settingInfo) {
      const options = {
        component: WindRainEditPage,
        componentProps: {
          settingInfo: settingInfo,
          siteName: this.nodeData.site_name,
          houseName: (this.nodeData.api_name || this.nodeData.node_name),
          canEdit: this.nodeData.canEdit,
          isPending: false
        }
      };

      const settingsModal = await this.modalCtrl.create(options);
      settingsModal.onDidDismiss().then(data => {
        data = data.hasOwnProperty('data') ? data.data : data;
        if (typeof data !== 'undefined'){
          var value = data['value']
          if (data['button'] == "Save" && value != null) {
            var eng = this.nodeData.settings.hasOwnProperty('english') ? this.nodeData.settings.english : true;
            reqData.value = settingsForKey.hasOwnProperty("convertValue") ? settingsForKey.convertValue(value.value, eng) : value['value'];
            reqData.comment = value['comment'];
            this.sitesApiProvider.postRequest(reqData);
            this.timerService.setFastUpdate(2); //set to fast update for the next 60 seconds
            this.setPending(1, reqData);
          }
        }
      });
      settingsModal.present();
    }
  }

  async editTimerSetting(index, key, currentValue?) {
    const timer_index = index;
    const request_key = `tbTimer${timer_index}`;
    const options = {
      component: EditTimeBossComponent,
      componentProps: {
        key: this.keyToReadableKey[key],
        timer_index: timer_index,
        request_key: `tbTimer${timer_index}`,
        value: currentValue,
        users: this.nodeData.users,
        isPending: false
      }
    };

    var settingsModal = await this.modalCtrl.create(options);
    settingsModal.onDidDismiss().then((data) => {
      console.log(`edit time boss dismissed: ${JSON.stringify(data)}`);
      data = data.hasOwnProperty('data') ? data.data : data;

      let reqData;
      if (typeof data !== 'undefined') {
        if (data['button'] == "Save" && data['value'] != null) {
          var valid = moment(data['value'], "HH:mm", true).isValid() || moment(data['value'], "H:mm", true).isValid();
          if(key !== 'start' && valid) {
            var full_timer_value = this.timerKeys().map((timer_key) => {
              if(key === timer_key) { return data['value'] }
              else {
                var enable_or_abort = ['enable', 'abort']
                if(enable_or_abort.includes(timer_key)) {
                  return this.t[timer_key] ? 1 : 0;
                } else {
                  return this.t[timer_key];
                }
              }
            }).join(",")

            reqData = {
              node_id: this.nodeData.node_id,
              key: request_key,
              value: full_timer_value,
              comment: data['comment']
            }
          } else if(valid) {
            reqData = {
              node_id: this.nodeData.node_id,
              key: `tbTimer${index}.manualDuration`,
              value: data['value'],
              comment: data['comment'] || `User, ${this.email} started timer ${index}`
            }
          }

          if(!valid) {
            console.log(`invalid time value ${data['value']}`)
          } else {
            console.log(`request was valid - ${timer_index}, ${JSON.stringify(reqData)}`)
            this.sitesApiProvider.postRequest(reqData);
            this.timerService.setFastUpdate(2); //set to fast update for the next 60 seconds
            this.setPending(timer_index, reqData);
          }
        }
      }
    });
    settingsModal.present();
  }

  setMisterPending(event) {
    this.setPending(event.index, event.value);
  }

  setPending(index, reqData = {}) {
    this.pending.emit({
      index: (index - 1),
      pending_data: reqData
    });
  }

  getSubsetNodeData() {
    return {
      node_id: this.nodeData.node_id,
      node_name: this.nodeData.node_name,
      site_name: this.nodeData.site_name,
      canEdit: this.canEdit
    };
  }

  private setCurrentStage() {
    if(this.t && this.t.timeRemaining && this.t.timeRemaining !== '0:00') {
      let clockAsInt = parseInt(this.clock.replace(':', ''))
      let startTimeA_asInt = parseInt(this.t.startTimeA.replace(':', ''))
      let startTimeB_asInt = parseInt(this.t.startTimeB.replace(':', ''))
      let endTimeA = startTimeA_asInt + parseInt(this.t.holdTimeA.replace(':', ''))
      let endTimeB = startTimeB_asInt + parseInt(this.t.holdTimeB.replace(':', ''))

      if(clockAsInt >= startTimeA_asInt && endTimeA >= clockAsInt) {
        this.currentStage = "stageA"
      } else if(clockAsInt >= startTimeB_asInt && endTimeB >= clockAsInt) {
        this.currentStage = "stageB"
      } else { this.currentStage = undefined; }
    }
  }

  public keyToReadableKey = {
    startTimeA: "Stage A Start",
    holdTimeA: "Stage A Hold",
    startTimeB: "Stage B Start",
    holdTimeB: "Stage B Hold",
    start: "Manual Duration"
  }
}
