export const SettingInfo = {
  get: (nodeData, settings, key) => {
    var info = settings[key];
    if(key && info) {
      var keyPath = key.split(".")
      var val = ""
      keyPath.forEach((pathPart) => {
        if(val === "") {
          val = nodeData[pathPart]
        } else {
          val = val[pathPart]
        }
      });

      info.value = val;
      info.pattern = info.pattern ? info.pattern : "\\.*"
      return info;
    } else {
      return undefined;
    }
  }
}
