export const EnglishMetricFormat = {
  convertToMph: (kph) => {
    return parseFloat((kph * 0.6214).toString()).toFixed(0);
  },
  convertToKmph: (mph) => {
    return parseFloat((mph / 0.6214).toString()).toFixed(0);
  },
  convertToFahrenheit: (celsius) => {
    celsius = typeof (celsius === 'string') ? parseInt(celsius) : celsius;
    return parseInt(((celsius*(9/5))+32).toString());
  },
  convertToCelsius: (fahrenheit) => {
    fahrenheit = typeof (fahrenheit === 'string') ? parseInt(fahrenheit) : fahrenheit;
    return Math.round((fahrenheit-32)*(5/9));
  },
  offsetConvertToFarenheit: (celsius) => {
    return ((celsius*(9/5))).toString();
  },
  offsetConvertToCelsius: (fahrenheit) => {
    return fahrenheit === 0 ? 0 : Math.round((fahrenheit)*(5/9));
  }
}
