import { EnglishMetricFormat } from '../status-wind-rain/englishMetricConversion'

class dehumInformationClass {
  start: string;
  humidity_set_point: string;
  outputs?: string[];
  humid_sensor: boolean | string;
  exhaust_1: string;
  exhaust_2: string;
  preheat: string;
  low_limit: number;
  high_limit: number;
  repeat: boolean;
  dhSetup: string;
}

var default_settings = {
  "difst": "6:00",
  "dayst": "8:00",
  "nitst": "17:00",
  "difsp1": 66,
  "daysp1": 65,
  "nitsp1": 65,
  "difsp2": 65,
  "daysp2": 65,
  "nitsp2": 65,
  "sp1": 65,
  "sp2": 65,
  "sp3": 50,
  "humsp": 0,
  "controller_time": "15:57",
  "alarm1l": 35,
  "alarm1h": 95,
  "alarm2l": 32,
  "alarm2h": 131,
  "c1t": 3,
  "c2t": 5,
  "c3t": 7,
  "c4t": 8,
  "h1t": 20,
  "h2t": 10,
  "c1m": "AUTO",
  "c2m": "AUTO",
  "c3m": "AUTO",
  "c4m": "AUTO",
  "h1m": "AUTO",
  "h2m": "AUTO",
  "group1Timer": [
    {
      "startTimeA": "0:00",
      "holdTimeA": "0:00",
      "startTimeB": "0:00",
      "holdTimeB": "0:00",
      "timeRemaining": "0:00",
      "inUse": true,
      "enable": true,
      "abort": false,
      "manualActive": false,
      "isMister": false,
      "isShade": true
    },
    {
      "startTimeA": "0:00",
      "holdTimeA": "0:00",
      "startTimeB": "0:00",
      "holdTimeB": "0:00",
      "timeRemaining": "0:00",
      "inUse": true,
      "enable": true,
      "abort": false,
      "manualActive": false,
      "isMister": true
    },
    {},
    {},
    {},
    {},
    {},
    {}
  ],
  "group1Mister": [
    {
      "lightSP": 0,
      "mistAOnSec": 0,
      "mistACycleMin": 0,
      "mistBOnSec": 0,
      "mistBCycleMin": 0,
      "mistIdleOnSec": 0,
      "mistIdleCycleMin": 0
    },
    {
      "lightSP": 0,
      "mistAOnSec": 0,
      "mistACycleMin": 0,
      "mistBOnSec": 0,
      "mistBCycleMin": 0,
      "mistIdleOnSec": 0,
      "mistIdleCycleMin": 0
    },
    {},
    {},
    {},
    {},
    {},
    {}
  ],
  "dhStartTime": "0:00",
  "dhSp": 0,
  "dhFan1": 0,
  "dhFan2": 0,
  "dhHeat": 0,
  "dhLoTemp": 0,
  "dhHiTemp": 0,
  "dhSetup": 0,
  "dhVentMode": "Off",
  "dhDif": "OFF",
  "dhDay": "OFF",
  "dhNight": "OFF",
  "dhRepeat": "OFF",
  "shadeOpenTime": 100,
  "shadeStep": 25,
  "shadePause": 2,
  "shadeTempSP": 97,
  "shadeEnable": true,
  "shadeSlowRetract": false,
  "vent": [
    {
      "man": 15,
      "mode": "5-STEP",
      "st": 4,
      "p1": 7,
      "p2": 15,
      "p3": 25,
      "p4": 50,
      "p5": 99,
      "threshold": 1,
      "openTime": 200,
      "rainOR": 1,
      "wind1OR": 1,
      "wind2OR": 0
    },
    {
      "man": 4,
      "mode": "MAN",
      "st": 0,
      "p1": 0,
      "p2": 0,
      "p3": 0,
      "p4": 0,
      "p5": 0,
      "threshold": 0,
      "openTime": 0,
      "rainOR": 1,
      "wind1OR": 0,
      "wind2OR": 1
    },
    {
      "man": 0,
      "mode": "OFF",
      "st": 2,
      "p1": 7,
      "p2": 15,
      "p3": 25,
      "p4": 50,
      "p5": 99,
      "threshold": 1,
      "openTime": 200,
      "rainOR": 1,
      "wind1OR": 1,
      "wind2OR": 0
    },
    {
      "man": 0,
      "mode": "OFF",
      "st": 15,
      "p1": 7,
      "p2": 15,
      "p3": 25,
      "p4": 50,
      "p5": 99,
      "threshold": 1,
      "openTime": 200,
      "rainOR": 1,
      "wind1OR": 1,
      "wind2OR": 0
    }
  ]
}

let generateSettingsFile = (info, timerType, dehumInformation: dehumInformationClass, isCelsius = false) => {
  let dif_sp = parseInt(info.dif_degrees)
  let nite_sp = parseInt(info.nite_degrees)
  let one_zone = (info.zones === 1)
  
  try {
    let mapped_vents = info.vents.map((v, index) => {
      let day_temp = (one_zone ? true : index < 3) ? parseInt(info.day_temp) : parseInt(info.day_temp_zone2)
      let step = ((v.start_opening && day_temp) ? (v.start_opening - day_temp) : v.st)
      if(isCelsius) { step = EnglishMetricFormat.offsetConvertToFarenheit(step)}
      return {
        "man": v.man || 0,
        "mode": v.mode || '5-STEP',
        "st": step,
        "p1": v.p1,
        "p2": v.p2,
        "p3": v.p3,
        "p4": v.p4,
        "p5": v.p5,
        "threshold": 1,
        "openTime": v.openTime,
        "rainOR": v.rainOR,
        "wind1OR": v.wind1OR,
        "wind2OR": v.wind2OR,
        "name": v.vent_name
      }
    })
    
    if(info.model && info.model.includes('GHK')) {
      let updated_vents = [{}, {}].concat(mapped_vents)
      if(updated_vents.length < 3) { updated_vents = updated_vents.concat([{}]) }
      mapped_vents = updated_vents;
    }

    let mapped_timers = info.timers.map((t, index) => {
      return {
        "startTimeA": t.stage_a_start_time || t.startTimeA,
        "holdTimeA": t.stage_a_run_time || t.holdTimeA,
        "startTimeB": t.stage_b_start_time || t.startTimeB,
        "holdTimeB": t.stage_b_run_time || t.holdTimeB,
        "inUse": true,
        "enable": true,
        "abort": false,
        "manualActive": false,
        "isMister": timerType[index] ? timerType[index].isMister : false,
        "isShade": timerType[index] ? timerType[index].isShade : false
      }
    })
    
    let mapped_misters = info.timers.map((t, index) => {
      return {
        "lightSP": t.light_sp,
        "mistAOnSec": t.stage_a_on_time,
        "mistACycleMin": t.stage_a_cycle_time,
        "mistBOnSec": t.stage_b_on_time,
        "mistBCycleMin": t.stage_b_cycle_time,
        "mistIdleOnSec": t.stage_idle_on_time,
        "mistIdleCycleMin": t.stage_idle_cycle_time
      }
    })

    let updated = {
     "difst": info.dif_start,
     "dayst": info.day_start,
     "nitst": info.nite_start,
     "difsp1": dif_sp,
     "daysp1": parseInt(info.day_temp),
     "nitsp1": nite_sp,
     "difsp2": one_zone ? dif_sp : parseInt(info.dif_degrees_zone2),
     "daysp2": one_zone ? parseInt(info.day_temp) : parseInt(info.day_temp_zone2),
     "nitsp2": one_zone ? nite_sp : parseInt(info.nite_degrees_zone2),
     "c1t": (parseInt(info.day_c1) - parseInt(info.day_temp)),
     "c2t": (parseInt(info.day_c2) - parseInt(info.day_temp)),
     "c3t": one_zone ? (parseInt(info.day_c3) - parseInt(info.day_temp)) : parseInt(info.day_c3) - parseInt(info.day_temp_zone2),
     "c4t": one_zone ? (parseInt(info.day_c4) - parseInt(info.day_temp)) : (parseInt(info.day_c4) - parseInt(info.day_temp_zone2)),
     "h1t": (parseInt(info.day_temp) - parseInt(info.day_h1)),
     "h2t": one_zone ? (parseInt(info.day_temp) - parseInt(info.day_h2)) : (parseInt(info.day_temp_zone2) - parseInt(info.day_h2)),
     "c1m": info.c1m,
     "c2m": info.c2m,
     "c3m": info.c3m,
     "c4m": info.c4m,
     "h1m": info.h1m,
     "h2m": info.h2m,
     "vent": mapped_vents,
     "group1Timer": mapped_timers,
     "group1Mister": mapped_misters,
     "humsp": dehumInformation.humidity_set_point,
     "dhStartTime": !dehumInformation.humid_sensor ? dehumInformation.start : '00:00',
     "dhSp": dehumInformation.humid_sensor ? dehumInformation.humidity_set_point : 0,
     "dhFan1": dehumInformation.exhaust_1,
     "dhFan2": dehumInformation.exhaust_2,
     "dhHeat": dehumInformation.preheat,
     "dhLoTemp": dehumInformation.low_limit,
     "dhHiTemp": dehumInformation.high_limit,
     "dhSetup": dehumInformation.dhSetup,
     "dhVentMode": info.dehumidification ? (dehumInformation && dehumInformation.humid_sensor && dehumInformation.humid_sensor === 'true' ? 'Humid': 'Timed') : "Off",
     "dhDif": (dehumInformation && dehumInformation.outputs && dehumInformation.outputs.includes('dif') ? "ON" : "OFF"),
     "dhDay": (dehumInformation && dehumInformation.outputs && dehumInformation.outputs.includes('day') ? "ON" : "OFF"),
     "dhNight": (dehumInformation && dehumInformation.outputs && dehumInformation.outputs.includes('nite') ? "ON" : "OFF"),
     "dhRepeat": (dehumInformation.repeat ? "ON" : "OFF"),
    }

    if(info.timers && info.timers[0] && info.timers[0].pulse_yes_no !== undefined) {
      let shade_config = info.timers[0]
      let shade_settings = {
        "shadeOpenTime": shade_config.open_time,
        "shadeStep": shade_config.step_percent,
        "shadePause": shade_config.pause,
        "shadeTempSP": isCelsius ? (EnglishMetricFormat.convertToFahrenheit(shade_config.temp_sp)) : shade_config.temp_sp,
        "shadeEnable": true,
        "shadeSlowRetract": shade_config.slow_retract,
      }
      updated = {...updated, ...shade_settings}
    }

    if(isCelsius) {
      let offsetKeys = ['c1t', 'c2t', 'c3t', 'c4t', 'h1t', 'h2t']
      offsetKeys.forEach((key) => {
        updated[key] = EnglishMetricFormat.offsetConvertToFarenheit(key)
      })      

      let tempKeys = ['dhLoTemp', 'dhHiTemp', 'difsp1', 'daysp1', 'nitsp1', 'difsp2', 'daysp2', 'nitsp2']
      tempKeys.forEach((key) => {
        updated[key] = EnglishMetricFormat.convertToFahrenheit(key)
      })
    }
    
    return updated;
  } catch(e) {
    console.log(e);
  }
}

export const SettingsFileGenerator = {
  generate: generateSettingsFile
}
