<div id="run-times-container" *ngIf="runTimes">
  <div>
    <h6>Run Times</h6>
    <!-- <button>Reset Run Times</button> -->
  </div>
  <ul id="run-time-info">
    <li>
      <label>Days Since Reset</label>
      <p>{{runTimes.last_reset}}</p>
    </li>
    <li>
      <label>Heat 1</label>
      <p>{{runTimes.h1}} (hours)</p>
    </li>
    <li>
      <label>Heat 2</label>
      <p>{{runTimes.h2}} (hours)</p>
    </li>
    <li>
      <label>Cool 1</label>
      <p>{{runTimes.c1}} (hours)</p>
    </li>
    <li>
      <label>Cool 2</label>
      <p>{{runTimes.c2}} (hours)</p>
    </li>
    <li>
      <label>Cool 3</label>
      <p>{{runTimes.c3}} (hours)</p>
    </li>
    <li>
      <label>Cool 4</label>
      <p>{{runTimes.c4}} (hours)</p>
    </li>
  </ul>
</div>
<div id="run-times-container" *ngIf="!runTimes">No run times for this controller</div>
