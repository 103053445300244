import { Component, OnInit } from '@angular/core';
import { NavController, NavParams } from '@ionic/angular';
import { SitesService } from 'src/app/services/sites.service';
import { UserService } from 'src/app/services/user.service';
import { UserEditPage } from '../user-edit/user-edit.page';
import { Node } from '../model-defs/node-def';
import { NavigationExtras, ActivatedRoute } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  public userListLoaded = false;
  public nodeData: Node;
  public nodeId: string;
  public userList: [{role: string, email: string}];
  public addUserForm: UntypedFormGroup;
  public formSubmitted: boolean = false;

  constructor(
    public navCtrl: NavController,
    public sitesApiProvider: SitesService,
    public userService: UserService) {
      let username = new UntypedFormControl('', [Validators.required, CustomValidators.email]);
      let isAdmin = new UntypedFormControl('', []);
      let canEdit = new UntypedFormControl('', []);
      let canView = new UntypedFormControl('', []);
      this.addUserForm = new UntypedFormGroup({
        username: username
      });      
  }

  getUserData() {
    this.sitesApiProvider.getNodeUserList().then((data) => this.onGetListSuccess(data));    
  }

  ngOnInit() {
    this.formSubmitted = false;
    this.getUserData();
    let username = new UntypedFormControl('', [Validators.required, CustomValidators.email]);
    this.addUserForm = new UntypedFormGroup({
      username: username
    });
  }

  ngOnChanges() {
    this.formSubmitted = false;
    this.getUserData();
  }

  private onGetListSuccess(data){
    this.userList = data.sort();
    if (this.userList) {
      this.userListLoaded = true;
      if(this.userList.find((u) => {
        return typeof u === 'string' ? (u === this.addUserForm.value.username) : (u.email === this.addUserForm.value.username)
      })) {
        this.addUserForm.reset();
        this.formSubmitted = false;
      }
    }
  }

  selectUser(user){
    let navigationExtras: NavigationExtras = {
      queryParams: {user: user}
    };
    this.navCtrl.navigateForward('user-edit', navigationExtras);
  }

  addUser(formValue) {
    this.formSubmitted = true;
    if(formValue.valid) {
      let navigationExtras: NavigationExtras = {
        queryParams: {user: formValue.value.username}
      };

      this.navCtrl.navigateForward('user-edit', navigationExtras);
    }
  }
}
