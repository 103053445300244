import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WindRainEditPage } from '../wind-rain-edit/wind-rain-edit.page'
import { TimerService } from 'src/app/services/timer.service';
import { SitesService } from 'src/app/services/sites.service';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-light-sensor',
  templateUrl: './light-sensor.component.html',
  styleUrls: ['./light-sensor.component.scss'],
})
export class LightSensorComponent implements OnInit {
  @Input() nodeData;
  @Input() light_set_point;
  @Input() daily_light_integral;
  @Input() light_value;
  @Input() misterSettings;
  @Input() misterIndex;
  @Input() pending;
  @Input() format_set_point;
  @Output() edit = new EventEmitter<any>();

  public formatted_set_point;

  constructor(public modalCtrl: PopoverController,
              public sitesApiProvider: SitesService,
              public timerService: TimerService) {

              }

  ngOnInit() {
    if(this.format_set_point) {
      this.formatted_set_point = `${this.light_set_point.toString().substring(0, 2)}.${this.light_set_point.toString().substring(2)}`;
    }
  }

  valueForTimerRequest(lightSetPointValue) {
    if(lightSetPointValue.toString().includes(".")) {
      lightSetPointValue = lightSetPointValue.toString().replace(".", "")
    }

    let settings = this.misterSettings;
    return `${lightSetPointValue},${settings.mistAOnSec},${settings.mistACycleMin},${settings.mistBOnSec},${settings.mistBCycleMin},${settings.mistIdleOnSec},${settings.mistIdleCycleMin}`
  }

  public editLightSetPoint() {
    var settingInfo = {
      node_id: this.nodeData.node_id,
      key: `tbMister${this.misterIndex}`,
      value: this.light_set_point,
      current: this.light_set_point,
      type: 'number',
      pending: false,
      desc: "Light Set Point",
      pattern: "[0-9]*",
      error: "Please enter only numbers.",
      inputmode:"tel"
    }

    this.showWindRainEdit(settingInfo);
  }

  async showWindRainEdit(settings) {
      var settingsForKey = settings;
      if(!settingsForKey) return;
      var reqData = {
        node_id: this.nodeData.node_id,
        key: settings.key,
        value: '',
        comment: ''
      }

      var settingInfo = settings;
      if(settingInfo) {
        const options = {
          component: WindRainEditPage,
          componentProps: {
            settingInfo: settingInfo,
            siteName: this.nodeData.site_name,
            houseName: (this.nodeData.api_name || this.nodeData.node_name),
            canEdit: this.nodeData.canEdit,
            isPending: false
          }
        };
        const settingsModal = await this.modalCtrl.create(options);

        settingsModal.onDidDismiss().then(data => {
          data = data.hasOwnProperty('data') ? data.data : data;
          if (typeof data !== 'undefined'){
            var value = data['value']
            if (data['button'] == "Save" && value != null) {
              reqData.value = this.valueForTimerRequest(value['value']);
              reqData.comment = value['comment'];
              this.sitesApiProvider.postRequest(reqData);
              this.timerService.setFastUpdate(60); //set to fast update for the next 60 seconds
              // if(this.timerMisterSettings[key]) {
              //   this.timerMisterSettings[key].pending = true;
              // }
            }
          }
        });
        settingsModal.present();
      }
  }
}
