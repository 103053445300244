import { AfterViewChecked, Component, Input, OnChanges, OnInit } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { NavController, PopoverController, ToastController } from '@ionic/angular';
import { SitesService } from 'src/app/services/sites.service';
import { UserService } from 'src/app/services/user.service';
import { TimerService } from 'src/app/services/timer.service';
import { EditVentSettingPage } from '../edit-vent-setting/edit-vent-setting.page';
import { Node } from '../model-defs/node-def';
import { EnglishMetricFormat } from '../status-wind-rain/englishMetricConversion';
import { SettingInfo } from '../status-wind-rain/setting-info';
import { WindRainSettings } from '../status-wind-rain/wind-rain-settings';
import { WindRainEditPage } from '../wind-rain-edit/wind-rain-edit.page';

@Component({
  selector: 'app-wind-rain-controller-status',
  templateUrl: './wind-rain-controller-status.component.html',
  styleUrls: ['./wind-rain-controller-status.component.scss'],
})
export class WindRainControllerStatusComponent implements OnInit, OnChanges {
  public nodeLoaded = false;
  public nodeData = new Node;
  @Input() initNodeData;
  @Input() node: String;
  public units = "MPH"
  private settings;
  public enableAlerts;

  public canViewExtendedOverride = false;

  constructor(
    public navCtrl: NavController,
    private timers: TimerService,
    public sitesApiProvider: SitesService,
    public userService: UserService,
    private toastCtrl: ToastController,
    public modalCtrl: PopoverController
  ) {
    this.settings = WindRainSettings.get();
  }

  updateStatusInfo() {
    this.sitesApiProvider.getNodeData().then((data) => {
      try {
        this.initNodeData = data;
        this.nodeData.node_id = this.initNodeData.node_id;
        this.nodeData.node_name = (this.initNodeData.api_name || this.initNodeData.node_name);
        this.nodeData.site_name = this.initNodeData.site_name;
        this.nodeData.type = this.initNodeData.type;
        this.onGetNodeSuccess(data)
      } catch(e) { console.log(e); }
    }).catch((error) => this.onGetNodeError(error))

    this.sitesApiProvider.doUpdates = 2;
    this.timers.setTimerSecond(1);
  }

  ngOnInit() {
    this.updateStatusInfo()
    this.userService.getLoggedInUser().then((user) => {
      if(user && user.email && user.email.includes("@bartinst.com")) { 
        this.canViewExtendedOverride = true; 
      }
    })    
  }

  ngOnChanges(changes) {
    if(changes && changes.currentValue && changes.previousValue && changes.currentValue['node_id'] !== changes.previousValue['node_id']) {
      this.updateStatusInfo()
      console.log(this.userService.getLoggedInEmail())
      this.canViewExtendedOverride = this.userService.getLoggedInEmail() && this.userService.getLoggedInEmail().includes("@bartinst.com")
    }
  }

  ionViewWillLeave() {
    console.log(`did leave ${this.initNodeData && this.initNodeData.node_id}`)
    this.sitesApiProvider.doSiteUpdates = false;
    this.sitesApiProvider.doUpdates = 1;
    this.nodeData = undefined;
  }

  private onGetNodeSuccess(data) {
    var eng = (this.userService.getTemperatureScale() === 'celsius') ? false : (data.settings.hasOwnProperty('english') ? data.settings.english : true);
    if(eng) {
      this.convertValuesToMilesPerHour(data.settings.wind, true);
      this.convertValuesToMilesPerHour(data.settings.wind2, false);
      data.status.wind.current = EnglishMetricFormat.convertToMph(data.status.wind.current);
    }

    if(data.settings && data.settings.wind) this.setDirections(data.settings.wind)
    if(data.settings && data.settings.wind2) this.setDirections(data.settings.wind2)
    // var timePassed = moment.duration(moment().diff(moment(data.updated)));
    // var tenMinutes = moment.duration(10, 'minutes');
    // this.nodeData.online = !(timePassed > tenMinutes);
    // console.log(`settings: ${this.nodeData.settings}`)

    var activeRequestsPending = data.reqs ? data.reqs.map((request) => { return request.key; }) : [];
    Object.keys(this.settings).forEach((key) => {
      var setting = this.settings[key];
      if(activeRequestsPending.includes(setting.requestKey)) {
        setting.pending = true;
      } else {
        setting.pending = false;
      }
    });

    this.nodeData = data;
    this.units = eng ? "MPH" : "KPH";
    this.nodeLoaded = true;
  }

  private onGetNodeError(error){
    console.log("error getting data: " + JSON.stringify(error));
  }

  private setDirections(settings) {
    var inBits = (parseInt(settings && settings.directionMask)).toString(2)
    var updatedSettings = inBits.padStart(8, "0");
    var splitSettings = updatedSettings.split("").map((settingOn) => {return settingOn === "0" ? "low" : "high" });

    settings.directions = {
      nw: splitSettings[0],
      n: splitSettings[7],
      ne: splitSettings[6],
      w: splitSettings[1],
      e: splitSettings[5],
      sw: splitSettings[2],
      s: splitSettings[3],
      se: splitSettings[4]
    }
  }

  private convertValuesToMilesPerHour(settings, convertDropOff) {
    if(settings) {
      settings.activation.setPoint = EnglishMetricFormat.convertToMph(settings.activation.setPoint);
      settings.activation.loSetPoint = EnglishMetricFormat.convertToMph(settings.activation.loSetPoint);
      if(convertDropOff) {
        settings.dropOff.setPoint = EnglishMetricFormat.convertToMph(settings.dropOff.setPoint);
      }
    } else {
      if(!settings) {
        settings = {};
      }

      if(settings.activation) { settings.activation.setPoint = "N/A"; }
      if(convertDropOff && settings.dropOff) {
        settings.dropOff.setPoint = "N/A";
      }
    }
  }

  public isPending(key) {
    return this.settings[key].pending;
  }

  public setPending(key) {
    this.settings[key].pending = true;
  }

  public changeDirectionActivationSetting(windOutput, directionKey) {
    let settingsWindOutput = this.nodeData.settings[windOutput]
    if(settingsWindOutput) {
      let currentValue = settingsWindOutput.directions[directionKey];
      let updatedValue = currentValue === 'high' ? 'low' : 'high'
      settingsWindOutput.directions[directionKey] = updatedValue;

      var isOnToBit = (k) => {
        // console.log(`${k}: ${settingsWindOutput.directions[k]}, ${settingsWindOutput.directions[k] === 'high' ? "1" : "0"}`)
        return settingsWindOutput.directions[k] === 'high' ? "1" : "0";
      };
      var keys = ["nw", "w", "sw", "s", "se", "e", "ne", "n"]
      const windDirectionalSettingBitMask = keys.map((key) => isOnToBit(key)).join("")

      var reqData = {
        node_id: this.nodeData.node_id,
        key: windOutput === 'wind' ? 'windDir1' : 'windDir2',
        value: parseInt(windDirectionalSettingBitMask, 2),
        comment: `Updated wind direction activation speed to ${updatedValue} for ${directionKey.toUpperCase()}`
      }
      console.log(`making request for changing direction: ${JSON.stringify(reqData)}`)
      this.sitesApiProvider.postRequest(reqData);
    }
  }

  public goToAuditTrailPage() {
    let navigationExtras: NavigationExtras = {
      queryParams: { node_id: this.nodeData.node_id, english: this.isEnglishMetrics() }
    };
    this.navCtrl.navigateForward('audit-trail', navigationExtras);
  }

  public getSensitivity(key) {
    var levels = {0: "LOW", 1: "MED", 2: "HIGH"}
    return levels[key] || key;
  }

  getLightSetPoint() {
    if(this.nodeData && this.nodeData.settings && this.nodeData.settings.group1Mister && this.nodeData.settings.group1Mister.length > 0) {
      return this.nodeData.settings.group1Mister[1].lightSP;
    }
  }

  async onAlertsChange() {
    if (this.enableAlerts) this.enableAlerts = false;
    else this.enableAlerts = true;
    this.sitesApiProvider.setAlerts(this.nodeData.node_id, this.enableAlerts,
      {push: this.enableAlerts,
        status: this.enableAlerts,
        settings: this.enableAlerts,
        alarms: this.enableAlerts,
        wind: this.enableAlerts,
        rain: this.enableAlerts,
        windAndRain: this.enableAlerts,
        settingsFailed: this.enableAlerts,
        vfdLinkLost: false
      });

    let toast = await this.toastCtrl.create({
      message: `You have ${this.enableAlerts ? 'enabled' : 'disabled'} alerts for ${this.nodeData ? (this.nodeData.api_name || this.nodeData.node_name) : "this device"}`,
      duration: 3000,
      position: 'top'
    });

    toast.present();
  }

  public activate() {
    this.sitesApiProvider.postRequest({
      node_id: this.nodeData.node_id,
      key: "activate",
      value: '1',
      comment: 'Activating W/R alarm'
    });
    this.timers.setFastUpdate(60); //set to fast update for the next 60 seconds
    this.nodeData.status.override.activation = true;
    this.nodeData.status.activated = true;
  }

  public cancel() {
    this.sitesApiProvider.postRequest({
      node_id: this.nodeData.node_id,
      key: "cancel",
      value: '0',
      comment: 'Cancel W/R alarm'
    });
    this.timers.setFastUpdate(60); //set to fast update for the next 60 seconds
    this.nodeData.status.override.cancel = true;
    this.nodeData.status.activated = false;
  }

  public async manualOverride(event) {
    let options = {
      component: EditVentSettingPage,
      componentProps: {
        node_id: this.nodeData.node_id,
        key: "activate",
        value: '0:00',
        comment: 'Activating W/R alarm',
        canEdit: true,
        isPending: false,
        note: "This will activate the Weather Boss force alarm until the override duration expires. If there is a power outage during this time, the alarm will expire at the normal 20 minutes."
      },
      event: event
    };

    const settingsModal = await this.modalCtrl.create(options);
    settingsModal.onDidDismiss().then((data) => {
      data = data.hasOwnProperty('data') ? data.data : data;
      if (typeof data !== 'undefined'){
        if (data['button'] == "Save" && data['value'] != null){
          //process the user input
          let reqData = {
            node_id: this.nodeData.node_id,
            key: "activate",
            value: '1',
            comment: data['value'] && data['value']['comment'],
            overrideDuration: data['value'] && data['value']['overrideDuration']
          };

          this.sitesApiProvider.postRequest(reqData);
          this.timers.setFastUpdate(60); //set to fast update for the next 60 seconds
          this.setPending('v1');
        }
      }
    });

    settingsModal.present();
  }

  private isEnglishMetrics() {
    return (this.nodeData && this.nodeData.settings) && (this.nodeData.settings.hasOwnProperty('english') ? this.nodeData.settings.english : true);
  }

  public isCb() {
    return this.nodeData && this.nodeData.model && this.nodeData.model.includes('CB');
  }

  async editSetting(key) {
      var settingsForKey = this.settings[key]
      let useClimateBossKey = this.nodeData.model.includes('CB') && settingsForKey.hasOwnProperty("climateBoss")
      var reqData = {
        node_id: this.nodeData.node_id,
        key: (useClimateBossKey ? settingsForKey.climateBoss : settingsForKey.requestKey),
        value: '',
        comment: ''
      }

      var settingInfo = SettingInfo.get(this.nodeData, this.settings, key);
      if(settingInfo) {
        const options = {
          component: WindRainEditPage,
          componentProps: {
            settingInfo: settingInfo,
            siteName: this.nodeData.site_name,
            houseName: (this.nodeData.api_name || this.nodeData.node_name),
            canEdit: this.nodeData.canEdit,
            isPending: this.isPending(key)
          }
        };

        const settingsModal = await this.modalCtrl.create(options);

        settingsModal.onDidDismiss().then(data => {
          data = data.hasOwnProperty('data') ? data.data : data;
          if (typeof data !== 'undefined'){
            if (data['button'] == "Save" && data['value'] != null){
              var keysToConvertToKph = ['settings.wind.activation.setPoint', 'settings.wind.dropOff.setPoint', 'settings.wind.activation.loSetPoint', 'settings.wind2.activation.setPoint', 'settings.wind2.activation.loSetPoint'];
              var eng = this.isEnglishMetrics();
              if(eng && keysToConvertToKph.includes(key)) {
                reqData.value = EnglishMetricFormat.convertToKmph(data['value']['value']).toString();
                // console.log(`converting ${data['value']['value']} MPH to ${reqData.value} KMPH`);
              } else {
                if(settingsForKey.hasOwnProperty("convertValue")) console.log(settingsForKey.convertValue(data['value']['value']))
                reqData.value = settingsForKey.hasOwnProperty("convertValue") ? settingsForKey.convertValue(data['value']['value']) : data['value']['value'];
              }

              reqData.comment = data['value']['comment'];
              // console.log(`request: ${JSON.stringify(reqData)}`)
              this.sitesApiProvider.postRequest(reqData);
              this.timers.setFastUpdate(60); //set to fast update for the next 60 seconds
              this.setPending(key);
            }
          }
        });
        settingsModal.present();
      }
  }
}
