<div class="timer"
  *ngIf="(timerInfo.mode === 'TIMER' || timerInfo.mode === 'OFF')"
  [class.offline]="offline">
  <section> <!-- settings -->
    <div class="title">
      <div>
        <ion-icon src="assets/custom/alarm"></ion-icon>
        <span>{{timerInfo.mode === 'OFF' ? 'Timer/Mister Options' : getTimerName()}}</span>
      </div>
      <app-status-chip
        class="set-point green status-chip"
        green="true"
        *ngIf="!isPending('settings.timerStart') && nodeData.canEdit && (timerInfo.mode ==='OFF' || outputStatus === 'OFF')"
        param='timerStart'
        [pending]="isPending('settings.timerStart')"
        [editable]='false'
        name="Activate"
        (edit)="activateTimer(1)"
      >
      </app-status-chip>
      <app-status-chip
        class="set-point green status-chip"
        green="true"
        *ngIf="!isPending('settings.timerStart') && nodeData.canEdit && (nodeData.status.timer !== 'OFF' || outputStatus !== 'OFF')"
        param='timerStart'
        [pending]="isPending('settings.timerStart')"
        [editable]='false'
        name="Stop"
        (edit)="activateTimer(0)"
      >
      </app-status-chip>
      <ion-button *ngIf="isPending('settings.timerStart')"
        class="set-point syncing" color="secondary">
        <ion-icon src="assets/custom/icon_sync.svg"></ion-icon> Syncing
      </ion-button>
    </div>

    <app-status-chip
      param='settings.timerStart'
      [pending]="isPending('settings.timerStart')"
      [showstatus]="true"
      name="Output Status"
      data="{{outputStatus}}"
      [setting]="timerInfo.mode"
      [indicator]="outputStatus === 'ON'"
      off="outputStatus==='OFF' || timerInfo.mode ==='OFF'"
      [status]="outputStatus === 'ON'"
      class="status-chip"
    >
    </app-status-chip>

    <app-status-chip
      param='settings.timerStartTime'
      [pending]="isPending('settings.timerStartTime', 'startTime')"
      [editable]='false'
      name="Start Time"
      data="{{timerInfo.startTime}}"
      [setting]="timerInfo.startTime"
      (edit)="showWindRainEdit($event)"
      class="status-chip"
    >
    </app-status-chip>

    <app-status-chip
      param='settings.timerRepeat'
      [pending]="isPending('settings.timerRepeat', 'repeat')"
      [editable]='false'
      name="Repeat"
      data="{{timerInfo.repeat}}"
      [setting]="timerInfo.repeat"
      (edit)="showWindRainEdit($event)"
      class="status-chip"
    >
    </app-status-chip>

    <app-status-chip
      param='settings.timerHoldTime'
      [pending]="isPending('settings.timerHoldTime', 'holdTime')"
      name="Hold Time"
      data="{{timerInfo.holdTime}}"
      [setting]="timerInfo.holdTime"
      (edit)="showWindRainEdit($event)"
      class="status-chip"
    >
    </app-status-chip>

    <div *ngIf="shadeInfo && shadeInfo.shadeEnable" class="shade-settings">
      <div class="title">
        <div>
          <ion-icon name="cloud-outline"></ion-icon>
          <span>Shade Settings</span>
        </div>
      </div>
      <app-status-chip
        param='settings.shadeEnable'
        [pending]="isPending('settings.shadeEnable', 'shadeEnable')"
        name="Shade Mode"
        data="{{shadeInfo.shadeEnable ? 'ON' : 'OFF'}}"
        [setting]="shadeInfo && shadeInfo.shadeSlowRetract ? 'ON' : 'OFF'"
        (edit)="showWindRainEdit($event)"
        class="status-chip"
      >
      </app-status-chip>
      <app-status-chip
        param='settings.shadeSlowRetract'
        [pending]="isPending('settings.shadeSlowRetract', 'shadeSlowRetract')"
        name="Slow Retract"
        data="{{shadeInfo && shadeInfo.shadeSlowRetract ? 'ON' : 'OFF'}}"
        [setting]="shadeInfo && shadeInfo.shadeSlowRetract ? 'ON' : 'OFF'"
        (edit)="showWindRainEdit($event)"
        class="status-chip"
      >
      </app-status-chip>
      <app-status-chip
        param='settings.shadeOpenTime'
        [pending]="isPending('settings.shadeOpenTime', 'shadeOpenTime')"
        name="Open Time"
        data="{{shadeInfo.shadeOpenTime}}"
        [setting]="shadeInfo.shadeOpenTime"
        (edit)="showWindRainEdit($event)"
        class="status-chip"
        *ngIf="shadeInfo"
      >
      </app-status-chip>
      <app-status-chip
        param='settings.shadeStep'
        [pending]="isPending('settings.shadeStep', 'shadeStep')"
        name="Step Percentage"
        data="{{shadeInfo.shadeStep}}"
        [setting]="shadeInfo.shadeStep"
        (edit)="showWindRainEdit($event)"
        class="status-chip"
      >
      </app-status-chip>
      <div class="setting uneditable">
        <label>Extend</label>
        <p>{{shadeInfo && shadeInfo.shadeExtending !== 'OFF' ? "On" : "Off"}}</p>
      </div>
      <app-status-chip
        param='settings.shadePause'
        [pending]="isPending('settings.shadePause', 'shadePause')"
        name="Pause"
        data="{{shadeInfo.shadePause}}"
        [setting]="shadeInfo.shadePause"
        (edit)="showWindRainEdit($event)"
        class="status-chip"
      >
      </app-status-chip>
      <div class="setting uneditable">
        <label>Retract</label>
        <p>{{shadeInfo && shadeInfo.shadeRetracting !== 'OFF' && shadeInfo.shadeExtending !== 'OFF' ? "Off" : "On"}}</p>
      </div>
    </div>
  </section>
</div>

<div class="mister"
  *ngIf="timerInfo.mode === 'MISTER'"
  [class.offline]="!nodeData.online">
  <section> <!-- settings -->
    <div class="title">
      <div>
        <ion-icon name="rainy-outline"></ion-icon>
        <span>Mister Options</span>
      </div>
    </div>

    <app-status-chip
      param='settings.mistDifOnSec'
      [pending]="isPending('settings.mistDifOnSec', 'mistDifOnSec')"
      name="DIF: On"
      data="{{timerInfo.mistDifOnSec}}"
      units="s"
      (edit)="showWindRainEdit($event)"
      class="status-chip"
    >
    </app-status-chip>

    <app-status-chip
      param='settings.mistDifCycleMin'
      [pending]="isPending('settings.mistDifCycleMin', 'mistDifCycleMin')"
      name="DIF: Cycle"
      data="{{timerInfo.mistDifCycleMin}}"
      units="m"
      (edit)="showWindRainEdit($event)"
      class="status-chip"
    >
    </app-status-chip>

    <app-status-chip
      param='settings.mistDayOnSec'
      [pending]="isPending('settings.mistDayOnSec', 'mistDayOnSec')"
      [editable]='false'
      name="DAY: On"
      data="{{timerInfo.mistDayOnSec}}"
      units="s"
      (edit)="showWindRainEdit($event)"
      class="status-chip"
    >
    </app-status-chip>

    <app-status-chip
      param='settings.mistDayCycleMin'
      [pending]="isPending('settings.mistDayCycleMin', 'mistDayCycleMin')"
      [editable]='false'
      name="DAY: Cycle"
      data="{{timerInfo.mistDayCycleMin}}"
      units="m"
      (edit)="showWindRainEdit($event)"
      class="status-chip"
    >
    </app-status-chip>

    <app-status-chip
      param='settings.mistNightOnSec'
      [pending]="isPending('settings.mistNightOnSec', 'mistNightOnSec')"
      [editable]='false'
      name="NITE: On"
      data="{{timerInfo.mistNightOnSec}}"
      units="s"
      (edit)="showWindRainEdit($event)"
      class="status-chip"
    >
    </app-status-chip>

    <app-status-chip
      param='settings.mistNightCycleMin'
      [pending]="isPending('settings.mistNightCycleMin', 'mistNightCycleMin')"
      [editable]='false'
      name="NITE: Cycle"
      data="{{timerInfo.mistNightCycleMin}}"
      units="m"
      (edit)="showWindRainEdit($event)"
      class="status-chip"
    >
    </app-status-chip>
  </section>
</div>
