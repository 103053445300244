import { Component, OnInit } from '@angular/core';
import { NavParams, NavController, PopoverController } from '@ionic/angular';
import { NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-settings-list-popover',
  templateUrl: './settings-list-popover.component.html',
  styleUrls: ['./settings-list-popover.component.scss'],
})
export class SettingsListPopoverComponent implements OnInit {

  private user;
  private subscriptionOptions;
  private ringtone;
  private isBartAdmin;

  constructor(public navCtrl: NavController,
    public navParams: NavParams,
    private popoverController: PopoverController) { }

  ngOnInit() {
    this.user = this.navParams.get('user');
    this.subscriptionOptions = this.navParams.get('subscriptionOptions');
    this.ringtone = this.user ? this.user.ringtone : '';
    this.isBartAdmin = this.navParams.get('isBartAdmin');
  }

  open(route) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        user: this.user,
        subscriptionOptions: this.subscriptionOptions,
        ringtone: this.ringtone,
        isBartAdmin: this.isBartAdmin
      }
    };

    this.popoverController.dismiss();
    this.navCtrl.navigateForward(route, navigationExtras);
  }
}
