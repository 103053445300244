<ion-item class="h4" (click)="view = view ? null : 'searchAndAdd'">
  <ion-label>Search and/or Add Controller</ion-label>
  <ion-icon slot="end" *ngIf="view === 'searchAndAdd'" name="arrow-up-outline"></ion-icon>
  <ion-icon slot="end" *ngIf="view !== 'searchAndAdd'" name="arrow-down-outline"></ion-icon>
</ion-item>

<ion-item class="h4" [class.active]="view === 'unclaimedControllers'" (click)="view = view ? null : 'unclaimedControllers'">
  <ion-label>Unclaimed Controllers</ion-label>
  <ion-icon slot="end" *ngIf="view === 'unclaimedControllers'" name="arrow-up-outline"></ion-icon>
  <ion-icon slot="end" *ngIf="view !== 'unclaimedControllers'" name="arrow-down-outline"></ion-icon>
</ion-item>

<ion-item class="h4" [class.active]="view === 'offlineControllers'" (click)="view = view ? null : 'offlineControllers'">
  <ion-label>Offline Controllers Dashboard</ion-label>
  <ion-icon slot="end" *ngIf="view === 'offlineControllers'" name="arrow-up-outline"></ion-icon>
  <ion-icon slot="end" *ngIf="view !== 'offlineControllers'" name="arrow-down-outline"></ion-icon>
</ion-item>

<app-controller-search-and-add
  *ngIf="view === 'searchAndAdd'" [showSection]="view === 'searchAndAdd'" 
  [adminPage]="true" (loginAs)="loginAsFromComponent($event)"
></app-controller-search-and-add>

<app-unclaimed-controllers
[showSection]="view === 'unclaimedControllers'"
></app-unclaimed-controllers>

<app-offline-controllers
[showSection]="view === 'offlineControllers'"
></app-offline-controllers>