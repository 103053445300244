<div *ngIf="timer" [formGroup]="timerForm" class="vertical timer-form">
  <p *ngIf="!timer.enable">
    Your timer is not enabled. Please enable it at the controller first and then you can configure it.
  </p>

  <div [ngClass]="'timer-graph-' + index"></div>
  
  <div *ngIf="timer.enable && current_page && current_page.fields.includes('name')" class="name">
    <ion-label>Do you want to name this timer output?</ion-label>
    <div><input formControlName="name" type="text" /></div>
  </div>

  <div *ngIf="timer.enable && current_page && current_page && current_page.fields.includes('name')">
    
    <div>
      <ion-label *ngIf="!timer.isMister && timer.isShade">What time during the day (hh:mm) would you like Stage A to start for shade extending (plants covered)?</ion-label>
      <ion-label *ngIf="timer.isMister && !timer.isShade">What time during the day (hh:mm) would you like Stage A to start the first stage of misting?</ion-label>
      <ion-label *ngIf="!timer.isMister && !timer.isShade">What time during the day (hh:mm) would you like start Stage A?</ion-label>
      <ion-input type="text" 
          formControlName="stage_a_start_time" 
          inputmode="tel"
          pattern="[0-9]{1,2}:[0-9]{2}"
          (keyup)="reformatTime($event, 'stage_a_start_time')"
        ></ion-input>
    </div>
  
    <div>
      <ion-label *ngIf="!timer.isMister && timer.isShade">How long should the shade be extended (plants covered)?</ion-label>
      <ion-label *ngIf="!timer.isShade">How long should the first stage last?</ion-label>
      <ion-input type="text" 
        formControlName="stage_a_run_time" 
        inputmode="tel"
        pattern="[0-9]{1,2}:[0-9]{2}"
        (keyup)="reformatTime($event, 'stage_a_run_time')"
      ></ion-input>
    </div>
  
      <div *ngIf="timer.enable && timer.isMister && !timer.isShade">
        <ion-label>During the first stage, how frequently should the misting cycle run (in minutes)?</ion-label>
        <ion-input type="text" 
          formControlName="stage_a_cycle_time" 
          inputmode="tel"
          min="0" max="255"
        ></ion-input>
      </div>
  
      <div *ngIf="timer.enable && timer.isMister && !timer.isShade">
        <ion-label>During the first stage, how long should the misting run (in seconds)?</ion-label>
        <ion-input type="text" formControlName="stage_a_on_time" inputmode="tel" min="0" max="255"></ion-input>
      </div>
  
      <div>
        <ion-label *ngIf="!timer.isMister && timer.isShade">Would you like to extend the shade (plants covered) another time?</ion-label>
        <ion-label *ngIf="timer.isMister && !timer.isShade">Would you like to set up another stage of misting during the day?</ion-label>
        <ion-label *ngIf="!timer.isMister && !timer.isShade">Would you like to turn the timer on again that same day?</ion-label>
        <div class="buttons">
          <ion-button (click)="goToModeDetails()">No</ion-button>
          <ion-button (click)="goToStageB()">Yes</ion-button>
        </div>
      </div>
  </div>

  <div *ngIf="timer.enable && current_page && current_page.key === 'stage_b'" class="timerMode">
    <div >
      <ion-label *ngIf="!timer.isMister && timer.isShade">What time (hh:mm) would you like Stage B to extend the shade (plants covered)?</ion-label>
      <ion-label *ngIf="timer.isMister && !timer.isShade">What time during the day (hh:mm) would you like the stage to start the second stage of misting?</ion-label>
      <ion-label *ngIf="!timer.isMister && !timer.isShade">What time during the day (hh:mm) would you like the second timer stage?</ion-label>
      <ion-input type="text" 
        formControlName="stage_b_start_time"
        inputmode="tel"
        pattern="[0-9]{1,2}:[0-9]{2}"
        (keyup)="reformatTime($event, 'stage_b_start_time')"
      ></ion-input>
    </div>

    <div>
      <ion-label>How long should the second stage last?</ion-label>
      <ion-input type="text" 
          formControlName="stage_b_run_time"
          inputmode="tel"
          pattern="[0-9]{1,2}:[0-9]{2}"
          (keyup)="reformatTime($event, 'stage_b_run_time')"
      ></ion-input>
    </div>    

    <div *ngIf="timer && timer.isMister && !timer.isShade">
      <ion-label>During the first stage, how frequently should the misting cycle run (in minutes)?</ion-label>
      <ion-input type="text" formControlName="stage_b_cycle_time" inputmode="tel" min="0" max="255"></ion-input>
    </div>

    <div *ngIf="timer && timer.isMister && !timer.isShade">
      <ion-label>During the first stage, how long should the misting run (in seconds)?</ion-label>
      <ion-input type="text" formControlName="stage_b_on_time" inputmode="tel" min="0" max="255"></ion-input>
    </div>

    <div *ngIf="timer && timer.isMister && !timer.isShade">
      <ion-label>Misting can also be configured during the Idle time (in between the configured stages).  Would you like to set up misting for the Idle period?</ion-label>
      <div class="buttons">
        <ion-button (click)="goToPrevious()">Previous</ion-button>
        <ion-button (click)="goToModeDetails()">No</ion-button>
        <ion-button (click)="goToIdle()">Yes</ion-button>
      </div>
    </div>

    <div *ngIf="timer && !timer.isMister">
      <div class="buttons">
        <ion-button (click)="goToPrevious()">Previous</ion-button>
        <ion-button (click)="goToModeDetails()">Next</ion-button>        
      </div>
    </div>
  </div>  
  
  <div *ngIf="timer.enable && timer.isShade && current_page && current_page.key === 'shade_settings'" class="shadeMode">
    <div *ngIf="current_page">
      <ion-label>What is the total time, in seconds, that it takes your shade to open?</ion-label>
      <ion-input type="number" formControlName="open_time" inputmode="tel" min="0" max="9999" ></ion-input>
    </div>

    <div *ngIf="current_page">
      <ion-label>Are you going to use the curtain for energy savings?</ion-label>
      <div class="vertical">
        <ion-label><input type="radio" formControlName="slow_retract" value="true" /><span>Yes</span></ion-label>
        <ion-label><input type="radio" formControlName="slow_retract" value="false" /><span>No</span></ion-label>
      </div>
    </div>

    <div *ngIf="current_page">
      <ion-label>You can automatically extend/retract by temperature, light or time. If so, enter the desired temperature.</ion-label>
      <ion-input type="number" formControlName="temp_sp" inputmode="tel" min="0" max="130"></ion-input>
    </div>

    <div *ngIf="current_page">
      <ion-label>Would you like to extend/retract the shade a specific light set point? If so enter the level (in moles per meter squared).</ion-label>
      <ion-input type="number" formControlName="light_sp" inputmode="tel" min="0" max="9999"></ion-input>
    </div>

    <div *ngIf="current_page">
      <ion-label>Would you like to retract your energy curtain in pulses?</ion-label>
      <div class="vertical buttons">
        <ion-label><input type="radio" formControlName="pulse_yes_no" value="true"/><span>Yes</span></ion-label>
        <ion-label><input type="radio" formControlName="pulse_yes_no" value="false"/><span>No</span></ion-label>
      </div>
    </div>

    <div *ngIf="current_page && current_page.fields.includes('step_percent') && timerForm.value && (timerForm.value.pulse_yes_no || (timerForm.value.pulse_yes_no && timerForm.valuetimerForm.value.pulse_yes_no === 'true'))">
      <ion-label>How far (%) would you like to retract and then wait while the temperature regulates?</ion-label>
      <ion-input type="number" formControlName="step_percent"  inputmode="tel" min="0" max="99"></ion-input>
    </div>

    <div *ngIf="current_page && current_page.fields.includes('pause') && timerForm.value && (timerForm.value.pulse_yes_no || (timerForm.value.pulse_yes_no && timerForm.valuetimerForm.value.pulse_yes_no === 'true'))">
      <ion-label>How long would you like it to pause?</ion-label>
      <ion-input type="number" formControlName="pause" inputmode="tel" min="0" max="30"></ion-input>
    </div>

    <div *ngIf="timer && !timer.isMister">
      <div class="buttons">
        <ion-button (click)="goToPrevious()">Previous</ion-button>
        <ion-button (click)="completeForm()">Next</ion-button>        
      </div>
    </div>
  </div>

  <div *ngIf="timer.enable && current_page && current_page.key === 'stage_idle'" class="misterMode">        
    <div>
      <ion-label>During the Idle time, how frequently should the misting cycle run (in minutes)?</ion-label>
      <ion-input type="text" 
          formControlName="stage_idle_cycle_time" 
          inputmode="tel"
          pattern="[0-9]{1,2}:[0-9]{2}"
          (keyup)="reformatTime($event, 'stage_idle_cycle_time')"
        ></ion-input>
    </div>

    <div>
      <ion-label>During the Idle time, how long should the misting run (in seconds)?</ion-label>
      <ion-input type="text" 
          formControlName="stage_idle_on_time" 
          inputmode="tel"
          pattern="[0-9]{1,2}:[0-9]{2}"
          (keyup)="reformatTime($event, 'stage_idle_on_time')"
        ></ion-input>
    </div>    

    <div>
      <div class="buttons">
        <ion-button (click)="goToPrevious()">Previous</ion-button>
        <ion-button (click)="goToModeDetails()">Next</ion-button>        
      </div>
    </div>
  </div>  

  <div *ngIf="timer.enable && current_page && current_page.fields.includes('light_sp')">
    <ion-label>Would you like the timer to turn off at a specific light set point? If so, please enter the light set point (in moles per meter squared).?</ion-label>
    <ion-input type="number" formControlName="light_sp" inputmode="tel" min="0" max="9999"></ion-input>

    <div>
      <div class="buttons">
        <ion-button (click)="goToPrevious()">Previous</ion-button>
        <ion-button (click)="completeForm()">Complete</ion-button>        
      </div>
    </div>
  </div>

  <div *ngIf="!valid()">
    This field is invalid. Please correct before you can go to the next step.
  </div>
</div>
