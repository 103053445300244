<ion-grid *ngIf="nodeLoaded" id="zone"
  [class.view-only]="!nodeData.canEdit"
>
  <ion-row class="main-heading">
    <ion-col class="zone" size="7">
      <b>Wind / Rain Status</b>
    </ion-col>
    <ion-col class="stage" size="5">
      <!-- {{nodeData.status.activated ? "Activated" : "Inactive"}} -->
    </ion-col>
  </ion-row>
  <hr class="hr-line">

  <div class="current">
    <ion-row class="main" *ngIf="nodeData && nodeData.status && nodeData.settings">
      <div class="alarm"
        *ngIf="((nodeData.status.override && nodeData.status.override.activation || isPending('status.override.activation')) ||
               (nodeData.status.override && nodeData.status.override.cancel || isPending('status.override.cancel')) ||
               ((nodeData.status.wind && nodeData.status.wind.alarm) || (nodeData.status.rain && nodeData.status.rain.alarm)))">
        <div>
          <p>Alert</p>
          <p *ngIf="nodeData.status.override && nodeData.status.override.activation && (!nodeData.status.wind.alarm || !nodeData.status.rain.alarm)">The alarm has been manually activated</p>
          <p *ngIf="nodeData.status.override && nodeData.status.override.cancel">The alarm has been manually cancelled</p>
          <p *ngIf="nodeData.status.wind.alarm && nodeData.status.rain.alarm">The alarm for both wind & rain has sounded</p>
          <p *ngIf="nodeData.status.wind.alarm && !nodeData.status.rain.alarm">The alarm for wind has sounded</p>
          <p *ngIf="nodeData.status.rain.alarm && !nodeData.status.wind.alarm">The alarm for rain has sounded</p>
        </div>
        <ion-icon name='warning' class="note-alert warning"></ion-icon>
      </div>
      <div class="alarm offline" *ngIf="!nodeData.online">
        <div>
          <p>OFFLINE</p>
          <p>The node is offline and was last updated: {{nodeData.updatedAt | date:'short'}}</p>
        </div>
        <ion-icon name='eye-off' class="note-alert warning"></ion-icon>
      </div>
      <ion-col class="temp-and-set">
        <div class="temp">
          <h5>Current Wind</h5>
          <div class="temp-inline">
            <p>{{nodeData.status.wind && nodeData.status.wind.current}}<span class="units">{{units}}</span></p>
            <span class="small"> from the </span><p>{{nodeData.status && nodeData.status.wind && nodeData.status.wind.direction}}</p>
          </div>
          <div class="set">
            <p>
              <label>Current Temp</label>
              {{nodeData.status.outsideTemp}}
            </p>
          </div>
        </div>
      </ion-col>
      <ion-col class="temp-and-set">
        <div class="temp">
          <h5>Current Humidity</h5>
          <p>{{nodeData.status.humidity}}%</p>
          <div class="set" *ngIf="!nodeData.canEdit">
            <p>VIEW ONLY</p>
          </div>
          <div class="set">
            <p>
              <label>Clock</label>
              {{nodeData.status.time}}
            </p>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </div>

  <div class="options">
    <!-- Wind Activation -->
    <section class="heat-cool">
      <div class="title">
        <p><ion-icon name="cloudy"></ion-icon>{{nodeData.settings.wind2 ? 'Wind 1 Settings' : 'Wind Settings'}}</p>
      </div>

      <div class="cools">
        <div class="{{isCb() ? 'wind-rain-column' : 'no-column'}}">
          <app-status-chip
            param='settings.wind.activation.setPoint'
            [indicator]='isPending("settings.wind.activation.setPoint")'
            [pending]='isPending("settings.wind.activation.setPoint")'
            [editable]='nodeData.canEdit'
            (edit)="editSetting($event)"
            name="{{nodeData.settings.wind2 ? 'High Activation Speed' : 'Set Point'}}"
            data="{{nodeData.settings.wind && nodeData.settings.wind.activation && nodeData.settings.wind.activation.setPoint}}"
            [status]="'ON'"
            [setting]="'ON'"
            units="{{units}}"
            off="nodeData.settings.wind && nodeData.settings.wind.activation && (nodeData.settings.wind.activation.setPoint < nodeData.status.wind.current)"
            class="status-chip-box"
            [class.highSetPoint]="isCb()"
            underline="{{isCb() ? 'green': ''}}"
          >
          </app-status-chip>

          <app-status-chip
            *ngIf="nodeData.settings && nodeData.settings.wind && nodeData.settings.wind.activation && nodeData.settings.wind.activation.loSetPoint && isCb()"
            param='settings.wind.activation.loSetPoint'
            [indicator]='isPending("settings.wind.activation.loSetPoint")'
            [pending]='isPending("settings.wind.activation.loSetPoint")'
            [editable]='nodeData.canEdit'
            (edit)="editSetting($event)"
            name="Low Activation Speed"
            data="{{nodeData.settings && nodeData.settings.wind && nodeData.settings.wind.activation && nodeData.settings.wind.activation.loSetPoint}}"
            [status]="'ON'"
            [setting]="'ON'"
            units="{{units}}"
            off="nodeData.settings && nodeData.settings.wind && nodeData.settings.wind.activation && (nodeData.settings.wind.activation.loSetPoint < nodeData.status.wind.current)"
            class="status-chip-box"
            [class.loSetPoint]="isCb()"
            underline="{{isCb() ? 'red': ''}}"
          >
          </app-status-chip>

          <app-status-chip
            param='settings.wind.activation.delay'
            [indicator]='isPending("settings.wind.activation.delay")'
            [pending]='isPending("settings.wind.activation.delay")'
            [editable]='nodeData.canEdit'
            (edit)="editSetting($event)"
            name="{{nodeData.settings.wind2 ? 'Activation Delay' : 'Delay'}}"
            data="{{nodeData.settings && nodeData.settings.wind && nodeData.settings.wind.activation && nodeData.settings.wind.activation.delay}}"
            off="nodeData.settings && nodeData.settings.wind && nodeData.settings.wind.activation && (nodeData.settings.wind.activation.delay < nodeData.status.wind.current)"
            class="status-chip-box"
          >
          </app-status-chip>
        </div>

        <table *ngIf="nodeData.settings && nodeData.settings.wind && nodeData.settings.wind.directions"
           class="cardinal-and-intermediate-directions"
           [class.pending]='isPending("settings.windDir1")'>
          <tr class="syncing"*ngIf='isPending("settings.windDir1")'>Changes Syncing With Controller</tr>
          <tr>
            <td (click)="changeDirectionActivationSetting('wind', 'nw')" >
              <span class="high" *ngIf="nodeData.settings.wind.directions && nodeData.settings.wind.directions.nw === 'high'">&nbsp;</span>
              <span>NW</span>
              <span class="low" *ngIf="nodeData.settings.wind.directions && nodeData.settings.wind.directions.nw === 'low'">&nbsp;</span>
            </td>
            <td (click)="changeDirectionActivationSetting('wind', 'n')">
              <span class="high" *ngIf="nodeData.settings.wind.directions && nodeData.settings.wind.directions.n === 'high'">&nbsp;</span>
              <span>N</span>
              <span class="low" *ngIf="nodeData.settings.wind.directions && nodeData.settings.wind.directions.n === 'low'">&nbsp;</span>
            </td>
            <td (click)="changeDirectionActivationSetting('wind', 'ne')">
              <span class="high" *ngIf="nodeData.settings.wind.directions && nodeData.settings.wind.directions.ne === 'high'">&nbsp;</span>
              <span>NE</span>
              <span class="low" *ngIf="nodeData.settings.wind.directions && nodeData.settings.wind.directions.ne === 'low'">&nbsp;</span>
            </td>
          </tr>
          <tr>
            <td (click)="changeDirectionActivationSetting('wind', 'w')">
              <span class="high" *ngIf="nodeData.settings.wind.directions && nodeData.settings.wind.directions.w === 'high'">&nbsp;</span>
              <span>W</span>
              <span class="low" *ngIf="nodeData.settings.wind.directions && nodeData.settings.wind.directions.w === 'low'">&nbsp;</span>
            </td>
            <td>&nbsp;</td>
            <td (click)="changeDirectionActivationSetting('wind', 'e')">
              <span class="high" *ngIf="nodeData.settings.wind.directions && nodeData.settings.wind.directions.e === 'high'">&nbsp;</span>
              <span>E</span>
              <span class="low"  *ngIf="nodeData.settings.wind.directions && nodeData.settings.wind.directions.e === 'low'">&nbsp;</span>
            </td>
          </tr>
          <tr>
            <td (click)="changeDirectionActivationSetting('wind', 'sw')">
              <span class="high" *ngIf="nodeData.settings.wind.directions && nodeData.settings.wind.directions.sw === 'high'">&nbsp;</span>
              <span>SW</span>
              <span class="low" *ngIf="nodeData.settings.wind.directions && nodeData.settings.wind.directions.sw === 'low'">&nbsp;</span>
            </td>
            <td (click)="changeDirectionActivationSetting('wind', 's')">
              <span class="high" *ngIf="nodeData.settings.wind.directions && nodeData.settings.wind.directions.s === 'high'">&nbsp;</span>
              <span>S</span>
              <span class="low" *ngIf="nodeData.settings.wind.directions && nodeData.settings.wind.directions.s === 'low'">&nbsp;</span>
            </td>
            <td (click)="changeDirectionActivationSetting('wind', 'se')">
              <span class="high" *ngIf="nodeData.settings.wind.directions && nodeData.settings.wind.directions.se === 'high'">&nbsp;</span>
              <span>SE</span>
              <span class="low" *ngIf="nodeData.settings.wind.directions && nodeData.settings.wind.directions.se === 'low'">&nbsp;</span>
            </td>
          </tr>
        </table>
      </div>
    </section>

    <!-- Wind Drop Off -->
    <section class="heat-cool">
      <div class="title">
        <p><ion-icon name="cloudy-night"></ion-icon>{{nodeData.settings.wind2 ? 'Wind 1 Drop Off' : 'Wind Drop Off'}}</p>
      </div>

      <div class="cools" *ngIf="nodeData && nodeData.settings && nodeData.settings.wind && nodeData.settings.wind.dropOff">
        <app-status-chip
          param='settings.wind.dropOff.setPoint'
          [indicator]='isPending("settings.wind.dropOff.setPoint")'
          [pending]='isPending("settings.wind.dropOff.setPoint")'
          [editable]='nodeData.canEdit'
          (edit)="editSetting($event)"
          name="{{nodeData.settings.wind2 ? 'Drop Speed' : 'Set Point'}}"
          units="{{units}}"
          data="{{nodeData.settings.wind.dropOff.setPoint}}"
          off="nodeData.settings.wind.dropOff.setPoint < nodeData.status.wind.current"
          class="status-chip-box"
        >
        </app-status-chip>

        <app-status-chip
          param='settings.wind.dropOff.delay'
          [indicator]='isPending("settings.wind.dropOff.delay")'
          [pending]='isPending("settings.wind.dropOff.delay")'
          [editable]='nodeData.canEdit'
          (edit)="editSetting($event)"
          name="Delay"
          data="{{nodeData.settings.wind.dropOff.delay}}"
          off="nodeData.settings.wind.dropOff.delay < nodeData.status.wind.current"
          class="status-chip-box"
        >
        </app-status-chip>
      </div>
    </section>

    <section class="misc">
      <app-status-chip
        class="set-point green times-two status-chip"
        *ngIf="nodeData.status &&
          (((nodeData.status.wind && !nodeData.status.wind.alarm)) && (nodeData.status.override && !nodeData.status.override.activation))
          && !isPending('status.override.activation')"
        param='status.override.activation'
        [editable]='nodeData.canEdit'
        (edit)="activate()"
        name="Force"
        green="true"
      >
      </app-status-chip>
      <app-status-chip
        class="set-point gray times-two status-chip"
        *ngIf="nodeData.canEdit && canViewExtendedOverride"
        param='status.manualOverride'
        [editable]='nodeData.canEdit'
        (edit)="manualOverride($event)"
        name="Extended Override"
        green="true"
      >
      </app-status-chip>
      <app-status-chip
        class="set-point red times-two status-chip"
        *ngIf="nodeData.canEdit && (nodeData.status.override && nodeData.status.override.activation) && !isPending('status.override.activation')"
        param='status.override.cancel'
        [editable]='nodeData.canEdit'
        (edit)="cancel()"
        name="Cancel"
        red="true"
      >
      </app-status-chip>
      <ion-button *ngIf="isPending('status.override.activation')" class="set-point syncing" color="secondary"><ion-icon src="assets/custom/icon_sync.svg"></ion-icon> Syncing</ion-button>      
    </section>
  </div>

  <div class="options wind2" *ngIf="nodeData.settings && nodeData.settings.wind2">
    <!-- Wind Activation -->
    <section class="heat-cool">
      <div class="title">
        <p><ion-icon name="cloudy"></ion-icon>Wind Alarm 2 Settings</p>
      </div>

      <div class="cools">
        <div class="wind-rain-column">
          <app-status-chip
            param='settings.wind2.activation.setPoint'
            [indicator]='isPending("settings.wind2.activation.setPoint")'
            [pending]='isPending("settings.wind2.activation.setPoint")'
            [editable]='nodeData.canEdit'
            (edit)="editSetting($event)"
            name="High Activation Speed"
            data="{{nodeData.settings.wind2.activation.setPoint}}"
            [status]="'ON'"
            [setting]="'ON'"
            units="{{units}}"
            off="nodeData.settings.wind2.activation.setPoint < nodeData.status.wind.current"
            class="status-chip-box"
            [class.highSetPoint]="isCb()"
            underline="{{isCb() ? 'green': ''}}"
          >
          </app-status-chip>

          <app-status-chip
            param='settings.wind2.activation.loSetPoint'
            [indicator]='isPending("settings.wind2.activation.loSetPoint")'
            [pending]='isPending("settings.wind2.activation.loSetPoint")'
            [editable]='nodeData.canEdit'
            (edit)="editSetting($event)"
            name="Low Activation Speed"
            data="{{nodeData.settings.wind2.activation.loSetPoint}}"
            [status]="'ON'"
            [setting]="'ON'"
            units="{{units}}"
            off="nodeData.settings.wind2.activation.loSetPoint < nodeData.status.wind.current"
            class="status-chip-box"
            [class.loSetPoint]="isCb()"
            underline="{{isCb() ? 'red': ''}}"
          >
          </app-status-chip>

          <app-status-chip
            param='settings.wind2.activation.delay'
            [indicator]='isPending("settings.wind.activation.delay")'
            [pending]='isPending("settings.wind.activation.delay")'
            [editable]='nodeData.canEdit'
            (edit)="editSetting($event)"
            name="Activation Delay"
            data="{{nodeData.settings && nodeData.settings.wind && nodeData.settings.wind.activation && nodeData.settings.wind.activation.delay}}"
            off="nodeData.settings && nodeData.settings.wind && nodeData.settings.wind.activation && (nodeData.settings.wind.activation.delay < nodeData.status.wind.current)"
            class="status-chip-box"
          >
          </app-status-chip>
        </div>

        <table *ngIf="nodeData.settings.wind2.directions"
           class="cardinal-and-intermediate-directions"
           [class.pending]='isPending("settings.windDir2")'>
          <tr>
            <td (click)="changeDirectionActivationSetting('wind2', 'nw')">
              <span class="high" *ngIf="nodeData.settings.wind2.directions && nodeData.settings.wind2.directions.nw === 'high'">&nbsp;</span>
              <span>NW</span>
              <span class="low" *ngIf="nodeData.settings.wind2.directions && nodeData.settings.wind2.directions.nw === 'low'">&nbsp;</span>
            </td>
            <td (click)="changeDirectionActivationSetting('wind2', 'n')">
              <span class="high" *ngIf="nodeData.settings.wind2.directions && nodeData.settings.wind2.directions.n === 'high'">&nbsp;</span>
              <span>N</span>
              <span class="low" *ngIf="nodeData.settings.wind2.directions && nodeData.settings.wind2.directions.n === 'low'">&nbsp;</span>
            </td>
            <td (click)="changeDirectionActivationSetting('wind2', 'ne')">
              <span class="high" *ngIf="nodeData.settings.wind2.directions && nodeData.settings.wind2.directions.ne === 'high'">&nbsp;</span>
              <span>NE</span>
              <span class="low" *ngIf="nodeData.settings.wind2.directions && nodeData.settings.wind2.directions.ne === 'low'">&nbsp;</span>
            </td>
          </tr>
          <tr>
            <td (click)="changeDirectionActivationSetting('wind2', 'w')">
              <span class="high" *ngIf="nodeData.settings.wind2.directions && nodeData.settings.wind2.directions.w === 'high'">&nbsp;</span>
              <span>W</span>
              <span class="low" *ngIf="nodeData.settings.wind2.directions && nodeData.settings.wind2.directions.w === 'low'">&nbsp;</span>
            </td>
            <td>&nbsp;</td>
            <td (click)="changeDirectionActivationSetting('wind2', 'e')">
              <span class="high" *ngIf="nodeData.settings.wind2.directions && nodeData.settings.wind2.directions.e === 'high'">&nbsp;</span>
              <span>E</span>
              <span class="low" *ngIf="nodeData.settings.wind2.directions && nodeData.settings.wind2.directions.e === 'low'">&nbsp;</span>
            </td>
          </tr>
          <tr>
            <td (click)="changeDirectionActivationSetting('wind2', 'sw')">
              <span class="high" *ngIf="nodeData.settings.wind2.directions && nodeData.settings.wind2.directions.sw === 'high'">&nbsp;</span>
              <span>SW</span>
              <span class="low" *ngIf="nodeData.settings.wind2.directions && nodeData.settings.wind2.directions.sw === 'low'">&nbsp;</span>
            </td>
            <td  (click)="changeDirectionActivationSetting('wind2', 's')">
              <span class="high" *ngIf="nodeData.settings.wind2.directions && nodeData.settings.wind2.directions.s === 'high'">&nbsp;</span>
              <span>S</span>
              <span class="low" *ngIf="nodeData.settings.wind2.directions && nodeData.settings.wind2.directions.s === 'low'">&nbsp;</span>
            </td>
            <td (click)="changeDirectionActivationSetting('wind2', 'se')">
              <span class="high" *ngIf="nodeData.settings.wind2.directions && nodeData.settings.wind2.directions.se === 'high'">&nbsp;</span>
              <span>SE</span>
              <span class="low" *ngIf="nodeData.settings.wind2.directions && nodeData.settings.wind2.directions.se === 'low'">&nbsp;</span>
            </td>
          </tr>
        </table>
      </div>
    </section>

    <!-- Wind Drop Off -->
    <section class="heat-cool">
      <div class="title" *ngIf="nodeData && nodeData.settings && nodeData.settings.wind2 && nodeData.settings.wind2.dropOff">
        <p><ion-icon name="cloudy-night"></ion-icon>Wind 2 Drop Off</p>
      </div>

      <div class="cools" *ngIf="nodeData && nodeData.settings && nodeData.settings.wind2 && nodeData.settings.wind2.dropOff">
        <app-status-chip
          param='settings.wind2.dropOff.setPoint'
          [indicator]='isPending("settings.wind2.dropOff.setPoint")'
          [pending]='isPending("settings.wind2.dropOff.setPoint")'
          [editable]='nodeData.canEdit'
          (edit)="editSetting($event)"
          name="Drop Speed"
          units="{{units}}"
          data="{{nodeData.settings.wind2.dropOff.setPoint}}"
          off="nodeData.settings.wind2.dropOff.setPoint < nodeData.status.wind2.current"
          class="status-chip-box"
        >
        </app-status-chip>

        <app-status-chip
          param='settings.wind2.dropOff.delay'
          [indicator]='isPending("settings.wind2.dropOff.delay")'
          [pending]='isPending("settings.wind2.dropOff.delay")'
          [editable]='nodeData.canEdit'
          (edit)="editSetting($event)"
          name="Delay"
          data="{{nodeData.settings.wind2.dropOff.delay}}"
          off="nodeData.settings.wind2.dropOff.delay < nodeData.status.wind2.current"
          class="status-chip-box"
        >
        </app-status-chip>
      </div>
    </section>
  </div>

  <div class="options" *ngIf="nodeData && nodeData.settings && nodeData.settings.lightSource !== 'NONE'">
    <div class="title">
      <p><ion-icon name="sunny"></ion-icon>Light Settings</p>
    </div>
    <app-light-sensor
      [daily_light_integral]="nodeData && nodeData.status && nodeData.status.dli"
      [light_value]="nodeData && nodeData.status && nodeData.status.light"
      [nodeData]="nodeData"
      [misterSettings]="nodeData.settings.group1Mister && nodeData.settings.group1Mister.length > 0 && nodeData.settings.group1Mister[1]"
      [misterIndex]="1"
      [pending]="nodeData.settings.group1Mister && nodeData.settings.group1Mister.length > 0 && nodeData.settings.group1Mister[1].pending"
    ></app-light-sensor>
  </div>

  <hr class="hr-line">
  <div class="last-update">
    <span>Last Update</span>
    <span>{{nodeData.updatedAt | date:'short'}}</span>
  </div>

  <div class="last-update audit-trail" (click)="goToAuditTrailPage()">
    <a>View Audit Trail</a>
  </div>

</ion-grid>
