import * as d3 from 'd3';
import * as d3Array from 'd3-array';
import * as d3Axis from 'd3-axis';
import * as d3Scale from 'd3-scale';
import { select, selectAll } from "d3-selection";
import * as d3Shape from 'd3-shape';
import * as d3TimeFormat from 'd3-time-format';
import moment from 'moment';
import * as momentTz from 'moment-timezone';

var svg;
const chartLabel = "Log Data";
var margin = {top: 40, right: 75, bottom: 80, left: 90};
var width;
var tooltip_width;
var height;
var data;
var color;
var yDomain;
var xAxis;
var yAxis;
var gX;
var gY;
let xScale, yScaleGlobal, yLinear, lightYScale, zoomScale;
let rectangle_width = 1;
let rectangleScale;
let difDayNiteScale;
let line_g;
let font_size_small = '0.85em';
let font_size = '1em';
let num_ticks = 14;
let x_offset_headings = '-10';
let x_offset_headings_large = '-25';
let keys_to_show;
let has_light_logging = false;
let platformWidth = 800;

let small_screen_heights = {
  temps: 126, onOffKeys: 10, percentKeys: 22, light: 133, padding: 10, zoomToolbarHeight: 65, x_axis_label: 45
}

let large_screen_heights = {
  temps: 190, onOffKeys: 15, percentKeys: 36, light: 200, padding: 10, zoomToolbarHeight: 65, x_axis_label: 45
}
let current_height_info;

function * range ( start, end, step = 1 ) {
  let state = start;
  while ( state < end ) {
    yield state;
    state += step;
  }
  return;
};

const initZoomSubChart = (chartId, brush, y, x, data_width) => {
  let viewBoxWidth = data_width > 500 ? width + (margin.left + margin.right + (tooltip_width * 1.5)) :  width + (margin.left + margin.right)
  let zoomSubChart = select(chartId)
    .append('svg')
    .attr('id', "zoomSubChart")
    .attr("viewBox", `0 -10 ${viewBoxWidth} 65`)
    .style("display", "block");

  zoomSubChart
    .append('text')
    .attr('x', '0')
    .attr('dx', '5em')
    .attr('fill', "#000")
    .text("Zoom Toolbar (click, hold and drag to zoom)");

  let onResetG = zoomSubChart.append('g')
    .attr('height', 75)
    .attr('y', y+current_height_info.padding)
    .attr('x', (margin.left * 2))
    .attr("width", data_width - (margin.right + margin.left))
    .attr('class', 'brush')
    .attr('transform', 'translate(' + margin.left + ',' + (margin.top !== 0 ? 10 : 5) + ')')
    .attr('fill', "#FD62A1")
    .call(brush);

  xAxis = zoomSubChart.append('g')
    .attr('class', 'axis axis--x')
    .attr('transform', 'translate(' + margin.left + ',' + (margin.top !== 0 ? 10 : 5) + ')')
    // tickValues to include dif/day/nite start/stop
    .call(d3Axis.axisBottom(x)
      .ticks(num_ticks)
      .tickFormat(d3TimeFormat.timeFormat("%m/%d %H:%M")))
    .attr('font-size', '10px')
    .selectAll("text")
      .attr("transform", "rotate(35)")
      .attr("dx", "0.74em")
      .attr("dy", "-.5em")
      .style("text-anchor", "start");

  return {chart: zoomSubChart, onReset: (transform, identity) => {
    zoomSubChart.select(".brush").call(brush.move, null);
  }};
}

const init = (chartId, data_width, height) => {
  console.log(`init height: ${height}, ${JSON.stringify(margin)}, data_width: ${data_width}, screen width: ${window.screen.width}, width: ${width}`)
  svg = select(chartId)
    .append('svg')
    .attr('height', height - margin.bottom - margin.top)
    .attr('width', width)
    .attr('id', 'logs-chart')
    .attr("viewBox", `0 0 ${width + margin.right + tooltip_width} ${height}`)
    // .attr('width', width + margin.left + margin.right)
    // .attr('height', height + margin.top + margin.bottom);

  const g = svg.append('g')
    .attr('width', data_width)
    .attr('transform', 'translate(' + margin.left + ', -' + (margin.top) + ')')
    .attr("pointer-events","all")    
    
  line_g = g
    .append("svg:clipPath")
      .attr("id", "lines")
      .append("svg:rect")
        .attr("id", "line-rect")
        .attr("x", 0)
        .attr("y", '0')
        .attr("width", data_width)
        .attr("height", height)
        .attr('fill', `transparent`)

  return {svg: svg, g: g}
}

const keys_to_data_key = {
  "Heat 1": "h1",
  "Heat 2": "h2",
  "Cool 1": "c1",
  "Cool 2": "c2",
  "Cool 3": "c3",
  "Cool 4": "c4",
  "Heat 3": "h3",
  "% 1": "v1p",
  "% 2": "v2p",
  "% 3": "v3p",
  "% 4": "v4p",
  "Alarm": "alarm"
}

const getMinAndMaxForKeys = (data, keys) => {
  const minMaxData = data.reduce((minMaxAcc, timeLogData) => {
    keys.forEach((key) => {
      timeLogData.logTime = moment(timeLogData.createdAt).format("MMM Do YY, HH:mm");
      if (['h1','h2','h3','c1','c2','c3','c4','t1','t2','t3','t4','t5','t6','t7','t8','t9','t10'].includes(key)){
        timeLogData[key] = (parseInt(timeLogData[key]) == 1) ? "ON" : "OFF";
      }
      if (['aa','w1a','w2a','ra','orf','orb'].includes(key)){
        timeLogData[key] = (timeLogData[key] == "true") ? "ON" : "OFF";
      }
      const data_for_key = timeLogData[keys_to_data_key[key]]
      if(data_for_key === "ON" || (key === 'alarm' && data_for_key !== "NONE" && data_for_key !== "OFF") ||
          (['v1p', 'v2p', 'v3p', 'v4p'].includes(key) && data_for_key != "99" && data_for_key !== "OFF")) {
            if(!minMaxAcc[key]) {
              minMaxAcc[key] = {min: timeLogData.raw, max: timeLogData.raw, raw: timeLogData.raw}
            } else {
              const day_time = moment(timeLogData.raw);
              const currentMax = minMaxAcc[key] ? moment(minMaxAcc[key].max) : undefined;
              const currentMin = minMaxAcc[key] ? moment(minMaxAcc[key].min) : undefined;

              if((!currentMax || !day_time) || (currentMax.isBefore(day_time))) {
                minMaxAcc[key].max = timeLogData.raw;
              }

              if((!currentMin || !day_time) || (day_time.isBefore(currentMin))) {
                minMaxAcc[key].min = timeLogData.raw;
              }
            }
      }
    });

    return minMaxAcc;
  }, {});

  return minMaxData;
}

const minMaxLight = (timeLog, minMaxAcc) => {
  const currentMinLight = parseInt(minMaxAcc.min_light)
  const currentMaxLight = parseInt(minMaxAcc.max_light)
  if(timeLog.ll) {
    const currentMaxLight = parseInt(minMaxAcc.max_light)
    const currentMinLight = parseInt(minMaxAcc.min_light)
    const light_level = parseInt(timeLog.ll)
    if((!currentMaxLight && currentMaxLight != 0) || (light_level > currentMaxLight)) {
      minMaxAcc.max_light = light_level;
    }

    if((!currentMinLight && currentMinLight != 0) || (light_level < currentMinLight)) {
      minMaxAcc.min_light = light_level;
    }
  }

  return minMaxAcc;
}

const createLightScale = (chartData, y) => {
  var minAndMax = chartData.reduce((minMaxAcc, timeLog) => {
    return minMaxLight(timeLog, minMaxAcc);
  }, {min_light: undefined, max_light: undefined})

  let y_top = minAndMax.max_light === minAndMax.min_light ? y : (y + current_height_info.light)
  return {scale: d3Scale.scaleLinear([(minAndMax.max_light), (minAndMax.min_light)], [(y + current_height_info.padding), y_top]), updated_y: (y_top + current_height_info.padding)};
}

const draw24HourDividingLines = (data, g, height) => { 
  let h = (width < 500) ? (height + margin.top) : (height);

  if(data) {
    data = data.filter((d) => {
      let midnight = moment(d.createdAt).clone().startOf('day');
      var diffMinutes = moment(d.createdAt).diff(midnight, 'minutes');
      return diffMinutes < 4;
    })

    g.selectAll("bar")
      .data(data)
      .enter()
      .append("rect")
      .style("fill", "#000")
      .attr("fill-opacity","1")
      .attr('class', `twentyfourhourline`)
      .attr("x", function(d) {
        return rectangleScale(d.createdAt);
      })
      .attr("width", (d) => { return rectangleScale.bandwidth() })
      .attr("y", margin.top)
      .attr("height", h)
      .attr("pointer-events","all")
      .attr('clip-path', 'url("#lines")');
  } else {
    g.selectAll('.twentyfourhourline')
       .transition()
       .duration(1000)
       .attr("x", function(d) {
          let xPoint = rectangleScale(d.createdAt)
          return xPoint ? xPoint : '-800';
       })
       .attr("width", (d) => { return rectangleScale.bandwidth() })
       .style("fill", "#000")
       .attr("fill-opacity","1")
  }
}

const initAxes = (chartData, keys, modelType, keys_to_show) => {
  var minAndMax = chartData.reduce((minMaxAcc, timeLog) => {
    const currentMax = parseInt(minMaxAcc.max)
    const currentMin = parseInt(minMaxAcc.min)

    const timeLogT1 = parseInt(timeLog.ct1)
    const timeLogT2 = parseInt(timeLog.ct2)
    const timeLogS1 = parseInt(timeLog.sp1)
    const timeLogS2 = parseInt(timeLog.sp2)

    if((!currentMax && currentMax != 0) || (timeLogS1 > currentMax) || (timeLogS2 > currentMax)
        || (timeLogT1 > currentMax) || (timeLogT2 > currentMax)) {
      var max_of_two = Math.max(timeLogS1, timeLogS2, timeLogT1, timeLogT2)
      minMaxAcc.max = max_of_two;
    }

    if((!currentMin && currentMin != 0) || timeLogS1 < currentMin || timeLogS2 < currentMin
        || timeLogT1 < currentMin || timeLogT2 < currentMin) {
      var min_of_two = Math.min(timeLogS1, timeLogS2, timeLogT1, timeLogT2)
      minMaxAcc.min = min_of_two;
    }

    minMaxAcc = minMaxLight(timeLog, minMaxAcc);    
    return minMaxAcc;
  }, {min: undefined, max: undefined, min_light: undefined, max_light: undefined, });

  const getMinMaxArray = (minMax) => {
    var top = (minMax.max + 5)
    var bottom = (minMax.min - 5)
    return Array.from(range(bottom, top, Math.round((top - bottom) / 5)))
  }

  var half_height = ((height - margin.top - margin.bottom) / 3)
  const yRange = Array.from(range((half_height * 2), (height - margin.top), ((height - margin.top) - (half_height * 2)) / keys.length))
  const y = d3Scale.scaleBand()
                   .domain(keys)
                   .rangeRound([(half_height * 2), (height - margin.top)])
                   .paddingInner(0.1);

  let temp_height = current_height_info.temps;
  const yLinear = d3Scale.scaleLinear([(minAndMax.max + 5), (minAndMax.min - 5)], [margin.top, temp_height]);

  let min_light_y = keys_to_show.includes('temps') ? (temp_height + current_height_info.padding) : margin.top;
  let {scale, updated_y} = createLightScale(data, min_light_y)
  return {yScale: y, yLinear: yLinear, lightY: scale, heightAfterTemp: temp_height + current_height_info.padding};
}

const mousemoveTooltip = function (d, i, g) {  
  if(i) {
    const tooltip = g.select('.tooltip_text');

    tooltip.transition().attr("width",tooltip_width);

    tooltip.select("tspan#tooltip_title").text(`${moment(i.createdAt).format('MM/DD/YYYY HH:ss')}`);
    tooltip.select("tspan#tooltip_subtitle").text(`Stage: ${i.stage}`);

    tooltip.select("tspan#ct1").text(`${i.ct1}`);
    tooltip.select("tspan#ct2").text(`${i.ct2}`);
    if(i.ct3 && i.ct3 < 250) tooltip.select("tspan#ct3").text(`${i.ct3}`);

    tooltip.select("tspan#sp1").text(`${i.sp1}`);
    tooltip.select("tspan#sp2").text(`${i.sp2}`);
    if(i.ct3 && i.ct3 < 250) tooltip.select("tspan#sp3").text(`${i.sp3}`);
        
    tooltip.select("tspan#h1").text(`${i.h1}`);
    tooltip.select("tspan#h2").text(`${i.h2}`);

    tooltip.select("tspan#c1").text(`${i.c1}`);
    tooltip.select("tspan#c2").text(`${i.c2}`);
    tooltip.select("tspan#c3").text(`${i.c3}`);
    tooltip.select("tspan#c4").text(`${i.c4}`);

    tooltip.select("tspan#v1").text(`${i.v1p}`);
    tooltip.select("tspan#v2").text(`${i.v2p}`);
    tooltip.select("tspan#v3").text(`${i.v3p}`);
    tooltip.select("tspan#v4").text(`${i.v4p}`);
  }
}

const drawDifDayNiteRectangles = (data, g, height) => {  
  let colors = {'DIF': '#a968bb', 'DAY': '#cc9000', 'NITE': '#33668e'}
  let h = (width < 500) ? (height + margin.top) : height;
  if(data) {
    g.selectAll("bar")
      .data(data)
      .enter()
      .append("rect")
      .style("fill", (d) => {
        return colors[d.stage]
      })
      .attr("fill-opacity","0.3")
      .attr('class', `difDayNiteBar`)
      .attr("x", function(d) {
        return rectangleScale(d.createdAt);
      })
      .attr("width", (d) => { return rectangleScale.bandwidth() })
      .attr("y", margin.top)
      .attr("height", h)
      .attr("pointer-events","all")
      .on('mouseover', (d, i) => { 
        if(platformWidth > 600) { g.select('.tooltip').style('display', 'inline'); }
      })
      .on("mouseleave", (d, i) => { 
          g.select('.tooltip').style('display', null);
          g.select('.tooltip').select('rect').attr('width', tooltip_width);
      })
      .on('mousemove', (d, i) => { if(platformWidth > 600) { mousemoveTooltip(d, i, g)} })
      .attr('clip-path', 'url("#lines")');
  } else {
    g.selectAll('.difDayNiteBar')
       .transition()
       .duration(1000)
       .attr("x", function(d) {
         let xPoint = rectangleScale(d.createdAt)
         return xPoint ? xPoint : '-45';
       })
       .attr("width", (d) => { return rectangleScale.bandwidth() })
       .style("fill", (d) => {
         return colors[d.stage]
       })
       .attr("fill-opacity","0.3")
  }
}

const drawTimeAxes = (g, x, y, zoom = false) => {
  if(zoom) {
    g.select('.axis--x')
      .transition()
      .duration(300)
      .call(d3Axis.axisBottom(x)
          .ticks(num_ticks)
          .tickFormat(d3TimeFormat.timeFormat("%m/%d %H:%M")))
      .attr('font-size', font_size_small)
        .selectAll("text")
          .attr("transform", "rotate(65)")
          .attr("dx", "1em")
          .attr("dy", "-.5em")
          .style("text-anchor", "start");
  } else {
    // tickValues to include dif/day/nite start/stop
    xAxis = g.append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', 'translate(0,' + y + ')')
      .call(d3Axis.axisBottom(x)
        .ticks(num_ticks)
        .tickFormat(d3TimeFormat.timeFormat("%m/%d %H:%M")))
      .attr('font-size', font_size_small)
      .selectAll("text")
        .attr("transform", "rotate(65)")
        .attr("dx", "1em")
        .attr("dy", "-.5em")
        .style("text-anchor", "start");
  }
}

const drawTemperatureAxes = (g, x, y, yLinear, chartLabel) => {
   yAxis = g.append('g')
    .attr('class', 'axis axis--y')
    .call(d3Axis.axisLeft(yLinear)
     .ticks(6)
     .tickSize(-(width - margin.right)))
    .attr('font-size', font_size_small)
    .append('text')
      .attr('class', 'axis-title')
      .attr('x', (width + margin.right + margin.left))
      .attr('dx', '5em')
      .attr('fill', 'rgb(92, 191, 145)')
      .text(chartLabel);

    g.append('text')
      .attr('x', x_offset_headings)
      .attr('y', (y.range()[1] - y.range()[0] / 2) + y.range()[0])
      .attr('stroke', 'rgb(92, 191, 145)')
      .style("font-size", font_size)
      .attr("text-anchor", "end")
      .attr('class', `label`)
      .text('Log Data')
}

const createD3Lines = (x, yLinear) => {
  var set_point_1_line = d3Shape.line()
    .x(function(d, i) { return x(d.createdAt); })
    .y(function(d) {return yLinear(d.sp1);});

    var set_point_2_line = d3Shape.line()
      .x(function(d, i) { return x(d.createdAt); })
      .y(function(d) { return yLinear(d.sp2); });

    var temp_1_line = d3Shape.line()
      .x(function(d, i) { return x(d.createdAt); })
      .y(function(d) { return yLinear(d.ct1); });

    var temp_2_line = d3Shape.line()
      .x(function(d, i) { return x(d.createdAt); })
      .y(function(d) { return yLinear(d.ct2); });

    return {
      sp1: set_point_1_line,
      sp2: set_point_2_line,
      temp1: temp_1_line,
      temp2: temp_2_line
    }
}

function prepareTooltip(g, data) {  
  const tooltip = g.append("g")
    .attr("class", "tooltip")
    .attr("height", 400)
    .attr('width', '215')
    .attr('y', margin.top)
    .attr('border-left', '1px solid black')
    .style("display", "flex")
    .attr('transform', `translate(${width-20}, ${margin.top*1.5})`);

  tooltip.append("rect")
    .attr("height", 400)
    .attr('width', tooltip_width)
    .attr('stroke', "#858585")
    .attr("fill", 'white')
    .attr('break-word', 'break-all')
    .style("opacity", 0.9);

  tooltip.append("text")
    .attr("x", 10)
    .attr('dy', '20')
    .text('Data')  

  let text_zone = tooltip.append("text")
    .attr("x", 10)
    .attr("dy", "0")
    .attr('width', tooltip_width)
    .style('font-family', 'sans-serif')
    .attr("font-size", "11px")
    .style('text-anchor', 'start')
    .attr('class', 'tooltip_text')
    // .style('fill', 'black');

  text_zone.append("tspan")
    .attr('id', 'tooltip_title')
    .attr('x', 45)
    .attr('dy', 20)
    .attr('font-size', '14px')
    .attr('font-weight', 800)
    .attr('width', tooltip_width)

  text_zone.append("tspan")
    .attr('id', 'tooltip_subtitle')
    .attr('x', 10)
    .attr('dy', 25)
    .attr('font-size', '12px')
    .attr('font-weight', 600)
    .attr('width', tooltip_width)

  text_zone.append('tspan')
    .attr('id', 'tooltip_zones')
    .attr('x', 10)
    .attr('y', 60)
    .text('Zone: ')  

  let zones = ['1', '2', '3']
  zones.forEach((id, i) => { 
    text_zone.append(`tspan`)
      .attr('id', `z${id}`)
      .attr('x', (70 + (i * 25)))
      .attr('y', 60)
      .text(id); 
  });  

  text_zone.append('tspan')
    .attr('id', 'tooltip_current_temps')
    .attr('x', 10)
    .attr('y', 85)
    .attr('width', tooltip_width)
    .text('Temp: ')  

  let current_temps = ['ct1', 'ct2', 'ct3']
  current_temps.forEach((id, i) => { 
    text_zone.append('tspan')
      .attr('id', `${id}`)
      .attr('x', (70 + (i * 25)))
      .attr('y', 85)
  });

  text_zone.append('tspan')
    .attr('id', 'tooltip_setpoints')
    .attr('y', 110)
    .attr('x', 10)    
    .attr('width', tooltip_width)
    .text('SP:')

  let sps = ['sp1', 'sp2', 'sp3']
  sps.forEach((id, i) => { 
    text_zone.append('tspan').attr('id', `${id}`)
      .attr('y', 110)
      .attr('x', (70 + (i * 25)))
  });

  text_zone.append("tspan")
  .attr('id', 'tooltip_heats')
  .attr("x", 10)
  .attr("y", 135)
  .text('Outputs')

  let opts = ['1', '2', '3', '4']
  opts.forEach((id, i) => { 
    text_zone.append(`tspan`)
      .attr('id', `z${id}`)
      .attr('x', (70 + (i * 30)))
      .attr('y', 135)
      .text(id); 
  });  

  text_zone.append("tspan")
    .attr('id', 'tooltip_heats')
    .attr("x", 10)
    .attr("y", 160)
    .text('Heat:')

  let heats = ['h1', 'h2']
  heats.forEach((id, i) => { 
    text_zone.append('tspan').attr('id', `${id}`)
    .attr('x', (70 + (i * 30)))
      .attr('y', 160); 
  });

  text_zone.append("tspan")
    .attr('id', 'tooltip_cools')
    .attr("x", 10)
    .attr("y", 185)
      .text('Cool:')

  let cools = ['c1', 'c2', 'c3', 'c4']
  cools.forEach((id, i) => { 
    text_zone.append('tspan').attr('id', `${id}`)
    .attr('x', (70 + (i * 30)))
    .attr('y', 185)
  });

  text_zone.append("tspan")
    .attr('id', 'tooltip_vents')
    .attr("x", 10)
    .attr("y", 210)
      .text('Vents: ')

  let vents = ['v1', 'v2', 'v3', 'v4']
  vents.forEach((id, i) => { 
    text_zone.append('tspan').attr('id', `${id}`)
      .attr('x', (70 + (i * 30)))
      .attr('y', 210)
  });

  text_zone.append("tspan")
    .attr('id', 'tooltip_l4')
    .attr("x", 10)
    .attr("dy", "14");
}

const drawLines = (chartData, g, x, y, yLinear) => {
  let lines = createD3Lines(x, yLinear);
  let strokeWidth = width > 500 ? 3 : 1
  if(chartData) {
    g.append("svg:path")
      .datum(chartData)
      .attr("stroke", "#5CBF91")
      .attr("stroke-width", strokeWidth)
      .attr("stroke-opacity", 70)
      .attr("fill", "none")
      .attr('class', 'sp1 line')
      .attr("pointer-events","all")
      .attr("d", lines.sp1)
      .attr('clip-path', 'url("#lines")')

    g.append("svg:path")
      .datum(chartData)
      .attr("stroke", "#5DA2BF")
      .attr("stroke-width", strokeWidth)
      .attr("stroke-opacity", 70)
      .attr("fill", "none")
      .attr('class', 'sp2 line')
      .attr("d", lines.sp2)
      .attr('clip-path', 'url("#lines")')

    g.append("svg:path")
      .datum(chartData)
      .attr("stroke", "#e0b500")
      .attr("stroke-width", strokeWidth)
      .attr("stroke-opacity", 70)
      .attr("fill", "none")
      .attr('class', 'temp1 line')
      .attr("d", lines.temp1)
      .attr('clip-path', 'url("#lines")')

    g.append("svg:path")
      .datum(chartData)
      .attr("stroke", "#a94633")
      .attr("stroke-width", strokeWidth)
      .attr("stroke-opacity", 70)
      .attr("fill", "none")
      .attr('class', 'temp2 line')
      .attr("d", lines.temp2)
      .attr('clip-path', 'url("#lines")')
  } else {
    g.select('.sp1')
       .transition()
       .duration(1000)
       .attr("d", lines.sp1)

    g.select('.sp2')
      .transition()
      .duration(1000)
      .attr("d", lines.sp2)

    g.select('.temp1')
       .transition()
       .duration(1000)
       .attr("d", lines.temp1)

    g.select('.temp2')
      .transition()
      .duration(1000)
      .attr("d", lines.temp2)
  }
}

const drawLightLines = (chartData, g, x, yScale, y, width) => {
  if(yScale) {
    var light_level_line = d3Shape.line()
      .x(function(d, i) { return x(d.createdAt); })
      .y(function(d) { return yScale(d.ll); });

    if(chartData) {
        yAxis = g.append('g')
         .attr('class', 'axis axis--y')
         .call(d3Axis.axisLeft(yScale)
          .ticks(5)
          .tickSize(-width))
         .attr('font-size', font_size_small);

       g.append('text')
         .attr('x', '-40')
         .attr('y', (y + (yScale.range()[1] - y) / 2))
         .attr('stroke', 'rgb(92, 191, 145)')
         .style("font-size", font_size)
         .attr("text-anchor", "end")
         .attr('class', `label`)
         .text('Light')

       g.append("path")
        .datum(chartData)
        .attr("stroke", "#e65722")
        .attr("stroke-width", 3)
        .attr("stroke-opacity", 70)
        .attr("fill", "none")
        .attr('class', 'light_level_line line')
        .attr("d", light_level_line)
        .attr('clip-path', 'url("#lines")');
      } else {
        g.select('.light_level_line')
           .transition()
           .duration(1000)
           .attr("d", light_level_line)
      }

    return yScale.range()[1] + current_height_info.padding;
  } else {
    return y + current_height_info.padding;
  }
}

//draw a vent open percent graph
const drawPercent = (data, g, x, y, keyInfo) => {
  var top = y + current_height_info.padding;
  var bottom = y + current_height_info.padding + current_height_info.percentKeys;
  const yLinear = d3Scale.scaleLinear([100, 0], [top, bottom]);
  const hLinear = d3Scale.scaleLinear([0, 100], [0, current_height_info.percentKeys]);
  const yLinearAxis = d3Axis.axisLeft(yLinear)
                            .ticks(1)
                            .tickSize(-(width - margin.right));

  if(data) {
    g.selectAll("bar")
      .data(data)
      .enter()
      .append("rect")
      .style("fill", keyInfo.color)
      .attr('class', `bar ${keyInfo.key}PercentBar percentBar`)
      .attr("x", function(d) {
        return rectangleScale(d.createdAt);
      })
      .attr("width", (d) => { return rectangleScale.bandwidth() })
      .attr("y", function(d) { return yLinear(d[keyInfo.key]); })
      .attr("height", function(d) { return hLinear(d[keyInfo.key]); })
      .attr('clip-path', 'url("#lines")');

    g.append('g')
      .attr('class', `axis axis--y percent`)
      .call(yLinearAxis)
      .attr('font-size', font_size_small)
      .append('text')
      .attr('class', 'axis-title')
      .attr('x', (width + margin.right + margin.left))
      .attr('dx', '5em')
      .attr('fill', 'rgb(92, 191, 145)')
      // .text(chartLabel);

    g.append('text')
      .attr('x', x_offset_headings_large)
      .attr('y', top + 15)
      .attr('stroke', 'rgb(92, 191, 145)')
      .style("font-size", font_size)
      .attr("text-anchor", "end")
      .attr('class', `label`)
      .text(keyInfo.name)
  } else {
    g.selectAll(`.${keyInfo.key}PercentBar`)
      .transition()
      .duration(500)
      .attr("width", (d) => { return rectangleScale.bandwidth() })
      .attr("x", function(d) { return rectangleScale(d.createdAt); })
      .attr("height", function(d) { return hLinear(d[keyInfo.key]); })
  }

  return y + current_height_info.percentKeys + current_height_info.padding;
}

//draw a on/off graph for heats and cools
const drawOnOff = (data, g, x, y, keyInfo) => {
  const yLinear = d3Scale.scaleLinear([1, 0], [y, (y + current_height_info.onOffKeys)]);
  if(data) {
    g.append('line')
      .attr("stroke-width", 1)
      .attr("stroke", "black")
      .attr("x1", 0)
      .attr("y1", y+7)
      .attr("x2", width - margin.right)
      .attr("y2", y+7);

    g.selectAll("bar")
      .data(data)
      .enter().append("rect")
        .style("fill", keyInfo.color)
        .attr('class', `bar onOffBar ${keyInfo.key}OnOff`)
        .attr("x", function(d) { return rectangleScale(d.createdAt); })
        .attr("width", (d) => { return rectangleScale.bandwidth() })
        .attr("y", function(d) { return (d[keyInfo.key] == 'ON' || d[keyInfo.key] === 1) ? y+1:y+6; })
        .attr("height", function(d) { return (d[keyInfo.key] === 'ON' || d[keyInfo.key] === 1) ? 11:2; })
        .attr('clip-path', 'url("#lines")');

    g.append('text')
      .attr('x', x_offset_headings)
      .attr('y', y+12)
      .attr('stroke', 'rgb(92, 191, 145)')
      .style("font-size", font_size)
      .attr("text-anchor", "end")
      .text(keyInfo.name)
  } else {
    g.selectAll(`.${keyInfo.key}OnOff`)
      .transition()
      .duration(500)
      .attr("x", function(d) { return rectangleScale(d.createdAt); })
      .attr("width", (d) => { return rectangleScale.bandwidth() })
      .attr("height", function(d) { return (d[keyInfo.key] == 'ON' || d[keyInfo.key] === 1) ? 11:2; })
  }

  return y + current_height_info.onOffKeys + current_height_info.padding;
}

const drawRectangles = (data, g, x, y, modelType, keys_to_show) => {
  const coolColor = '#4183D7';
  const heatColor = '#e65722';
  const timerColor = '#989aa2';
  var keyInfo = [];
  const rwlInfo = [
    {key: 'v1p', name: 'Vent A', color: coolColor},
    {key: 'v2p', name: 'Vent B', color: coolColor},
    {key: 'v3p', name: 'Vent C', color: coolColor},
    {key: 'v4p', name: 'Vent D', color: coolColor},
    {key: 'h1', name: 'Heat 1', color: heatColor},
    {key: 'h2', name: 'Heat 2', color: heatColor},
    {key: 't1', name: 'Timer 1', color: timerColor},
    {key: 't2', name: 'Timer 2', color: timerColor},
    {key: 'hum', name: 'Humidity', color: timerColor}
  ]
  const ghkInfo = [
    {key: 'v3p', name: 'Vent C', color: coolColor},
    {key: 'v4p', name: 'Vent D', color: coolColor},
    {key: 'h1', name: 'Heat 1', color: heatColor},
    {key: 'c1', name: 'Cool 1', color: coolColor},
    {key: 'c2', name: 'Cool 2', color: coolColor},
    {key: 'h2', name: 'Heat 2', color: heatColor},
    {key: 'c3', name: 'Cool 3', color: coolColor},
    {key: 'c4', name: 'Cool 4', color: coolColor},
    {key: 't1', name: 'Timer 1', color: timerColor},
    {key: 't2', name: 'Timer 2', color: timerColor},
    {key: 'hum', name: 'Hum.', color: timerColor}
  ]

  //select the info based on the product type
  if (modelType == 'R') { // RWL
    keyInfo = rwlInfo;
  } else if (modelType == 'G') { // GHK
    keyInfo = ghkInfo;
  }

  //draw the graphs from the info array
  var firstOnOffKey = true;
  keyInfo.forEach(function(info) {
    if(keys_to_show && keys_to_show.includes(info.key)) {
      if (info.key.startsWith('v') || info.key === 'hum') {
        y = drawPercent(data, g, x, y, info);        
      } else {
        if(firstOnOffKey) {
          firstOnOffKey = false;
          y = y + (current_height_info.padding);
        }

        y = drawOnOff(data, g, x, y, info);
      }
    }
  });

  return y;
}

export const ControllerLogsChartGenerator = {
  chartData:(chartId, chart_data, chart_width, chart_height, modelType, columns, keys_to_show, platform_width) => {
    platformWidth = platform_width;
    data = chart_data;
    width = chart_width;
    tooltip_width = chart_width > 1500 ? 200 : 187;
    keys_to_show = keys_to_show;
    let onOffKeys = keys_to_show.filter((k) => !['temps', 'v1p', 'v2p', 'v3p', 'v4p', 'hum', 'dayDifNite'].includes(k))
    let percentKeys = keys_to_show.filter((k) => ['v1p', 'v2p', 'v3p', 'v4p', 'hum'].includes(k))
    current_height_info = large_screen_heights;
    has_light_logging = (keys_to_show.includes('light')) && data.find((d) => d.ll) !== null
    let data_height;
    
    if(chart_width < 500) {      
      num_ticks = 6;
      font_size = '10px';
      x_offset_headings = '-3';
      x_offset_headings_large = '-5';
      tooltip_width = 0;
      // current_height_info = small_screen_heights;      
      // console.log(`height: ${height}, chart_height: ${chart_height}`)
      current_height_info.temps = (chart_height * 0.27)
      current_height_info.onOffKeys = ((chart_height * 0.13))/onOffKeys.length
      current_height_info.percentKeys = ((chart_height * 0.18))/percentKeys.length
      current_height_info.light = keys_to_show.includes('light') ? ((chart_height * 0.1)) : 0;
      current_height_info.padding = 7;
      height = chart_height;
      data_height = current_height_info.temps + (percentKeys.length*current_height_info.percentKeys) + (current_height_info.padding * (onOffKeys.length + percentKeys.length - 2)) + (current_height_info.onOffKeys*onOffKeys.length);
      // height = height * 1.2;
      // data_height = height;
      console.log(`chart height: ${chart_height}, data height: ${data_height}, height: ${height}`)      
      margin = {top: 10, right: 5, bottom: 80, left: 10};
    } else {
        current_height_info.temps = (chart_height * 0.3)
        current_height_info.onOffKeys = ((chart_height * 0.15))/onOffKeys.length
        current_height_info.percentKeys = ((chart_height * 0.2))/percentKeys.length
        current_height_info.light = keys_to_show.includes('light') ? ((chart_height * 0.1)) : 0;
        current_height_info.padding = 10;
        height = chart_height;
        data_height = current_height_info.temps + (percentKeys.length*current_height_info.percentKeys) + (current_height_info.padding * (onOffKeys.length + percentKeys.length - 2)) + (current_height_info.onOffKeys*onOffKeys.length);
        margin.right = margin.right;
    }

    console.log(`data length: ${data && data.length}`)
    
    var keys = columns ? columns.slice(1) : [];
    var y = 10;

    selectAll("svg").remove();
    color = d3Scale.scaleOrdinal(keys,
      ["#5CBF91", "#7044ff",
        "#f04141", "#f04141",
        "#989aa2", "#989aa2",
        "#989aa2", "#989aa2",
        "#f04141", "#d7d8da",
        "#d7d8da", "#d33939"]);

    const groupKey = "label";
    const chartLabel = "Time Log"
    let min_date, max_date;
    let reset_chart = () => {}
    let width_for_data = chart_width < 500 ? width : (width - (margin.left - margin.right));
    const drawZoneChart = (chartId, data) => {      
      data = data.map((d) => {
        d.createdAt = momentTz.utc(d.createdAt, 'MM/DD/YYYY HH:mm').toDate()
        return d;
      })

      let {yScale, yLinear: yLinearInit, lightY, heightAfterTemp} = initAxes(data, keys, modelType, keys_to_show);
      if(keys_to_show.includes('light') && lightY.range()[1] - lightY.range()[0] < 10) { height = height - 80; }
      // console.log(`height: ${height}, off/on keys: ${onOffKeys.length} temps? ${keys_to_show.includes('temps')}, water? ${keys_to_show.includes('water')}, light? ${keys_to_show.includes('light')}`)
      yScaleGlobal = yScale;
      yLinear = yLinearInit;
      lightYScale = lightY;

      var zoom = d3.zoom()
      .scaleExtent([1, Infinity])
      .translateExtent([[margin.left, margin.top], [width, height]])
      .extent([[margin.left, margin.top], [width, height]])
      .on("zoom", zoomed);
  
      function brushed(event, d) {
        if (event && event === "zoom") return; // ignore brush-by-zoom
        d3.select("#reset-zoom").style("display", "flex");
        let selection = event.selection
        if(selection) {
          let inverted = [zoomScale.invert(selection[0]), zoomScale.invert(selection[1])]
          xScale.domain([inverted[0], inverted[1]])
          rectangleScale
            .domain(get_rectangle_domain(inverted[0], inverted[1]))
          g.call(zoom.transform, d3.zoomIdentity)
          g.select(".brush").call(brush.move, null)
        }
      }
    
      const brush = d3.brushX(zoomScale)
                      .extent([[0, 0], [width_for_data, 100]])
                      .on('end', brushed);

      const min_and_max_by_key = getMinAndMaxForKeys(data, keys);
      min_date = d3Array.min(data, d => d.createdAt);
      max_date = d3Array.max(data, d => d.createdAt);

      rectangle_width = width/data.length;
      let get_rectangle_domain = (min, max) => {
        let domain = data
          .filter((d) => {
            let same_or_before_max = moment(d.createdAt).isSameOrBefore(max)
            let same_or_after_min = moment(d.createdAt).isSameOrAfter(min);
            return same_or_before_max && same_or_after_min;
          })
          .map((d) => d.createdAt)
          .sort((a, b) => {
            if (a && b && a < b) { return -1; } else if (a && b && a === b) { return 0; } else { return 1; }
          })

        return domain;
      }

      rectangleScale = d3Scale.scaleBand()
        .domain(get_rectangle_domain(min_date, max_date))
        .range([0, width_for_data])
        .padding(0);

      xScale = d3Scale.scaleTime()
        .domain([min_date, max_date])
        .range([0, width_for_data]);
      zoomScale = xScale.copy()
                      
      let {chart, onReset} = initZoomSubChart(chartId, brush, y, zoomScale, width_for_data);
      var {svg, g} = init(chartId, width_for_data, height);

      function zoomed(event) {
        let y = 10;
        if (event && event === "brush") return; // ignore zoom-by-brush
        if(keys_to_show.includes('dayDifNite')) drawDifDayNiteRectangles(null, g, data_height);
        try {
          draw24HourDividingLines(null, g, data_height);
        } catch(e) { console.log(e); }
        if(keys_to_show.includes('temps')) drawLines(null, g, xScale, yScaleGlobal, yLinear)

        let zoom_y = keys_to_show.includes('temps') ? heightAfterTemp : margin.top;
        zoom_y = has_light_logging ? drawLightLines(null, g, zoomScale, lightYScale, lightYScale.range()[0], width_for_data) : zoom_y;
        zoom_y = drawRectangles(null, g, xScale, zoom_y, modelType, keys_to_show) + 10;
        drawTimeAxes(g, xScale, zoom_y, true);
      }

      //only draw temperature graph for RWL or
      if(keys_to_show.includes('temps')) drawTemperatureAxes(g, xScale, yScale, yLinear, chartLabel);
      //ghk or rwl
      if(keys_to_show.includes('dayDifNite')) drawDifDayNiteRectangles(data, g, data_height);
      try {
        draw24HourDividingLines(data, g, data_height);
      } catch(e) { console.log(e); }
      if(keys_to_show.includes('temps')) drawLines(data, g, xScale, yScale, yLinear);
      y = keys_to_show.includes('temps') ? heightAfterTemp : margin.top;
      y = has_light_logging ? drawLightLines(data, g, xScale, lightYScale, lightYScale.range()[0], width_for_data) : y;
      y = drawRectangles(data, g, xScale, y, modelType, keys_to_show) + 10;
      drawTimeAxes(g, xScale, y);
      y += 60; //add space for time axis

      if((modelType === 'R' || modelType === 'G') && platform_width > 700) {
        prepareTooltip(g, data [0]);
      }

      reset_chart = function() {
        xScale
          .domain([min_date, max_date])
          .range([0, width_for_data])
        // zoomScale
        //   .domain([min_date, max_date])
        //   .range([0, width_for_data])
        let rectangle_domain = get_rectangle_domain(min_date, max_date);
        rectangleScale
          .domain(rectangle_domain)
          .range([0, width_for_data]);
        g.call(zoom.transform, d3.zoomIdentity)
        onReset(zoom.transform, d3.zoomIdentity)
        d3.select("#reset-zoom").style("display", "none");
      }
      g.on("dblclick", reset_chart);
      d3.select("#reset-zoom").on("mouseleave", reset_chart )
    }

    drawZoneChart(chartId, data);

    return {
      draw: () => {
        drawZoneChart(chartId, data);
      },
      reset: reset_chart,
      height: y
    }
  }
}
