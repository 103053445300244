import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ErrorProvider } from 'src/app/services/error.service';
import { environment } from '../../environments/environment';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class SubscriptionService {
  private baseUrl = environment.apiUrl;
  private tiered_pricing = true;

  constructor (
    private http: HttpClient,
    public platform: Platform,
    private users: UserService,
    private errorService: ErrorProvider
  ) {}

  public getClientToken(): Promise<any> {
    var tokenUrl = this.baseUrl + "/api/payment/token";
    return new Promise((resolve, reject) => {
      this.http.get(tokenUrl)
          .toPromise()
          .then(response => {
            resolve(response)
          })
          .catch(error => { console.log(error); });
    })
  }

  public createSubscription(subscriptionInformation): Promise<any> {
    var subscriptionCreateUrl = this.baseUrl + "/api/payment/receive";
    var subscription = {
      type: subscriptionInformation.type,
      name: subscriptionInformation.name,
      payment_nonce: subscriptionInformation.payment_nonce,
      numberOfDevices: subscriptionInformation.numberOfDevices,
      numberOfBillingCycles: subscriptionInformation.numberOfBillingCycles,
      tiers: this.tiered_pricing,
      addOnId: subscriptionInformation.addOnId
    };

    if(this.tiered_pricing) {
      delete subscription.addOnId
    }

    return new Promise((resolve, reject) => {
      this.http.post(subscriptionCreateUrl, subscription).toPromise()
        .then(response => {
          resolve(response)
        }).catch(error => {
          console.log(error);
          reject(error.error)
        });
    })
  }

  public editSubscription(subscriptionInfo, comment?): Promise<any> {
    var subscriptionEditUrl = `${this.baseUrl}/api/subscriptions`;
    var subscription = {
      planId: subscriptionInfo.planId,
      planName: subscriptionInfo.planName,
      numberOfDevices: subscriptionInfo.numberOfDevices,
      addOnId: subscriptionInfo.addOnId,
      tiers: this.tiered_pricing,
      numberOfBillingCycles: subscriptionInfo.numberOfBillingCycles,
      neverExpires: false,
      addOns: subscriptionInfo.addOns
    };

    return new Promise((resolve, reject) => {
      this.http.put(subscriptionEditUrl, {subscription: subscription, comment: comment}).toPromise()
        .then(response => {
          resolve(response)
        }).catch(error => {
          reject(error.error)
        });
    })
  }

  public controllerAddRemove(subscriptionId, controllerId, url, event): Promise<any> {
    var subscription = {
      controller_id: controllerId,
      subscription_id: subscriptionId
    };

    return new Promise((resolve, reject) => {
      this.http.put(url, subscription).toPromise()
        .then(response => {
          resolve(response)
        }).catch(error => {
          console.log(error.error);
          reject(error)
        });
    })
  }

  public addControllerToSubscription(subscriptionId, controllerId) {
    var subscriptionEditUrl = `${this.baseUrl}/api/subscriptions/add`;
    return this.controllerAddRemove(subscriptionId, controllerId, subscriptionEditUrl, 'addControllerToSubscription')
  }

  public removeControllerFromSubscription(subscriptionId, controllerId) {
    var subscriptionEditUrl = `${this.baseUrl}/api/subscriptions/remove`;
    return this.controllerAddRemove(subscriptionId, controllerId, subscriptionEditUrl, 'removeControllerFromSubscription')
  }

  public search(term): Promise<any> {
    var url = `${this.baseUrl}/api/subscriptions/search`;
    return this.http.post(url, {search: term}).toPromise();
  }

  public updateSubscription(subscriptionId, controllerIds?, additionalControllers?): Promise<any> {
    var subscription = {
      controller_ids: controllerIds,
      subscription_id: subscriptionId,
      additional_controller_number: additionalControllers
    };

    var url = `${this.baseUrl}/api/subscriptions/controllers`;
    return new Promise((resolve, reject) => {
      this.http.put(url, subscription).toPromise()
        .then(response => {
          resolve(response);
        }).catch(error => {
          reject(error.error);
        });
    });
  }

  public startTrial(): Promise<any> {
    var url = `${this.baseUrl}/api/subscriptions/trial`;
    return new Promise((resolve, reject) => {
      this.http.post(url, {}).toPromise()
        .then(response => {
          resolve(response)
        }).catch(error => {
          reject(error.message)
        });
    })
  }

  public getSubscriptionNotifications(): Promise<any> {
    var url = this.users.isLoggedInAs() ? `${this.baseUrl}/api/subscription/notifications?loginAs=${this.users.getLoggedInEmail()}` : `${this.baseUrl}/api/subscription/notifications`;
    return new Promise((resolve, reject) => {
      this.http.get(url, {}).toPromise()
        .then(response => {
          resolve(response)
        }).catch(error => {
          reject(error.message)
        });
    })
  }


  public cancelSubscription(): Promise<any> {
    var subscriptionCancelUrl = `${this.baseUrl}/api/subscriptions`;
    return new Promise((resolve, reject) => {
      this.http.delete(subscriptionCancelUrl).toPromise()
        .then(response => {
          resolve(response)
        }).catch(error => {
          reject(error.error)
        });
    })
  }

  public getSubscriptionOptions(): Promise<any> {
    var subscriptionsUrl = this.baseUrl + "/api/subscriptions/plans";
    return new Promise((resolve, reject) => {
      this.http.get(subscriptionsUrl).toPromise()
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          });
    })
  }

  public getSubscriptionsForUsers(): Promise<any> {
    var subscriptionsUrl = this.baseUrl + "/api/subscriptions";
    return new Promise((resolve, reject) => {
      this.http.get(subscriptionsUrl).toPromise()
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error.error && error.error.message ? error.error.message : error.error)
          });
    })
  }

  public getControllerNames(): Promise<any> {
    var controllerNamesUrl = this.users.isLoggedInAs() ? `${this.baseUrl}/api/controllers/names?loginAs=${this.users.getLoggedInEmail()}` : `${this.baseUrl}/api/controllers/names`;
    return new Promise((resolve, reject) => {
      this.http.get(controllerNamesUrl).toPromise()
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        });
    })
  }
}
