import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import moment from 'moment';
import { SitesService } from 'src/app/services/sites.service';

@Component({
  selector: 'app-offline-controllers',
  templateUrl: './offline-controllers.component.html',
  styleUrls: ['./offline-controllers.component.scss'],
})
export class OfflineControllersComponent implements OnInit {
  @Input() showSection;
  public controllers = [];
  public filteredControllers = [];
  public searchForm: UntypedFormGroup;
  public is_ascending;
  public sort_column;
  public owners_site = {};
  public offline_owners = {};
  public filteredSite;
  public filteredOwner;
  public stats = {
    total: 0,
    registered: 0
  }
  public show = {
    in_use: true,
    online: false,
    registeredDate: true,
    updatedAt: true,
    model: false,
    fw: false
  }
  public required_fields = ['in_use', 'registeredDate', 'updatedAt', 'fw'];
  @ViewChild('searchInputId') searchInputId: ElementRef;

  constructor(
    public navCtrl: NavController,
    public alertCtrl: AlertController,
    public controllersService: SitesService,
    private ref: ChangeDetectorRef,
    private toastCtrl: ToastController
  ) {
    this.sort_column = 'updatedAt'
  }

  ngOnChanges() {
    if(this.showSection) {
      this.controllersService.getOfflineNodes()
          .then((data) => { this.offlineControllerSuccess(data) })
          .catch((error) => {
            this.alert("There was an error getting the offline nodes.")
          })
    }
  }

  ngOnInit() {
    this.required_fields = ['in_use', 'registeredDate', 'updatedAt', 'fw'];
    this.searchForm = new UntypedFormGroup({
      searchTerm: new UntypedFormControl('')
    });
    this.ref.detectChanges();
    if(this.searchInputId) { this.searchInputId.nativeElement.focus(); }
  }

  async alert(msg) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 4000,
      position: 'top'
    });

    toast.present();
  }

  public setSelectedSite(event) {
    this.filteredSite = event.target.value;
    this.filteredOwner = undefined;
    if(this.filteredSite === 'reset') {
      this.filteredSite = null;
      this.filteredControllers = this.controllers;
    } else {
      this.filter({searchTerm: this.filteredSite});
    }
  }

  public setOwner(event) {
    this.filteredOwner = event.target.value
    this.filteredSite = undefined;
    if(this.filteredOwner === 'reset') {
      this.filteredOwner = null;
      this.filteredControllers = this.controllers;
    } else {
      this.filter({searchTerm: this.filteredOwner});
    }
  }

  isRequired(field) {
    return this.required_fields.findIndex((f) => f === field) !== -1
  }

  public filter(values) {
    let filterRegex = new RegExp(values.searchTerm && values.searchTerm.toLowerCase());
    this.filteredControllers = this.controllers.filter((u) => {
      return u &&
        (filterRegex.test((u.node_id && typeof u.node_id === 'string') ? u.node_id.toLowerCase() : (u.node_id ? u.node_id.toString().toLowerCase() : null))
        || filterRegex.test(u.model ? u.model.toLowerCase() : null)
        || (values.searchTerm === 'online' && u.online))
        || (u.site && u.site[0] && u.site[0].users.find((u) => u.email.includes(values.searchTerm))) || (u.site && u.site[0] && u.site[0].name.includes(values.searchTerm));
    })
  }

  public getControllers() {
    return this.filteredControllers && this.filteredControllers.length > 0 ? this.filteredControllers : this.controllers;
  }

  public cancelSearch() {
    this.searchForm = new UntypedFormGroup({
      searchTerm: new UntypedFormControl('')
    });
    this.filteredControllers = [];
  }

  public sortBy(sort_column) {
    if(!this.sort_column || this.sort_column !== sort_column) {
      this.sort_column = sort_column;
      this.is_ascending = true;
    } else {
      this.is_ascending = !this.is_ascending;
    }

    let sort_function = (a, b) => {
      let a_greater = a[sort_column] > b[sort_column];
      let b_greater = b[sort_column] > a[sort_column];

      if(sort_column === 'subscription_end_date') {
        a_greater = moment(a[sort_column]).isAfter(moment(b[sort_column]));
        b_greater = moment(b[sort_column]).isAfter(moment(a[sort_column]));
      }

      if(a_greater) { return this.is_ascending ? 1 : -1; }
      if(b_greater) { return this.is_ascending ? -1 : 1; }
    }

    this.controllers = this.controllers.sort(sort_function);
    this.filteredControllers = this.filteredControllers.sort(sort_function);
  }

  getOwner(controller) {
    let site = this.getSite(controller)
    let owner = {email: 'N/A'}
    if(site) {
      let owner_object = this.getSiteOwner(site)
      if(owner_object) owner = owner_object;
    }

    return owner.email;
  }

  getSiteOwner(site) {
    return site.users ? site.users.find((u) => u.role === 4): 'N/A';
  }

  hasSite(controller) {
    return this.getSite(controller) && this.getSite(controller).name
  }

  getSite(controller) {
    return controller.site && controller.site[0]
  }

  getOfflineSites() {
    return Object.keys(this.owners_site);
  }

  getOfflineSiteOwners() {
    return Object.keys(this.offline_owners);
  }

  getOfflineDuration(controller) {
    return moment.duration(moment().diff(moment(controller.updatedAt))).humanize()
  }

  flipDirection() {
    this.sortBy(this.sort_column)
  }

  private offlineControllerSuccess(response) {
    let sortByRegisteredDate = (a, b) => {
      let a_has_site = (a.site && a.site[0] && a.site[0].name) || !a.site;
      let b_has_site = (b.site && b.site[0] && b.site[0].name) || !b.site;
      let a_greater = a_has_site && !b_has_site;
      let b_greater = b_has_site && !a_has_site;

      if(a_has_site && b_has_site) {
        a_greater = (a.updatedAt && b.updatedAt) ? moment(a.updatedAt).isAfter(moment(b.updatedAt)) : (a.updatedAt ? true : false);
        b_greater = (a.updatedAt && b.updatedAt) ? moment(b.updatedAt).isAfter(moment(a.updatedAt)) : (b.updatedAt ? true : false);
      }

      if(a_greater) { return this.is_ascending ? 1 : -1; }
      if(b_greater) { return this.is_ascending ? -1 : 1; }
    }
    this.controllers = response.nodes.sort(sortByRegisteredDate)

    this.stats = {
      total: this.controllers.length,
      registered: this.controllers.filter((c) => c.registeredDate).length
    };

    let addOwnerCount = (site) => {
      let offline_owner = this.getSiteOwner(site) ? this.getSiteOwner(site).email : 'N/A';
      if(!this.offline_owners[offline_owner]) {
        this.offline_owners[offline_owner] = 1;
      } else {
        this.offline_owners[offline_owner] = this.offline_owners[offline_owner] + 1;
      }
    }

    let offline_accumulation = this.controllers.reduce((accumulator, controller) => {
      let site = this.getSite(controller);
      if(site) {
        if(accumulator.sites[site.name]) { //has site name already
          accumulator.sites[site.name] = accumulator.sites[site.name] + 1
        } else {
          accumulator.sites[site.name] = 1
        }

        let offline_owner = this.getSiteOwner(site) ? this.getSiteOwner(site).email : 'N/A';
        if(offline_owner && accumulator.owners[offline_owner]) { //has owner already
          accumulator.owners[offline_owner] = accumulator.owners[offline_owner] + 1
        } else if(offline_owner) {
          accumulator.owners[offline_owner] = 1
        }
      }

      return accumulator;
    }, {owners: {}, sites: {}});

    this.offline_owners = offline_accumulation.owners;
    this.owners_site = offline_accumulation.sites;
  }
}
