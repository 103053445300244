import * as d3 from 'd3-selection';
import * as d3Scale from 'd3-scale';
import * as d3Array from 'd3-array';
import * as d3Axis from 'd3-axis';
import * as d3Shape from 'd3-shape';

let svg, g;
let height = 150
let width = 150;
let x, x1, y;
let margin = { top: 25, right: 50, bottom: 30, left: 40 };

let chartLabel;
let groupKey = "day_label";
let groupBy = 'days';
let svg_width = '100%';
let svg_height = '100%';

let init = (chartId, adjusted_svg_width = '100%', adjusted_svg_height = '100%', chartLabelInput) => {
  chartLabel = chartLabelInput;
  if(d3.select(chartId).size() == 0) console.log(`no element found for id ${chartId}`)
  if(svg_height) svg_height = adjusted_svg_height;
  if(svg_width) svg_width = adjusted_svg_width;

  if(d3.select(chartId).select('svg').size() != 0) {
    d3.select(chartId).select('svg').remove();
  }
  
  svg = d3.select(chartId)
    .append('svg')
    .attr('width', svg_width)
    .attr('height', svg_height)
    .attr('viewBox', '0 0 200 225');
  g = svg.append('g')
    .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

  console.log(`init ${svg}, ${g}`)
  return {svg: svg, g: g}
}

let initAxes = (chartData, svg, g) => {
  const min_max = {
    min: (d3Array.min(chartData, d => d.count) - 5),
    max: (d3Array.max(chartData, d => d.count) + 5)
  }

  const x = d3Scale.scaleBand()
    .domain(chartData.map(d => d.date))
    .rangeRound([0, width - margin.right])
    .paddingInner(0.1);
  const y = d3Scale.scaleLinear()
    .domain([min_max.min, min_max.max]).nice()
    .rangeRound([height, margin.top])

  return {x: x, y: y};
}

let drawAxes = (g, x, y) => {
  let tickValues = x.domain().length > 5 ? x.domain().filter(function(d,i) { return !(i%2)}) : x.domain();
  g.append('g')
    .attr('class', 'axis axis--x')
    .attr('transform', 'translate(0,' + height + ')')
    .call(
      d3Axis.axisBottom(x)
        .tickValues(tickValues))
    .attr('font-size', '10')
    .selectAll("text")
      .attr("transform", "rotate(60)")
      .attr("dx", "1em")
      .attr("dy", "-.5em")
      .style("text-anchor", "start");

  g.append('g')
    .attr('class', 'axis axis--y')
    .attr('font-size', '14')
    .call(
      d3Axis.axisLeft(y)
        .ticks(10)
        .tickSize(-(width - margin.right))
    )
    .append('text')
      .attr('class', 'axis-title')
      .attr('x', margin.left)
      .attr('dx', '5em')
      .attr('fill', 'rgb(0, 0, 0)')
      .text(`${chartLabel}`);
}

let createD3Line = (x, yLinear) => {
  let groupByKey = groupBy;
  return d3Shape.line()
      .x(function(d, i) { return x(d.date); })
      .y(function(d) { return yLinear(d.count); });
}

let drawLineChart = (data, g, x, y) => {
  let line = createD3Line(x, y);
  if(data) {
    g.append("svg:path")
      .datum(data)
      .attr("stroke", "#Dd6f6f")
      .attr("stroke-width", 3)
      .attr("fill", "none")
      .attr('class', `line`)
      .attr("pointer-events","all")
      .attr("d", line)
  } else {
    g.select('.line')
       .transition()
       .duration(1000)
       .attr("d", line)
  }
}

let drawChart = (chartData, g, x, y) => {
  drawLineChart(chartData, g, x, y)
}

export const LineChartGenerator = {
  drawChart,
  drawLineChart,
  createD3Line,
  drawAxes,
  initAxes,
  init
}
