import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { HTTP } from '@ionic-native/http/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Storage } from '@ionic/storage';
import { environment } from '../environments/environment';
import { UserService } from 'src/app/services/user.service';

type HttpMethod = 'get' | 'post' | 'put' | 'patch' | 'head' | 'delete' | 'upload' | 'download';

@Injectable()
export class NativeHttpInterceptor implements HttpInterceptor {
  constructor(
    private nativeHttp: HTTP,
    private platform: Platform,
    private appVersion: AppVersion,
    private storage: Storage,
    private userService: UserService
  ) { }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.platform.is('cordova')) {
      let token = this.userService.getAuthToken();
      let headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }

      if(!request.url.includes('login')) {
        headers["x-app-name"] = `${environment.package_name},${environment.clientId}`;
      }
      if(token) {
        headers["x-access-token"] = token;
      }
      let authenticatedRequest = request.clone({setHeaders: headers})
      return next.handle(authenticatedRequest);
    } else {
      return from(this.handleNativeRequest(request));
    }
  }

  private async appToken() {
    let name = await this.appVersion.getPackageName();
    return `${name},${environment.clientId}`;
  }

  private async handleNativeRequest(request: HttpRequest<any>): Promise<HttpResponse<any>> {
    let name = await this.appVersion.getPackageName();
    let token = this.userService.getAuthToken();
    let app_token = await this.appToken();
    const headerKeys = request.headers.keys();
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      "x-app-name": app_token
    };

    if(token) {
      headers["x-access-token"] = token;
    }

    headerKeys.forEach((key) => {
      headers[key] = request.headers.get(key);
    });

    try {
      await this.platform.ready();

      const method = <HttpMethod> request.method.toLowerCase();
      const nativeHttpResponse = await this.nativeHttp.sendRequest(request.url, {
        method: method,
        data: request.body,
        headers: headers,
        serializer: 'json',
      });

      let body;
      try {
        body = JSON.parse(nativeHttpResponse.data);
      } catch (error) {
        body = { response: nativeHttpResponse.data };
      }

      const response = new HttpResponse({
        body: body,
        status: nativeHttpResponse.status,
        headers: new HttpHeaders(nativeHttpResponse.headers),
        url: nativeHttpResponse.url,
      });

      // console.log(`returning from http request, body: ${JSON.stringify(body)}`)
      return Promise.resolve(response);
    } catch (error) {
      if (!error.status) { return Promise.reject(error); }

      const response = new HttpResponse({
        body: JSON.parse(error.error),
        status: error.status,
        headers: error.headers,
        url: error.url,
      });

      return Promise.reject(response);
    }
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
