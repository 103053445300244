import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-water-sensor',
  templateUrl: './water-sensor.component.html',
  styleUrls: ['./water-sensor.component.scss'],
})
export class WaterSensorComponent implements OnInit {
  public index;
  public volume;
  public sp;
  @Input() info;

  constructor() { }

  ngOnInit() {
    if(this.info) {
      this.index = this.info.index;
      this.volume = this.info.volume;
      this.sp = this.info.sp;
    }
  }

}
