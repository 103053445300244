import { Component, OnInit, Input, ViewChild, ElementRef, ViewEncapsulation, Output, EventEmitter, OnChanges, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-run-times',
  templateUrl: './run-times.component.html',
  styleUrls: ['./run-times.component.scss'],
})
export class RunTimesComponent implements OnInit {
  @Input() runTimes;

  constructor() { }

  ngOnInit() {}

}
