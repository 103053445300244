<ion-header>
  <ion-toolbar class="settings-header">
    <ion-buttons slot="start">
      <ion-back-button class="icon back"></ion-back-button>
    </ion-buttons>
    <div>
      <span class="settings-name">{{settingInfo && settingInfo.desc}}</span>
      <span class="settings-site-house">{{siteName}} - {{houseName}}</span>
    </div>
  </ion-toolbar>
</ion-header>

<div class="content" #windRainEdit>
  <div *ngIf="error" class="error">{{error}}</div>
  <div class="pending" *ngIf="isPending">
    Currently syncing with the controller
  </div>

  <div class="setting-item" *ngIf="settingInfo">
    <ion-item lines="none" color="white">
      <ion-label class="setting-header">Setting <span *ngIf="settingInfo.units">({{settingInfo.units}})</span></ion-label>
      <ion-label class="ion-float-right setting-value">
        {{getCurrentValue()}}
      </ion-label>
    </ion-item>

    <ion-item lines="none" color="white" class="editable-input">
      <ion-label class="setting-header" *ngIf="settingInfo.type === 'select'">&nbsp;</ion-label>
      <ion-input
        type="{{settingInfo.type}}"
        [attr.inputmode]="settingInfo.inputmode ? settingInfo.inputmode : 'text'"
        pattern="{{settingInfo.pattern}}"
        [(ngModel)]="value"
        (change)="reformatAndValidate()"
        select-all-ion-input
        *ngIf="settingInfo.type !== 'checkbox' && settingInfo.type !== 'select'"
        #inputBox
      >
      </ion-input>
      <ion-checkbox color="secondary-blue"
        *ngIf="settingInfo.type === 'checkbox'"
        item-right
        [checked]="value"
        (ngChange)="value = !value">
      </ion-checkbox>
      <ion-select
        *ngIf="settingInfo.type === 'select'"
        [(ngModel)]="value"
      >
        <ion-select-option
          *ngFor="let option of settingInfo.options"
          [value]="option.value">{{option.text}}
        </ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item *ngIf="settingInfo.additionalOption" color="white">
      <ion-label class="setting-header">{{settingInfo.additionalOption.label}}</ion-label>
      <input type="checkbox" (ngChange)="toggleAdditionalOption($event)"
         checked="additional_option_check">
    </ion-item>
  </div>

  <ion-item class="setting-ion-item comment-box" *ngIf="canEdit" color="white">
      <ion-label>Comment<sup>*</sup></ion-label>
      <ion-input [(ngModel)]="comment" placeholder="Please add a comment"></ion-input>
  </ion-item>
  <p *ngIf="canEdit"><sup>*</sup>Add a message about this change</p>

  <div class="saveOrCancel">
    <ion-button color="secondary" class="settings-buttons" (click)="cancel()">
      Cancel
    </ion-button>
    <ion-button color="primary" [disabled]="!canEdit || error" class="settings-buttons" (click)="save()">
      Save
    </ion-button>
  </div>
  <div *ngIf="!canEdit"><i>Save disabled; no edit permissions</i></div>
</div>
