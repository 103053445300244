<div class="admin-form view-subscriptions" *ngIf="showSection"
  [class.active]="showSection">
  <div class="refine">
    <div id="subscription-filter">
      <label>Filter</label>
      <select [(ngModel)]='filterValue' (change)="setFilteredSubscriptionValue($event)">
        <option value="reset">See All</option>
        <option
          *ngFor="let value of getFilterValues()"
          [value]="value.value"
          selected="value.value">{{value.show}}
        </option>
      </select>
    </div>
    <form *ngIf="showSection && subscriptions" [formGroup]="searchForm" (ngSubmit)="filter(searchForm.value)">
      <label>Search Subscriptions <input type='search' value="User" formControlName="searchTerm" autofocus="true" #searchInputId/></label>
      <div>
        <ion-button type="button" (click)="cancelSearch()"><ion-icon name="close-outline"></ion-icon></ion-button>
        <ion-button type="submit" (click)="filter(searchForm.value)"><ion-icon name="search-outline"></ion-icon></ion-button>
      </div>
    </form>
  </div>
  <!-- <div class="summary" *ngIf="subscriptions">
    <div><label>Total:</label> {{subscription_summary.total}}</div>
    <div><label>Shown:</label> {{filteredSubscriptions.length}}</div>
    <div><label>Active:</label> {{subscription_summary.active}}</div>
    <div><label>Past Due or Expired:</label> {{subscription_summary.past_due_or_expired}}</div>
    <div><label>Never Expires:</label> {{subscription_summary.never_expires}}</div>
    <div><label>Upcoming Expiry:</label> {{subscription_summary.upcoming_renewal.length}}</div>
  </div> -->
  <ion-grid class="subscriptions-list" *ngIf="subscriptions">
    <ion-row>      
      <ion-col>Owner</ion-col>            
      <ion-col>Status</ion-col>            
      <ion-col>Controller Limit</ion-col>
      <ion-col>&nbsp;</ion-col>
    </ion-row>
    <ion-row *ngFor="let subscription of getSubscriptions()">
      <!-- <p *ngIf="subscription.site_id && subscription.site_name"><label>Site Name and ID: </label>{{subscription.site_name}}, {{subscription.site_id}}</p> -->
      <ion-col>{{subscription.owner}}</ion-col>      
      <ion-col [class.past_due_or_expired]="subscription.status === 'Past Due' || subscription.status === 'Expired'">{{subscription.status}}</ion-col>      
      <ion-col>{{subscription.controllerLimit}}</ion-col>
      <ion-col (click)="selectSubscription(subscription)">
        <ion-icon color="medium" *ngIf="selected === subscription" name="arrow-up-outline"></ion-icon>
        <ion-icon color="medium" *ngIf="selected !== subscription" name="arrow-down-outline"></ion-icon>
      </ion-col>
      <!-- <p *ngIf="filterValue === 'expiration_upcoming_renewal'"><label>Will Expire In: </label>{{getHumanFriendlyExpirationString(subscription)}}</p>            
      <p><label>Controllers Set Up: </label>{{subscription.controllers && subscription.controllers.length}}</p>
      <div *ngIf="subscription.history && subscription.history.length > 1"><label>History: </label> {{subscription.history.length}} previous subscriptions</div> -->
    </ion-row>
  </ion-grid>
  <ion-grid *ngIf="selected">
    <ion-row>
      <ion-col>Plan</ion-col>
      <ion-col>Next Billing Date</ion-col>
      <ion-col>Expiration Date</ion-col>
      <ion-col>Controllers Added</ion-col>
      <ion-col>Controllers Not Added to Subscription</ion-col>
    </ion-row>
    <ion-row>
      <ion-col>{{selected.planName}}</ion-col>
      <ion-col>{{selected.nextBillingDate}}</ion-col>
      <ion-col *ngIf="!selected.neverExpires">{{selected.expirationDate}} <span *ngIf="filterValue === 'expiration_past_due_or_expired'">({{getHumanFriendlyExpirationString(selected)}})</span></ion-col>
      <ion-col *ngIf="selected.neverExpires">Never Expires</ion-col>
      <ion-col>{{selected.included && selected.included.length}}</ion-col>
      <ion-col *ngIf="selected.excluded && selected.excluded.length > 0">({{selected.excluded && selected.excluded.length}})&nbsp;{{selected.excluded && selected.excluded.join(', ')}}</ion-col>
      <ion-col *ngIf="selected.excluded && selected.excluded.length === 0">0</ion-col>
    </ion-row>
  </ion-grid>
  <p *ngIf="!subscriptions || subscriptions.length == 0 && !filteredSubscriptions || filteredSubscriptions.length == 0">No subscriptions retrieved</p>
</div>
