<div class="section" *ngIf="showSection" [class.active]="showSection">
  <div>
    <div class='subdiv'>
      <label>Choose Date: <input [(ngModel)]='selectedDate' type='date'/></label>
      <input type="button" (click)="updateChanges()" value="Update"/>
    </div>
    <div class='subdiv'>
      <label>Filter By:
        <select [(ngModel)]='selectedType' (change)="filter()">
          <option value="NONE">Clear</option>
          <option value="request-log">Change Request</option>
          <option value="site-change">Site Change</option>
          <option value="push-notification">Push Notification</option>
          <option value="created-user">User Created</option>
        </select>
      </label>
    </div>
  </div>
  <div class="summary">
    <p>Change Requests: {{summaries['request-log']}}</p>
    <p>Site Changes: {{summaries['site-change']}}</p>
    <p>Push Notifications: {{summaries['push-notification']}}</p>
    <p>User Creations: {{summaries['created-user']}}</p>
  </div>
  <div id="snapshotCharts">
    <div id="snapshot-users"></div>
    <div id="snapshot-userChanges"></div>
    <div id="snapshot-pushNotifications"></div>
    <div id="snapshot-siteChanges"></div>
  </div>
  <div *ngIf="getChanges().length === 0 && selectedType">
    There is nothing in the list for that day/filter combination.
  </div>
  <div>
    <ul *ngIf="selectedType === 'request-log'" class="users-submenu">
      <li *ngFor="let key of getChangeRequestsGrouped()"
        (click)="selectUser(key)" [class.selected]="selectedUser === key">
        {{key}}
      </li>
    </ul>
    <table *ngIf="getChanges().length > 0" class="all-changes">
      <h5>{{selectedType}}</h5>
      <tr class="change-header change">
        <td>Datetime</td>
        <td>Email</td>
        <td>Change</td>
      </tr>
      <tr *ngFor='let change of getChanges()' class='change'>
        <td>{{change.date}}</td>
        <td>{{change.email}}</td>
        <td>{{change.change}}</td>
      </tr>
    </table>
  </div>
</div>
