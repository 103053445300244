<div class="node-name"
  [class.warning-on]='true'
  [class.dashboard]="asDashboard"
  *ngIf="node && !node.status && !asDashboard"
  (click)="selectNodeNoStatus(node.node_id)"
>
  <div [class.warning-on]='true'>
    <div>
      <h1>{{node.api_name || node.node_name || 'N/A'}}</h1>
    </div>
    <div class="model">
      <div class="controller_name">{{node.model || 'N/A'}}
        <span class='offline' *ngIf="!node.online && node.in_use">&nbsp;|&nbsp;OFFLINE</span>
        <span class='offline' *ngIf="!node.in_use">&nbsp;|&nbsp;NOT IN USE</span>
      </div>
    </div>
  </div>
  <div class="icons">
    <ion-icon name='alert-circle-outline' class="note-alert warning">
    </ion-icon>
  </div>
</div>
<div class="node-name"
  [class.warning-on]='node.status.alarm && node.status.alarm!="NONE"'
  *ngIf="node && node.status && !asDashboard"
  (click)="selectNode(node.node_id)"
>
  <div [class.warning-on]='node.status.alarm && node.status.alarm!="NONE"'>
    <div>
      <h1 (click)="selectNode(node.node_id)">{{node.api_name || node.node_name}}
        <ion-spinner *ngIf="node.reqs && node.reqs.length > 0" color="secondary" name="bubbles"></ion-spinner>
      </h1>
    </div>
    <div class="model" (click)="selectNode(node.node_id)">
      <div class="controller_name">{{node.model}}
        <span class='offline' *ngIf="!node.online">&nbsp;|&nbsp;OFFLINE</span>
        <span *ngIf="!node.in_use">&nbsp;|&nbsp;NOT IN USE</span>
      </div>
    </div>
    <div *ngIf="isOwner && node.users && node.users.length <= 1" class='add-users'
      (click)='openUsers()'>
       Only you can see this house. <span class="link">Add other users.</span>
     </div>
  </div>
  <div class="icons" (click)="selectNode(node.node_id)">
    <ion-icon
      *ngIf='node.status.alarm && node.status.alarm!=="NONE" && !(node.status.alarm.endsWith("1") || node.status.alarm.endsWith("2"))'
      name='alert-circle-outline'
      class="note-alert warning">
    </ion-icon>
    <ion-icon class="go-to-settings" name='arrow-forward-outline'></ion-icon>
  </div>
</div>

<div class="status" *ngIf="node && node.status && !asDashboard" (click)="selectNode(node.node_id)">
    <h3>Zone 1</h3>
    <span
      [class.temp]='node.online && node.status.heatCool || (node.status.heatCool && !node.status.heatCool.zone1.on &&
        !(zone1HasAlarm))'
      [class.warning]='zone1HasAlarm'
      [class.offline]='!node.online'
      [class.changing]="node.online &&node.status.heatCool && node.status.heatCool.zone1.on"
    >
      <ion-icon
        *ngIf='node.online && zone1HasAlarm'
        name='alert-circle-outline'
        class="note-alert warning">
      </ion-icon>
      <ion-icon
        class="changing"
        color="secondary"
        *ngIf='node.online && (node.status.heatCool && node.status.heatCool.zone1.on &&
          !(zone1HasAlarm))'
          name="{{ (node.status.heatCool.zone1.heating === true) ? 'arrow-up-outline' : 'arrow-down-outline'}}">
      </ion-icon>
      <ion-icon
        *ngIf='node.online && (node.status.heatCool && !node.status.heatCool.zone1.on &&
          !(zone1HasAlarm))'
        color="{{!node.online ? 'offline' : 'secondary-blue'}}"
        class="temp"
        src="/assets/custom/icon_star.svg">
      </ion-icon>
      <ion-icon
        *ngIf='!node.online'
        class="warning"
        color="danger"
        name="eye-off-outline">
      </ion-icon>
      <p *ngIf="!node.online">OFF</p>

      <p *ngIf="node.online">{{node.status.ct1}}°</p>
    </span>
</div>
<div *ngIf='node && node.status && node.status.zones==2 && !asDashboard' class="status" (click)="selectNode(node.node_id)">
    <h3>Zone 2</h3>
    <span
      [class.temp]='!node.status.heatCool.zone2.on && !(zone2HasAlarm)'
      [class.warning]='zone2HasAlarm'
      [class.offline]='!node.online'
      [class.changing]="node.status.heatCool.zone2.on"
    >
      <ion-icon
        *ngIf='node.online && zone2HasAlarm'
        name='alert-circle-outline'
        color="danger"
        class="note-alert warning">
      </ion-icon>
      <ion-icon
        class="changing"
        *ngIf='node.online && node.status.heatCool.zone2.on && !(zone2HasAlarm)'
        color="secondary"
        name="{{ (node.status.heatCool.zone2.heating === true) ? 'arrow-up-outline' : 'arrow-down-outline'}}">
      </ion-icon>
      <ion-icon
        *ngIf='node.online && !node.status.heatCool.zone2.on && !(zone2HasAlarm)'
        color="{{!node.online ? 'offline' : 'secondary-blue'}}"
        class="temp"
        src="/assets/custom/icon_star.svg">
      </ion-icon>
      <ion-icon
        *ngIf='!node.online'
        color="danger"
        class="warning"
        name="eye-off-outline">
      </ion-icon>
      <p *ngIf="!node.online">OFF</p>
      <p *ngIf="node.online">{{node.status.ct2}}°</p>
    </span>
</div>
<div class="status" *ngIf="node && node.status && !asDashboard && node.hasH3" (click)="selectNode(node.node_id)">
    <h3>Heat 3</h3>
    <span class="temp">
      <ion-icon
        *ngIf='node.online && h3HasAlarm'
        name='warning'
        class="note-alert warning">
      </ion-icon>
      <ion-icon
        *ngIf="node.online && !h3HasAlarm"
        color="{{!node.online ? 'offline' : 'secondary-blue'}}"
        class="temp"
        src="/assets/custom/icon_star.svg">
      </ion-icon>
      <!-- <ion-icon
        *ngIf='node.status.h3==="OFF"'
        class="warning"
        color="danger"
        name="eye-off-outline">
      </ion-icon> -->
      <p *ngIf="node.online && !h3HasAlarm">{{node.status.ct3}}°</p>
      <p *ngIf="node.online && h3HasAlarm">N/A</p>
    </span>
</div>























<div class="node-name"
  [class.warning-on]='true'
  [class.dashboard]="asDashboard"
  *ngIf="node && asDashboard"
  (click)="selectNodeNoStatus(node.node_id)"
>
  <div [class.warning-on]='(node && !node.status) || !node || (node && node.status && node.status.alarm && node.status.alarm!="NONE")'>
    <div>
      <h1 [class.truncate]="node.node_name && node.node_name.length > 26">{{node.node_name || 'N/A'}}
        <ion-spinner *ngIf="node && node.reqs && node.reqs.length > 0" color="secondary" name="bubbles"></ion-spinner>
      </h1>
    </div>
    <div class="model" (click)="selectNode(node.node_id)">
      <div class="controller_name" *ngIf="!asDashboard">{{node.model || 'N/A'}}
        <span class='offline' *ngIf="!node.online">&nbsp;|&nbsp;OFFLINE</span>
      </div>
    </div>
  </div>
  <div class="icons" (click)="selectNode(node.node_id)">
    <ion-icon name='warning' class="note-alert warning" *ngIf="(!node.status) || !node"></ion-icon>
    <ion-icon
      *ngIf='node.status && node.status.alarm && node.status.alarm!=="NONE" && !(node.status.alarm.endsWith("1") || node.status.alarm.endsWith("2"))'
      name='warning'
      class="note-alert warning">
    </ion-icon>
  </div>
</div>
<div class="status" [class.dashboard]="asDashboard" *ngIf="node && asDashboard" (click)="selectNode(node.node_id)">
  <div class="table">
    <div class="thead">
      <div class="tr">
        <div class="th" *ngIf="node && node.status && (node.status.zones === 2 || node.hasH3)">Zone</div>
        <div class="th">Status</div>
        <div class="th">Temp</div>
        <div class="th">Set Pt.</div>
      </div>
    </div>
    <div class="tbody">
      <div class="tr" [class.one-zone]="node && node.status && node.status.zones==1 && !node.hasH3 && asDashboard">
        <div class="td" *ngIf='node && node.status && (node.status.zones==2 || node.hasH3) && asDashboard'>1</div>
        <div class="td" [class.one-zone-icon]="node && node.status && node.status.zones==1 && !node.hasH3 && asDashboard">
        <!-- <span
          [class.temp]='node.online && node.status.heatCool || (node.status.heatCool && !node.status.heatCool.zone1.on &&
            !(zone1HasAlarm))'
          [class.warning]='zone1HasAlarm'
          [class.offline]='!node.online'
          [class.changing]="node.online &&node.status.heatCool && node.status.heatCool.zone1.on"
        > -->
          <ion-icon
            *ngIf='node.online && zone1HasAlarm'
            name='warning'
            class="note-alert warning">
          </ion-icon>
          <ion-icon
            class="{{ (node.status.heatCool.zone1.heating === true) ? 'status-orange' : 'secondary-blue'}}"
            *ngIf='node.online && (node.status.heatCool && node.status.heatCool.zone1.on && !(zone1HasAlarm))'
            name="{{ (node.status.heatCool.zone1.heating === true) ? 'arrow-up-outline' : 'arrow-down-outline'}}">
          </ion-icon>
          <ion-icon
            *ngIf='node.online && (node.status.heatCool && !node.status.heatCool.zone1.on &&
              !(zone1HasAlarm))'
            class="temp"
            name="star">
          </ion-icon>
          <ion-icon
            *ngIf='!node.online'
            class="warning"
            color="danger"
            name="eye-off-outline">
          </ion-icon>
        </div>
        <div class="td current-temp">
          <p *ngIf="!node.online">OFF</p>
          <p *ngIf="node.online">{{node.status.ct1}}°</p>
        </div>
        <div class="td">
          {{node.setPt1}}°
        </div>
      </div>
      <div class="tr" *ngIf='node && node.status && node.status.zones==2 && asDashboard' (click)="selectNode(node.node_id)">
        <div class="td">2</div>
        <div class="td">
          <!-- <span
            [class.temp]='!node.status.heatCool.zone2.on && !(zone2HasAlarm)'
            [class.warning]='zone2HasAlarm'
            [class.offline]='!node.online'
            [class.changing]="node.status.heatCool.zone2.on"
          > -->
          <ion-icon
            *ngIf='node.online && zone2HasAlarm'
            name='warning'
            color="danger"
            class="note-alert warning">
          </ion-icon>
          <ion-icon
            *ngIf='node.online && node.status.heatCool.zone2.on && !(zone2HasAlarm)'
            class="{{ (node.status.heatCool.zone2.heating === true) ? 'status-orange' : 'secondary-blue'}}"
            name="{{ (node.status.heatCool.zone2.heating === true) ? 'arrow-up-outline' : 'arrow-down-outline'}}">
          </ion-icon>
          <ion-icon
            *ngIf='node.online && !node.status.heatCool.zone2.on && !(zone2HasAlarm)'
            class="temp"
            name="star">
          </ion-icon>
          <ion-icon
            *ngIf='!node.online'
            color="danger"
            class="warning"
            name="eye-off-outline">
          </ion-icon>
        </div>
        <div class="td current-temp">
            <p *ngIf="!node.online">OFF</p>
            <p *ngIf="node.online">{{node.status.ct2}}°</p>
        </div>
        <div class="td">{{node.setPt2}}°</div>
      </div>
      <div class="tr timer-dashboard" *ngIf='node && node.status && asDashboard' (click)="selectNode(node.node_id)">
        <div class="td timer">Timers</div>
        <div class="td timer">
          <ion-icon
            *ngIf='node.status && node.status.timer'
            name='timer'
            class="{{node.online ? (node.status.timer === 'ON' ? 'changing' : '') : ''}} timer-icon"
          >
          </ion-icon>
          <ion-icon
            *ngIf='node.status && node.status.timer2'
            name='timer'
            class="{{node.online ? (node.status.timer2 === 'ON' ? 'changing' : '') : ''}} timer-icon">
          </ion-icon>
        </div>
      </div>
      <div class="tr" *ngIf='node && node.status && node.hasH3 && asDashboard' (click)="selectNode(node.node_id)">
        <div class="td">H3</div>
        <div class="td">
          <!-- <span
            [class.temp]='!node.status.heatCool.zone2.on && !(zone2HasAlarm)'
            [class.warning]='zone2HasAlarm'
            [class.offline]='!node.online'
            [class.changing]="node.status.heatCool.zone2.on"
          > -->
          <ion-icon
            *ngIf='node.online && h3HasAlarm'
            name='warning'
            color="danger"
            class="note-alert warning">
          </ion-icon>
          <ion-icon
            *ngIf='!node.online'
            color="danger"
            class="warning"
            name="eye-off-outline">
          </ion-icon>
          <ion-icon
            *ngIf='node.online && !h3HasAlarm'
            class="temp"
            name="star">
          </ion-icon>
        </div>
        <div class="td current-temp">
            <p *ngIf="node.online">{{node.status.ct3}}°</p>
        </div>
        <div class="td">{{node.setPt3}}°</div>
      </div>
    </div>
  </div>
</div>
