import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { EditHeatCoolSettingPageRoutingModule } from './edit-heat-cool-setting-routing.module';

import { EditHeatCoolSettingPage } from './edit-heat-cool-setting.page';
import { NgxGaugeModule } from 'ngx-gauge';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    NgxGaugeModule,
    EditHeatCoolSettingPageRoutingModule
  ],
  declarations: [EditHeatCoolSettingPage],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EditHeatCoolSettingPageModule {}
