import { Component, Input, OnInit } from '@angular/core';
import { SitesService } from '../services/sites.service';
import { UserService } from '../services/user.service';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-admin-controllers',
  templateUrl: './admin-controllers.component.html',
  styleUrls: ['./admin-controllers.component.scss'],
})
export class AdminControllersComponent  implements OnInit {
  @Input() showSection = false;
  public view;

  constructor(
    private sitesApiProvider: SitesService,
    private userService: UserService,
    private navCtrl: NavController
  ) { }

  ngOnInit() {}

  public loginAsFromComponent(event) {
    this.loginAs(event.email, event.cis_id)
  }

  public loginAs(email, cis_id) {
    if(cis_id) { this.sitesApiProvider.adminNodeId = cis_id; }
    if(email) { this.userService.setLoginAs(email, cis_id); }
    this.sitesApiProvider.doUpdates = 3;
    this.sitesApiProvider.getNodeList(true, email);
    this.navCtrl.navigateForward('node-list');
  }
}
