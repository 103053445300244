import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-title-navigation',
  templateUrl: './title-navigation.component.html',
  styleUrls: ['./title-navigation.component.scss'],
})
export class TitleNavigationComponent  implements OnInit {
  @Input() finalReview; 
  @Input() startFromScratch; 
  @Input() online;
  @Input() smallScreen; 
  @Input() current_page;
  @Input() viewablePages;
  @Input() current_step;
  @Output() change = new EventEmitter();
  @Output() import = new EventEmitter();

  @ViewChild('popover') popover;

  isOpen = false;
  collapsedBreadcrumbs: HTMLIonBreadcrumbElement[] = [];

  constructor() { }

  ngOnInit() {}

  async presentPopover(e: Event) {
    this.collapsedBreadcrumbs = (e as CustomEvent).detail.collapsedBreadcrumbs;
    this.popover.event = e;
    this.isOpen = true;
  }

  setPageIn(ev) {
    this.isOpen = false;
    this.change.emit(ev.detail.value);
  }  

  importToController() {
    this.import.emit()
  }
}
