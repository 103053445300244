import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { NodeListItem } from '../model-defs/node-def'
import { SitesService } from 'src/app/services/sites.service';
import { NavController } from '@ionic/angular';
import { StatusWindRainPage } from '../status-wind-rain/status-wind-rain.page';
import { NavigationExtras } from '@angular/router';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-wind-rain-node-list',
  templateUrl: './wind-rain-node-list.component.html',
  styleUrls: ['./wind-rain-node-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WindRainNodeListComponent implements OnInit {
  @Input() public node;
  @Input() asDashboard: Boolean = false;

  @Output()
  public choose: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public sitesApiProvider: SitesService,
    public navCtrl: NavController,
    public platform: Platform
  ) {}
  ngOnInit() {}

  public alarmOn() {
    return this.node && this.node.status && (
      (this.node.status.wind && this.node.status.wind.alarm) || (this.node.status.rain && this.node.status.rain.alarm) ||
      (this.node.status.override && this.node.status.override.activation)
    )
  }

  public selectNode(id){
    this.sitesApiProvider.activeNodeId = id;

    let navigationExtras: NavigationExtras = {
      queryParams: { inNodeData: this.node }
    };

    let width = this.platform.width();
    if(width >= 820) {
      this.choose.emit({node: this.node, has_status: this.node.status});
    } else {
      this.navCtrl.navigateForward(`status-wind-rain/${this.node.node_id}`, navigationExtras);
    }
  }
}
