<div class="program-review" *ngIf="programmingForm">
  <div class="review-container" [class.reviewing]="reviewing" *ngIf="programmingForm && programmingForm.value">
    <div id="timelinechart" [class.display]="showGraph"></div>
    <div id="timelinechart2" [class.display]="showGraph"
      [class.hidden]="programmingForm && programmingForm.value && programmingForm.value.zones && programmingForm.value.zones !== 2"></div>
    <div *ngIf="!showGraph 
        && programmingForm && programmingForm.value && programmingForm.value.zones !== 2
        && (!narrow || (narrow && (narrow.includes('Day') || narrow.includes('DIF') || narrow.includes('Night') || narrow.includes('Outputs') || narrow.includes('Zone 2 Outputs'))))">
      <h6>Stage Configuration</h6>
      <ion-grid class="general" *ngIf="programmingForm && programmingForm.value">
        <ion-row>
          <ion-col>&nbsp;</ion-col>
          <ion-col class="header">Start Time</ion-col>
          <ion-col class="header">Target Temp</ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="header" class="dif">Dif &nbsp;&nbsp;&nbsp;</ion-col>
          <ion-col>{{programmingForm.value.dif_start || 'N/A'}}</ion-col>
          <ion-col>{{programmingForm.value.dif_degrees}}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="header" class="day">Day &nbsp;&nbsp;&nbsp;</ion-col>
          <ion-col>{{programmingForm.value.day_start || 'N/A'}}</ion-col>
          <ion-col>{{programmingForm.value.day_temp}}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="header" class="nite">Night &nbsp;&nbsp;&nbsp;</ion-col>
          <ion-col>{{programmingForm.value.nite_start}}</ion-col>
          <ion-col>{{programmingForm.value.nite_degrees}}</ion-col>
        </ion-row>
      </ion-grid>

      <h6 *ngIf="narrow && (narrow.includes('Outputs') || narrow.includes('Zone 2 Outputs'))">Heat/Cool Outputs</h6>
      <ion-grid class="general" *ngIf="programmingForm && programmingForm.value && 
        (narrow && (narrow.includes('Outputs') || narrow.includes('Zone 2 Outputs')))">
        <ion-row class="heat-cool-outputs">
          <ion-col class="header">Activation by Stage</ion-col>
          <ion-col class="header" *ngIf="model.includes('GHK')">Cool 1</ion-col>
          <ion-col class="header" *ngIf="model.includes('GHK')">Cool 2</ion-col>
          <ion-col class="header" *ngIf="model.includes('GHK')">Cool 3</ion-col>
          <ion-col class="header" *ngIf="model.includes('GHK')">Cool 4</ion-col>
          <ion-col class="header">Heat 1</ion-col>
          <ion-col class="header">Heat 2</ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="header" class="dif">DIF</ion-col>
          <ion-col *ngIf="model.includes('GHK')">{{programmingForm.value.dif_degrees + (programmingForm.value.day_c1 - programmingForm.value.day_temp)}}</ion-col>
          <ion-col *ngIf="model.includes('GHK')">{{programmingForm.value.dif_degrees + (programmingForm.value.day_c2 - programmingForm.value.day_temp)}}</ion-col>
          <ion-col *ngIf="model.includes('GHK')">{{programmingForm.value.dif_degrees + (programmingForm.value.day_c3 - programmingForm.value.day_temp)}}</ion-col>
          <ion-col *ngIf="model.includes('GHK')">{{programmingForm.value.dif_degrees + (programmingForm.value.day_c4 - programmingForm.value.day_temp)}}</ion-col>
          <ion-col>{{programmingForm.value.dif_degrees - (programmingForm.value.day_temp - programmingForm.value.day_h1)}}</ion-col>
          <ion-col>{{programmingForm.value.dif_degrees - (programmingForm.value.day_temp - programmingForm.value.day_h2)}}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="header" class="day">Day</ion-col>
          <ion-col *ngIf="model.includes('GHK')">{{programmingForm.value.day_c1}}</ion-col>
          <ion-col *ngIf="model.includes('GHK')">{{programmingForm.value.day_c2}}</ion-col>
          <ion-col *ngIf="model.includes('GHK')">{{programmingForm.value.day_c3}}</ion-col>
          <ion-col *ngIf="model.includes('GHK')">{{programmingForm.value.day_c4}}</ion-col>
          <ion-col>{{programmingForm.value.day_h1}}</ion-col>
          <ion-col>{{programmingForm.value.day_h2}}</ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="header" class="nite">Night</ion-col>
          <ion-col *ngIf="model.includes('GHK')">{{programmingForm.value.nite_degrees + (programmingForm.value.day_c1 - programmingForm.value.day_temp)}}</ion-col>
          <ion-col *ngIf="model.includes('GHK')">{{programmingForm.value.nite_degrees + (programmingForm.value.day_c2 - programmingForm.value.day_temp)}}</ion-col>
          <ion-col *ngIf="model.includes('GHK')">{{programmingForm.value.nite_degrees + (programmingForm.value.day_c3 - programmingForm.value.day_temp)}}</ion-col>
          <ion-col *ngIf="model.includes('GHK')">{{programmingForm.value.nite_degrees + (programmingForm.value.day_c4 - programmingForm.value.day_temp)}}</ion-col>
          <ion-col>{{programmingForm.value.nite_degrees - (programmingForm.value.day_temp - programmingForm.value.day_h1)}}</ion-col>
          <ion-col>{{programmingForm.value.nite_degrees - (programmingForm.value.day_temp - programmingForm.value.day_h2)}}</ion-col>
        </ion-row>
      </ion-grid>
    </div>

    <div *ngIf="!showGraph && programmingForm && programmingForm.value && programmingForm.value.zones === 2
      && (!narrow || (narrow && narrow.includes('Day') || narrow.includes('DIF') || narrow.includes('Night') || narrow.includes('Outputs') || narrow.includes('Zone 2 Outputs')))">
      <h6>Stage Configuration</h6>
      <ion-grid class="general">
        <ion-row>
          <ion-col>&nbsp;</ion-col>
          <ion-col class="header">Start Time</ion-col>
          <ion-col class="header">Zone 1</ion-col>
          <ion-col class="header">Zone 2</ion-col>
        </ion-row>
        <ion-row class="dif">
          <ion-col class="header">Dif &nbsp;&nbsp;&nbsp;</ion-col>
          <ion-col>{{programmingForm && programmingForm.value && programmingForm.value.dif_start || 'N/A'}}</ion-col>
          <ion-col>{{programmingForm && programmingForm.value && programmingForm.value.dif_degrees}}</ion-col>
          <ion-col>{{programmingForm && programmingForm.value && programmingForm.value.dif_degrees_zone2}}</ion-col>
        </ion-row>
        <ion-row class="day">
          <ion-col class="header">Day &nbsp;&nbsp;&nbsp;</ion-col>
          <ion-col>{{programmingForm && programmingForm.value && programmingForm.value.day_start || 'N/A'}}</ion-col>
          <ion-col>{{programmingForm && programmingForm.value && programmingForm.value.day_temp}}</ion-col>
          <ion-col>{{programmingForm && programmingForm.value && programmingForm.value.day_temp_zone2}}</ion-col>
        </ion-row>
        <ion-row class="nite">
          <ion-col class="header">Night &nbsp;&nbsp;&nbsp;</ion-col>
          <ion-col>{{programmingForm && programmingForm.value && programmingForm.value.nite_start}}</ion-col>
          <ion-col>{{programmingForm && programmingForm.value && programmingForm.value.nite_degrees}}</ion-col>
          <ion-col>{{programmingForm && programmingForm.value && programmingForm.value.nite_degrees_zone2}}</ion-col>
        </ion-row>
      </ion-grid>

      <h6 *ngIf="narrow && (narrow.includes('Outputs') || narrow.includes('Zone 2 Outputs'))">Heat/Cool Outputs</h6>
      <ion-grid class="general two-zone-set-points" *ngIf="narrow && (narrow.includes('Outputs') || narrow.includes('Zone 2 Outputs'))">
        <ion-row *ngIf="model && model.includes('GHK')" class="heat-cool-outputs">
          <ion-col class="header" colspan="1">Zone 1</ion-col>
          <ion-col class="header">Heat 1</ion-col>
          <ion-col class="header" *ngIf="model.includes('GHK')">Cool 1</ion-col>
          <ion-col class="header" *ngIf="model.includes('GHK')">Cool 3</ion-col>            
          <ion-col *ngIf="!model.includes('GHK')">&nbsp;</ion-col>
          <ion-col *ngIf="!model.includes('GHK')">&nbsp;</ion-col>
        </ion-row>
        <ion-row class="dif" *ngIf="model && model.includes('GHK')">
          <ion-col class="header" colspan="1">Dif</ion-col>
          <ion-col>{{programmingForm.value.dif_degrees - (programmingForm.value.day_temp - programmingForm.value.day_h1)}}</ion-col>
          <ion-col *ngIf="model.includes('GHK')">{{programmingForm.value.dif_degrees + (programmingForm.value.day_c1 - programmingForm.value.day_temp)}}</ion-col>
          <ion-col *ngIf="model.includes('GHK')">{{programmingForm.value.dif_degrees + (programmingForm.value.day_c3 - programmingForm.value.day_temp)}}</ion-col>            
          <ion-col *ngIf="!model.includes('GHK')">&nbsp;</ion-col>
          <ion-col *ngIf="!model.includes('GHK')">&nbsp;</ion-col>
        </ion-row>
        <ion-row *ngIf="model && model.includes('GHK')" class="day">
          <ion-col class="header" colspan="1">Day</ion-col>
          <ion-col>{{programmingForm.value.day_h1}}</ion-col>
          <ion-col *ngIf="model.includes('GHK')">{{programmingForm.value.day_c1}}</ion-col>
          <ion-col *ngIf="model.includes('GHK')">{{programmingForm.value.day_c3}}</ion-col>            
          <ion-col *ngIf="!model.includes('GHK')">&nbsp;</ion-col>
          <ion-col *ngIf="!model.includes('GHK')">&nbsp;</ion-col>
        </ion-row>
        <ion-row *ngIf="model && model.includes('GHK')" class="nite" >
          <ion-col class="header" colspan="1">Night</ion-col>
          <ion-col>{{programmingForm.value.nite_degrees - (programmingForm.value.day_temp - programmingForm.value.day_h1)}}</ion-col>
          <ion-col *ngIf="model.includes('GHK')">{{programmingForm.value.nite_degrees + (programmingForm.value.day_c1 - programmingForm.value.day_temp)}}</ion-col>
          <ion-col *ngIf="model.includes('GHK')">{{programmingForm.value.nite_degrees + (programmingForm.value.day_c3 - programmingForm.value.day_temp)}}</ion-col>                    
          <ion-col *ngIf="!model.includes('GHK')">&nbsp;</ion-col>
          <ion-col *ngIf="!model.includes('GHK')">&nbsp;</ion-col>
        </ion-row>
        <ion-row *ngIf="model && model.includes('GHK')">
          <ion-col class="header" colspan="1">Set Point</ion-col>
          <ion-col>{{(programmingForm.value.day_temp - programmingForm.value.day_h1)}}</ion-col>
          <ion-col *ngIf="model.includes('GHK')">{{(programmingForm.value.day_c1 - programmingForm.value.day_temp)}}</ion-col>
          <ion-col *ngIf="model.includes('GHK')">{{(programmingForm.value.day_c3 - programmingForm.value.day_temp)}}</ion-col>                    
          <ion-col *ngIf="!model.includes('GHK')">&nbsp;</ion-col>
          <ion-col *ngIf="!model.includes('GHK')">&nbsp;</ion-col>
        </ion-row>
        <ion-row *ngIf="model && !model.includes('GHK')">
          <ion-col>&nbsp;</ion-col>
          <ion-col class="header">Heat 1</ion-col>
          <ion-col class="header">Heat 2</ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid class="general two-zone-set-points">
        <ion-row *ngIf="model && model.includes('GHK')" class="heat-cool-outputs">
          <ion-col class="header" colspan="1">Zone 2</ion-col>
          <ion-col class="header">Heat 2</ion-col>
          <ion-col class="header" *ngIf="model.includes('GHK')">Cool 2</ion-col>
          <ion-col class="header" *ngIf="model.includes('GHK')">Cool 4</ion-col>            
          <ion-col *ngIf="!model.includes('GHK')">&nbsp;</ion-col>
          <ion-col *ngIf="!model.includes('GHK')">&nbsp;</ion-col>            
        </ion-row>
        <ion-row *ngIf="model && model.includes('GHK') && programmingForm && programmingForm.value" class="dif">
          <ion-col class="header" colspan="1">Dif</ion-col>
          <ion-col>{{programmingForm.value.dif_degrees_zone2 - (programmingForm.value.day_temp_zone2 - programmingForm.value.day_h2)}}</ion-col>
          <ion-col *ngIf="model.includes('GHK')">{{programmingForm.value.dif_degrees_zone2 + (programmingForm.value.day_c2 - programmingForm.value.day_temp_zone2)}}</ion-col>
          <ion-col *ngIf="model.includes('GHK')">{{programmingForm.value.dif_degrees_zone2 + (programmingForm.value.day_c4 - programmingForm.value.day_temp_zone2)}}</ion-col>            
          <ion-col *ngIf="!model.includes('GHK')">&nbsp;</ion-col>
          <ion-col *ngIf="!model.includes('GHK')">&nbsp;</ion-col>
        </ion-row>
        <ion-row *ngIf="model && model.includes('GHK') && programmingForm && programmingForm.value" class="day">
          <ion-col class="header" colspan="1">Day</ion-col>
          <ion-col>{{programmingForm.value.day_h2}}</ion-col>
          <ion-col *ngIf="model.includes('GHK')">{{programmingForm.value.day_c2}}</ion-col>
          <ion-col *ngIf="model.includes('GHK')">{{programmingForm.value.day_c4}}</ion-col>            
          <ion-col *ngIf="!model.includes('GHK')">&nbsp;</ion-col>
          <ion-col *ngIf="!model.includes('GHK')">&nbsp;</ion-col>
        </ion-row>
        <ion-row *ngIf="model && model.includes('GHK') && programmingForm && programmingForm.value" class="nite">
          <ion-col class="header" colspan="1">Night</ion-col>
          <ion-col>{{programmingForm.value.nite_degrees_zone2 - (programmingForm.value.day_temp_zone2 - programmingForm.value.day_h2)}}</ion-col>
          <ion-col *ngIf="model.includes('GHK')">{{programmingForm.value.nite_degrees_zone2 + (programmingForm.value.day_c2 - programmingForm.value.day_temp_zone2)}}</ion-col>
          <ion-col *ngIf="model.includes('GHK')">{{programmingForm.value.nite_degrees_zone2 + (programmingForm.value.day_c4 - programmingForm.value.day_temp_zone2)}}</ion-col>            
          <ion-col *ngIf="!model.includes('GHK')">&nbsp;</ion-col>
          <ion-col *ngIf="!model.includes('GHK')">&nbsp;</ion-col>
        </ion-row>
        <ion-row *ngIf="model && model.includes('GHK') && programmingForm && programmingForm.value">
          <ion-col class="header" colspan="1">Set Point</ion-col>
          <ion-col>{{(programmingForm.value.day_temp_zone2 - programmingForm.value.day_h2)}}</ion-col>
          <ion-col *ngIf="model.includes('GHK')">{{(programmingForm.value.day_c2 - programmingForm.value.day_temp_zone2)}}</ion-col>
          <ion-col *ngIf="model.includes('GHK')">{{(programmingForm.value.day_c4 - programmingForm.value.day_temp_zone2)}}</ion-col>            
          <ion-col *ngIf="!model.includes('GHK')">&nbsp;</ion-col>
          <ion-col *ngIf="!model.includes('GHK')">&nbsp;</ion-col>
        </ion-row>
      </ion-grid>
    </div>

    <div *ngIf="vent_config && vent_config.length > 0 && (reviewing && !showGraph)
      && (!narrow || (narrow && narrow.includes('Vents') || narrow.includes('Zone 2 Vents')))" class="vent-config-review">
      <h6>Vents</h6>
      <ion-grid>
        <ion-row>
          <ion-col class="header">Name</ion-col>
          <ion-col class="header">Start</ion-col>
          <ion-col class="header">P1</ion-col>
          <ion-col class="header">P2</ion-col>
          <ion-col class="header">P3</ion-col>
          <ion-col class="header">P4</ion-col>
          <ion-col class="header">P5</ion-col>
          <ion-col class="header">Rain</ion-col>
          <ion-col class="header">W1</ion-col>
          <ion-col class="header">W2</ion-col>            
        </ion-row>
        <ng-container *ngFor="let v of vent_config; let i = index;">
          <ion-row>
            <ion-col>{{v.vent_name || getVentName(i)}}</ion-col>
            <ion-col>{{v.start_opening || 'N/A'}}</ion-col>
            <ion-col>{{v.p1}}</ion-col>
            <ion-col>{{v.p2}}</ion-col>
            <ion-col>{{v.p3}}</ion-col>
            <ion-col>{{v.p4}}</ion-col>
            <ion-col>{{v.p5}}</ion-col>
            <ion-col>
              <ion-icon name="rainy-outline" [class.dark]="v.rainOR === 1" [class.display-none]="v.rainOR !== 1"></ion-icon>
            </ion-col>
            <ion-col>
              <ion-icon src="assets/custom/icon_wind.svg" [class.dark]="v.wind1OR === 1" [class.display-none]="v.wind1OR !== 1"></ion-icon>
            </ion-col>
            <ion-col>
              <ion-icon src="assets/custom/icon_wind2.svg" [class.dark]="v.wind2OR === 1" [class.display-none]="v.wind2OR !== 1"></ion-icon>
            </ion-col>
          </ion-row>            
        </ng-container>
      </ion-grid>
    </div>

    <div *ngIf="dehumValue && (reviewing && !showGraph)
            && (!narrow || (narrow && narrow.includes('Dehumidification')))" class="dehum-config-review">
      <h6>Dehumidification Values</h6>
      <div *ngIf="dehumValue.humid_sensor === 'true'">
        <p>Humidity Set Point: {{dehumValue.humidity_set_point}}</p>
      </div>

      <div *ngIf="dehumValue.humid_sensor === 'false'">
        <p>Start Time: {{dehumValue.start}}, {{dehumValue.repeat ? 'Repeats Daily': 'No Repeat'}}</p>
      </div>

      <div *ngIf="dehumValue.outputs && dehumValue.outputs.length > 0">Outputs that are eligible to run during the dehumidification cycle: {{dehumValue.outputs.join(', ')}}</div>

      <ion-grid class="dehum-sub-table">
        <ion-row><ion-col class="header">Configuration</ion-col><ion-col class="header">Details</ion-col></ion-row>
        <ion-row><ion-col>May run during</ion-col><ion-col>{{dehumValue.stages.join(', ') | uppercase }}</ion-col></ion-row>
        <ion-row><ion-col>High Limit</ion-col><ion-col>{{(dehumValue.high_limit === 0 || dehumValue.high_limit === 'high') ? dehumValue.high_limit_val : dehumValue.high_limit}}</ion-col></ion-row>
        <ion-row><ion-col>Low Limit</ion-col><ion-col>{{(dehumValue.low_limit === 0 || dehumValue.low_limit === 'low') ? dehumValue.low_limit_val : dehumValue.low_limit}}</ion-col></ion-row>
      </ion-grid>

      <ion-grid>
        <ion-row><ion-col class="header">Cycle</ion-col><ion-col class="header">Time</ion-col></ion-row>
        <ion-row><ion-col>Exhaust</ion-col><ion-col>{{dehumValue.exhaust_1}}</ion-col></ion-row>
        <ion-row><ion-col>Preheat</ion-col><ion-col>{{dehumValue.preheat}}</ion-col></ion-row>
        <ion-row><ion-col>Exhaust 2</ion-col><ion-col>{{dehumValue.exhaust_2}}</ion-col></ion-row>
      </ion-grid>
    </div>
    <div *ngIf="timer_values && timer_values.length > 0 && (reviewing && !showGraph)
                  && (!narrow || (narrow && narrow.includes('Timers')))" class="timer-config-review">
      <h6>Timer Values</h6>
      <ion-grid>
        <ion-row>
          <ion-col class="header">Name</ion-col>
          <ion-col class="header" colspan='2'>Stage A</ion-col>
          <ion-col class="header" colspan='2'>Stage B</ion-col>
          <ion-col class="header" colspan='2'>Light SP</ion-col>
        </ion-row>
        <ng-container *ngFor="let t of timer_values; let ti = index">
          <ion-row>
            <ion-col>{{t.name ? t.name : 'Timer ' + (ti + 1)}}</ion-col>
            <ion-col>{{t.stage_a_start_time || t.startTimeA}}</ion-col>
            <ion-col>{{t.stage_a_run_time || t.holdTimeA}}</ion-col>
            <ion-col>{{t.stage_b_start_time || t.startTimeB}}</ion-col>
            <ion-col>{{t.stage_b_run_time || t.holdTimeB}}</ion-col>
            <ion-col colspan='2'>{{t.light_sp || t.lightSP}}</ion-col>
          </ion-row>
          <ion-row *ngIf="t.isMister && !t.isShade">
            <ion-col>&nbsp;</ion-col>
            <ion-col class="header" colspan='2'>Stage A</ion-col>
            <ion-col class="header" colspan='2'>Stage B</ion-col>
            <ion-col class="header" colspan="2">Idle</ion-col>
          </ion-row>
          <ion-row *ngIf="t.isMister && !t.isShade">
            <ion-col>&nbsp;</ion-col>
            <ion-col>Cycle Time</ion-col>
            <ion-col>On Time</ion-col>
            <ion-col>Cycle Time</ion-col>
            <ion-col>On Time</ion-col>
            <ion-col>Cycle Time</ion-col>
            <ion-col>On Time</ion-col>
          </ion-row>
          <ion-row *ngIf="t.isMister && !t.isShade">
            <ion-col>&nbsp;</ion-col>
            <ion-col>{{t.stage_a_cycle_time || t.mistACycleMin}}</ion-col>
            <ion-col>{{t.stage_a_on_time || t.mistAOnSec}}</ion-col>
            <ion-col>{{t.stage_b_cycle_time || t.mistBCycleMin}}</ion-col>
            <ion-col>{{t.stage_b_on_time || t.mistBOnSec}}</ion-col>
            <ion-col>{{t.stage_idle_cycle_time || t.mistIdleCycleMin}}</ion-col>
            <ion-col>{{t.stage_idle_on_time || t.mistIdleOnSec}}</ion-col>
          </ion-row>
          <ion-row *ngIf="timerTypes && timerTypes[ti] && timerTypes[ti].isShade">
            <p *ngIf="t.slow_retract">The shade is configured to slow retract. It will step back by {{t.step_percent}}% for 3 pulses and pause for {{t.pause}} between pulses.</p>
            <p *ngIf="t.open_time">You have set the total open time for the shade is {{t.open_time}}.</p>
          </ion-row>
        </ng-container>
      </ion-grid>
    </div>
  </div>    
</div>