<ul class="popover-settings-list">
  <li (click)="open('/')">Home</li>
  <li (click)="open('settings')">General Settings</li>
  <li (click)="open('notifications')">Notification Settings</li>
  <li (click)="open('edit-subscription')">Subscription</li>
  <li (click)="open('view-site')">Manage Site(s)</li>
  <li (click)="open('users')">User Access</li>
  <li (click)="open('push-notifications')">Sent Notifications</li>
  <li (click)="open('contact-us')">Contact Us</li>
  <li (click)="open('terms-and-conditions')">Legal</li>
</ul>
