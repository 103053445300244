<div id="credit-card-container" *ngIf="user && (user.subscription || user.braintree_customer_id)">
  <ul>
    <li>
      <h5 *ngIf="paymentInfo">Credit Card Information</h5>
      <div *ngIf="hasExpired">Your credit card information has expired.</div>
      <div *ngIf="user && !user.subscription">You have not set up a payment method. You can do so when you create a subscription.</div>
    </li>
    <li class="vertical">
      <button class="primary" *ngIf="!updating && user.subscription && paymentInfo" (click)="updatePayment()">Update Now</button>
      <div *ngIf="updating">
        <ion-progress-bar *ngIf="!dropInFormSuccess" type="indeterminate"></ion-progress-bar>
        <div id="dropin-container"></div>
        <div id="submit-container" *ngIf="!updatingPayment">
          <button *ngIf="updating" (click)="updating = false;">Cancel Update</button>
          <button class="primary" id="submit-button">Submit</button>
        </div>
      </div>
    </li>
    <li class="vertical" *ngIf="paymentInfo">
      <button *ngIf="!viewing" (click)="viewing = true">View Masked Card Information</button>
      <button *ngIf="viewing === true" (click)="viewing = false">Hide Masked Card Information</button>
      <div *ngIf="viewing">
        <p><label>Masked Number:</label> {{paymentInfo.masked}}</p>
        <p><label>Expiration:</label> {{paymentInfo.expiration}}</p>
      </div>
    </li>
    <li *ngIf="!paymentInfo">You do not have a method of payment set up.</li>
  </ul>
</div>