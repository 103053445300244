<div class="section" *ngIf="showSection" [class.active]="showSection">
  <form id="offline-controller-search" [formGroup]="searchForm" (ngSubmit)="filter(searchForm.value)">
    <label>Search Controllers <input type='search' value="User" formControlName="searchTerm" autofocus="true" #searchInputId/></label>
    <div>
      <ion-button type="button" (click)="cancelSearch()"><ion-icon name="close-outline"></ion-icon></ion-button>
      <ion-button type="submit" (click)="filter(searchForm.value)"><ion-icon name="search-outline"></ion-icon></ion-button>
    </div>
  </form>
  <div class="summary">
    <div><label>Offline Total:</label><span>{{stats.total}}</span></div>
    <div><label>Total Show:</label><span>{{filteredControllers.length > 0 ? filteredControllers.length : stats.total}}</span></div>
    <div><label>Registered:</label><span>{{stats.registered}}</span></div>
  </div>
  <div class='sort_items'>
    <h6>Filter</h6>
    <ul>
      <li>
        <select [(ngModel)]='filteredSite' (change)="setSelectedSite($event)">
          <option value="reset">See All</option>
          <option
            *ngFor="let owner_site of getOfflineSites()"
            [value]="owner_site"
            selected="owner_site">{{owner_site}} ({{owners_site[owner_site]}})
          </option>
        </select>
      </li>
      <li>
        <select [(ngModel)]='filteredOwner' (change)="setOwner($event)">
          <option value="reset">See All</option>
          <option
            *ngFor="let owner of getOfflineSiteOwners()"
            [value]="owner"
            selected="owner">{{owner}} ({{offline_owners[owner]}})
          </option>
        </select>
      </li>

      <li [class.showing]="isRequired('registeredDate')" (click)="sortBy('registeredDate')">
        Registered
        <ion-icon *ngIf="sort_column === 'registeredDate' && !is_ascending" name="chevron-down-outline"></ion-icon>
        <ion-icon *ngIf="sort_column === 'registeredDate' && is_ascending" name="chevron-up-outline"></ion-icon>
      </li>
      <li [class.showing]="isRequired('fw')" (click)="sortBy('fw')">
        Firmware
        <ion-icon *ngIf="sort_column === 'fw' && !is_ascending" name="chevron-down-outline"></ion-icon>
        <ion-icon *ngIf="sort_column === 'fw' && is_ascending" name="chevron-up-outline"></ion-icon>
      </li>
      <li [class.showing]="isRequired('updatedAt')" (click)="sortBy('updatedAt')">
        Updated At
        <ion-icon *ngIf="sort_column === 'updatedAt' && !is_ascending" name="chevron-down-outline"></ion-icon>
        <ion-icon *ngIf="sort_column === 'updatedAt' && is_ascending" name="chevron-up-outline"></ion-icon>
      </li>
      <li [class.showing]="isRequired('in_use')" (click)="sortBy('in_use')">
        In Use
        <ion-icon *ngIf="sort_column === 'in_use' && !is_ascending" name="chevron-down-outline"></ion-icon>
        <ion-icon *ngIf="sort_column === 'in_use' && is_ascending" name="chevron-up-outline"></ion-icon>
      </li>
    </ul>
  </div>
  <div class='shown_fields'>
    <h6>Fields to Show</h6>
    <ul>
      <li>
        <label>Registered Date: </label>
        <input type="checkbox" color="secondary-blue" [(ngModel)]="show.registeredDate"/>
      </li>
      <li>
        <label>Model: </label>
        <input type="checkbox" color="secondary-blue" [(ngModel)]="show.model"/>
      </li>
      <li>
        <label>Firmware: </label>
        <input type="checkbox" color="secondary-blue" [(ngModel)]="show.fw"/>
      </li>
      <li>
        <label>Online: </label>
        <input type="checkbox" color="secondary-blue" [(ngModel)]="show.online"/>
      </li>
      <li>
        <label>In Use: </label>
        <input type="checkbox" color="secondary-blue" [(ngModel)]="show.in_use"/>
      </li>
    </ul>
  </div>
  <ul id="offline-controllers">
    <li *ngFor="let controller of getControllers()">
      <h6>Node ID: {{controller.node_id}}</h6>
      <div *ngIf="hasSite(controller)">Site: {{getSite(controller).name}}</div>
      <div *ngIf="hasSite(controller)">Owner: {{getOwner(controller)}}</div>
      <div *ngIf="!hasSite(controller)">Site: N/A</div>
      <div *ngIf="show.updatedAt">Last Updated: {{controller.updatedAt | date:'short' }} ({{getOfflineDuration(controller)}})</div>
      <div *ngIf="show.model">Model: {{controller.model }}</div>
      <div *ngIf="show.fw">Firmware: {{controller.fw }}</div>
      <div *ngIf="controller.registeredDate && show.registeredDate">Registered: {{controller.registeredDate | date:'short' }}</div>
      <div *ngIf="show.online">Online: {{controller.online}}</div>
      <div *ngIf="show.in_use">In Use: {{controller.in_use}}</div>
    </li>
  </ul>
</div>
