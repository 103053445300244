<section id="time-boss-status" *ngIf="nodeData && timers && timers.length > 0">
  <div class="alarm offline" *ngIf="!nodeData.online">
    <div>
      <p>OFFLINE</p>
      <p>The node is offline and was last updated: {{nodeData.updatedAt | date:'short'}}</p>
    </div>
    <ion-icon name='eye-off' class="note-alert warning"></ion-icon>
  </div>
  <div *ngFor="let t of timers; let i = index;" class="timer" [class.timer-on]="t ? t.on : 'false'">
    <app-time-boss-status [t]="t" [i]="i+1"
      [canEdit]="canEdit" (pending)="setPending($event)"
      [nodeData]="nodeData" [email]="current_user"
      [light_value]="nodeData.status && nodeData.status.light"
      [daily_light_integral]="nodeData.status && nodeData.status.dli"
      [light_set_point]="getLightSetPoint()"
      [misterSettings]="getMisterSettings(t)"
      [misterIndex]="i+1" [pendingSettings]="t ? t.pendingSettings : {}"
      [shadeInfo]="getShadeInfo(i)"
      [clock]="nodeData.status && nodeData.status.time"
      ></app-time-boss-status>
  </div>

  <hr class="hr-line">
  <div class="last-update">
    <span>Last Update</span>
    <span>{{nodeData.updatedAt | date:'short'}}</span>
  </div>
</section>
