import { Component, Input, OnInit } from '@angular/core';
import { NavController, Platform, ToastController } from '@ionic/angular';
import { NotificationDetails, SitesService } from 'src/app/services/sites.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-controller-notifications',
  templateUrl: './controller-notifications.component.html',
  styleUrls: ['./controller-notifications.component.scss'],
})
export class ControllerNotificationsComponent implements OnInit {
  @Input() node_id = null;
  @Input() model: String;
  @Input() is_time_boss = false;
  public device;
  public notifications: NotificationDetails;
  @Input() call_test_group = false;
  private default_user_alert_type = 'push';

  constructor(
    public navCtrl: NavController,
    public sitesApiProvider: SitesService,
    private toastCtrl: ToastController,
    private platform: Platform,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.userService.getLoggedInUser().then((user) => { 
      this.default_user_alert_type = user.alert_type || 'push';
      this.sitesApiProvider.getAlerts(this.node_id)
          .then((data) => this.setDevices(data))
          .catch(() => this.showFailureMessage());
            if(this.device && this.device.notifications) {
              this.call_test_group = this.device.allowTextsAndPhoneCallNotifications;
              this.setDevices([this.device])
            }
    });
  }

  public toggleAll(event) {
    if(!this.call_test_group) {
      var toggleTo = (event && event.detail) ? event.detail.checked : false;
      Object["keys"](this.notifications).forEach((key) => {
        this.notifications[key] = this.notifications[key] || toggleTo;
      });
    }
  }

  public checkForAll() {
    var individualNotificationKeys = ["status", "settings", "alarms", "settingsFailed"];
    if(this.model.includes("WR") || this.model.includes("WB")) {
      individualNotificationKeys = individualNotificationKeys.concat(["wind", "rain"])
    }

    return individualNotificationKeys
      .every((key) => {
        var val = this.notifications[key] && this.notifications[key] === 'false' ? false : this.notifications[key];
        if(key == "wind" || key == "rain") {
          return (this.device && this.device.wind_rain) ? val : true;
        } else {
          return val;
        }
      });
  }

  public toggleOne(event) {
    if(event.target.name) {
      this.notifications[event.target.name] = event.detail.checked;
    }
  }

  public saveAlertSettings() {
    this.notifications.push = this.checkForAll();
    this.notifications.windAndRain = this.notifications.wind && this.notifications.rain;
    this.sitesApiProvider.setAlerts((this.node_id || this.device.node_id), this.notifications.push, this.notifications).then(() => {this.showSuccessMessage()});
  }

  async showSuccessMessage() {
    let toast = await this.toastCtrl.create({
      message: `Your notification settings for this device have been updated`,
      duration: 1500,
      position: 'top'
    });

    if(820 > this.platform.width()) {
      toast.onDidDismiss().then(() => {
        // this.navCtrl.pop()
        this.navCtrl.navigateForward('settings', {replaceUrl: true})
      });
    }

    toast.present();
  }

  async showFailureMessage() {
    let toast = await this.toastCtrl.create({
      message: `There was an error getting notifications, please try again or contact us for help.`,
      duration: 1500,
      position: 'top',
      color: 'danger'
    });

    toast.present();
  }

  private setDevices(data) {
    this.device = (data.length === 1) ? data[0] : data.find((alertSettings) => { return alertSettings.node_id === this.node_id});
    if(this.device) {
      this.call_test_group = this.device.allowTextsAndPhoneCallNotifications;
      let default_notifications = { push: false, status: false, alarms: false, settings: false, wind: false, rain: false, windAndRain: false, settingsFailed: false };
      this.notifications = this.device.notifications ? this.device.notifications : default_notifications;
      this.notifications.push = (this.notifications.push || this.notifications.push === 'true') ? this.default_user_alert_type : this.notifications.push;
      Object.keys(this.notifications).forEach((notification_key) => {
        if(this.notifications[notification_key] === 'true' || (!this.notifications[notification_key] && this.notifications.push)) {
          this.notifications[notification_key] = this.notifications.push;
        }

        if(this.notifications[notification_key] === 'false') { 
          this.notifications[notification_key] = false;
        }
      })

      this.notifications = {
        push: this.notifications.push,
        status: this.notifications.status || this.notifications.push,
        alarms: this.notifications.alarms || this.notifications.push,
        settings: this.notifications.settings || this.notifications.push,
        wind: this.notifications.wind || this.notifications.push,
        rain: this.notifications.rain || this.notifications.push,
        windAndRain: this.notifications.windAndRain || this.notifications.push,
        settingsFailed: this.notifications.settingsFailed || this.notifications.push,
        vfdLinkLost: this.notifications.vfdLinkLost || this.notifications.push
      }
    } else {
      this.notifications = {
        push: false, status: false, alarms: false, settings: false,
        wind: false, rain: false, windAndRain: false,
        settingsFailed: false, vfdLinkLost: false
      }
    }
  }
}
