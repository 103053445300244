<div class="program-review full" *ngIf="settings" [class.reviewing]="reviewing">
  <div class="title program-review-title">      
    <h5>Program Review</h5>
    <div>
      <button *ngIf="!showGraph && reviewing" (click)="showHideGraph()"><ion-icon name="stats-chart-outline"></ion-icon></button>
      <button *ngIf="showGraph && reviewing" (click)="showHideGraph()"><ion-icon name="list-outline"></ion-icon></button>
    </div>
  </div>
  <div class="review-container" [class.reviewing]="reviewing" *ngIf="settings && settings">
    <div id="timelinechart" [class.display]="showGraph && reviewing"></div>
    <div id="timelinechart2" [class.display]="showGraph && reviewing"
      [class.hidden]="settings && settings && settings.zones && settings.zones !== 2"></div>
    <div *ngIf="((reviewing && !showGraph)) && settings && settings && settings.zones !== 2">
      <h6>Stage Configuration</h6>
      <table class="general" *ngIf="settings && settings">
        <tr>
          <td>&nbsp;</td>
          <th>Start Time</th>
          <th>Target Temp</th>
        </tr>
        <tr>
          <th class="dif">Dif &nbsp;&nbsp;&nbsp;</th>
          <td>{{settings.difst || 'N/A'}}</td>
          <td>{{settings.difsp1}}</td>
        </tr>
        <tr>
          <th class="day">Day &nbsp;&nbsp;&nbsp;</th>
          <td>{{settings.dayst || 'N/A'}}</td>
          <td>{{settings.daysp1}}</td>
        </tr>
        <tr>
          <th class="nite">Night &nbsp;&nbsp;&nbsp;</th>
          <td>{{settings.nitst}}</td>
          <td>{{settings.nitsp1}}</td>
        </tr>
      </table>

      <h6>Heat/Cool Outputs</h6>
      <table class="general" *ngIf="settings && settings">
        <tr class="heat-cool-outputs">
          <th>Activation by Stage</th>
          <th *ngIf="model.includes('GHK')">Cool 1</th>
          <th *ngIf="model.includes('GHK')">Cool 2</th>
          <th *ngIf="model.includes('GHK')">Cool 3</th>
          <th *ngIf="model.includes('GHK')">Cool 4</th>
          <th>Heat 1</th>
          <th>Heat 2</th>
        </tr>
        <tr>
          <th class="dif">DIF</th>
          <td *ngIf="model.includes('GHK')">{{settings.difsp1 + settings.c1t}}</td>
          <td *ngIf="model.includes('GHK')">{{settings.difsp1 + settings.c2t}}</td>
          <td *ngIf="model.includes('GHK')">{{settings.difsp1 + settings.c3t}}</td>
          <td *ngIf="model.includes('GHK')">{{settings.difsp1 + settings.c4t}}</td>
          <td>{{settings.difsp1 - settings.h1t}}</td>
          <td>{{settings.difsp1 - settings.h2t}}</td>
        </tr>
        <tr>
          <th class="day">Day</th>
          <td *ngIf="model.includes('GHK')">{{settings.daysp1 + settings.c1t}}</td>
          <td *ngIf="model.includes('GHK')">{{settings.daysp1 + settings.c2t}}</td>
          <td *ngIf="model.includes('GHK')">{{settings.daysp1 + settings.c3t}}</td>
          <td *ngIf="model.includes('GHK')">{{settings.daysp1 + settings.c4t}}</td>
          <td>{{settings.daysp1 - settings.h1t}}</td>
          <td>{{settings.daysp1 - settings.h2t}}</td>
        </tr>
        <tr>
          <th class="nite">Night</th>
          <td *ngIf="model.includes('GHK')">{{settings.nitsp1 + settings.c1t}}</td>
          <td *ngIf="model.includes('GHK')">{{settings.nitsp1 + settings.c2t}}</td>
          <td *ngIf="model.includes('GHK')">{{settings.nitsp1 + settings.c3t}}</td>
          <td *ngIf="model.includes('GHK')">{{settings.nitsp1 + settings.c4t}}</td>
          <td>{{settings.nitsp1 - settings.h1t}}</td>
          <td>{{settings.nitsp1 - settings.h2t}}</td>
        </tr>
      </table>
    </div>

    <div *ngIf="((reviewing && !showGraph)) 
      && settings && settings && settings.zones === 2">
      <h6>Stage Configuration</h6>
      <table class="general">
        <tr>
          <td>&nbsp;</td>
          <th>Start Time</th>
          <th>Zone 1</th>
          <th>Zone 2</th>
        </tr>
        <tr class="dif">
          <th>Dif &nbsp;&nbsp;&nbsp;</th>
          <td>{{settings && settings.difst || 'N/A'}}</td>
          <td>{{settings && settings.difsp1}}</td>
          <td>{{settings && settings.difsp2}}</td>
        </tr>
        <tr class="day">
          <th>Day &nbsp;&nbsp;&nbsp;</th>
          <td>{{settings && settings.dayst || 'N/A'}}</td>
          <td>{{settings && settings.daysp1}}</td>
          <td>{{settings && settings.daysp2}}</td>
        </tr>
        <tr class="nite">
          <th>Night &nbsp;&nbsp;&nbsp;</th>
          <td>{{settings && settings.nitst || 'N/A'}}</td>
          <td>{{settings && settings.nitsp1}}</td>
          <td>{{settings && settings.nitsp2}}</td>
        </tr>
      </table>

      <h6>Heat/Cool Outputs</h6>
      <table class="general two-zone-set-points">
        <tr *ngIf="model && model.includes('GHK')" class="heat-cool-outputs">
          <th colspan="1">Zone 1</th>
          <th>Heat 1</th>
          <th *ngIf="model.includes('GHK')">Cool 1</th>
          <th *ngIf="model.includes('GHK')">Cool 3</th>            
          <td *ngIf="!model.includes('GHK')">&nbsp;</td>
          <td *ngIf="!model.includes('GHK')">&nbsp;</td>
        </tr>
        <tr class="dif" *ngIf="model && model.includes('GHK')">
          <th colspan="1">Dif</th>
          <td>{{settings.difsp1 - settings.h1t}}</td>
          <td *ngIf="model.includes('GHK')">{{settings.difsp1 + settings.c1t}}</td>
          <td *ngIf="model.includes('GHK')">{{settings.difsp1 + settings.c3t}}</td>
          <td *ngIf="!model.includes('GHK')">&nbsp;</td>
          <td *ngIf="!model.includes('GHK')">&nbsp;</td>
        </tr>
        <tr *ngIf="model && model.includes('GHK')" class="day">
          <th colspan="1">Day</th>
          <td>{{settings.daysp1 - settings.h1t}}</td>
          <td *ngIf="model.includes('GHK')">{{settings.daysp1 + settings.c1t}}</td>
          <td *ngIf="model.includes('GHK')">{{settings.daysp1 + settings.c3t}}</td>         
          <td *ngIf="!model.includes('GHK')">&nbsp;</td>
          <td *ngIf="!model.includes('GHK')">&nbsp;</td>
        </tr>
        <tr *ngIf="model && model.includes('GHK')" class="nite" >
          <th colspan="1">Night</th>
          <td>{{settings.nitsp1 - settings.h1t}}</td>
          <td *ngIf="model.includes('GHK')">{{settings.nitsp1 + settings.c1t}}</td>
          <td *ngIf="model.includes('GHK')">{{settings.csp1 + settings.c3t}}</td>
          <td *ngIf="!model.includes('GHK')">&nbsp;</td>
          <td *ngIf="!model.includes('GHK')">&nbsp;</td>
        </tr>
        <!-- <tr *ngIf="model && model.includes('GHK')">
          <th colspan="1">Set Point</th>
          <td>{{(settings.day_temp - settings.day_h1)}}</td>
          <td *ngIf="model.includes('GHK')">{{(settings.day_c1 - settings.day_temp)}}</td>
          <td *ngIf="model.includes('GHK')">{{(settings.day_c3 - settings.day_temp)}}</td>                    
          <td *ngIf="!model.includes('GHK')">&nbsp;</td>
          <td *ngIf="!model.includes('GHK')">&nbsp;</td>
        </tr> -->
        <tr *ngIf="model && !model.includes('GHK')">
          <td>&nbsp;</td>
          <th>Heat 1</th>
          <th>Heat 2</th>
        </tr>
      </table>
      <table class="general two-zone-set-points">
        <tr *ngIf="model && model.includes('GHK')" class="heat-cool-outputs">
          <th colspan="1">Zone 2</th>
          <th>Heat 2</th>
          <th *ngIf="model.includes('GHK')">Cool 2</th>
          <th *ngIf="model.includes('GHK')">Cool 4</th>            
          <td *ngIf="!model.includes('GHK')">&nbsp;</td>
          <td *ngIf="!model.includes('GHK')">&nbsp;</td>            
        </tr>
        <tr *ngIf="model && model.includes('GHK') && settings && settings" class="dif">
          <th colspan="1">Dif</th>
          <td>{{settings.difsp2 - settings.h2t}}</td>
          <td *ngIf="model.includes('GHK')">{{settings.difsp2 + settings.c2t}}</td>
          <td *ngIf="model.includes('GHK')">{{settings.difsp2 + settings.c4t}}</td>
          <td *ngIf="!model.includes('GHK')">&nbsp;</td>
          <td *ngIf="!model.includes('GHK')">&nbsp;</td>
        </tr>
        <tr *ngIf="model && model.includes('GHK') && settings && settings" class="day">
          <th colspan="1">Day</th>
          <td>{{settings.daysp2 - settings.h2t}}</td>
          <td *ngIf="model.includes('GHK')">{{settings.daysp2 + settings.c2t}}</td>
          <td *ngIf="model.includes('GHK')">{{settings.daysp2 + settings.c4t}}</td>
          <td *ngIf="!model.includes('GHK')">&nbsp;</td>
          <td *ngIf="!model.includes('GHK')">&nbsp;</td>
        </tr>
        <tr *ngIf="model && model.includes('GHK') && settings && settings" class="nite">
          <th colspan="1">Night</th>
          <td>{{settings.nitsp2 - settings.h2t}}</td>
          <td *ngIf="model.includes('GHK')">{{settings.nitsp2 + settings.c2t}}</td>
          <td *ngIf="model.includes('GHK')">{{settings.nitsp2 + settings.c4t}}</td>
          <td *ngIf="!model.includes('GHK')">&nbsp;</td>
          <td *ngIf="!model.includes('GHK')">&nbsp;</td>
        </tr>
        <!-- <tr *ngIf="model && model.includes('GHK') && settings && settings">
          <th colspan="1">Set Point</th>
          <td>{{(settings.day_temp_zone2 - settings.day_h2)}}</td>
          <td *ngIf="model.includes('GHK')">{{(settings.day_c2 - settings.day_temp_zone2)}}</td>
          <td *ngIf="model.includes('GHK')">{{(settings.day_c4 - settings.day_temp_zone2)}}</td>            
          <td *ngIf="!model.includes('GHK')">&nbsp;</td>
          <td *ngIf="!model.includes('GHK')">&nbsp;</td>
        </tr> -->
      </table>
    </div>

    <div *ngIf="populatedVents && populatedVents.length > 0 && (reviewing && !showGraph)" class="vent-config-review">
      <h6>Vents</h6>
      <table>
        <tr>
          <th>Name</th>
          <th>Start</th>
          <th>P1</th>
          <th>P2</th>
          <th>P3</th>
          <th>P4</th>
          <th>P5</th>
          <th>Rain</th>
          <th>W1</th>
          <th>W2</th>            
        </tr>
        <ng-container *ngFor="let v of populatedVents;">
          <tr>
            <td>{{getVentName(v.index)}}</td>
            <td>{{v.st !== undefined ? v.st : ''}}</td>
            <td>{{v.p1}}</td>
            <td>{{v.p2}}</td>
            <td>{{v.p3}}</td>
            <td>{{v.p4}}</td>
            <td>{{v.p5}}</td>
            <td>
              <ion-icon name="rainy-outline" [class.dark]="v.rainOR === 1" [class.display-none]="v.rainOR !== 1"></ion-icon>
            </td>
            <td>
              <ion-icon src="assets/custom/icon_wind.svg" [class.dark]="v.wind1OR === 1" [class.display-none]="v.wind1OR !== 1"></ion-icon>
            </td>
            <td>
              <ion-icon src="assets/custom/icon_wind2.svg" [class.dark]="v.wind2OR === 1" [class.display-none]="v.wind2OR !== 1"></ion-icon>
            </td>
          </tr>            
        </ng-container>
      </table>
    </div>

    <div *ngIf="settings && (reviewing && !showGraph)" class="dehum-config-review">
      <h6>Dehumidification Values</h6>
      <div *ngIf="settings.humsp !== '0'">
        <p>Humidity Set Point: {{settings.humsp}}</p>
      </div>

      <div *ngIf="settings.humsp === '0'">
        <p>Start Time: {{settings.dhStartTime}}, {{settings.dhVentMode === 'Timed' ? 'No Repeat': 'Repeats Daily'}}</p>
      </div>

      <div *ngIf="settings.dhSetup && settings.dhSetup > 0">Outputs that are eligible to run during the dehumidification cycle: {{dehumidificationSetupValue(settings.dhSetup)}}</div>
      
      <table class="dehum-sub-table">
        <tr><th>Configuration<th><th>Details</th></tr>
        <tr><td>May run during: </td>
          <td *ngIf="settings.dhDif !== 'OFF' || settings.dhDay !== 'OFF' || settings.dhNight !== 'OFF'">{{settings.dhDif ? 'DIF, ' : ''}}{{settings.dhDay ? 'Day, ' : ''}}{{settings.dhNight ? 'Night, ' : ''}}</td>
          <td *ngIf="settings.dhDif === 'OFF' && settings.dhDay === 'OFF' && settings.dhNight === 'OFF'">No stages enabled</td>
        </tr>
        <tr><td>High Limit</td><td>{{(settings.dhHighTemp === '0' || settings.dhHighTemp === 'undefined') ? 'Use Cool 2' : settings.dhHighTemp}}</td></tr>
        <tr><td>Low Limit</td><td>{{(settings.dhLoTemp === '0' || settings.dhLoTemp === 'undefined') ? 'Use Heat 1' : settings.dhLoTemp}}</td></tr>
      </table>

      <table>
        <tr><th>Cycle<th><th>Time</th></tr>
        <tr><td>Exhaust</td><td>{{settings.dhFan1}}m</td></tr>
        <tr><td>Preheat</td><td>{{settings.dhHeat}}m</td></tr>
        <tr><td>Exhaust 2</td><td>{{settings.dhFan2}}m</td></tr>
      </table>
    </div>
    <div *ngIf="timer_values && timer_values.length > 0 && (reviewing && !showGraph)" class="timer-config-review">
      <h6>Timer Values</h6>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th colspan='2'>Stage A</th>
            <th colspan='2'>Stage B</th>
            <th colspan='2'>Light SP</th>
          </tr>        
        </thead>
        <tbody>
          <ng-container *ngFor="let t of timer_values; let ti = index;">
            <tr>
              <td>{{t.name ? t.name : 'Timer ' + (ti + 1)}}</td>
              <td>{{t.startTimeA}}</td>
              <td>{{t.holdTimeA}}</td>
              <td>{{t.startTimeB}}</td>
              <td>{{t.holdTimeB}}</td>
              <td colspan='2'>{{t.lightSP}}</td>
            </tr>
            <tr *ngIf="t && t.isMister && !t.isShade">
              <td>&nbsp;</td>
              <th colspan='2'>Stage A</th>
              <th colspan='2'>Stage B</th>
              <th colspan="2">Idle</th>
            </tr>
            <tr *ngIf="t && t.isMister && !t.isShade">
              <td>&nbsp;</td>
              <td>Cycle Time</td>
              <td>On Time</td>
              <td>Cycle Time</td>
              <td>On Time</td>
              <td>Cycle Time</td>
              <td>On Time</td>
            </tr>
            <tr *ngIf="t && t.isMister && !t.isShade">
              <td>&nbsp;</td>
              <td>{{t.mistACycleMin}}</td>
              <td>{{t.mistAOnSec}}</td>
              <td>{{t.mistBCycleMin}}</td>
              <td>{{t.mistBOnSec}}</td>
              <td>{{t.mistIdleCycleMin}}</td>
              <td>{{t.mistIdleOnSec}}</td>
            </tr>
            <!-- <tr *ngIf="timerTypes && timerTypes[ti] && timerTypes[ti].isShade">
              <p *ngIf="t.slow_retract">The shade is configured to slow retract. It will step back by {{t.step_percent}}% for 3 pulses and pause for {{t.pause}} between pulses.</p>
              <p *ngIf="t.open_time">You have set the total open time for the shade is {{t.open_time}}.</p>
            </tr> -->
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>    
</div>