import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AlertController, NavController, Platform, ToastController } from '@ionic/angular';
import { SiteService } from 'src/app/services/site.service';
import { UserService } from 'src/app/services/user.service';
// import moment from 'moment';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-site-reports',
  templateUrl: './site-reports.component.html',
  styleUrls: ['./site-reports.component.scss'],
})
export class SiteReportsComponent implements OnInit {
  @Input() private site_id;
  private user;
  @Input() public site;
  public highLowReportForm;
  public highLowView = false;
  public isSiteAdmin = false;
  public timezones = new Set();
  public selectedTimezone;
  public automaticLogDownloadForm;
  public autoDownloadView = false;
  public error; 

  constructor(
    public navCtrl: NavController,
    private route: ActivatedRoute,
    public siteService: SiteService,
    public userService: UserService,
    private toastCtrl: ToastController,
    public formBuilder: UntypedFormBuilder,
    public alertController: AlertController,
    private router: Router
  ) {  }

  ngOnInit() {
    this.timezones.add(moment.tz.guess())
    moment.tz.zonesForCountry('US').concat(moment.tz.zonesForCountry('CA')).concat(moment.tz.names()).forEach((tz) => this.timezones.add(tz) )
    this.highLowReportForm = new UntypedFormGroup({
      runAt: new UntypedFormControl("8:00", [Validators.required]),
      sendTo: new UntypedFormArray([])
    });

    this.automaticLogDownloadForm = new UntypedFormGroup({
      runAt: new UntypedFormControl("8:00", [Validators.required]),
      controllers: new UntypedFormArray([]),
      sendTo: new UntypedFormArray([])
    });

    this.user = this.userService.isLoggedInAs() ? {is_bart_admin : false, email: this.userService.getLoggedInEmail()} : this.userService.getLoginData();
    if(this.site) {
      var current_user = this.site ? this.site.users.find((u) => { return u && this.user && u.email === this.user.email; }) : null;
      this.setUpHighLowForm(this.site)
      this.setUpAutoDownloadForm(this.site)
      this.selectedTimezone = this.site.reports ? this.site.reports.timeOffset : '';

      if(current_user) {        
        this.isSiteAdmin = current_user.owner || current_user.role >= 3 || current_user.is_bart_admin;
        console.log(`${current_user.email} - isSiteAdmin ${this.isSiteAdmin}`)
      }
    } else if(this.site_id) {
      this.setTz(moment.tz.guess())
      this.siteService.listSites(this.site_id).then((data) => { this.setSiteInfo(data) }).catch((error) => { this.setSiteError(error) });
    } else {
      this.route.params.subscribe(params => {
        if(params && params['site_id']) {
          this.site_id = params["site_id"];
          this.siteService.listSites(this.site_id).then((data) => { this.setSiteInfo(data) }).catch((error) => { this.setSiteError(error) });
        }
      });
    }
  }

  setUpHighLowForm(site) {
    let sendToArray = site && site.reports && site.reports.highLow && site.reports.highLow.sendTo || []
    let utcHourMin = "09:00";
    if(site.reports && site.reports.highLow && site.reports.highLow.runAt) {
      let runAtTime = moment.utc(site.reports && site.reports.highLow && site.reports.highLow.runAt, 'HH:mm a');
      utcHourMin = moment(runAtTime).local().format('HH:mm')
    }

    this.highLowReportForm = new UntypedFormGroup({
      runAt: new UntypedFormControl(utcHourMin, [Validators.required]),
      sendTo: new UntypedFormArray(site.users.map((u) => {
        return new UntypedFormControl(sendToArray.includes(u.email));
      }))
    });
  }

  setUpAutoDownloadForm(site) {
    let sendToArray = site && site.reports && site.reports.autoDownload && site.reports.autoDownload.sendTo || []
    let controllerArray = site && site.reports && site.reports.autoDownload && site.reports.autoDownload.controllers || []
    let utcHourMin = "09:00";
    if(site.reports && site.reports.autoDownload && site.reports.autoDownload.runAt) {
      let runAtTime = moment.utc(site.reports && site.reports.autoDownload && site.reports.autoDownload.runAt, 'HH:mm a');
      utcHourMin = moment(runAtTime).local().format('HH:mm')
    }

    this.automaticLogDownloadForm = new UntypedFormGroup({
      runAt: new UntypedFormControl(utcHourMin, [Validators.required]),
      frequency: new UntypedFormControl(site.reports.autoDownload.frequency, [Validators.required]),
      controllers: new UntypedFormArray(site.controllers.map((ctrl) => {
        return new UntypedFormControl(controllerArray.includes(ctrl._id));
      })),
      sendTo: new UntypedFormArray(site.users.map((u) => {
        return new UntypedFormControl(sendToArray.includes(u.email));
      }))
    });
  }

  viewSiteReport() {
    let navigationExtras: NavigationExtras = {
      queryParams: {isBartAdmin: (this.user ? this.user.is_bart_admin : false), site: this.site, user: this.user}
    };
    this.router.navigate([`site`, this.site_id, `usage`]);
  }

  updateAutomaticDownloadSettings(autoDownloadFormValue) {
    autoDownloadFormValue.users = autoDownloadFormValue.sendTo.map((sendToChecked, index) => {
      return sendToChecked ? this.site.users[index].email : undefined;
    }).filter((v) => v)

    autoDownloadFormValue.controllers = autoDownloadFormValue.controllers.map((controllerChecked, index) => {
      return controllerChecked && this.site && this.site.controllers && this.site.controllers[index] ? this.site.controllers[index]._id : undefined;
    }).filter((v) => v)

    let runAtTime = moment(autoDownloadFormValue.runAt, 'HH:mm a');
    let utcHourMin = moment.utc(runAtTime).format('HH:mm')
    autoDownloadFormValue.runAt = utcHourMin;
    this.editSite({reports: {autoDownload: autoDownloadFormValue, offset: this.selectedTimezone}, site_id: this.site.cis_id})        
  }

  updateReportSettings(highLowFormValue) {
    highLowFormValue.users = highLowFormValue.sendTo.map((sendToChecked, index) => {
      return sendToChecked ? this.site.users[index].email : undefined;
    }).filter((v) => v)

    let runAtTime = moment(highLowFormValue.runAt, 'HH:mm a');
    let utcHourMin = moment.utc(runAtTime).format('HH:mm')
    highLowFormValue.runAt = utcHourMin;
    this.editSite({reports: {highLow: highLowFormValue, offset: this.selectedTimezone}, site_id: this.site.cis_id})    
  }

  editSite(value) {
    if(!this.selectedTimezone) {
      this.showMessage("Please select timezone before saving report configuration")
      this.error = "Please select timezone before saving report configuration";
    } else {
      this.error = null;
      this.siteService.editSite(value)
            .then((data) => { this.editSiteSuccess(data) })
            .catch((error) => { this.editSiteError(error) })
    }
  }

  async showMessage(msg) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'top'
    });

    toast.present();
  }

  setTz(event) {
    console.log(event);
  }

  private setSiteInfo(data) {
    this.site = data.sites.find((retrieved) => this.site_id == retrieved.cis_id)
    var current_user = this.site ? this.site.users.find((u) => { return u && this.user && u.email === this.user.email; }) : null;
    this.selectedTimezone = this.site.reports ? this.site.reports.timeOffset : '';
    this.setUpHighLowForm(this.site)
    this.setUpAutoDownloadForm(this.site)
    if(current_user) {
      this.isSiteAdmin = current_user.owner || current_user.role >= 3 || current_user.is_bart_admin;
      console.log(`${current_user.email} - isSiteAdmin ${this.isSiteAdmin}`)
    }
  }

  private setSiteError(error) {
    this.showMessage("Sorry there was an error retrieving site information. We are redirecting you to your sites.");
    this.navCtrl.navigateForward('view-site');
  }

  private editSiteSuccess(response) {
    this.showMessage("Your report settings were updated");
  }

  private editSiteError(error) {
    this.showMessage("Your report settings could not be updated. Please try again later or contact support.");
  }
}
