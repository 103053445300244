import { Component, OnInit, ViewChild } from '@angular/core';
import { IonContent, NavController, NavParams, PopoverController } from '@ionic/angular';
import { SitesService } from 'src/app/services/sites.service';
import { SettingInfoType } from '../status/status.page';

@Component({
  selector: 'app-wind-rain-edit',
  templateUrl: './wind-rain-edit.page.html',
  styleUrls: ['./wind-rain-edit.page.scss'],
})
export class WindRainEditPage implements OnInit {
  @ViewChild('windRainEdit', { read: IonContent }) content: IonContent;
  @ViewChild('inputBox') inputBox;

  public settingInfo: SettingInfoType;
  public siteName: string;
  public houseName: string;
  public value;
  public current;
  public option: string;
  public comment: string;
  public error: string;
  public canEdit: false;
  public isPending;
  public additional_option_check;

  constructor(public navCtrl: NavController,
    private navParams: NavParams,
    public popoverController: PopoverController,
    public sitesApiProvider: SitesService)
  {
    this.settingInfo = navParams.get('settingInfo');
    this.siteName = navParams.get('siteName');
    this.houseName = navParams.get('houseName');
    this.canEdit = navParams.get('canEdit');
    this.isPending = navParams.get('isPending');
    if(this.settingInfo) {
      this.value = this.settingInfo.value;
      this.current = this.settingInfo.current || this.settingInfo.value;
    }

    if(this.settingInfo && this.settingInfo.additionalOption) {
      this.additional_option_check = parseInt(this.value) === parseInt(this.settingInfo.additionalOption.value);
    } else {
      this.additional_option_check = false;
    }
  }

  public getCurrentValue() {
    let current_val = this.settingInfo.current || this.settingInfo.value
    if(typeof current_val === "boolean") {
      return current_val ? "On" : "Off"
    } else {
      return current_val;
    }
  }

  public cancel(){
    this.popoverController.dismiss({
      button: "Cancel",
      value: null
    });
  }

  public scroll() {
    this.content.scrollToBottom();
  }

  public validateValue(pattern, value) {
    var regex = new RegExp(pattern)
    return regex.test(value)
  }

  public save(){
    this.error = null;

    var requiredPattern = this.settingInfo.pattern;
    if(requiredPattern && !this.validateValue(requiredPattern, this.value)) {
      this.error = this.settingInfo.error || "Sorry, your input did not match the required pattern.";
    } else {
      this.saveAndDismiss();
    }
  }

  public reformatAndValidate() {
    this.error = null;
    this.value = this.settingInfo.reformat ? this.settingInfo.reformat(this.value, this.settingInfo.pattern) : this.value
    if(this.settingInfo.valid && !this.settingInfo.valid(this.value)) {
      this.error = this.settingInfo.error || "The value you entered is invalid. Please correct this to continue."
    }
  }

  public saveAndDismiss() {
    this.popoverController.dismiss({
      button: "Save",
      value: {comment: this.comment, value: this.value}
    });
  }

  public timeToStr(minutes: number){
    var hourStr = Math.floor(minutes/60).toString();
    var minStr = "0" + (minutes%60).toString();
    minStr = minStr.substr(minStr.length-2);

    return hourStr.padStart(2, "0") + ":" + minStr.padStart(2, "0");
  }

  public additionalOptionChecked() {
    this.additional_option_check = parseInt(this.value) === parseInt(this.settingInfo.additionalOption.value);
    return this.additional_option_check;
  }

  ngOnInit() {
  }

  toggleAdditionalOption(event) {
    this.value = this.settingInfo.additionalOption.value;
    this.additional_option_check = !this.additional_option_check;
  }

  ngAfterViewChecked() {
    if(this.inputBox && !this.value) {
      this.inputBox.setFocus();
    }
  }
}
