import { Component, OnInit, Input, ViewChild, ElementRef, ViewChildren, QueryList,
  ViewEncapsulation, Output, EventEmitter, OnChanges, ChangeDetectorRef } from '@angular/core';
import { AlertController, NavController, NavParams, ToastController, PopoverController, Platform } from '@ionic/angular';
import { SiteService } from 'src/app/services/site.service';

import { ReportStyle } from './report-style'
import { StatisticsGraphGenerator } from '../controller-statistics/statistics-bar-chart-generator';

import * as d3 from 'd3-selection';
import * as d3Scale from 'd3-scale';
import * as d3Array from 'd3-array';
import * as d3Axis from 'd3-axis';
import * as d3Shape from 'd3-shape';

@Component({
  selector: 'app-site-usage-report',
  templateUrl: './site-usage-report.component.html',
  styleUrls: ['./site-usage-report.component.scss'],
})
export class SiteUsageReportComponent implements OnInit {
  @Input() showSection;
  @Input() siteId;
  @Input() adminPage;

  public sites = [];
  public selectedSite;
  public siteName;
  public site_usage = [];
  public graphsView = {}
  public barData = {}
  public hasGraphData = {}
  public charts_drawn = false;

  @ViewChildren('allSiteUsage') siteUsageList: QueryList<any>;

  constructor(
    private sitesServies: SiteService
  ) { }

  ngOnInit() {}
  ngOnChanges() {
    if(this.showSection) {
      if(this.siteId) {
        this.sitesServies.listSites(this.siteId).then((data) => { this.setSiteInformation(data) }).catch((error) => { this.setSiteError(error) });
        this.selectedSite = this.siteId;
        this.updateChanges();
      } else {
        this.sitesServies.listSites('ALL').then((data) => { this.setSiteInformation(data) }).catch((error) => { this.setSiteError(error) });
      }
    }
  }

  ngAfterViewInit() {
    this.siteUsageList.changes.subscribe(t => {
      this.ngForRendered();
    })
  }

  ngForRendered() {
    if(!this.charts_drawn && this.site_usage && this.site_usage.length > 0) {
      this.drawChartForAveragesForAll(`after checked`)
    }
  }

  updateChanges() {
    this.sitesServies.getUsageReport(this.selectedSite)
        .then((data) => {
          this.site_usage = data;
          data.forEach((controller, index) => {
            this.hasGraphData[index] = {zone_one: false, zone_two: false};
            console.log(`reset this.barData[index]`)
            this.barData[index] = this.barData[index] ? this.barData[index] : {zone_one: false, zone_two: false};
          });
        })
        .catch((error) => {
          console.log(error);
        })
    this.setSelectedName();
    this.charts_drawn = false;
  }

  setSelectedName() {
    if(this.sites) {
      let found_site = this.sites.find((site) => { return this.selectedSite === site.cis_id; })
      this.siteName = found_site ? found_site.name : 'Site Name N/A'
    }
  }

  getNodes() {
    return this.site_usage || [];
  }

  drawChartForAveragesForAll(from = '') {
    console.log(`from: ${from}`)
    this.site_usage.forEach((controller, index) => {
      this.drawChartForAverages(controller, index);
    });
    this.charts_drawn = true;
  }

  drawChartForAverages(controller, index) {
    this.barDataByDays(controller, index);

    const drawZoneChart = (zoneId, data, zone) => {
      var {svg, g} = StatisticsGraphGenerator.init(zoneId, '75%', '100%');
      var {x, y, x1, min_max, xDayScale} = StatisticsGraphGenerator.initAxes(data, svg, g);
      StatisticsGraphGenerator.drawAxes(g, x, y, zone);
      StatisticsGraphGenerator.drawLegend(g);
      StatisticsGraphGenerator.drawChart(data, g, x, x1, y, min_max, xDayScale);
    }

    if(!this.hasGraphData[index]) this.hasGraphData[index] = {zone_one: false, zone_two: false};
    this.hasGraphData[index].zone_one = this.barData[index].zone_one ? this.barData[index].zone_one.length > 0 : false;
    if(this.barData[index].zone_one) {
      drawZoneChart(`#zoneOneChart${index}`, this.barData[index].zone_one, "Zone 1");
    }

    this.hasGraphData[index].zone_two = this.barData[index].zone_two ? this.barData[index].zone_two.length > 0 : false;
    if(this.barData[index].zone_two) {
      drawZoneChart(`#zoneTwoChart${index}`, this.barData[index].zone_two, "Zone 2");
    }
  }

  barDataByDays(controller, index) {
    // this.groupBy = "days";
    if(controller && controller.avg_temps) {
      this.barData[index].zone_one = controller.avg_temps.reduceRight((arr, avg) => {
        if(avg && avg.zone_one) {
          const zone_one_info = avg.zone_one;
          const graph_info = {
            day_label: avg.date.label,
            average: zone_one_info.twentyfour_hr,
            nite: zone_one_info.nite,
            day: zone_one_info.day,
            dif: zone_one_info.dif
          }
          arr.push(graph_info)
        }

        return arr;
      }, []);

      this.barData[index].zone_two = controller.avg_temps.reduceRight((arr, avg) => {
        if(avg && avg.zone_two) {
          const zone_info = avg.zone_two;
          const graph_info = {
            day_label: avg.date.label,
            average: zone_info.twentyfour_hr,
            nite: zone_info.nite,
            day: zone_info.day,
            dif: zone_info.dif
          }
          arr.push(graph_info)
        }

        return arr;
      }, []);
    }
  }

  print() {
    var printwin = window.open("", '_system', 'location=yes');
    printwin.document.write(`<html><head><title>Site Usage Report: ${this.siteName}</title><style>${ReportStyle.get()}</style></head><body><div id="site-usage-report">`);
    printwin.document.write(document.getElementById("site-usage-report").innerHTML);
    printwin.document.write('</div></body></html>');
    printwin.print();
  }

  private setSiteInformation(data) {
    this.sites = data.sites ? data.sites : data;
    this.setSelectedName();
  }
  private setSiteError(error) { this.sites = []; }
}
