<div id="controller-statistics-container">
  <div class="title">
    <h6>Statistics</h6>
    <div class="toggles">
      <label>See Graph</label>
      <ion-toggle name="status" [(ngModel)]="showStatisticsGraph">
      </ion-toggle>
      <label>See Raw Data</label>
      <ion-toggle name="status" [(ngModel)]="showStatisticsData">
      </ion-toggle>
    </div>
  </div>

  <div class="change-groups" [class.display-none]="!showStatisticsGraph || (!hasGraphData.zone_one && !hasGraphData.zone_two)">
    <a (click)="drawChartGroupByDays()" [class.bold]="groupBy === 'days'">Show grouped by days</a>
    <a (click)="drawChartGroupByStages()" [class.bold]="groupBy === 'stage'">Show grouped by stages</a>
  </div>

  <div id="zoneOneChart" [class.display-none]="!showStatisticsGraph || !this.hasGraphData.zone_one"></div>
  <div id="zoneTwoChart" [class.display-none]="!showStatisticsGraph || !this.hasGraphData.zone_two"></div>
  <div class="no-chart" *ngIf="!barData.zone_one || !this.hasGraphData.zone_one">
    There is no data for Zone 1 at this time.
  </div>
  <div class="no-chart" *ngIf="!barData.zone_two || !this.hasGraphData.zone_two">
    There is no data for Zone 2 at this time.
  </div>

  <div class="change-groups" [class.display-none]="!showStatisticsData || (!hasGraphData.zone_one && !hasGraphData.zone_two)">
      <a (click)="barDataByStages()" [class.bold]="groupBy === 'stage'">Show grouped by stages</a>
      <a (click)="barDataByDays()" [class.bold]="groupBy === 'days'">Show grouped by days</a>
  </div>
  <table *ngIf="showStatisticsData && (hasGraphData.zone_one || hasGraphData.zone_two)">
    <tbody *ngIf="groupBy === 'stage'">
      <tr class="subheader-title" *ngIf="hasGraphData.zone_one">
        <td>Zone 1</td>
      </tr>
      <tr class="subheader" *ngIf="hasGraphData.zone_one">
        <th>&nbsp;</th>
        <th>SUN</th>
        <th>MON</th>
        <th>TUES</th>
        <th>WED</th>
        <th>THUR</th>
        <th>FRI</th>
        <th>SAT</th>
      </tr>
      <tr *ngFor="let i of barData.zone_one">
        <td>{{i.label}}</td>
        <td>{{i.SUN}}</td>
        <td>{{i.MON}}</td>
        <td>{{i.TUES}}</td>
        <td>{{i.WED}}</td>
        <td>{{i.THUR}}</td>
        <td>{{i.FRI}}</td>
        <td>{{i.SAT}}</td>
      </tr>

      <tr class="subheader-title" *ngIf="hasGraphData.zone_two">
        <td>Zone 2</td>
      </tr>
      <tr class="subheader" *ngIf="hasGraphData.zone_two">
        <th>&nbsp;</th>
        <th>SUN</th>
        <th>MON</th>
        <th>TUES</th>
        <th>WED</th>
        <th>THUR</th>
        <th>FRI</th>
        <th>SAT</th>
      </tr>
      <tr *ngFor="let i of barData.zone_two">
        <td>{{i.label}}</td>
        <td>{{i.SUN}}</td>
        <td>{{i.MON}}</td>
        <td>{{i.TUES}}</td>
        <td>{{i.WED}}</td>
        <td>{{i.THUR}}</td>
        <td>{{i.FRI}}</td>
        <td>{{i.SAT}}</td>
      </tr>
    </tbody>

    <tbody *ngIf="groupBy === 'days' && hasGraphData.zone_one">
      <tr class="subheader-title" *ngIf="hasGraphData.zone_one">
        <td>Zone 1</td>
      </tr>
      <tr class="subheader"  *ngIf="hasGraphData.zone_one">
        <th>&nbsp;</th>
        <th>DAY</th>
        <th>DIF</th>
        <th>NITE</th>
        <th>24 Hour</th>
      </tr>
      <tr *ngFor="let i of barData.zone_one">
        <td>{{i.day_label}}</td>
        <td>{{i.day}}</td>
        <td>{{i.dif}}</td>
        <td>{{i.nite}}</td>
        <td>{{i.average}}</td>
      </tr>

      <tr class="subheader-title" *ngIf="hasGraphData.zone_two">
        <td>Zone 2</td>
      </tr>
      <tr class="subheader"  *ngIf="hasGraphData.zone_two">
        <th>&nbsp;</th>
        <th>DAY</th>
        <th>DIF</th>
        <th>NITE</th>
        <th>24 Hour</th>
      </tr>
      <tr *ngFor="let i of barData.zone_two">
        <td>{{i.day_label}}</td>
        <td>{{i.day}}</td>
        <td>{{i.dif}}</td>
        <td>{{i.nite}}</td>
        <td>{{i.average}}</td>
      </tr>
    </tbody>
  </table>

  <app-run-times [runTimes]="runTimes"></app-run-times>
</div>
