<div class="filter-bys">
  <ion-item class="width-100">
    <ion-label>Filter by Date:</ion-label>
    <ionic-selectable
      *ngIf="dateOptions"
      item-content
      [(ngModel)]="searchDate"
      [items]="dateOptions"
      [canSearch]="true"
      (onChange)="filterItems(searchDate)"
      placeholder="View All"
    >
    </ionic-selectable>
  </ion-item>
  <ion-item class="width-100">
    <ion-label>Filter by Key:</ion-label>
    <ionic-selectable
      *ngIf="settingKeys"
      item-content
      [(ngModel)]="searchSettingKey"
      [items]="settingKeys"
      [canSearch]="true"
      (onChange)="filterItemsByKey(searchSettingKey)"
      placeholder="View All"
    >
    </ionic-selectable>
  </ion-item>
</div>
<div *ngFor="let change of changes()" class="notifications" [class.failed]="change.result && !change.result.success">
  <p (click)="change.showing = !change.showing" class="change" [class.failed]="change.result && !change.result.success">
    <span><label>{{change.key}}</label> by {{change.email}}</span>
    <ion-icon name="arrow-down-outline" color="dark-gray"></ion-icon>
  </p>
  <p class="showing" *ngIf="change.showing"><label>Updated value:</label> {{change.value}}</p>
  <p class="showing" *ngIf="change.showing"><label>When:</label> {{change.date}}</p>
  <p class="showing" *ngIf="change.showing"><label>Comment:</label> {{change.comment ? change.comment : 'N/A'}}</p>
  <p *ngIf="(change && !change.result.success) && change.showing"
    class="ion-text-wrap showing">
    Change attempted but did not succeed. Details: {{change.result.message}}
  </p>
</div>
<div class="audit-no-changes" *ngIf="!changes() || changes().length === 0">
  <p *ngIf="searchSettingKey || searchDate">No changes for this filter.</p>
  <p *ngIf="!searchSettingKey && !searchDate">No changes found for this controller.</p>
</div>
