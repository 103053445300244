import * as d3 from 'd3';
import { select, selectAll } from "d3-selection";
import moment from 'moment';

var svg;
var margin = {top: 20, right: 50, bottom: 20, left: 100};
var width;
var height;
var data;
var line_g;

const init = (chartId, width, height) => {
  svg = select(chartId)
    .append('svg')
    .attr('height', height)
    .attr('width', width)
    .attr('id', chartId)
    .attr("viewBox", `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
    
  const g = svg.append('g')
    .attr('width', width)
    .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
    
  line_g = g
    .append("svg:clipPath")
      .attr("id", "lines")
      .append("svg:rect")
        .attr("id", "line-rect")
        .attr("x", 0)
        .attr("y", '0')
        .attr("width", width)
        .attr("height", height)
        .attr('fill', `transparent`)

  return {svg: svg, g: g}
}

const initAxes = (svg, groups, dates) => {
    let subgroups = dates; //Array of the days
    let width = svg.style("width").replace("px", "")
    let height = svg.style("height").replace("px", "")

    // Add X axis
    var x = d3.scaleBand()
              .domain(groups)
              .range([0, width])
              .padding([0.2])
    
    svg.append("g")
       .attr("transform", "translate(0," + height + ")")
       .call(d3.axisBottom(x).tickSize(0));

    // Add Y axis
    var y = d3.scaleLinear()
              .domain([0, 86400000])
              .range([ height, 0 ]);
    
    svg.append("g")
       .call(d3.axisLeft(y).ticks(15));

    var xSubgroup = d3.scaleBand()
       .domain(subgroups)
       .range([0, x.bandwidth()])
       .padding([0.05])

    return {x, y, xSubgroup}
}

const drawBars = (svg, xSubgroup, x, y, subgroups, data) => {
  try {
    let height = svg.style("height").replace("px", "")
    // color palette = one color per subgroup
    var stackedData = d3.stack().keys(['t1', 't2'])(data)
    console.log(stackedData);
    var color = d3.scaleOrdinal()
      .domain(subgroups)
      .range(d3.schemeSpectral[subgroups.length])
    console.log(`subgroups - domain: ${xSubgroup.domain()}, range: ${xSubgroup.range()}`)
    console.log(`X - domain: ${x.domain()}, range: ${x.range()}`)

    var div = d3.select("body").append("div")
     .attr("class", "tooltip-graph")
     .style("opacity", 0)
     .style("padding", "1em")
     .style("background-color", 'white')
     .style('position', 'fixed');

    svg.append("g")
      .selectAll("g")
      // Enter in the stack data = loop key per key = group per group
      .data(stackedData)
      .enter().append("g")
              .attr("fill", function(d) { return color(d.key); })
              .selectAll("rect")
              // enter a second time = loop subgroup per subgroup to add all rectangles
                .data(function(d) { 
                  return subgroups.map(function(key) { 
                    let valueArray = d.filter((ind) => {
                      return ind.data.date === key
                    })
                    return {key: key, 
                      value: valueArray, 
                      group: (valueArray[0].data && valueArray[0].data.group),
                      data: valueArray[0].data
                    }; 
                  }); 
                })
                .enter().append("rect")
                    .attr("x", function(d) { 
                      // console.log(`${d.group} - ${d.group ? x(d.group): 'n/a'}, ${d.key} - ${xSubgroup(d.key)}`)
                      return x(d.group) + xSubgroup(d.key);
                    })
                    .attr("y", function(d) { 
                      return y(d.value[0][1]); 
                    })
                    .attr("height", function(d) { 
                      return y(d.value[0][0]) - y(d.value[0][1]); 
                    })
                    .attr("width", xSubgroup.bandwidth())
                    .on('mouseover', function (e, d, i) {
                      d3.select(this).transition()
                        .duration('50')
                        .attr('opacity', '.85');
                      div.transition()
                        .duration(50)
                        .style("opacity", 1);
                      let date_timer_info = `${moment(d.key).format('L')} - T1: ${d.data.t1 ? moment.duration(d.data.t1).humanize() : '0'}, T2: ${d.data.t2 ? moment.duration(d.data.t2).humanize() : '0'}`;
                      div.html(date_timer_info)
                        .style("left", (e.pageX + 10) + "px")
                        .style("top", (e.pageY - 15) + "px");
                    })
                    .on('mouseout', function (e, d, i) {
                      d3.select(this).transition()
                        .duration('50')
                        .attr('opacity', '1');
                    })
  } catch(e) {
    console.log(e);
  }
}

export const TimerGraphChartGenerator = {
  chartData:(chartId, width, height) => {
    selectAll("svg").remove();
    const drawChart = (groups, subgroups, data) => {
      var {svg, g} = init(chartId, width, height);
      let {x, y, xSubgroup} = initAxes(svg, groups, subgroups)
      console.log(subgroups);
      console.log(data);
      drawBars(svg, xSubgroup, x, y, subgroups, data)
    }

    return {
      draw: (groups, subgroups, data) => {
        drawChart(groups, subgroups, data);
      }
    }
  }
}
