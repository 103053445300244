import { Component, OnInit, ViewChild } from '@angular/core';
import { NavController, NavParams, PopoverController, ToastController, IonContent } from '@ionic/angular';

@Component({
  selector: 'app-device-select',
  templateUrl: './device-select.page.html',
  styleUrls: ['./device-select.page.scss'],
})
export class DeviceSelectPage implements OnInit {
  public devices: any[];
  public selected_devices: any[];

  constructor(
    public popoverController: PopoverController,
    private navParams: NavParams,
    private toastCtrl: ToastController
  ) {
    this.devices = navParams.get('devices');
  }

  ngOnInit() {}
  selectDevice(device) {
    this.showMessage(`Selected ${device.api_name || device.node_name}`);
    device.api_name = device.node_name;
    this.popoverController.dismiss({
      button: "Cancel",
      value: device
    });
  }

  public cancel(){
    this.popoverController.dismiss({
      button: "Cancel",
      value: null
    });
  }

  async showMessage(msg) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'top'
    });

    toast.present();
  }
}
