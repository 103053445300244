var ringtoneOptions = [
  {"Name": "Siren", 'Url':'siren1.wav', default: true, filename: 'siren1'},
  {"Name": "Irish Rock", 'Url':'irish_rock.wav', filename: 'irish_rock'},
  {"Name": "Alarm 1", 'Url':'loud_alarm_clock.wav', filename: 'loud_alarm_clock'},
  {"Name": "Alarm 2", 'Url':'alarm_alarm_alarm.wav', filename: 'alarm_alarm_alarm'},
  {"Name": "Car Alarm", 'Url':'alarm_car.wav', filename: 'alarm_car'},
  {"Name": "Bells Keyboard", 'Url':'midnight_greenhouse.wav', filename: 'midnight_greenhouse'},
  {"Name": "Droid", 'Url':'wake_up_alarm.wav', filename: 'wake_up_alarm'},
  {"Name": "System Default", 'Url':'', systemDefault: true, filename: 'default'}
]

export const NotificationOptions = {
  getAll: () => {
    return ringtoneOptions;
  }
}
