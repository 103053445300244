export class VentSetting {
  man: string;
  mode: string;
  st: string; p1: string; p2: string; p3: string; p4: string; p5: string;
  threshold: number; openTime: number; rainOR: string; wind1OR: string;
  wind2OR: string;
  original_index?: number;
}

export class TimerSetting {
  startTime: string;
  holdTime: string;
  mistDifOnSec: string;
  mistDifCycleMin: string;
  mistDayOnSec: string;
  mistDayCycleMin: string;
  mistNightOnSec: string;
  mistNightCycleMin: string;
  mode: string; repeat: string
}

export class TimeBossTimerSetting {
  abort: boolean;
  enable: boolean;
  holdTimeA: string;
  holdTimeB: string;
  isMister: boolean;
  manualActive: boolean;
  startTimeA: string;
  startTimeB: string;
  timeRemaining: string;
  pending?: boolean;
  on?: string;
  mister_pending?: boolean;
  isShade?: boolean;
  pendingSettings?: {};
}

export class TimeBossMisterSetting {
  lightSP: number;
  mistACycleMin: number;
  mistAOnSec: number;
  mistBCycleMin: number;
  mistBOnSec: number;
  mistIdleCycleMin: number;
  mistIdleOnSec: number;
  pending?: boolean;
  on?: string;
  mister_pending?: boolean;
  pendingSettings?: {};
}

export class Node {
    _id?: string;
    node_id: string;
    api_name?: string;
    node_name: string;
    site_name: string;
    site_id?: string;
    type: string;
    model: string;
    isRWL: boolean; //RWL controller flag, determined by model string
    hasH3: boolean; //heat 3 option, determined by model string
    hasPo1: boolean; //zone 1 is proportional output (curtain/wall), determined by model string
    hasPo2: boolean; //zone 2 is proportional output (curtain/wall), determined by model string
    isAdmin: boolean; //has admin role, determined by login in user role
    canEdit: boolean; //has edit role, determined by login in user role
    fw: string;
    in_use: boolean;
    created: string;
    updated: string;
    updatedAt: string;
    online: boolean;
    direct_reporting: boolean;
    cis_reporting: boolean;
    reqs: [{node_id: string, key: string}]
    status: {
        alarms?: [string];
        time: string;
        stage: string;
        alarm: string;
        zones;
        ct1: number;
        ct2: number;
        hum: number;
        h1: string;
        h2: string;
        h3: string;
        c1: string;
        c2: string;
        c3: string;
        c4: string;
        percent1: number;
        percent2: number;
        min1: number;
        max1: number;
        min2: number;
        max2: number;
        timer: string;
        timer2?: string;
        ct3?: number;
        timerMode: string;
        override: {
          activation: boolean;
          cancel: boolean;
        };
        activated: boolean;
        wind: {
          alarm: boolean
          current: string;
          outsideTemp: string;
          direction?: string;
        };
        rain: {
          alarm: boolean
        };
        dhPreheat: boolean;
        dhExhaust: boolean;
        humidity: string;
        outsideTemp: string;
        direction?: string;
        light?: number;
        dli?: number;
        spartan1?: string;
        spartan2?: string;
        spartan3?: string;
        spartan4?: string;
        spartan5?: string;
        spartan6?: string;
        spartan_connection?: string;
    };
    settings: {
        hc_delay?: number;
        on2auto?: string;
        therm_fail?: string;
        alarm1l?: number;
        alarm1h?: number;
        sp1?: number;
        alarm2l?: number;
        alarm2h?: number;
        sp2?: number;
        sp3?: number;
        humsp?: number;
        h1m?: string;
        h1t?: number;
        h1text?: string;
        h2m?: string;
        h2t?: number;
        h2text?: string;
        h3m?: string;
        h3text?: string;
        c1m?: string;
        c1t?: number;
        c1text?: string;
        c2m?: string;
        c2t?: number;
        c2text?: string;
        c3m?: string;
        c3t?: number;
        c3text?: string;
        c4m?: string;
        c4t?: number;
        c4text?: string;
        difst?: string;
        dayst?: string;
        nitst?: string;
        difsp1?: number;
        daysp1?: number;
        nitsp1?: number;
        difsp2?: number;
        daysp2?: number;
        nitsp2?: number;
        v1mode?: string;
        v1man?: number;
        v1st?: number;
        v1p1?: number;
        v1p2?: number;
        v1p3?: number;
        v1p4?: number;
        v1p5?: number;
        v2mode?: string;
        v2man?: number;
        v2st?: number;
        v2p1?: number;
        v2p2?: number;
        v2p3?: number;
        v2p4?: number;
        v2p5?: number;
        english?: boolean;
        timerStart?: number;
        timerStartTime?: string;
        timerHoldTime?: string;
        timerRepeat?:string;
        mistDifOnSec?:number;
        mistDifCycleMin?:number;
        mistDayOnSec?:number;
        mistDayCycleMin?:number;
        mistNightOnSec?:number;
        mistNightCycleMin?:number;
        dhVentMode?:string;
        dhStartTime?:string;
        dhRepeat?:string;
        dhFan1?:number;
        dhFan2?:number;
        dhHeat?:number;
        dhSp?:number;
        dhDif?:string;
        dhDay?:string;
        dhNight?:string;
        dhSetup?:number;
        dhLoTemp?:number;
        dhHiTemp?:number;
        wind?: {
          activation: {
            setPoint: number;
            delay: number;
            loSetPoint: number;
          },
          dropOff: {
            setPoint: number;
            delay: number;
          },
          directionMask?: number;
          directions?: {
            nw: string, n: string, ne: string, s: string, se: string, sw: string, e: string, w: string
          }
        },
        wind2?: {
          activation: {
            setPoint: number;
            delay: number;
            loSetPoint: number;
          },
          dropOff: {
            setPoint: number;
            delay: number;
          },
          directionMask?: number;
          directions?: {
            nw: string, n: string, ne: string, s: string, se: string, sw: string, e: string, w: string
          }
        },
        heatCool?: {
          zone1: {
            on: boolean;
            heating: boolean;
          },
          zone2: {
            on: boolean;
            heating: boolean;
          },
          heat3: {
            on: boolean;
          }
          vent1ActivationTemperature: string;
          vent2ActivationTemperature: string;
        },
        vent?: VentSetting[];
        timer?: TimerSetting[];
        timers? : TimerSetting[];
        group1Timer?: TimeBossTimerSetting[];
        group1Mister?: TimeBossMisterSetting[];
        shadeEnable?: boolean;
        shadeOpenTime?: number;
        shadePause?: number;
        shadeSlowRetract?: boolean;
        shadeStep?: number;
        shadeTempSP?: string;
        lightSource?: string;
        isShade?: boolean;
        alarm1h_float?: string;
        alarm1l_float?: string;
        alarm2l_float?: string;
        alarm2h_float?: string;
        alarm1l_float_delay?: string;
        alarm2l_float_delay?: string;
        alarm3h?: string;
        alarm3l?: string;
    };
    heatCool?: {
      zone1: {
        on: boolean;
        heating: boolean;
        cooling: boolean;
        vent1Activation: number;
        vent2Activation: number;
      },
      zone2: {
        on: boolean;
        heating: boolean;
        cooling: boolean;
        vent2Activation: number;
      },
      heat3: {
        on: boolean;
      }
    };
    users?: [{
        email: string,
        role: number,
        alerts: {
            push: boolean
        }
    }]
    isWindRain?: boolean;
    output_overrides?: {
      v1?: string;
      v2?: string;
      v3?: string;
      v4?: string;
      c1?: string;
      c2?: string;
      c3?: string;
      c4?: string;
      h1?: string;
      h2?: string;
      h3?: string;
      t1?: string;
      t2?: string;
      t3?: string;
      t4?: string;
      t5?: string;
      t6?: string;
      t7?: string;
      t8?: string;
      t9?: string;
      t10?: string;
    }
}

export class NodeListItem {
    _id: string;
    node_id: string;
    node_name: string;
    api_name: string;
    site_name: string;
    type: string;
    model: string;
    in_use: boolean;
    reqs?: [];
    site_id?: string;
    status: {
      alarms?: [string];
      alarm: string;
      zones: number;
      ct1: number;
      ct2: number;
      ct3: number;
      h3: string;
      heatCool: {
        zone1: {
          on: boolean;
          heating: boolean;
          cooling: boolean;
        },
        zone2: {
          on: boolean;
          heating: boolean;
          cooling: boolean;
        }
      };
      wind: {};
      rain: {};
      timer: string;
      timer2?: string;      
    };
    settings: {
      wind: {};
      wind2: {};
      rain: {};
    }
    updated: string;
    online: boolean;
    admin: boolean;
    class: string;
    isRWL: boolean; //RWL controller flag, determined by model string
    hasH3: boolean; //heat 3 option, determined by model string
    hasPo1: boolean; //zone 1 is proportional output (curtain/wall), determined by model string
    hasPo2: boolean; //zone 2 is proportional output (curtain/wall), determined by model string
    isWindRain: boolean;
    users: [];
    setPt1?: number;
    setPt2?: number;
    setPt3?: number;
    canEdit: boolean;
}
