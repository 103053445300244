<div *ngIf="notifications">
  <section>
    <div>
      <label>Enable All</label>
      <p>Get all notifications for this device.</p>
    </div>
    <ion-toggle name="all" [(ngModel)]="notifications.push" [checked]="checkForAll()" (ionChange)="toggleAll($event)" *ngIf="!call_test_group"></ion-toggle>
    <select [(ngModel)]="notifications.push" *ngIf="call_test_group">
      <option value="false">None</option>
      <option value="push">Push Notifications</option>
      <option value="call">Phone Call</option>
      <option value="text">Text Message</option>
    </select>
  </section>

  <section>
    <div>
      <label>Online/Offline Alert</label>
      <p>Get notifications if the device goes offline or comes back online.</p>
    </div>
    <ion-toggle name="status" [(ngModel)]="notifications.status" *ngIf="!call_test_group" [checked]="notifications.status" (ionChange)="toggleOne($event)"></ion-toggle>
    <div><i>This notification type will use your default alert type. Configure this in General Settings.</i></div>
  </section>

  <section *ngIf="!is_time_boss">
    <div>
      <label>Temperature Alarms</label>
      <p>Get notifications if an alarm sounds for the device.</p>
    </div>
    <ion-toggle name="alarms" [checked]="notifications.alarms" *ngIf="!call_test_group" (ionChange)="toggleOne($event)"></ion-toggle>
    <select [(ngModel)]="notifications.alarms" *ngIf="call_test_group">
      <option value="false">None</option>
      <option value="push">Push Notifications</option>
      <option value="call">Phone Call</option>
      <option value="text">Text Message</option>
    </select>
  </section>

  <section *ngIf="device && device.wind_rain">
    <div>
      <label>Wind Alarm</label>
      <p>Get notifications if an alarm sounds for wind settings.</p>
    </div>
    <ion-toggle name="wind" [checked]="notifications.wind" *ngIf="!call_test_group" (ionChange)="toggleOne($event)"></ion-toggle>
    <select [(ngModel)]="notifications.wind" *ngIf="call_test_group">
      <option value="false">None</option>
      <option value="push">Push Notifications</option>
      <option value="call">Phone Call</option>
      <option value="text">Text Message</option>
    </select>
  </section>

  <section *ngIf="device && device.wind_rain">
    <div>
      <label>Rain Alarm</label>
      <p>Get notifications if an alarm sounds for rain settings.</p>
    </div>
    <ion-toggle name="rain" [checked]="notifications.rain" *ngIf="!call_test_group" (ionChange)="toggleOne($event)"></ion-toggle>
    <select [(ngModel)]="notifications.rain" *ngIf="call_test_group">
      <option value="false">None</option>
      <option value="push">Push Notifications</option>
      <option value="call">Phone Call</option>
      <option value="text">Text Message</option>
    </select>
  </section>

  <section>
    <div>
      <label>Setting Change Failed</label>
      <p>Get notifications if the controller fails to make a setting change.</p>
    </div>
    <ion-toggle name="settingsFailed" [checked]="notifications.settingsFailed" *ngIf="!call_test_group" (ionChange)="toggleOne($event)"></ion-toggle>
    <select [(ngModel)]="notifications.settingsFailed" *ngIf="call_test_group">
      <option value="false">None</option>
      <option value="push">Push Notifications</option>
      <option value="call">Phone Call</option>
      <option value="text">Text Message</option>
    </select>
  </section>

  <section>
    <div>
      <label>VFD Link Communication Lost</label>
      <p>Get notifications if the VFD Link communication has been lost - applies only if the ClimateBoss is connected to a VFD Link.</p>
    </div>
    <ion-toggle name="vfdLinkLost" [checked]="notifications.vfdLinkLost" *ngIf="!call_test_group" (ionChange)="toggleOne($event)"></ion-toggle>
    <select [(ngModel)]="notifications.vfdLinkLost" *ngIf="call_test_group">
      <option value="false">None</option>
      <option value="push">Push Notifications</option>
      <option value="call">Phone Call</option>
      <option value="text">Text Message</option>
    </select>
  </section>

  <section *ngIf="device && device.admin">
    <div>
      <label>Setting Changed</label>
      <p><b>Admin Only</b> Get notifications if any setting is changed for the controller by a non-admin user.</p>
    </div>
    <ion-toggle name="settings" [checked]="notifications.settings" *ngIf="!call_test_group" (ionChange)="toggleOne($event)"></ion-toggle>
    <select [(ngModel)]="notifications.settings" *ngIf="call_test_group">
      <option value="false">None</option>
      <option value="push">Push Notifications</option>
      <option value="call">Phone Call</option>
      <option value="text">Text Message</option>
    </select>
  </section>

  <section class="submit-container">
    <ion-button expand="full" class="app-button" (click)="saveAlertSettings()">
      Save
    </ion-button>
  </section>
</div>
