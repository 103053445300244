import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { AlertController, NavController, PopoverController, ToastController } from '@ionic/angular';
import { SitesService } from 'src/app/services/sites.service';
import { TimerService } from 'src/app/services/timer.service';
import { UserService } from 'src/app/services/user.service';
import { Node, NodeListItem } from '../model-defs/node-def';

import { NavigationExtras } from '@angular/router';
import { Storage } from '@ionic/storage';
import { EditHeatCoolSettingPage } from '../edit-heat-cool-setting/edit-heat-cool-setting.page';
import { EditSettingPage } from '../edit-setting/edit-setting.page';
import { EditVentSettingPage } from '../edit-vent-setting/edit-vent-setting.page';
import { SettingInfo } from '../status-wind-rain/setting-info';
import { TimerMisterSettings } from '../status/getTimerMisterSettings';
import { WindRainEditPage } from '../wind-rain-edit/wind-rain-edit.page';
import { ProgrammingToolsPage } from '../programming-tools/programming-tools.page';

@Component({
  selector: 'app-controller-status',
  templateUrl: './controller-status.component.html',
  styleUrls: ['./controller-status.component.scss'],
})
export class ControllerStatusComponent implements OnInit, OnDestroy, OnChanges {
  public nodeData = new Node;
  public initNodeData = new NodeListItem;
  public enableAlerts = false;
  public house_name = '';
  public showInfo = false;
  public showStatus = true;
  public showSettings = false;
  public isAdmin = false;
  public canEdit = false;
  public isCelsius = false;
  public tUnits = '˚F';
  private timerMisterSettings;
  public onError = false;
  public showHeadgrowerOnlyAlarmSettings = false;
  public settings: SettingInfoType[] = [
    {key: "sp1", value: "", type: "temp", pending: false, desc: "Zone 1 Set Point Override"},
    {key: "alarm1l", value: "", type: "temp", pending: false, desc: "Zone 1 Low Temperature Alarm Setting"},
    {key: "alarm1h", value: "", type: "temp", pending: false, desc: "Zone 1 High Temperature Alarm Setting"},
    {key: "alarm1l_float", value: "", type: "step", pending: false, desc: "Zone 1 Low Temperature Floating Alarm Offset", disable_allowed: true},
    {key: "alarm1h_float", value: "", type: "step", pending: false, desc: "Zone 1 High Temperature Floating Alarm Offset", disable_allowed: true},
    {key: "alarm1l_float_delay", value: "", type: "time", pending: false, desc: "Zone 1 Low Temperature Floating Alarm Delay (mm:ss)"},
    {key: "sp2", value: "", type: "temp", pending: false, desc: "Zone 2 Set Point Override"},
    {key: "alarm2l", value: "", type: "temp", pending: false, desc: "Zone 2 Low Temperature Alarm Setting"},
    {key: "alarm2h", value: "", type: "temp", pending: false, desc: "Zone 2 High Temperature Alarm Setting"},
    {key: "alarm2l_float", value: "", type: "step", pending: false, desc: "Zone 2 Low Temperature Floating Alarm Offset", disable_allowed: true},
    {key: "alarm2h_float", value: "", type: "step", pending: false, desc: "Zone 2 High Temperature Floating Alarm Offset", disable_allowed: true},
    {key: "alarm2l_float_delay", value: "", type: "time", pending: false, desc: "Zone 2 Low Temperature Floating Alarm Delay"},
    {key: "alarm3l", value: "", type: "temp", pending: false, desc: "Heat 3 Low Temperature Alarm Setting"},
    {key: "alarm3h", value: "", type: "temp", pending: false, desc: "Heat 3 High Temperature Alarm Setting"},
    {key: "sp3", value: "", type: "temp", pending: false, desc: "Heat 3 Set Point"},
    {key: "humsp", value: "", type: "percent", pending: false, desc: "Humidity Set Point"},
    {key: "h1m", value: "", type: "heat", pending: false, desc: "Heat 1"},
    {key: "h2m", value: "", type: "heat", pending: false, desc: "Heat 2"},
    {key: "c1m", value: "", type: "cool", pending: false, desc: "Cool 1"},
    {key: "c2m", value: "", type: "cool", pending: false, desc: "Cool 2"},
    {key: "c3m", value: "", type: "cool", pending: false, desc: "Cool 3"},
    {key: "c4m", value: "", type: "cool", pending: false, desc: "Cool 4"},
    {key: "vent1", value: "", type: "vent", pending: false, desc: (this.nodeData && this.nodeData.model && this.nodeData.model.startsWith('CB') ? "Vent A Settings" : "Vent 1 Settings")},
    {key: "vent2", value: "", type: "vent", pending: false, desc: (this.nodeData && this.nodeData.model && this.nodeData.model.startsWith('CB') ? "Vent B Settings" : "Vent 2 Settings")},
    {key: "vent3", value: "", type: "vent", pending: false, desc: "Vent C Settings"},
    {key: "vent4", value: "", type: "vent", pending: false, desc: "Vent D Settings"},
    {key: "prog1", value: "", type: "prog", pending: false, desc: "Zone 1 Program Settings"},
    {key: "prog2", value: "", type: "prog", pending: false, desc: "Zone 2 Program Settings"},
    {key: "rem", value: "", type: "misc", pending: false, desc: ""},
    {key: "tbTimer2", pending: false},
    {key: "tbMister2", pending: false}
  ]

  @Input() inNodeData: any;
  public nodeLoaded = false;

  public zone1HasAlarm = false;
  public zone2HasAlarm = false;

  constructor(
    public navCtrl: NavController,
    public sitesApiProvider: SitesService,
    public alertCtrl: AlertController,
    private timers: TimerService,
    public userService: UserService,
    public modalCtrl: PopoverController,
    private toastCtrl: ToastController,
    public storage: Storage,
  ) {
    this.sitesApiProvider.doUpdates = 2;
  }

  public setTemperatureUnits(scale) {
    this.isCelsius = scale ? (scale != 'fahrenheit') : false;
    this.tUnits = this.isCelsius ? '˚C' : this.sitesApiProvider.tempUnits;
  }

  ngOnInit() {
    var loginData = this.userService.getLoginData();
    this.showHeadgrowerOnlyAlarmSettings = loginData && loginData.email && (loginData.email.includes('@bartinst.com') || loginData.email === 'evan.bartinst@gmail.com' || loginData.email === 'steve43276@gmail.com');
    // console.log(this.inNodeData)
    this.sitesApiProvider.nodeDataSubscription.subscribe(
      (data) => this.onGetNodeSuccess(data),
      (error) => this.onGetNodeError(error));

    this.sitesApiProvider.getNodeData().then((data) => {
      this.initNodeData = data;
      this.nodeData = data;
      if(!this.nodeData.model) { this.nodeData.model = 'NA'; }
      // if(!this.nodeData.status) { this.nodeData.status = {}; }
      if(!this.nodeData.settings) { this.nodeData.settings = {}; }
      this.nodeLoaded = true;
      this.setHeatAndCool(this.nodeData);
      this.setAlertStatus();
      if(this.nodeData && this.nodeData.status) {
        this.zone1HasAlarm = ((this.nodeData.status.alarm && this.nodeData.status.alarm!=="NONE" && this.nodeData.status.alarm.endsWith("1")) || (this.nodeData.status.alarms && (this.nodeData.status.alarms.includes("HIGH1") || this.nodeData.status.alarms.includes("LOW1"))));
        this.zone2HasAlarm = ((this.nodeData.status.alarm && this.nodeData.status.alarm!=="NONE" && this.nodeData.status.alarm.endsWith("2")) || (this.nodeData.status.alarms && (this.nodeData.status.alarms.includes("HIGH2") || this.nodeData.status.alarms.includes("LOW2"))));
      } else {
        this.zone1HasAlarm = false;
        this.zone2HasAlarm = false;
      }
    })
    this.timerMisterSettings = TimerMisterSettings.getTimerMisterSettings();
    this.sitesApiProvider.doUpdates = 2;
    this.timers.setTimerSecond(1);
    this.setTemperatureUnits(this.userService.getTemperatureScale());
  }

  ngOnChanges() {
    this.timerMisterSettings = TimerMisterSettings.getTimerMisterSettings();
    if(this.inNodeData) {
      this.initNodeData = this.inNodeData;
      this.nodeData = this.inNodeData;
      if(!this.nodeData.model) { this.nodeData.model = 'NA'; }
      // if(!this.nodeData.status) { this.nodeData.status = {}; }
      if(!this.nodeData.settings) { this.nodeData.settings = {}; }
      this.nodeLoaded = true;
      this.setHeatAndCool(this.nodeData);
      this.setAlertStatus();
      if(this.nodeData.status) {
        this.zone1HasAlarm = ((this.nodeData.status.alarm && this.nodeData.status.alarm!=="NONE" && this.nodeData.status.alarm.endsWith("1")) || (this.nodeData.status.alarms && (this.nodeData.status.alarms.includes("HIGH1") || this.nodeData.status.alarms.includes("LOW1"))));
        this.zone2HasAlarm = ((this.nodeData.status.alarm && this.nodeData.status.alarm!=="NONE" && this.nodeData.status.alarm.endsWith("2")) || (this.nodeData.status.alarms && (this.nodeData.status.alarms.includes("HIGH2") || this.nodeData.status.alarms.includes("LOW2"))));
      } else {
        this.zone1HasAlarm = false;
        this.zone2HasAlarm = false;
      }
    } else {
      this.nodeLoaded = false;
    }

    this.sitesApiProvider.doUpdates = 2;
    this.timers.setTimerSecond(1);
    this.setTemperatureUnits(this.userService.getTemperatureScale());
  }

  ngOnDestroy() {
    //reset data loaded after it leaves so you don't get the "loading" message on transition
    this.nodeLoaded = false;
    //stop sites updates if started
    this.sitesApiProvider.doSiteUpdates = false;
    //stop node list updates if started
    this.sitesApiProvider.doUpdates = 1;
    //stop subscriptions
    this.onError = false;
    this.zone1HasAlarm = false;
    this.zone2HasAlarm = false;
  }

  private onGetNodeSuccess(data) {
    this.nodeLoaded = true;
    this.nodeData = data;
    this.sitesApiProvider.registerNextPrevious(this.nodeData.node_id);
    // var timePassed = moment.duration(moment().diff(moment(data.updated)));
    // var tenMinutes = moment.duration(10, 'minutes');
    // this.nodeData.online = !(timePassed > tenMinutes);
    this.setHeatAndCool(this.nodeData);
    this.checkRequests(this.nodeData.reqs);
    this.setAlertStatus();
    if(this.nodeData && this.nodeData.status) {
      this.zone1HasAlarm = ((this.nodeData.status.alarm && this.nodeData.status.alarm!=="NONE" && this.nodeData.status.alarm.endsWith("1")) || (this.nodeData.status.alarms && (this.nodeData.status.alarms.includes("HIGH1") || this.nodeData.status.alarms.includes("LOW1"))));
      this.zone2HasAlarm = ((this.nodeData.status.alarm && this.nodeData.status.alarm!=="NONE" && this.nodeData.status.alarm.endsWith("2")) || (this.nodeData.status.alarms && (this.nodeData.status.alarms.includes("HIGH2") || this.nodeData.status.alarms.includes("LOW2"))));
    } else {
      this.zone1HasAlarm = false;
      this.zone2HasAlarm = false;
    }
  }

  private onGetNodeError(error){
    this.onError = true;
    console.log("error getting data: " + JSON.stringify(error));
  }

  async resetAlarm(zone_number) {
    let alarm_msg = this.getAlarmMessageForZone(zone_number);
    let high_low = alarm_msg.includes("HIGH") ? 'high' : 'low';

    let show_error_alert = async () => {
      const error_alert = await this.alertCtrl.create({
        header: 'Reset Error',
        message: `Sorry we couldn't get the current value for the alarm to reset it. Please check your alarm settings to ensure all values are set.`,
        buttons: [{
          text:'Ok',
          role: 'cancel'
        }]
      });

      await error_alert.present();
    }
    if(alarm_msg) {
      let key = `alarm${zone_number}${alarm_msg.includes("HIGH") ? 'h' : 'l'}`
      let current_value = this.nodeData.settings[key]

      if(key && current_value) {
        const alert = await this.alertCtrl.create({
           header: 'Confirm Reset',
           message: `Are you sure you want to reset the alarm from Headgrower? This will set the Zone ${zone_number} ${high_low} alarm temperature to ${current_value}. You can also enter a different value below.`,
           inputs: [
             {
               name: `alarm_temp`,
               type: 'number',
               label: 'New Alarm Temperature',
               placeholder: current_value,
               value: current_value
             },
           ],
           buttons: [{
             text:'Cancel',
             role: 'cancel'
           }, {
             text: 'Reset',
             handler: (data: any) => {
               if(alarm_msg && key && current_value) {
                 // let key = `alarm${zone_number}${alarm_msg.includes("HIGH") ? 'h' : 'l'}`
                 // let current_value = this.nodeData.settings[key]
                 var reqData = {
                   node_id: this.nodeData.node_id,
                   key: key,
                   value: data && data["alarm_temp"] ? data["alarm_temp"] : current_value,
                   comment: `Reset alarm`
                 }
                 console.log('Submit data: ' + JSON.stringify(reqData));
                 this.sitesApiProvider.postRequest(reqData);
               }
             }
           }]
         });

         await alert.present();
      } else { show_error_alert(); }
    } else { show_error_alert(); }
  }

  async editSettingArrayValue(arrayName, index) {
    // console.log(`arrayName: ${arrayName}, index: ${index}`)
    return this.editSetting(`${arrayName}${index+1}`)
  }

  async editSetting(key) {
    var reqData = {
      node_id: this.nodeData.node_id,
      key: key,
      value: '',
      comment: ''
    }

    var settingInfo = this.getSettingInfo(key);

      // create the popup to enter the new value
    let settingsModal;
    if(settingInfo && settingInfo.type === 'prog') {
      const options = {
        component: EditSettingPage,
        componentProps: {
          settingInfo: settingInfo,
          siteName: this.nodeData.site_name,
          houseName: (this.nodeData.api_name || this.nodeData.node_name),
          canEdit: this.nodeData.canEdit,
          isCelsius: this.isCelsius,
          isPending: this.isPending(key),
          help: () => { settingsModal.dismiss(); this.guideMe(['Day', 'DIF', 'Night']); }
        },
        event: key
      };

      settingsModal = await this.modalCtrl.create(options);
    } else if(settingInfo && settingInfo.type === 'vent') {
      if((this.nodeData.model.startsWith("CB") && !this.nodeData.cis_reporting) && ['vent1', 'vent2'].includes(key)) {
        const description = (key === 'vent1') ? "Vent A Settings" : "Vent B Settings";
        settingInfo.desc = description;
      }

      const options = {
        component: EditVentSettingPage,
        componentProps: {
          settingInfo: settingInfo,
          siteName: this.nodeData.site_name,
          houseName: (this.nodeData.api_name || this.nodeData.node_name),
          canEdit: this.nodeData.canEdit,
          isPending: this.isPending(key),
          isCelsius: this.isCelsius,
          isClimateBoss: this.nodeData.model.startsWith("CB")
        },
        event: key
      };
      settingsModal = await this.modalCtrl.create(options);
    } else {
      // console.log(settingInfo)
      const options = {
        component: EditHeatCoolSettingPage,
        componentProps: {
          settingInfo: settingInfo,
          siteName: this.nodeData.site_name,
          houseName: (this.nodeData.api_name || this.nodeData.node_name),
          canEdit: this.nodeData.canEdit,
          isPending: this.isPending(key),
          isCelsius: this.isCelsius          
        },
        event: key
      };

      settingsModal = await this.modalCtrl.create(options);
    }

    settingsModal.onDidDismiss().then((data) => {
      data = data.hasOwnProperty('data') ? data.data : data;
      if (typeof data !== 'undefined'){
        if (data['button'] == "Save" && data['value'] != null){
          //process the user input
          reqData.value = data['value'] && data['value']['value'];
          reqData.comment = data['value'] && data['value']['comment'];
          console.log('Submit data: ' + JSON.stringify(reqData));
          this.sitesApiProvider.postRequest(reqData);
          this.timers.setFastUpdate(60); //set to fast update for the next 60 seconds
          this.setPending(key);
        }
      }
    });
    settingsModal.present();
  }

  public activateTimer(value = 1) {
    this.manuallyActivate('timerStart', 'Manually starting timer', value.toString())
  }

  public activateDehumidification(value = 1) {
    this.manuallyActivate('dhStart', 'Manually starting dehumidifiation', value.toString())
  }

  public getTimerInfo() {
    return {
      startTime: this.nodeData.settings.timerStartTime,
      holdTime: this.nodeData.settings.timerHoldTime,
      mode: this.nodeData.status.timerMode,
      repeat: this.nodeData.settings.timerRepeat,
      mistDifOnSec: this.nodeData.settings.mistDifOnSec,
      mistDifCycleMin: this.nodeData.settings.mistDifCycleMin,
      mistDayOnSec: this.nodeData.settings.mistDayOnSec,
      mistDayCycleMin: this.nodeData.settings.mistDayCycleMin,
      mistNightOnSec: this.nodeData.settings.mistNightOnSec,
      mistNightCycleMin: this.nodeData.settings.mistNightCycleMin
    }
  }

  public getShadeInfo() {
    if(this.nodeData && this.nodeData.settings && this.nodeData.settings.group1Timer && this.nodeData.settings.group1Timer[0].isShade && this.nodeData.status) {
      return {
        shadeEnable: this.nodeData.settings && this.nodeData.settings.timers && this.nodeData.settings.timers.length > 0 ? this.nodeData.settings.timers[0].mode : this.nodeData.settings.shadeEnable,
        shadeOpenTime: this.nodeData.settings.shadeOpenTime,
        shadePause: this.nodeData.settings.shadePause,
        shadeSlowRetract: this.nodeData.settings.shadeSlowRetract,
        shadeStep: this.nodeData.settings.shadeStep,
        shadeExtending: this.nodeData.status.timer,
        shadeRetracting: this.nodeData.status.alarm,
        shadeTempSP: this.nodeData.settings.shadeTempSP
      }
    }
  }

  public editDehumidificationSetup() {
    var onSave = (data, callback) => {
      var value = data['value']
      if (data['button'] == "Save" && value != null){
        this.sitesApiProvider.postRequest({
          node_id: this.nodeData.node_id,
          key: 'dhSetup',
          value: value['value'],
          comment: value['comment']
        });
        this.timers.setFastUpdate(60); //set to fast update for the next 60 seconds
        var dehumidifiationSetup = this.timerMisterSettings["settings.dhSetup"]
        if(dehumidifiationSetup) {
          this.timerMisterSettings["settings.dhSetup"].pending = true;
        }
      }

      callback && callback();
    }

    let navigationExtras: NavigationExtras = {
      queryParams: {
        currentSettings: this.nodeData.settings.dhSetup,
        saveFunction: onSave
      }
    };
    this.navCtrl.navigateForward('dehumid-settings', navigationExtras);
  }

  private manuallyActivate(key, comment, value = '1') {
    this.sitesApiProvider.postRequest({
      node_id: this.nodeData.node_id,
      key: key,
      value: value,
      comment: comment
    });
    this.timers.setFastUpdate(60); //set to fast update for the next 60 seconds
    if(this.timerMisterSettings[key]) {
      this.timerMisterSettings[key].pending = true;
    }
  }

  async showWindRainEdit(key) {
      var settingsForKey = this.timerMisterSettings[key]
      if(!settingsForKey) return;
      var reqData = {
        node_id: this.nodeData.node_id,
        key: settingsForKey.requestKey,
        value: '',
        comment: ''
      }

      var settingInfo = SettingInfo.get(this.nodeData, this.timerMisterSettings, key);
      if(settingInfo) {
        const options = {
          component: WindRainEditPage,
          componentProps: {
            settingInfo: settingInfo,
            siteName: this.nodeData.site_name,
            houseName: (this.nodeData.api_name || this.nodeData.node_name),
            canEdit: this.nodeData.canEdit,
            isPending: this.isPending(key)
          }
        };
        const settingsModal = await this.modalCtrl.create(options);

        settingsModal.onDidDismiss().then(data => {
          data = data.hasOwnProperty('data') ? data.data : data;
          if (typeof data !== 'undefined'){
            var value = data['value']
            if (data['button'] == "Save" && value != null){
              var eng = this.nodeData.settings.hasOwnProperty('english') ? this.nodeData.settings.english : true;
              reqData.value = settingsForKey.hasOwnProperty("convertValue") ? settingsForKey.convertValue(value.value, eng) : value['value'];
              reqData.comment = value['comment'];
              this.sitesApiProvider.postRequest(reqData);
              this.timers.setFastUpdate(60); //set to fast update for the next 60 seconds
              if(this.timerMisterSettings[key]) {
                this.timerMisterSettings[key].pending = true;
              }
            }
          }
        });
        settingsModal.present();
      }
  }

  public resetMinMax(){
    var reqData = {
      node_id: this.nodeData.node_id,
      key: 'rem',
      value: '1'
    }

    this.setPending('rem');
    this.timers.setFastUpdate(60); //set to fast update for the next 60 seconds

    this.sitesApiProvider.postRequest(reqData).then(() => {
      this.setNotPending('rem')
    }).catch(async (error) => {
      this.setNotPending('rem')
      console.log(`BinstLog: Error resetting min/max for node_id, ${this.nodeData.node_id}: ${error}`)

      let additionalError = "";
      if (error.message.includes("You are not authorized")){
        additionalError = "\nYou are not authorized to reset min/max for this site"
      }

      let toast = await this.toastCtrl.create({
        message: `Resetting Min/Max Failed ${additionalError}`,
        duration: 5000,
        position: 'top'
      });
      toast.present();
    });
  }

  //set the pending flags for settings which have requests pending
  public checkRequests(reqs) {
    //first set all to false
    this.settings.forEach(setting => {setting.pending = false;});
    Object.keys(this.timerMisterSettings).forEach(setting => {
      if(this.timerMisterSettings[setting]) {
        this.timerMisterSettings[setting].pending = false;
      }
    });
    //then go through list and set true if there
    reqs && reqs.forEach(req => {
      if (req.node_id == this.nodeData.node_id){
        // console.log("setting key to pending = " + req.key + "  val " + req.value + " comment: " + req.comment);
        this.setPending(req.key, req.value, req.comment);
      }
    });
  }

  public setNotPending(key){
    var i = this.settings.findIndex((setting) => setting.key == key);
    if(i !== -1) {
      this.settings[i].pending = false;
    } else {
      console.log(`BinstLog: Could not set unpending in controller-status, ${key}`)
    }
  }

  //set the pending flags for settings which have requests pending
  public setPending(testKey, value = undefined, comment = undefined) {
    var i = this.settings.findIndex((setting) => setting.key == testKey);
    if(i !== -1) {
      this.settings[i].pending = true;
    } else {
      if(["tbTimer1", "tbTimer2"].includes(testKey)) {
        let splitKey = testKey.split('.')
        this.setTimeBossPending({index: splitKey[0].split('tbTimer')[1], pending_data: {value: value, key: splitKey[1], comment: comment}});
      }

      if(this.timerMisterSettings[testKey]) {
        this.timerMisterSettings[testKey].pending = true;
        // console.log(`${testKey} = ${this.timerMisterSettings[testKey].pending} now pending`)
      }
    }
  }

  public isPendingFromArrayVal(arrayName, index) {
    return this.isPending(`${arrayName}${index+1}`);
  }

  public isPending(testKey) {
    var i = this.settings.findIndex((setting) => setting.key == testKey);
    if (i > -1) {
      return this.settings[i].pending;
    } else {
      if(this.timerMisterSettings[testKey]) {
        return this.timerMisterSettings[testKey].pending;
      } else {
        return false;
      }
    }
  }

  //set the button style depending on the state of that setting
  public getSettingText(findKey){
    var i = this.settings.findIndex((setting) => setting.key == findKey);
    console.log("setting index=" + i);
    if((this.nodeData.model.startsWith("CB") && !this.nodeData.cis_reporting) && ['vent1', 'vent2'].includes(findKey)) {
      return (findKey === 'vent1') ? "Vent A" : "Vent B";
    } else {
      return this.settings[i].desc;
    }
  }

  //get the information for this setting
  public getSettingInfo(findKey) {
    var i = this.settings.findIndex((setting) => setting.key == findKey);
    // console.log("setting index=" + i);
    if(i !== -1 && this.settings[i]) {
      var key = this.settings[i].key;
      // console.log('key=' + key);
      var value = this.nodeData.settings[key];
      // console.log("value=" + value);
      if (typeof value !== 'undefined'){
        this.settings[i].value = value;
      } else {
        this.settings[i].value = "";
      }
      var difsp2: number, daysp2: number, nitsp2: number;
      if (this.nodeData.status.zones == 2){
        difsp2 = this.nodeData.settings.difsp2;
        daysp2 = this.nodeData.settings.daysp2;
        nitsp2 = this.nodeData.settings.nitsp2;
      } else {
        difsp2 = this.nodeData.settings.difsp1;
        daysp2 = this.nodeData.settings.daysp1;
        nitsp2 = this.nodeData.settings.nitsp1;
      }
      if (key == 'h1m') {
        console.log(`generating setting info for ${key}`)
        //combine heat 1 mode and offset in value
        this.settings[i].value = this.nodeData.settings.h1m;
        this.settings[i].value += "," + this.nodeData.settings.h1t.toString();
        this.settings[i].value += "," + this.nodeData.settings.difsp1.toString();
        this.settings[i].value += "," + this.nodeData.settings.daysp1.toString();
        this.settings[i].value += "," + this.nodeData.settings.nitsp1.toString();
        if(this.nodeData.status.spartan_connection) this.settings[i].proportional = this.nodeData.status.spartan5;
      } else if (key == 'h2m') {
        console.log(`generating setting info for ${key}`)
        //combine heat 2 mode and offset in value
        this.settings[i].value = this.nodeData.settings.h2m;
        this.settings[i].value += "," + this.nodeData.settings.h2t.toString();
        this.settings[i].value += "," + difsp2.toString();
        this.settings[i].value += "," + daysp2.toString();
        this.settings[i].value += "," + nitsp2.toString();
        if(this.nodeData.status.spartan_connection) this.settings[i].proportional = this.nodeData.status.spartan6;
      } else if (key == 'c1m') {
        //combine cool 1 mode and offset in value
        this.settings[i].value = this.nodeData.settings.c1m;
        this.settings[i].value += "," + this.nodeData.settings.c1t.toString();
        this.settings[i].value += "," + this.nodeData.settings.difsp1.toString();
        this.settings[i].value += "," + this.nodeData.settings.daysp1.toString();
        this.settings[i].value += "," + this.nodeData.settings.nitsp1.toString();
        if(this.nodeData.status.spartan_connection) this.settings[i].proportional = this.nodeData.status.spartan1;
      } else if (key == 'c2m') {
        //combine cool 2 mode and offset in value
        this.settings[i].value = this.nodeData.settings.c2m;
        this.settings[i].value += "," + this.nodeData.settings.c2t.toString();
        this.settings[i].value += "," + this.nodeData.settings.difsp1.toString();
        this.settings[i].value += "," + this.nodeData.settings.daysp1.toString();
        this.settings[i].value += "," + this.nodeData.settings.nitsp1.toString();
        if(this.nodeData.status.spartan_connection) this.settings[i].proportional = this.nodeData.status.spartan2;
      } else if (key == 'c3m') {
        //combine cool 3 mode and offset in value
        this.settings[i].value = this.nodeData.settings.c3m;
        this.settings[i].value += "," + this.nodeData.settings.c3t.toString();
        this.settings[i].value += "," + difsp2.toString();
        this.settings[i].value += "," + daysp2.toString();
        this.settings[i].value += "," + nitsp2.toString();
        if(this.nodeData.status.spartan_connection) this.settings[i].proportional = this.nodeData.status.spartan3;
      } else if (key == 'c4m') {
        //combine cool 4 mode and offset in value
        this.settings[i].value = this.nodeData.settings.c4m;
        this.settings[i].value += "," + this.nodeData.settings.c4t.toString();
        this.settings[i].value += "," + difsp2.toString();
        this.settings[i].value += "," + daysp2.toString();
        this.settings[i].value += "," + nitsp2.toString();
        if(this.nodeData.status.spartan_connection) this.settings[i].proportional = this.nodeData.status.spartan4;
      } else if (key == 'prog1') {
        //combine program 1 settings in value
        let difSt = this.timeStrToMinutes(this.nodeData.settings.difst).toString();
        let difSp1 = this.nodeData.settings.difsp1.toString();
        let daySt = this.timeStrToMinutes(this.nodeData.settings.dayst).toString();
        let daySp1 = this.nodeData.settings.daysp1.toString();
        let nitSt = this.timeStrToMinutes(this.nodeData.settings.nitst).toString();
        let nitSp1 = this.nodeData.settings.nitsp1.toString()
        let sp1 = this.nodeData && this.nodeData.settings && this.nodeData.settings.sp1.toString();
        this.settings[i].value = `${difSt},${difSp1},${daySt},${daySp1},${nitSt},${nitSp1},${sp1}`
      } else if (key == 'prog2') {
        //combine program 2 settings in value
        let difSt = this.timeStrToMinutes(this.nodeData.settings.difst).toString();
        let difSp2 = this.nodeData.settings.difsp2.toString();
        let daySt = this.timeStrToMinutes(this.nodeData.settings.dayst).toString();
        let daySp2 = this.nodeData.settings.daysp2.toString();
        let nitSt = this.timeStrToMinutes(this.nodeData.settings.nitst).toString();
        let nitSp2 = this.nodeData.settings.nitsp2.toString()
        let sp2 = this.nodeData && this.nodeData.settings && this.nodeData.settings.sp2.toString();
        this.settings[i].value = `${difSt},${difSp2},${daySt},${daySp2},${nitSt},${nitSp2},${sp2}`
      } else if (key == 'vent1') {
        //combine vent 1 settings in value
        if(this.nodeData.model.startsWith('CB')) {
          var vent = (this.nodeData.settings.vent && this.nodeData.settings.vent.length > 0) ? this.nodeData.settings.vent[0] : {mode: '', man: '', st: '', p1: '', p2: '', p3: '', p4: '', p5: '', threshold: 0, openTime: 0, rainOR: 0, wind1OR: 0, wind2OR: 0};
          this.settings[i].value = `${vent.mode},${vent.man},${vent.st},${vent.p1},${vent.p2},${vent.p3},${vent.p4},${vent.p5},${this.nodeData.settings.difsp1},${this.nodeData.settings.daysp1},${this.nodeData.settings.nitsp1},${vent.threshold},${vent.openTime},${vent.rainOR},${vent.wind1OR},${vent.wind2OR}`;
        } else {
          this.settings[i].value = this.nodeData.settings.v1mode;
          this.settings[i].value += "," + this.nodeData.settings.v1man.toString();
          this.settings[i].value += "," + this.nodeData.settings.v1st.toString();
          this.settings[i].value += "," + this.nodeData.settings.v1p1.toString();
          this.settings[i].value += "," + this.nodeData.settings.v1p2.toString();
          this.settings[i].value += "," + this.nodeData.settings.v1p3.toString();
          this.settings[i].value += "," + this.nodeData.settings.v1p4.toString();
          this.settings[i].value += "," + this.nodeData.settings.v1p5.toString();
          this.settings[i].value += "," + this.nodeData.settings.difsp1.toString();
          this.settings[i].value += "," + this.nodeData.settings.daysp1.toString();
          this.settings[i].value += "," + this.nodeData.settings.nitsp1.toString();
        }
      } else if (key == 'vent2') {
        if(this.nodeData.model.startsWith('CB')) {
          let daySp = this.nodeData.status.zones == 2 ? this.nodeData.settings.daysp2 : this.nodeData.settings.daysp1;
          let difSp = this.nodeData.status.zones == 2 ? this.nodeData.settings.difsp2 : this.nodeData.settings.difsp1;
          let niteSp = this.nodeData.status.zones == 2 ? this.nodeData.settings.nitsp2 : this.nodeData.settings.nitsp1;
          vent = (this.nodeData.settings.vent && this.nodeData.settings.vent.length > 1) ? this.nodeData.settings.vent[1] : {mode: '', man: '', st: '', p1: '', p2: '', p3: '', p4: '', p5: '', threshold: 0, openTime: 0, rainOR: 0, wind1OR: 0, wind2OR: 0};
          this.settings[i].value = `${vent.mode},${vent.man},${vent.st},${vent.p1},${vent.p2},${vent.p3},${vent.p4},${vent.p5},${difSp},${daySp},${niteSp},${vent.threshold},${vent.openTime},${vent.rainOR},${vent.wind1OR},${vent.wind2OR}`;
        } else {
          //combine vent 2 settings in value
          this.settings[i].value = this.nodeData.settings.v2mode;
          this.settings[i].value += "," + this.nodeData.settings.v2man.toString();
          this.settings[i].value += "," + this.nodeData.settings.v2st.toString();
          this.settings[i].value += "," + this.nodeData.settings.v2p1.toString();
          this.settings[i].value += "," + this.nodeData.settings.v2p2.toString();
          this.settings[i].value += "," + this.nodeData.settings.v2p3.toString();
          this.settings[i].value += "," + this.nodeData.settings.v2p4.toString();
          this.settings[i].value += "," + this.nodeData.settings.v2p5.toString();
          this.settings[i].value += "," + difsp2.toString();
          this.settings[i].value += "," + daysp2.toString();
          this.settings[i].value += "," + nitsp2.toString();
        }
      } else if (key == 'vent3') {
        vent = (this.nodeData.settings.vent && this.nodeData.settings.vent.length > 2) ? this.nodeData.settings.vent[2] : {mode: '', man: '', st: '', p1: '', p2: '', p3: '', p4: '', p5: '', threshold: 0, openTime: 0, rainOR: 0, wind1OR: 0, wind2OR: 0};
        this.settings[i].value = `${vent.mode},${vent.man},${vent.st},${vent.p1},${vent.p2},${vent.p3},${vent.p4},${vent.p5},${this.nodeData.settings.difsp1},${this.nodeData.settings.daysp1},${this.nodeData.settings.nitsp1},${vent.threshold},${vent.openTime},${vent.rainOR},${vent.wind1OR},${vent.wind2OR}`;
      } else if (key == 'vent4') {
        let daySp = this.nodeData.status.zones == 2 ? this.nodeData.settings.daysp2 : this.nodeData.settings.daysp1;
        let difSp = this.nodeData.status.zones == 2 ? this.nodeData.settings.difsp2 : this.nodeData.settings.difsp1;
        let niteSp = this.nodeData.status.zones == 2 ? this.nodeData.settings.nitsp2 : this.nodeData.settings.nitsp1;
        vent = (this.nodeData.settings.vent && this.nodeData.settings.vent.length > 3) ? this.nodeData.settings.vent[3] : {mode: '', man: '', st: '', p1: '', p2: '', p3: '', p4: '', p5: '', threshold: 0, openTime: 0, rainOR: 0, wind1OR: 0, wind2OR: 0};
        this.settings[i].value = `${vent.mode},${vent.man},${vent.st},${vent.p1},${vent.p2},${vent.p3},${vent.p4},${vent.p5},${difSp},${daySp},${niteSp},${vent.threshold},${vent.openTime},${vent.rainOR},${vent.wind1OR},${vent.wind2OR}`;
      }
      // console.log("key: " + this.settings[i].key + " value: " + this.settings[i].value);

      if(['h1m', 'h2m', 'c1m', 'c2m', 'c3m', 'c4m'].includes(key)) {
        this.settings[i].desc = this.getCoolHeatName(key)
      } else if(key.includes('vent')) {
        if(key.includes('1')) { this.settings[i].desc = this.getVentOneName() }
        if(this.nodeData.model.includes('CB')) {
          let cbName = this.getCbVentName(parseInt(key.split('vent')[1]) - 1, (this.nodeData.status && this.nodeData.status.zones || 1), true);
          if(cbName !== undefined) this.settings[i].desc = cbName;
        }
      }

      return this.settings[i];
    }
  }

  private timeStrToMinutes (timeStr: string){
    var timeAry = timeStr.split(":");
    var minutes = parseInt(timeAry[0]) * 60 + parseInt(timeAry[1]);
    return minutes;
  }

  //check to see if alerts are enabled for this node
  public setAlertStatus(){
    var testEnable = false;
    this.nodeData && this.nodeData.users && this.nodeData.users.forEach(user => {
      if (user.alerts){
        if (this.userService.userLoginData.email == user.email && user.alerts.push){
          testEnable = true;
        }
      }
    });
    // console.log("test enable: " + testEnable);
    this.enableAlerts = testEnable;
  }

  //the alerts setting has changed so send the updated state to the api
  async onAlertsChange(){
    //toggle the alerts enable setting
    if (this.enableAlerts) this.enableAlerts = false;
    else this.enableAlerts = true;
    this.sitesApiProvider.setAlerts(this.nodeData.node_id, this.enableAlerts,
      {push: this.enableAlerts,
        status: this.enableAlerts,
        settings: this.enableAlerts,
        alarms: this.enableAlerts,
        wind: this.enableAlerts,
        rain: this.enableAlerts,
        windAndRain: this.enableAlerts,
        settingsFailed: this.enableAlerts,
        vfdLinkLost: false
      });

    let toast = await this.toastCtrl.create({
      message: `You have ${this.enableAlerts ? 'enabled' : 'disabled'} alerts for ${this.nodeData ? (this.nodeData.api_name || this.nodeData.node_name) : "this device"}`,
      duration: 3000,
      position: 'top'
    });

    toast.present();
  }

  public isSetPointOverridden() {
    var current_sp1 = this.nodeData.settings ? this.nodeData.settings.sp1 : 77;
    var current_sp2 = this.nodeData.settings ? this.nodeData.settings.sp2 : 77;
    switch(this.nodeData && this.nodeData.status && this.nodeData.status.stage) {
      case "NITE": {
        return {
          sp1: (current_sp1 != this.nodeData.settings.nitsp1),
          sp2: (current_sp2 != this.nodeData.settings.nitsp2)
        }
      }
      case "DAY": {
        return {
          sp1: (current_sp1 != this.nodeData.settings.daysp1),
          sp2: (current_sp2 != this.nodeData.settings.daysp2)
        }
      }
      case "DIF": {
        return {
          sp1: (current_sp1 != this.nodeData.settings.difsp1),
          sp2: (current_sp2 != this.nodeData.settings.difsp2)
        }
      }
      default:
        return  {
          sp1: false,
          sp2: false
        }
    }
  }

  public getStage() {
    if(this.nodeLoaded && this.nodeData && this.nodeData.status) {
      switch(this.nodeData.status.stage) {
        case "NITE": {
          return 'icon_night.svg';
        }
        case "DAY": {
          return 'icon_day.svg';
        }
        case "DIF": {
          return 'icon_dif.svg';
        }
        default:
          return 'icon_day.svg'
      }
    } else {
      return 'icon_day.svg';
    }
  }

  public goToAuditTrailPage() {
    let navigationExtras: NavigationExtras = {
      queryParams: { node_id: this.nodeData.node_id, english: this.isEnglishMetrics() }
    };

    this.navCtrl.navigateForward('audit-trail', navigationExtras);
  }

  public getVentOneName() {
    if(this.nodeData.model === 'CB-RWL') {
      return this.nodeData.output_overrides && this.nodeData.output_overrides.v1 ? `${this.nodeData.output_overrides.v1} - Vent A` : 'Vent A';
    } else if(this.nodeData.model.includes('RWL') && this.nodeData.status && this.nodeData.status.zones === 1) {
      return this.nodeData.output_overrides && this.nodeData.output_overrides.v1 ? `${this.nodeData.output_overrides.v1} - Vent 1` : 'Vent 1';
    } else if (this.nodeData.model.includes('CB')) {
      return this.nodeData.output_overrides && this.nodeData.output_overrides.v1 ? `${this.nodeData.output_overrides.v1} - Vent C` : 'Vent C';
    } else {
      return this.nodeData.output_overrides && this.nodeData.output_overrides.v1 ? `${this.nodeData.output_overrides.v1} - Vent 1` : 'Vent 1';
    }
  }

  public getCbVentName(index, zoneNum, zone2?, firstAndLast?) {
    var hasTwoZones = parseInt(zoneNum) === 2;
    let ventZeroName = zone2 ? 'Vent C' : 'Vent A';
    if(this.nodeData && this.nodeData.output_overrides) {
      let additionalOverride = zone2 ? `${this.nodeData.output_overrides.v2} - ` : `${this.nodeData.output_overrides.v1} - `;
      ventZeroName = `${additionalOverride} ${ventZeroName}`
    }

    switch (index) {
      case 0:
        return ventZeroName;
        break;
      case 1:
        return (this.nodeData.output_overrides && this.nodeData.output_overrides.v2) ? `${this.nodeData.output_overrides.v2} - Vent B` : 'Vent B';
        break;
      case 2:
        return (this.nodeData.output_overrides && this.nodeData.output_overrides.v3) ? `${this.nodeData.output_overrides.v3} - Vent C` : 'Vent C';
        break;
      case 3:
        return (this.nodeData.output_overrides && this.nodeData.output_overrides.v4) ? `${this.nodeData.output_overrides.v4} - Vent D` : 'Vent D';
        break;
    }
  }

  public getTimerName(output) {
    let default_name = `Timer ${output.split('t')[1]}`
    return this.nodeData.output_overrides && this.nodeData.output_overrides[output] ? `${this.nodeData.output_overrides[output]} - ${default_name}` : default_name;
  }

  public getCoolHeatName(output) {
    let outputToDefaultName = {
      c1m: "Cool 1",
      c2m: "Cool 2",
      c3m: "Cool 3",
      c4m: "Cool 4",
      h1m: "Heat 1",
      h2m: "Heat 2",
      h3m: "Heat 3"
    }

    let default_name = outputToDefaultName[output]
    let key = output.split('m')[0]
    return this.nodeData.output_overrides && this.nodeData.output_overrides[key] ? `${this.nodeData.output_overrides[key]} - ${default_name}` : default_name;
  }

  public getActivationForCbVent(ventSetting?, i?) {
    if(ventSetting) {
      let set_point_one = this.nodeData.settings && this.nodeData.settings.sp1;
      let set_point_two = this.nodeData.settings && this.nodeData.settings.sp2
      return ventSetting.st + ((i%2 === 0) ? set_point_one : set_point_two);
    } else { return 'OFF' };
  }

  public getFloatingAlarmActivation(set_point, heat_offset, float_offset) {
    if((set_point !== undefined) && (heat_offset !== undefined) && (float_offset !== undefined)) {
      return set_point - heat_offset - float_offset;
    } else {
      return float_offset
    }
  }

  public isEmpty(obj) {
    return (obj.constructor === Object) && (Object.entries(obj).length === 0 || (Object.entries(obj).length === 1 && Object.keys(obj)[0] === "original_index"))
  }

  public getVentInformation(zone2?) {
    if(this.nodeData.settings.vent) {
      this.nodeData.settings.vent.forEach((vent, index) => {
        vent.original_index = index;
      });

      if(this.nodeData.hasPo2 && this.nodeData.status && this.nodeData.status.zones == 2) {
        var vent_data = this.nodeData.settings.vent.filter((v, index) => {
          return (zone2 ? ((index % 2) !== 0) : ((index % 2) === 0)) && !this.isEmpty(v);
        })
        return vent_data;
      } else {
        var vent_data = this.nodeData.settings.vent.filter((v, index) => {
          return !this.isEmpty(v);
        })
        return vent_data;
      }
    } else { return []; }
  }

  public setTimeBossPending(event) {
    let newVals, keys, objectToUpdate;
    let updating = event.pending_data && event.pending_data.key && event.pending_data.key.toLowerCase().includes("timer") ? 'timer' : 'mister';
    if(event.pending_data && event.pending_data.key && event.pending_data.key.toLowerCase().includes("timer") && !event.pending_data.key.includes('manual') && !['started', 'stopped', 'disabled', 'resume', 'end', 'abort'].some((k) => event.pending_data.comment.includes(k))) {
      keys = [ 'startTimeA', 'holdTimeA', 'startTimeB', 'holdTimeB', 'enable', 'abort'];
      newVals = {startTimeA: '', holdTimeA: '', startTimeB:'', holdTimeB: '', enable:'', abort: ''};
      [ newVals.startTimeA, newVals.holdTimeA, newVals.startTimeB, newVals.holdTimeB, newVals.enable, newVals.abort ] = event.pending_data && event.pending_data.value.split(",");
      objectToUpdate = this.nodeData.settings.group1Timer[event.index];
    } else if(event.pending_data && event.pending_data.key && event.pending_data.key.toLowerCase().includes("mister")) {
      keys = ['light_set_point', 'mistAOnSec', 'mistACycleMin', 'mistBOnSec', 'mistBCycleMin', 'mistIdleOnSec', 'mistIdleCycleMin']
      newVals = {light_set_point: '', mistAOnSec: '', mistACycleMin: '', mistBOnSec:'', mistBCycleMin: '', mistIdleOnSec:'', mistIdleCycleMin: ''};
      [ newVals.light_set_point, newVals.mistAOnSec, newVals.mistACycleMin, newVals.mistBOnSec, newVals.mistBCycleMin, newVals.mistIdleOnSec, newVals.mistIdleCycleMin ] = event.pending_data && event.pending_data.value.split(",");
      objectToUpdate = this.nodeData.settings.group1Mister[event.index];
    }

    let getPendingKey = (comment) => {
      let key = 'enable';
      if(comment && comment.includes('started')) { key = 'manualStart'; }
      if(comment && comment.includes('stopped')) { key = 'stopped'; }
      if(comment && comment.includes('aborted')) { key = 'abort'; }
      if(comment && comment.includes('resume')) { key = 'resume'; }
      if(comment && comment.includes('end')) { key = 'end'; }
      return key;
    }

    if(keys && objectToUpdate && newVals) {
      keys.forEach((k) => {
        // console.log(k)
        if(updating === 'mister' ? (parseInt(objectToUpdate[k]) !== parseInt(newVals[k])) : (objectToUpdate[k] !== newVals[k])) {
          if(!objectToUpdate.pendingSettings) {
            objectToUpdate.pendingSettings = {};
          }
          // console.log(`${updating} ${event.index} objectToUpdate[k]: ${objectToUpdate[k]}, ${typeof objectToUpdate[k]}, newVals[k]: ${newVals[k]}, ${typeof newVals[k]}`)
          if(event.pending_data.comment && ['started', 'stopped', 'disabled', 'resume', 'end', 'abort'].some((k) => event.pending_data.comment.includes(k))) {
            k = getPendingKey(event.pending_data.comment)
          }
          objectToUpdate.pendingSettings[k] = true;
        }

        if(['enable', 'abort'].includes(k)) {
          if(event.pending_data.comment && ['started', 'stopped', 'disabled', 'resume', 'end', 'abort'].some((k) => event.pending_data.comment.includes(k))) {
            k = getPendingKey(event.pending_data.comment)
          }
          objectToUpdate[k] = newVals[k] === '1' ? true : false;
        } else {
          objectToUpdate[k] = newVals[k];
        }
      })
    }

    if (event && event.pending_data && event.pending_data.key && event.pending_data.key.includes('manual')) {
      objectToUpdate = this.nodeData.settings.group1Timer[event.index];
      if(!objectToUpdate.pendingSettings) { objectToUpdate.pendingSettings = {}; }
      objectToUpdate.pendingSettings[getPendingKey(event.pending_data.comment)] = true;
    }
  }

  public getTwoStageTimerInformation(index = 1) {
    let timerInfo = this.nodeData.settings.group1Timer ? this.nodeData.settings.group1Timer[index] : {isMister: false, pending: false, mister_pending: false, manualActive: false, on: false, enable: false, isShade: false, pendingSetting: {}};
    let misterInfo = this.nodeData.settings.group1Mister ? this.nodeData.settings.group1Mister[index] : {pending: false, on: false, mister_pending: false, pendingSetting: {}};

    var mister_index = this.settings.findIndex((setting) => setting.key == "tbMister2");
    if(timerInfo && !timerInfo.isMister) {
      var i = this.settings.findIndex((setting) => setting.key == "tbTimer2");
      timerInfo.pending = this.settings[i] ? this.settings[i].pending : false;
      timerInfo.mister_pending = this.timerMisterSettings[`tbMister${mister_index}`] ? this.timerMisterSettings[`tbMister${mister_index}`].pending : false;
      if((this.nodeData.status[`timer${index === 0 ? '' : '2'}`] === 'ON') || timerInfo.manualActive) {
        timerInfo.on = true;
      }
      timerInfo['pendingSettings'] = timerInfo['pendingSettings'];

      return timerInfo;
    } else {
      if(mister_index) {
        misterInfo.pending = this.timerMisterSettings[`tbMister${mister_index}`] ? this.timerMisterSettings[`tbMister${mister_index}`].pending : false;
        misterInfo.on = this.nodeData.status[`timer${index === 0 ? '' : '2'}`] === 'ON';
        misterInfo['pendingSettings'] = {...timerInfo['pendingSettings'], ...misterInfo['pendingSettings']};
      }

      return {...timerInfo, ...misterInfo};
    }
  }

  getTwoStageTimerMode(index = 1) {
    let timerInfo = this.getTwoStageTimerInformation(index)

    if(!timerInfo.enable) {
      return 'Off';
    } else {
      if(timerInfo.isMister) {
        return 'Mister'
      } else if(timerInfo.isShade) {
        return 'Shade';
      } else {
        return 'Timer';
      }
    }
  }

  public getLightSetPoint(index = 0) {
    if(this.nodeData && this.nodeData.settings) {
      let misterInfo = this.nodeData.settings.group1Mister ? this.nodeData.settings.group1Mister[index] : undefined;
      return misterInfo ? misterInfo.lightSP : "N/A"
    } else {
      return "N/A"
    }
  }

  public getCurrentUserEmail() {
    return (this.userService && this.userService.userLoginData) ? this.userService.userLoginData.email : "Email N/A"
  }

  public downloadUrl() {
    return this.sitesApiProvider.getDownloadSettingsUrl(this.nodeData.node_id);
  }

  public getAlarmMessageForZone(zone_number) {
    return this.nodeData.status.alarm.endsWith(`${zone_number}`) ? this.nodeData.status.alarm : this.nodeData.status.alarms.find((msg) => msg.endsWith(zone_number))
  }

  private isEnglishMetrics() {
    return (this.nodeData && this.nodeData.settings) && (this.nodeData.settings.hasOwnProperty('english') ? this.nodeData.settings.english : true);
  }

  private transformTemperatures(nodeData) {
    // var statusKeysToConvert = [
    //   'ct1', 'ct2', 'ct3',
    //   'min1', 'max1', 'min2', 'max2'
    // ];
    // statusKeysToConvert.forEach((key) => {
    //   nodeData.status[key] = EnglishMetricFormat.convertToCelsius(nodeData.status[key]);
    // });
    //
    // var settingsKeysToConvert = [
    //   'alarm1l','alarm1h','alarm2l','alarm2h',
    //   'sp1', 'sp2', 'sp3',
    //   'difsp1','daysp1','nitsp1',
    //   'difsp2','daysp2','nitsp2',
    //   'h1t', 'h2t', 'c1t', 'c2t', 'c3t', 'c4t',
    //   'v1st'
    // ];
    // settingsKeysToConvert.forEach((key) => {
    //   nodeData.settings[key] = EnglishMetricFormat.convertToCelsius(nodeData.settings[key]);
    // });
  }

  alarmOn(setting, alarmName) {
    try {
      if(this.nodeData.settings[setting] && this.nodeData.status && this.nodeData.status.alarms && this.nodeData.status.alarms.length > 0) {
        return this.nodeData.status.alarms.includes(alarmName);
      } else {
        return false;
      }
    } catch(e) { return false; }
  }

  pressHelp(zone) {
    let pages = ['Outputs', 'Vents']
    if(this.nodeData && this.nodeData.status && this.nodeData.status.zones > 1) {
      if(zone === '1') { pages = ['Zone 1 Outputs', 'Vents']}
      if(zone === '2') { pages = ['Zone 2 Outputs', 'Zone 2 Vents']}
    }
    
    this.guideMe(pages)
  }

  async guideMe(pages) {
    const options = {
      component: ProgrammingToolsPage,
      componentProps: {
        node_id: this.nodeData ? this.nodeData.node_id : this.inNodeData.node_id,
        narrow: pages
      },
      event: pages
    };

    let settingsModal = await this.modalCtrl.create(options);
    settingsModal.onDidDismiss().then((data) => { 
      // console.log(data); 
    })
    settingsModal.present();
  }

  private setHeatAndCool(nodeData) {
    const status = nodeData.status;
    const settings = nodeData.settings;

    var vent2ActivationTemperature = (nodeData.settings ? parseInt(nodeData.settings.sp2) : 0) + (nodeData.settings ? parseInt(nodeData.settings.v2st) : 0)
    var vent1ActivationTemperature = (nodeData.settings ? parseInt(nodeData.settings.sp1) : 0) + (nodeData.settings ? parseInt(nodeData.settings.v1st) : 0)
    if(status) {
      var vent2Open = ((status.ct2 > vent2ActivationTemperature) && settings.v2mode !== 'OFF') || (settings.v2mode === 'ON');
      var vent1Open = ((status.ct1 > vent1ActivationTemperature) && settings.v1mode !== 'OFF') || (settings.v1mode === 'ON');
      var cool1or2On = ["c1", "c2"].some(coolSetting => status[coolSetting] === 'ON');
      var cool3or4On = ["c3", "c4"].some(coolSetting => status[coolSetting] === 'ON');

      if(nodeData.model.includes("CB") && settings.vent) {
        vent2Open = settings.vent.find((vent, index) => { return [1, 3].includes(index) && vent.man > 0});
        vent1Open = settings.vent.find((vent, index) => { return [0, 2].includes(index) && vent.man > 0});
      }
    }

    if(status && status.zones && status.zones == 2 && this.nodeData.model) {
      var zone2Cooling = (nodeData && nodeData.model && !nodeData.model.includes('RWL')) ? (cool3or4On || (nodeData.hasPo2 && vent2Open)) : (nodeData.hasPo2 && vent2Open)
      var zone2Heat = (status.h2 === 'ON')
      var zone1Heat = (status.h1 === 'ON')
      var zone1Cool = (nodeData && nodeData.model && !nodeData.model.includes('RWL')) ? (cool1or2On || (nodeData.hasPo1 && vent1Open)) : (nodeData.hasPo1 && vent1Open)
      nodeData.settings.heatCool = {
        zone1: {
          on: zone1Heat || zone1Cool,
          heating: zone1Heat,
          cooling: zone1Cool,
          vent1Activation: vent1ActivationTemperature
        },
        zone2: {
          on: zone2Heat || zone2Cooling,
          heating: zone2Heat,
          cooling: zone2Cooling,
          vent2Activation: vent2ActivationTemperature
        },
        heat3: {
          on: status && status.h3 === 'ON'
        }
      }
    } else {
      var heating = ["h1", "h2"].some(heatSetting => { return (status && status[heatSetting] === 'ON'); });
      var cooling = nodeData && nodeData.model && !nodeData.model.includes('RWL') ? (cool1or2On || cool3or4On || (nodeData.hasPo1 && vent1Open) || (nodeData.hasPo2 && vent2Open)) : ((nodeData.hasPo1 && vent1Open) || (nodeData.hasPo2 && vent2Open));
      if(!nodeData.settings) { nodeData.settings = {}}
      nodeData.settings.heatCool = {
        zone1: {
          on: heating || cooling,
          heating: heating,
          cooling: cooling,
          vent1Activation: vent1ActivationTemperature,
          vent2Activation: vent2ActivationTemperature
        },
        zone2: { on: false, heating: false, cooling: false },
        heat3: {
          on: status && status.h3 === 'ON'
        }
      }
    }
  }
}

export interface SettingInfoType {
    key: string;
    value?: string;
    current?: string;
    type?: string;
    pending: boolean;
    desc?: string;
    pattern?: string;
    reformat?;
    error?: string;
    additionalOption?: {
      checked?: boolean;
      value: string;
      label: string;
    },
    valid?;
    units?;
    options?;
    inputmode?;
    disable_allowed?: boolean;
    proportional?;
}
