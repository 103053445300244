let defaultGHK = {
    settings: {
        "lightSource": "NONE",
        "difst": "6:00",
        "dayst": "8:00",
        "nitst": "17:00",
        "difsp1": 65,
        "daysp1": 65,
        "nitsp1": 65,
        "difsp2": 65,
        "daysp2": 65,
        "nitsp2": 65,
        "sp1": 65,
        "sp2": 65,
        "sp3": 50,
        "humsp": 0,
        "on2auto": "0:10",
        "hc_delay": 5,
        "cisID": 28,
        "controller_time": "10:25",
        "therm_fail": "HEAT",
        "alarm1l": 32,
        "alarm1h": 131,
        "alarm2l": 32,
        "alarm2h": 131,
        "c1t": 3,
        "c2t": 5,
        "c3t": 7,
        "c4t": 8,
        "h1t": 0,
        "h2t": 2,
        "c1m": "AUTO",
        "c2m": "AUTO",
        "c3m": "AUTO",
        "c4m": "AUTO",
        "h1m": "AUTO",
        "h2m": "AUTO",
        "group1Timer": [
            {
                "startTimeA": "0:00",
                "holdTimeA": "0:00",
                "startTimeB": "0:00",
                "holdTimeB": "0:00",
                "timeRemaining": "0:00",
                "inUse": true,
                "enable": false,
                "abort": false,
                "manualActive": false,
                "isMister": false,
                "isShade": false
            },
            {
                "startTimeA": "0:00",
                "holdTimeA": "0:00",
                "startTimeB": "0:00",
                "holdTimeB": "0:00",
                "timeRemaining": "0:00",
                "inUse": true,
                "enable": false,
                "abort": false,
                "manualActive": false,
                "isMister": false
            },
            {
                "startTimeA": "0:00",
                "holdTimeA": "0:00",
                "startTimeB": "0:00",
                "holdTimeB": "0:00",
                "timeRemaining": "0:00",
                "inUse": false,
                "enable": false,
                "abort": false,
                "manualActive": false,
                "isMister": false
            },
            {
                "startTimeA": "0:00",
                "holdTimeA": "0:00",
                "startTimeB": "0:00",
                "holdTimeB": "0:00",
                "timeRemaining": "0:00",
                "inUse": false,
                "enable": false,
                "abort": false,
                "manualActive": false,
                "isMister": false
            },
            {
                "startTimeA": "0:00",
                "holdTimeA": "0:00",
                "startTimeB": "0:00",
                "holdTimeB": "0:00",
                "timeRemaining": "0:00",
                "inUse": false,
                "enable": false,
                "abort": false,
                "manualActive": false,
                "isMister": false
            },
            {
                "startTimeA": "0:00",
                "holdTimeA": "0:00",
                "startTimeB": "0:00",
                "holdTimeB": "0:00",
                "timeRemaining": "0:00",
                "inUse": false,
                "enable": false,
                "abort": false,
                "manualActive": false,
                "isMister": false
            },
            {
                "startTimeA": "0:00",
                "holdTimeA": "0:00",
                "startTimeB": "0:00",
                "holdTimeB": "0:00",
                "timeRemaining": "0:00",
                "inUse": false,
                "enable": false,
                "abort": false,
                "manualActive": false,
                "isMister": false
            },
            {
                "startTimeA": "0:00",
                "holdTimeA": "0:00",
                "startTimeB": "0:00",
                "holdTimeB": "0:00",
                "timeRemaining": "0:00",
                "inUse": true,
                "enable": false,
                "abort": false,
                "manualActive": false,
                "isMister": false
            }
        ],
        "group1Mister": [
            {
                "lightSP": 0,
                "mistAOnSec": 0,
                "mistACycleMin": 0,
                "mistBOnSec": 0,
                "mistBCycleMin": 0,
                "mistIdleOnSec": 0,
                "mistIdleCycleMin": 0
            },
            {
                "lightSP": 0,
                "mistAOnSec": 0,
                "mistACycleMin": 0,
                "mistBOnSec": 0,
                "mistBCycleMin": 0,
                "mistIdleOnSec": 0,
                "mistIdleCycleMin": 0
            },
            {
                "lightSP": 0,
                "mistAOnSec": 0,
                "mistACycleMin": 0,
                "mistBOnSec": 0,
                "mistBCycleMin": 0,
                "mistIdleOnSec": 0,
                "mistIdleCycleMin": 0
            },
            {
                "lightSP": 0,
                "mistAOnSec": 0,
                "mistACycleMin": 0,
                "mistBOnSec": 0,
                "mistBCycleMin": 0,
                "mistIdleOnSec": 0,
                "mistIdleCycleMin": 0
            },
            {
                "lightSP": 0,
                "mistAOnSec": 0,
                "mistACycleMin": 0,
                "mistBOnSec": 0,
                "mistBCycleMin": 0,
                "mistIdleOnSec": 0,
                "mistIdleCycleMin": 0
            },
            {
                "lightSP": 0,
                "mistAOnSec": 0,
                "mistACycleMin": 0,
                "mistBOnSec": 0,
                "mistBCycleMin": 0,
                "mistIdleOnSec": 0,
                "mistIdleCycleMin": 0
            },
            {
                "lightSP": 0,
                "mistAOnSec": 0,
                "mistACycleMin": 0,
                "mistBOnSec": 0,
                "mistBCycleMin": 0,
                "mistIdleOnSec": 0,
                "mistIdleCycleMin": 0
            },
            {
                "lightSP": 0,
                "mistAOnSec": 0,
                "mistACycleMin": 0,
                "mistBOnSec": 0,
                "mistBCycleMin": 0,
                "mistIdleOnSec": 0,
                "mistIdleCycleMin": 0
            }
        ],
        "dhStartTime": "0:00",
        "dhSp": 0,
        "dhFan1": 0,
        "dhFan2": 0,
        "dhHeat": 0,
        "dhLoTemp": 0,
        "dhHiTemp": 0,
        "dhSetup": 0,
        "dhVentMode": "Off",
        "dhDif": "OFF",
        "dhDay": "OFF",
        "dhNight": "OFF",
        "dhRepeat": "OFF",
        "shadeOpenTime": 100,
        "shadeStep": 25,
        "shadePause": 2,
        "shadeTempSP": 97,
        "shadeEnable": false,
        "shadeSlowRetract": false,
        "vent": [
            {},
            {},
            {
                "man": 99,
                "mode": "5-STEP",
                "st": 2,
                "p1": 7,
                "p2": 15,
                "p3": 25,
                "p4": 50,
                "p5": 99,
                "threshold": 1,
                "openTime": 100,
                "rainOR": 1,
                "wind1OR": 1,
                "wind2OR": 0
            },
            {
                "man": 99,
                "mode": "5-STEP",
                "st": 2,
                "p1": 7,
                "p2": 15,
                "p3": 25,
                "p4": 50,
                "p5": 99,
                "threshold": 1,
                "openTime": 100,
                "rainOR": 1,
                "wind1OR": 1,
                "wind2OR": 0
            }
        ],
        "english": true,
        "wind": {
            "activation": {
                "delay": "0:05",
                "setPoint": 40,
                "loSetPoint": 24
            },
            "dropOff": {
                "delay": "1:00",
                "setPoint": 16
            },
            "directionMask": 241
        },
        "wind2": {
            "activation": {
                "setPoint": 40,
                "loSetPoint": 24
            },
            "directionMask": 31
        },
        "group2Timer": [
            {
                "startTimeA": "0:00",
                "holdTimeA": "0:00",
                "startTimeB": "0:00",
                "holdTimeB": "0:00",
                "timeRemaining": "0:00",
                "enable": false,
                "abort": false,
                "manualActive": false,
                "isMister": false
            },
            {
                "startTimeA": "0:00",
                "holdTimeA": "0:00",
                "startTimeB": "0:00",
                "holdTimeB": "0:00",
                "timeRemaining": "0:00",
                "enable": true,
                "abort": false,
                "manualActive": false,
                "isMister": false
            }
        ],
        "group2Mister": [
            {
                "lightSP": 0,
                "mistAOnSec": 0,
                "mistACycleMin": 0,
                "mistBOnSec": 0,
                "mistBCycleMin": 0,
                "mistIdleOnSec": 0,
                "mistIdleCycleMin": 0
            },
            {
                "lightSP": 0,
                "mistAOnSec": 0,
                "mistACycleMin": 0,
                "mistBOnSec": 0,
                "mistBCycleMin": 0,
                "mistIdleOnSec": 0,
                "mistIdleCycleMin": 0
            }
        ]
    },
    type: "ENV",
    "isWindRain": false
}

let defaultRWL = {
    "settings": {
        "lightSource": "NONE",
        "difst": "6:00",
        "dayst": "8:00",
        "nitst": "17:00",
        "difsp1": 65,
        "daysp1": 65,
        "nitsp1": 65,
        "difsp2": 65,
        "daysp2": 65,
        "nitsp2": 65,
        "sp1": 65,
        "sp2": 65,
        "sp3": 50,
        "humsp": 0,
        "on2auto": "0:10",
        "hc_delay": 5,
        "cisID": 28,
        "controller_time": "10:49",
        "therm_fail": "HEAT",
        "alarm1l": 32,
        "alarm1h": 131,
        "alarm2l": 32,
        "alarm2h": 131,
        "c1t": 3,
        "c2t": 5,
        "c3t": 7,
        "c4t": 8,
        "h1t": 0,
        "h2t": 0,
        "c1m": "AUTO",
        "c2m": "AUTO",
        "c3m": "AUTO",
        "c4m": "AUTO",
        "h1m": "AUTO",
        "h2m": "AUTO",
        "group1Timer": [
            {
                "startTimeA": "0:00",
                "holdTimeA": "0:00",
                "startTimeB": "0:00",
                "holdTimeB": "0:00",
                "timeRemaining": "0:00",
                "inUse": true,
                "enable": false,
                "abort": false,
                "manualActive": false,
                "isMister": false,
                "isShade": false
            },
            {
                "startTimeA": "0:00",
                "holdTimeA": "0:00",
                "startTimeB": "0:00",
                "holdTimeB": "0:00",
                "timeRemaining": "0:00",
                "inUse": true,
                "enable": false,
                "abort": false,
                "manualActive": false,
                "isMister": false
            },
            {
                "startTimeA": "0:00",
                "holdTimeA": "0:00",
                "startTimeB": "0:00",
                "holdTimeB": "0:00",
                "timeRemaining": "0:00",
                "inUse": false,
                "enable": false,
                "abort": false,
                "manualActive": false,
                "isMister": false
            },
            {
                "startTimeA": "0:00",
                "holdTimeA": "0:00",
                "startTimeB": "0:00",
                "holdTimeB": "0:00",
                "timeRemaining": "0:00",
                "inUse": false,
                "enable": false,
                "abort": false,
                "manualActive": false,
                "isMister": false
            },
            {
                "startTimeA": "0:00",
                "holdTimeA": "0:00",
                "startTimeB": "0:00",
                "holdTimeB": "0:00",
                "timeRemaining": "0:00",
                "inUse": false,
                "enable": false,
                "abort": false,
                "manualActive": false,
                "isMister": false
            },
            {
                "startTimeA": "0:00",
                "holdTimeA": "0:00",
                "startTimeB": "0:00",
                "holdTimeB": "0:00",
                "timeRemaining": "0:00",
                "inUse": false,
                "enable": false,
                "abort": false,
                "manualActive": false,
                "isMister": false
            },
            {
                "startTimeA": "0:00",
                "holdTimeA": "0:00",
                "startTimeB": "0:00",
                "holdTimeB": "0:00",
                "timeRemaining": "0:00",
                "inUse": false,
                "enable": false,
                "abort": false,
                "manualActive": false,
                "isMister": false
            },
            {
                "startTimeA": "0:00",
                "holdTimeA": "0:00",
                "startTimeB": "0:00",
                "holdTimeB": "0:00",
                "timeRemaining": "0:00",
                "inUse": true,
                "enable": false,
                "abort": false,
                "manualActive": false,
                "isMister": false
            }
        ],
        "group1Mister": [
            {
                "lightSP": 0,
                "mistAOnSec": 0,
                "mistACycleMin": 0,
                "mistBOnSec": 0,
                "mistBCycleMin": 0,
                "mistIdleOnSec": 0,
                "mistIdleCycleMin": 0
            },
            {
                "lightSP": 0,
                "mistAOnSec": 0,
                "mistACycleMin": 0,
                "mistBOnSec": 0,
                "mistBCycleMin": 0,
                "mistIdleOnSec": 0,
                "mistIdleCycleMin": 0
            },
            {
                "lightSP": 0,
                "mistAOnSec": 0,
                "mistACycleMin": 0,
                "mistBOnSec": 0,
                "mistBCycleMin": 0,
                "mistIdleOnSec": 0,
                "mistIdleCycleMin": 0
            },
            {
                "lightSP": 0,
                "mistAOnSec": 0,
                "mistACycleMin": 0,
                "mistBOnSec": 0,
                "mistBCycleMin": 0,
                "mistIdleOnSec": 0,
                "mistIdleCycleMin": 0
            },
            {
                "lightSP": 0,
                "mistAOnSec": 0,
                "mistACycleMin": 0,
                "mistBOnSec": 0,
                "mistBCycleMin": 0,
                "mistIdleOnSec": 0,
                "mistIdleCycleMin": 0
            },
            {
                "lightSP": 0,
                "mistAOnSec": 0,
                "mistACycleMin": 0,
                "mistBOnSec": 0,
                "mistBCycleMin": 0,
                "mistIdleOnSec": 0,
                "mistIdleCycleMin": 0
            },
            {
                "lightSP": 0,
                "mistAOnSec": 0,
                "mistACycleMin": 0,
                "mistBOnSec": 0,
                "mistBCycleMin": 0,
                "mistIdleOnSec": 0,
                "mistIdleCycleMin": 0
            },
            {
                "lightSP": 0,
                "mistAOnSec": 0,
                "mistACycleMin": 0,
                "mistBOnSec": 0,
                "mistBCycleMin": 0,
                "mistIdleOnSec": 0,
                "mistIdleCycleMin": 0
            }
        ],
        "dhStartTime": "0:00",
        "dhSp": 0,
        "dhFan1": 0,
        "dhFan2": 0,
        "dhHeat": 0,
        "dhLoTemp": 0,
        "dhHiTemp": 0,
        "dhSetup": 0,
        "dhVentMode": "Off",
        "dhDif": "OFF",
        "dhDay": "OFF",
        "dhNight": "OFF",
        "dhRepeat": "OFF",
        "shadeOpenTime": 100,
        "shadeStep": 25,
        "shadePause": 2,
        "shadeTempSP": 97,
        "shadeEnable": false,
        "shadeSlowRetract": false,
        "vent": [
            {
                "man": 25,
                "mode": "5-STEP",
                "st": 2,
                "p1": 7,
                "p2": 15,
                "p3": 25,
                "p4": 50,
                "p5": 99,
                "threshold": 1,
                "openTime": 200,
                "rainOR": 1,
                "wind1OR": 1,
                "wind2OR": 0
            },
            {
                "man": 25,
                "mode": "5-STEP",
                "st": 2,
                "p1": 7,
                "p2": 15,
                "p3": 25,
                "p4": 50,
                "p5": 99,
                "threshold": 1,
                "openTime": 200,
                "rainOR": 1,
                "wind1OR": 0,
                "wind2OR": 1
            },
            {
                "man": 99,
                "mode": "5-STEP",
                "st": 2,
                "p1": 7,
                "p2": 15,
                "p3": 25,
                "p4": 50,
                "p5": 99,
                "threshold": 1,
                "openTime": 200,
                "rainOR": 1,
                "wind1OR": 1,
                "wind2OR": 0
            },
            {
                "man": 99,
                "mode": "5-STEP",
                "st": 2,
                "p1": 7,
                "p2": 15,
                "p3": 25,
                "p4": 50,
                "p5": 99,
                "threshold": 1,
                "openTime": 200,
                "rainOR": 1,
                "wind1OR": 1,
                "wind2OR": 0
            }
        ],
        "english": true,
        "group2Timer": [
            {
                "startTimeA": "0:00",
                "holdTimeA": "0:00",
                "startTimeB": "0:00",
                "holdTimeB": "0:00",
                "timeRemaining": "0:00",
                "enable": false,
                "abort": false,
                "manualActive": false,
                "isMister": false
            },
            {
                "startTimeA": "0:00",
                "holdTimeA": "0:00",
                "startTimeB": "0:00",
                "holdTimeB": "0:00",
                "timeRemaining": "0:00",
                "enable": true,
                "abort": false,
                "manualActive": false,
                "isMister": false
            }
        ],
        "group2Mister": [
            {
                "lightSP": 0,
                "mistAOnSec": 0,
                "mistACycleMin": 0,
                "mistBOnSec": 0,
                "mistBCycleMin": 0,
                "mistIdleOnSec": 0,
                "mistIdleCycleMin": 0
            },
            {
                "lightSP": 0,
                "mistAOnSec": 0,
                "mistACycleMin": 0,
                "mistBOnSec": 0,
                "mistBCycleMin": 0,
                "mistIdleOnSec": 0,
                "mistIdleCycleMin": 0
            }
        ]
    },
    type: "ENV",
    "isWindRain": false
}

function get(node) {
    let settingsForModel = (node && node.model && node.model.includes("RWL")) ? defaultRWL : defaultGHK;
    return {...{
        model: node.model,
        settings: {},
        status: {},
        node_name: node.node_name,
        node_id: node.node_id,
        site_id: node.site_id
    }, ...settingsForModel}
}

export const DefaultSettings = {
 get
}
  