import * as d3 from 'd3-selection';
import * as d3Scale from 'd3-scale';
import * as d3Array from 'd3-array';
import * as d3Axis from 'd3-axis';
import * as d3Shape from 'd3-shape';

let svg, g;
let height = 350
let width = 425;
let x, x1, y;
let margin = { top: 25, right: 50, bottom: 30, left: 40 };

let chartLabel = "Temperature (By Day)";
let keys = ["dif", "day", "nite", "average"];
let color = d3Scale.scaleOrdinal(keys, ["#efa900", "#Dd6f6f", "#084e7b", "#5cbf91"]);
let groupKey = "day_label";
let groupBy = 'days';
let svg_width = '100%';
let svg_height = '100%';

let init = (chartId, adjusted_svg_width = '100%', adjusted_svg_height = '100%') => {
  let not_drawn = false;
  if(d3.select(chartId).size() == 0) {
    console.log(`no element found for id ${chartId}`)
    not_drawn = true;
  }
  if(svg_height) svg_height = adjusted_svg_height;
  if(svg_width) svg_width = adjusted_svg_width;

  svg = d3.select(chartId)
    .append('svg')
    .attr('width', svg_width)
    .attr('height', svg_height)
    .attr('viewBox', '0 0 700 400');
  g = svg.append('g')
    .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

  return {svg: svg, g: g, not_drawn: not_drawn}
}

let initAxes = (chartData, svg, g) => {
  let x_keys = groupBy === 'days' ? chartData.map((d) => d.day_label) : ['dif', 'day', 'nite', 'average'];
  const min_max = {
    min: (d3Array.min(chartData, d => d3Array.min(keys, key => d[key])) - 5),
    max: (d3Array.max(chartData, d => d3Array.max(keys, key => d[key])) + 5)
  }

  const x = d3Scale.scaleBand()
    .domain(chartData.map(d => d[groupKey]))
    .rangeRound([0, width - margin.right])
    .paddingInner(0.1);
  const x1 = d3Scale.scaleBand()
    .domain(x_keys)
    .rangeRound([0, x.bandwidth()])
    .padding(0.05);
  const y = d3Scale.scaleLinear()
    .domain([min_max.min, min_max.max]).nice()
    .rangeRound([height, margin.top])

  let range_value_length = (width / x_keys.length);
  let xDayScaleRange = d3Array.range(0, width, (width / x_keys.length))
  const xDayScale = d3Scale.scaleOrdinal()
      .domain(x_keys)
      .range(xDayScaleRange);

  return {x: x, y: y, x1: x1, min_max: min_max, xDayScale: xDayScale};
}

let drawAxes = (g, x, y, zone) => {
  g.append('g')
    .attr('class', 'axis axis--x')
    .attr('transform', 'translate(0,' + height + ')')
    .call(d3Axis.axisBottom(x))
    .attr('font-size', '12');

  g.append('g')
    .attr('class', 'axis axis--y')
    .attr('font-size', '14')
    .call(
      d3Axis.axisLeft(y)
        .ticks(10)
        .tickSize(-(width - margin.right))
    )
    .append('text')
      .attr('class', 'axis-title')
      .attr('x', ((width + margin.right + margin.left) / 2))
      .attr('dx', '5em')
      .attr('fill', 'rgb(0, 0, 0)')
      .text(`${zone} ${chartLabel}`);

  // g.append('g')
  //   .attr('class', 'axis axis--y')
  //   .attr('font-size', '14')
  //   .attr("transform", "translate( " + (width - margin.right) + ", 0 )")
  //   .call(d3Axis.axisRight(y).ticks(10))
  //     .append('text')
  //       .attr('class', 'axis-title')
  //       .attr('dx', '5em')
  //       .attr('fill', 'rgb(0, 0, 0)')
  //       .text(`${zone} ${chartLabel}`);
}

let drawLegend = (g) => {
  const domain = color.domain().slice();

  var legendRectSize = 10;
  var legendSpacing = 2;
  var legend = g
    .selectAll('.legend')
    .data(domain)
    .enter()
    .append('g')
    .attr('class', 'legend')
    .attr('transform', function(d, i) {
      var circle_height = legendRectSize + legendSpacing;
      var offset =  circle_height * domain.length / 2;
      var horz = - 2 * legendRectSize;
      var vert = (i + 5) * circle_height - offset;
      return 'translate(' + (margin ? margin.right : 0) + ',' + vert + ')';
    });

  legend
      .append("rect")
      .attr("x", (width - (margin.right / 4)))
      .attr('y', (d,i) => {
        return i*10;
      })
      .attr("width", legendRectSize)
      .attr("height", legendRectSize)
      .attr("fill", (d, i) => {
        return color(d);
      });

  legend
      .append("text")
      .attr("x", (width))
      .attr('y', (d,i) => {
        return (i*10) + (legendSpacing * 2);
      })
      .attr("font-size", legendRectSize)
      .attr("text-anchor", "right")
      .style("alignment-baseline", "middle")
      .text((d, i) => {
        return d;
      });
}

let createD3Lines = (keys, x, yLinear, x_keys) => {
  let groupByKey = groupBy;
  let keysForLine = x_keys;

  let lines = {};
  // console.log(`x scale: range - ${x.range()}, domain - ${x.domain()}`)
  // console.log(`y scale: range - ${yLinear.range()}, domain - ${yLinear.domain()}`)
  keys.forEach((key) => {
    lines[key] = d3Shape.line()
      .x(function(d, i) {
        let value_for_x_pos = groupByKey === 'days' ? d.day_label : d.xKey;
        // console.log(`x val for ${key} = ${x(value_for_x_pos)} | (value_for_x_pos = ${value_for_x_pos}) | groupByKey: ${groupByKey} | d: ${JSON.stringify(d)}`)
        return x(value_for_x_pos);
      })
      .y(function(d) {
        // console.log(`yLinear for ${key} = ${yLinear(d[key])}, d[key] = ${d[key]}`)
        return yLinear(d[key]);
      });
  })

  return lines;
}

let drawLineChart = (data, g, x, y, min_max, x_keys) => {
  let lines = createD3Lines(keys, x, y, x_keys);
  if(data) {
    Object.keys(lines).forEach((key, index) => {
      let colors = { dif:"#efa900", day:"#Dd6f6f", nite:"#084e7b", average:"#5cbf91"}

      g.append("svg:path")
        .datum(data)
        .attr("stroke", color(key))
        .attr("stroke-width", (6 - (index/2)))
        .attr("stroke-opacity", (1 - (index*0.1)))
        .attr("fill", "none")
        .attr('class', `${key} line`)
        .attr("pointer-events","all")
        .attr("d", lines[key])
    })
  } else {
    Object.keys(lines).forEach((key) => {
      g.select('.sp1')
         .transition()
         .duration(1000)
         .attr("d", lines[key])
    });
  }
}

let drawChart = (chartData, g, x, x1, y, min_max, xDayScale) => {
  let x_keys = groupBy === 'days' ? chartData.map((d) => d.day_label) : ['dif', 'day', 'nite', 'average'];
  drawLineChart(chartData, g, xDayScale, y, min_max, x_keys)
}

export const StatisticsGraphGenerator = {
  drawChart,
  drawLineChart,
  createD3Lines,
  drawLegend,
  drawAxes,
  initAxes,
  init
}
