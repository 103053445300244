import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-status-chip',
  templateUrl: './status-chip.component.html',
  styleUrls: ['./status-chip.component.scss'],
})
export class StatusChipComponent implements OnInit {
  @Input() name: string;
  @Input() data: string;
  @Input() activation: string = undefined;
  @Input() units: string;
  @Input() param: string;
  @Input() status: string;
  @Input() setting: string;
  @Input() display: boolean = true;
  @Input() pending: boolean;
  @Input() editable: boolean;
  @Input() showstatus: boolean;
  @Input() action: any;
  @Input() indicator: boolean;
  @Input() green: boolean;
  @Input() red: boolean;
  @Input() underline: string;
  @Input() zeroDisabled: boolean = false;
  @Input() vfdStatus;
  public off;

  @Output() edit = new EventEmitter<String>();

  constructor() {
    this.off = this.showstatus && (!this.indicator && !this.pending);
  }

  ngOnInit(): void { }
  ngOnChanges() {
    if(this.indicator && typeof this.indicator === 'string' && (this.indicator === 'undefined' || this.indicator === 'false')) { this.indicator = false;}    
  }

  public editSetting(setting: String) {
    this.edit.emit(setting);
  }

  public isHeat() {
    return (this.name.includes("heat") || this.name.includes("Heat")) && (!this.name.includes('floating') || !this.name.includes('Floating'));
  }

  public settingIsZero() {
    try {
      return this.zeroDisabled && this.data !== undefined && parseInt(this.data) === 0;
    } catch(e) {
      return false;
    }
  }
}
