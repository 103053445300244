import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NavController, NavParams, PopoverController } from '@ionic/angular';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-edit-time-boss',
  templateUrl: './edit-time-boss.component.html',
  styleUrls: ['./edit-time-boss.component.scss'],
})
export class EditTimeBossComponent implements OnInit {
  canEdit = false;
  isPending = false;
  current_value: String;
  key: String;
  users = [];
  timer_index;
  public inputInvalid = false;
  public timerEditForm: UntypedFormGroup;
  public currentTimeValue;
  private timeValidator(timeString: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const invalid = timeString.test(control.value);
      return invalid ? {invalid: {value: control.value}} : null;
    };
  }

  constructor(public navCtrl: NavController,
    private navParams: NavParams,
    public popoverController: PopoverController,
    public userService: UserService
  ) {
    this.isPending = navParams.get('isPending');
    this.key = navParams.get('key');
    this.current_value = navParams.get('value');
    this.timer_index = navParams.get('timer_index');

    let value = new UntypedFormControl(navParams.get('value'), []);
    let comment = new UntypedFormControl('', [this.timeValidator(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)]);

    this.users = navParams.get('users');
    const current_user = this.userService.userLoginData.email;

    if(this.users && current_user) {
      var current_user_role = this.users.find((u) => { return u.email === current_user; })
      this.canEdit = current_user_role ? (current_user_role.role > 2) : false;
    } else {
      this.canEdit = false;
    }

    this.timerEditForm = new UntypedFormGroup({
      value: value,
      comment: comment
    })
  }

  ngOnInit() {}

  save() {
    const form = this.timerEditForm.value;
    var returnData = {
      button: "Save",
      comment: form.comment,
      value: form.value,
      key: this.key
    };

    this.popoverController.dismiss(returnData);
  }

  cancel() {
    this.popoverController.dismiss({button: "Cancel"});
  }

  clearValue() {
    this.currentTimeValue = undefined;
  }

  reformatTime(event) {
    var timeString = event.target.value;
    if(Number.isInteger(parseInt(event.key))) {
      // console.log(`timeString: ${timeString}, length: ${timeString.length}`)
      timeString = timeString.replace(/^0+/, '').replace(":", "")
      this.currentTimeValue = timeString;
      let updatedVal;
      if(timeString.length == 1) {
        updatedVal = `0:0${timeString}`;
      } else if(timeString.length == 2) {
        updatedVal = `0:${timeString}`
      } else if(timeString.length >= 3 && timeString.length <= 4){
        var startInt = (timeString.length === 3) ? 1 : 2
        updatedVal = `${timeString.substring(0, startInt)}:${timeString.substring(startInt, timeString.length)}`
      } else {
        updatedVal = timeString

      }

      var valFormInput = this.timerEditForm.get('value');
      valFormInput.setValue(updatedVal);
    }
  }
}
