import { Component, OnInit } from '@angular/core';
import { CisProvider } from '../services/cis.service';

@Component({
  selector: 'app-admin-sites',
  templateUrl: './admin-sites.component.html',
  styleUrls: ['./admin-sites.component.scss'],
})
export class AdminSitesComponent  implements OnInit {
  public selectedSite;
  public sites = [];
  view = "";

  constructor(private cisService: CisProvider) { }

  ngOnInit() {
    this.cisService.getCisList().then((data) => {
      this.sites = data.sort((da, db) => {
        if(da.name > db.name) { return 1; } else { return -1; }
      }).map((d) => {
        let owner = d.users.find((u) => u.role === 4)
        d.owner_email = owner && owner.email;
        return d;
      });
    });
  }

  siteChange(event) {
    this.selectedSite = event.target.value;
  }
}
