<div *ngIf="info" class="water-sensor-container">
  <div class="uneditable">
    <label>Water Volume</label>
    <span>{{volume}}</span>
  </div>
  <div>
    <label>Water Set Point</label>
    <span>{{sp}}</span>
  </div>
</div>
