import * as d3 from 'd3';
import {customEvent, select, selectAll, touch} from "d3-selection";
import * as d3Scale from 'd3-scale';
import * as d3Array from 'd3-array';
import * as d3Axis from 'd3-axis';
import * as d3Time from 'd3-time';
import * as d3TimeFormat from 'd3-time-format';
import * as d3Shape from 'd3-shape';
import moment from 'moment';
import * as momentTz from 'moment-timezone';

let svg, g;
let height = 200;
let xAxisHeight = 20;
let width = 300;
let margin = {left: 10, right: 10, top: 10, bottom: 10};
let colors = {dif: '#9F24F5', day: '#24C8F5', nite: '#121D8C'}

function drawStage(svg, start, end, set_point, temps, color, yScale) {
  svg.append("svg:rect")
    .attr("id", "line-rect")
    .attr("x", start)
    .attr("y", '0')
    .attr("width", (end-start))
    .attr("height", height-xAxisHeight)
    .style("fill", color)
    .style("opacity", 0.3);

  svg.append("line")
    .attr("x1", start)
    .attr("x2", end)
    .attr("y1", yScale(set_point))
    .attr("y2", yScale(set_point))
    .style("stroke", color)
    .style("stroke-width", 4);

  // svg.append('text')
  //    .attr('class', 'barsEndlineText')
  //    .attr('text-anchor', 'start')
  //    .attr("x", start + 5)
  //    .attr("y", yScale(set_point))
  //    .text('Set Point')

  temps.forEach((t) => {
    let y, color;
    if(['h1t', 'h2t'].includes(t.key)) {
      let original =
      y = yScale(set_point - t.val)
      color = 'red';
    } else {
      y = yScale(set_point + t.val)
      color = 'blue';
    }

    svg.append("line")
      .attr("x1", start)
      .attr("x2", end)
      .attr("y1", y)
      .attr("y2", y)
      .style("stroke", color)
      .style("stroke-width", 2);

    // let labels = {  h1t: 'Heat 1', h2t: 'Heat 2',
    //                 c1t: 'Cool 1', c2t: 'Cool 2', c3t: 'Cool 3', c4t: 'Cool 4'
    //              }
    // svg.append('text')
    //    .attr('class', 'barsEndlineText')
    //    .attr('text-anchor', 'start')
    //    .attr("x", start + 5)
    //    .attr("y", y)
    //    .text(labels[t.key] || 'N/A')
  })
}

function draw(chartId, difStart, dayStart, niteStart, setPoints, temps) {
  let minSP = d3Array.min(Object.values(setPoints))
  let maxSP = d3Array.min(Object.values(setPoints))
  let minTemp = d3Array.min(temps, d => d.val)
  let maxTemp = d3Array.max(temps, d => d.val);
  select(chartId)
    .select('svg')
    .remove()

  svg = select(chartId)
    .append('svg')
    .attr('height', (height+xAxisHeight+margin.bottom+margin.top))
    .attr('width', '100%')
    .attr('id', 'logs-chart')
    .attr("viewBox", `0 -10 ${width + margin.left + margin.right} ${(height+xAxisHeight+margin.bottom+margin.top+10)}`)

  let start = moment().startOf('day').toDate()
  let end = moment().endOf('day').toDate()
  let xScale = d3Scale.scaleTime().domain([start, end]).range([0, width+margin.left])
  let yScale = d3Scale.scaleLinear().domain([minSP-maxTemp-5, maxTemp+maxSP+5]).range([height - margin.top - margin.bottom, 5])

  let difX = xScale(moment(difStart, 'HH:mm').toDate())
  let dayX = xScale(moment(dayStart, 'HH:mm').toDate())
  let niteX = xScale(moment(niteStart, 'HH:mm').toDate())

  drawStage(svg, xScale(start), difX, setPoints.nite, temps, colors.nite, yScale)
  drawStage(svg, difX, dayX, setPoints.dif, temps, colors.dif, yScale)
  drawStage(svg, dayX, niteX, setPoints.day, temps, colors.day, yScale)
  drawStage(svg, niteX, xScale(end), setPoints.nite, temps, colors.nite, yScale)

  const g = svg.append('g')
               .attr('width', '100%')
               .attr("transform", `translate(0,${height-xAxisHeight})`)
               .call(d3Axis.axisBottom(xScale))
               .attr('font-size', '14px')
               .selectAll("text")
               .attr("transform", "rotate(65)")
               .style("text-anchor", "start");

  svg.append('g')
    .attr('width', '100%')
    .attr("transform", "translate(0,0)")
    .call(d3Axis.axisLeft(yScale).ticks(5))
    .attr('font-size', '14px')
    .selectAll("text")
    .style("text-anchor", "end");
}

export const TimelineChartGenerator = {
  draw: draw,
  remove: (chartId) => { select(chartId).select('svg').remove() }
}
