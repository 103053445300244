import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class ToggleService {
  private baseUrl = environment.apiUrl;
  private toggles: any = {}

  constructor(public http: HttpClient, private userService: UserService) {}

  public getCurrentToggles(): Promise<any>{
    var url = `${this.baseUrl}/api/toggles`;
    return this.http.get(url)
                 .toPromise()
                 .then((response) => { 
                  this.toggles = response; 
                })
                 .catch(() => { 
                  this.toggles = {irrigation: {active: true}, guidedProgramming: {active: true}}; }
                  );
  }

  public hasAccess(toggleName, userEmail = null, nodeId = null) {
    try {      
      let overallToggleActive = this.toggles[toggleName] ? this.toggles[toggleName].active : false      
      let loggedInUser = this.userService.getLoggedInEmail()      
      if(this.userService.isLoggedInAs()) { 
        userEmail = loggedInUser; 
      } else {
        userEmail = this.userService.getLoginData() && this.userService.getLoginData().email;
      }      
      let userAccessToToggle = userEmail && userEmail.includes("@bartinst.com");
      let nodeAccessToToggle = userEmail && userEmail.includes("@bartinst.com");    
      
      if(!userAccessToToggle && this.toggles[toggleName] && this.toggles[toggleName].users && userEmail) {
          userAccessToToggle = this.toggles[toggleName].users.includes(userEmail);          
      }
      
      if(this.toggles[toggleName] && this.toggles[toggleName].nodes && nodeAccessToToggle) {
          nodeAccessToToggle = this.toggles[toggleName].nodes.includes(nodeId);
      }
      
      return overallToggleActive ? (userAccessToToggle || nodeAccessToToggle) : false;
    } catch(e) {
      console.log(e)
      return false;
    }
  }
}