<ion-item lines="none">
  <ion-label>Program Guide</ion-label>
  <ul slot="end" *ngIf="!finalReview && !startFromScratch && online && !smallScreen">
    <li mode="ios" *ngFor="let page of viewablePages; let i = index;" (click)="setPageIn(page)" class="breadcrumb"
      [class.chosen]="page.steps && page.steps.includes(current_step)" lines="none">
      <span *ngIf="i > 0" class="breadcrumb-separator">/</span>
      <a>{{page.name}}</a>
      <span *ngIf="i === 0" class="breadcrumb-separator">/</span>
      <ion-button (click)="presentPopover($event)" *ngIf="i === 0" class="ellipses" size="small"><ion-icon name="ellipsis-horizontal-outline"></ion-icon></ion-button>
    </li>    
  </ul>
  <ion-select slot="end" *ngIf="!finalReview && !startFromScratch && online && smallScreen && current_page" [value]="current_page" (ionChange)="setPageIn($event)">
    <ion-select-option *ngFor="let page of viewablePages; let i = index;" [value]="page">
        {{page.name}}
      </ion-select-option>
  </ion-select>   
  <ion-button slot="end" class="load-controller" *ngIf="smallScreen"
    [disabled]="(!finalReview || !startFromScratch || (current_page && current_page.steps[0] !== 'dehumidification_form'))" 
    (click)="importToController()">
    <ion-icon name="cloud-upload-outline"></ion-icon>
  </ion-button>   
</ion-item>
<ion-popover #popover [isOpen]="isOpen" (didDismiss)="isOpen = false">
  <ng-template>
    <ion-content>
      <ion-list>
        <ion-item
          *ngFor="let page of viewablePages; last as isLast"          
          [lines]="isLast ? 'none' : null"
        >
          <ion-label><a (click)="setPageIn(page)">{{ page.name }}</a></ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ng-template>
</ion-popover>