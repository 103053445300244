<div class="admin-form change-owner">
  <div id="cisOwnerForm">
    <ion-item color="light">
      <ion-label>New Owner</ion-label>
      <ionic-selectable
        *ngIf="users"
        item-content
        [(ngModel)]="newCisOwner"
        [items]="users"
        itemValueField="email"
        itemTextField="email"
        [canSearch]="true"
        (onChange)="selectNewOwner($event)"
        placeholder="Select New Owner"
      >
      </ionic-selectable>
      <!-- <ion-select formControlName="user" placeholder="Select One">
        <ion-select-option *ngFor="let user of users" [value]="user.email">{{user.email}}</ion-select-option>
      </ion-select> -->
    </ion-item>
    <div class="flex-end">
      <button class="submit" [disabled]="!selectedCis || !newCisOwner" (click)="changeOwner()">Change Owner</button>
    </div>
  </div>
</div>