import { Component, Input, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SitesService } from 'src/app/services/sites.service';

import * as d3Array from 'd3-array';
import * as d3Axis from 'd3-axis';
import * as d3Scale from 'd3-scale';
import * as d3 from 'd3-selection';
import * as d3Shape from 'd3-shape';

@Component({
  selector: 'app-controller-statistics',
  templateUrl: './controller-statistics.component.html',
  styleUrls: ['./controller-statistics.component.scss'],
})
export class ControllerStatisticsComponent implements OnInit {
  public showStatisticsGraph = true;
  public showStatisticsData = false;
  rawData: any;
  runTimes: any = this.basicRuntime();
  barData: any = {};
  analytics: any = {zone_one: [], zone_two: []};
  title = 'Controller Statistics';
  subtitle = 'Average Temperatures';
  width: number;
  height: number;
  margin = { top: 25, right: 50, bottom: 30, left: 40 };
  x: any;
  x1: any;
  y: any;
  svg: any;
  g: any;
  keys = ["dif", "day", "nite", "average", 'difAnalytics', 'dayAnalytics', 'niteAnalytics', 'twentyFourHourAnalytics'];
  color = d3Scale.scaleOrdinal(this.keys, ["#efa900", "#Dd6f6f", "#084e7b", "#5cbf91", '#ffc73e', '#dd6f6f', '#0d7dc5', '#86d0ae']);
  groupKey = "day_label";
  chartLabel;
  groupBy;
  hasGraphData = {
    zone_one: false,
    zone_two: false
  }
  private line_opacity = {}
  private key_to_label = {
    dif: "DIF", day: "DAY", nite: "NITE", average: "24 Hour Avg.",
    difAnalytics: "DIF (1 week ago)", dayAnalytics: 'DAY (1 week ago)', niteAnalytics: 'NITE (1 week ago)', twentyFourHourAnalytics: '24 Hour Avg. (1 week ago)'
  }

  @Input() node_id;

  constructor(private _platform: Platform,
    private nodeService: SitesService
  ) {
    this.width = 600 - this.margin.left - this.margin.right;
    this.height = 300 - this.margin.top - this.margin.bottom;
  }

  ngOnInit() {
    this.nodeService.getAverageInformation(this.node_id).then((data) => this.onGetInformationSuccess(data))
  }

  onGetInformationSuccess(data) {
    this.rawData = data;
    this.runTimes = data.run_times || this.basicRuntime()
    this.drawChartGroupByDays();
  }

  basicRuntime() {
    return {
			h1: "N/A",
			h2: "N/A",
			c1: "N/A",
			c2: "N/A",
			c3: "N/A",
			c4: "N/A",
			last_reset: "N/A"
		};
  }

  barDataByDays() {
    this.groupBy = "days";
    if(this.rawData && this.rawData.avg_temps && this.rawData.avg_temps.length && this.rawData.avg_temps.length > 1) {
      this.barData.zone_one = this.rawData.avg_temps.reduceRight((arr, avg) => {
        if(avg && avg.zone_one) {
          const zone_one_info = avg.zone_one;
          const graph_info = {
            day_label: avg.date.label,
            average: zone_one_info.twentyfour_hr,
            nite: zone_one_info.nite,
            day: zone_one_info.day,
            dif: zone_one_info.dif
          }
          arr.push(graph_info)
        }

        return arr;
      }, []);

      this.barData.zone_two = this.rawData.avg_temps.reduceRight((arr, avg) => {
        if(avg && avg.zone_two) {
          const zone_info = avg.zone_two;
          const graph_info = {
            day_label: avg.date.label,
            average: zone_info.twentyfour_hr,
            nite: zone_info.nite,
            day: zone_info.day,
            dif: zone_info.dif
          }
          arr.push(graph_info)
        }

        return arr;
      }, []);
    } else if(this.rawData && this.rawData.length > 0) {
      console.log(this.rawData)
    }
  }

  drawChartGroupByDays() {
    d3.selectAll("svg").remove();
    this.chartLabel = "Temperature (By Day)";
    this.barDataByDays();
    if(this.hasAnalytics()) {
      this.keys = ["dif", "day", "nite", "average", 'difAnalytics', 'dayAnalytics', 'niteAnalytics', 'twentyFourHourAnalytics'];
      this.color = d3Scale.scaleOrdinal(this.keys, ["#efa900", "#Dd6f6f", "#084e7b", "#5cbf91", '#ffc73e', '#dd6f6f', '#0d7dc5', '#86d0ae']);
    } else {
      this.keys = ["dif", "day", "nite", "average"];
      this.color = d3Scale.scaleOrdinal(this.keys, ["#efa900", "#Dd6f6f", "#084e7b", "#5cbf91"]);
    }

    this.groupKey = "day_label";

    const drawZoneChart = (zoneId, data, zone, analytics = {}) => {
      var {svg, g} = this.init(zoneId);
      var {x, y, x1, min_max, xDayScale} = this.initAxes(data, svg, g);
      this.drawAxes(g, x, y, zone);
      this.drawLegend(g);
      this.drawChart(data, g, x, x1, y, min_max, xDayScale, analytics);
    }

    this.hasGraphData.zone_one = this.barData.zone_one && this.barData.zone_one.length > 0;
    if(this.barData.zone_one) {
      drawZoneChart("#zoneOneChart", this.barData.zone_one, "Zone 1", this.analytics.zone_one);
    }

    this.hasGraphData.zone_two = this.barData.zone_two && this.barData.zone_two.length > 0;
    if(this.barData.zone_two) {
      drawZoneChart("#zoneTwoChart", this.barData.zone_two, "Zone 2", this.analytics.zone_two);
    }
  }

  barDataByStages() {
    this.groupBy = "stage";
    var twentryfour_hours_avgs = {label: "24 Hr. Average", xKey: 'average'};
    var nite_avgs = {label: "NITE", xKey: 'nite'};
    var day_avgs = {label: "DAY", xKey: 'day'};
    var dif_avgs = {label: "DIF", xKey: 'dif'};

    this.rawData.avg_temps.forEach((avg) => {
      const zone_one_info = avg.zone_one;
      const label = avg.date.label;
      twentryfour_hours_avgs[label] = zone_one_info.twentyfour_hr;
      nite_avgs[label] = zone_one_info.nite;
      day_avgs[label] = zone_one_info.day;
      dif_avgs[label] = zone_one_info.dif;
    });

    this.barData.zone_one = [twentryfour_hours_avgs, nite_avgs, day_avgs, dif_avgs];

    var z2_twentryfour_hours_avgs = {label: "24 Hr. Average", xKey: 'average'};
    var z2_nite_avgs = {label: "NITE", xKey: 'nite'};
    var z2_day_avgs = {label: "DAY", xKey: 'day'};
    var z2_dif_avgs = {label: "DIF", xKey: 'dif'};
    this.rawData.avg_temps.forEach((avg) => {
      const zone_two_info = avg.zone_two;
      const label = avg.date.label;
      z2_twentryfour_hours_avgs[label] = zone_two_info.twentyfour_hr;
      z2_nite_avgs[label] = zone_two_info.nite;
      z2_day_avgs[label] = zone_two_info.day;
      z2_dif_avgs[label] = zone_two_info.dif;
    });
    this.barData.zone_two = [z2_twentryfour_hours_avgs, z2_nite_avgs, z2_day_avgs, z2_dif_avgs];
  }

  drawChartGroupByStages() {
    d3.selectAll("svg").remove();
    this.chartLabel = "Temperature (By Stage)";
    this.barDataByStages();
    this.keys = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    this.color = d3Scale.scaleOrdinal(this.keys,
      ["#397195", "#f5cb66", "#408666", "#Dd6f6f", "#084e7b", "#efa900", "#5cbf91"]);
    this.groupKey = "label";

    const drawZoneChart = (zoneId, data, zone, analyticsData = {}) => {
      var {svg, g} = this.init(zoneId);
      var {x, y, x1, min_max, xDayScale} = this.initAxes(data, svg, g);
      this.drawAxes(g, x, y, zone);
      this.drawLegend(g);

      this.drawChart(data, g, x, x1, y, min_max, xDayScale, analyticsData);
    }

    this.hasGraphData.zone_one = (this.rawData.avg_temps && this.rawData.avg_temps.length > 0) && this.barData.zone_one;
    if(this.hasGraphData.zone_one) {
      drawZoneChart("#zoneOneChart", this.barData.zone_one, "Zone 1", this.analytics.zone_one);
    }

    this.hasGraphData.zone_two = (this.rawData.avg_temps && this.rawData.avg_temps.length > 0) && this.barData.zone_two
    if(this.hasGraphData.zone_two) {
      drawZoneChart("#zoneTwoChart", this.barData.zone_two, "Zone 2", this.analytics.zone_two);
    }
  }

  init(chartId) {
    const svg = d3.select(chartId)
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('viewBox', '0 0 600 300');
    const g = svg.append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

    return {svg: svg, g: g}
  }

  hasAnalytics() {
    return (this.analytics.zone_one && this.analytics.zone_one.length > 0) || (this.analytics.zone_two && this.analytics.zone_two.length)
  }

  initAxes(chartData, svg, g) {
    let x_keys = this.groupBy === 'days' ? chartData.map((d) => d.day_label) : ['dif', 'day', 'nite', 'average'];
    if(this.hasAnalytics() && this.groupBy !== 'days') {
      x_keys = ["dif", "day", "nite", "average", 'difAnalytics', 'dayAnalytics', 'niteAnalytics', 'twentyFourHourAnalytics'];
      // this.color = d3Scale.scaleOrdinal(this.keys, ["#efa900", "#Dd6f6f", "#084e7b", "#5cbf91", '#ffc73e', '#dd6f6f', '#0d7dc5', '#86d0ae']);
    }
    const min_max = {
      min: (d3Array.min(chartData, d => d3Array.min(this.keys, key => d[key])) - 5),
      max: (d3Array.max(chartData, d => d3Array.max(this.keys, key => d[key])) + 5)
    }

    const x = d3Scale.scaleBand()
      .domain(chartData.map(d => d[this.groupKey]))
      .rangeRound([0, this.width - this.margin.right])
      .paddingInner(0.1);
    const x1 = d3Scale.scaleBand()
      .domain(x_keys)
      .rangeRound([0, x.bandwidth()])
      .padding(0.05);
    const y = d3Scale.scaleLinear()
      .domain([min_max.min, min_max.max]).nice()
      .rangeRound([this.height, this.margin.top])

    let range_value_length = (this.width / x_keys.length);
    let xDayScaleRange = d3Array.range(0, this.width, (this.width / x_keys.length))
    const xDayScale = d3Scale.scaleOrdinal()
        .domain(x_keys)
        .range(xDayScaleRange);

    return {x: x, y: y, x1: x1, min_max: min_max, xDayScale: xDayScale};
  }

  drawAxes(g, x, y, zone) {
    g.append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', 'translate(0,' + this.height + ')')
      .call(d3Axis.axisBottom(x))
      .attr('font-size', '12');

    g.append('g')
      .attr('class', 'axis axis--y')
      .attr('font-size', '14')
      .call(
        d3Axis.axisLeft(y)
          .ticks(10)
          .tickSize(-(this.width - this.margin.right))
      )
      .append('text')
        .attr('class', 'axis-title')
        .attr('x', ((this.width + this.margin.right + this.margin.left) / 2))
        .attr('dx', '5em')
        .attr('fill', 'rgb(92, 191, 145)')
        .text(`${zone} ${this.chartLabel}`);

    g.append('g')
      .attr('class', 'axis axis--y')
      .attr('font-size', '14')
      .attr("transform", "translate( " + (this.width - this.margin.right) + ", 0 )")
      .call(d3Axis.axisRight(y).ticks(10))
        .append('text')
          .attr('class', 'axis-title')
          .attr('dx', '5em')
          .attr('fill', 'rgb(92, 191, 145)')
          .text(`${zone} ${this.chartLabel}`);
  }

  drawLegend(g) {
    const domain = this.color.domain().slice();

    var legendRectSize = 10;
    var legendSpacing = 2;
    var line_opacity = this.line_opacity;
    var keyLabels = this.key_to_label
    var updateLineOpacity = (key) => {
      this.line_opacity[key] = this.line_opacity[key] === 1 ? 0 : 1;
      return this.line_opacity[key];
    }
    var legend = g
      .selectAll('.legend')
      .data(domain)
      .enter()
      .append('g')
      .attr('class', 'legend')
      .attr('transform', function(d, i) {
        var circle_height = legendRectSize + legendSpacing;
        var offset =  circle_height * domain.length / 2;
        var horz = - 2 * legendRectSize;
        var vert = (i + 5) * circle_height - offset;
        return 'translate(' + (this.margin ? this.margin.right : 0) + ',' + vert + ')';
      });

    legend
        .append("rect")
        .attr("x", (this.width - (this.margin.right / 4)))
        .attr('y', (d,i) => {
          return i*10;
        })
        .attr("width", legendRectSize)
        .attr("height", legendRectSize)
        .attr("fill", (d, i) => {
          return this.color(d);
        });

    legend
        .append("text")
        .attr("x", (this.width))
        .attr('y', (d,i) => {
          return (i*10) + (legendSpacing * 2);
        })
        .attr("font-size", legendRectSize)
        .attr("text-anchor", "right")
        .style("alignment-baseline", "middle")
        .style("cursor", "pointer")
        .attr('class', (d, i) => {
          return `${d}Legend`;
        })
        .on("click", function(event) {
          let legendInfo = Object.keys(keyLabels).find(key => keyLabels[key] === event.target.innerHTML);
          console.log(`click on .${legendInfo}`)
          let new_opacity = updateLineOpacity(legendInfo);
          console.log(`new opacity should be ${new_opacity}`)
          console.log(d3.select(`.${legendInfo}`))
          d3.select(`.${legendInfo}`).attr("opacity", new_opacity)
      	})
        .text((d, i) => {
          return keyLabels[d];
        });
  }

  createD3Areas(keys, x, yLinear, x_keys, height) {
    let groupByKey = this.groupBy;
    let keysForLine = x_keys;

    let areas = {};
    keys.forEach((key) => {
      areas[key] = d3Shape.area()
        .x(function(d) {
          let value_for_x_pos = groupByKey === 'days' ? d.day_label : d.xKey;
          return x(value_for_x_pos);
        })
        .y0(height)
        .y1(function(d) { return yLinear(d[key]); });
    })

    return areas;
  }

  createD3Lines(keys, x, yLinear, x_keys, analytics) {
    let groupByKey = this.groupBy;
    let keysForLine = x_keys;
    let lines = {};
    let analytics_lines = {};
    // console.log(`x scale: range - ${x.range()}, domain - ${x.domain()}`)
    // console.log(`y scale: range - ${yLinear.range()}, domain - ${yLinear.domain()}`)
    keys.forEach((key) => {
      lines[key] = d3Shape.line()
        .x(function(d, i) {
          let value_for_x_pos = groupByKey === 'days' ? d.day_label : d.xKey;
          // console.log(`x val for ${key} = ${x(value_for_x_pos)} | (value_for_x_pos = ${value_for_x_pos}) | groupByKey: ${groupByKey} | d: ${JSON.stringify(d)}`)
          return x(value_for_x_pos);
        })
        .y(function(d) {
          // console.log(`yLinear for ${key} = ${yLinear(d[key])}, d[key] = ${d[key]}`)
          return yLinear(d[key]);
        });
      this.line_opacity[key] = 1;

      if(analytics && analytics.length) {
        analytics_lines[key] = d3Shape.line()
          .x(function(d, i) {
            let value_for_x_pos = groupByKey === 'days' ? d.day_label : d.xKey;
            // console.log(`value_for_x_pos: ${value_for_x_pos} - ${x(value_for_x_pos)}`)
            return x(value_for_x_pos);
          })
          .y(function(d) {
            // console.log(`y key: ${key} ${d[key]} - ${yLinear(d[key])}`)
            return yLinear(d[key]);
          });

        this.line_opacity[key] = 0;
      }
    })

    return {lines: lines, analytics_lines: analytics_lines};
  }

  drawLineChart(data, g, x, y, min_max, x_keys, analytics = {}) {
    let {lines, analytics_lines} = this.createD3Lines(this.keys, x, y, x_keys, analytics);
    let areas = this.createD3Areas(this.keys, x, y, x_keys, this.height)

    if(analytics && analytics_lines) {
      Object.keys(analytics_lines).forEach((key, index) => {
        let colors = { dif:"#efa900", day:"#Dd6f6f", nite:"#084e7b", average:"#5cbf91",
                       analytics_dif: '#ffc73e', analytics_day: '#dd6f6f', analytics_nite: '#0d7dc5', analytics_average: '#86d0ae'}
        g.append("svg:path")
          .datum(analytics)
          .attr("stroke", this.color(key))
          .attr("stroke-width", 6)
          .style("stroke-dasharray", ("3, 3"))
          // .attr("stroke-opacity", (1 - (index*0.1)))
          .attr("fill", "none")
          .attr("opacity", 0)
          .attr('class', `${key}Analytics analyticsline`)
          .attr("pointer-events","all")
          .attr("d", analytics_lines[key])
      })
    } else {
      Object.keys(analytics_lines).forEach((key) => {
        g.select(`.analyticsline-${key}`)
           .transition()
           .duration(1000)
           .attr("d", analytics_lines[key])
      });
    }

    if(data) {
      Object.keys(lines).forEach((key, index) => {
        let colors = { dif:"#efa900", day:"#Dd6f6f", nite:"#084e7b", average:"#5cbf91",
                       analytics_dif: '#ffc73e', analytics_day: '#dd6f6f', analytics_nite: '#0d7dc5', analytics_average: '#86d0ae'}
        g.append("svg:path")
          .datum(data)
          .attr("stroke", this.color(key))
          .attr("stroke-width", 6)
          // .attr("stroke-opacity", (1 - (index*0.1)))
          .attr("fill", "none")
          .attr('class', `${key} line`)
          .attr("pointer-events","all")
          .attr("d", lines[key])
      })
    } else {
      Object.keys(lines).forEach((key) => {
        g.select('.line')
           .transition()
           .duration(1000)
           .attr("d", lines[key])
      });
    }
  }

  drawChart(chartData, g, x, x1, y, min_max, xDayScale, analytics = {}) {
    let x_keys = this.groupBy === 'days' ? chartData.map((d) => d.day_label) : ['dif', 'day', 'nite', 'average'];
    this.drawLineChart(chartData, g, xDayScale, y, min_max, x_keys, analytics)
  }
}
